import React, { Component, lazy, useEffect } from "react";
// const d3 = lazy(() => import("d3"));
const color = [
	"#1f77b4",
	"#ff7f0e",
	"#2ca02c",
	"#d62728",
	"#9467bd",
	"#8c564b",
	"#e377c2",
	"#7f7f7f",
	"#bcbd22",
	"#17becf",
];

const data = [
	{
		value: 1689,
		function_name: "software_development",
		detail: "(web, mobile, desktop, machine learning, AI, etc.)",
	},
	{
		value: 961,
		function_name: "business_operation",
		detail: "(sales, business development, etc.)",
	},
	{
		value: 650,
		function_name: "product_development",
		detail: "(product management, product design, etc.)",
	},

	// {
	// 	value: 438,
	// 	function_name: "research",
	// },
	{
		value: 602,
		function_name: "marketing",
		detail: "(digital marketing, content marketing, etc.)",
	},
	{
		value: 451,
		function_name: "design",
		detail: "(UI/UX design, graphic design, etc.)",
	},
	// {
	// 	value: 188,
	// 	function_name: "artificial intelligence",
	// },
	{
		value: 420,
		function_name: "hardware_development",
		detail:
			"(hardware development, hardware design, hardware engineering, etc.)",
	},
	{
		value: 135,
		function_name: "human_resource",
		detail: "(recruiting, talent acquisition, etc.)",
	},
	{
		value: 74,
		function_name: "finance",
		detail: "(accounting, financial analysis, etc.)",
	},
	{
		value: 43,
		function_name: "law",
		detail: "(legal, compliance, etc.)",
	},
	{
		value: 249,
		function_name: "other",
	},
];
const JobFunctionChartf = (props) => {
	const { t } = props;
	function drawChart(D3) {
		const dataValueSum = data.reduce((acc, cur) => acc + cur.value, 0);
		const width = 350,
			height = 350,
			margin = 10;
		const radius = Math.min(width, height) / 2 - margin;

		const D3SVG = D3.selectAll("#jobFumctionChart")
			.append("svg")
			.attr("width", width + 100)
			.attr("height", height + 100)
			.append("g")
			.attr(
				"transform",
				`translate(${(width + 100) / 2},${(height + 100) / 2})`,
			);

		// // color
		// const myColor = D3.scaleLinear()
		// 	.domain([
		// 		Math.log(D3.min(data, (d) => d.value)),
		// 		Math.log(D3.max(data, (d) => d.value)),
		// 	])
		// 	.range(["#5DD4EF", "#2768F6"]);

		const pie = D3.pie()
			.value((d) => {
				return d[1].value;
			})
			.sort((a, b) => {
				// console.log(a, b);
				// if function_name is other, put it at the end
				if (a[1].function_name === "other") {
					return 1;
				}
				if (b[1].function_name === "other") {
					return -1;
				}
				return a[1].value - b[1].value > 0 ? -1 : 1;
			});
		// console.log(Object.entries(data));
		const data_ready = pie(Object.entries(data));
		// console.log("data_ready", data_ready);
		const colors = D3.scaleOrdinal()
			.domain(
				data_ready.map((d) => {
					// console.log("d", d);
					return d.data[1].function_name;
				}),
			)
			.range(color);

		// Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
		const { t } = props;
		let dataArea = D3SVG.selectAll("whatever")
			.data(data_ready)
			.join("path")
			.attr(
				"d",
				D3.arc()
					.innerRadius(80) // This is the size of the donut hole
					.outerRadius((d, i) => (i <= 5 ? radius * 1.1 : radius)),
			)
			.attr("fill", (d, i) => (i > 5 ? "#ddd" : color[i]))
			.attr("stroke", "#fff")
			.style("stroke-width", "1.5px")
			.style("opacity", 0.8)
			.attr("rx", 5)
			.attr("ry", 5)
			.on("mouseover", function() {
				return tooltip.style("visibility", "visible");
			})
			.on("mousemove", function(event, d) {
				return (
					tooltip
						.style("top", event.layerY + 10 + "px")
						.style("left", event.layerX + 10 + "px")
						// get data from data and change the text

						.html(
							`<div style="white-space:nowrap;font-weight:bold">
							${t(d.data[1].function_name)}
								<span style="color:#2768F6">
									${Math.round(Number((d.value / dataValueSum) * 100))}%
								</span>
							</div>
							<div style="color:gray;font-size:1rem">
							${d.data[1].detail ? d.data[1].detail : ""}
							</div>
							
							`,
						)
				);
			})
			.on("mouseout", function() {
				return tooltip.style("visibility", "hidden");
			});

		//  add tooltip
		const tooltip = D3.select(`#jobFumctionChart`)
			.append("div")
			.style("position", "absolute")
			.style("visibility", "hidden")
			.style("background-color", "white")
			.style("backdrop-filter", "blur(10px)")
			.style("border", "solid #ccc")
			.style("border-width", "1px")
			.style("border-radius", "5px")
			.style("padding", "10px")
			.style("color", "black")
			.style("box-shadow", "0 0 10px rgba(0,0,0,0.25)")
			.style("font-size", "1rem")
			.style("opacity", 0.95)
			.style("text-align", "left");
		// .style("transition", "all 0.3s ease 0s")
		// .text("I'm a circle!");

		// ADD LABELS at the outside of the circle if the area is too small, don't show the label, text color is black.
		D3SVG.selectAll("allLabels")
			.data(data_ready)
			.enter()
			.append("text")
			.text((d) => {
				// console.log("d", d);
				return `${Math.round(Number((d.value / dataValueSum) * 100))}%`;
			})
			.attr("transform", function(d) {
				// console.log("d", d);
				return (
					"translate(" +
					D3.arc()
						.innerRadius(100)
						.outerRadius(radius * 1.9)
						.centroid(d) +
					")"
				);
			})
			.style("text-anchor", "middle")
			.style("font-size", "1rem")
			.style("fill", "black")
			.style("opacity", (d) => (d.value / dataValueSum < 0.05 ? 0 : 1));
	}
	useEffect(() => {
		import("d3").then((d3) => {
			drawChart(d3);
		});
	}, []);
	return (
		<div
			style={{
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "space-around",
				alignItems: "center",
				gap: "5%",
				rowGap: "5rem",
			}}>
			<div style={{ width: "40%", minWidth: "400px" }}>
				<div id="jobFumctionChart"></div>
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						alignItems: "center",
						gap: "5%",
						rowGap: "1rem",
					}}>
					{color.slice(0, 6).map((color, index) => (
						<div
							key={index}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								gap: "3%",

								width: "fit-content",
							}}>
							<div
								style={{
									backgroundColor: color,
									width: "10px",
									height: "10px",
									aspectRatio: "1/1",
								}}></div>
							<div style={{ whiteSpace: "nowrap" }}>{`${t(
								data[index].function_name,
							)}`}</div>
						</div>
					))}
				</div>
			</div>
			<div
				style={{
					textAlign: "start",
					maxWidth: "600px",
					minWidth: "400px",
				}}>
				{/* <h3>nice chart</h3> */}
				<p
					style={{
						fontSize: "1.4rem",
						fontWeight: "bold",
						lineHeight: "2.5rem",
					}}>
					{t("jobFunctionChartTitle")}
				</p>
				{/* <p style={{ fontSize: "1.3rem", lineHeight: "2rem" }}>
					{t("jobFunctionChartText")}
					軟體工程師人才約佔人才庫的 1/3。
				</p> */}
			</div>
		</div>
	);
};
//  class JobFunctionChart extends Component {
// 	constructor(props) {
// 		super(props);
// 	}
// 	componentDidMount() {}

// 	render() {
// 		return <></>;
// 	}
//  }
export default JobFunctionChartf;
