import React from "react";
import Select from "react-select";
import Textarea from "react-textarea-autosize";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import moment from "moment";
import Message from "../../../models/message.js";
import Comment from "../../../models/comment.js";
import ReferrerNote from "../../../models/referrer_note.js";
import JobApplication from "../../../models/job_application.js";
import { withNamespaces, Interpolate } from "react-i18next";
import { CSSTransitionGroup } from "react-transition-group";
import {
	get_options,
	contain_not_only_whitespace,
	linkify,
	download_file,
	get_value_from_query_string,
	smoothly_scroll_to,
} from "../../../../assets/js/functions.js";
import { ReactComponent as IconOk } from "../../../../assets/images/icon/icon_28_ok_white.svg";
import icon_attachment from "../../../../assets/images/icon/icon_24_attachment_BG400.svg";
import icon_calendar from "../../../../assets/images/icon/icon_24_calendar_BG400.svg";
import { FaRegQuestionCircle } from "react-icons/fa";
import icon_lock from "../../../../assets/images/icon/icon_24_lock_BG400.svg";
import Employer from "../../../models/employer";
import MeetJobsLoader from "../../general/MeetJobsLoader.jsx";
import icon_redirect from "../../../../assets/images/icon/icon_24_redirect_B100.svg";
import { Link } from "react-router-dom";
import InternalUri from "../../../models/internal_uri";
import modal_banner_confirm from "../../../../assets/images/i044-offer-confirm.svg";
import DeviceDetector from "../../../models/device_detector";

const config = new Config();
const headers = new ApiHeaders();

class EmployerMessageModal extends React.Component {
	constructor(props) {
		super(props);
		this.fetchData = this.fetchData.bind(this);
		this.fetchComments = this.fetchComments.bind(this);
		this.fetchMessages = this.fetchMessages.bind(this);

		this.handleTabChange = this.handleTabChange.bind(this);
		this.closeModal = this.closeModal.bind(this);

		this.updateInterviewBox = this.updateInterviewBox.bind(this);
		this.handleInterviewInputChange = this.handleInterviewInputChange.bind(
			this,
		);
		this.handleInterviewTimeKeyDown = this.handleInterviewTimeKeyDown.bind(
			this,
		);
		this.handleInterviewSelectChange = this.handleInterviewSelectChange.bind(
			this,
		);

		this.submitChatForm = this.submitChatForm.bind(this);
		this.submitInterviewForm = this.submitInterviewForm.bind(this);

		this.messagesScrollToBottom = this.messagesScrollToBottom.bind(this);
		// this.handleKeyPress = this.handleKeyPress.bind(this);

		this.cancelChangeState = this.cancelChangeState.bind(this);
		this.confirmApplication = this.confirmApplication.bind(this);
		this.rejectApplication = this.rejectApplication.bind(this);

		this.handleMessagesWrapperScroll = this.handleMessagesWrapperScroll.bind(
			this,
		);
		this.loadMoreData = this.loadMoreData.bind(this);

		// attachment
		this.handleAttachment = this.handleAttachment.bind(this);
		this.deleteAttachment = this.deleteAttachment.bind(this);
		this.handleDownload = this.handleDownload.bind(this);

		// check sendable
		this.checkIsSendable = this.checkIsSendable.bind(this);
		this.checkIsSendableInterview = this.checkIsSendableInterview.bind(this);

		this.handleActionEvents = this.handleActionEvents.bind(this);
		this.sendActionEvents = this.sendActionEvents.bind(this);
		this.addActionEventTriggerToContent = this.addActionEventTriggerToContent.bind(
			this,
		);

		this.state = {
			application: {},
			active_company_id: -1,
			active_company: {},
			active_job_id: -1,
			active_application_id: -1,
			active_user_id: -1,
			active_tab: "",
			active_tab_index: 0,
			messages: [],
			comments: [],
			referrer_notes: [],
			form_data: {
				message: {
					content: "",
					interview: {
						open: false,
						title: "",
						location: "",
						years_selected_value: "",
						months_selected_value: "",
						dates_selected_value: "",
						start_at: "19:00",
						end_at: "20:30",
					},
					attachment: {},
				},
				comment: {
					content: "",
				},
			},
			unread: {
				messages: 0,
				comments: 0,
				referrer_notes: 0,
			},
			options: {},
			loading: true,
			loading_conversations: true,
			sending: false,
			sending_interview: false,
			sending_change_state: false,
			loading_more: false,
			is_sendable: false,
			is_sendable_interview: false,
			change_state_message_modal: {
				open: false,
				action_type: "",
				next_state: "",
			},
			error_messages: {},
		};
		this.paginator = {
			message: {},
			comment: {},
			referrer_note: {},
		};
	}

	componentDidMount() {
		let years = {},
			dates = {},
			months = {
				"1": "Jan",
				"2": "Feb",
				"3": "March",
				"4": "Apr",
				"5": "May",
				"6": "Jun",
				"7": "Jul",
				"8": "Aug",
				"9": "Sep",
				"10": "Oct",
				"11": "Nov",
				"12": "Dec",
			};
		const now_year = parseInt(moment(Date.now()).format("YYYY"), 10);
		const now_month = parseInt(moment(Date.now()).format("MM"), 10);
		const now_date = parseInt(moment(Date.now()).format("DD"), 10);
		const now_days_in_month = moment(Date.now()).daysInMonth();
		for (let i = 0; i < 5; i++) {
			years[now_year + i] = `${now_year + i}`;
		}
		for (let i = 0; i < now_days_in_month; i++) {
			dates[i + 1] = `${i + 1}`;
		}

		this.setState((prev_state) => ({
			...prev_state,
			form_data: {
				...prev_state.form_data,
				message: {
					...prev_state.form_data.message,
					interview: {
						...prev_state.form_data.message.interview,
						years_selected_value: now_year,
						months_selected_value: now_month,
						dates_selected_value: now_date,
					},
				},
			},
			options: {
				years: get_options("years", years),
				months: get_options("months", months),
				dates: get_options("dates", dates),
			},
		}));
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		// console.log(next_props);
		const {
			open,
			company_id,
			job_id,
			application_id,
		} = next_props.site_state.employer_message_modal;
		if (
			JSON.stringify(this.props.site_state.employer_message_modal) !==
			JSON.stringify(next_props.site_state.employer_message_modal)
		) {
			if (open) {
				const query = decodeURI(this.props.location.search);
				let open_interview_box = false,
					open_double_confirm_modal = "";
				if (query) {
					open_interview_box =
						get_value_from_query_string("open_interview_box", query) === "true";
					open_double_confirm_modal = get_value_from_query_string(
						"open_double_confirm_modal",
						query,
					);
				}

				const { reduxTokenAuth, current_user } = next_props;
				this.setState(
					(prev_state) => ({
						...prev_state,
						messages: [],
						comments: [],
						active_company_id: company_id,
						active_company: new Employer(current_user.active_company),
						active_job_id: job_id,
						active_application_id: application_id,
						active_tab: "message",
						active_tab_index: 0,
						active_user_id: reduxTokenAuth.currentUser.attributes.id,
						form_data: {
							...prev_state.form_data,
							message: {
								...prev_state.form_data.message,
								interview: {
									...prev_state.form_data.message.interview,
									open: open_interview_box,
								},
							},
						},
						loading_conversations: true,
						loading: true,
						error_messages: {},
					}),
					() => {
						this.fetchData(open_double_confirm_modal);
						const { handleActionEvents } = this;
						document
							.querySelector(".employer-message-modal-wrapper")
							.addEventListener("click", (e) => handleActionEvents(e));
					},
				);
			} else {
				const { handleActionEvents } = this;
				document
					.querySelector(".employer-message-modal-wrapper")
					.removeEventListener("click", (e) => handleActionEvents(e));
			}
		}
	}

	componentWillUnmount() {
		this.props.updateEmployerMessageModal(false, -1, -1, -1);
	}

	fetchData(open_double_confirm_modal) {
		const {
			active_company_id,
			active_job_id,
			active_application_id,
			active_user_id,
		} = this.state;
		let options = {
			method: "GET",
			url: config.api_url(
				`/employers/${active_company_id}/jobs/${active_job_id}/job_applications/${active_application_id}`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let application,
			messages = [],
			comments = [],
			referrer_notes = [];
		let message_ids = [],
			comment_ids = [],
			referrer_note_ids = [];
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);
				application = new JobApplication(response.data);
				options = {
					method: "GET",
					url: config.api_url(
						`/jobs/${active_job_id}/job_applications/${active_application_id}/messages`,
					),
					headers: headers.getItemsFromLocalStorage(),
					json: true,
				};
				return axios(options);
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				messages = response.data.collection.reverse();
				this.paginator.message = response.data.paginator;
				for (let i = 0; i < messages.length; i++) {
					messages[i] = new Message(messages[i]);
				}
				for (let i = 0; i < messages.length; i++) {
					message_ids.push(messages[i].id);
				}

				options = {
					method: "GET",
					url: config.api_url(
						`/jobs/${active_job_id}/job_applications/${active_application_id}/comments`,
					),
					headers: headers.getItemsFromLocalStorage(),
					json: true,
				};
				return axios(options);
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				comments = response.data.collection.reverse();
				this.paginator.comment = response.data.paginator;
				for (let i = 0; i < comments.length; i++) {
					comments[i] = new Comment(comments[i]);
				}
				for (let i = 0; i < comments.length; i++) {
					comment_ids.push(comments[i].id);
				}

				options = {
					method: "GET",
					url: config.api_url(
						`/jobs/${active_job_id}/job_applications/${active_application_id}/referrer_notes`,
					),
					headers: headers.getItemsFromLocalStorage(),
					json: true,
				};
				return axios(options);
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				referrer_notes = response.data.collection.reverse();
				this.paginator.referrer_note = response.data.paginator;
				for (let i = 0; i < referrer_notes.length; i++) {
					referrer_notes[i] = new ReferrerNote(referrer_notes[i]);
				}
				for (let i = 0; i < referrer_notes.length; i++) {
					referrer_note_ids.push(referrer_notes[i].id);
				}

				let promise = [];
				if (message_ids.length > 0)
					promise.push(
						axios({
							method: "POST",
							url: config.api_url(`/users/${active_user_id}/readings`),
							headers: headers.getItemsFromLocalStorage(),
							data: {
								reading: {
									readable_type: "Job::JobApplication::Message",
									readable_ids: message_ids,
								},
							},
							json: true,
						}),
					);
				if (comment_ids.length > 0)
					promise.push(
						axios({
							method: "POST",
							url: config.api_url(`/users/${active_user_id}/readings`),
							headers: headers.getItemsFromLocalStorage(),
							data: {
								reading: {
									readable_type: "Job::JobApplication::Comment",
									readable_ids: comment_ids,
								},
							},
							json: true,
						}),
					);
				if (referrer_note_ids.length > 0)
					promise.push(
						axios({
							method: "POST",
							url: config.api_url(`/users/${active_user_id}/readings`),
							headers: headers.getItemsFromLocalStorage(),
							data: {
								reading: {
									readable_type: "Job::JobApplication::ReferrerNote",
									readable_ids: referrer_note_ids,
								},
							},
							json: true,
						}),
					);
				return axios.all(promise);
			})
			.then((results) => {
				for (let i = 0; i < results.length; i++) {
					headers.updateItemsToLocalStorage(results[i].headers);
				}

				this.setState(
					(prev_state) => ({
						...prev_state,
						application,
						messages,
						comments,
						referrer_notes,
						unread: {
							// messages: application.current_user_status.unread.messages,
							messages: 0,
							comments: application.current_user_status.unread.comments,
							referrer_notes:
								application.current_user_status.unread.referrer_notes,
						},
						loading: false,
						sending_change_state: false,
						loading_conversations: false,
						error_messages: {},
					}),
					() => {
						this.messagesScrollToBottom();
						this.checkIsSendable();
						this.checkIsSendableInterview();

						if (
							open_double_confirm_modal === "reject" &&
							application.employer_state_changeable() &&
							!application.is_rejected()
						)
							this.rejectApplication(new Event("build"));
					},
				);
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState(
					(prev_state) => ({
						...prev_state,
						loading: false,
						sending_change_state: false,
						loading_conversations: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}),
					() => {
						const internal_uri = new InternalUri();
						this.props.history.push(
							`${internal_uri.formatted_dashboard_applications_page_path()}?refresh=true`,
						);
						this.props.updateEmployerMessageModal(false, -1, -1, -1);
					},
				);
			});
	}

	fetchMessages() {
		const { active_job_id, active_application_id, active_user_id } = this.state;
		let options = {
			method: "GET",
			url: config.api_url(
				`/jobs/${active_job_id}/job_applications/${active_application_id}/messages`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let messages = [];
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				messages = response.data.collection.reverse();
				this.paginator.message = response.data.paginator;
				for (let i = 0; i < messages.length; i++) {
					messages[i] = new Message(messages[i]);
				}

				let message_ids = [];
				for (let i = 0; i < messages.length; i++) {
					message_ids.push(messages[i].id);
				}
				let data = {
					reading: {
						readable_type: "Job::JobApplication::Message",
						readable_ids: message_ids,
					},
				};
				options = {
					method: "POST",
					url: config.api_url(`/users/${active_user_id}/readings`),
					headers: headers.getItemsFromLocalStorage(),
					data: data,
					json: true,
				};
				return axios(options);
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				this.setState((prev_state) => ({
					...prev_state,
					error_messages: {},
				}));
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState((prev_state) => ({
					...prev_state,
					error_messages:
						error.response &&
						error.response.data &&
						error.response.data.status === "error"
							? error.response.data.errors
							: { full_message: "There was an error. Please try again later." },
				}));
			})
			.then(() => {
				this.setState(
					(prev_state) => ({
						...prev_state,
						messages,
						loading: false,
						loading_conversations: false,
					}),
					() => {
						this.messagesScrollToBottom();
						this.checkIsSendable();
						this.checkIsSendableInterview();
					},
				);
			});
	}

	fetchComments() {
		const { active_job_id, active_application_id, active_user_id } = this.state;
		let options = {
			method: "GET",
			url: config.api_url(
				`/jobs/${active_job_id}/job_applications/${active_application_id}/comments`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let comments = [];
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				comments = response.data.collection.reverse();
				this.paginator.comment = response.data.paginator;
				for (let i = 0; i < comments.length; i++) {
					comments[i] = new Comment(comments[i]);
				}

				let comment_ids = [];
				for (let i = 0; i < comments.length; i++) {
					comment_ids.push(comments[i].id);
				}
				let data = {
					reading: {
						readable_type: "Job::JobApplication::Comment",
						readable_ids: comment_ids,
					},
				};
				options = {
					method: "POST",
					url: config.api_url(`/users/${active_user_id}/readings`),
					headers: headers.getItemsFromLocalStorage(),
					data: data,
					json: true,
				};
				return axios(options);
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				this.setState((prev_state) => ({
					...prev_state,
					error_messages: {},
				}));
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState((prev_state) => ({
					...prev_state,
					error_messages:
						error.response &&
						error.response.data &&
						error.response.data.status === "error"
							? error.response.data.errors
							: { full_message: "There was an error. Please try again later." },
				}));
			})
			.then(() => {
				this.setState(
					(prev_state) => ({
						...prev_state,
						comments,
						loading: false,
						loading_conversations: false,
					}),
					() => {
						this.messagesScrollToBottom();
						this.checkIsSendable();
					},
				);
			});
	}

	handleInputChange = (e) => {
		e.preventDefault();
		const { sending, sending_interview, sending_change_state } = this.state;
		if (!sending && !sending_interview && !sending_change_state) {
			const { active_tab } = this.state;
			const value = e.target.value;
			this.setState(
				(prev_state) => ({
					...prev_state,
					form_data: {
						...prev_state.form_data,
						[active_tab]: {
							...prev_state.form_data[active_tab],
							content: value,
						},
					},
				}),
				() => this.checkIsSendable(),
			);
		}
	};

	handleTabChange(tab_index) {
		// console.log(tab_index);
		const { sending, sending_interview, sending_change_state } = this.state;
		if (!sending && !sending_interview && !sending_change_state) {
			let active_tab = "message";
			let unread;
			if (tab_index === 0) {
				active_tab = "message";
				unread = {
					...this.state.unread,
					messages: 0,
				};
			}
			if (tab_index === 1) {
				active_tab = "comment";
				unread = {
					...this.state.unread,
					comments: 0,
				};
			}
			if (tab_index === 2) {
				active_tab = "referrer_note";
				unread = {
					...this.state.unread,
					referrer_notes: 0,
				};
			}

			this.setState(
				(prev_state) => ({
					...prev_state,
					active_tab,
					active_tab_index: tab_index,
					unread,
				}),
				() => {
					this.messagesScrollToBottom();
					this.checkIsSendable();
					this.checkIsSendableInterview();
				},
			);
		}
	}

	closeModal = (e) => {
		e.preventDefault();
		// TODO: 一定還有更好的做法！
		const internal_uri = new InternalUri();
		this.props.history.push(
			`${internal_uri.formatted_dashboard_applications_page_path()}?refresh=true`,
		);
		this.props.updateEmployerMessageModal(false, -1, -1, -1);
	};

	// handleKeyPress = (e) => {
	//     if(e.key === 'Enter' && !e.shiftKey) {
	//         this.submitChatForm(e);
	//     }
	// };

	// interview

	updateInterviewBox = (e) => {
		e.preventDefault();
		this.setState(
			(prev_state) => ({
				...prev_state,
				form_data: {
					...prev_state.form_data,
					message: {
						...prev_state.form_data.message,
						interview: {
							...prev_state.form_data.message.interview,
							open: !prev_state.form_data.message.interview.open,
						},
					},
				},
			}),
			() => {
				this.messagesScrollToBottom();
				this.checkIsSendableInterview();
			},
		);
	};

	handleInterviewInputChange = (e, property_name) => {
		e.preventDefault();
		const value = e.target.value;
		this.setState(
			(prev_state) => ({
				...prev_state,
				form_data: {
					...prev_state.form_data,
					message: {
						...prev_state.form_data.message,
						interview: {
							...prev_state.form_data.message.interview,
							[property_name]: value,
						},
					},
				},
			}),
			() => this.checkIsSendableInterview(),
		);
	};
	newInterViewTimeHandler = (e, startOrEndTime) => {
		const { t } = this.props;
		// console.log(this.state);
		const newTime = e.target.value;
		const newtimeToNumber = Number(newTime.replace(":", ""));
		this.setState(
			(prevState) => {
				let interview_time = [];
				if (startOrEndTime === "start_at") {
					if (
						newtimeToNumber >
						Number(
							prevState.form_data.message.interview.end_at.replace(":", ""),
						)
					) {
						interview_time.push(
							t("general:end_time_must_be_greater_than_start_time"),
						);
					}
				} else {
					if (
						newtimeToNumber <
						Number(
							prevState.form_data.message.interview.start_at.replace(":", ""),
						)
					) {
						interview_time.push(
							t("general:end_time_must_be_greater_than_start_time"),
						);
					}
				}
				return {
					...prevState,
					form_data: {
						...prevState.form_data,
						message: {
							...prevState.form_data.message,
							interview: {
								...prevState.form_data.message.interview,
								[`${startOrEndTime}`]: newTime,
							},
						},
					},
					error_messages: {
						...prevState.error_messages,
						interview_time,
					},
				};
			},
			() => this.checkIsSendableInterview(),
		);
	};
	handleInterviewTimeKeyDown = (e, property_name) => {
		let value = this.state.form_data.message.interview[property_name];
		let target = e.target;
		let index = e.target.selectionStart;
		const key_code = e.keyCode;
		if (key_code === 8) {
			switch (index) {
				case 1:
				case 2:
				case 5:
					index = index - 1;
					value = value.substring(0, index) + "0" + value.substring(index + 1);
					target.blur();
					window.setTimeout(function() {
						target.focus();
						target.setSelectionRange(index, index);
					}, 0);
					break;
				case 4:
					index = index - 1;
					value = value.substring(0, index) + "0" + value.substring(index + 1);
					target.blur();
					window.setTimeout(function() {
						target.focus();
						target.setSelectionRange(index, index);
					}, 0);
					break;
				case 3:
					index = index - 1;
					target.blur();
					window.setTimeout(function() {
						target.focus();
						target.setSelectionRange(index, index);
					}, 0);
					break;
				default:
					break;
			}
		}

		if (key_code >= 48 && key_code <= 57) {
			let new_number = e.keyCode - 48;
			switch (index) {
				case 0:
					if (new_number >= 0 && new_number <= 2) {
						value =
							value.substring(0, index) +
							`${new_number}` +
							value.substring(index + 1);
					}
					if (new_number === 2) {
						value =
							value.substring(0, index + 1) +
							"0" +
							value.substring(index + 1 + 1);
					}
					index = index + 1;
					target.blur();
					window.setTimeout(function() {
						target.focus();
						target.setSelectionRange(index, index);
					}, 0);
					break;
				case 1:
					let first_number = parseInt(value[0]);
					if (first_number === 2) {
						if (new_number >= 0 && new_number <= 3) {
							value =
								value.substring(0, index) +
								`${new_number}` +
								value.substring(index + 1);
						}
					} else {
						value =
							value.substring(0, index) +
							`${new_number}` +
							value.substring(index + 1);
					}

					index = index + 2;
					target.blur();
					window.setTimeout(function() {
						target.focus();
						target.setSelectionRange(index, index);
					}, 0);
					break;
				case 2:
					index = index + 1;
					target.blur();
					window.setTimeout(function() {
						target.focus();
						target.setSelectionRange(index, index);
					}, 0);
					break;
				case 3:
					if (new_number >= 0 && new_number <= 5) {
						value =
							value.substring(0, index) +
							`${new_number}` +
							value.substring(index + 1);
					}
					index = index + 1;
					target.blur();
					window.setTimeout(function() {
						target.focus();
						target.setSelectionRange(index, index);
					}, 0);
					break;
				case 4:
					value =
						value.substring(0, index) +
						`${new_number}` +
						value.substring(index + 1);
					index = index + 1;
					target.blur();
					window.setTimeout(function() {
						target.focus();
						target.setSelectionRange(index, index);
					}, 0);
					break;
				default:
					break;
			}
		}

		this.setState(
			(prev_state) => {
				// check form error
				let error_messages = { ...prev_state.error_messages };
				const { t } = this.props;
				let start_at = parseInt(
					property_name === "start_at"
						? value.replace(":", "")
						: this.state.form_data.message.interview.start_at.replace(":", ""),
					10,
				);
				let end_at = parseInt(
					property_name === "end_at"
						? value.replace(":", "")
						: this.state.form_data.message.interview.end_at.replace(":", ""),
					10,
				);
				let interview_time = [];
				if (start_at >= end_at)
					interview_time.push(
						t("general:end_time_must_be_greater_than_start_time"),
					);
				error_messages = {
					...error_messages,
					interview_time,
				};

				return {
					...prev_state,
					form_data: {
						...prev_state.form_data,
						message: {
							...prev_state.form_data.message,
							interview: {
								...prev_state.form_data.message.interview,
								[property_name]: value,
							},
						},
					},
					error_messages,
				};
			},
			() => this.checkIsSendableInterview(),
		);
	};

	handleInterviewSelectChange = (property_name, selected_option) => {
		if (selected_option) {
			this.setState(
				(prev_state) => {
					let dates = {},
						new_days_in_month = 0;
					let { options } = prev_state;

					switch (property_name) {
						case "years_selected_value":
							new_days_in_month = moment(
								`${selected_option.value}-${prev_state.form_data.message.interview.months_selected_value}`,
								"YYYY-MM",
							).daysInMonth();
							for (let i = 0; i < new_days_in_month; i++) {
								dates[i + 1] = `${i + 1}`;
							}
							options["dates"] = get_options("dates", dates);
							return {
								...prev_state,
								form_data: {
									...prev_state.form_data,
									message: {
										...prev_state.form_data.message,
										interview: {
											...prev_state.form_data.message.interview,
											years_selected_value: selected_option.value,
											dates_selected_value:
												prev_state.form_data.message.interview
													.dates_selected_value > new_days_in_month
													? new_days_in_month
													: prev_state.form_data.message.interview
															.dates_selected_value,
										},
									},
								},
								options,
							};
						case "months_selected_value":
							new_days_in_month = moment(
								`${prev_state.form_data.message.interview.years_selected_value}-${selected_option.value}`,
								"YYYY-MM",
							).daysInMonth();
							for (let i = 0; i < new_days_in_month; i++) {
								dates[i + 1] = `${i + 1}`;
							}
							options["dates"] = get_options("dates", dates);
							return {
								...prev_state,
								form_data: {
									...prev_state.form_data,
									message: {
										...prev_state.form_data.message,
										interview: {
											...prev_state.form_data.message.interview,
											months_selected_value: selected_option.value,
											dates_selected_value:
												prev_state.form_data.message.interview
													.dates_selected_value > new_days_in_month
													? new_days_in_month
													: prev_state.form_data.message.interview
															.dates_selected_value,
										},
									},
								},
								options,
							};
						case "dates_selected_value":
							return {
								...prev_state,
								form_data: {
									...prev_state.form_data,
									message: {
										...prev_state.form_data.message,
										interview: {
											...prev_state.form_data.message.interview,
											dates_selected_value: selected_option.value,
										},
									},
								},
							};
						default:
							return { ...prev_state };
					}
				},
				() => this.checkIsSendableInterview(),
			);
		}
	};

	// submit form

	submitChatForm = (e) => {
		e.preventDefault();
		const { form_data, is_sendable, active_tab } = this.state;
		if (is_sendable) {
			this.setState(
				{
					sending: true,
				},
				() => {
					this.checkIsSendable();
					this.messagesScrollToBottom();
				},
			);
			const { active_job_id, active_application_id } = this.state;
			let formData = new FormData();
			formData.append(`${active_tab}[content]`, form_data[active_tab].content);
			if (active_tab === "message" && form_data[active_tab].attachment.file)
				formData.append(
					`${active_tab}[attachment_attributes][asset]`,
					form_data[active_tab].attachment.file,
				);
			let options = {
				method: "POST",
				url: config.api_url(
					`/jobs/${active_job_id}/job_applications/${active_application_id}/${active_tab}s`,
				),
				headers: headers.getItemsFromLocalStorage("multipart/form-data"),
				data: formData,
				json: true,
			};
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					this.setState(
						(prev_state) => ({
							...prev_state,
							form_data: {
								...prev_state.form_data,
								message: {
									...prev_state.form_data.message,
									content: "",
									attachment: {},
								},
								comment: {
									...prev_state.form_data.comment,
									content: "",
								},
							},
							loading_conversations: true,
							sending: false,
							error_messages: {},
						}),
						() => {
							if (active_tab === "message") this.fetchMessages();
							if (active_tab === "comment") this.fetchComments();
						},
					);
				})
				.catch((error) => {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					this.setState((prev_state) => ({
						...prev_state,
						sending: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				});
		}
	};

	submitInterviewForm = (e) => {
		// console.log(this.state);
		e.preventDefault();
		const { is_sendable_interview } = this.state;
		if (is_sendable_interview) {
			this.setState(
				(prev_state) => ({
					...prev_state,
					form_data: {
						...prev_state.form_data,
						message: {
							...prev_state.form_data.message,
							interview: {
								...prev_state.form_data.message.interview,
								open: false,
							},
						},
					},
					sending_interview: true,
				}),
				() => {
					this.checkIsSendableInterview();
					this.messagesScrollToBottom();
				},
			);
			const { form_data, active_job_id, active_application_id } = this.state;
			let formData = new FormData();
			if (contain_not_only_whitespace(form_data.message.interview.title))
				formData.append(
					`message[interview_attributes][name]`,
					form_data.message.interview.title,
				);
			if (contain_not_only_whitespace(form_data.message.interview.location))
				formData.append(
					`message[interview_attributes][location]`,
					form_data.message.interview.location,
				);
			if (contain_not_only_whitespace(form_data.message.interview.start_at))
				formData.append(
					`message[interview_attributes][started_at]`,
					`${form_data.message.interview.years_selected_value}-${form_data.message.interview.months_selected_value}-${form_data.message.interview.dates_selected_value}T${form_data.message.interview.start_at}:00.000+08:00`,
				);
			if (contain_not_only_whitespace(form_data.message.interview.end_at))
				formData.append(
					`message[interview_attributes][ended_at]`,
					`${form_data.message.interview.years_selected_value}-${form_data.message.interview.months_selected_value}-${form_data.message.interview.dates_selected_value}T${form_data.message.interview.end_at}:00.000+08:00`,
				);

			let options = {
				method: "POST",
				url: config.api_url(
					`/jobs/${active_job_id}/job_applications/${active_application_id}/messages`,
				),
				headers: headers.getItemsFromLocalStorage("multipart/form-data"),
				data: formData,
				json: true,
			};
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					this.setState(
						(prev_state) => ({
							...prev_state,
							form_data: {
								...prev_state.form_data,
								message: {
									...prev_state.form_data.message,
									interview: {
										...prev_state.form_data.message.interview,
										title: "",
										location: "",
									},
								},
							},
							loading_conversations: true,
							sending_interview: false,
							error_messages: {},
						}),
						() => this.fetchMessages(),
					);
				})
				.catch((error) => {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					this.setState((prev_state) => ({
						...prev_state,
						sending_interview: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				});
		}
	};

	// change state

	confirmApplication(e) {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: true,
				action_type: "confirm",
				next_state: "confirmed",
			},
		}));
	}

	rejectApplication(e) {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: true,
				action_type: "reject",
				next_state: "rejected",
			},
		}));
	}

	cancelChangeState(e) {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: false,
				action_type: "",
				next_state: "",
			},
		}));
	}

	submitChangeState = () => {
		const { sending, sending_interview, sending_change_state } = this.state;

		if (!sending && !sending_interview && !sending_change_state) {
			this.setState({ sending_change_state: true });

			const {
				active_company_id,
				active_job_id,
				active_application_id,
				change_state_message_modal,
			} = this.state;
			let formData = new FormData();
			formData.append(
				`job_application[employer_state]`,
				change_state_message_modal.next_state,
			);
			let options = {
				method: "PUT",
				url: config.api_url(
					`/employers/${active_company_id}/jobs/${active_job_id}/job_applications/${active_application_id}`,
				),
				headers: headers.getItemsFromLocalStorage("multipart/form-data"),
				data: formData,
				json: true,
			};
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					this.setState(
						(prev_state) => ({
							...prev_state,
							change_state_message_modal: {
								open: false,
								action_type: "",
								next_state: "",
							},
							error_messages: {},
						}),
						() => {
							this.fetchData();
							const internal_uri = new InternalUri();
							setTimeout(() => {
								this.props.history.push(
									`${internal_uri.formatted_dashboard_applications_page_path()}?refresh=true`,
								);
								this.props.updateEmployerMessageModal(false, -1, -1, -1);
								// window.location.reload();
							}, 2500);
						},
					);
				})
				.catch((error) => {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					this.setState((prev_state) => ({
						...prev_state,
						change_state_message_modal: {
							open: false,
							action_type: "",
							next_state: "",
						},
						sending_change_state: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				});
		}
	};

	// message scroll to bottom

	messagesScrollToBottom() {
		const element = document.querySelector(".messages-wrapper");
		if (element) smoothly_scroll_to(element, element.scrollHeight, 200);
	}

	// attachment

	handleAttachment = (e) => {
		e.preventDefault();
		let file = e.target.files[0];
		let file_type = "";
		if (file) {
			e.target.value = null;
			if (file.type === "application/msword" || file.type === ".doc")
				file_type = "DOC";
			if (file.type === "application/pdf" || file.type === ".pdf")
				file_type = "PDF";
			if (
				file.type ===
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
				file.type === ".docx"
			)
				file_type = "DOCX";
			this.setState(
				(prev_state) => ({
					...prev_state,
					form_data: {
						...prev_state.form_data,
						message: {
							...prev_state.form_data.message,
							attachment: {
								file,
								file_type,
							},
						},
					},
				}),
				() => {
					this.messagesScrollToBottom();
					this.checkIsSendable();
				},
			);
		}
	};

	deleteAttachment = (e) => {
		e.preventDefault();
		this.setState(
			(prev_state) => ({
				...prev_state,
				form_data: {
					...prev_state.form_data,
					message: {
						...prev_state.form_data.message,
						attachment: {},
					},
				},
			}),
			() => this.checkIsSendable(),
		);
	};

	handleDownload = (e, url, filename) => {
		e.preventDefault();
		download_file(url, filename);
	};

	//check sendable

	checkIsSendable() {
		const {
			form_data,
			sending,
			sending_interview,
			sending_change_state,
			active_tab,
		} = this.state;
		switch (active_tab) {
			case "message":
				if (
					!sending &&
					!sending_interview &&
					!sending_change_state &&
					(contain_not_only_whitespace(form_data[active_tab].content) ||
						form_data[active_tab].attachment.file)
				) {
					this.setState({ is_sendable: true });
				} else {
					this.setState({ is_sendable: false });
				}
				break;
			case "comment":
				if (
					!sending &&
					contain_not_only_whitespace(form_data[active_tab].content)
				) {
					this.setState({ is_sendable: true });
				} else {
					this.setState({ is_sendable: false });
				}
				break;
			default:
		}
	}

	checkIsSendableInterview() {
		const {
			form_data,
			sending_interview,
			sending,
			sending_change_state,
			error_messages,
		} = this.state;
		if (
			!sending &&
			!sending_interview &&
			!sending_change_state &&
			form_data.message.interview.open &&
			contain_not_only_whitespace(form_data.message.interview.title) &&
			!(error_messages.interview_time && error_messages.interview_time.length)
		) {
			this.setState({ is_sendable_interview: true });
		} else {
			this.setState({ is_sendable_interview: false });
		}
	}

	// load more

	handleMessagesWrapperScroll = (e) => {
		e.preventDefault();
		const element = document.querySelector(".messages-wrapper");
		if (element && element.scrollTop === 0) this.loadMoreData();
	};

	loadMoreData() {
		const {
			active_job_id,
			active_application_id,
			active_tab,
			loading_more,
			active_user_id,
		} = this.state;

		if (this.paginator[active_tab].next_page && !loading_more) {
			this.setState({ loading_more: true });
			let options = {
				method: "GET",
				url: config.api_url(
					`/jobs/${active_job_id}/job_applications/${active_application_id}/${active_tab}s?page=${this.paginator[active_tab].next_page}`,
				),
				headers: headers.getItemsFromLocalStorage(),
				json: true,
			};
			let conversations = [];
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					conversations = response.data.collection.reverse();
					this.paginator[active_tab] = response.data.paginator;

					if (active_tab === "message") {
						for (let i = 0; i < conversations.length; i++) {
							conversations[i] = new Message(conversations[i]);
						}
						let message_ids = [];
						for (let i = 0; i < conversations.length; i++) {
							message_ids.push(conversations[i].id);
						}
						let data = {
							reading: {
								readable_type: "Job::JobApplication::Message",
								readable_ids: message_ids,
							},
						};
						options = {
							method: "POST",
							url: config.api_url(`/users/${active_user_id}/readings`),
							headers: headers.getItemsFromLocalStorage(),
							data: data,
							json: true,
						};
						return axios(options);
					}

					if (active_tab === "comment") {
						for (let i = 0; i < conversations.length; i++) {
							conversations[i] = new Comment(conversations[i]);
						}
						let comment_ids = [];
						for (let i = 0; i < conversations.length; i++) {
							comment_ids.push(conversations[i].id);
						}
						let data = {
							reading: {
								readable_type: "Job::JobApplication::Comment",
								readable_ids: comment_ids,
							},
						};
						options = {
							method: "POST",
							url: config.api_url(`/users/${active_user_id}/readings`),
							headers: headers.getItemsFromLocalStorage(),
							data: data,
							json: true,
						};
						return axios(options);
					}

					if (active_tab === "referrer_note") {
						for (let i = 0; i < conversations.length; i++) {
							conversations[i] = new ReferrerNote(conversations[i]);
						}
						let referrer_note_ids = [];
						for (let i = 0; i < conversations.length; i++) {
							referrer_note_ids.push(conversations[i].id);
						}
						let data = {
							reading: {
								readable_type: "Job::JobApplication::ReferrerNote",
								readable_ids: referrer_note_ids,
							},
						};
						options = {
							method: "POST",
							url: config.api_url(`/users/${active_user_id}/readings`),
							headers: headers.getItemsFromLocalStorage(),
							data: data,
							json: true,
						};
						return axios(options);
					}
				})
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);

					this.setState((prev_state) => ({
						...prev_state,
						error_messages: {},
					}));
				})
				.catch((error) => {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					this.setState((prev_state) => ({
						...prev_state,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				})
				.then(() => {
					this.setState(
						(prev_state) => ({
							...prev_state,
							[active_tab + `s`]: [
								...conversations,
								...prev_state[active_tab + `s`],
							],
							loading_more: false,
						}),
						() => {
							let wrappers = document.querySelectorAll(".messages-wrapper");
							let elements = document.querySelectorAll(".message-wrapper");
							wrappers[0].scrollTop =
								elements[conversations.length - 1].offsetTop -
								wrappers[0].offsetTop;
						},
					);
				});
		}
	}

	handleActionEvents = (e) => {
		if (e.target.hasAttribute("data-action-event-url"))
			this.sendActionEvents(e.target.dataset.actionEventUrl);
	};

	sendActionEvents = (url) => {
		const { active_company_id, active_application_id } = this.state;
		let options = {
			method: "POST",
			url: config.api_url(
				`/employers/${active_company_id}/job_applications/${active_application_id}/action_events`,
			),
			headers: headers.getItemsFromLocalStorage(),
			data: { url },
			json: true,
		};
		axios(options).then((response) => {
			headers.updateItemsToLocalStorage(response.headers);
		});
	};

	addActionEventTriggerToContent = (content) => {
		let temp = document.createElement("div");
		temp.innerHTML = content;
		let atags = temp.querySelectorAll("a.link");
		for (let i = 0; i < atags.length; i++) {
			atags[i].dataset.actionEventUrl = atags[i].href;
		}
		content = temp.outerHTML;
		return content.slice(5, -6);
	};

	// fetch new application state

	render() {
		// console.log("employer modal state&props", this.state, this.props);
		const {
			handleInputChange,
			handleTabChange,
			closeModal,
			submitChatForm,
			updateInterviewBox,
			handleInterviewInputChange,
			handleInterviewTimeKeyDown,
			handleInterviewSelectChange,
			submitInterviewForm,
		} = this;
		const {
			handleAttachment,
			deleteAttachment,
			handleDownload,
			handleMessagesWrapperScroll,
		} = this;
		const { addActionEventTriggerToContent } = this;
		const { paginator } = this;
		const { site_state } = this.props;
		const {
			active_tab_index,
			active_user_id,
			unread,
			error_messages,
		} = this.state;
		const {
			active_company,
			application,
			messages,
			comments,
			referrer_notes,
			options,
			form_data,
			is_sendable,
			is_sendable_interview,
		} = this.state;
		const {
			loading,
			sending,
			sending_interview,
			sending_change_state,
			loading_more,
			loading_conversations,
		} = this.state;
		const {
			confirmApplication,
			rejectApplication,
			cancelChangeState,
			submitChangeState,
		} = this;
		const { change_state_message_modal } = this.state;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		return (
			<CSSTransitionGroup
				component="div"
				transitionName="modal-wrapper-slide"
				transitionEnterTimeout={300}
				transitionLeaveTimeout={300}>
				{site_state.employer_message_modal.open ? (
					<div className="modal-wrapper employer-message-modal-wrapper">
						<CSSTransitionGroup
							component="div"
							className="modal-inner-wrapper"
							transitionName="modal-slide"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={300}>
							<div className="modal-bg" onClick={(e) => closeModal(e)} />
							{!loading ? (
								<div
									className={`modal employer-message-modal ${
										application.has_referrer() ? "with-referred-wrapper" : ""
									}`}>
									<div className="btn-close" onClick={(e) => closeModal(e)} />
									<div className="modal-row">
										<div className="modal-col left">
											<div className="avatar">
												<div
													className="image-wrapper"
													style={{
														backgroundImage: `url(${application.applier.formatted_avatar_applying_url()})`,
													}}
												/>
											</div>

											<div className="candidate-info-wrapper">
												<h6 className="sub-title">{t("candidate_info")}</h6>
												<h4>{application.formatted_applier_name()}</h4>
												<p>{application.formatted_applier_email()}</p>
												<p>{application.formatted_applier_phone()}</p>
												{application.has_attachment_asset_url() ||
												application.has_portfolio_link_url() ? (
													<div className="buttons-wrapper">
														{application.has_attachment_asset_url() ? (
															<a
																href={application.attachment.formatted_url()}
																className="btn btn-smaller btn-flat btn-hollow"
																target="_blank"
																rel="noopener noreferrer"
																data-action-event-url={application.attachment.formatted_url()}>
																<h5>{t("general:resume___cv_file")}</h5>
															</a>
														) : (
															""
														)}
														{/*{*/}
														{/*(application.has_attachment_asset_url()) ?*/}
														{/*<Link*/}
														{/*to={`${internal_uri.formatted_talents_talent_page_path(application.attachment.id)}?origin_type=job_application&hash=${application.attachment.secret_token}&employer_id=${active_company_id}&job_id=${active_job_id}&job_application_id=${active_application_id}`}*/}
														{/*className="btn btn-smaller btn-flat btn-hollow"*/}
														{/*>*/}
														{/*<h5>{t('general:resume___cv_file')}</h5>*/}
														{/*</Link> : ''*/}
														{/*}*/}
														{application.has_portfolio_link_url() ? (
															<a
																href={application.formatted_portfolio_link_url()}
																className="btn btn-smaller btn-flat btn-hollow"
																target="_blank"
																rel="noopener noreferrer"
																data-action-event-url={application.formatted_portfolio_link_url()}>
																<h5>{t("general:reference_link")}</h5>
															</a>
														) : (
															""
														)}
													</div>
												) : (
													""
												)}
											</div>

											<div className="match-wrapper">
												<h6 className="sub-title">
													{t("talent_match")}
													<div className="tooltip-wrapper">
														<FaRegQuestionCircle className="icon react-icon" />
														<div className="tooltip long-text">
															<h6
																dangerouslySetInnerHTML={{
																	__html: t(
																		"assessment_for_suitable_candidates_to_invite_to_interviews__the_specific_service_for_hire_and_hire_plus_plan_",
																	),
																}}
															/>
														</div>
													</div>
												</h6>
												{active_company.is_matchable() ? (
													<p>{t(`states:${application.matching_state_key}`)}</p>
												) : (
													<p>
														<img src={icon_lock} className="lock" alt="" />
													</p>
												)}
											</div>

											<h6 className="sub-title">{t("general:applied_at")}</h6>
											<p>
												{application.formatted_applied_at()}
												<span className="description">
													({application.formatted_applied_at_past_time(t)})
												</span>
											</p>

											{application.employer_state_changeable() &&
											!sending_change_state ? (
												<div className="buttons-wrapper">
													<button
														className="btn btn-flat btn-hollow btn-success btn-confirm"
														onClick={(e) => confirmApplication(e)}>
														<h5>{t("confirm_hire")}</h5>
													</button>
													<button
														className="btn btn-flat btn-hollow btn-danger btn-reject"
														onClick={(e) => rejectApplication(e)}>
														<h5>{t("general:reject")}</h5>
													</button>
												</div>
											) : (
												<div className="buttons-wrapper">
													{application.is_confirmed() ? (
														<button
															onClick={(e) => e.preventDefault()}
															className={`btn btn-flat btn-hollow btn-success btn-confirm disabled btn-with-icon`}>
															<h5>
																<IconOk />
																{t("general:confirmed")}
															</h5>
														</button>
													) : (
														<button
															onClick={(e) => e.preventDefault()}
															className={`btn btn-flat btn-hollow btn-success btn-confirm disabled`}>
															<h5>{t("confirm_hire")}</h5>
														</button>
													)}
													{application.is_rejected() ? (
														<button
															onClick={(e) => e.preventDefault()}
															className={`btn btn-flat btn-hollow btn-danger btn-reject disabled btn-with-icon`}>
															<h5>
																<IconOk />
																{t("general:rejected")}
															</h5>
														</button>
													) : (
														<button
															onClick={(e) => e.preventDefault()}
															className={`btn btn-flat btn-hollow btn-danger btn-reject disabled`}>
															<h5>{t("general:reject")}</h5>
														</button>
													)}
												</div>
											)}
											<button
												className="btn btn-flat btn-fill close"
												onClick={(e) => closeModal(e)}>
												<h5>{t("general:close")}</h5>
											</button>
										</div>
										<div className="modal-col right">
											<h4 className="modal-title">
												{`${application.formatted_state(t)} - `}
												<Link
													to={internal_uri.formatted_frontend_job_page_path(
														application.job.id,
														application.job.slug,
													)}
													target="_blank"
													rel="noopener noreferrer">
													{application.job.formatted_title()}
													<img src={icon_redirect} alt="" />
												</Link>
											</h4>
											{application.has_referrer() ? (
												<div className="referred-wrapper">
													<button
														className="referred"
														onClick={(e) => handleTabChange(2)}>
														<span className="badge badge-green-reward">
															{t("general:referred")}
														</span>
														<p>
															<Interpolate
																i18nKey="by__"
																useDangerouslySetInnerHTML={true}
																referrer_name={application.formatted_referrer_user_name()}
															/>
														</p>
													</button>
												</div>
											) : (
												""
											)}
											{error_messages.full_message ? (
												<h6 className="message error-message">
													{error_messages.full_message}
												</h6>
											) : (
												""
											)}
											<div className="messages-box">
												<Tabs
													className="react-tabs tabs"
													selectedIndex={active_tab_index}
													onSelect={(index) => handleTabChange(index)}>
													<div className="messages-box-header">
														<TabList>
															<Tab>
																<h5>
																	{t("general:messages")}
																	{unread.messages ? (
																		<span className="counting-block counting-block-red">
																			{unread.messages}
																		</span>
																	) : (
																		""
																	)}
																</h5>
															</Tab>
															<Tab>
																<h5>
																	{t("general:comments")}
																	{unread.comments ? (
																		<span className="counting-block counting-block-red">
																			{unread.comments}
																		</span>
																	) : (
																		""
																	)}
																</h5>
															</Tab>
															<Tab>
																<h5>
																	{t("general:ref__letter")}
																	{unread.referrer_notes ? (
																		<span className="counting-block counting-block-red">
																			{unread.referrer_notes}
																		</span>
																	) : (
																		""
																	)}
																</h5>
															</Tab>
														</TabList>
														<div className="tabs-tl">
															<div className="select-wrapper">
																<Select
																	className="drop-down drop-down-larger drop-down-grey"
																	clearable={false}
																	searchable={false}
																	value={active_tab_index}
																	onChange={(value) =>
																		handleTabChange(value.value)
																	}
																	options={[
																		{
																			value: 0,
																			label: t("general:messages"),
																			count: unread.messages,
																		},
																		{
																			value: 1,
																			label: t("general:comments"),
																			count: unread.comments,
																		},
																		{
																			value: 2,
																			label: t("general:reference_letter"),
																			count: unread.referral_notes,
																		},
																	]}
																	valueRenderer={(option) => (
																		<div>
																			{option.label}
																			{option.count ? (
																				<span className="counting-block counting-block-red">
																					{option.count}
																				</span>
																			) : (
																				""
																			)}
																		</div>
																	)}
																	optionRenderer={(option) => (
																		<div>
																			{option.label}
																			{option.count ? (
																				<span className="counting-block counting-block-red">
																					{option.count}
																				</span>
																			) : (
																				""
																			)}
																		</div>
																	)}
																/>
															</div>
														</div>
													</div>
													<div className="messages-box-body">
														<TabPanel>
															<div
																className={`messages-wrapper${
																	form_data.message.attachment.file
																		? " has-pending-file"
																		: ""
																}`}
																onScroll={(e) =>
																	handleMessagesWrapperScroll(e)
																}>
																<div className="messages">
																	{loading_more ? (
																		<div className="loading-more-message-wrapper">
																			<p className="loading-more-message">{`${t(
																				"general:loading",
																			)}...`}</p>
																		</div>
																	) : (
																		""
																	)}
																	{!paginator.message.next_page &&
																	paginator.message.total_pages >= 2 ? (
																		<div className="loading-more-message-wrapper">
																			<p className="loading-more-message">
																				{t("general:no_more_messages")}
																			</p>
																		</div>
																	) : (
																		""
																	)}
																	{messages.length > 0
																		? messages.map((message, i) => {
																				const message_content = addActionEventTriggerToContent(
																					linkify(message.content).replace(
																						/\n/g,
																						`<br />`,
																					),
																				);
																				if (message.sender_type === "system") {
																					if (
																						message.employer_state ===
																						"reverted"
																					)
																						// reverted
																						return (
																							<div
																								className="message-wrapper"
																								key={i}>
																								<div className="message message-center">
																									<div className="content">
																										<h5>
																											{t(
																												"system_messages:revertedText",
																											)}
																										</h5>
																									</div>
																									<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																								</div>
																							</div>
																						);
																					else if (message.integrated_state)
																						// reset
																						return (
																							<div
																								className="message-wrapper"
																								key={i}>
																								<div className="message message-center">
																									<div className="content">
																										<h4>
																											{t(
																												"system_messages:resetText",
																												{
																													state: t(
																														`states:${message.integrated_state}`,
																													),
																												},
																											)}
																										</h4>
																										<p
																											dangerouslySetInnerHTML={{
																												__html: message_content,
																											}}
																										/>
																									</div>
																									<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																								</div>
																							</div>
																						);
																					else
																						return (
																							<div
																								className="message-wrapper"
																								key={i}>
																								<div className="message message-center">
																									<div className="content">
																										<p
																											dangerouslySetInnerHTML={{
																												__html: message_content,
																											}}
																										/>
																									</div>
																									<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																								</div>
																							</div>
																						);
																				} else if (
																					message.applier_state ===
																					"initialized"
																				) {
																					if (
																						contain_not_only_whitespace(
																							message.content,
																						)
																					)
																						return (
																							<div key={i}>
																								<div className="message-wrapper">
																									<div className="message message-center">
																										<div className="content">
																											<h5>
																												<Interpolate
																													i18nKey="system_messages:appliedText"
																													useDangerouslySetInnerHTML={
																														true
																													}
																													applier_name={application.formatted_applier_name()}
																													job_title={application.job.formatted_title()}
																												/>
																											</h5>
																										</div>
																										<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																									</div>
																								</div>
																								{message.sender.id ===
																								active_user_id ? (
																									<div className="message-wrapper">
																										<div className="message message-right">
																											<div className="content">
																												<p
																													dangerouslySetInnerHTML={{
																														__html: message_content,
																													}}
																												/>
																											</div>
																											<h6 className="time">
																												{message.formatted_created_at()}
																											</h6>
																										</div>
																									</div>
																								) : (
																									<div className="message-wrapper">
																										<div className="message message-left">
																											{message.is_applier(
																												application.applier.id,
																											) ? (
																												<div className="user">
																													<div className="avatar">
																														<div
																															className="image-wrapper"
																															style={{
																																backgroundImage: `url(${message.sender.formatted_avatar_conversation_url()})`,
																															}}
																														/>
																													</div>
																													<h5>
																														{application.formatted_applier_name()}
																													</h5>
																												</div>
																											) : (
																												<div className="user">
																													<div className="avatar">
																														<div
																															className="image-wrapper"
																															style={{
																																backgroundImage: `url(${
																																	application.job.has_employer()
																																		? application.job.employer.formatted_logo_toolbar_url()
																																		: application.job.formatted_external_employer_logo_url()
																																})`,
																															}}
																														/>
																													</div>
																													<h5>
																														{message.formatted_employer_name()}
																													</h5>
																												</div>
																											)}
																											<div className="content">
																												<p
																													dangerouslySetInnerHTML={{
																														__html: message_content,
																													}}
																												/>
																											</div>
																											<h6 className="time">
																												{message.formatted_created_at()}
																											</h6>
																										</div>
																									</div>
																								)}
																							</div>
																						);
																					else
																						return (
																							<div
																								className="message-wrapper"
																								key={i}>
																								<div className="message message-center">
																									<div className="content">
																										<h5>
																											<Interpolate
																												i18nKey="system_messages:appliedText"
																												useDangerouslySetInnerHTML={
																													true
																												}
																												applier_name={application.formatted_applier_name()}
																												job_title={application.job.formatted_title()}
																											/>
																										</h5>
																									</div>
																									<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																								</div>
																							</div>
																						);
																				} else if (
																					message.applier_state === "cancelled"
																				)
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-center red">
																								<div className="content">
																									<h5>
																										<Interpolate
																											i18nKey="system_messages:cancelledText"
																											useDangerouslySetInnerHTML={
																												true
																											}
																											applier_name={application.formatted_applier_name()}
																										/>
																									</h5>
																								</div>
																								<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																							</div>
																						</div>
																					);
																				else if (
																					message.applier_state === "reported"
																				)
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-center green">
																								<div className="content">
																									<h5>
																										<Interpolate
																											i18nKey="system_messages:claimedByApplierText"
																											useDangerouslySetInnerHTML={
																												true
																											}
																											applier_name={application.formatted_applier_name()}
																										/>
																									</h5>
																								</div>
																								<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																							</div>
																						</div>
																					);
																				else if (
																					message.employer_state === "rejected"
																				)
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-center red">
																								<div className="content">
																									<h5>
																										<Interpolate
																											i18nKey="system_messages:rejectedText"
																											useDangerouslySetInnerHTML={
																												true
																											}
																											employer_name={application.job.formatted_employer_name()}
																										/>
																									</h5>
																								</div>
																								<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																							</div>
																						</div>
																					);
																				else if (
																					message.employer_state === "confirmed"
																				)
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-center green">
																								<div className="content">
																									<h5>
																										<Interpolate
																											i18nKey="system_messages:confirmedText"
																											useDangerouslySetInnerHTML={
																												true
																											}
																											employer_name={application.job.formatted_employer_name()}
																										/>
																									</h5>
																								</div>
																								<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																							</div>
																						</div>
																					);
																				else if (
																					message.referrer_state === "claimed"
																				)
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-center green-reward">
																								<div className="content">
																									<h5>
																										<Interpolate
																											i18nKey="system_messages:claimedByReferralText"
																											useDangerouslySetInnerHTML={
																												true
																											}
																											referrer_name={application.formatted_referrer_user_name()}
																											applier_name={application.formatted_applier_name()}
																										/>
																									</h5>
																								</div>
																								<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																							</div>
																						</div>
																					);
																				else if (
																					message.sender.id === active_user_id
																				) {
																					if (message.interview) {
																						return (
																							<div
																								className="message-wrapper"
																								key={i}>
																								<div className="message message-right">
																									<div className="content interview">
																										<h6>{t("title")}</h6>
																										<p>
																											{message.interview.name}
																										</p>

																										<h6>{t("location")}</h6>
																										<p>
																											{
																												message.interview
																													.location
																											}
																										</p>

																										<h6>{t("date")}</h6>
																										<p>
																											{message.interview
																												.started_at
																												? moment(
																														message.interview
																															.started_at,
																												  ).format("YYYY/MM/DD")
																												: ""}
																										</p>

																										<h6>{t("time")}</h6>
																										<p>
																											{message.interview
																												.started_at
																												? moment(
																														message.interview
																															.started_at,
																												  ).format("HH:mm")
																												: ""}{" "}
																											{t("to")}{" "}
																											{message.interview
																												.ended_at
																												? moment(
																														message.interview
																															.ended_at,
																												  ).format("HH:mm")
																												: ""}
																										</p>

																										<a
																											className="btn btn-smaller btn-flat btn-hollow btn-google-calendar"
																											href={
																												`https://www.google.com/calendar/event?action=TEMPLATE` +
																												`&dates=${moment(
																													message.interview
																														.started_at,
																												)
																													.utc()
																													.format(
																														"YYYYMMDDTHHmmss",
																													)}Z/${moment(
																													message.interview
																														.ended_at,
																												)
																													.utc()
																													.format(
																														"YYYYMMDDTHHmmss",
																													)}Z` +
																												`&text=${message.interview.name}` +
																												`&location=${message.interview.location}` +
																												`&details=`
																											}
																											target="_blank"
																											rel="noopener noreferrer">
																											<span />
																											<h5>Google calendar</h5>
																										</a>
																									</div>
																									<h6 className="time">
																										{message.formatted_created_at()}
																									</h6>
																								</div>
																							</div>
																						);
																					} else {
																						if (message.has_attachment()) {
																							const device_detector = new DeviceDetector();
																							return (
																								<div
																									className="message-wrapper"
																									key={i}>
																									<div className="message message-right">
																										<div className="content attachment">
																											<p
																												dangerouslySetInnerHTML={{
																													__html: message_content,
																												}}
																											/>
																											<h6>{t("file_name")}</h6>
																											{device_detector.is_mobile_or_tablet() ? (
																												<a
																													className="file-wrapper"
																													href={message.attachment.formatted_url()}
																													target="_blank"
																													rel="noopener noreferrer"
																													data-action-event-url={message.attachment.formatted_url()}>
																													<span className="file-type">
																														<h5>
																															{message.attachment.formatted_file_type()}
																														</h5>
																													</span>
																													<span className="file-name">
																														<h5>
																															{message.attachment.formatted_filename()}
																														</h5>
																														<span className="btn-download" />
																													</span>
																												</a>
																											) : (
																												<button
																													className="file-wrapper"
																													onClick={(e) =>
																														handleDownload(
																															e,
																															message.attachment.formatted_url(),
																															message.attachment.formatted_filename(),
																														)
																													}
																													data-action-event-url={message.attachment.formatted_url()}>
																													<span className="file-type">
																														<h5>
																															{message.attachment.formatted_file_type()}
																														</h5>
																													</span>
																													<span className="file-name">
																														<h5>
																															{message.attachment.formatted_filename()}
																														</h5>
																														<span className="btn-download" />
																													</span>
																												</button>
																											)}
																										</div>
																										<h6 className="time">
																											{message.formatted_created_at()}
																										</h6>
																									</div>
																								</div>
																							);
																						} else {
																							return (
																								<div
																									className="message-wrapper"
																									key={i}>
																									<div className="message message-right">
																										<div className="content">
																											<p
																												dangerouslySetInnerHTML={{
																													__html: message_content,
																												}}
																											/>
																										</div>
																										<h6 className="time">
																											{message.formatted_created_at()}
																										</h6>
																									</div>
																								</div>
																							);
																						}
																					}
																				} else {
																					if (message.interview) {
																						return (
																							<div
																								className="message-wrapper"
																								key={i}>
																								<div className="message message-left">
																									{message.is_applier(
																										application.applier.id,
																									) ? (
																										<div className="user">
																											<div className="avatar">
																												<div
																													className="image-wrapper"
																													style={{
																														backgroundImage: `url(${message.sender.formatted_avatar_conversation_url()})`,
																													}}
																												/>
																											</div>
																											<h5>
																												{application.formatted_applier_name()}
																											</h5>
																										</div>
																									) : (
																										<div className="user">
																											<div className="avatar">
																												<div
																													className="image-wrapper"
																													style={{
																														backgroundImage: `url(${
																															application.job.has_employer()
																																? application.job.employer.formatted_logo_toolbar_url()
																																: application.job.formatted_external_employer_logo_url()
																														})`,
																													}}
																												/>
																											</div>
																											<h5>
																												{message.formatted_employer_name()}
																											</h5>
																										</div>
																									)}
																									<div className="content interview">
																										<h6>{t("title")}</h6>
																										<p>
																											{message.interview.name}
																										</p>

																										<h6>{t("location")}</h6>
																										<p>
																											{
																												message.interview
																													.location
																											}
																										</p>

																										<h6>{t("date")}</h6>
																										<p>
																											{message.interview
																												.started_at
																												? moment(
																														message.interview
																															.started_at,
																												  ).format("YYYY/MM/DD")
																												: ""}
																										</p>

																										<h6>{t("time")}</h6>
																										<p>
																											{message.interview
																												.started_at
																												? moment(
																														message.interview
																															.started_at,
																												  ).format("HH:mm")
																												: ""}{" "}
																											{t("to")}{" "}
																											{message.interview
																												.ended_at
																												? moment(
																														message.interview
																															.ended_at,
																												  ).format("HH:mm")
																												: ""}
																										</p>

																										<a
																											className="btn btn-smaller btn-flat btn-hollow btn-google-calendar"
																											href={
																												`https://www.google.com/calendar/event?action=TEMPLATE` +
																												`&dates=${moment(
																													message.interview
																														.started_at,
																												)
																													.utc()
																													.format(
																														"YYYYMMDDTHHmmss",
																													)}Z/${moment(
																													message.interview
																														.ended_at,
																												)
																													.utc()
																													.format(
																														"YYYYMMDDTHHmmss",
																													)}Z` +
																												`&text=${message.interview.name}` +
																												`&location=${message.interview.location}` +
																												`&details=`
																											}
																											target="_blank"
																											rel="noopener noreferrer">
																											<span />
																											<h5>Google calendar</h5>
																										</a>
																									</div>
																									<h6 className="time">
																										{message.formatted_created_at()}
																									</h6>
																								</div>
																							</div>
																						);
																					} else {
																						if (message.has_attachment()) {
																							const device_detector = new DeviceDetector();
																							return (
																								<div
																									className="message-wrapper"
																									key={i}>
																									<div className="message message-left">
																										{message.is_applier(
																											application.applier.id,
																										) ? (
																											<div className="user">
																												<div className="avatar">
																													<div
																														className="image-wrapper"
																														style={{
																															backgroundImage: `url(${message.sender.formatted_avatar_conversation_url()})`,
																														}}
																													/>
																												</div>
																												<h5>
																													{application.formatted_applier_name()}
																												</h5>
																											</div>
																										) : (
																											<div className="user">
																												<div className="avatar">
																													<div
																														className="image-wrapper"
																														style={{
																															backgroundImage: `url(${
																																application.job.has_employer()
																																	? application.job.employer.formatted_logo_toolbar_url()
																																	: application.job.formatted_external_employer_logo_url()
																															})`,
																														}}
																													/>
																												</div>
																												<h5>
																													{message.formatted_employer_name()}
																												</h5>
																											</div>
																										)}
																										<div className="content attachment">
																											<p
																												dangerouslySetInnerHTML={{
																													__html: message_content,
																												}}
																											/>
																											<h6>{t("file_name")}</h6>
																											{device_detector.is_mobile_or_tablet() ? (
																												<a
																													className="file-wrapper"
																													href={message.attachment.formatted_url()}
																													target="_blank"
																													rel="noopener noreferrer"
																													data-action-event-url={message.attachment.formatted_url()}>
																													<span className="file-type">
																														<h5>
																															{message.attachment.formatted_file_type()}
																														</h5>
																													</span>
																													<span className="file-name">
																														<h5>
																															{message.attachment.formatted_filename()}
																														</h5>
																														<span className="btn-download" />
																													</span>
																												</a>
																											) : (
																												<a
																													className="file-wrapper"
																													href={message.attachment.formatted_url()}
																													onClick={(e) =>
																														handleDownload(
																															e,
																															message.attachment.formatted_url(),
																															message.attachment.formatted_filename(),
																														)
																													}
																													data-action-event-url={message.attachment.formatted_url()}>
																													<span className="file-type">
																														<h5>
																															{message.attachment.formatted_file_type()}
																														</h5>
																													</span>
																													<span className="file-name">
																														<h5>
																															{message.attachment.formatted_filename()}
																														</h5>
																														<span className="btn-download" />
																													</span>
																												</a>
																											)}
																										</div>
																										<h6 className="time">
																											{message.formatted_created_at()}
																										</h6>
																									</div>
																								</div>
																							);
																						} else {
																							return (
																								<div
																									className="message-wrapper"
																									key={i}>
																									<div className="message message-left">
																										{message.is_applier(
																											application.applier.id,
																										) ? (
																											<div className="user">
																												<div className="avatar">
																													<div
																														className="image-wrapper"
																														style={{
																															backgroundImage: `url(${message.sender.formatted_avatar_conversation_url()})`,
																														}}
																													/>
																												</div>
																												<h5>
																													{application.formatted_applier_name()}
																												</h5>
																											</div>
																										) : (
																											<div className="user">
																												<div className="avatar">
																													<div
																														className="image-wrapper"
																														style={{
																															backgroundImage: `url(${
																																application.job.has_employer()
																																	? application.job.employer.formatted_logo_toolbar_url()
																																	: application.job.formatted_external_employer_logo_url()
																															})`,
																														}}
																													/>
																												</div>
																												<h5>
																													{message.formatted_employer_name()}
																												</h5>
																											</div>
																										)}
																										<div className="content">
																											<p
																												dangerouslySetInnerHTML={{
																													__html: message_content,
																												}}
																											/>
																										</div>
																										<h6 className="time">
																											{message.formatted_created_at()}
																										</h6>
																									</div>
																								</div>
																							);
																						}
																					}
																				}
																		  })
																		: ""}
																	<CSSTransitionGroup
																		component="div"
																		transitionName="pending-message"
																		transitionEnterTimeout={200}
																		transitionLeaveTimeout={200}>
																		{form_data.message.interview.open ? (
																			<div className="message-wrapper">
																				<div className="message message-right">
																					<div className="content interview">
																						<form>
																							<label htmlFor="title">
																								<h6>{t("title")}</h6>
																							</label>
																							<div className="form-row">
																								<input
																									className="input"
																									type="text"
																									value={
																										form_data.message.interview
																											.title
																									}
																									onChange={(e) =>
																										handleInterviewInputChange(
																											e,
																											`title`,
																										)
																									}
																									id="title"
																									// placeholder="eg. https://meet.jobs"
																								/>
																							</div>

																							<label htmlFor="location">
																								<h6>{t("location")}</h6>
																							</label>
																							<div className="form-row">
																								<input
																									className="input"
																									type="text"
																									value={
																										form_data.message.interview
																											.location
																									}
																									onChange={(e) =>
																										handleInterviewInputChange(
																											e,
																											`location`,
																										)
																									}
																									id="location"
																									// placeholder="eg. https://meet.jobs"
																								/>
																							</div>

																							<label htmlFor="">
																								<h6>{t("date")}</h6>
																							</label>
																							<div className="form-row form-row-3">
																								<div className="form-col">
																									<div className="select-wrapper">
																										<Select
																											className="drop-down drop-down-smaller drop-down-grey"
																											clearable={false}
																											value={
																												form_data.message
																													.interview
																													.dates_selected_value
																											}
																											onChange={(value) =>
																												handleInterviewSelectChange(
																													"dates_selected_value",
																													value,
																												)
																											}
																											options={options.dates}
																										/>
																									</div>
																								</div>
																								<div className="form-col">
																									<div className="select-wrapper">
																										<Select
																											className="drop-down drop-down-smaller drop-down-grey"
																											clearable={false}
																											value={
																												form_data.message
																													.interview
																													.months_selected_value
																											}
																											onChange={(value) =>
																												handleInterviewSelectChange(
																													"months_selected_value",
																													value,
																												)
																											}
																											options={options.months}
																										/>
																									</div>
																								</div>
																								<div className="form-col">
																									<div className="select-wrapper">
																										<Select
																											className="drop-down drop-down-smaller drop-down-grey"
																											clearable={false}
																											value={
																												form_data.message
																													.interview
																													.years_selected_value
																											}
																											onChange={(value) =>
																												handleInterviewSelectChange(
																													"years_selected_value",
																													value,
																												)
																											}
																											options={options.years}
																										/>
																									</div>
																								</div>
																							</div>

																							<label htmlFor="">
																								<h6>{t("time")}</h6>
																							</label>
																							<div className="form-row form-row-2 form-row-dire-col">
																								<div className="form-col">
																									<div className="form-row form-row-3 form-time">
																										<div className="form-col">
																											<input
																												className={`input ${
																													error_messages.interview_time &&
																													error_messages
																														.interview_time
																														.length
																														? "input-danger"
																														: ""
																												}`}
																												type="time"
																												value={
																													form_data.message
																														.interview.start_at
																												}
																												name="startTime"
																												id="startTime"
																												onChange={(e) => {
																													this.newInterViewTimeHandler(
																														e,
																														"start_at",
																													);
																												}}
																											/>
																											{/* <input
																												className={`input ${
																													error_messages.interview_time &&
																													error_messages
																														.interview_time
																														.length
																														? "input-danger"
																														: ""
																												}`}
																												type="text"
																												value={
																													form_data.message
																														.interview.start_at
																												}
																												onChange={(e) => {}}
																												onKeyDown={(e) => {
																													// handleInterviewTimeKeyDown(
																													// 	e,
																													// 	"start_at",
																													// );
																												}}
																											/> */}
																										</div>
																										<div className="form-col">
																											<h6>{t("to")}</h6>
																										</div>
																										<div className="form-col">
																											<input
																												className={`input ${
																													error_messages.interview_time &&
																													error_messages
																														.interview_time
																														.length
																														? "input-danger"
																														: ""
																												}`}
																												type="time"
																												value={
																													form_data.message
																														.interview.end_at
																												}
																												name="endTime"
																												id="endTime"
																												onChange={(e) => {
																													this.newInterViewTimeHandler(
																														e,
																														"end_at",
																													);
																												}}
																											/>
																											{/* <input
																												className={`input ${
																													error_messages.interview_time &&
																													error_messages
																														.interview_time
																														.length
																														? "input-danger"
																														: ""
																												}`}
																												type="text"
																												value={
																													form_data.message
																														.interview.end_at
																												}
																												onChange={(e) => {}}
																												onKeyDown={(e) =>
																													handleInterviewTimeKeyDown(
																														e,
																														"end_at",
																													)
																												}
																											/> */}
																										</div>
																									</div>
																								</div>
																								<div className="form-col">
																									{error_messages.interview_time &&
																									error_messages.interview_time
																										.length ? (
																										<h6 className="message error-message">
																											{error_messages.interview_time.join(
																												", ",
																											)}
																										</h6>
																									) : (
																										""
																									)}
																								</div>
																							</div>

																							<div className="buttons-wrapper">
																								<button
																									className="btn btn-smaller btn-flat btn-ghost"
																									onClick={(e) =>
																										updateInterviewBox(e)
																									}>
																									<h5>{t("general:cancel")}</h5>
																								</button>
																								<button
																									className={`btn btn-smaller btn-flat btn-fill ${
																										is_sendable_interview
																											? ""
																											: "disabled"
																									}`}
																									onClick={(e) =>
																										submitInterviewForm(e)
																									}>
																									<h5>{t("general:save")}</h5>
																								</button>
																							</div>
																						</form>
																					</div>
																				</div>
																			</div>
																		) : (
																			""
																		)}
																	</CSSTransitionGroup>
																	{sending ||
																	sending_interview ||
																	loading_conversations ? (
																		<div className="message-wrapper">
																			<div className="message message-right">
																				<div className="content sending">
																					<MeetJobsLoader />
																				</div>
																				<h6 className="time">
																					{t("general:sending")}...
																				</h6>
																			</div>
																		</div>
																	) : (
																		""
																	)}
																</div>
															</div>
															<div className="chat-form">
																<form>
																	<div className="input-wrapper">
																		{form_data.message.attachment.file ? (
																			<div className="file-wrapper">
																				<span className="file-type">
																					<h5>
																						{
																							form_data.message.attachment
																								.file_type
																						}
																					</h5>
																				</span>
																				<span className="file-name">
																					<h5>
																						{
																							form_data.message.attachment.file
																								.name
																						}
																					</h5>
																					<div
																						className="btn-close"
																						onClick={(e) => deleteAttachment(e)}
																					/>
																				</span>
																			</div>
																		) : (
																			""
																		)}
																		<Textarea
																			className="textarea"
																			minRows={1}
																			maxRows={8}
																			placeholder={t("type_something___")}
																			value={form_data.message.content}
																			onChange={(e) => handleInputChange(e)}
																			// onKeyPress={e => handleKeyPress(e)}
																		/>
																		<button
																			className={`btn btn-smaller btn-flat ${
																				is_sendable ? "btn-fill" : "btn-ghost"
																			}`}
																			onClick={(e) => submitChatForm(e)}>
																			<h5>{t("general:send")}</h5>
																		</button>
																	</div>
																	<div className="widget-wrapper">
																		<ul>
																			<li>
																				<a
																					href="/#"
																					onClick={(e) => e.stopPropagation()}>
																					<input
																						id="upload-attachment"
																						type="file"
																						accept={
																							`.pdf, ` +
																							`.doc, ` +
																							`.docx, ` +
																							`application/pdf, ` +
																							`application/msword, ` +
																							`application/vnd.openxmlformats-officedocument.wordprocessingml.document`
																						}
																						onChange={(e) =>
																							handleAttachment(e)
																						}
																					/>
																					<label htmlFor="upload-attachment">
																						<img src={icon_attachment} alt="" />
																						<h5>{t("general:add_file")}</h5>
																					</label>
																				</a>
																			</li>
																			<li>
																				<a
																					href="/#"
																					onClick={(e) =>
																						updateInterviewBox(e)
																					}>
																					<img src={icon_calendar} alt="" />
																					<h5>{t("invite_to_an_interview")}</h5>
																				</a>
																			</li>
																		</ul>
																	</div>
																</form>
															</div>
														</TabPanel>
														<TabPanel>
															<div
																className="messages-wrapper comments"
																onScroll={(e) =>
																	handleMessagesWrapperScroll(e)
																}>
																<div className="messages">
																	{loading_more ? (
																		<div className="loading-more-message-wrapper">
																			<p className="loading-more-message">{`${t(
																				"general:loading",
																			)}...`}</p>
																		</div>
																	) : (
																		""
																	)}
																	{!paginator.comment.next_page &&
																	paginator.comment.total_pages >= 2 ? (
																		<div className="loading-more-message-wrapper">
																			<p className="loading-more-message">
																				{t("general:no_more_comments")}
																			</p>
																		</div>
																	) : (
																		""
																	)}
																	{!paginator.comment.next_page ? (
																		<div className="message-wrapper">
																			<div className="message message-center">
																				<div className="content">
																					<h5>
																						{t(
																							"internal_comments_will_not_be_seen_by_candidates",
																						)}
																					</h5>
																				</div>
																			</div>
																		</div>
																	) : (
																		""
																	)}
																	{comments.length > 0
																		? comments.map((comment, i) => {
																				const comment_content = linkify(
																					comment.content,
																				).replace(/\n/g, `<br />`);
																				if (
																					comment.sender.id === active_user_id
																				) {
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-right">
																								<div className="content">
																									<p
																										dangerouslySetInnerHTML={{
																											__html: comment_content,
																										}}
																									/>
																								</div>
																								<h6 className="time">
																									{comment.formatted_created_at()}
																								</h6>
																							</div>
																						</div>
																					);
																				} else {
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-left">
																								<div className="user">
																									<div className="avatar">
																										<div
																											className="image-wrapper"
																											style={{
																												backgroundImage: `url(${comment.sender.formatted_avatar_conversation_url()})`,
																											}}
																										/>
																									</div>
																									<h5>
																										{comment.formatted_sender_name()}
																									</h5>
																								</div>
																								<div className="content">
																									<p
																										dangerouslySetInnerHTML={{
																											__html: comment_content,
																										}}
																									/>
																								</div>
																								<h6 className="time">
																									{comment.formatted_created_at()}
																								</h6>
																							</div>
																						</div>
																					);
																				}
																		  })
																		: ""}
																	{sending || loading_conversations ? (
																		<div className="message-wrapper">
																			<div className="message message-right">
																				<div className="content sending">
																					<MeetJobsLoader />
																				</div>
																				<h6 className="time">
																					{t("general:sending")}...
																				</h6>
																			</div>
																		</div>
																	) : (
																		""
																	)}
																</div>
															</div>
															<div className="chat-form">
																<form>
																	<div className="input-wrapper">
																		<Textarea
																			className="textarea"
																			minRows={1}
																			maxRows={8}
																			placeholder={t("type_something___")}
																			value={form_data.comment.content}
																			onChange={(e) => handleInputChange(e)}
																			// onKeyPress={ e => handleKeyPress(e)}
																		/>
																		<button
																			className={`btn btn-smaller btn-flat ${
																				is_sendable ? "btn-fill" : "btn-ghost"
																			}`}
																			onClick={(e) => submitChatForm(e)}>
																			<h5>{t("general:send")}</h5>
																		</button>
																	</div>
																</form>
															</div>
														</TabPanel>
														<TabPanel>
															<div
																className="messages-wrapper notes"
																onScroll={(e) =>
																	handleMessagesWrapperScroll(e)
																}>
																<div className="messages">
																	{loading_more ? (
																		<div className="loading-more-message-wrapper">
																			<p className="loading-more-message">{`${t(
																				"general:loading",
																			)}...`}</p>
																		</div>
																	) : (
																		""
																	)}
																	{!paginator.referrer_note.next_page &&
																	paginator.referrer_note.total_pages >= 2 ? (
																		<div className="loading-more-message-wrapper">
																			<p className="loading-more-message">
																				{t("general:no_more_reference_letter")}
																			</p>
																		</div>
																	) : (
																		""
																	)}
																	{referrer_notes.length > 0 ? (
																		referrer_notes.map((referrer_note, i) => {
																			const referrer_note_content = linkify(
																				referrer_note.content,
																			).replace(/\n/g, `<br />`);
																			return (
																				<div
																					className="message-wrapper"
																					key={i}>
																					<div className="message message-left">
																						<div className="user">
																							<div className="avatar">
																								<div
																									className="image-wrapper"
																									style={{
																										backgroundImage: `url(${referrer_note.formatted_sender_avatar_url()})`,
																									}}
																								/>
																							</div>
																							<h5>
																								{referrer_note.formatted_sender_name()}
																							</h5>
																						</div>
																						<div className="content">
																							<h6>{t("email")}</h6>
																							<p>
																								{referrer_note.formatted_referrer_email()}
																							</p>
																							<h6>{t("phone")}</h6>
																							<p>
																								{referrer_note.formatted_referrer_phone()}
																							</p>
																							<p
																								dangerouslySetInnerHTML={{
																									__html: referrer_note_content,
																								}}
																							/>
																						</div>
																						<h6 className="time">
																							{referrer_note.formatted_created_at()}
																						</h6>
																					</div>
																				</div>
																			);
																		})
																	) : (
																		<div className="message-wrapper">
																			<div className="message message-center">
																				<div className="content">
																					<h5>{t("no_reference_letter")}</h5>
																				</div>
																			</div>
																		</div>
																	)}
																</div>
															</div>
														</TabPanel>
													</div>
												</Tabs>
											</div>
											<button
												className="btn btn-flat btn-fill close"
												onClick={(e) => closeModal(e)}>
												<h5>{t("general:close")}</h5>
											</button>
										</div>
									</div>
								</div>
							) : (
								""
							)}
						</CSSTransitionGroup>

						<CSSTransitionGroup
							component="div"
							transitionName="modal-wrapper-with-modal-slide"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={300}>
							{change_state_message_modal.open &&
							change_state_message_modal.action_type === "confirm" ? (
								<div className="modal-wrapper double-confirm-modal-wrapper">
									<div className="modal-inner-wrapper">
										<div className="modal-bg" />
										<div className="modal double-confirm-modal with-banner">
											<div
												className="image-wrapper"
												style={{
													backgroundImage: `url(${modal_banner_confirm})`,
												}}
											/>
											<div className="content">
												<h4 className="modal-title">
													{t("confirmTitle", {
														applier_name: application.formatted_applier_name(),
													})}
												</h4>
												{error_messages.full_message ? (
													<h6 className="message error-message">
														{error_messages.full_message}
													</h6>
												) : (
													""
												)}
												<p className="text-align-center">
													{t("confirmDescription")}
												</p>
												<div className="buttons-wrapper">
													<button
														className="btn btn-larger btn-flat btn-ghost"
														onClick={(e) => cancelChangeState(e)}>
														<h5>{t("general:cancel")}</h5>
													</button>
													{!sending && !sending_change_state ? (
														<button
															className="btn btn-larger btn-flat btn-fill btn-success"
															onClick={(e) => submitChangeState(e)}>
															<h5>{t("general:confirm")}</h5>
														</button>
													) : (
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-larger btn-flat btn-fill disabled">
															<h5>{t("general:confirm")}</h5>
														</button>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								""
							)}
						</CSSTransitionGroup>

						<CSSTransitionGroup
							component="div"
							transitionName="modal-wrapper-with-modal-slide"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={300}>
							{change_state_message_modal.open &&
							change_state_message_modal.action_type === "reject" ? (
								<div className="modal-wrapper thank-you-letter-modal-wrapper">
									<div className="modal-inner-wrapper">
										<div className="modal-bg" />
										<div className="modal thank-you-letter-modal">
											<h4 className="modal-title">
												{t("send_a_thank_you_letter")}
											</h4>
											{error_messages.full_message ? (
												<h6 className="message error-message">
													{error_messages.full_message}
												</h6>
											) : (
												""
											)}
											<p className="warning">{t("thank_you_letter_warning")}</p>
											<p
												dangerouslySetInnerHTML={{
													__html: t("thank_you_letter_content", {
														candidate_name: application.formatted_applier_name(),
														employer_name: application.job.formatted_employer_name(),
														job_title: application.job.formatted_title(),
													}),
												}}
											/>
											<div className="buttons-wrapper">
												<button
													className="btn btn-larger btn-flat btn-ghost"
													onClick={(e) => cancelChangeState(e)}>
													<h5>{t("general:cancel")}</h5>
												</button>
												{!sending && !sending_change_state ? (
													<button
														className="btn btn-larger btn-flat btn-fill"
														onClick={(e) => submitChangeState(e)}>
														<h5>{t("send")}</h5>
													</button>
												) : (
													<button
														onClick={(e) => e.preventDefault()}
														className="btn btn-larger btn-flat btn-fill disabled">
														<h5>{t("send")}</h5>
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							) : (
								""
							)}
						</CSSTransitionGroup>
					</div>
				) : (
					""
				)}
			</CSSTransitionGroup>
		);
	}
}

export default withNamespaces([
	"dashboard_modals",
	"system_messages",
	"general",
	"states",
])(EmployerMessageModal);
