import React from 'react';
import { withNamespaces } from "react-i18next";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import top_banner_bg from "../../../../assets/images/about_us_banner.jpg";
import meet_jobs_logo from "../../../../assets/images/logo_single.svg";
import icon_linkedin from '../../../../assets/images/icon/icon_linkedin.svg';
import member_avatar_1 from "../../../../assets/images/member_avatar_1.jpg";
// import member_avatar_2 from "../../../../assets/images/member_avatar_2.jpg";
import news_banner_1 from "../../../../assets/images/news_banner_inside.png";
import news_banner_2 from "../../../../assets/images/news_banner_prnewswire.png";
import news_banner_3 from "../../../../assets/images/news_banner_channel_newsasia.png";
import news_banner_4 from "../../../../assets/images/news_banner_alphacamp.png";
import news_banner_5 from "../../../../assets/images/news_banner_techorange.png";
import InternalUri from "../../../models/internal_uri";
import {UseReferralUrlWrapper} from "../../../containers/general";

const AboutUsPage = props => {

    const { t } = props;
    const internal_uri = new InternalUri();

    return (
        <div className="page-about-us content-wrapper">
            <UseReferralUrlWrapper/>
            <Helmet>
                <title>{t('general:about_us')}｜Meet.jobs</title>
            </Helmet>
            <section
                className="top-banner"
                style={{backgroundImage: `url(${top_banner_bg}), linear-gradient(293deg, #1cdfb3, #2768f6 65%, #2768f6)`}}
            >
                <div className="container">
                    <h2 className="title">{t('general:about_us')}</h2>
                </div>
            </section>
            <section className="a-brief-history">
                <div className="container">

                    <div className="content">
                        <img src={meet_jobs_logo} alt="meet.jobs" />
                        <h2 className="title">{t('meet_jobs_is_')}</h2>
                        <div dangerouslySetInnerHTML={{__html: t('meet_jobs_is_content')}} />
                        <h2 className="title">{t('sharing__trust__and_diversity')}</h2>
                        <div dangerouslySetInnerHTML={{__html: t('sharing__trust__and_diversity_content')}} />
                        <div className="sub-content">
                            <h3 className="sub-title">{t('sharing___community_referral')}</h3>
                            <div dangerouslySetInnerHTML={{__html: t('sharing___community_referral_content')}} />
                            <h3 className="sub-title">{t('trust___information_transparency')}</h3>
                            <div dangerouslySetInnerHTML={{__html: t('trust___information_transparency_content')}} />
                            <h3 className="sub-title">{t('diversity___international_focused')}</h3>
                            <div dangerouslySetInnerHTML={{__html: t('diversity___international_focused_content')}} />
                        </div>

                    </div>
                </div>
            </section>
            <section className="our-team">
                <div className="container">
                    <h2 className="title">{t('our_team')}</h2>
                    <div className="member-cards">
                        <div className="member-card-wrapper">
                            <div className="member-card">
                                <div className="avatar">
                                    <div
                                        className='image-wrapper'
                                        style={{backgroundImage: `url(${member_avatar_1})`}}
                                    />
                                    <a
                                        href="https://www.linkedin.com/in/reinhardtlin/"
                                        className="cover-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img className="linkedin-logo" src={icon_linkedin} alt=""/>
                                    </a>
                                </div>
                                <h3 className="name">Reinhardt Lin</h3>
                                <h4 className="title">CAO, Founder</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="news-and-press">
                <div className="container">
                    <h2 className="title">{t('news_and_press')}</h2>
                    <div className="news-cards">
                        <a
                            className="news-card card"
                            href="https://www.inside.com.tw/article/13569-mit-jobs"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="banner">
                                <div
                                    className='image-wrapper'
                                    style={{backgroundImage: `url(${news_banner_1})`}}
                                />
                            </div>
                            <div className="content">
                                <h6 className="date">16, July, 2018</h6>
                                <h3>Gogoro、阿里巴巴也愛用！跨境求職新創「mit.Jobs」天使輪募得 1200 萬</h3>
                                <span className="link"><h6>繼續閱讀</h6></span>
                            </div>
                        </a>
                        <a
                            className="news-card card"
                            href="https://hk.prnasia.com/story/226816-2.shtml"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="banner">
                                <div
                                    className='image-wrapper'
                                    style={{backgroundImage: `url(${news_banner_2})`}}
                                />
                            </div>
                            <div className="content">
                                <h6 className="date">24, Oct, 2018</h6>
                                <h3>一次面談Gogoro、趨勢科技等多家企業mit.Jobs邀人才和老闆「快速面試」</h3>
                                <span className="link"><h6>繼續閱讀</h6></span>
                            </div>
                        </a>
                        <a
                            className="news-card card"
                            href="https://www.channelnewsasia.com/news/video-on-demand/start-up-s5/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="banner">
                                <div
                                    className='image-wrapper'
                                    style={{backgroundImage: `url(${news_banner_3})`}}
                                />
                            </div>
                            <div className="content">
                                <h6 className="date">23, Dec, 2017</h6>
                                <h3>Singapore’s Channel NewsAsia Start-Up Season 5</h3>
                                <span className="link"><h6>繼續閱讀</h6></span>
                            </div>
                        </a>
                        <a
                            className="news-card card"
                            href="https://blog.alphacamp.co/2015/06/30/mit-jobs-changyu-lin/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="banner">
                                <div
                                    className='image-wrapper'
                                    style={{backgroundImage: `url(${news_banner_4})`}}
                                />
                            </div>
                            <div className="content">
                                <h6 className="date">30, Jun, 2015</h6>
                                <h3>讓全世界幫你找工作 — mit.Jobs 創辦人林昶聿專訪</h3>
                                <span className="link"><h6>繼續閱讀</h6></span>
                            </div>
                        </a>
                        <a
                            className="news-card card"
                            href="http://buzzorange.com/techorange/2015/04/21/mitjobs/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="banner">
                                <div
                                    className='image-wrapper'
                                    style={{backgroundImage: `url(${news_banner_5})`}}
                                />
                            </div>
                            <div className="content">
                                <h6 className="date">21, Apr, 2015</h6>
                                <h3>不是當台勞！mit.Jobs 帶你出國工作</h3>
                                <span className="link"><h6>繼續閱讀</h6></span>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <section className="call-to-action">
                <div className="container">
                    <h2 className="title">{t('call_to_action_content')}</h2>
                    <Link
                        className="btn btn-larger btn-flat btn-hollow"
                        to={internal_uri.formatted_frontend_employer_page_path(444, 'mitjobs')}
                    >
                        <h5>{t('join_us')}</h5>
                    </Link>
                </div>
            </section>
        </div>
    );
};

export default withNamespaces(['frontend_about_us_page', 'general'])(AboutUsPage);
