import React from 'react';

const StepProcessBar = props => {
    const { steps } = props;

    let steps_to_render = [];
    for(let i=0; i<steps.total_number; i++) {
        const number = i+1;
        if(i!==0) steps_to_render.push(<div key={2*i-1} className={`line ${(steps.active>=number) ? 'active' : ''}`} />);
        const is_actived = (steps.active===number);
        const is_selectable = !is_actived && steps[number].visited;
        steps_to_render.push(
            <div
                key={2*i}
                className={`step-process step-process-${number} ${(is_actived) ? 'active' : (is_selectable) ? 'selectable' : ''}`}
                onClick={ e => { if(is_selectable) props.handleActiveStep(e, number)  }}
                data-event-category={(steps[number].eventCategory && is_selectable) ? steps[number].eventCategory : ''}
            >
                <p>{number}</p>
                <h6 dangerouslySetInnerHTML={{__html: steps[number].label}} />
            </div>
        );
    }

    return (
        <div className="step-process-bar">
            {steps_to_render}
        </div>
    );
};

StepProcessBar.defaultProps = {
    handleActiveStep: f=>f,
    steps: {
        active: 1,
        total_number: 1,
        1: {
            visited: true,
            label: 'default',
        }
    },
};

export default StepProcessBar;