import React from "react";
import { Route, Switch } from "react-router-dom";
import SignUpPage from "./pages/SignUpPage.jsx";
import LogInPage from "./pages/LogInPage.jsx";
import PasswordChangedPage from "./pages/PasswordChangedPage.jsx";
import CreateEmployerPage from "./pages/CreateEmployerPage.jsx";
import NoMatchPage from "./pages/NoMatchPage.jsx";
import Error404Page from "./pages/Error404Page.jsx";
import Error500Page from "./pages/Error500Page";
import PrivacyAndTermsPage from "./pages/PrivacyAndTermsPage";
import AboutUsPage from "./pages/AboutUsPage.jsx";

import { HeaderWrapper, FooterWrapper } from "./../../containers/general.jsx";
import {
	ConfirmDonePageWrapper,
	ConfirmPageWrapper,
	EntranceModalWrapper,
	ApplyModalWrapper,
	InviteToApplyModalWrapper,
	EmployerPageWrapper,
	IndexPageWrapper,
	JobsPageWrapper,
	JobPageWrapper,
	JobInvitationPageWrapper,
	ReferPageWrapper,
	ReferralLandingPageWrapper,
	EmployerLandingPageWrapper,
	ResetPasswordPageWrapper,
	ForgotPasswordPageWrapper,
	RedirectFromFacebookWrapper,
	RedirectFromSignInMailWrapper,
	RedirectFromForgotPasswordMailWrapper,
	CallbackHandlerWrapper,
	AdCoverModalWrapper,
} from "../../containers/frontend.jsx";
import CornerModals from "./modals/CornerModals";

class Frontend extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			validating: true,
		};
	}

	componentDidMount() {
		this.setState({ validating: this.props.validating });
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		if (next_props.validating !== this.props.validating) {
			this.setState({ validating: next_props.validating });
		}
	}

	render() {
		const { match, location } = this.props;
		const { validating } = this.state;
		// console.log("validating", validating);
		return (
			<div className="Frontend">
				<HeaderWrapper validating={validating} />
				<Switch>
					<Route
						path={`${match.url}/redirect-from-facebook`}
						render={() => <RedirectFromFacebookWrapper />}
					/>
					<Route
						path={`${match.url}/redirect-from-sign-in-email`}
						render={() => <RedirectFromSignInMailWrapper />}
					/>
					<Route
						path={`${match.url}/redirect-from-forgot-password-email`}
						render={() => <RedirectFromForgotPasswordMailWrapper />}
					/>
					<Route
						path={`${match.url}/callback-handler`}
						render={() => <CallbackHandlerWrapper />}
					/>

					{/* 登入時不該看到的畫面，登入狀態則導到首頁 */}
					<Route
						path={`${match.url}/confirm`}
						render={() =>
							!validating ? (
								<ConfirmPageWrapper />
							) : (
								<div className="content-wrapper" />
							)
						}
					/>
					<Route
						path={`${match.url}/forgot-password`}
						render={() =>
							!validating ? (
								<ForgotPasswordPageWrapper />
							) : (
								<div className="content-wrapper" />
							)
						}
					/>
					<Route
						path={`${match.url}/sign-up`}
						render={() =>
							!validating ? <SignUpPage /> : <div className="content-wrapper" />
						}
					/>
					<Route
						path={`${match.url}/log-in`}
						render={() =>
							!validating ? <LogInPage /> : <div className="content-wrapper" />
						}
					/>

					{/* 未登入時不該看到的畫面，未登入時導到登入頁 */}
					<Route
						path={`${match.url}/confirm-done`}
						render={() =>
							!validating ? (
								<ConfirmDonePageWrapper />
							) : (
								<div className="content-wrapper" />
							)
						}
					/>
					<Route
						path={`${match.url}/reset-password`}
						render={() =>
							!validating ? (
								<ResetPasswordPageWrapper />
							) : (
								<div className="content-wrapper" />
							)
						}
					/>
					<Route
						path={`${match.url}/create-employer`}
						render={() =>
							!validating ? (
								<CreateEmployerPage />
							) : (
								<div className="content-wrapper" />
							)
						}
					/>

					<Route
						exact
						path={`${match.url}/`}
						render={() => <IndexPageWrapper />}
					/>
					<Route
						path={`${match.url}/employers/:id_slug`}
						render={() => <EmployerPageWrapper />}
					/>
					<Route
						path={`${match.url}/jobs/:id_slug`}
						render={() => <JobPageWrapper />}
					/>
					<Route
						path={`${match.url}/job-invitations/:id_slug`}
						render={() => <JobInvitationPageWrapper />}
					/>
					<Route
						path={`${match.url}/jobs`}
						render={() => <JobsPageWrapper />}
					/>
					{/* TODO: 調整 validating 的畫面 */}
					<Route
						path={`${match.url}/refer/:referral_type/:referral_type_id`}
						render={() =>
							!validating ? (
								<ReferPageWrapper />
							) : (
								<div className="content-wrapper with-gradient higher" />
							)
						}
					/>
					<Route
						path={`${match.url}/referrer-landing`}
						render={() => <ReferralLandingPageWrapper />}
					/>
					<Route
						path={`${match.url}/employer-landing`}
						render={() => <EmployerLandingPageWrapper />}
					/>
					<Route
						path={`${match.url}/password-changed`}
						render={() => <PasswordChangedPage />}
					/>
					<Route
						path={`${match.url}/privacy-and-terms`}
						render={() => <PrivacyAndTermsPage />}
					/>
					<Route
						path={`${match.url}/about-us`}
						render={() => <AboutUsPage />}
					/>
					<Route path={`${match.url}/404`} render={() => <Error404Page />} />
					<Route path={`${match.url}/500`} render={() => <Error500Page />} />
					<Route render={() => <NoMatchPage />} />
				</Switch>
				{location.pathname !== `${match.url}/404` &&
				location.pathname !== `${match.url}/500` ? (
					<FooterWrapper />
				) : (
					""
				)}
				<EntranceModalWrapper />
				<ApplyModalWrapper />
				<InviteToApplyModalWrapper />
				<AdCoverModalWrapper />
				<CornerModals validating={validating} />
			</div>
		);
	}
}

export default Frontend;
