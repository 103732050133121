export default {
    meet_jobs_is_: "Meet.jobs is...",
    meet_jobs_is_content:
        `<p>Meet.jobs is a social job platform focusing on international (and professional) talents and career opportunities.</p>` +
        `<p>The mission of Meet.jobs is to help everyone to maximize one’s own value in the world!</p>`,
    sharing__trust__and_diversity: "Sharing, Trust, and Diversity",
    sharing__trust__and_diversity_content:
        `<p>Meet.jobs believes in Sharing, Trust, and Diversity, reflected on the characters of Community Referral, Information Transparency, and International-Focused.</p>`,

    sharing___community_referral: "Sharing - Community Referral",
    sharing___community_referral_content:
        `<p>If you get hired through Meet.jobs, you will receive US$100 “Onboard Reward”. In return, you could share your cross-cultural working and living experience, not to help Meet.jobs, but other job seekers.</p>` +
        `<p>You can also refer jobs to your friends as well as searching for yourself. Meet.jobs provides “Referral Reward”, about 2% to 4% of the annual salary, to the “referrer” recommending candidates get hired successfully!</p>`,
    trust___information_transparency: "Trust - Information Transparency",
    trust___information_transparency_content:
        `<p>Comprehensive information is not only fair to candidates, but also enhancing recruitment efficiency of employers. Meet.jobs insists all job vacancies on the platform, not only “negotiable”, must have salary ranges.</p>` +
        `<p>Trust is the core of recruitment. With open information, talents shall swiftly select job opportunities; therefore employers can get only effective applications.</p>`,

    diversity___international_focused: "Diversity - International-Focused",
    diversity___international_focused_content:
        `<p>Meet.jobs believes in people’s talents and experiences. There must be a job for you, and you alone, to maximize your own value in the world!</p>` +
        `<p>Open-minded, continuous learning, tolerance, accepting difference, these are not only soft skills, but also what Meet.jobs’ core competitiveness.</p>`,

    our_team: "Our team",
    news_and_press: "News and Press",
    call_to_action_content: "Let’s together to build a totally different experience of international job seeking and recruitment with Meet.jobs!",
    join_us: "Join us",
};