import Abstract from './abstract';
import moment from "moment/moment";
import User from "./user.js";
import Attachment from "./attachment";

class Message extends Abstract {

    constructor(attrs) {
        super(attrs);

        let raw_sender = attrs.sender;
        if(raw_sender) {
            delete attrs['sender'];
            this._build_association({
                name:  'sender',
                klass: User,
                attrs: raw_sender
            });
        }

        let raw_attachment = attrs.attachment;
        if(raw_attachment) {
            delete attrs['attachment'];
            this._build_association({
                name:  'attachment',
                klass: Attachment,
                attrs: raw_attachment
            });
        }
    };

    is_applier(applier_id) {
        if(applier_id === this.sender.id) return true;
        return false;
    };

    formatted_employer_name() {
        let sender_name = this.employer_name;
        if(this.membership_title) sender_name += ' ' + this.membership_title;
        else sender_name += ' ' + this.sender.formatted_name();
        return sender_name;
    };

    has_attachment() {
      if(!this.attachment) return false;
      return true;
    };

    formatted_created_at() {
        if(!this.created_at) return '';
        return moment(this.created_at).format('YYYY/MM/DD HH:mm');
    };
}

export default Message;
