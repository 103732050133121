import React from 'react';
import {withNamespaces} from "react-i18next";
import { CSSTransitionGroup } from 'react-transition-group'

class ReadReferenceNotesModal extends React.Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            active_application: {},
        };
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(next_props.site_state.read_reference_notes_modal.open !== this.props.site_state.read_reference_notes_modal.open) {
            if(next_props.site_state.read_reference_notes_modal.open) {
                this.setState({
                    active_application: next_props.site_state.read_reference_notes_modal.application,
                });
            }
        }
    };

    closeModal = e => {
        e.preventDefault();
        this.props.updateReadReferenceNotesModal(false, {});
    };

    render() {

        const { active_application } = this.state;
        const { closeModal } = this;
        const { site_state } = this.props;
        const { t } = this.props;

        return (
            <CSSTransitionGroup
                component="div"
                transitionName="modal-wrapper-with-modal-slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {(site_state.read_reference_notes_modal.open) ?
                    <div className="modal-wrapper read-reference-notes-modal-wrapper">
                        <div className="modal-inner-wrapper">
                            <div className="modal-bg" onClick={ e => closeModal(e) } />
                            <div className="modal read-reference-notes-modal">
                                <div className="btn-close" onClick={ e => closeModal(e) } />
                                <h4 className="modal-title">{t('read_reference_letter')}</h4>
                                <h6 className="candidate-name">{active_application.formatted_applier_name()}</h6>
                                <h5>{t('name')}</h5>
                                <p>{active_application.formatted_referrer_name()}</p>
                                <h5>{t('email')}</h5>
                                <p>{active_application.formatted_referrer_email()}</p>
                                <h5>{t('phone_number')}</h5>
                                <p>{active_application.formatted_referrer_phone()}</p>
                                <h5>{t('general:reference_letter')}</h5>
                                <p
                                    className="referral-letter from-editor"
                                    dangerouslySetInnerHTML={{
                                        __html: active_application.formatted_referrer_content()
                                    }}
                                />
                                <div className="buttons-wrapper">
                                    <button className="btn btn-flat btn-fill" onClick={ e => closeModal(e) }>
                                        <h5>{t('general:ok')}</h5>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : ''
                }
            </CSSTransitionGroup>
        );
    };
}

export default withNamespaces(['userpanel_modals', 'general'])(ReadReferenceNotesModal);
