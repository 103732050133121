import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from './Lightbox';
import ModalVideo from 'react-modal-video';

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.gotoImage = this.gotoImage.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.openModal = this.openModal.bind(this);
        this.state = {
            lightboxIsOpen: false,
            isOpen: false,
            currentImage: 0,
        };
    }

    openLightbox(index, e) {
        e.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({ currentImage: this.state.currentImage - 1 });
    }

    gotoNext() {
        this.setState({ currentImage: this.state.currentImage + 1 });
    }

    gotoImage(index) {
        this.setState({ currentImage: index });
    }

    handleClickImage() {
        if (this.state.currentImage === this.props.images.length - 1) return;
        this.gotoNext();
    }

    openModal() {
        this.setState({ isOpen: true });
    }

    render () {
        const { images, youtube_vcode, showThumbnails } = this.props;
        const { lightboxIsOpen, isOpen, currentImage} = this.state;
        const { openLightbox, closeLightbox, gotoPrevious, gotoNext, gotoImage, handleClickImage, openModal } = this;

        const image_count = (images) ? images.length : 0;
        let media_count = 0, media_wrapper_classes = '', youtube_img_url = null;

        let images_for_lightbox = [];
        if(images) {
            for(let i = 0; i < images.length; i++) {
                images_for_lightbox.push({
                    src: images[i].formatted_url(),
                    alt: this.props.alt ? `${this.props.alt}` : 'Meet.jobs'
                });
            }
        }

        if(youtube_vcode) {
            youtube_img_url = `http://img.youtube.com/vi/${youtube_vcode}/mqdefault.jpg`;
            media_count = image_count + 1;
        } else {
            media_count = image_count;
        }

        switch(media_count) {
            case 0:
                media_wrapper_classes = '';
                break;
            case 1:
                media_wrapper_classes = 'media-item-wrapper-one';
                break;
            case 2:
                media_wrapper_classes = 'media-item-wrapper-two';
                break;
            case 3:
                media_wrapper_classes = 'media-item-wrapper-three';
                break;
            default:
                media_wrapper_classes = 'media-item-wrapper-more';
        }

        return (
            <div className='gallery'>
                {
                    (youtube_vcode) ?
                    <div className={`media-item-wrapper ${media_wrapper_classes}`}>
                        <div
                            className="media-item youtube-item"
                            data-src={`https://www.youtube.com/embed/${youtube_vcode}?showinfo=0&autoplay=1`}
                            onClick={openModal}
                            data-event-category="404_media"
                        >
                            <div
                                className='image-wrapper'
                                style={{backgroundImage: `url(${youtube_img_url})`}}
                            />
                        </div>
                        {
                            (images) ? images.filter((obj, i) => i < 2).map((obj, i) =>
                                <div
                                    className='media-item'
                                    key={i}
                                    onClick={ e => openLightbox(i, e) }
                                >
                                    <div
                                        className='image-wrapper'
                                        style={{backgroundImage: `url(${obj.formatted_url()})`}}
                                    />
                                </div>
                            ) : ''
                        }
                    </div> :
                    <div className={`media-item-wrapper ${media_wrapper_classes}`}>
                        {
                            (images) ? images.filter((obj, i) => i < 3).map((obj, i) =>
                                <div
                                    className='media-item'
                                    key={i}
                                    onClick={ e => openLightbox(i, e)}
                                    data-event-category="404_media"
                                >
                                    <div
                                        className='image-wrapper'
                                        style={{backgroundImage: `url(${obj.formatted_url()})`}}
                                    />
                                </div>
                            ) : ''
                        }
                    </div>
                }
                <ModalVideo
                    channel='youtube'
                    isOpen={isOpen}
                    videoId={youtube_vcode}
                    onClose={ () => this.setState({ isOpen: false }) }
                />
                <Lightbox
                    currentImage={currentImage}
                    images={images_for_lightbox}
                    isOpen={lightboxIsOpen}
                    onClickImage={handleClickImage}
                    onClickNext={gotoNext}
                    onClickPrev={gotoPrevious}
                    onClickThumbnail={gotoImage}
                    onClose={closeLightbox}
                    showThumbnails={showThumbnails}
                />
            </div>
        );
    }
}

Gallery.propTypes = {
    images: PropTypes.array,
    showThumbnails: PropTypes.bool,
};

export default Gallery;
