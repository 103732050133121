import React from 'react';
import { withNamespaces } from "react-i18next";
import icon_email from "../../../../assets/images/icon/icon_24_email_BG400.svg";
import icon_phone from "../../../../assets/images/icon/icon_24_phone_BG400.svg";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Employer from "../../../models/employer";
import { CSSTransitionGroup } from 'react-transition-group'

const config = new Config();
const headers = new ApiHeaders();

class ContactMeetJobsModal extends React.Component {

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            active_company_id: -1,
            active_company: {},
            loading: true,
            error_messages: {},
        };
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(next_props.site_state.contact_meet_jobs_modal !== this.props.site_state.contact_meet_jobs_modal) {
            if(next_props.site_state.contact_meet_jobs_modal) {
                this.setState((prev_state) => ({
                    ...prev_state,
                    active_company_id: next_props.current_user.active_company.id,
                    loading: true,
                    error_messages: {},
                }), () => this.fetchData());
            }
        }
    };

    fetchData() {
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${this.state.active_company_id}`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                let employer = new Employer(response.data);
                this.setState((prev_state) => ({
                    ...prev_state,
                    active_company: employer,
                    loading: false,
                    error_messages: {},
                }));
            })
            .catch((error) => {
                if(typeof error.response === 'undefined') {
                    window.location.reload();
                    this.props.updateConnectionOffModal(true);
                }

                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                this.setState((prev_state) => ({
                    ...prev_state,
                    loading: false,
                    error_messages:
                        (error.response && error.response.data && error.response.data.status === 'error') ?
                            error.response.data.errors :
                            {full_message: 'There was an error. Please try again later.'},
                }));
            });
    };

    closeModal = e => {
        e.preventDefault();
        this.props.updateContactMeetJobsModal(false);
    };

    render() {

        const { site_state } = this.props;
        const { active_company, loading, error_messages } = this.state;
        const { closeModal } = this;
        const { t } = this.props;

        return (
            <CSSTransitionGroup
                component="div"
                transitionName="modal-wrapper-slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {(site_state.contact_meet_jobs_modal) ?
                    <div className="modal-wrapper contact-meet-jobs-modal-wrapper">
                        <CSSTransitionGroup
                            component="div"
                            className="modal-inner-wrapper"
                            transitionName="modal-slide"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >
                            <div className="modal-bg" onClick={ e => closeModal(e) } />
                            {(!loading) ?
                                <div className="modal contact-meet-jobs-modal">
                                    <div className="btn-close" onClick={ e => closeModal(e) } />
                                    <h4 className="modal-title">{t('contact_meet_jobs')}</h4>
                                    {
                                        (error_messages.full_message) ?
                                            <h6 className="message error-message">{error_messages.full_message}</h6> :
                                            ''
                                    }
                                    <div className="content">
                                        <div className="avatar">
                                            <div
                                                className='image-wrapper'
                                                style={{backgroundImage: `url(${active_company.formatted_assistant_avatar_url()})`}}
                                            />
                                        </div>
                                        {(active_company.has_assistant()) ? <h6>{active_company.formatted_assistant_title()}</h6> : ''}
                                        <h4 className={`name ${(active_company.has_assistant()) ? '' : 'without-title'}`}>{active_company.formatted_assistant_name()}</h4>
                                        <div className="contact-ways">
                                            <div className="email">
                                                <img src={icon_email} alt="" />
                                                <h6>{active_company.formatted_assistant_email()}</h6>
                                            </div>
                                            <div className="phone">
                                                <img src={icon_phone} alt="" />
                                                <h6>{active_company.formatted_assistant_phone()}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''
                            }
                        </CSSTransitionGroup>
                    </div> : ''
                }
            </CSSTransitionGroup>
        );
    };
}

export default withNamespaces(['dashboard_modals', 'general'])(ContactMeetJobsModal);
