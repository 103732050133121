import React from "react";
import {Link} from "react-router-dom";
// 91 app
import partner_01 from "../../../assets/images/partner_logos/91app2.jpg";
import partner_02 from "../../../assets/images/partner_logos/logo-02.png";
import partner_03 from "../../../assets/images/partner_logos/cool.jpg";
import partner_04 from "../../../assets/images/partner_logos/logo-03.png";
import partner_05 from "../../../assets/images/partner_logos/logo-04.png";
import partner_06 from "../../../assets/images/partner_logos/Partner_03@2x.png";
import partner_07 from "../../../assets/images/partner_logos/grab.jpg";
import partner_08 from "../../../assets/images/partner_logos/ikala.jpg";
import partner_09 from "../../../assets/images/partner_logos/logo-06.png";
import partner_10 from "../../../assets/images/partner_logos/paypay.jpg";
import partner_11 from "../../../assets/images/partner_logos/splash.jpg";
import partner_12 from "../../../assets/images/partner_logos/Partner_10@2x.png";
import InternalUri from "../../models/internal_uri";

const PartnerLogos = props => {
    const { eventCategory } = props;
    const internal_uri = new InternalUri();

    return (
        <div className="partner-logos">
            <div className="logos">
                <div className="logo">
                    {/* 1. ASPEED */}
                    <Link

                        to={internal_uri.formatted_frontend_employer_page_path(747, '91app')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_01})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 2. ASUS */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(1471, 'asus')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_02})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 3. FET */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(929, 'coolbitx')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_03})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 4. Garmin */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(1278, 'fareastone')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_04})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 5. Gogoro */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(1333, 'garmin')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_05})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 6. MOXA */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(860, 'gogoro')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_06})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 7. Taxes Instruments */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(1945, 'grab')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_07})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 8. TrendMicro */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(982, 'ikala')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_08})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 9. Shopee */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(1468, 'moxa')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_09})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 10. Titansoft */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(2020, 'paypay-corp')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_10})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 11. Riedel */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(1390, 'splashtop')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_11})`}}
                        />
                    </Link>
                </div>
                <div className="logo">
                    {/* 12. RingCentral */}
                    <Link
                        to={internal_uri.formatted_frontend_employer_page_path(650, 'titansoft')}
                        data-event-category={eventCategory}
                    >
                        <div
                            className='image-wrapper'
                            style={{backgroundImage: `url(${partner_12})`}}
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PartnerLogos;