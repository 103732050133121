import React from 'react';
import rule_1_social_referral from "../../../assets/images/i046-3rules_1-2.svg";
import rule_1_custom_referral from "../../../assets/images/i046-3rules_1-3.svg";
import rule_1_custom_referral_display from "../../../assets/images/i046-3rules_1-2-w.svg";
import rule_2_social_referral from "../../../assets/images/i034-3rules_2_30days.svg";
import rule_2_custom_referral from "../../../assets/images/i034-3rules_2_30days.svg";
import rule_3 from "../../../assets/images/i035-3rules_3.svg";
import { withNamespaces , Interpolate } from "react-i18next";

const ThreeBasicRules = props => {
    const { t,forDisplay = null } = props;

    return (
        <div className="three-basic-rules">
            <div className="rules">
                <div className="rule rule-1">
                    {
                        forDisplay ?
                            <div
                                className="image-wrapper"
                                style={{backgroundImage: `url(${rule_1_custom_referral_display})`}}
                            /> :
                            <div
                                className="image-wrapper"
                                style={{backgroundImage: `url(${(props.type==='custom_referral') ? rule_1_custom_referral : rule_1_social_referral})`}}
                            />
                    }
                    <p>
                        {
                            forDisplay ?
                                t('general:payment_by_successful_use_hire_or_hire_plus'):
                                <Interpolate
                                    i18nKey="payment_by_successful_hire___of_annual_salary_"
                                    useDangerouslySetInnerHTML={true}
                                    number={(props.type==='custom_referral') ? 20 : 16}
                                />
                        }
                    </p>
                </div>
                <div className="rule rule-2">
                    <div
                        className="image-wrapper"
                        style={{backgroundImage: `url(${(props.type==='custom_referral') ? rule_2_custom_referral : rule_2_social_referral})`}}
                    />
                    <p>
                        <Interpolate
                            i18nKey="___day_probation_guarantee"
                            useDangerouslySetInnerHTML={true}
                            number={(props.type==='custom_referral') ? 30 : 30}
                        />
                    </p>
                </div>
                <div className="rule rule-3">
                    <div
                        className="image-wrapper"
                        style={{backgroundImage: `url(${rule_3})`}}
                    />
                    <p>{t('general:salary_information_is_mandatory_')}</p>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces(['general'])(ThreeBasicRules);