export default {
    welcome_back_: "Welcome back!",
    welcome_to_meet_jobs: "Welcome to Meet.jobs",
    don_t_have_an_account____now_: "Don't have an account? {{sign_up}} now!",
    already_have_an_account___: "Already have an account? {{log_in}}",
    forgot_password_: "Forgot password?",
    by_continuing__you_agree_to_our___and_acknowledge_our___: "By continuing, you agree to our {{terms_of_use}} and acknowledge our {{privacy_policy}}.",
    email_address: "Email address",
    password: "Password",
    forgot_password: "Forgot password",
    confirm_password: "Confirm password",
    enter_your_registered_email: "Enter your registered email",
    password_reset_link_has_been_sent_to_your_mail_box_: "Password reset link has been sent to your mail box!",
    password_is_changed_: "Password is changed!",
    please_log_in_with_your_new_password_next_time_: "Please log in with your new password next time.",
    reset_password: "Reset password",
    please_type_in_your_new_password: "Please type in your new password",
};