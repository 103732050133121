export default {
    meet_jobs_featured: "Meet.jobs featured",
    job_type: "Job type",
    full_time: "Full-time",
    part_time: "Part-time",
    internship: "Internship",
    volunteer: "Volunteer",
    all_filters: "All filters",
    more_job_functions: "More job functions",
    remote: "Remote",
    allowing_remote: "Allowing remote",
    apply: "Apply",

    best_match: "Best match",
    by_update: "By update",
    by_salary: "By salary",

    filters: "Filters",

    we_re_sorry_: "We're sorry!",
    we_can_t_seem_to_find_any_job_openings_that_match_your_search_: "We can't seem to find any job openings that match your search.",

    // earn_cash_reward_with_successful_referrals_: "Earn Cash Reward with Successful Referrals!",
};