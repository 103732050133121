import Abstract from './abstract';
import User from "./user.js";
import moment from "moment";

class Comment extends Abstract {

    constructor(attrs) {
        super(attrs);

        let raw_sender = attrs.sender;
        if(raw_sender) {
            delete attrs['sender'];
            this._build_association({
                name:  'sender',
                klass: User,
                attrs: raw_sender
            });
        }
    };

    formatted_sender_name() {
        let sender_name = this.sender.formatted_name();
        if(this.membership_title) sender_name += ' (' + this.membership_title + ')';
        return sender_name;
    };

    formatted_created_at() {
        if(!this.created_at) return '';
        return moment(this.created_at).format('YYYY/MM/DD HH:mm');
    };
}

export default Comment;
