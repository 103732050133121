import React from 'react';
import { Link } from 'react-router-dom';
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import JobApplication from "../../../models/job_application.js";
import { withNamespaces } from "react-i18next";
import {download_file, get_value_from_query_string, page_smothly_scroll_to} from "../../../../assets/js/functions";
import User from "../../../models/user";
import {ReactComponent as IconDownload} from "../../../../assets/images/icon/icon_24_download_B100.svg";
import {ReactComponent as IconCalendar} from "../../../../assets/images/icon/icon_24_calendar_BG400.svg";
import {ReactComponent as IconMessage} from "../../../../assets/images/icon/icon_24_message_B100.svg";
import { Document, Page } from 'react-pdf';
import InternalUri from "../../../models/internal_uri";
import DeviceDetector from "../../../models/device_detector";
import Attachment from "../../../models/attachment";

const config = new Config();
const headers = new ApiHeaders();

class TalentPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.fetchResumeFromApplication = this.fetchResumeFromApplication.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.onDocumentLoadError = this.onDocumentLoadError.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleMessagesWrapperScroll = this.handleMessagesWrapperScroll.bind(this);
        this.state = {
            active_params: {
                origin_type: '',
            },
            application: {},
            candidate: {},
            attachment: {},
            paginator: {
                total_entries: 0,
                total_pages: 1,
                per_page: 5,
                current_page: 1,
            },
            fail_to_load_file: false,
            pages: [],
            loading: true,
            error_messages: {},
        };
        this.timestamp = Date.now();
    };

    componentDidMount() {
        this._isMounted = true;

        const resume_id = this.props.match.params.id;
        const query = decodeURI(this.props.location.search);
        if(query) {
            const origin_type = get_value_from_query_string('origin_type', query);
            if(origin_type === 'job_application') {
                const employer_id = get_value_from_query_string('employer_id', query);
                const job_id = get_value_from_query_string('job_id', query);
                const job_application_id = get_value_from_query_string('job_application_id', query);
                const hash = get_value_from_query_string('hash', query);
                this.setState((prev_state) => ({
                    ...prev_state,
                    active_params: {
                        origin_type,
                        resume_id,
                        employer_id,
                        job_id,
                        job_application_id,
                        hash
                    },
                    loading: true,
                    error_messages: {}
                }), () => this.fetchResumeFromApplication());
            }
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    fetchResumeFromApplication() {
        const { origin_type, resume_id, employer_id, job_id, job_application_id, hash } = this.state.active_params;
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${employer_id}/jobs/${job_id}/job_applications/${job_application_id}`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let application = {}, candidate = {}, attachment = {};
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                application = new JobApplication(response.data);

                options = {
                    method: 'GET',
                    url: config.api_url(`/employers/${employer_id}/candidate_resumes/${resume_id}?origin_type=${origin_type}&origin_id=${job_application_id}&secret_token=${hash}`),
                    headers: headers.getItemsFromLocalStorage(),
                    json: true
                };
                return axios(options);
            })
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);

                candidate = new User(response.data.candidate);
                attachment = new Attachment(response.data.attachment);

                this.setState((prev_state) => ({
                    ...prev_state,
                    application,
                    candidate,
                    attachment,
                    loading: false,
                    error_messages: {}
                }));
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    const Internal_uri = new InternalUri();
                    const { t } = this.props;
                    if(error.response.status === 401) window.location.reload();
                    if(error.response.status === 403 || error.response.status === 404 || error.response.status === 422) {
                        this.props.history.push(Internal_uri.formatted_frontend_index_page_path());

                        if(error.response.status === 403) {
                            this.props.addFlashMessage(
                                true,
                                t('flash_messages:you_need_permission_to_browse_the_page_please_ask_for_access_or_switch_to_an_account_with_permission_'),
                                'danger',
                                'with-dismiss',
                                ''
                            );
                        }
                    }
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                }
            });
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        let pages = [];
        for(let i=0; i<numPages; i++) {
            pages.push(<Page key={i} pageNumber={i+1} width={800} />);
        }
        this.setState((prev_state) => ({
            ...prev_state,
            pages,
            paginator: {
                ...prev_state.paginator,
                total_entries: numPages,
                total_pages: Math.ceil(numPages/this.state.paginator.per_page),
                current_page: 1,
            },
        }));
    };

    onDocumentLoadError = () => {
        this.setState({ fail_to_load_file: true });
    };

    handleDownload = (e, url, filename) => {
        e.preventDefault();
        download_file(url, filename);
    };

    handleMessagesWrapperScroll = e => {
        e.preventDefault();
        const element =  document.querySelector(".page-talent-inner-wrapper");
        if(element && (element.scrollTop + element.offsetHeight >= element.scrollHeight - 150)) {
            if (this.state.paginator.current_page < this.state.paginator.total_pages) {
                this.setState((prev_state) => ({
                    ...prev_state,
                    paginator: {
                        ...prev_state.paginator,
                        current_page: prev_state.paginator.current_page + 1
                    }
                }));
            }
        }
    };

    render() {

        const { active_params, candidate, attachment, application, pages, loading, error_messages, paginator, fail_to_load_file } = this.state;
        const { onDocumentLoadSuccess, onDocumentLoadError, handleDownload, handleMessagesWrapperScroll } = this;
        const { t } = this.props;
        const internal_uri = new InternalUri();
        const device_detector = new DeviceDetector();

        if(loading) return null;

        const attachment_url_with_timestamp = `${attachment.formatted_url()}?${this.timestamp}`;

        return(
            <div className="page-talent content-wrapper">
                <div className="page-talent-sidebar-wrapper">
                    <div className="sidebar">
                        <div className="avatar">
                            <div
                                className='image-wrapper'
                                style={{backgroundImage: `url(${candidate.formatted_avatar_url()})`}}
                            />
                        </div>
                        <h4>{attachment.candidate_name}</h4>
                        <div className="call-to-action-buttons">
                            <Link
                                to={`${internal_uri.formatted_dashboard_applications_page_path()}?message_modal_open=true&employer_id=${active_params.employer_id}&job_id=${active_params.job_id}&job_application_id=${active_params.job_application_id}`}
                                className="btn btn-flat btn-ghost tooltip-wrapper"
                            >
                                <IconMessage />
                                <div className="tooltip">
                                    <h6>{t('message')}</h6>
                                </div>
                            </Link>
                            <Link
                                to={`${internal_uri.formatted_dashboard_applications_page_path()}?message_modal_open=true&employer_id=${active_params.employer_id}&job_id=${active_params.job_id}&job_application_id=${active_params.job_application_id}&open_interview_box=true`}
                                className="btn btn-flat btn-ghost tooltip-wrapper"
                            >
                                <IconCalendar />
                                <div className="tooltip">
                                    <h6>{t('interview')}</h6>
                                </div>
                            </Link>
                            {
                                (device_detector.is_mobile_or_tablet()) ?
                                    <a
                                        className="btn btn-flat btn-ghost tooltip-wrapper"
                                        href={attachment.formatted_url()}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <IconDownload />
                                        <div className="tooltip">
                                            <h6>{t('download')}</h6>
                                        </div>
                                    </a> :
                                    <button
                                        className="btn btn-flat btn-ghost tooltip-wrapper"
                                        onClick={ e => handleDownload(e, attachment_url_with_timestamp, attachment.formatted_filename()) }
                                    >
                                        <IconDownload />
                                        <div className="tooltip">
                                            <h6>{t('download')}</h6>
                                        </div>
                                    </button>
                            }
                        </div>
                        {
                            (application.employer_state_changeable() && !application.is_rejected()) ?
                                <Link
                                    to={`${internal_uri.formatted_dashboard_applications_page_path()}?message_modal_open=true&employer_id=${active_params.employer_id}&job_id=${active_params.job_id}&job_application_id=${active_params.job_application_id}&open_double_confirm_modal=reject`}
                                    className="btn btn-smaller btn-flat btn-hollow btn-danger"
                                >
                                    <h5>{t('general:reject')}</h5>
                                </Link> : ''
                        }
                    </div>
                </div>
                <div
                    className="page-talent-inner-wrapper"
                    onScroll={ e => handleMessagesWrapperScroll(e) }
                >
                    <div className="container-fluid">
                        {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                        <div className="document-wrapper">
                            {
                                (attachment.formatted_filename().match(/^.+\.([pP][dD][fF])$/) && !fail_to_load_file) ?
                                    <div>
                                        <Document
                                            file={attachment_url_with_timestamp}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            onLoadError={onDocumentLoadError}
                                        >
                                            {pages.filter(page => parseInt(page.key) < paginator.current_page*paginator.per_page)}
                                        </Document>
                                    </div> :
                                    <div className="file-format-cannot-be-open-wrapper">
                                        <h4>{t('this_file_format_cannot_be_opened_in_browser_please_download_and_open_in_your_computer_')}</h4>
                                        {
                                            (device_detector.is_mobile_or_tablet()) ?
                                                <a
                                                    className="btn btn-larger btn-flat btn-fill"
                                                    href={attachment.formatted_url()}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <h5>{t('general:download')}</h5>
                                                </a> :
                                                <button
                                                    className="btn btn-larger btn-flat btn-fill"
                                                    onClick={ e => handleDownload(e, attachment_url_with_timestamp, attachment.formatted_filename()) }
                                                >
                                                    <h5>{t('general:download')}</h5>
                                                </button>
                                        }
                                    </div>
                            }
                        </div>
                    </div>

                    <div className="mobile-call-to-action-wrapper">
                        <div className="container">
                            <div className="mobile-call-to-action">
                                <Link
                                    to={`${internal_uri.formatted_dashboard_applications_page_path()}?message_modal_open=true&employer_id=${active_params.employer_id}&job_id=${active_params.job_id}&job_application_id=${active_params.job_application_id}`}
                                    className="btn btn-larger btn-flat btn-fill"
                                >
                                    <h5>{t('reply')}</h5>
                                </Link>
                                {
                                    (device_detector.is_mobile_or_tablet()) ?
                                        <a
                                            className="btn-download"
                                            href={attachment.formatted_url()}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <IconDownload />
                                        </a> :
                                        <button
                                            className="btn-download"
                                            onClick={ e => handleDownload(e, attachment_url_with_timestamp, attachment.formatted_filename()) }
                                        >
                                            <IconDownload />
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default withNamespaces(['talents_talent_page', 'general', 'flash_messages'])(TalentPage);
