import React from 'react';
import Config from "../../models/config.js";
import ApiHeaders from "../../models/api_headers";
import axios from "axios";
import { withNamespaces, Interpolate } from "react-i18next";
import icon_fb from "../../../assets/images/icon/icon_28_facebook.svg";
import { get_value_from_query_string, page_smothly_scroll_to, smoothly_scroll_to } from "../../../assets/js/functions.js";
import User from "../../models/user.js";
import i18n from "../../../i18n.js";
import InternalUri from "../../models/internal_uri.js";
import {formatted_locale} from "../../../assets/js/functions";
import {sendDataLayer} from "../../../assets/js/dataLayer";

const config = new Config();
const headers = new ApiHeaders();

class LogInForm extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goPage = this.goPage.bind(this);
        this.submit = this.submit.bind(this);
        this.state = {
            email: '',
            password: '',
            fbAuthLink: "",
            error_messages: {},
            sending: false,
        };
        this.go_back_path = '';
    };

    componentDidMount() {
        this._isMounted = true;

        if(this.props.reduxTokenAuth.currentUser.isSignedIn) {
            const internal_uri = new InternalUri();
            this.props.history.push(internal_uri.formatted_frontend_index_page_path());
            this.props.updateEntranceFormModal('');
        }

        let fbAuthLink = '';

        const internal_uri = new InternalUri();
        const query = decodeURI(this.props.location.search);
        const pathname = decodeURI(this.props.location.pathname);
        let token, job_id, referrer_id, invitation_id;
        if(query) {
            token = get_value_from_query_string('token', query);
            job_id = get_value_from_query_string('job_id', query);
            referrer_id = get_value_from_query_string('referrer_id', query);
            const pathname_array = pathname.split('/');
            invitation_id = pathname_array[pathname_array.length-1];
        }

        const { utm } = this.props;
        let utm_parameters = '';
        if(utm.source || utm.medium || utm.campaign) {
            utm_parameters += `&utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`;
        }

        if(token && job_id && invitation_id) {
            // fb auth with talent invitation info
            const auth_origin_url = encodeURIComponent(`callback-handler?callback_key=assign_redirect_url&callback_resource=jobs/talent_invitation&callback_params[id]=${invitation_id}&callback_params[job_id]=${job_id}&callback_params[token]=${token}`);
            fbAuthLink = config.api_url(`/auth/facebook?auth_origin_url=${auth_origin_url}${utm_parameters}&referrer_id=${referrer_id}`);
        } else {
            const { location } = this.props;
            const auth_origin_url = encodeURIComponent(`${window.location.origin}${internal_uri.formatted_frontend_redirect_from_facebook_page_path()}?redirect_pathname=${encodeURIComponent(`${location.pathname}${location.search}`)}`);

            const { referral_codes } = this.props;
            if(referral_codes.length) {
                // fb auth with the latest referral code
                fbAuthLink = config.api_url(`/auth/facebook?auth_origin_url=${auth_origin_url}${utm_parameters}&referral_code=${referral_codes[referral_codes.length-1]}`);
            } else {
                // normal fb auth
                fbAuthLink = config.api_url(`/auth/facebook?auth_origin_url=${auth_origin_url}${utm_parameters}`);
            }
        }

        this.setState({ fbAuthLink });

        this.go_back_path = get_value_from_query_string('go_back_path', query);
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    handleInputChange = (e, property_name) => {
        e.preventDefault();
        let value = e.target.value;

        // if(property_name==='email') value = value.toLowerCase();

        this.setState({ [property_name]: value });
    };

    goPage = (e, pathname) => {
        e.preventDefault();
        this.props.history.push(pathname);
        this.props.updateEntranceFormModal('');
    };

    submit = e => {
        e.preventDefault();
        const { email, password, sending } = this.state;
        let options = {};
        if(!sending) {
            this.setState({ sending: true });

            options = {
                method: 'POST',
                url: config.api_url(`/auth/sign_in?locale=${i18n.language}`),
                data: {
                    email,
                    password
                },
                json: true
            };
            let userAttributes = {};
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    userAttributes = response.data.data;
                    i18n.changeLanguage(formatted_locale(userAttributes.locale));
                    sendDataLayer('userId', userAttributes.id);
                })
                .catch((error) => {
                    if(this._isMounted) {
                        if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                        this.setState((prev_state) => ({
                            ...prev_state,
                            sending: false,
                            error_messages: {
                                general: (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors.full_message :
                                    "There was an error. Please try again later."
                            }
                        }), () => {
                            if(error.response && error.response.data && error.response.data.data ) {
                                if(typeof error.response.data.data.confirmed !== 'undefined' && !error.response.data.data.confirmed) {
                                    setTimeout(() => {
                                        const internal_uri = new InternalUri();
                                        this.props.history.push(`${internal_uri.formatted_frontend_confirm_page_path()}?email=${this.state.email}`);
                                        this.props.updateEntranceFormModal('');
                                    }, 1500);
                                }
                            }
                        });

                        if(this.props.site_state.entrance_form_modal === 'log-in') {
                            smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
                        } else {
                            page_smothly_scroll_to(0, 300);
                        }
                    }

                    return new Promise(() => {});
                })
                .then(() => {
                    options = {
                        method: 'GET',
                        url: config.api_url(`/users/${userAttributes.id}?include=employer_roles`),
                        headers: headers.getItemsFromLocalStorage(),
                        json: true
                    };
                    return axios(options);
                })
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);

                    const user = new User(response.data);
                    this.props.updateCurrentUserIsReferralUser(user.is_referrals_exist);

                    let userAttributes = response.data;
                    this.props.verifyTokenRequestSucceeded(userAttributes);

                    let employer_roles = {}, promises_employers = [];
                    const raw_employer_roles = response.data.employer_roles;
                    for(let i=0; i<raw_employer_roles.length; i++) {
                        options = {
                            method: 'GET',
                            url: config.api_url(`/employers/${raw_employer_roles[i].employer_id}`),
                            headers: headers.getItemsFromLocalStorage(),
                            json: true
                        };
                        promises_employers.push(axios(options));
                        employer_roles[raw_employer_roles[i].employer_id] = raw_employer_roles[i];
                    }
                    this.props.updateCurrentUserEmployerRoles(employer_roles);

                    return axios.all(promises_employers);
                })
                .then((results) => {
                    for(let i=0; i<results.length; i++) {
                        headers.updateItemsToLocalStorage(results[i].headers);
                    }

                    const { current_user } = this.props;
                    let active_employer_id = current_user.active_company.id || 0;
                    let companies = [], company;
                    let active_company_index = 0;
                    for(let i=0; i<results.length; i++) {
                        company = results[i].data;
                        companies.push(company);
                        if(active_employer_id === company.id) {
                            active_company_index = i;
                        }
                    }

                    if(companies.length > 0) {
                        this.props.updateCurrentUserIsEmployerUser(true);
                        this.props.updateCurrentUserCompanies([...companies]);
                        this.props.updateCurrentUserActiveCompany({...companies[active_company_index]});
                    } else {
                        this.props.updateCurrentUserIsEmployerUser(false);
                        this.props.updateCurrentUserCompanies([]);
                        this.props.updateCurrentUserActiveCompany({});
                    }

                    this.setState({
                        error_messages: {},
                        sending: false,
                    }, () => {
                        const { location, site_state } = this.props;
                        const internal_uri = new InternalUri();
                        if(
                            location.pathname.search('/log-in') !== -1 ||
                            location.pathname.search('/sign-up') !== -1
                        ) {
                            const next_path = this.go_back_path ? internal_uri.formatted_path_with_new_language(this.go_back_path) : internal_uri.formatted_frontend_index_page_path();
                            this.props.history.push(next_path);
                        }
                        if(
                            site_state.entrance_form_modal === 'log-in' ||
                            site_state.entrance_form_modal === 'sign-up'
                        ) {
                            this.props.updateEntranceFormModal('');
                            this.props.history.replace(
                                `${internal_uri.formatted_path_with_new_language(window.location.pathname)}` +
                                `${window.location.search}`
                            );
                        }
                    });
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) {
                            this.props.verifyTokenRequestFailed();
                            window.location.reload();
                        }
                    }

                    if(this._isMounted) {
                        this.setState({ sending: false });
                    }
                });
        }
    };

    render() {

        const { email, password, fbAuthLink, error_messages, sending } = this.state;
        const { site_state } = this.props;
        const { submit, handleInputChange, goPage } = this;
        const { t } = this.props;
        const internal_uri = new InternalUri();

        return (
            <form>
                {
                    (error_messages.general) ?
                        <div className="form-row">
                            <h6 className="message error-message">{error_messages.general}</h6>
                        </div> : ''
                }
                <div className="form-row">
                    <input
                        className="input"
                        type="email"
                        value={email}
                        onChange={ e => handleInputChange(e, 'email') }
                        placeholder={t('email_address')}
                        autoComplete="username"
                    />
                </div>
                <div className="form-row">
                    <input
                        className="input"
                        type="password"
                        value={password}
                        onChange={ e => handleInputChange(e, 'password') }
                        placeholder={t('password')}
                        autoComplete="current-password"
                    />
                </div>
                <div className="form-row">
                    <button
                        type="submit"
                        value="submit"
                        className={`btn btn-larger btn-flat btn-fill ${(sending) ? 'disabled' : ''}`}
                        onClick={ e => submit(e) }
                        data-event-category={(site_state.entrance_form_modal === 'log-in') ? "033_modal_log_in" : "039_log_in_fullpage"}
                    >
                        <h5>{t('general:log_in')}</h5>
                    </button>
                </div>
                <h5>
                    <a
                        onClick={ e => goPage(e, internal_uri.formatted_frontend_forgot_password_page_path())}
                        href={internal_uri.formatted_frontend_forgot_password_page_path()}
                        className="link"
                    >
                        {t('forgot_password_')}
                    </a>
                </h5>
                <div className="separator"><span>{t('general:or')}</span></div>
                <a
                    className="btn btn-larger btn-flat btn-fill btn-facebook"
                    href={fbAuthLink}
                    data-event-category="037_log_in_facebook"
                >
                    <img src={icon_fb} alt="" />
                    <h6>{t('general:log_in_with_facebook')}</h6>
                </a>
                <h6 className="description">
                    <Interpolate
                        i18nKey="by_continuing__you_agree_to_our___and_acknowledge_our___"
                        useDangerouslySetInnerHTML={true}
                        terms_of_use={
                            <a
                                href={`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=user-agreement`}
                                onClick={ e => goPage(e, `${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=user-agreement`) }
                                className="link"
                            >
                                {t('general:user_agreement')}
                            </a>
                        }
                        privacy_policy={
                            <a
                                href={`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=privacy-policy`}
                                onClick={ e => goPage(e, `${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=privacy-policy`) }
                                className="link"
                            >
                                {t('general:privacy_policy')}
                            </a>
                        }
                    />
                </h6>
            </form>
        );
    };
}

export default withNamespaces(['frontend_authentication', 'general'])(LogInForm);
