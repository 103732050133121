export default {
    maximize_your_value_in_the_world_: "Maximize Your Value in the World!",
    // reach_a_bigger_world_: "Reach a Bigger World.",
    trending: "Trending",

    refer_talents: "To Recruit Talents",
    refer_your_friends_to_get_hired_and_earn_referral_reward__1_000__usd_: "Refer your friends to get hired and earn Referral Reward $1,000+ USD",
    hire_talents: "To Refer Friend Jobs",
    headhunter_services_with_less_than_half_cost_and_it_technologies_: "Headhunter services with less than half cost and IT technologies.\n",

    latest_jobs: "Latest Jobs",
    more_latest_jobs: "More latest jobs",



    how_does_meet_jobs_work_: "How does Meet.jobs work?",
    feature_title:"Meet.jobs, a Referral Platform For and By Professional Talents",
    featured_jobs: "Featured Jobs",
    feature_1_title:'International-Focused',
    feature_2_title:'Salary Transparency',
    feature_3_title:'No “Silent Card”',
    feature_4_title:'$100 Onboard Reward',
    feature_1_des : 'Employers, talents and job opportunities from 71 districts and countries.',
    feature_2_des : 'All vacancies have Salary Range. Fair to candidates, efficient to employers.',
    feature_3_des : 'Employer Response Rate over 99%! No application or resume will be ignored.',
    feature_4_des : 'Candidates will receive US$100 Onboard Reward and invited to share experience of getting hired.',

    reward_and_sharing:'Onboard Rewards and Experience Sharing',

    good_jobs_and_employers_selected_by_meet_jobs: "Good jobs and employers selected by Meet.jobs",
    _100_usd_onboard_reward_when_you_getting_hired: "$100 USD Onboard Reward when you getting hired",
    _1_000__usd_referral_reward_for_a_successful_hire: "$1,000+ USD Referral Reward for a successful hire",

    featured_employers: "Featured Employers",

    sign_up_meet_jobs:'Signup Meet.jobs<br>Get more job information worldwide',
    benefit_to_meet_jobs:'Benefits to Meet.jobs’ members',
    benefit_1:'1. One-time Free career consulting service',
    benefit_2:'2. Regular newsletter for latest career information',
    one_click:'*One click to signup WITHOUT uploading resume',

    step_one:"Step 1. Apply",
    step_two:"Step 2. Report",
    step_three:"Step 3. Reward",

    sign_up_call_to_action_index:"Free Subscribe for details",
    remote: "Remote",
};