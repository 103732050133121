export default {
    your_link_has_expired_please_send_the_request_again_: "您的連結已失效，請再重新操作一次。",
    you_have_successfully_joined_the_employer_dashboard_of___: "您已成功接受邀請並成為 {{employer_name}} 的企業成員。",
    your_invitation_has_expired_please_ask_for_a_new_invitation_: "您的邀請已過期，請再次要求新的邀請。",
    you_have_left___: "您已離開 {{employer_name}}。",
    you_need_permission_to_browse_the_page_please_ask_for_access_or_switch_to_an_account_with_permission_: "您沒有瀏覽該頁面的權限，請要求權限或切換至其他帳戶。",
    please_check_the_verification_link_with_your_new_email__: "驗證連結已寄送到您 {{email}} 的信箱。",
    you_are_now_logged_in_as__: "您現在是以 {{email}} 登入。",
    your_invitation_has_been_sent_you_can_find_it_in___: "您已協助朋友完成應徵此一職位。您可以從「{{my_referrals}}」了解更多資訊。",
    you_have_rejected_this_invitation_on__: "您已在 {{- updated_at}} 拒絕了此邀請。",
    you_have_accepted_this_invitation_and_applied_on__: "您已接受邀請並在 {{- updated_at}} 應徵了此工作機會。",
    this_invitation_is_not_available_because_the_job_is_closed_: "因企業關閉了工作機會，此邀請已失效。",
    link_copied_: "連結已複製！",
    can_t_get_your_email_address_from_the_service_Please_try_to_sign_up_with_another_method_: "無法由該服務取得您的 email 信箱位址，請使用其他方式註冊。",
    all_published_jobs_have_been_updated_and_will_last_for_60_days_: "您刊登中的工作機會都已更新，將持續刊登60天。",
    job_post_published_successfully_: "工作機會已成功刊登！",
};
