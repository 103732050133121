export default {
  high_quality_and_efficient_recruitment_service_with_competitive_price: `讓全世界一起幫你找人才！`,
  meet_jobs_is_a_headhunting_platform_leveraging_on_social_referral_and_community_sharing_and_focusing_on_international_talents_and_job_opportunities_: `Meet.jobs 是一個專注於資訊和跨國人才的獵才平台。<br>透過 A.I. 科技媒合與專業獵頭推薦，為企業自全球招募最優秀的人選！`,

  our_plans: "我們的方案",

  our_advantages: "我們的優勢",
  _17_countries: "17 個國家",
  talents___employers___vacancies_across_17_countries: "人才 / 企業 / 工作機會",
  _25_days: "25 天",
  from_posting_job_to_offer_acceptance: "從張貼工作機會到確認錄取",
  _16__service_fee: `16<span class="percent">%</span> 服務費`,
  compare_to_20___charged_by_headhunters: "低於傳統獵頭的年薪 20%↑",
  precise_matching_by_social_referral: "社群招募，精準推薦",
  machine_learning_and_ai_technology: "運用人工智能技術協助媒合",
  employer_branding_and_integrated_marketing: "品牌形象推廣及招募整合行銷",
  recruitment_consulting_service: "招募顧問諮詢服務",
  _hire_plan_: "(社群方案)",
  _hire_plus_plan_: "(獵才方案)",
  _hire__hire_plus_plan_: "(社群 /獵才 方案)",

  partners: "合作夥伴",

  use_first__contract_later_: "立即獵才，合約後補！",
  you_need_only_to_agree_with_3_Basic_Rules__:
    "同意以下「三定律」，即可開始招募",
  note__contract_must_be_signed_before_your_first_candidate_s_onboarding:
    "備註：在第一名錄取者就職前必須完成簽約。",
  start_recruiting: "即刻開始招募",

  back_to_plan: "回到方案",

  talents_dispatch: "國際人才派遣",
  corparation_branding: "行銷僱主品牌",

  how_meetjob_acquire_talents: "Meet.jobs如何獲取人才",
  ai_title: "AI人才推薦",
  ai_content: "A.I. 自動辨識履歷，搜尋全球人才資料庫",
  headhunter_title: "專業獵才",
  headhunter_content: "專業獵頭團隊，協助進行精準篩選及配對",
  social_title: "社群推薦",
  social_content: "透過專業社群宣傳職缺，推薦合適人選",
  events_title: "招募活動",
  events_content: "整合式招募活動，快速面試打造僱主品牌",
  across_71_countries: "來自於全球 160+ 國家的人才 / 企業 / 工作機會",
  across_71_countries_content: "來自於全球 160+ 國家的人才 / 企業 / 工作機會",
  worldmap_more: "多",
  worldmap_less: "少",
  worldmap_user_distribution: "用戶分佈數量",
  worldmap_explain_1: `依國家排序:台灣(約30%)、美國(10.16%)、中國(8.35%)、日本(4.17%)及其他國家。 `,
  worldmap_explain_2: "依地區排序:台灣+歐洲+北美+東南亞+中國大陸 超過75%",
  wordmap_count_method: "",

  jobFunctionChartTitle:
    "專注於六大領域的專業人才：軟體開發、跨國商務、產品開發、數位行銷、產品設計及硬體工程（六項總和超過90% 的用戶）。",
  experienceChartTitle: "超過 60% 以上的用戶擁有 3 年以上工作經驗。",
  // charts
  chart_main_title: "人才用戶分佈",
  chart_culture_title: "文化背景",
  chart_job_function_title: "工作職能",
  chart_experience_title: "年資經驗",

  // countries in chinese
  Taiwan: "台灣",
  Europe: "歐洲",
  North_americas: "北美洲",
  SEA: "東南亞",
  East_asia: "東亞",
  South_asia: "南亞",
  Middle_east: "中東",
  ANZ: "澳紐",
  United_States: "美國",
  China: "中國",
  India: "印度",
  Japan: "日本",
  Singapore: "新加坡",
  Brazil: "巴西",
  United_Kingdom: "英國",
  Philippines: "菲律賓",
  Indonesia: "印尼",
  Germany: "德國",
  Hong_Kong: "香港",
  Canada: "加拿大",
  Australia: "澳洲",
  Others: "其他",

  // job functions
  software_development: "軟體開發",
  business_operation: "商業運營",
  product_development: "產品開發",
  marketing: "行銷",
  design: "設計",
  hardware_development: "硬體開發",
  human_resource: "人力資源",
  finance: "財務",
  law: "法務",
  other: "其他",

  // experience
  y0_1: "0-1年",
  y1_3: "1-3年",
  y3_5: "3-5年",
  y5_7: "5-7年",
  y7_10: "7-10年",
  y5_10: "5-10年",
  y10: "10年以上",
};
