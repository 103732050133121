import Abstract from "./abstract";
import moment from "moment/moment";
import User from "./user.js";
import Job from "./job.js";
import Attachment from "./attachment";
import Event from "./event";

class JobApplication extends Abstract {
  constructor(attrs) {
    super(attrs);

    let raw_applier = attrs.applier;
    if (raw_applier) {
      delete attrs["applier"];
      this._build_association({
        name: "applier",
        klass: User,
        attrs: raw_applier,
      });
    }

    let raw_job = attrs.job;
    if (raw_job) {
      delete attrs["job"];
      this._build_association({
        name: "job",
        klass: Job,
        attrs: raw_job,
      });
    }

    let raw_referrers = attrs.referrers;
    if (raw_referrers) {
      delete attrs["referrers"];
      this._build_associations({
        name: "referrers",
        klass: User,
        attrs: raw_referrers,
      });
    }

    let raw_attachment = attrs.attachment;
    if (raw_attachment) {
      delete attrs["attachment"];
      this._build_association({
        name: "attachment",
        klass: Attachment,
        attrs: raw_attachment,
      });
    }

    let raw_events = attrs.events;
    if (raw_events) {
      delete attrs["events"];
      this._build_associations({
        name: "events",
        klass: Event,
        attrs: raw_events,
      });
    }
  }

  formatted_applier_name() {
    if (this.applier_name) return this.applier_name;
    if (this.applier) {
      if (this.applier.name) {
        return this.applier.name;
      }
    }
    return "(not provided)";
  }

  formatted_applier_email() {
    if (this.applier_email) return this.applier_email;
    if (this.applier) {
      if (this.applier.email) {
        return this.applier.email;
      }
    }
    return "(not provided)";
  }

  formatted_applier_phone() {
    if (this.applier_phone) return this.applier_phone;
    if (this.applier) {
      if (this.applier.phone) {
        return this.applier.phone;
      }
    }
    return "(not provided)";
  }

  has_attachment() {
    if (!this.attachment) return false;
    return true;
  }

  has_attachment_asset_url() {
    if (this.has_attachment() && this.attachment.has_url()) return true;
    return false;
  }

  has_portfolio_link_url() {
    if (this.portfolio_url) return true;
    return false;
  }

  formatted_portfolio_link_url() {
    return this.portfolio_url ? this.portfolio_url : "";
  }

  // formatted_portfolio_link(t) {
  //     return (this.portfolio_url) ? (this.portfolio_url) : t('general:_not_provided_');
  // };

  has_unread_messages() {
    if (!this.current_user_status) return false;
    if (!this.current_user_status.unread) return false;
    if (!this.current_user_status.unread.messages) return false;
    if (this.current_user_status.unread.messages <= 0) return false;
    return true;
  }

  formatted_applied_at() {
    const the_time = this.applied_at;
    if (the_time) return moment(the_time).format("YYYY/MM/DD");
    return "";
  }

  formatted_applied_at_past_time(t) {
    const the_time = this.applied_at;
    if (the_time) {
      const now_time = moment(Date.now());
      const time_by_minute = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "minutes", true))
      );
      const time_by_hour = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "hours", true))
      );
      const time_by_day = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "days", true))
      );

      // 61天以前，顯示日期
      if (time_by_day > 60) {
        return this.formatted_applied_at();
      }
      // 60天以內，顯示幾天前
      if (time_by_minute <= 1 && time_by_minute >= 0) {
        return time_by_minute + t("general:_minute_ago");
      } else if (time_by_hour < 1 && time_by_hour >= 0) {
        return time_by_minute + t("general:_minutes_ago");
      } else if (time_by_hour === 1) {
        return time_by_hour + t("general:_hour_ago");
      } else if (time_by_day < 1 && time_by_day >= 0) {
        return time_by_hour + t("general:_hours_ago");
      } else if (time_by_day === 1) {
        return time_by_day + t("general:_day_ago");
      } else {
        return time_by_day + t("general:_days_ago");
      }
    }
    return "";
  }

  formatted_updated_at() {
    const the_time = this.updated_at;
    if (the_time) return moment(the_time).format("YYYY/MM/DD");
    return "";
  }

  formatted_updated_at_past_time(t) {
    const the_time = this.updated_at;
    if (the_time) {
      const now_time = moment(Date.now());
      const time_by_minute = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "minutes", true))
      );
      const time_by_hour = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "hours", true))
      );
      const time_by_day = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "days", true))
      );

      // 61天以前，顯示日期
      if (time_by_day > 60) {
        return this.formatted_updated_at();
      }
      // 60天以內，顯示幾天前
      if (time_by_minute <= 1 && time_by_minute >= 0) {
        return time_by_minute + t("general:_minute_ago");
      } else if (time_by_hour < 1 && time_by_hour >= 0) {
        return time_by_minute + t("general:_minutes_ago");
      } else if (time_by_hour === 1) {
        return time_by_hour + t("general:_hour_ago");
      } else if (time_by_day < 1 && time_by_day >= 0) {
        return time_by_hour + t("general:_hours_ago");
      } else if (time_by_day === 1) {
        return time_by_day + t("general:_day_ago");
      } else {
        return time_by_day + t("general:_days_ago");
      }
    }
    return "";
  }

  is_confirmed() {
    if (this.employer_state === "confirmed") return true;
    return false;
  }

  is_rejected() {
    if (this.employer_state === "rejected") return true;
    return false;
  }

  is_cancelled() {
    if (this.applier_state === "cancelled") return true;
    return false;
  }

  is_reported() {
    if (this.applier_state === "reported") return true;
    return false;
  }

  is_claimed() {
    if (this.referrer_state === "claimed") return true;
    return false;
  }

  is_employer_archived() {
    return this.job && this.job.employer && !this.job.employer.activated_state;
  }

  employer_state_changeable() {
    if (this.applier_state === "cancelled") return false;
    if (
      this.employer_state === "rejected" ||
      this.employer_state === "confirmed" ||
      this.employer_state === "archived"
    )
      return false;
    return true;
  }

  referrer_state_changeable() {
    if (this.applier_state === "cancelled") return false;
    if (this.referrer_state === "claimed") return false;
    if (
      this.employer_state === "rejected" ||
      this.employer_state === "confirmed" ||
      this.employer_state === "archived"
    )
      return false;
    return true;
  }

  formatted_state(t) {
    if (this.employer_state === "confirmed")
      return t(`states:${this.employer_state}`);
    if (this.employer_state === "rejected")
      return t(`states:${this.employer_state}`);

    if (this.applier_state === "cancelled")
      return t(`states:${this.applier_state}`);
    if (this.applier_state === "reported")
      return t(`states:${this.applier_state}`);
    if (this.referrer_state === "claimed")
      return t(`states:${this.referrer_state}`);

    if (this.applier_state === "applying")
      return t(`states:${this.applier_state}`);
    return t(`states:${this.applier_state}`);
  }

  has_referrer() {
    if (!this.referrers) return false;
    if (this.referrers.length > 0) return true;
    return false;
  }

  formatted_referrer_user_name() {
    if (!this.referrers) return "(no referrer)";
    if (this.referrers.length > 0) {
      return this.referrers[0].formatted_name();
    }
    return "(no referrer)";
  }

  formatted_referrer_name() {
    if (!this.referrer_note) return "(not provided)";
    if (
      this.referrer_note.referrer_first_name ||
      this.referrer_note.referrer_last_name
    ) {
      let name = "";
      if (this.referrer_note.referrer_first_name)
        name += this.referrer_note.referrer_first_name;
      if (
        this.referrer_note.referrer_first_name &&
        this.referrer_note.referrer_last_name
      )
        name += " ";
      if (this.referrer_note.referrer_last_name)
        name += this.referrer_note.referrer_last_name;
      return name;
    }
    return "(not provided)";
  }

  formatted_referrer_email() {
    if (!this.referrer_note) return "(not provided)";
    if (this.referrer_note.referrer_email)
      return this.referrer_note.referrer_email;
    return "(not provided)";
  }

  formatted_referrer_phone() {
    if (!this.referrer_note) return "(not provided)";
    if (this.referrer_note.referrer_phone)
      return this.referrer_note.referrer_phone;
    return "(not provided)";
  }

  formatted_referrer_content() {
    if (!this.referrer_note) return "(not provided)";
    if (this.referrer_note.content) return this.referrer_note.content;
    return "(not provided)";
  }

  get_referral_id() {
    if (!this.referrals) return null;
    if (this.referrals.length > 0) return this.referrals[0].id;
    return null;
  }

  has_event() {
    if (!this.events) return false;
    if (this.events.length <= 0) return false;
    return true;
  }
}

export default JobApplication;
