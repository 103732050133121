import React from 'react';
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import Config from "../../../models/config.js";
import {withRouter} from "react-router-dom";
import InternalUri from "../../../models/internal_uri";
import {get_value_from_query_string} from "../../../../assets/js/functions";

const config = new Config();
const headers = new ApiHeaders();

class ResetPasswordPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
        this.state = ({
            password: '',
            password_confirmation: '',
            error_messages: {},
            sending: false,
        });
        this.redirect_url = '';
        this.flash_message_id_to_open = null;
    };

    componentDidMount() {
        this._isMounted = true;

        const { reduxTokenAuth } = this.props;
        if(!reduxTokenAuth.currentUser.isSignedIn) {
            const internal_uri = new InternalUri();
            const { location } = this.props;
            this.props.history.push(`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${location.pathname}${location.search}`);
        } else {
            const query = decodeURI(this.props.location.search);
            if(query) {
                const redirect_url = get_value_from_query_string('redirect_url', query);
                this.flash_message_id_to_open = get_value_from_query_string('flash_message_id_to_open', query);

                const internal_uri = new InternalUri();
                this.redirect_url = redirect_url ?
                    internal_uri.formatted_path_with_new_language(redirect_url) :
                    internal_uri.formatted_frontend_password_changed_page_path();
            }
        }
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(this.props.reduxTokenAuth.currentUser.isSignedIn !== next_props.reduxTokenAuth.currentUser.isSignedIn) {
            if(!next_props.reduxTokenAuth.currentUser.isSignedIn) {
                const internal_uri = new InternalUri();
                const { location } = this.props;
                this.props.history.push(`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${location.pathname}${location.search}`);
            }
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    handleInputChange = (e, property_name) => {
        e.preventDefault();
        const value = e.target.value;
        this.setState({ [property_name]: value });
    };

    submit = e => {
        e.preventDefault();
        const { sending } = this.state;
        if(!sending) {
            this.setState({ sending: true });
            let formData = new FormData();
            const { password, password_confirmation } = this.state;
            formData.append('password', password);
            formData.append('password_confirmation', password_confirmation);
            let options = {
                method: 'PUT',
                url: config.api_url(`/auth/password`),
                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                data: formData,
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    if(this._isMounted) {
                        this.setState((prev_state) => ({
                            ...prev_state,
                            error_messages: {},
                            sending: false
                        }), () => {
                            this.props.history.push(this.redirect_url);
                            if(this.flash_message_id_to_open) this.props.openFlashMessage(this.flash_message_id_to_open);
                        });
                    }
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    if(this._isMounted) {
                        if(typeof error.response === 'undefined') {
                            window.location.reload();
                            this.props.updateConnectionOffModal(true);
                        }

                        this.setState((prev_state) => ({
                            ...prev_state,
                            sending: false,
                            error_messages:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: 'There was an error. Please try again later.'},
                        }));
                    }
                });
        }
    };

    render() {

        const { password, password_confirmation, error_messages, sending } = this.state;
        const { handleInputChange, submit } = this;
        const { t } = this.props;

        return (
            <div className="page-password content-wrapper white">
                <div className="container">
                    <section className="page-title">
                        <h2>{t('reset_password')}</h2>
                        <p>{t('please_type_in_your_new_password')}</p>
                        {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                    </section>
                    <section className="page-content">
                        <div className="entrance-wrapper">
                            <form onSubmit={submit}>
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <input
                                            className={`input ${(error_messages.password && error_messages.password.length) ? 'input-danger' : ''}`}
                                            type="password"
                                            value={password}
                                            placeholder={t('password')}
                                            onChange={ e => handleInputChange(e, 'password') }
                                        />
                                    </div>
                                    <div className="form-col">
                                        {(error_messages.password && error_messages.password.length) ? <h6 className="message error-message">{error_messages.password.join(', ')}</h6> : ''}
                                    </div>
                                </div>

                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <input
                                            className={`input ${(error_messages.password_confirmation && error_messages.password_confirmation.length) ? 'input-danger' : ''}`}
                                            type="password"
                                            value={password_confirmation}
                                            placeholder={t('confirm_password')}
                                            onChange={ e => handleInputChange(e, 'password_confirmation') }
                                        />
                                    </div>
                                    <div className="form-col">
                                        {(error_messages.password_confirmation && error_messages.password_confirmation.length) ? <h6 className="message error-message">{error_messages.password_confirmation}</h6> : ''}
                                    </div>
                                </div>

                                <button className={`btn btn-larger btn-flat btn-fill ${(sending) ? 'disabled' : ''}`}>
                                    <h6>{t('general:ok')}</h6>
                                </button>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default withRouter(withNamespaces(['frontend_authentication', 'general'])(ResetPasswordPage));
