import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group'
import FlashMessage from "./FlashMessage.jsx";

const FlashMessages = props => (
    (props.flash_messages && props.flash_messages.length) ?
        <div className="flash-messages">
            <CSSTransitionGroup
                component="div"
                transitionName="fade-in-out"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {
                    props.flash_messages.map(flash_message => {
                        return (
                            <FlashMessage
                                key={flash_message.id}
                                flash_message={flash_message}
                                removeFlashMessage={ id => props.removeFlashMessage(id) }
                            />
                        )
                    })
                }
            </CSSTransitionGroup>
        </div> : ''
);

export default FlashMessages;
