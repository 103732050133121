import React from 'react';
import {withNamespaces} from "react-i18next";
import { Link } from "react-router-dom";
import Employer from "../../../models/employer.js";
import icon_email from "../../../../assets/images/icon/icon_24_email_BG400.svg";
import icon_phone from "../../../../assets/images/icon/icon_24_phone_BG400.svg";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import InternalUri from "../../../models/internal_uri";

const config = new Config();
const headers = new ApiHeaders();

class LockedModal extends React.Component {

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = {
            active_company_id: -1,
            active_company: {},
            open: true,
            loading: true,
            error_messages: {},
        };
    };

    componentDidMount() {
        const active_company = new Employer(this.props.current_user.active_company);
        if(
            active_company.is_latest_plan_post() &&
            (
                this.props.location.pathname.search('/dashboard/payment-setting') !== -1 ||
                this.props.location.pathname.search('/dashboard/upgrade-plans') !== -1
            )
        ) {
            this.setState((prev_state) => ({
                ...prev_state,
                active_company_id: active_company.id,
                active_company,
                loading: false,
                open: false,
            }));
        } else {
            this.setState((prev_state) => ({
                ...prev_state,
                active_company_id: active_company.id,
                active_company: {},
                loading: true,
                open: true,
            }), () => this.fetchData());
        }
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(this.props.current_user.active_company.id !== next_props.current_user.active_company.id) {
            const active_company = new Employer(next_props.current_user.active_company);
            if(
                active_company.is_latest_plan_post() &&
                (
                    next_props.location.pathname.search('/dashboard/payment-setting') !== -1 ||
                    next_props.location.pathname.search('/dashboard/upgrade-plans') !== -1
                )
            ) {
                this.setState((prev_state) => ({
                    ...prev_state,
                    active_company_id: active_company.id,
                    active_company,
                    loading: false,
                }));
            } else {
                this.setState((prev_state) => ({
                    ...prev_state,
                    active_company_id: active_company.id,
                    active_company: {},
                    loading: true,
                }), () => this.fetchData());
            }
        }

        if(this.props.location.pathname !== next_props.location.pathname) {
            const active_company = new Employer(next_props.current_user.active_company);
            if(
                active_company.is_latest_plan_post() &&
                (
                    next_props.location.pathname.search('/dashboard/payment-setting') !== -1 ||
                    next_props.location.pathname.search('/dashboard/upgrade-plans') !== -1
                )
            ) {
                this.setState((prev_state) => ({
                    ...prev_state,
                    open: false,
                }));
            } else {
                this.setState((prev_state) => ({
                    ...prev_state,
                    open: true,
                }));
            }
        }
    };

    fetchData() {
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${this.state.active_company_id}`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                let employer = new Employer(response.data);
                this.setState((prev_state) => ({
                    ...prev_state,
                    active_company: employer,
                    loading: false,
                    error_messages: {},
                }));
            })
            .catch((error) => {
                if(typeof error.response === 'undefined') {
                    window.location.reload();
                    this.props.updateConnectionOffModal(true);
                }

                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                this.setState((prev_state) => ({
                    ...prev_state,
                    loading: false,
                    error_messages:
                        (error.response && error.response.data && error.response.data.status === 'error') ?
                            error.response.data.errors :
                            {full_message: 'There was an error. Please try again later.'},
                }));
            });
    };

    render() {

        const { open, active_company, loading } = this.state;
        const { t } = this.props;
        const internal_uri = new InternalUri();

        if(loading)
            return (
                <div className="modal-wrapper locked-modal-wrapper">
                    <div className="modal-bg" />
                </div>
            );

        if(open) {
            return (
                <div className="modal-wrapper locked-modal-wrapper">
                    <div className="modal-inner-wrapper">
                        <div className="modal-bg" />
                        <div className="modal locked-modal">
                            <h4 className="modal-title">{t('your_account_is_temporarily_locked_')}</h4>
                            {(active_company.is_latest_plan_post()) ?
                                <div>
                                    <p>{t('locked_content_post_plan')}</p>
                                    <Link
                                        className="btn btn-flat btn-fill"
                                        to={internal_uri.formatted_dashboard_payment_setting_page_path()}
                                    >
                                        <h5>{t('go_to_payment_setting')}</h5>
                                    </Link>
                                </div> :
                                <div>
                                    <p>{t('locked_content')}</p>
                                    <div className="content">
                                        <div className="avatar">
                                            <div
                                                className='image-wrapper'
                                                style={{backgroundImage: `url(${active_company.formatted_assistant_avatar_url()})`}}
                                            />
                                        </div>
                                        {(active_company.has_assistant()) ? <h6>{active_company.formatted_assistant_title()}</h6> : ''}
                                        <h4 className={`name ${(active_company.has_assistant()) ? '' : 'without-title'}`}>{active_company.formatted_assistant_name()}</h4>
                                        <div className="contact-ways">
                                            <div className="email">
                                                <img src={icon_email} alt="" />
                                                <h6>{active_company.formatted_assistant_email()}</h6>
                                            </div>
                                            <div className="phone">
                                                <img src={icon_phone} alt="" />
                                                <h6>{active_company.formatted_assistant_phone()}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };
}

export default withNamespaces(['dashboard_modals', 'general'])(LockedModal);
