import i18n from "../../i18n";
import {isEmail, isNumeral} from "./validators";
export const required = value => {
    if(value.length<=0) return i18n.t('error_messages:required');
};

export const email = value => {
    if(!isEmail(value)) return i18n.t('error_messages:invalidFormat', { type: "Email" });
};

export const numeral = value => {
    if(!isNumeral(value)) return i18n.t('error_messages:mustBeNumber');
};