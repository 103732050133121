import React from "react";
import {withNamespaces} from "react-i18next";

class InputField extends React.Component {
    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.state = {
            value: '',
            errorMessages: []
        };
    }

    componentDidMount() {
        if(this.props.shareValidateMethods) this.props.shareValidateMethods(this.validate);

        this.setState({
            value: this.props.inputValue,
            errorMessages: this.props.errorMessages
        })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(
            this.props.inputValue !== nextProps.inputValue
            || this.props.errorMessages !== nextProps.errorMessages
        ) {
            this.setState({
                value: nextProps.inputValue,
                errorMessages: nextProps.errorMessages
            })
        }
    };

    validate = () => {
        const format = this.props.format;

        let value = this.state.value;
        let errorMessages = [];
        if(format) value = format.removeFormat(value);
        for(const validation of this.props.validations) {
            const errorMessage = validation(value);
            if(errorMessage) errorMessages.push(errorMessage);
        }

        this.setState({ errorMessages }, () => this.props.handleChange(this.state.value, this.state.errorMessages));
    };

    handleChange(e) {
        e.preventDefault();
        const value = e.target.value;
        this.setState({ value }, () => this.validate());
    };

    handleFocus(e) {
        e.preventDefault();
        const format = this.props.format;
        let value = this.state.value;
        if(format) value = format.removeFormat(value);
        this.setState({ value }, () => this.props.handleChange(this.state.value, this.state.errorMessages));
    };

    handleBlur(e) {
        e.preventDefault();
        const format = this.props.format;
        let value = this.state.value;
        if(format) value = format.applyFormat(value);
        this.setState({ value }, () => this.props.handleChange(this.state.value, this.state.errorMessages));
    };

    render() {
        const { value, errorMessages } = this.state;

        return (
            <React.Fragment>
                <label htmlFor={this.props.inputId} dangerouslySetInnerHTML={{__html: this.props.labelContent}} />
                <input
                    type="text"
                    id={this.props.inputId}
                    className={`input ${(errorMessages.length) ? 'input-danger' : ''}`}
                    value={value}
                    onChange={ e => this.handleChange(e) }
                    onFocus={ e => this.handleFocus(e) }
                    onBlur={ e => this.handleBlur(e) }
                    placeholder={this.props.inputPlaceholderText}
                />
                {errorMessages.length ? <h6 className="message error-message">{errorMessages.join(', ')}</h6> : ''}
            </React.Fragment>
        );
    }
}

InputField.defaultProps = {
    value: '',
    errorMessages: [],
    validations: [],
};

export default withNamespaces(['error_messages'])(InputField);