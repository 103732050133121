import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Metas from "../../../models/metas";
import {
  compare,
  get_options,
  page_smothly_scroll_to,
} from "../../../../assets/js/functions";
import { Elements, StripeProvider } from "react-stripe-elements";
import SaveCardForm from "../SaveCardForm";
import { withNamespaces, Interpolate } from "react-i18next";
import Employer from "../../../models/employer.js";
import banner_plan_post from "../../../../assets/images/i013-plan_banner_01.svg";
import banner_plan_hire from "../../../../assets/images/i014-plan_banner_02.svg";
import banner_plan_hire_plus from "../../../../assets/images/i015-plan_banner_03.svg";
import powered_by_stripe from "../../../../assets/images/powered_by_stripe.png";
import moment from "moment";
import { CSSTransitionGroup } from "react-transition-group";
import InternalUri from "../../../models/internal_uri";
import Pagination from "../../general/Pagination";

const config = new Config();
const headers = new ApiHeaders();

class PaymentPostonlyProgramsPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.state = {
      latest_plan_key: "",
      form_data: {},
      original_form_data: {},
      has_pending_data: false,
      loading: false,
      sending: false,
      error_messages: {},
      metas: {},
      options: {},
      data: [
        { TITLE: "PROGRAM 1", AA: "MOCK1-1", BB: "MOCK1-2", CC: "MOCK1-3" },
        { TITLE: "PROGRAM 2", AA: "MOCK2-1", BB: "MOCK2-2", CC: "MOCK2-3" },
        { TITLE: "PROGRAM 3", AA: "MOCK3-1", BB: "MOCK3-2", CC: "MOCK3-3" },
      ],
    };

    this.parameters = {
      paginator: { current_page: 1 },
      criteria: { name: "updated_at", sortBy: "desc" },
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // this.setState({ loading: true });

    // let axios_options = {
    //   method: "GET",
    //   url: config.api_url("/employers/plan_types"),
    //   headers: headers.getItemsFromLocalStorage(),
    //   json: true,
    // };
    // let metas = {},
    //   options = {};
    // axios(axios_options)
    //   .then((response) => {
    //     headers.updateItemsToLocalStorage(response.headers);
    //     this.props.updateConnectionOffModal(false);

    //     if (this._isMounted) {
    //       metas = {
    //         ...metas,
    //         plan_types: new Metas("plan_types", response.data),
    //       };
    //       options = {
    //         ...options,
    //         plan_types: get_options("plan_types", response.data),
    //       };

    //       axios_options = {
    //         method: "GET",
    //         url: config.api_url("/employers/payment_types"),
    //         headers: headers.getItemsFromLocalStorage(),
    //         json: true,
    //       };
    //       return axios(axios_options);
    //     }
    //   })
    //   .then((response) => {
    //     headers.updateItemsToLocalStorage(response.headers);

    //     if (this._isMounted) {
    //       metas = {
    //         ...metas,
    //         payment_types: new Metas("payment_types", response.data),
    //       };
    //       options = {
    //         ...options,
    //         payment_types: get_options("payment_types", response.data),
    //       };

    //       this.setState(
    //         (prev_state) => ({
    //           ...prev_state,
    //           metas,
    //           options,
    //           error_messages: {},
    //         }),
    //         () =>
    //           this.updateActiveCompany(
    //             this.props.current_user.active_company.id
    //           )
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response) {
    //       headers.updateItemsToLocalStorage(error.response.headers);
    //       if (error.response.status === 401) window.location.reload();
    //     }

    //     if (this._isMounted) {
    //       if (typeof error.response === "undefined") {
    //         window.location.reload();
    //         this.props.updateConnectionOffModal(true);
    //       }

    //       this.setState((prev_state) => ({
    //         ...prev_state,
    //         loading: false,
    //         error_messages:
    //           error.response &&
    //           error.response.data &&
    //           error.response.data.status === "error"
    //             ? error.response.data.errors
    //             : {
    //                 full_message: "There was an error. Please try again later.",
    //               },
    //       }));

    //       page_smothly_scroll_to(0, 300);
    //     }
    //   });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setCurrentPage = (page) => {
    page_smothly_scroll_to(0, 300);
    this.setState({ loading: true });
    this.parameters.paginator.current_page = page;
    this.fetchJobs();
  };

  checkPendingData() {
    const { form_data, original_form_data } = this.state;
    const has_pending_data = !compare(
      JSON.parse(JSON.stringify(form_data)),
      original_form_data
    );
    this.setState({ has_pending_data });
  }

  handleRadioChange(e, property_name, value) {
    console.log(e, property_name, value);
    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: {
          ...prev_state.form_data,
          [property_name]: value,
        },
      }),
      () => {
        this.checkPendingData();
      }
    );
  }

  render() {
    const { latest_plan_key, loading, sending, error_messages } = this.state;
    const { form_data } = this.state;
    const { has_pending_data, data } = this.state;
    const {
      discard,
      submitForm,
      parameters,
      setCurrentPage,
      handleRadioChange,
    } = this;
    const { t } = this.props;
    const internal_uri = new InternalUri();

    const ProgramSelection = ({ value }) => (
      <div
        className="program-selection"
        onClick={(e) => handleRadioChange(e, "plan_checked", value)}
      >
        <label>
          <input
            name={value}
            checked={form_data.plan_checked === value}
            type="radio"
            className="radio-input"
            readOnly
            style={{ display: "none" }}
          />
          <div className="program-selection-inner">{value}</div>
        </label>
      </div>
    );

    const columns_pc = [
      {
        Header: () => <h5></h5>,
        accessor: "TITLE",
        id: "title",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
        width: 140,
      },
      {
        Header: () => <h5>SEASON</h5>,
        accessor: "AA",
        id: "minimum_salary",
        Cell: (props) => {
          return <ProgramSelection value={props.value} />;
        },
        width: 200,
      },
      {
        Header: () => <h5>HALF</h5>,
        accessor: "BB",
        id: "job_applications_count",
        Cell: (props) => {
          return <ProgramSelection value={props.value} />;
        },
        width: 200,
      },
      {
        Header: () => <h5>ANNUAL</h5>,
        accessor: "CC",
        id: "updated_at",
        Cell: (props) => {
          return <ProgramSelection value={props.value} />;
        },
        width: 200,
      },
    ];

    // 992px
    const columns_tl = [
      // {
      //   Header: () => <h5></h5>,
      //   accessor: "TITLE",
      //   id: "title",
      //   Cell: (props) => {
      //     return <div>{props.value}</div>;
      //   },
      //   width: 120,
      // },
      // {
      //   Header: () => <h5>SEASON</h5>,
      //   accessor: "AA",
      //   id: "minimum_salary",
      //   Cell: (props) => {
      //     return <ProgramSelection value={props.value} />;
      //   },
      //   width: 140,
      // },
      // {
      //   Header: () => <h5>HALF</h5>,
      //   accessor: "BB",
      //   id: "job_applications_count",
      //   Cell: (props) => {
      //     return <ProgramSelection value={props.value} />;
      //   },
      //   width: 140,
      // },
      // {
      //   Header: () => <h5>ANNUAL</h5>,
      //   accessor: "CC",
      //   id: "updated_at",
      //   Cell: (props) => {
      //     return <ProgramSelection value={props.value} />;
      //   },
      //   width: 140,
      // },
    ];

    // 600px
    const columns_mb = [
      {
        Header: () => <h5>{t("title")}</h5>,
        accessor: "job",
        id: "title",
        Cell: (props) => {
          return <div>3</div>;
        },
      },
    ];

    return (
      <div className="dashboard-payment">
        <div className="container-fluid">
          <h6 className="breadcrumb">
            <Link to={internal_uri.formatted_dashboard_index_page_path()}>
              {t("general:home")}
            </Link>
            {" / "}
            {t("general:payment_setting")}
            {" / "}
            POSTONLY PROGRAMS
          </h6>
          <h2>POSTONLY PROGRAMS</h2>
          {error_messages.full_message ? (
            <h6 className="message error-message">
              {error_messages.full_message}
            </h6>
          ) : (
            ""
          )}
          form_data.plan_checked:{form_data.plan_checked}
          <div>
            <div className="table-wrapper-pc table-wrapper block">
              <ReactTable
                loading={loading}
                data={data}
                columns={columns_pc}
                defaultPageSize={parameters.paginator.per_age}
                minRows={0}
                showPagination={false}
                noDataText={t("general:your_list_is_empty_")}
              />
            </div>
            <div className="table-wrapper-tl table-wrapper block">
              <div className="table-wrapper block">
                <ReactTable
                  loading={loading}
                  data={data}
                  columns={columns_tl}
                  defaultPageSize={parameters.paginator.per_age}
                  minRows={0}
                  showPagination={false}
                  noDataText={t("general:your_list_is_empty_")}
                  // expanded={data ? data.map((i) => i) : false}
                  // SubComponent={(row) => {
                  //   return (
                  //     <div className="extended-sub-row-wrapper">
                  //       SUBCOMPONENT
                  //     </div>
                  //   );
                  // }}
                />
              </div>
            </div>
            <div className="table-wrapper-mb table-wrapper block">
              <div className="table-wrapper block">
                <ReactTable
                  loading={loading}
                  data={data}
                  columns={columns_mb}
                  defaultPageSize={parameters.paginator.per_age}
                  minRows={0}
                  showPagination={false}
                  noDataText={t("general:your_list_is_empty_")}
                />
              </div>
            </div>
            {parameters.paginator.total_entries ? (
              <div>
                <div className="pagination-wrapper-pc hidden-xs hidden-sm">
                  <Pagination
                    currentPage={parameters.paginator.current_page}
                    pagesCount={parameters.paginator.total_pages}
                    pagedCount={5}
                    previous_page={parameters.paginator.previous_page}
                    next_page={parameters.paginator.next_page}
                    setCurrentPage={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="pagination-wrapper-mb">
                  <Pagination
                    currentPage={parameters.paginator.current_page}
                    pagesCount={parameters.paginator.total_pages}
                    pagedCount={4}
                    previous_page={parameters.paginator.previous_page}
                    next_page={parameters.paginator.next_page}
                    setCurrentPage={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <CSSTransitionGroup
          component="div"
          transitionName="actions-bar-slide"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={100}
        >
          {has_pending_data ? (
            <div className="actions-bar-wrapper">
              <div className="actions-bar">
                <p>SELECTED PROGRAM</p>
                <div className="buttons-wrapper">
                  <button
                    className="btn btn-larger btn-flat btn-hollow"
                    onClick={(e) => submitForm(e)}
                  >
                    <h5>PAY</h5>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default withNamespaces(["dashboard_payment_page", "general"])(
  PaymentPostonlyProgramsPage
);
