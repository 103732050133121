import {contain_not_only_whitespace} from "../../assets/js/functions";
const REACT_APP_BUILT_AT = (typeof process.env.REACT_APP_BUILT_AT == 'undefined') ? "" : process.env.REACT_APP_BUILT_AT;

let instance = null;
class ApiHeaders {
    constructor() {
        if(!instance) instance = this;
        return instance;
    }

    getItemsFromLocalStorage(content_type = null) {
        let app_version = '';
        let scripts = document.querySelectorAll('script');
        for(let i=0; i<scripts.length; i++) {
            let temp_string = scripts[i].src.match(/\/static\/js\/main\.(.*?)\.chunk\.js/);
            if(temp_string) {
                app_version = temp_string[1];
                break;
            }
        }

        let headers = {
            "uid": localStorage.getItem("uid"),
            "client": localStorage.getItem("client"),
            // "expiry": localStorage.getItem("expiry"),
            // "token-type": localStorage.getItem("token-type"),
            "access-token": localStorage.getItem("access-token"),
            "Cache-Control": "no-cache, no-store, must-revalidate",
            "Pragma": "no-cache",
            "Expires": "0"
        };

        if(content_type) {
            headers["Content-Type"] = content_type;
        }

        if(contain_not_only_whitespace(app_version)) {
            headers["app-uid"] = "meet-web-frontend";
            headers["app-version"] = app_version;
            headers["app-built-at"] = REACT_APP_BUILT_AT;
        }

        // test for token
        // console.log(headers);
        return headers;
    }

    updateItemsToLocalStorage(response_headers) {
        // test for token
        // console.log(response_headers);
        let new_access_token = response_headers['access-token'];
        if(new_access_token) {
            localStorage.setItem('uid', response_headers['uid']);
            localStorage.setItem('client', response_headers['client']);
            // localStorage.setItem('expiry', response_headers['expiry']);
            // localStorage.setItem('token-type', response_headers['token-type']);
            localStorage.setItem('access-token', new_access_token);
        }
        if(response_headers['is_newest_version'] === 'false' && window.location.host !== 'localhost:3000') window.location.reload();
    }
}

export default ApiHeaders;