export default {
    refer_this_job_to_your_friends__and_get___reward_: "Refer this job to your friends, and get {{reward}} reward.",
    refer_this_employer_to_your_friends_and_get_reward_: "Refer this employer to your friends, and get reward.",

    if_you_refer_anonymously__you_will_not_get_any_referral_reward_: "If you refer anonymously, you will not get any Referral Reward.",
    or___here: "or {{log_in}} here",

    your_referral_link: "Your referral link",
    you_can_manage_your_referral_at___: "You can manage your referral at {{my_referrals}}.",
    share_with_: "Share with:",

    // mail
    a_great_job_you_might_be_interested_: "A Great Job You Might Be Interested!",
    hello_my_friend__i_think_this_is_a_good_job_opportunity_that_you_might_be_interested_: "Hello my friend, I think this is a good job opportunity that you might be interested!",

    // social
    hi__i_found_a_great_job_you_might_be_interested_come_and_check_it_out_: "Hi! I found a great job you might be interested. Come and check it out.",
};