import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOutUser } from './../../../../redux-token-auth-config';
import Employer from "../../../models/employer";
import { withNamespaces } from "react-i18next";
import User from "../../../models/user";
import logo from '../../../../assets/images/logo_meetjobs_nav_standard_white.svg';
import InternalUri from "../../../models/internal_uri";
import {sendDataLayer} from "../../../../assets/js/dataLayer";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.updateCompanies = this.updateCompanies.bind(this);
        this.toggleMobileMainMenu = this.toggleMobileMainMenu.bind(this);
        this.handleSubMenu = this.handleSubMenu.bind(this);
        this.signOut = this.signOut.bind(this);
        this.state = {
            active_company: {},
            active_user: {},
            options: [],
        };
    };

    UNSAFE_componentWillMount() {
        this.updateCompanies(this.props);

        this.setState((prev_state) => ({
            ...prev_state,
            active_user: new User(this.props.reduxTokenAuth.currentUser.attributes),
        }));
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        this.updateCompanies(next_props);

        const { reduxTokenAuth } = next_props;
        if(
            reduxTokenAuth.currentUser.isSignedIn !== this.props.reduxTokenAuth.currentUser.isSignedIn ||
            JSON.stringify(reduxTokenAuth.currentUser.attributes) !== JSON.stringify(this.props.reduxTokenAuth.currentUser.attributes)
        ) {
            if(reduxTokenAuth.currentUser.isSignedIn) {
                this.setState({
                    active_user: new User(reduxTokenAuth.currentUser.attributes),
                });
            }
        }
    };

    updateCompanies(props) {
        const { current_user } = props;
        const { t } = this.props;
        if(current_user.companies) {
            let options = [];
            for (let i=0; i < current_user.companies.length; i++) {
                options.push({value: current_user.companies[i].id, label: current_user.companies[i].name});
            }
            options.push({ value: 'new_company', label: t('create_employer')});
            this.setState({
                active_company: new Employer(current_user.active_company),
                options
            });
        }
    };

    handleSubMenu = e => {
        e.preventDefault();
        this.props.updateSubMenu(!this.props.site_state.sub_menu);
    };

    toggleMobileMainMenu = e => {
        e.preventDefault();

        const { site_state, updateMobileMenu } = this.props;
        const cur_mobile_menu = site_state.mobile_menu;

        if(cur_mobile_menu) {
            updateMobileMenu("");
        } else {
            updateMobileMenu("main_menu");
        }
    };

    signOut = e => {
        e.preventDefault();
        const { signOutUser } = this.props;
        signOutUser()
            .then(() => {
                this.props.closeAll();
                this.props.updateConnectionOffModal(false);
                sendDataLayer('userId', -1);
            })
            .catch((error) => {
                if(typeof error.response === 'undefined') {
                    window.location.reload();
                    this.props.updateConnectionOffModal(true);
                }

                if(error.response) {
                    if(error.response.status === 404) {
                        window.location.reload();
                    }
                }
            });
    };

    render() {

        const { site_state, reduxTokenAuth, current_user } = this.props;
        const { active_user, active_company } = this.state;
        const { toggleMobileMainMenu, handleSubMenu, signOut } = this;
        const { t } = this.props;
        const internal_uri = new InternalUri();

        const referral = (reduxTokenAuth.currentUser.isSignedIn && current_user.is_referral_user);
        const employer = (reduxTokenAuth.currentUser.isSignedIn && current_user.is_employer_user && Object.keys(active_company).length > 0);

        return (
            <header
                className={
                    'talents' +
                    `${(site_state.mobile_menu==='main_menu') ? ' open-main-menu' : ''}`
                }
            >
                <div className="navigation-wrapper">
                    <div className="container-fluid">
                        <div className="navigation">
                            <ul className="left-menu">
                                <li>
                                    <div className="logo-wrapper">
                                        <Link to={internal_uri.formatted_frontend_index_page_path()}>
                                        </Link>
                                        <img
                                            src={logo}
                                            alt="meet.jobs logo"
                                            onClick={ e => toggleMobileMainMenu(e)}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <h6>Talents</h6>
                                </li>
                            </ul>
                            <ul className="right-menu">
                                <li>
                                    <NavLink
                                        exact
                                        to={internal_uri.formatted_frontend_index_page_path()}
                                        className="btn btn-flat btn-hollow back-to-meet-jobs"
                                    >
                                        <h5>{t('back_to_meet_jobs')}</h5>
                                    </NavLink>
                                </li>
                                <li className={`sub-menu-trigger-wrapper${(site_state.sub_menu) ? ' open-sub-menu' : ''}`}>
                                    <a href="/#submenu" className="sub-menu-trigger" onClick={ e => handleSubMenu(e) }>
                                        <div className="avatar">
                                            <div
                                                className="image-wrapper"
                                                style={{backgroundImage: `url(${active_user.formatted_avatar_toolbar_url()})`}}
                                            />
                                        </div>
                                        <h5>{active_user.formatted_name()}</h5>
                                    </a>

                                    <ul className="sub-menu">
                                        {
                                            (employer) ?
                                                <li className="with-logo with-border-bottom">
                                                    <Link to={internal_uri.formatted_dashboard_index_page_path()}>
                                                        <div className="logo">
                                                            <div
                                                                className='image-wrapper'
                                                                style={{
                                                                    backgroundImage: `url('${active_company.formatted_logo_toolbar_url()}')`
                                                                }}
                                                            />
                                                        </div>
                                                        <h6>{active_company.formatted_name()}</h6>
                                                    </Link>
                                                </li> : ''
                                        }
                                        <li>
                                            <Link
                                                to={internal_uri.formatted_userpanel_index_page_path()}
                                            >
                                                <h6>{t('account_setting')}</h6>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={internal_uri.formatted_userpanel_my_applications_page_path()}
                                            >
                                                <h6>{t('my_applications')}</h6>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={internal_uri.formatted_userpanel_saved_jobs_page_path()}
                                            >
                                                <h6>{t('saved_jobs')} </h6>
                                            </Link>
                                        </li>
                                        {
                                            (referral) ?
                                                <li>
                                                    <NavLink to={internal_uri.formatted_userpanel_my_referrals_page_path()}>
                                                        <h6>{t('my_referrals')}</h6>
                                                    </NavLink>
                                                </li> :
                                                <li className="with-btn">
                                                    <NavLink to={internal_uri.formatted_userpanel_my_referrals_page_path()}>
                                                        <h6>{t('my_referrals')}</h6>
                                                    </NavLink>
                                                    <NavLink
                                                        to={internal_uri.formatted_frontend_referrer_landing_page_path()}
                                                        className="btn btn-smallest btn-flat btn-fill"
                                                    >
                                                        <h6>{t('get_reward')}</h6>
                                                    </NavLink>
                                                </li>
                                        }
                                        <hr />
                                        <li>
                                            <a href="/#logout" onClick={ e => signOut(e) }>
                                                <h6>{t('log_out')}</h6>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mobile-main-menu-wrapper">
                    <div className="mobile-main-menu">
                        <div className="container">
                            <ul>
                                {
                                    (employer) ?
                                        <li className="with-avatar with-employer-avatar with-border-bottom">
                                            <NavLink to={internal_uri.formatted_dashboard_index_page_path()}>
                                                <div className="avatar">
                                                    <div
                                                        className='image-wrapper'
                                                        style={{backgroundImage: `url('${active_company.formatted_logo_toolbar_url()}')`}}
                                                    />
                                                </div>
                                                <h5>{active_company.name}</h5>
                                            </NavLink>
                                        </li> : ''
                                }
                                <li>
                                    <NavLink exact to={internal_uri.formatted_userpanel_index_page_path()}>
                                        <h5>{t('account_setting')}</h5>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink exact to={internal_uri.formatted_userpanel_my_applications_page_path()}>
                                        <h5>{t('my_applications')}</h5>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink exact to={internal_uri.formatted_userpanel_saved_jobs_page_path()}>
                                        <h5>{t('saved_jobs')}</h5>
                                    </NavLink>
                                </li>
                                {
                                    (referral) ?
                                        <li>
                                            <NavLink exact to={internal_uri.formatted_userpanel_my_referrals_page_path()}>
                                                <h5>{t('my_referrals')}</h5>
                                            </NavLink>
                                        </li> :
                                        <li className="with-btn">
                                            <NavLink exact to={internal_uri.formatted_userpanel_my_referrals_page_path()}>
                                                <h5>{t('my_referrals')}</h5>
                                            </NavLink>
                                            <NavLink
                                                exact
                                                to={internal_uri.formatted_frontend_referrer_landing_page_path()}
                                                className="btn btn-larger btn-flat btn-fill"
                                            >
                                                <h5>{t('get_reward')}</h5>
                                            </NavLink>
                                        </li>
                                }
                                <li>
                                    <a href="/#logout" onClick={ e => signOut(e) }>
                                        <h5>{t('log_out')}</h5>
                                    </a>
                                </li>
                                <li className="back-to-meet-jobs">
                                    <Link
                                        to={internal_uri.formatted_frontend_index_page_path()}
                                        className="btn btn-smaller btn-flat btn-hollow"
                                    >
                                        <h5>{t('back_to_meet_jobs')}</h5>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default connect(null, { signOutUser })(withNamespaces(['general'])(Header));
