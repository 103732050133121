import React from 'react';
import icon_arrow_down_b from "../../../assets/images/icon/icon_arrow_down_blue.svg";
import icon_arrow_down_g from "../../../assets/images/icon/icon_28_arrow_down_BG400.svg";

const Pagination = ({ pagedCount, currentPage, pagesCount, previous_page, next_page, setCurrentPage=f=>f }) => {

    const getPaginationLists = (showingCount)  => {
        let lists = [];
        let showingOffset = 0;
        if( showingCount <= pagesCount && currentPage > (Math.floor(showingCount/2)+1) ) {
            if( currentPage > (pagesCount-Math.ceil(showingCount/2)) ) {
                showingOffset = pagesCount - showingCount
            } else {
                showingOffset = currentPage - (Math.floor(showingCount/2)+1)
            }
        }
        const lists_length = ( showingCount <= pagesCount ) ? showingCount : pagesCount;
        for(let i = 1 ; i <= lists_length ; i++) {
            let page = showingOffset + i;
            lists.push(
                <li className={( currentPage === page ) ? 'active' : ''} key={i} >
                    <a
                        href={`/#${page}`}
                        onClick={ e => { e.preventDefault(); setCurrentPage(page)} }
                    >
                        {page}
                    </a>
                </li>
            );
        }
        return lists;
    };

    return (
        <ul className="pagination">
            {
                (previous_page) ?
                    <li className="prev">
                        <a
                            href="/#prev"
                            onClick={ e => { e.preventDefault(); setCurrentPage(previous_page);} }
                        >
                            <img src={icon_arrow_down_b} alt="" />
                        </a>
                    </li> :
                    <li className="prev no-more-page">
                        <a
                            href="/#prev"
                            onClick={ e => e.preventDefault() }
                        >
                            <img src={icon_arrow_down_g} alt="" />
                        </a>
                    </li>
            }
            {getPaginationLists(pagedCount)}
            {
                (next_page) ?
                    <li className="next">
                        <a
                            href={`/#next`}
                            onClick={ e => { e.preventDefault(); setCurrentPage(next_page);} }
                        >
                            <img src={icon_arrow_down_b} alt="" />
                        </a>
                    </li> :
                    <li className="next no-more-page">
                        <a
                            href={`/#next`}
                            onClick={ e => e.preventDefault() }
                        >
                            <img src={icon_arrow_down_g} alt="" />
                        </a>
                    </li>
            }
        </ul>
    );
};

export default Pagination;
