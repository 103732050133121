import React from 'react';
import Select from 'react-select';
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import {get_options, contain_not_only_whitespace, smoothly_scroll_to} from "../../../../assets/js/functions";
import {withNamespaces} from "react-i18next";
import { CSSTransitionGroup } from 'react-transition-group'
import InternalUri from "../../../models/internal_uri";

const config = new Config();
const headers = new ApiHeaders();
const internal_uri = new InternalUri();

class AddMemberModal extends React.Component {

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            active_company_id: -1,
            form_data: {
                email: '',
                title: '',
                roles_selected_value: '',
            },
            options: {},
            error_messages: {},
            loading: true,
            sending_add_member: false
        };
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        const { open, company_id } = next_props.site_state.add_member_modal;
        if(open !== this.props.site_state.add_member_modal.open) {
            if( company_id && open ) {
                this.setState((prev_state) => ({
                    ...prev_state,
                    active_company_id: company_id,
                    loading: true,
                    error_messages: {}
                }), () => this.fetchData());
            }
        }
    };

    fetchData() {
        const { active_company_id } = this.state;
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${active_company_id}/roles`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let new_options = [];
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                // TODO: 要取得 all roles (調整 per_page)
                const raw_roles = response.data.collection;
                new_options = get_options('roles', raw_roles);
                const default_role_id = this.props.site_state.add_member_modal.role_id;

                this.setState((prev_state) => ({
                    ...prev_state,
                    options: {
                        roles: new_options
                    },
                    form_data: {
                        email: '',
                        title: '',
                        roles_selected_value: (default_role_id !== -1) ? default_role_id : new_options[0].value
                    },
                    loading: false,
                }));
            })
            .catch((error) => {
                if(typeof error.response === 'undefined') {
                    window.location.reload();
                    this.props.updateConnectionOffModal(true);
                }

                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                this.setState((prev_state) => ({
                    ...prev_state,
                    loading: false,
                    error_messages:
                        (error.response && error.response.data && error.response.data.status === 'error') ?
                            error.response.data.errors :
                            {full_message: 'There was an error. Please try again later.'},
                }));

                smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
            });
    };

    handleInputChange = (e, property_name) => {
        e.preventDefault();
        let value = e.target.value;

        // if(property_name==='email') value = value.toLowerCase();

        this.setState((prev_state) => ({
            form_data: {
                ...prev_state.form_data,
                [property_name]: value
            }
        }));
    };

    handleSelectChange = (property_name, selected_option) => {
        this.setState((prev_state) => ({
            form_data: {
                ...prev_state.form_data,
                [property_name]: selected_option.value
            }
        }));
    };

    closeModal = e => {
        e.preventDefault();
        this.props.updateAddMemberModal(false, -1, -1);
    };

    submitForm = e => {
        e.preventDefault();

        const { form_data, sending_add_member, active_company_id } = this.state;
        if(contain_not_only_whitespace(form_data.email) && !sending_add_member) {
            this.setState({ sending_add_member: true });

            let formData = new FormData();
            formData.append('member_invitation[role_id]', form_data.roles_selected_value);
            formData.append('member_invitation[email]', form_data.email);
            formData.append('member_invitation[roleship_title]', form_data.title);

            let options = {
                method: 'POST',
                url: config.api_url(`/employers/${active_company_id}/member_invitations`),
                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                data: formData,
                json: true
            };
            // 對象未註冊 -> invited
            // 對象已註冊 -> joined
            axios(options)
                .then((response) => {
                    // console.log('invited success');
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    this.setState((prev_state) => ({
                        ...prev_state,
                        form_data: {
                            email: '',
                            title: '',
                            roles_selected_value: ''
                        },
                        options: {},
                        error_messages: {},
                        sending_add_member: false
                    }), () => {
                        this.props.history.replace(`${internal_uri.formatted_dashboard_member_management_page_path()}?refresh=true`);
                        this.props.updateAddMemberModal(false, -1, -1);
                    });

                    return new Promise(() => {});
                })
                .catch((error) => {
                    if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    if(error.response && error.response.data && error.response.data.data ) {
                        if(typeof error.response.data.data.user_exists !== 'undefined' && error.response.data.data.user_exists) {
                            // console.log('member should be join');
                            formData = new FormData();
                            formData.append('roleship[email]', form_data.email);
                            formData.append('roleship[title]', form_data.title);

                            options = {
                                method: 'POST',
                                url: config.api_url(`/employers/${active_company_id}/roles/${form_data.roles_selected_value}/roleships`),
                                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                                data: formData,
                                json: true
                            };
                            return axios(options);
                        }
                    } else {
                        // console.log('invited failed');
                        this.setState((prev_state) => ({
                            ...prev_state,
                            sending_add_member: false,
                            error_messages:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: 'There was an error. Please try again later.'},
                        }));

                        smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);

                        return new Promise(() => {});
                    }
                })
                .then((response) => {
                    // console.log('member joined');
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    this.setState((prev_state) => ({
                        ...prev_state,
                        form_data: {
                            email: '',
                            title: '',
                            roles_selected_value: ''
                        },
                        options: {},
                        error_messages: {},
                        sending_add_member: false
                    }), () => {
                        this.props.history.replace(`${internal_uri.formatted_dashboard_member_management_page_path()}?refresh=true`);
                        this.props.updateAddMemberModal(false, -1, -1);
                    });
                })
                .catch((error) => {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        sending_add_member: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
                });
        }
    };

    render() {

        const { site_state } = this.props;
        const { form_data, options, error_messages, sending_add_member, loading } = this.state;
        const { handleInputChange, handleSelectChange, closeModal, submitForm } = this;
        const { t } = this.props;

        return (
            <CSSTransitionGroup
                component="div"
                transitionName="modal-wrapper-slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {(site_state.add_member_modal.open) ?
                    <div className="modal-wrapper add-member-modal-wrapper">
                        <CSSTransitionGroup
                            component="div"
                            className="modal-inner-wrapper"
                            transitionName="modal-slide"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >
                            <div className="modal-bg" onClick={e => closeModal(e)}/>
                            {(!loading) ?
                                <div className="modal add-member-modal">
                                    <div className="btn-close" onClick={e => closeModal(e)}/>
                                    <h4 className="modal-title">{t('add_member')}</h4>
                                    {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                                    <form>
                                        <label htmlFor="email"><h5>{t('email')}</h5></label>
                                        <div className="form-row">
                                            <input
                                                className="input"
                                                type="email"
                                                placeholder={`${t('general:e_g__')} service@meet.jobs`}
                                                value={form_data.email}
                                                onChange={e => handleInputChange(e, 'email')}
                                                id="email"
                                            />
                                        </div>

                                        <label htmlFor="member-name-or-title"><h5>{t('title')}</h5></label>
                                        <div className="form-row">
                                            <input
                                                className="input"
                                                type="text"
                                                value={form_data.title}
                                                onChange={e => handleInputChange(e, 'title')}
                                                id="title"
                                            />
                                        </div>

                                        <label htmlFor="role"><h5>{t('role')}</h5></label>
                                        <div className="form-row">
                                            <div className="select-wrapper">
                                                <Select
                                                    className="drop-down drop-down-larger drop-down-grey"
                                                    name="form-field-name"
                                                    clearable={false}
                                                    searchable={false}
                                                    value={form_data.roles_selected_value}
                                                    onChange={ value => handleSelectChange('roles_selected_value', value) }
                                                    options={options.roles}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                    {(sending_add_member || !contain_not_only_whitespace(form_data.title) || !contain_not_only_whitespace(form_data.email)) ?
                                        <div className="buttons-wrapper">
                                            <button className="btn btn-flat btn-ghost" onClick={e => closeModal(e)}>
                                                <h5>{t('general:cancel')}</h5>
                                            </button>
                                            <button onClick={ e => e.preventDefault() } className="btn btn-flat btn-fill disabled">
                                                <h5>{t('general:add')}</h5>
                                            </button>
                                        </div> :
                                        <div className="buttons-wrapper">
                                            <button className="btn btn-flat btn-ghost" onClick={e => closeModal(e)}>
                                                <h5>{t('general:cancel')}</h5>
                                            </button>
                                            <button className="btn btn-flat btn-fill" onClick={e => submitForm(e)}>
                                                <h5>{t('general:add')}</h5>
                                            </button>
                                        </div>
                                    }
                                </div> : ''
                            }
                        </CSSTransitionGroup>
                    </div> : ''
                }
            </CSSTransitionGroup>
        );
    };
}

export default withNamespaces(['dashboard_member_management_page', 'general'])(AddMemberModal);
