export default {
    my_referral_link: "My referral link",

    refer_jobs_to_a_friend_and_get_reward_with_successful_hire_: "Refer jobs to a friend and get reward with successful hire.",
    view_jobs: "View jobs",

    ongoing_referrals: "Ongoing referrals",
    pending_invitation: "Pending invitation",
    talent: "Talent",
    date: "Date",
    applier: "Applier",
    applications: "Applications",
    job_title: "Job title",
    status: "Status",

    reference_status: "Reference status",
    claim_rewards: "Claim rewards",
};