import React from "react";
import {withNamespaces} from "react-i18next";
import ReactMinimalPieChart from "react-minimal-pie-chart";
import { FaRegQuestionCircle } from 'react-icons/fa';

const RateSummaryCard = props => {

    const { t, colors, text, leftRate, rightRate, loading } = props;

    const currentPercentage = rightRate ? Math.round(rightRate*1000)/10 : 0;

    if(loading) {
        return (
            <div className="loading-skeleton-wrapper">
                <div className="col-sm-6 col-md-6">
                    <div className="summary-card rate-summary-card block">
                        <div className="card-left">
                            <h4 className="text text-160">{`${t('general:loading')}...`}</h4>
                            <h2 className="text text-80">000%</h2>
                            <p className="text text-160">{`${t('general:loading')}...`}</p>
                        </div>
                        <div className="card-right">
                            <p className="text text-160">{`${t('general:loading')}...`}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="col-sm-6 col-md-6">
            <div className="summary-card rate-summary-card block">
                <div className="card-left" style={{ backgroundColor: colors.light }}>
                    <h4>{text.title}</h4>
                    {(leftRate!==null) ?
                        <h2 style={{ color: colors.main }}>{Math.round(leftRate*1000)/10}%</h2>
                        : <h3 className="no-data" style={{ color: colors.main }}>{text.noDataContent}</h3>
                    }
                    <p>{text.leftSubTitle}</p>
                </div>
                <div className="card-right">
                    <div className="pie-chart-wrapper">
                        <ReactMinimalPieChart
                            animate={true}
                            animationDuration={500}
                            animationEasing="ease-out"
                            cx={50}
                            cy={50}
                            data={[{
                                color: colors.main,
                                value: currentPercentage
                            }]}
                            totalValue={100}
                            background="#DFE4E9"
                            label={false}
                            lengthAngle={360}
                            lineWidth={20}
                            onClick={undefined}
                            onMouseOut={undefined}
                            onMouseOver={undefined}
                            paddingAngle={0}
                            radius={50}
                            rounded={false}
                            startAngle={-90}
                            viewBoxSize={[
                                100,
                                100
                            ]}
                        />
                        <h3 style={{ color: colors.main }}>{currentPercentage}%</h3>
                    </div>
                    <div className="text">
                        <p>{text.rightSubTitle}</p>
                        <span className="tooltip-wrapper">
                            <FaRegQuestionCircle className="react-icon icon-question-circle" />
                            <div className="tooltip long-text">
                                <h6 dangerouslySetInnerHTML={{__html: text.tooltipContent}} />
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces(['dashboard_index_page', 'general'])(RateSummaryCard);