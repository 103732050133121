import React from 'react';
import {withNamespaces} from "react-i18next";
import { Link } from "react-router-dom";
import InternalUri from "../../../models/internal_uri";

const Error500Page = ({t}) => {

    const internal_uri = new InternalUri();

    return (
        <div className="page-500 content-wrapper">
            <div className="container">
                <div className="diagram">
                    <div className="image-wrapper" />
                </div>
                <h1>{t('something_went_wrong_')}</h1>
                <p>{t('_500_server_error_sorry_for_the_inconvenience_feel_free_to_contact_us_if_this_problem_persists_')}</p>
                <Link
                    to={internal_uri.formatted_frontend_index_page_path()}
                    className="btn btn-larger btn-flat btn-hollow"
                >
                    <h5>{t('go_to_home_page')}</h5>
                </Link>
            </div>
        </div>
    );
}

export default withNamespaces(['frontend_error_500_page'])(Error500Page);