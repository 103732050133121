export default {
  // user_agreement
  user_agreement_title_0: `<span class="li-number">1.</span>Introduction and Definition`,
  user_agreement_content_0:
    `<h5>1.1. Our website (https://meet.jobs/) and all related derivative services are built, maintained and provided by the Taiwan firm &quot;Work Together Co., Ltd.&quot;.</h5>` +
    `<h5>1.2. Definition</h5>` +
    `<h6>1.2.1. &quot;Company&quot; means Taiwan firm “Work Together Co., Ltd.”.</h6>` +
    `<h6>1.2.2. "Website" and "System" refer to the website which Meet.jobs operates through information and electronic devices such as computers and mobile phones to provide member services.</h6>` +
    `<h6>1.2.3. "Services" refers to the job search and referral services provided by Meet.jobs through the Website, network and physical channels.</h6>` +
    `<h6>1.2.4. "Members" means an individual( including Natural Persons and Juridical Persons) who agrees to this Agreement and uses the Meet.jobs Services.</h6>` +
    `<h6>1.2.5. "Account" refer to the electronic identity information that a Members hold after the completion of registration at Meet.jobs.</h6>` +
    `<h6>1.2.6. "Password" means an authorization code that is generated by the Members or automatically generated by the website to enable Members to register and log in Meet.jobs Services.</h6>` +
    `<h6>1.2.7. "Visitor" means an individual who has not registered as a Members and can only use incomplete Website features and Services.</h6>` +
    `<h5>1.3. You agree that by clicking “Sign Up”, “Join Now”, “Subscription Service” or similar, registering, accessing or using our Services, you are agreeing to enter into a legally binding contract with Meet.jobs. If you do not agree to this Agreement, do not click “Sign Up” (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this Agreement, simply no longer access or use ours Services, or contact us to close your account.</h5>` +
    `<h5>1.4. This Agreement applies to our Website and all Services related to this Agreement. Meanwhile, This Agreement applies to both Members and Visitors.</h5>` +
    `<h5>1.5. As a Visitors or Members of our Services, the collection, use and sharing of your personal data is subject Privacy Policy and Cookie Policy.</h5>` +
    `<h5>1.6. We may modify this Agreement, our Privacy Policy and our Cookies Policy from time to time. If we make material changes to it, we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. We agree that changes cannot be retroactive. If you object to any changes, you shall no longer use or access ours Services, or contact us to close your account. Your continued use of our Services after our changes to these terms means that you are consenting to the updated terms.</h5>` +
    `<h5>1.7. Our membership Services can be divided into three parts: “Common Part”, “Job Search Part” and “Recommended Part”, which are explained in Articles 2, 3 and 4 of this Agreement.</h5>`,

  user_agreement_title_1: `<span class="li-number">2.</span>Common Part`,
  user_agreement_content_1: `
        <h5>2.1. Members are Account holders. You agree to: (1) try to choose a strong and secure password; (2) keep your password secure and confidential; (3) not transfer any part of your Account and (4) follow the law, this Agreement, our Privacy Policy and Cookie Policy. If you have an interest agreement with another person, such as an employment relationship, others may have access to part of the service information available to your Account, but the paying party still does not have the right to use your member account.</h5>
    
        <h5>2.2. If you use any of our paid Services, you agree to pay us the applicable fees, taxes and other fees. Failure to pay these fees will result in the termination of your paid Services. Also, you agree that:</h5>
        <h6>A. Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates), or other fees.</h6>
        <h6>B. We may store and continue billing your payment method (e.g. credit card) even after it has expired, to avoid interruptions in your Services and to use to pay other Services you may buy.</h6>
        <h6>C. We may calculate taxes payable by you based on the billing information that you provide us at the time of purchase.</h6>
        <h6>D. In the case of a continuous fee-based service, we have the right to charge before you cancel or suspend the Services on our System or by other method which can clearly record.</h6>
    
    
        <h5>2.3. You agree that we will provide notices and messages to you in the following ways: (1) within the Services, or (2) sent to the contact information you provided us (e.g., email, mobile number, physical address). You can manage the messages you receive from Meet.jobs directly from the Settings in our Website.</h5>
    
    
        <h5>2.4. You own the content and information that you submit or post to our Services, and you are only granting Meet.jobs following non-exclusive license:</h5>
        <h6>A. You can end this license for specific content by deleting such content from the Services, except (a) to the content you shared it with others as part of the Services and they can copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.</h6>
        <h6>B. We will not include your content in advertisements for the products and services of third parties to others without your separate consent (including sponsored content). However, we have the right, without payment to you or others, to serve ads near your content and information, and your social actions may be visible and included with ads, as noted in the Privacy Policy.</h6>
        <h6>C. While we may edit and make format changes to your content (such as translating it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.</h6>
        <h6>D. Because you own your content and information and we only have non-exclusive rights to it, you may choose to make it available to others.</h6>
        <h6>E. You agree that we may access, store, process and use any information and personal data that you provide in accordance with the terms of the Privacy Policy and your choices (including settings).</h6>
        <h6>F. By submitting suggestions or other feedback regarding our Services to Meet.jobs, you agree that Meet.jobs can use and share (but does not have to) such feedback for any purpose without compensation to you.</h6>
        <h6>G. You agree to only provide content or information that does not violate the law nor anyone’s rights (including intellectual property rights). You also agree that your profile information will be truthful. Meet.jobs may be required by law to remove certain information or content in certain countries. If the information you provide infringes others rights or intellectual property rights, you shall bear the relevant legal responsibilities.</h6>
    
    
        <h5>2.5. We may change or discontinue any of our Services. We don’t promise to store or keep showing any information and content that you’ve posted. Meet.jobs is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.</h5>
    
    
        <h5>2.6. By using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Meet.jobs generally does not review content provided by our Members or others. You agree that we are not responsible for others’ (including other Members’) content or information. We cannot always prevent this misuse of our Services, and you agree that we are not responsible for any such misuse. You also acknowledge the risk that you or your organization may be mistakenly associated with content about others when we let connections and followers know you or your organization were mentioned in the news.</h5>
    
    
        <h5>2.7. We reserve the right to limit your use of the Services, including the number of your connections and your ability to contact other Members. We reserve the right to restrict, suspend, or terminate your account if We believes that you may be in breach of this Agreement or law or are misusing the Services.</h5>
    
    
        <h5>2.8. We reserve all of its intellectual property rights in the Services. Using the Services does not give you any ownership in our Services or the content or information made available through our Services. Trademarks and logos used in connection with the Services are the trademarks of their respective owners. “Meet.jobs”, “meet.jobs”,  “Meet.Jobs” and other trademarks, service marks, graphics, and logos used for our Services are trademarks or registered trademarks of Meet.jobs.</h5>
    
    
        <h5>2.9. We will use the information and data that you provide and that we have about Members to make recommendations for connections, content and features that may be useful to you. For example, we use data and information about you to recommend jobs to you and you to recruiters. Keeping your profile accurate and up-to-date helps us to make these recommendations more accurate and relevant.</h5>
        `,

  user_agreement_title_2: `<span class="li-number">3.</span>Job Application`,
  user_agreement_content_2: `
        <h5>3.1. After registering as an individual member, you, may search, save or directly apply for job opportunities posted on the website through Meet.jobs.</h5>
            
            
        <h5>3.2. You may let recruiting enterprises, recruiters or headhunters find your information and contact you for job offers by setting up personal information and resume on Meet.jobs.</h5>
    
    
        <h5>3.3. Once you are offered a job through the Services of Meet.jobs, you will obtain a “Hiring Bonus” of USD 100.00 (or equivalent local currency) provided by Meet.jobs, and is obliged to inform Meet.jobs of the job offered. Meet.jobs also welcomes you to share your job application and work experience through all means to help more members.</h5>
    
    
        <h5>3.4. Job application and work shall be done with “integrity” and the attitude of mutual respect. If the business, institute or recruiting party requires you not to inform Meet.jobs of your offer in exchange of any benefits or conditions, you may report the same to Meet.jobs. Once the foregoing is found to be true, Meet.jobs will list the business as “not recommended” for its dishonest conduct and announce the same on the website. You may still obtain the “Report Appreciation” of USD 100.00 provided by Meet.jobs.</h5>
    
    
        <h5>3.5. Following the foregoing, if you request the business or the recruiting party to provide any benefits in exchange of not telling Meet.jobs regarding the offer, once found to be true, Meet.jobs will list you as “not recommended” due to your dishonest conduct and announce the same to the business users. Because this will severely affect your future job application and career development, we hope you will value your “integrity” and not violate the same.</h5>
    
    
        <h5>3.6. Following the foregoing, in the event your conduct violates general “integrity” and “respect”, such as making false statements on the job application materials or absenting from interviews without cause and notice or spread rumors regarding the recruiting enterprise, once found true, Meet.jobs will also list you as “not recommended” as announce the same to business users.</h5>
    
    
        <h5>3.7. For the aforementioned “Hiring Bonus” of USD 100.00, because the laws, regulations, tax rates, payment methods, and charges are all different in each country, Meet.jobs will only pay USD 100.00 or the equivalent local currency and will not bear the costs and expenses of delivering it.</h5>
    
    
        <h5>3.8. Save and except for the force majeure events, the “Hiring Bonus” will be delivered within 60 calendars days after reporting to work by cash or remittance.</h5>
        `,

  user_agreement_title_3: `<span class="li-number">4.</span>Referral`,
  user_agreement_content_3: `
        <h5>4.1. After registering as an individual member, you may share and refer job opportunities on the website through Meet.jobs. Once it is confirmed that the “referral is accepted,” and the referred applicant has successfully worked in that business, you will receive “Referral Reward.”</h5>
    
    
        <h5>4.2. 「The amount of the Referral Reward is US$200. Meet.jobs system will also label the amount of the Referral Reward directly.</h5>
    
    
        <h5>4.3. Payment Rule: The payment of Referral Reward, once “Successful Referral”, will be within the 20th day of the month, and the next business day if the 20th day is a non-working day, after the applicant has been recruited and begun to work for the employer.</h5>
    
    
        <h5>4.4. Following the foregoing, considering the circumstance that the referrer as an individual is representing a group, an institute, an organization, an enterprise or any other forms as juridical person, the referrer must hold the user account when “Successful Referral”. Otherwise, the Referral Reward should be given to the group, institute, organization, enterprise or any other forms as juridical person but not the individual person.</h5>
        
        <h5>4.5. Following the foregoing, while the referrer is not obliged to ensure that the recruiting business pays Meet.jobs service fee, if the business has not paid the fees, considering the payment costs, Meet.jobs will not pay the Referral Reward to the referrer. This situation usually occurs to business or employers who recruit through Meet.jobs for the first time, where the payment is delayed due to paperwork. We appreciate your understanding that Meet.jobs needs to pay the Referral Reward after receiving fees from the business.</h5>
    
    
        <h5>4.6.  “Successful Referral” Definition and Function</h5>
        <h6>4.6.1. Referrer may generate his/her own “referral code” and “referral link” through the refer and share function of the Meet.jobs website. By sharing the referral code (or the referral link) to the applicant and making the applicant to apply for the job through Meet.jobs or registering a resume to be searched by the recruiting business, and the recruiting business confirms its employment on the system and the applicant has employed by the recruiting business within 90 calendar days, it will be “successful referral.”</h6>
        <h6>4.6.2. Following the foregoing, where the referrer obtains the applicant’s consent, s/he may use the application function of Meet.jobs website and submit the resume on behalf of the applicant to apply for the job. However, this referrer must obtain the applicant’s authorization of personal information. In the event there is any legal issues and damages deriving from the non-authorization, Meet.jobs is not liable therefor.</h6>
        <h6>4.6.3. The referrer needs to share according to information about the business and the job published by the recruiting business on the Meet.jobs platform. The referrer needs to ensure the shared job and business information, including texts, pictures, and videos, are truthful and not exaggerated, false, leaking business secrets. In the event there is any legal issues and damages deriving from the falsity, exaggeration, counterfeit, leakage of business secrets, Meet.jobs is not liable therefor.</h6>
        <h6>4.6.4 The foregoing “exaggerated, false, leaking business secrets” content not suitable for referral and sharing will be determined unilaterally by Meet.jobs and ruled. The referrer may not have any objections.</h6>
        <h6>4.6.5 Following the above, if the shared content has any exaggerated, false, leaking business secrets information which causes disputes or any legal issues between the labor and the employer during the recruiting period or even the trial period, Meet.jobs is not liable therefor. In the event Meet.jobs suffers any damages therefrom, the referrer shall be liable to Meet.jobs.</h6>
    
    
        <h5>4.7. Confidentiality of the Referrer</h5>
        <h6>4.7.1. When the applicant is applying for the job, it will be able to see the username of the referrer on Meet.jobs system. Save and except for that, the applicant will not be able to obtain any personal and contact information of the referrer from Meet.jobs.</h6>
        <h6>4.7.2. To protect the referrer’s privacy, the referrer’s information regarding the business will be shown as anonymous by default, and will not involve any information relevant to any users. If the referrer is willing to, s/he may choose to show his/her contact information to the business so that the business may contact the referrer to confirm the recruiting status, but the foregoing is not necessary.</h6>
        <h6>4.7.3. Following the foregoing, if the referrer is willing to, s/he may choose to reveal the conversation between him/her and the business to the applicant for the applicant’s understanding to the questions raised by the business and the referrer’s answers, but the foregoing is not necessary.</h6>
    
    
        <h5>4.8 If the referrer is the user of Meet.jobs website and the relevant software service, the parties are both independent individuals without employment, agent, hire for work, partnership. If the referrer has any legal disputes caused by abuses or incorrect uses of the referral and sharing mechanism of Meet.jobs, Meet.jobs is not liable therefor. In the event Meet.jobs suffers any damages therefrom, the referrer shall be liable to Meet.jobs.</h5>
    
    
        <h5>4.9 Following the foregoing, Meet.jobs is entitled to terminate the referral and sharing authorization of a username.</h5>
    
    
        <h5>4.10 For the aforementioned Referral Reward, because the laws, regulations, tax rates, payment method, and charges are different in each country, Meet.jobs will only pay the amount of the reward and will not bear the costs and expenses of its delivery.</h5> 
        `,

  user_agreement_title_4: `<span class="li-number">5.</span>Disclaimer and Limit of Liability`,
  user_agreement_content_4: `
        <h5>5.1. TO THE EXTENT ALLOWED UNDER LAW, MEET.JOBS (A) DISCLAIM ALL IMPLIED WARRANTIES AND REPRESENTATIONS (E.G. WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY OF DATA, AND NONINFRINGEMENT); (B) DO NOT GUARANTEE THAT THE SERVICES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS, AND (C) PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS.</h5>
    
    
        <h5>5.2. TO THE EXTENT PERMITTED UNDER LAW, MEET.JOBS SHALL NOT BE LIABLE TO YOU OR OTHERS FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF DATA, OPPORTUNITIES, REPUTATION, PROFITS OR REVENUES, RELATED TO THE SERVICES (E.G. OFFENSIVE OR DEFAMATORY STATEMENTS, DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT).</h5>
    
    
        <h5>THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE BARGAIN BETWEEN YOU AND MEET.JOBS AND SHALL APPLY TO ALL CLAIMS OF LIABILITY (E.G. WARRANTY, TORT, NEGLIGENCE, CONTRACT, LAW) AND EVEN IF MEET.JOBS HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.</h5>
        `,

  user_agreement_title_5: `<span class="li-number">6.</span>Termination`,
  user_agreement_content_5: `
            <h5>6.1. Both you and Meet.jobs may terminate this Agreement at any time, provided that you notify the other party in writing or in other form which can be recorded. On termination, you lose the right to access or use the Services. The following shall survive after termination:</h5>
            <h6>A. Our rights to use and disclose your feedback;</h6>
            <h6>B. Members and/or Visitors’ rights to re-share content and information you shared through the Service in the extent copied or re-shared prior to termination;</h6>
            <h6>C. Sections 5, 7, and 8 of this Agreement;</h6>
            <h6>D. Any amounts owed by either party prior to termination remain owed after termination.</h6>  
        `,

  user_agreement_title_6: `<span class="li-number">7.</span>Governing Law and Dispute Resolution`,
  user_agreement_content_6: `
        <h5>7.1. Unless otherwise stipulated by laws and regulations, You and Meet.jobs agree that any dispute arising from this Agreement and/or “Services,” will be governed by the relevant laws and regulations of the Republic of China and shall be submitted to Taiwan Taipei District Court for the first instance.</h5>
    
    
        <h5>7.2. If the Agreement has any parts or terms that cannot be performed, the parties both agree that the terms of the Agreement shall be revised in a way feasible and consistent with the original ends. If the revision is impossible, the parties agree to remove those parts incapable of being performed, and perform the rest of the term in the Agreement.</h5>
    
    
        <h5>7.3. Within the extent permissible by laws, both the English version and the Traditional Chinese version are legally binding and other translations are only for the convenience of understanding. In the event there is any discrepancy in the clauses and terms between the different versions, then the Traditional Chinese version shall prevail. This Agreement is the final and complete consent of the parties and replaces any oral or written promises, understanding or other expresses submitted during the negotiation. The title of terms of this Agreement is only for reference, the parties agree that the title may not be used as the basis of interpretation.</h5>
    
    
        <h5>7.4. If Meet.jobs does not take legal actions for breaches, it does not mean that Meet.jobs waive the right to perform this Agreement. Client may not assign or transfer this Agreement (or membership or use of service) without the consent of Meet.jobs. However, the Client agrees that Meet.jobs may assign this Agreement to relevant institutes or institutes purchases Meet.jobs.</h5>
    
    
        <h5>7.5. You agree that the address specified in Article 10 will be the only way to provide Meet.jobs a legal notice.</h5>
        `,

  user_agreement_title_7: `<span class="li-number">8.</span>Code of Practice for Meet.jobs`,
  user_agreement_content_7: `
        <h5>8.1. You agree to use Meet.jobs in accordance with the following principles:</h5>
        <h6>A. Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;</h6>
        <h6>B. Provide accurate information to us and keep it updated;</h6>
        <h6>C. Use your real name on your profile; and</h6>
        <h6>D. Use the Service in a professional manner and with the care of a good administrator.</h6>
    
    
        <h5>8.2. You agree that you will not have the following behavior when using Meet.jobs:</h5>
        <h6>A. Create a false identity on Meet.jobs, misrepresent your identity, create a profile that is not your own (non-real person), or use or attempt to use another’s account;</h6>
        <h6>B. Develop, support or use software, devices, scripts, robots, or any other means or processes (including crawlers, browser plugins and add-ons, or any other technology) to scrape the Services or copy profiles and other data from the Services;</h6>
        <h6>C. Override any security feature or bypass or circumvent any access controls or use limits of the Services;</h6>
        <h6>D. Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of Meet.jobs;</h6>
        <h6>E. Disclose information that you do not have the consent to obtain (such as confidential information of others (including your employer));</h6>
        <h6>F. Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets, or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the work information, posts or other content of others without their permission;</h6>
        <h6>G. Violate the intellectual property or other rights of Meet.jobs, including, without limitation, (i)copying, modifying or distributing our articles, pictures, videos and other materials as commercial without consent or (ii) copying or distributing our technology, unless it is released under open source licenses; (iii) using the word “Meet.jobs” or our logos in any business name, email, or URL without consent;</h6>
        <h6>H. Post anything that contains software viruses, worms, or any other harmful code;</h6>
        <h6>I. Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;</h6>
        <h6>J. Imply or state that you are affiliated with Meet.jobs or have obtained or will obtain an endorsement of Meet.jobs without consent;</h6>
        <h6>K. Rent, lease, loan, trade, sell/re-sell Services or related data or their access rights, or otherwise use the Services or related data or their access rights to earn revenue, without consent;</h6>
        <h6>L. Deep-link to our Services for any purpose other than to promote your profile or group on our Services, without consent;</h6>
        <h6>M. Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages;</h6>
        <h6>N. Monitor the Services’ availability, performance or functionality for any competitive purpose;</h6>
        <h6>O. Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services;</h6>
        <h6>P. Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included on the Services);</h6>
        <h6>Q. Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms). </h6>
        `,

  user_agreement_title_8: `<span class="li-number">9.</span>Complaints Regarding Content`,
  user_agreement_content_8:
    `<h5>9.1. We respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties.</h5>` +
    `<h5>9.2. You can contact Meet.jobs through Section 10 "Contact Information " to complain about the above violation of intellectual property rights and all violations of this Agreement.</h5>`,

  user_agreement_title_9: `<span class="li-number">10.</span>Contact`,
  user_agreement_content_9:
    `<h5>Online: Reply robot, message board or emails us at service@meet.jobs.</h5>` +
    `<h5>Mailing address: 8 Claymore Hill #03-01 Singapore 229572 or 2nd Floor, No. 2, Lane 59, Yitong Street, Zhongshan District, Taipei 10486, Taiwan.</h5>`,

  // employer_agreement
  employer_agreement_head_content:
    `<p>This Agreement is authorized by</p>` +
    `<p>` +
    `Name: __________(hereinafter, such as “Party A,” “Client,” “Employer,” “Buyer,” and “Recruiting Business”)<br>` +
    `Telephone: __________<br>` +
    `Email: __________` +
    `</p>` +
    `<p>to WORK TOGETHER CO., LTD. (hereinafter, such as “Meet.jobs,” “Party B,” “Service Provider,” “Seller”) for the assistance of recruiting talents through the platform and service owned thereby.</p>` +
    `<br>`,

  employer_agreement_section_title_0: "Recitals and Explanations",
  employer_agreement_part_0: `<h5>1. This Agreement is to be used when the WORK TOGETHER CO., LTD. and its website, platform and online service provides solution service to talent recruitment. </h5>
    <h5>2. All explanations and definitions of the terms under this Agreement are the same as those in the “User Agreement”, “Privacy Policy” and all relevant documents of Meet.jobs. </h5>
    <h5>3. Client must have the contact person responsible for recruitment registered as a member of the Meet.jobs service to further register a business account. </h5>
    <h5>4. Except for the agreements separately reached by the Client and Meet.jobs in writing, all information will be recognized as that recorded on the Meet.jobs website, system and  extended service. </h5>`,
  employer_agreement_section_title_1: "Obligations of Client",
  employer_agreement_part_1: `
    <h5>5. The Client does not need to pay for posting expenses. </h5>
    <h5>6. Clients shall adopt the service plan from those stated on Meet.jobs' website, platform and service content. Only one plan may be adopted at a time for the same corporate account. The plan selected at a given time shall be determined based on the record displayed on the service system of Meet.jobs. </h5>
    <h5>7. The Client is able to post the recruiting job information to the Meet.jobs website and all extended web platforms and guarantee the information provided is correct and without mistakes. If the information provided by the Client is incorrect and causes damages to Meet.jobs, the Client shall be liable to Meet.jobs for the damages; in the event applicants suffer damages incurred therefrom, the Client shall be liable and Meet.jobs will not be involved. </h5>
    <h5>8. When the Client publishes an opening through the service of Meet.jobs, the bottom line is that the salary range or lower limit of the salary needs to be published. </h5>
<h6>Note: “Trust” is a core value which Meet.jobs believes, and “transparent information” could allow the talents to sufficiently trust the employer. When the employer provides information as transparent, complete, and accurate, it will be able to recruit a suitable employee more efficiently through Meet.jobs. Contrarily, if there any information related to the employer and/or to the job description including and especially about the salary information cannot be revealed openly, such talent demand will only be served under the term of Hunter Plan, and the service fee will be adjusted according to how open the information can be published.</h6>
<h5>9. The Client may, based on advice from professional consultants of Meet.jobs, provide information necessary and relating to the recruitment, including, without limitation,  videos, pictures, recordings, text messages, and interviews and/or resumes of current  employees, hiring manager or business founder. </h5>
<h5>10. The materials mentioned under the preceding section do not include information and materials that need to be kept confidential due to the Client’s business concerns.  Including the Client’s company name, Meet.jobs needs to obtain the employer’s permit in any recordable form to publish such information. If it is published without permit, the Client is entitled to immediately terminate the Agreement. In the event the Client suffers any damages therefrom, Meet.jobs will be liable for all damages and legal responsibilities. </h5>
<h5>11. Clients are required to respond to job seekers' application messages, including rejection letters (i.e., thank you letters), on Meet.jobs website, platform, and online services. In particular, if a job application is not rejected and the status is still shown as “applying," Meet.jobs will retain the right to charge a fee regardless of the length of time that has elapsed and whether or not the same job position is filled; once the client has  hired the candidate, it is deemed to have been hired through Meet.jobs. </h5>
<h5>12. Clients shall reply to the job seeker or his/her referrer within 3 business days to confirm whether to accept the candidate. If the acceptance is not confirmed on the same date as the candidate's commencement of employment, please inform Meet.jobs of the candidate’s commencement date of employment by means capable of leaving a record.</h5>
<h5>13. When confirming the acceptance of an application, the client shall provide the candidate’s salary information, including but not limited to the Offer Letter, employment agreement, and salary slip, as the basis for Meet.jobs' charges. </h5>
<h5>14. The client needs to complete the payment within 30 calendar days after receipt of the invoice. </h5>
`,
  employer_agreement_section_title_2: "Obligations of Meet.jobs",
  employer_agreement_part_2: `
    <h5>15. After the client registers for a corporate account on the Meet.jobs system, a Meet.jobs professional consultant will contact the client within one business day to provide  assistance. </h5>
    <h5>16. Meet.jobs will, based on its professional judgment and depending on the recruiting needs of the Client, provide target marketing, text or video interview, online or offline  promotion, community referral to assist the Client in recruiting. </h5>
    <h5>17. Meet.jobs will share and promote the Client’s business and job openings information on social networks, including, without limitation, Facebook, LinkedIn, YouTube,  Instagram, WeChat and TikTok, as well as other websites or networks specially required by the  Client. </h5>
    <h5>18. In the event any additional expenses derive from the recruitment plan provided by Meet.jobs, then after obtaining the Client’s consent shown in any recordable form, the  parties will agree upon the party bearing such expenses and then separately agree upon  the details of payment, certificates, and reimbursement. </h5>
    <h5>19. Meet.jobs shall track the status of each job application until the candidate’s application is accepted, canceled, or rejected by the client. If a client has unread and/or unprocessed job seekers, Meet.jobs shall remind the client to process them as soon as possible by  means capable of leaving a record. </h5>
    <h5>20. After the candidate’s commencement of employment is confirmed, Meet.jobs shall issue an invoice based on the salary information provided by the client, and has an obligation to advise the clients on the payment method that is most convenient and cost effective.</h5>
    
    <h5>21. Once the client has completed the payment, Meet.jobs shall confirm and inform the client within 3 business days. </h5>
`,
  employer_agreement_section_title_3: "Recognition of employment",
  employer_agreement_part_3: `
    <h5>22. Employment on Meet.jobs service may be recognized in the following three ways: </h5>
<h6>A. Client confirmation: Client may directly confirm the employment on Meet.jobs system or inform it in any recordable form.</h6>
<h6>B. Client confirmation after the applicant reports: Once employed the job, the applicant may report the employment on the Meet.jobs system and Meet.jobs system will automatically confirm with the Client about the employment. The client may confirm the employment on the Meet.jobs system or inform it in any recordable form.</h6>
<h6>C. Client confirmation after the referrer reports: If the applicant has a referrer, the referrer may report the employment on the Meet.jobs system, and the Meet.jobs system will automatically confirm with the Client about the employment. The Client may confirm the employment on Meet.jobs system or inform it in any recordable form.</h6>
<h5>23. Based on the above, when confirming the acceptance of a candidate, the client shall also inform the candidate of the commencement date of employment, which shall be the specific date the candidate commences employment. If there is any change in the commencement date of employment during the period, it shall be agreed by both parties by means capable of leaving a record. </h5>
<h5>24. In the event applicant and referrer report the employment under the preceding section, if confirmed as “not hired,” the Client needs to reply the same on the Meet.jobs system within 3 workdays, or inform Meet.jobs in any recordable form, failing which Meet jobs will  reserve the right to charge. </h5>
<h5>25. If the applicant is not hired for the Client’s job, the Client needs to “reject” its application on the Meet.jobs system. In the event there is any applicant not refused, it will be deemed as in the “applying” status. As such, however long the time has passed, once hired, Meet.jobs is still entitled to charge. </h5>
<h5>26. If the applicant is in the status of “applying”, then once employed with the Client’s opening, whether or not it is employed with the position originally applied for, Meet.jobs will be entitled to the charge. Namely, if the Client does not intend to hire such an applicant, it must “reject” the applicant on Meet.jobs, and please refrain from committing any conduct damaging the Client’s goodwill and trust such as “silent refusal”.</h5>
<h5>27. Applicants who apply through the Meet.jobs system, and any candidate referred by Meet.jobs in any recordable form, once hired, Meet.jobs is entitled to charge the Client therefor. In addition, this is irrelevant to whether or not the employer learned about this candidate through other channels, and Meet.jobs is still entitled to charge. </h5>
<h5>28. If the candidate has applied for a job through other channels and is still “applying”, then the candidate will not be counted as a referral of Meet.jobs if he/she applies separately through Meet.jobs. Note that the term “applying” used herein refers to “The client has contacted the candidate for interview through other channels, and the candidate  has responded to accept the interview with contact records, while such application has  not been ‘rejected’ or ‘canceled,’ and the duration between the last ‘contact between the  client and the candidate’ and the ‘date on which the candidate applied through Meet.jobs’  is within 10 business days.” Under such circumstances, it will be deemed that the candidate’s previous application through other channels has not been closed, and Meet.jobs will not charge service fees. </h5>
<h5>29. If the Client considers that the hired applicant was not employed through the service of Meet.jobs, it needs to obtain the applicant’s confirmation in any recordable form. In this situation, Meet.jobs will not charge service fees. </h5>
`,
  employer_agreement_section_title_4: "Work period guarantee",
  employer_agreement_part_4: `
    <h5>30. For those candidates who are accepted through Meet.jobs, Meet.jobs guarantees that such candidates will work for a period not less than 30 calendar days; however, this shall  not apply to the case where the client and Meet.jobs will otherwise agree and keep records  of such agreement. </h5>
    <h5>31. If the candidate resigns within the first 30 calendar days, then Meet.jobs needs to refund 50% of the service fee no matter which recruitment plan the client has adopted.</h5>
    <h5>32. Following the foregoing, Meet.jobs will help the client to recruit the substitute candidate and charge the full service fee for the substitute candidate of employment.</h5>
    <h5>33. Specifically, if such an offeree is disemployed by the Client during the trial period due to work performance within the first 30 calendar days, it will not be within the guaranteed scope of Meet.jobs. </h5>
    <h5>34. If the client has a customized demand, such as extending the guarantee period, please adopt the Hunter Plan and Meet.jobs will adjust the service fee based on the customized demand.</h5>
`,
  employer_agreement_section_title_5:
    "Actual Salary (Annual salary) Recognition",
  employer_agreement_part_5: `
    <h5>35. Once confirmed hired, the Client needs to provide the salary information, such as employment agreement, offer letter or payment slip as evidence as the basis for Meet.jobs to charge. If such information is provided by the applicant or referrer, it needs to obtain the Client’s confirmation thereof in any recordable form. </h5>
    <h5>36. Meet.jobs is obliged to keep the foregoing salary information confidential, and such information may only be used as the evidence of charging service fees. In the event the information is disseminated, causing the Client’s damages, Meet.jobs needs to be liable therefor. </h5>
    <h5>37. Clients may select the calculation method of annual salary, monthly salary, daily salary or hourly salary when posting vacancies for job positions through the Meet.jobs system.  Meet.jobs will charge service fees in accordance with different service plans selected by the client. </h5>
    <h5>38. If the salary for the job position is calculated on an annual basis, the actual salary shall be the sum of full-time employment offered and stated on the official Offer Letter or employment agreement. </h5>
    <h5>39. If the salary for the job position is calculated on a monthly basis, the sum of the actual salary shall be the sum of full-time employment offered and stated on the official Offer Letter or employment agreement times by the number of months guaranteed for the job position (for example, 14 months. If it has not been specified, it shall be 12 months.) If the months of guaranteed salary are less than 12 months, it shall be calculated as 12 months. </h5>
    <h5>40. If the salary for the job position is calculated on a daily basis, the sum of the actual salary shall be the sum of full-time employment offered and stated on the official Offer Letter or employment agreement times by 20 (number of working days per month) and times by the number of months guaranteed for the job position by the client (for example,  14 months. If it has not been specified, it shall be 12 months.) If the months of guaranteed salary are less than 12 months, it shall be calculated as 12 months. </h5>
    <h5>41. If the salary for the job position is calculated on an hourly basis, the sum of the actual salary shall be the sum of full-time job offered and stated on the official Offer Letter or employment agreement times by 160 (number of working hours per month) and times by  the number of months guaranteed for the job position by the client (for example, 14  months. If it has not been specified, it shall be 12 months.) If the months of guaranteed salary are less than 12 months, it shall be calculated as 12 months. </h5>
    <h5>42. When clients post their job vacancies in the Meet.jobs system, they may choose four different types of jobs which are full-time, part-time, internship and volunteer work. For those who choose a "full-time" job for the job vacancies, regardless of whether it is an indefinite agreement or a fixed-term agreement, the actual salary shall be calculated in  accordance with the aforesaid methods. </h5>
    <h5>43. Following the foregoing, if the position is listed on the system as “part-time” or “internship” job, the salary of the position is recognized as 60% of the “full-time” position under the preceding section. However, the salary of the position is still recognized as a “full-time” job, while the position on the system is targeted to a full-time employment. </h5>
    <h5>44. If the salary during the probation period is different from the salary during the regular employment, the salary during the regular employment should be used as the basis for calculating the actual salary. </h5>
`,
  employer_agreement_section_title_6: "Payment",
  employer_agreement_part_6: `
    <h5>45. Once the Client confirms the employment on the Meet.jobs system and informs it of the salary information, Meet.jobs will provide an invoice and the document required for payment (such as receipt). The client needs to complete the payment within 30 calendar days after receipt of the invoice. </h5>
    <h5>46. Meet.jobs now accepts two payment methods of “credit cards”,  “remittance” and “digital currency” among which the Client may choose therefrom and the same will be shown on the Meet.jobs system.</h5>
    
    <h5>47. If the Client chooses a credit card, then it will only need to register the credit card information on the Meet.jobs system. Meet.jobs will issue invoice according to the employment confirmation and charge on the monthly settlement day (the 5th day of each month). Credit card is the best payment method recommended by Meet.jobs. </h5>
    <h5>48. If the Client chooses to remit the amount, then Meet.jobs will provide a bank account for the Client to remit the payment. </h5>
    <h5>49. Meet.jobs is responsible for informing the Client with the most convenient and cost-effective payment methods. Meet.jobs charges "full-service fee". In the event there is any derivative expenses, such as charges, remittance fees, and all relevant taxes, it will be  borne by the Client. </h5>
`,
  employer_agreement_section_title_7: "*Social Plan",
  employer_agreement_part_7: `
    <h5>50. The fee for the Social Plan shall be 16% of the actual annual salary of the recruited position, and the service fee shall be charged to the client based on the confirmation of  the candidate's commencement of employment. </h5>
    <h5>51. Clients that adopt the Social Plan will obtain the information of all appropriate candidates recommended to users by Meet.jobs. Once the recommended candidate is successfully accepted, Meet.jobs will provide a congratulatory gift of money for commencement of employment, and/or a gratitude gift of money for recommendation, etc., and invite the candidate to share their experience for acceptance in the recruitment process. </h5>
    <h5>52. For clients that adopt the Social Plan, Meet.jobs will use its own professional judgment to conduct targeted marketing and promotion in online and offline communities based on clients’ information regarding enterprises themselves and their job vacancies. </h5>
    <h5>53. Clients that adopt the Social Plan may participate in online and offline integrated marketing recruitment activities organized by Meet.jobs. If there is any other expense derived from such an event, it shall be directly discussed and agreed by both parties by means capable of leaving a record. </h5>
    <h5>54. The Social Plan Agreement is a standard service agreement, and any modification to the agreement shall not be acceptable. However, the details not clearly stated in the agreement may be interpreted by both parties in the form of an agreement supplement.  However, the content of the agreement supplement shall not violate the content and spirit of the master agreement. In the event of any conflict, the content of the agreement supplement shall automatically become invalid. </h5>
    <h5>55. If a client desires to switch to the Social Plan from other plans, it needs to separately notify Meet.jobs in writing or by means capable of leaving a record, and the system administrator will help it perform the operation on its behalf. </h5>
`,
  employer_agreement_section_title_8: "*Hunter Plan",
  employer_agreement_part_8: `
    <h5>56. The fee for the Hunter Plan shall be 20% or more of the actual salary of the recruited position, and service fee shall be charged to the client based on the confirmation of the  candidate’s commencement of employment. </h5>
    <h5>57. Clients that adopt the Hunter Plan may be entitled to all the service content under the Social Plan. In addition, the talent hunting consultants deployed by Meet.jobs will actively assist with recommending and selecting candidates. </h5>
    <h5>58. For clients that adopt the Hunter Plan, professional talent hunting consultants of Meet.jobs will provide advice on the work content, salary range and recruitment methods (such as events) of the recruited position. </h5>
    <h5>59. For clients that adopt the Hunter Plan, Meet.jobs will assist with conducting talent screening tests, such as language, professional skills, and adaptive testing; however, it must confirm the test methods and standards in detail with the client. </h5>
    <h5>60. For other customized services, such as poaching candidates, salary negotiations, guaranteeing the number of interviews, etc., Meet.jobs will charge 20% or more of the service fee depending on the needs of the service content. Fees and collecting methods shall be discussed and agreed by both the client and Meet.jobs by means capable of leaving a record. If necessary, a supplementary agreement may be made for recording. </h5>
    <h5>61. In the event the Client has already chosen other plans and wishes to upgrade to Hunter Plan, it only needs to select Hunter Plan on Meet.jobs system or inform Meet.jobs in recordable form. After confirming the Client’s needs to upgrade the plan, Meet.jobs will have a professional consultant contacting the Client to provide assistance.</h5>
    `,
  employer_agreement_section_title_9: "Ethics Statement",
  employer_agreement_part_9: `
    <h5>62. The success of talent recruitment often determines the success of a business. A business could successfully recruit excellent talent based on the mutual trust among the business, talents and service provider. As such, if an applicant or its referrer requests the Client to provide any extra compensation, bonus or benefits in other forms under the condition of “not reporting the employment to Meet.jobs,” Meet.jobs strongly advises the Client not to hire such an applicant. In the event such applicant or referrer is reported to Meet.jobs, Meet.jobs will be responsible for the investigation thereof, and once confirmed to be true, such an applicant or referrer will be listed as “excluded,” and be informed to all of Meet.jobs’ business clients. </h5>
    <h5>63. If the Client actively offers benefit to applicant or referrer in any form under the condition of “not reporting the employment to Meet.jobs,” the applicant or referrer is also entitled to report such a business. Once confirmed to be true, considering such a conduct is in violation of the integrity of the recruitment, Meet.jobs will announce the Client’s name on the website and list it as “not recommended.” </h5>
    <h5>64. If the Client cannot complete the payment within 30 calendar days after receipt of invoice, it needs to obtain Meet.jobs consent in recordable form and the parties will agree on a payment day. If the Client intends to delay or does not pay in time without Meet.jobs’ consent, Meet.jobs will announce the business name on the website and list it as “not recommended.” </h5>
    <h5>65. If the information of the opening provided by the Client is inconsistent with the condition of the final offer, especially where the offered salary is lower than the lowest salary in the recruiting salary range on the system, the Business needs to proactively  submit an explanation in writing or other recordable form within 72 hours and obtain the  applicant’s and the referrer’s consent statement as evidence. If the applicant’s and the referrer’s consent is not obtained and the same happens for more than three times, Meet.jobs will announce the Client’s name on the website and list it as “not recommended.” </h5>`,
  employer_agreement_section_title_10:
    "Confidentiality of Personal Information",
  employer_agreement_part_10: `
    <h5>66. For applicants answering the post through or referred by Meet.jobs, all of their information including resume, portfolio, technical test results will only be provided to the Client to assess whether the applicant is hired or not and the Client may not use the same otherwise. In the event any damages are caused to the applicant, the Client will be liable to the applicant therefor and Meet.jobs is not involved. </h5>
`,
  employer_agreement_section_title_11: "Execution",
  employer_agreement_part_11: `
    <h5>67. Meet.jobs provides the following methods for the Client to sign this Agreement and the Client only needs to adopt one. </h5>
<h6>A. Directly select agree to the agreement on Meet.jobs system. This is recommended by Meet.jobs to be legally binding and the most efficient.</h6>
<h6>B. Adopt a third-party electronic signing system acknowledged by Meet.jobs, and upload the same to Meet.jobs system after execution.</h6>
<h6>C. Download the agreement already executed by Meet.jobs and printed by the Client, and scan and upload the executed version to Meet.jobs system.</h6>
<h6>D. Select sending hardcopy on Meet.jobs system and fill in the receipt address. Meet.jobs will send the executed hard copies in two counterparts after receipt of the request. After the Client executes the same, scan and upload it to Meet.jobs system, and send a hard copy back to Meet.jobs for reference.</h6>
`,
  employer_agreement_section_title_12: "Amendment and Termination",
  employer_agreement_part_12: `
    <h5>68. No matter which plan is selected, as long as the job vacancy is not posted or the job vacancy is closed, the client shall not incur additional expenses except for the unfulfilled  obligations. Thus, in most cases, there is no need to terminate this agreement. </h5>
    <h5>69. If necessary, both Meet.jobs and the client shall have the right to unilaterally terminate this agreement. If either Party desires to terminate this agreement, it shall inform the other Party within one month before the termination date. And this agreement may be terminated only after all obligations of this agreement have been fulfilled. At that time, Meet.jobs will also remove the client's all information on the system. However, since the information obtained via third-party platforms such as Google are used for SEO ranking purposes, such information cannot be controlled or modified by Meet.jobs.</h5>
    
    <h5>70. All obligations stated in the preceding paragraph shall include all matters with regard to offer and confirmation of employment, payment of fee, provision of information, and  closure of information. </h5>
    <h5>71. If Client has breached the agreement or violated the law, Meet.jobs can remove the job vacancies posted by Client and can immediately terminate the agreement. </h5>
    <h5>72. In the event of force majeure events, if either party cannot perform the agreement due to disasters, political events, religion, racial and other conflicts, the party is not legally  liable to the other party. </h5>
    <h5>73. Except for the Hunter Plan that may have a customized plan, if Meet.jobs has any revision to the Agreement, it will inform the Client through the system and recordable  form. In the event the Client disagrees with the revised content, under the general circumstances, it only needs to cease to use Meet.jobs services. </h5>
    <h5>74. Meet.jobs reserves the right to accept or reject the employer profile and the job vacancy that the Client wants to post. </h5>
`,
  employer_agreement_section_title_13: "Governing Law and Dispute Resolution",
  employer_agreement_part_13: `
    <h5>75. Client and Meet.jobs agree that any dispute arising from this Agreement and/or “services,” will be governed by the relevant laws and regulations of the Republic of China  and shall be submitted to Taiwan Taipei District Court for the first instance. </h5>
    <h5>76. If the Agreement has any parts or terms that cannot be performed, the parties both agree that the terms of the Agreement shall be revised in a way feasible and consistent  with the original ends. If the revision is impossible, the parties agree to remove those parts incapable of being performed, and perform the rest of the term in the Agreement. </h5>
    <h5>77. Within the extent permissible by laws, both the English version and the Traditional Chinese version are legally binding and other translations are only for the convenience of  understanding. In the event there is any discrepancy in the clauses and terms between the different versions, then the Traditional Chinese version shall prevail. This Agreement is the final and complete consent of the parties and replaces any oral or written promises,  understanding or other expressions submitted during the negotiation. The title of terms of this Agreement is only for reference, the parties agree that the title may not be used as the basis of interpretation. </h5>
    <h5>78. If Meet.jobs does not take legal actions for breaches, it does not mean that Meet.jobs waive the right to perform this Agreement. The client may not assign or transfer this Agreement (or membership or use of service) without the consent of Meet.jobs. However, the Client agrees that Meet.jobs may assign this Agreement to relevant institutes or institutes purchase Meet.jobs. </h5>
    <h5>79. You agree that the address specified in this agreement will be the only way to provide Meet.jobs a legal notice. </h5>

`,
  employer_agreement_section_title_14: "Applicable to the Existing Agreement",
  employer_agreement_part_14: `
    <h5>80. If the client continues to use Meet.jobs after 1st May 2023, it shall be deemed to have agreed to the new agreement. </h5>
`,
  employer_agreement_section_title_15: "Contact",
  employer_agreement_part_15: `
    <h5>81. Online: Reply robot, message board or email us at service@meet.jobs 85. TEL: +886 2 2577 5800 </h5>
    <h5>82. Mail Delivery: 5F., No. 106, Sec. 4, Nanjing E. Rd., Songshan Dist., Taipei City 105, Taiwan (R.O.C.) </h5>
`,

  // employer_agreement_section_title_0: "Recitals and Explanations",

  employer_agreement_title_0:
    "1. This Agreement is to be used when the Singaporean business MIT.JOBS Pte. Ltd. and its website, platform and online service provides solution service to talent recruitment.",

  employer_agreement_title_1:
    "2. All explanation and definition of the terms under this Agreement are the same as those in the “User Agreement”, “Privacy Policy” and all relevant documents of Meet.jobs.",

  employer_agreement_title_2:
    "3. Client must have the contact person responsible for recruitment registered as a member of the Meet.jobs service to further register a business account.",

  employer_agreement_title_3:
    "4. Save and except for the agreements separately reached by the Client and Meet.jobs in writing, all information will be recognized as that recorded on the Meet.jobs website, system and extended service.",

  employer_agreement_title_4:
    "5. Meet.jobs provides three different recruitment plans, which are “Hire Plan,” “Hire+ Plan,” and “Post Plan.”",

  employer_agreement_title_5:
    "6. The same Client may only adopt a plan at a time. The chosen plan of a specific time period will be the one shown on the record of Meet.jobs service system.",

  employer_agreement_title_6:
    "7. This Agreement is divided into four parts, where each plan will be explained separately and the mutual part of the three plans.",

  employer_agreement_title_7:
    "8. The above three plans all have the information open and transparent. When the Client publish an opening through the service of Meet.jobs, the bottom line is that the salary range or lower limit of the salary needs to be published.",
  employer_agreement_content_7: `<h6>Note: “Trust” is a core value which Meet.jobs believes and “transparent information” could allow the talents to sufficiently trust the employer. When the employer provides information as transparent, complete, and accurate, it will be able to recruit a suitable employee more efficiently through Meet.jobs.</h6>`,

  // employer_agreement_section_title_1: "Part I: Hire Plan",

  employer_agreement_title_8:
    "9. Hire Plan is the standard service provided by Meet.jobs, if services other than the below items are needed, such as customized agreement, extension of guarantee period, change to recruiting advertisement, please choose “Hire+ Plan.”",

  employer_agreement_title_9: "10. Hire Plan Stipulations",
  employer_agreement_content_9:
    `<h6>10.1. Hire Plan of Meet.jobs is charged based on results. After the Client successfully recruits the candidate, it will pay Meet.jobs of the expenses and the amount will be 16% of the annual salary for the first year of the position.</h6>` +
    `<h6>10.2. The Client needs to pay the fees within thirty (30) calendar days after the offeree starts working.</h6>` +
    `<h6>10.3. Meet.jobs guarantees that the offeree will be on the job for 90 calendar days.</h6>` +
    `<h6>10.4. If the offeree resigns within the first 90 calendar days, then within 90 calendar days from the resignation day, Meet.jobs needs to supply with a substitute candidate.</h6>` +
    `<h6>10.5. Following the foregoing, such a substitute has a guarantee period of 90 calendar days since commencement as well. In the event the salary is different from that of the original offeree, the service fees will be adjusted accordingly.</h6>` +
    `<h6>10.6. Following the foregoing, if Meet.jobs cannot supply with a substitute candidate within the period, or the Client has offered the job to an appropriate candidate through other channels, or if such a demand no longer exists (provided that the Client has to submit a reasonable explanation to the lack of the need for such a candidate), then Meet.jobs needs to refund in full.</h6>` +
    `<h6>10.7. Specifically, if such an offeree is disemployed by the Client during the trial period due to work performance within the first 90 calendar days, it will not be within the guaranteed scope of Meet.jobs.</h6>` +
    `<h6>10.8. The Client adopting this plan does not need to pay for posting expenses.</h6>`,

  employer_agreement_title_10: "11. Hire Plan Contents",
  employer_agreement_content_10:
    `<h6>11.1. After the Client selects Hire Plan on the system of Meet.jobs, a professional consultant of Meet.jobs will contact the Client to provide with assistance.</h6>` +
    `<h6>11.2. The Client needs to post the recruiting job information to the Meet.jobs website and all extended web platform and guarantee the information provided is correct and without mistakes. If the information provided by the Client is incorrect and causes damages to Meet.jobs, the Client shall be liable to Meet.jobs for the damages; in the event applicants suffer damages incurred therefrom, the Client shall be liable and Meet.jobs will not be involved.</h6>` +
    `<h6>11.3. Meet.jobs will, based on its professional judgment and depending on the recruiting needs of the Client, provide target marketing, text or video interview, online or offline promotion, community referral to assist the Client in recruiting.</h6>` +
    `<h6>11.4. Meet.jobs will share and promote the Client’s business and job openings information on social networks, including, without limitation, Facebook, Google+, LinkedIn, YouTube, Instagram, and WeChat, as well as other website or network specially required by the Client.</h6>` +
    `<h6>11.5. The Client may, based on advice from professional consultant of Meet.jobs, provide information necessary and relating to the recruitment, including, without limitation, videos, pictures, recordings, text messages, and interviews and/or resumes of current employees, hiring manager or business founder.</h6>` +
    `<h6>11.6. The materials mentioned under the preceding section do not include information and materials that need to be kept confidential due to the Client’s business concerns. Including the Client’s company name, Meet.jobs needs to obtain the employer’s permit in any recordable form to publish such information. If it is published without permit, the Client is entitled to immediately terminate the Agreement. In the event the Client suffers any damages therefrom, Meet.jobs will be liable for all damages and legal responsibilities.</h6>` +
    `<h6>11.7. In the event any additional expenses derive from the recruitment plan provided by Meet.jobs, then after obtaining the Client’s consent shown in any recordable form, the parties will agree upon the party bearing such expenses and then separately agree upon the details of payment, certificates, and reimbursement.</h6>`,

  employer_agreement_title_11: "12. Employment Recognition",
  employer_agreement_content_11:
    `<h6>12.1. Employment on Meet.jobs service may be recognized in the following three ways:</h6>` +
    `<h6>A. Client confirmation: Client may directly confirm the employment on Meet.jobs system or inform it in any recordable form.</h6>` +
    `<h6>B. Client confirmation after the applicant reports: Once employed the job, the applicant may report the employment on the Meet.jobs system, and Meet.jobs system will automatically confirm with the Client about the employment. The client may confirm the employment on the Meet.jobs system or inform it in any recordable form.</h6>` +
    `<h6>C. Client confirmation after the referrer reports: If the applicant has a referrer, the referrer may report the employment on the Meet.jobs system, and the Meet.jobs system will automatically confirm with the Client about the employment. The Client may confirm the employment on Meet.jobs system or inform it in any recordable form.</h6>` +
    `<h6>12.2. In the event applicant and referrer report the employment under the preceding section, if confirmed as “not hired,” the Client needs to reply the same on the Meet.jobs system within 72 hours, or inform Meet.jobs in any recordable form, failing which Meet jobs will reserve the right to payment.</h6>` +
    `<h6>12.3. If the applicant is not hired for the Client’s job, the Client needs to “reject” its application on the Meet.jobs system. In the event there is any applicant not refused, it will be deemed as in the “applying” status. As such, however long the time has passed, once hired, Meet.jobs is still entitled to the relevant payment.</h6>` +
    `<h6>12.4. If the applicant is in the status of “applying”, then once employed with the Client’s opening, whether or not it is employed with the position originally applied for, Meet.jobs will be entitled to the payment. Namely, if the Client does not intend to hire such an applicant, it must “reject” the applicant on Meet.jobs, and please refrain from committing any conduct damaging the Client’s goodwill and trust such as “silent refusal”.</h6>` +
    `<h6>12.5. Applicants who apply through the Meet.jobs system, and any candidate referred by Meet.jobs in any recordable form, once hired, Meet.jobs is entitled to charge the Client therefor. In addition, this is irrelevant to whether or not the employer learned about this candidate through other channels, and Meet.jobs is still entitled to the Client’s payment.</h6>` +
    `<h6>12.6. If the Client considers that the hired applicant was not employed through the service of Meet.jobs, it needs to obtain the applicant’s confirmation of the same and statement of forfeiting the Hiring Bonus in any recordable form. If that applicant has a referrer, it also needs to obtain the referrer’s confirmation of the same and statement forfeiting the Referral Reward in any recordable form as evidence. In this situation, Meet.jobs will not charge service fees.</h6>`,

  employer_agreement_title_12: "13. Actual Salary Recognition",
  employer_agreement_content_12:
    `<h6>13.1. Once confirmed hired, the Client needs to provide the salary information, such as employment agreement, offer letter or payment slip as evidence as the basis for Meet.jobs to charge. If such information is provided by the applicant or referrer, it needs to obtain the Client’s confirmation thereof in any recordable form.</h6>` +
    `<h6>13.2. Meet.jobs is obliged to keep the foregoing salary information confidential, and such information may only be used as the evidence of charging service fees. In the event the information is disseminated, causing the Client’s damages, Meet.jobs needs to be liable therefor.</h6>` +
    `<h6>13.3. The service fees will be calculated in the following way: if the salary of the position is listed annually on the system, then it will be the 16% of such amount; if monthly on the system, then such an amount will multiple with the guarantee months (for instance, 14 months) and then the 16% thereof will be the service fees.</h6>` +
    `<h6>13.4. Following the foregoing, if the salary of the position is listed daily on the system, then such an amount will multiple with 20 (number of monthly working day) and with the guarantee months (for instance, 14 months) and then the 16% thereof will be the service fees.</h6>` +
    `<h6>13.5. Following the foregoing, if the salary of the position is listed hourly on the system, then such an amount will multiple with 160 (number of monthly working hour) and with the guarantee months (for instance, 14 months) and then the 16% thereof will be the service fees.</h6>` +
    `<h6>13.6. Following the foregoing, if the position is listed on the system as “part-time” or “internship” job, the salary of the position is recognized as 60% of the “full-time” position under the preceding section. However, the salary of the position is still recognized as a “full-time” job, while the position on the system is targeted to a fulltime employment.</h6>`,

  employer_agreement_title_13: "14. Payment Methods",
  employer_agreement_content_13:
    `<h6>14.1. Once the Client confirms the employment on the Meet.jobs system and inform it of the salary information, Meet.jobs will provide an invoice and the document required for payment (such as receipt). The client needs to complete the payment within 30 calendar days after receipt of the invoice.</h6>` +
    `<h6>14.2. Meet.jobs now accepts two payment methods of “credit cards” and “remittance,” among which the Client may choose therefrom and the same will be shown on the Meet.jobs system.</h6>` +
    `<h6>14.3. If the Client chooses credit card, then it will only need to register the credit card information on the Meet.jobs system. Meet.jobs will issue invoice according to the employment confirmation and charge on the monthly settlement day (the 5th day of each month). Credit card is the best payment method recommended by Meet.jobs.</h6>` +
    `<h6>14.4. If the Client chooses to remit the amount, then Meet.jobs will provide bank account for the Client to remit the payment.</h6>` +
    `<h6>14.5. Meet.jobs is responsible for informing the Client with the most convenient and cost-effective payment methods. However, the Hire Plan of Meet.jobs is charged at “16% of the annual salary.” In the event there is any derivative expenses, such as charges, remittance fees, and all relevant taxes, it will be borne by the Client.</h6>`,

  employer_agreement_title_14: "15. Ethics Statement",
  employer_agreement_content_14:
    `<h6>15.1. The success of talent recruitment often determines the success of a business. A business could successfully recruit excellent talent based on the mutual trust among the business, talents and service provider. As such, if an applicant or its referrer requests the Client to provide any extra compensation, bonus or benefits in other forms under the condition of “not reporting the employment to Meet.jobs,” Meet.jobs strongly advise the Client not to hire such an applicant. In the event such applicant or referrer is reported to Meet.jobs, Meet.jobs will be responsible for the investigation thereof, and once confirmed to be true, such an applicant or referrer will be listed as “excluded,” and be informed to all of Meet.jobs’ business clients.</h6>` +
    `<h6>15.2. If the Client actively offers benefit to applicant or referrer in any form under the condition of “not reporting the employment to Meet.jobs,” the applicant or referrer is also entitled to report such a business. Once confirmed to be true, considering such a conduct is in violation of integrity of the recruitment, Meet.jobs will announce the Client’s name on the website and list it as “not recommended.”</h6>` +
    `<h6>15.3. If the Client cannot complete the payment within 30 calendar days after receipt of invoice, it needs to obtain Meet.jobs consent in recordable form and the parties will agree on a payment day. If the Client intends to delay or does not pay in time without Meet.jobs’ consent, Meet.jobs will announce the business name on the website and list it as “not recommended.”</h6>` +
    `<h6>15.4. If the information of the opening provided by the Client is inconsistent with the condition of the final offer, especially where the offered salary is lower than the lowest salary in the recruiting salary range on the system, the Business needs to proactively submit an explanation in writing or other recordable form within 72 hours and obtain the applicant’s and the referrer’s consent statement as evidence. If the applicant’s and the referrer’s consent is not obtained and the same happens for more than three times, Meet.jobs will announce the Client’s name on the website and list it as “not recommended.”</h6>`,

  // employer_agreement_section_title_2: "Part II Hire+ Plan",

  employer_agreement_title_15:
    "16. Hire+ Plan is a service plan set up for the Client’s special needs. Below is a list of difference between it and the Hire Plan for the Client to determine and choose from.",
  employer_agreement_content_15:
    `<h6>16.1. If the Client chooses Hire+ Plan, then it may sign a separate customized service agreement and the content of the agreement will be determined based on the service needed, such as talent strategy consultation, optimization of recruiting advertisement, and extension of guarantee period.</h6>` +
    `<h6>16.2. The fees will be at least 20% of the annual salary of the position without an upper limit. The actual percentage will depend on the content of the special service.</h6>` +
    `<h6>16.3. Hire+ Plan guarantees that the offeree will be on the position for at least 180 calendar days.</h6>` +
    `<h6>16.4. Following the foregoing, the charging scheme of Hire+ Plan is, the Client completes the payment of 16% of the annual salary of such a position 30 calendars prior to the offeree reports to work. The payment of the remaining balance will be made according to the separate agreement between the Client and Meet.jobs.</h6>` +
    `<h6>16.5. Following the foregoing, if such an offeree resigns after it assumes work for 90 calendar days, then Meet.jobs will not refund the 16% already paid. The refund of the remaining balance will be separately agreed upon by the Client and Meet.jobs.</h6>`,

  employer_agreement_title_16:
    "17. Save and except for the foregoing content, all service details of Hire+ Plan is the same as Hire Plan, and may refer to the terms of the first part.",

  employer_agreement_title_17:
    "18. In the event the Client has already chosen other plans, and wishes to upgrade to Hire+ Plan, it only needs to select Hire+ Plan on Meet.jobs system, or inform Meet.jobs in recordable form. After confirming the Client’s needs to upgrade the plan, Meet.jobs will have a professional consultant contacting the Client to provide assistance.",

  // employer_agreement_section_title_3: "Part III Post Plan",

  employer_agreement_title_18: "19. Post Plan Function, Stipulation and Fees",
  employer_agreement_content_18:
    `<h6>19.1. Post Plan of Meet.jobs only provides functions of posting openings and managing applications on the website and its extended platforms and wait for applicants to apply therefor. Comparing to the Hire Plan that will assist the Client to proactively recruit talents, the Post Plan is relatively passive.</h6>` +
    `<h6>19.2. Post Plan is charged based on the “usage.” The fee is USD 1 per opening and per day and the fee will be billed each month, where the Client shall pay the fee of the previous month on the 5th day of the following month. In the event no opening is posted or the opening is closed, there will be no fees deriving therefrom.</h6>` +
    `<h6>19.3. Post Plan fees may only be paid with credit cards.</h6>` +
    `<h6>19.4. Clients using Post Plan may directly upgrade to Hire Plan or Hire+ Plan on Meet.jobs system. If the Client wishes to change from Hire Plan or Hire+ Plan to Post Plan, it needs to inform Meet.jobs of the same in writing or other recordable form, and the system administrator will assist in the change.</h6>`,

  // employer_agreement_section_title_4: "Part IV Mutual Part",

  employer_agreement_title_19: "20. Confidentiality of Personal Information",
  employer_agreement_content_19: `<h6>For applicants answering the post through or referred by Meet.jobs, all of their information including resume, portfolio, technical test results will only be provided to the Client to assess whether the applicant is hired or not and the Client may not use the same otherwise. In the event any damages are caused to the applicant, the Client will be liable to the applicant therefor and Meet.jobs is not involved.</h6>`,

  employer_agreement_title_20: "21. Execution",
  employer_agreement_content_20:
    `<h6>Meet.jobs provides the following methods for the Client to sign this Agreement and the Client only needs to adopt one.</h6>` +
    `<h6>A. Directly select agree to the agreement on Meet.jobs system. This is recommended by Meet.jobs to be legally binding and the most efficient.</h6>` +
    `<h6>B. Adopt a third-party electronic signing system acknowledged by Meet.jobs, and upload the same to Meet.jobs system after execution.</h6>` +
    `<h6>C. Download the agreement already executed by Meet.jobs and printed by the Client, and scan and upload the executed version to Meet.jobs system.</h6>` +
    `<h6>D. Select sending hardcopy on Meet.jobs system and fill in the receipt address. Meet.jobs will send the executed hard copies in two counterparts after receipt of the request. After the Client executes the same, scan and upload it to Meet.jobs system, and send a hard copy back to Meet.jobs for reference.</h6>`,

  employer_agreement_title_21: "22. Amendment and Termination",
  employer_agreement_content_21:
    `<h6>22.1. Whichever plan chosen, as long as there is no posting for openings or the opening is closed, the Client does not need to bear any fees thereof. As such, under most circumstances, this Agreement does not need to be terminated.</h6>` +
    `<h6>22.2. If needed, Meet.jobs and the Client both has the right to unilaterally terminate the Agreement. If a party desires to terminate the Agreement, it has to inform the other party within a month prior to the expiration date. The parties may terminate the Agreement after performing all obligations under this Agreement.</h6>` +
    `<h6>22.3. All obligations under the preceding section include all payment, provision of information and information closure.</h6>` +
    `<h6>22.4. In the event of force majeure events, if either party cannot perform the agreement due to disasters, political events, religion, racial and other conflicts, the party is not legally liable to the other party.</h6>` +
    `<h6>22.5. Save and except that the Hire+ Plan that may have customized plan, if Meet.jobs has any revision to the Agreement, it will inform the Client through the system and recordable form. In the event the Client disagrees with the revised content, under the general circumstances, it only needs to cease to use Meet.jobs services.</h6>`,

  employer_agreement_title_22: "23. Governing Law and Dispute Resolution",
  employer_agreement_content_22:
    `<h6>23.1. Unless otherwise stipulated by laws and regulations, the Client and Meet.jobs agree that any dispute arising from this Agreement and/or “services,” will be governed by the relevant laws and regulations of the Republic of China and shall be submitted to Taiwan Taipei District Court for the first instance.</h6>` +
    `<h6>23.2. If the Agreement has any parts or terms that cannot be performed, the parties both agree that the terms of the Agreement shall be revised in a way feasible and consistent with the original ends. If the revision is impossible, the parties agree to remove those parts incapable of being performed, and perform the rest of the term in the Agreement.</h6>` +
    `<h6>23.3. Within the extent permissible by laws, both the English version and the Traditional Chinese version are legally binding and other translations are only for the convenience of understanding. In the event there is any discrepancy in the clauses and terms between the different versions, then the English version shall prevail. This Agreement is the final and complete consent of the parties and replaces any oral or written promises, understanding or other expresses submitted during the negotiation. The title of terms of this Agreement is only for reference, the parties agree that the title may not be used as the basis of interpretation.</h6>` +
    `<h6>23.4. If Meet.jobs does not take legal actions for breaches, it does not mean that Meet.jobs waive the right to perform this Agreement. Client may not assign or transfer this Agreement (or membership or use of service) without the consent of Meet.jobs. However, the Client agrees that Meet.jobs may assign this Agreement to relevant institutes or institutes purchases Meet.jobs.</h6>` +
    `<h6>23.5. You agree that the address specified in Article 24 will be the only way to provide Meet.jobs a legal notice.</h6>`,

  employer_agreement_title_23: "24. Contact",
  employer_agreement_content_23:
    `<h6>Online: Reply robot, message board or emails us at service@meet.jobs.</h6>` +
    `<h6>Mail Delivery: 8 Claymore Hill #03-01 Singapore 229572 or 5F., No. 1, Sec. 2, Jianguo N. Rd., Zhongshan Dist., Taipei City 104, Taiwan.</h6>`,

  // privacy_policy
  privacy_policy_intro_content:
    "<p>This Privacy Policy applies when you use our Services. We offer our users about the data we collect, use and share as described in this Privacy Policy, Cookie Policy, Business User Agreement, User Agreement.</p>",

  privacy_policy_title_0: `<span class="li-number">1.</span>Introduction`,
  privacy_policy_content_0:
    `<h5>1.1. Meet.jobs' "Services" provide registered users ("Members") to find, apply and recommend job opportunities. Content and data on some of our Services is viewable to non-members (“Visitors”).</h5>` +
    `<h5>1.2. This Privacy Policy applies to the Meet.jobs website and all other derivative services, but excluding services that state that they are offered under a different privacy policy.</h5>` +
    `<h5>1.3. As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this Privacy Policy and updates.</h5>` +
    `<h5>1.4. Meet.jobs can modify this Privacy Policy at any time, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. We agree that the change cannot be retroactive. If you object to any changes, you can stop using, access the Meet.jobs service, or close your account by contacting us. If you continue to use the Meet.jobs service, you agree and will abide by the updated terms.</h5>`,

  privacy_policy_title_1: `<span class="li-number">2.</span>Data We Collect`,
  privacy_policy_content_1:
    `<h5>2.1. Data You Provide To Us</h5>` +
    `<h6>2.1.1. Registration: To create an account you need to provide data including your name, email address, and a password. If you register for a premium Service, you will need to provide payment (e.g., credit card) and billing information.</h6>` +
    `<h6>2.1.2. Profile: You have choices about the information on your profile, such as your education, work experience, skills, photo, city or area. You don’t have to provide additional information on your profile; however, profile information helps you to get more from our Services, and help recruiting enterprise and headhunting consultants find you. Please do not post or add personal data to your profile that you would not want to be publicly available. </h6>` +
    `<h6>2.1.3. Posting and Uploading: We collect personal data from you when you provide, post or upload it to our Services, such as when you fill out a form, respond to a survey, or submit a resume. You don’t have to post or upload personal data; though if you don’t, it may limit the content you can use with Meet.jobs Services.</h6>` +
    `<h5>2.2. Data From Others</h5>` +
    `<h6>You and others may post content that includes information about you on our Services. Meet.jobs will collect public information about you.</h6>` +
    `<h5>2.3. Service Use</h5>` +
    `<h6>We log usage data when you visit or otherwise use our Services, such as when you view or click on content (e.g., learning video) or ads (on or off our sites and apps), perform a search, share articles or apply for jobs. We use log-ins, cookies, device information and internet protocol (“IP”) addresses to identify you and log your use.</h6>` +
    `<h5>2.4. Cookies, Web Beacons and Other Similar Technologies</h5>` +
    `<h6>Meet.jobs use cookies and similar technologies (e.g., web beacons, pixels, ad tags and device identifiers) to recognize you and/or your device(s) on, off and across different Services and devices. We also allow some others to use cookies as described in our Cookie Policy. You can control cookies through your browser settings and other tools. You can also opt-out from our use of cookies and similar technologies that track your behavior on the sites of others for third party advertising.</h6>` +
    `<h5>2.5. Your Device and Location</h5>` +
    `<h6>When you visit or leave our Services (including our plugins or cookies or similar technology on the sites of others), we receive the URL of both the site you came from and the one you go to next. We also get information about your IP address, proxy server, operating system, web browser and add-ons, device identifier and features, and/or ISP or your mobile carrier. If you use our Services from a mobile device, that device will send us data about your location based on your phone settings. We will ask you to opt-in before we use GPS or other tools to identify your precise location.</h6>` +
    `<h5>2.6. Messages</h5>` +
    `<h6>We collect information about you when you send, receive, or engage with messages in connection with our Services. For example, If you receive an interview invitation from a company, we track whether you have acted on it and will send you reminders. We also use automatic scanning technology on messages.</h6>` +
    `<h5>2.7. Other</h5>` +
    `<h6>Our Services are dynamic, and we often introduce new features, which may require the collection of new information. If we collect materially different personal data or materially change how we use your data, we will notify you and may also modify this Privacy Policy.</h6>`,

  privacy_policy_title_2: `<span class="li-number">3.</span>How We Use Your Data`,
  privacy_policy_content_2:
    `<h5>3.1. Period: The period of use of the information collected above refers to the period of use in accordance with the specific purpose of the collection, the relevant laws and regulations, the necessary period of preservation required for the execution of the business or the retention period determined by the individual contract.</h5>` +
    `<h5>3.2. Region: The information collected above will be used by Meet.jobs and Meet.jobs global relationships or partners to provide related services.</h5>` +
    `<h5>3.3. How we use your personal data will depend on which Services you use, how you use those Services and the choices you make in your settings. We use the data that we have about you to provide and personalize, including with the help of automated systems and inferences we make, our Services (including ads) so that they can be more relevant and useful to you and others.</h5>` +
    `<h5>3.4. We use your information to authorize you to use Our Services. Our Services allow you to explore careers, and seek out, and be found for, career opportunities. Your profile can be found by a recruiter (a specific position) or a referee. We will use your data to recommend jobs to you. You can signal that you are interested in changing jobs and share information with job recruiters. We may use automated systems to profile and provide recommendations to help make our Services more relevant to our Members, Visitors and recruitment enterprise. Keeping your profile accurate and up-to-date may help you better to grasp the opportunities through our Services.</h5>` +
    `<h5>3.5. Ours Services provides exclusive search functionality and tools (including messaging and event notifications) for recruiting enterprise, headhunters and referees. The aforementioned personnel can export limited information from your profile, such as name, headline, current company, current title, and general location. You can make it impossible for others to find you through settings.</h5>` +
    `<h5>3.6. We will contact you through email, mobile phone, notices posted on our websites or apps, messages to you through our system, and other ways through our Services, including text messages and push notifications. We will send you messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use the Services, job opportunity advice and promotional messages from us and our partners. Please be aware that you cannot opt-out of receiving service messages from us, including security and legal notices.</h5>` +
    `<h5>3.7. We target (and measure the performance of) ads to Members, Visitors and others both on and off our Services directly or through a variety of partners, using the following data, whether separately or combined:</h5>` +
    `<h6>Data from advertising technologies on and off our Services, like web beacons, pixels, ad tags, cookies, and device identifiers;</h6>` +
    `<h6>Member-provided information (e.g., profile, contact information, title and industry);</h6>` +
    `<h6>Data from your use of our Services (e.g., search history, feed, content you read, page visits, videos you watch, clicking on an ad, etc.);</h6>` +
    `<h6>Information from advertising partners and publishers; and</h6>` +
    `<h6>Information inferred from data described above (e.g., using job titles from a profile to infer industry, seniority, and compensation bracket; using graduation dates to infer age or using first names or pronoun usage to infer gender).</h6>` +
    `<h6>If you take an action (such as click) on these ads, your action is associated with your account and viewable by others, including the advertiser. </h6>` +
    `<h5>3.8. We do not share your personal data with any third-party advertisers or ad networks for their advertising except for: (i) hashed or device identifiers (to the extent they are personal data in some countries); (ii) with your separate permission or (iii) data already visible to any users of the Services (e.g. profile). However, if you view or click on an ad on or off our site or apps, the ad provider will get a signal that someone visited the page that displayed the ad, and they may through the use of mechanisms such as cookies determine it is you. Advertising partners can associate personal data collected by the advertiser directly from you with our cookies and similar technologies. In such instances, we seek to contractually require such advertising partners to obtain your explicit, opt-in consent before doing so.</h5>` +
    `<h5>3.9. We use data and content about Members for invitations and communications promoting membership and network growth, engagement and our Services.</h5>` +
    `<h5>3.10. We use data, including public feedback, to conduct research and development for the further development of our Services in order to provide you and others with a better, more intuitive and personalized experience, drive membership growth and promote interaction between individuals and corporate members in the Services.</h5>` +
    `<h5>3.11. We use the personal data available to us to research social, economic and workplace trends such as jobs availability and skills needed for these jobs and policies that help bridge the gap in various industries and geographic areas. In some cases, we work with trusted third parties to perform this research, under controls that are designed to protect your privacy. We publish or allow others to publish economic insights, presented as aggregated data rather than personal data.</h5>` +
    `<h5>3.12. Surveys are conducted by us and our business partner through our Services. You are not obligated to respond to surveys, and you have choices about the information you provide.</h5>` +
    `<h5>3.13. We use the data (which can include your communications) to investigate, respond to and resolve complaints and Services issues (e.g., bugs).</h5>` +
    `<h5>3.14. We use your data to produce and share aggregated insights that do not identify you. For example we may use your data to generate statistics about our members, their profession or industry, to calculate ad impressions served or clicked on, or to publish visitor demographics for a Services or demographic workforce insights.</h5>` +
    `<h5>3.15. We use your data (including your communications) if we think it’s necessary for security purposes or to investigate possible fraud or other violations of our User Agreement or this Privacy Policy and/or attempts to harm our Members or Visitors.</h5>`,

  privacy_policy_title_3: `<span class="li-number">4.</span>How We Share Information`,
  privacy_policy_content_3:
    `<h5>4.1. Profile</h5>` +
    `<h6>Members, customers, and Visitors within our Services can see your profile. The openness of the profile and whether others can view the specific fields in the file are determined by your settings on our Services.</h6>` +
    `<h5>4.2. Interactive information</h5>` +
    `<h6>Our Services allows Members to interact through messages, concerns, post responses and sharing. All interactive information is part of your profile, it can be determined whether openness based on your settings on our Services. If you have interaction with other Members, they will also know about this interaction, record and message.</h6>` +
    `<h5>4.3. Enterprise Accounts</h5>` +
    `<h6>Depending on the enterprise Service, before you use such Service, we will ask for permission to share relevant data from your profile or use of our non-enterprise Services. We understand that certain activities such as job hunting and personal messages are sensitive, and so we do not share those with your employer unless you choose to share it with them through our Services (for example, by applying for a new position in the same company or mentioning your job hunting in a message to a co-worker through our Services).</h6>` +
    `<h5>4.4. Communication Archival</h5>` +
    `<h6>Some Members (or their employers) need, for legal or professional compliance, to archive their communications and social media activity, and will use services of others to provide these archival services. We enable archiving of messages by those Members outside of our Services. For example, a financial advisor needs to archive communications with her clients through our Services in order to maintain her professional financial advisor license.</h6>` +
    `<h5>4.5. Other Services</h5>` +
    `<h6>Subject to your settings, other services may look-up your profile. When you opt to link your account with other services, personal data will become available to them. The sharing and use of that personal data will be described in, or linked to, a consent screen when you opt to link the accounts.</h6>` +
    `<h5>4.6. Related Services</h5>` +
    `<h6>We will share your personal data with our affiliates to provide and develop our Services. We may combine information internally across the different services covered by this Privacy Policy to help our Services be more relevant and useful. </h6>` +
    `<h5>4.7. Service Providers</h5>` +
    `<h6>We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your information as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.</h6>` +
    `<h5>4.8. Legal Disclosures</h5>` +
    `<h6>It is possible that we will need to disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you, (3) investigate and defend ourselves against any third-party claims or allegations, (4) protect the security or integrity of our Service (such as by sharing with companies facing similar threats); or (5) exercise or protect the rights and safety of Meet.jobs, our Members, personnel, or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to challenge every demand.</h6>` +
    `<h5>4.9. Change in Control or Sale</h5>` +
    `<h6>We can also share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.</h6>`,

  privacy_policy_title_4: `<span class="li-number">5.</span>Your Choices & Obligations`,
  privacy_policy_content_4:
    `<h5>5.1. Data Retention</h5>` +
    `<h6>We retain your personal data while your account is in existence or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of our Services. Even if you only use our Services when looking for a new job every few years, we will retain your information and keep your profile open until you decide to close your account. In some cases we choose to retain certain information in a depersonalized or aggregated form.</h6>` +
    `<h5>5.2. Rights to Access and Control Your Personal Data</h5>` +
    `<h6>We offer you settings right to control and manage the personal data we have about you:</h6>` +
    `<h6>5.2.1. Delete Data: You can ask us to erase or delete all or some of your personal data (e.g., if it is no longer necessary to provide Services to you).</h6>` +
    `<h6>5.2.2. Change or Correct Data: You can edit some of your personal data through your account. You can also ask us to change, update or fix your data in certain cases, particularly if it’s inaccurate.</h6>` +
    `<h6>5.2.3. Object to, or Limit or Restrict, Use of Data: You can ask us to stop using all or some of your personal data (e.g., if we have no legal right to keep using it) or to limit our use of it (e.g., if your personal data is inaccurate or unlawfully held).</h6>` +
    `<h6>5.2.4. Right to Access and/or Take Your Data: You can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine readable form. Please note that the actual situation in a particular country may have additional rights depending on its regulations.</h6>` +
    `<h5>5.3. Account Closure</h5>` +
    `<h6>If you choose to close your Meet.jobs account, your personal data will generally stop being visible to others on our Services within 24 hours. We generally delete closed account information within 30 days of account closure, except as noted below.</h6>` +
    `<h6>We retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse, enforce our User Agreement, or fulfill your request to “unsubscribe” from further messages from us. We will retain de-personalized information after your account has been closed.</h6>` +
    `<h6>Information you have shared with others will remain visible after you closed your account or deleted the information from your own profile or mailbox, and we do not control data that other Members copied out of our Services. Your profile may continue to be displayed in the services of others (e.g., search engine results) until they refresh their cache.</h6>`,

  privacy_policy_title_5: `<span class="li-number">6.</span>Other Important Information`,
  privacy_policy_content_5:
    `<h5>6.1. Information Security</h5>` +
    `<h6>We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</h6>` +
    `<h5>6.2. Cross-Border Data Transfers</h5>` +
    `<h6>Our users come from all countries and we rely on legally-provided mechanisms to lawfully transfer data across borders. Countries where we process data may have laws which are different, and potentially not as protective, as the laws of your own country.</h6>` +
    `<h5>6.3. Legal basis and authorization</h5>` +
    `<h6>We will only collect and process personal data about you where we have lawful bases. Lawful bases include consent (where you have given consent), contract (where processing is necessary for the performance of a contract with you) and “legitimate interests”. </h6>` +
    `<h6>Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object.</h6>` +
    `<h5>6.4. Direct Marketing and Do Not Track Signals</h5>` +
    `<h6>We do not share personal data with third parties for their direct marketing purposes without your permission. Further, in most cases, Meet.jobs will not response to “do not track” signals..</h6>` +
    `<h5>6.5. Contact Information</h5>` +
    `<h6>Online contact: Reply to the message robot, message board, or mail to service@meet.jobs.</h6>` +
    `<h6>Mailing address contact: 8 Claymore Hill #03-01 Singapore 229572 or 2nd Floor, No. 2, Lane 59, Yitong Street, Zhongshan District, Taipei 10486, Taiwan.</h6>` +
    `<h5>6.6. Governing Law and Jurisdiction</h5>` +
    `<h6>The disputes arising from this website shall be governed in accordance with the laws of the Republic of China and the Taiwan Taipei District Court is the court of first instance.</h6>`,

  // cookie policy
  cookie_policy_intro_content: `<p>This cookie policy applies to any Meet.jobs product or service that links to this policy or incorporates it by reference. This policy provides detailed information about how and when we use cookies.</p>`,

  cookie_policy_title_0: `<span class="li-number">1.</span>Does Meet.jobs use cookies?`,
  cookie_policy_content_0: `<h5>Yes. We use cookies and other technologies to ensure everyone who uses Meet.jobs has the best possible experience. Cookies also help us keep your account safe. By continuing to visit or use our services, you are agreeing to the use of cookies and similar technologies for the purposes we describe in this policy.</h5>`,

  cookie_policy_title_1: `<span class="li-number">2.</span>What is a cookie?`,
  cookie_policy_content_1: `<h5>A cookie is a small file placed onto your device that enables Meet.jobs features and functionality. For example, cookies enable us to identify your device, secure your access to Meet.jobs and our sites generally, and even help us know if someone attempts to access your account from a different device. Cookies also enable you to easily share content on Meet.jobs and help us serve relevant information to you.</h5>`,

  cookie_policy_title_2: `<span class="li-number">3.</span>When does Meet.jobs place cookies?`,
  cookie_policy_content_2: `<h5>We use cookies on our sites and mobile applications. Any browser visiting these sites will receive cookies from us. We also place cookies in your browser when you visit non- Meet.jobs sites that host our plugins or tags.</h5>`,

  cookie_policy_title_3: `<span class="li-number">4.</span>What types of cookies does Meet.jobs use?`,
  cookie_policy_content_3: `<h5>We use two types: persistent cookies and session cookies. A persistent cookie helps us recognize you as an existing user, so it’s easier to return to Meet.jobs or interact with our services without signing in again. After you sign in, a persistent cookie stays in your browser and will be read by Meet.jobs when you return to one of our sites or a partner site that uses our services. Session cookies only last for as long as the session (usually the current visit to a website or a browser session).</h5>`,

  cookie_policy_title_4: `<span class="li-number">5.</span>What are cookies used for?`,
  cookie_policy_content_4:
    `<h5>Cookies can be used to recognize you when you visit Meet.jobs, remember your preferences, and give you a personalized experience that’s in line with your settings. Cookies also make your interactions with Meet.jobs faster and more secure. Additionally, cookies allow us to bring you advertising both on and off the Meet.jobs sites, and bring customized features to you through Meet.jobs plugins such as our “Share” button.</h5>` +
    `<table style="width:100%">` +
    `<tr>` +
    `<th style="width:30%">Categories of Use</th>` +
    `<th style="width:70%">Description</th>` +
    `</tr>` +
    `<tr>` +
    `<td>Authentication</td>` +
    `<td>If you’re signed in to Meet.jobs, cookies help us show you the right information and personalize your experience.</td>` +
    `</tr>` +
    `<tr>` +
    `<td>Security</td>` +
    `<td>We use cookies to enable and support our security features, and to help us detect malicious activity and violations of our User Agreement.</td>` +
    `</tr>` +
    `<tr>` +
    `<td>Preferences, features and services</td>` +
    `<td>Cookies can tell us which language you prefer and what your communications preferences are. They can help you fill out forms on Meet.jobs more easily. They also provide you with features, insights, and customized content in conjunction with our plugins. You can learn more about plugins in our Privacy Policy.</td>` +
    `</tr>` +
    `<tr>` +
    `<td>Performance, Analytics and Research</td>` +
    `<td>Cookies help us learn how well our site and plugins perform in different locations. We also use cookies to understand, improve, and research products, features, and services, including when you access Meet.jobs from other websites, applications, or devices such as your work computer or your mobile device.</td>` +
    `</tr>` +
    `</table>`,

  cookie_policy_title_5: `<span class="li-number">6.</span>What is Do Not Track (DNT)?`,
  cookie_policy_content_5: `<h5>DNT is a concept that has been promoted by regulatory agencies such as the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing Internet users to control the tracking of their online activities across websites by using browser settings. The World Wide Web Consortium (W3C) has been working with industry groups, Internet browsers, technology companies, and regulators to develop a DNT technology standard. While some progress has been made, it has been slow. No standard has been adopted to this date. As such, Meet.jobs does not generally respond to “do not track” signals.</h5>`,

  cookie_policy_title_6: `<span class="li-number">7.</span>Controlling cookies`,
  cookie_policy_content_6: `<h5>Most browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information.</h5>`,

  cookie_policy_title_7: `<span class="li-number">8.</span>What to do if you don’t want cookies to be set or want them to be removed?`,
  cookie_policy_content_7: `<h5>If you do not want to receive cookies, you can also change your browser settings on your computer or other device you’re using to access our services. If you use Meet.jobs without changing your browser settings, we’ll assume that you’re happy to receive all cookies on the Meet.jobs website. Most browsers also provide functionality that lets you review and erase cookies, including Meet.jobs cookies. Please note that the Meet.jobs site will not work properly without cookies.</h5>`,

  cookie_policy_title_8: `<span class="li-number">9.</span>Other helpful resources`,
  cookie_policy_content_8: `<h5>To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit wikipedia.org, www.allaboutcookies.org, or www.aboutcookies.org.</h5>`,

  // summary_of_changes
  summary_of_changes_user_agreement_content: ``,
  summary_of_changes_employer_agreement_content: `<p>7. The Client is able to post the recruiting job information to the Meet.jobs website and all extended web platforms and guarantee the information provided is correct and without mistakes. If the information provided by the Client is incorrect and causes damages to Meet.jobs, the Client shall be liable to Meet.jobs for the damages; in the event applicants suffer damages incurred therefrom, the Client shall be liable and Meet.jobs will not be involved. </p>
        <p>17. Meet.jobs will share and promote the Client’s business and job openings information on social networks, including, without limitation, Facebook, LinkedIn, YouTube, Instagram, WeChat and TikTok, as well as other websites or networks specially required by the Client. </p>
        <p>30. For those candidates who are accepted through Meet.jobs, Meet.jobs guarantees that such candidates will work for a period not less than 30 calendar days; however, this shall not apply to the case where the client and Meet.jobs will otherwise agree and keep records of such agreement. </p>
        <p>31. If the candidate resigns within the first 30 calendar days, then Meet.jobs needs to refund 50% of the service fee no matter which recruitment plan the client has adopted.</p>
        <p>32. Following the foregoing, Meet.jobs will help the client to recruit the substitute candidate and charge the full service fee for the substitute candidate of employment.</p>
        <p>33. Specifically, if such an offeree is disemployed by the Client during the trial period due to work performance within the first 30 calendar days, it will not be within the guaranteed scope of Meet.jobs. </p>
        <p>34. If the client has a customized demand, such as extending the guarantee period, please adopt the Hunter Plan and Meet.jobs will adjust the service fee based on the customized demand.</p>
        <p>46. Meet.jobs now accepts two payment methods of “credit cards”, “remittance” and “digital currency” among which the Client may choose therefrom and the same will be shown on the Meet.jobs system.</p>
        <p>50. The fee for the Social Plan shall be 16% of the actual annual salary of the recruited position, and the service fee shall be charged to the client based on the confirmation of the candidate's commencement of employment. </p>
        <p>56. The fee for the Hunter Plan shall be 20% or more of the actual salary of the recruited position, and service fee shall be charged to the client based on the confirmation of the candidate’s commencement of employment. </p>
        <p>60. For other customized services, such as poaching candidates, salary negotiations, guaranteeing the number of interviews, etc., Meet.jobs will charge 20% or more of the service fee depending on the needs of the service content. Fees and collecting methods shall be discussed and agreed by both the client and Meet.jobs by means capable of leaving a record. If necessary, a supplementary agreement may be made for recording. </p>
        <p>80. If the client continues to use Meet.jobs after 1st May 2023, it shall be deemed to have agreed to the new agreement. </p>`,
  summary_of_changes_privacy_policy_content: ``,
  summary_of_changes_cookie_policy_content: ``,
};
