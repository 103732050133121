import React from 'react';
import {withNamespaces, Interpolate} from "react-i18next";
import { CSSTransitionGroup } from 'react-transition-group'
import InternalUri from "../../../models/internal_uri";
import { Link } from "react-router-dom";
import {copy_string} from "../../../../assets/js/functions";

class MyReferralLinkModal extends React.Component {

    constructor(props) {
        super(props);
        this.handleCopyLink = this.handleCopyLink.bind(this);
        this.handleCopyCode = this.handleCopyCode.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            referral_code_copied: false,
            referral_link_copied: false,
        };
        this.referral_code = props.reduxTokenAuth.currentUser.attributes.referral_code;
        this.referral_link = `${window.location.origin}?referral_code=${props.reduxTokenAuth.currentUser.attributes.referral_code}`;
        this.copied_code_remaining_seconds = 2;
        this.timer = false;
    };

    handleCopyLink = (e) => {
        e.preventDefault();
        copy_string(e, this.referral_link);

        const input =  document.querySelector(".input-referral-link");
        if(input) {
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }

        this.setState({ referral_link_copied: true });
    };

    handleCopyCode = (e) => {
        e.preventDefault();
        copy_string(e, this.referral_code);
        if(!this.timer) this.timer = setInterval(this.tick, 1000);
        this.setState({ referral_code_copied: true });
    };

    tick = () => {
        this.copied_code_remaining_seconds -= 1;
        if(this.copied_code_remaining_seconds <= 0) {
            this.copied_code_remaining_seconds = 2;
            this.setState({ referral_code_copied: false });
            clearInterval(this.timer);
            this.timer = false;
        }
    };

    closeModal = e => {
        e.preventDefault();
        this.props.updateMyReferralLinkModal(false);
    };

    render() {

        const { handleCopyLink, handleCopyCode, closeModal } = this;
        const { site_state } = this.props;
        const { referral_code_copied, referral_link_copied } = this.state;
        const { t } = this.props;
        const internal_uri = new InternalUri();

        return (
            <CSSTransitionGroup
                component="div"
                transitionName="modal-wrapper-with-modal-slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {(site_state.my_referral_link_modal.open) ?
                    <div className="modal-wrapper my-referral-link-modal-wrapper">
                        <div className="modal-inner-wrapper">
                            <div className="modal-bg" onClick={ e => closeModal(e) } />
                            <div className="modal my-referral-link-modal">
                                <div className="btn-close" onClick={ e => closeModal(e) } />
                                <h4 className="modal-title">{t('my_referral_link')}</h4>
                                <div className="input-btn-wrapper">
                                    <input
                                        readOnly
                                        className="input input-referral-link"
                                        type="text"
                                        value={this.referral_link}
                                    />
                                    <div
                                        className="btn btn-larger btn-flat btn-fill"
                                        onClick={ e => handleCopyLink(e)}
                                        data-event-category="542_my_referral_link_copy"
                                    >
                                        {
                                            (referral_link_copied) ?
                                                <h5>{t('general:copied')}</h5> :
                                                <h5>{t('general:copy')}</h5>
                                        }
                                    </div>
                                </div>
                                {(referral_link_copied) ? <h6 className="message notice-message">{t('general:link_is_copied')}</h6> : ''}
                                <h5 className="sub-title">{t('how_it_works_')}</h5>
                                <ul>
                                    <li>
                                        <h6>{t('_1__share_your_referral_link_to_friends_')}</h6>
                                    </li>
                                    <li>
                                        <h6>
                                            <Interpolate
                                                i18nKey="_2__or_enter_your_referral_code___when_he_or_she_applies_"
                                                useDangerouslySetInnerHTML={true}
                                                referral_code={
                                                    (referral_code_copied) ?
                                                        <span className="referral-code">{t('general:copied').toLowerCase()}</span> :
                                                        <button
                                                            className="referral-code"
                                                            onClick={ e => handleCopyCode(e) }
                                                            data-event-category="543_my_referral_code_copy"
                                                        >{this.referral_code}</button>
                                                }
                                            />
                                        </h6>
                                    </li>
                                    <li>
                                        <h6>{t('_3__when_candidates_are_successfully_hired_and_pass_the_probation_claim_your_referral_cash_reward_')}</h6>
                                    </li>
                                    <li>
                                        <h6>
                                            <Link
                                                to={internal_uri.formatted_frontend_referrer_landing_page_path()}
                                                className="link"
                                                data-event-category="544_my_referral_link_learn_more"
                                            >
                                                {t('general:learn_more')}
                                            </Link>
                                        </h6>
                                    </li>
                                </ul>
                                <div className="buttons-wrapper">
                                    <button className="btn btn-flat btn-fill" onClick={ e => closeModal(e) }>
                                        <h5>{t('general:ok')}</h5>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : ''
                }
            </CSSTransitionGroup>
        );
    };
}

export default withNamespaces(['userpanel_modals', 'general'])(MyReferralLinkModal);
