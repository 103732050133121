import React from 'react';
import Select from 'react-select';
import Textarea from 'react-textarea-autosize';
import { Link } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group'
import axios from "axios";
import { withNamespaces, Interpolate } from "react-i18next";
import { compare, get_options, contain_not_only_whitespace, page_smothly_scroll_to, raw_html_to_editor_content } from "../../../../assets/js/functions";
import ApiHeaders from "../../../models/api_headers";
import Config from "../../../models/config.js";
import Employer from "../../../models/employer.js";
import Metas from "../../../models/metas.js";
import icon_upload from "../../../../assets/images/icon/icon_24_upload_B100.svg";
import i18n from "../../../../i18n";
import Editor from "../Editor.jsx";
import Prompt from "../../general/Prompt.jsx";
import default_logo from '../../../../assets/images/image_default_logo.svg';
import InternalUri from "../../../models/internal_uri";

const config = new Config();
const headers = new ApiHeaders();

class CompanyProfilePage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.updateActiveCompany = this.updateActiveCompany.bind(this);
        this.handleOptions = this.handleOptions.bind(this);

        this.handleLogoChange = this.handleLogoChange.bind(this);

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);

        this.addPhoto = this.addPhoto.bind(this);
        this.deletePhoto = this.deletePhoto.bind(this);

        this.addAddress = this.addAddress.bind(this);
        this.deleteAddress = this.deleteAddress.bind(this);
        this.handleAddressCountrySelectChange = this.handleAddressCountrySelectChange.bind(this);
        this.handleAddressInputChange = this.handleAddressInputChange.bind(this);
        this.handleMainOfficeChange = this.handleMainOfficeChange.bind(this);

        // check pending data
        this.checkPendingData = this.checkPendingData.bind(this);
        this.getCurrentEmployerProfileDegreeOfCompletion = this.getCurrentEmployerProfileDegreeOfCompletion.bind(this);

        // actions bar
        this.discard = this.discard.bind(this);
        this.submitForm = this.submitForm.bind(this);

        this.state = {
            active_company_id: -1,
            employer: {},
            form_data: {},
            original_form_data: {},
            has_pending_data: false,
            options: {},
            metas: {},
            new_address: {
                options: [],
                metas: []
            },
            loading: true,
            publishing: false,
            error_messages: {},
            force_switch_to_anothor_employer: false,
        };
    };

    componentDidMount() {
        this._isMounted = true;

        let options = {
            method: 'GET',
            url: config.api_url(`/employers/industries?locale=${i18n.language}`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    this.handleOptions('industries', response.data);
                    options = {
                        method: 'GET',
                        url: config.api_url('/employers/company_sizes'),
                        headers: headers.getItemsFromLocalStorage(),
                        json: true
                    };
                    return axios(options);
                }
            })
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);

                if(this._isMounted) {
                    this.handleOptions('company_sizes', response.data);
                    options = {
                        method: 'GET',
                        url: config.api_url('/countries'),
                        headers: headers.getItemsFromLocalStorage(),
                        json: true
                    };
                    return axios(options);
                }
            })
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);

                if(this._isMounted) {
                    this.handleOptions('countries', response.data);
                    this.handleOptions('phone_codes', response.data);

                    this.setState({
                        error_messages: {},
                        loading: true
                    }, () => this.updateActiveCompany(this.props.current_user.active_company.id));
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                }
            })
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(this.props.current_user.active_company.id !== next_props.current_user.active_company.id) {
            const internal_uri = new InternalUri();
            this.updateActiveCompany(next_props.current_user.active_company.id);
            this.setState({
                force_switch_to_anothor_employer: true
            }, () => this.props.history.push(internal_uri.formatted_dashboard_index_page_path()));
        }

        // TODO: what is this for?
        if(!next_props.reduxTokenAuth.currentUser.isSignedIn) window.location.reload();
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.props.updateEmployerProfilePendingDegreeOfCompletion(null);
    };

    updateActiveCompany(company_id) {
        const { current_user } = this.props;
        if(current_user.employer_roles[company_id].access_control_list.setting_manageable) {
            this.setState((prev_state) => ({
                ...prev_state,
                active_company_id: company_id,
                employer: {...current_user.active_company},
                access_control_list: current_user.employer_roles[company_id].access_control_list,
                loading: true,
            }), () => this.fetchData());
        } else {
            this.setState((prev_state) => ({
                ...prev_state,
                active_company_id: company_id,
                employer: {...current_user.active_company},
                access_control_list: current_user.employer_roles[company_id].access_control_list,
                loading: false,
            }));
        }
    };

    fetchData() {
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${this.state.active_company_id}?include=addresses,photos,contact`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let photos = [], addresses = [];
        let form_data = {};
        let employer = {};
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_employer = response.data;
                    const { options, metas } = this.state;
                    employer = new Employer(raw_employer);
                    this.props.updateCurrentUserActiveCompany({...employer});

                    if(employer.photos.length > 0) {
                        for(let i=0; i<employer.photos.length; i++) {
                            photos.push({
                                id: employer.photos[i].id,
                                image_preview_url: employer.photos[i].asset.url,
                                "_destroy": false,
                            })
                        }
                    }

                    if(employer.addresses.length > 0) {
                        for(let i=0; i<employer.addresses.length; i++) {
                            const address = employer.addresses[i];
                            addresses.push({
                                id: address.id,
                                countries_selected_value: metas.countries.key(address.handwriting_country) ? metas.countries.key(address.handwriting_country) : 'TW',
                                city: address.formatted_form_city(),
                                street: address.formatted_form_street(),
                                is_main_office: address.is_main_office,
                                latitude: address.latitude,
                                longitude: address.longitude,
                                "_destroy": false,
                            });
                        }
                    }

                    form_data = {
                        logo: { image_preview_url: employer.formatted_logo_profile_url() },
                        industries_selected_value: (employer.industry_key) ? employer.industry_key : options.industries[0].value,
                        company_sizes_selected_value: (employer.company_size_key !== null) ? `${employer.company_size_key}` : '0',
                        addresses,
                        url: (employer.url) ? (employer.url) : '',
                        slogan: (employer.slogan) ? (employer.slogan) : '',
                        description: raw_html_to_editor_content((employer.description) ? (employer.description) : ''),
                        photos,
                        video: (employer.video) ? (employer.video) : '',
                        contact_name: employer.formatted_form_contact_name(),
                        contact_phone_number: employer.formatted_form_contact_phone_number()
                    };

                    this.setState((prev_state) => ({
                        ...prev_state,
                        form_data,
                        employer,
                        original_form_data: JSON.parse(JSON.stringify(form_data)),
                        has_pending_data: false,
                        error_messages: {},
                        loading: false
                    }));
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                }
            });
    };

    handleOptions(property_name, raw_data) {
        const { t } = this.props;
        let new_metas = new Metas(property_name, raw_data);
        let new_options = get_options(property_name, raw_data, t);
        this.setState((prev_state) => ({
            ...prev_state,
            options: {
                ...prev_state.options,
                [property_name]: new_options
            },
            metas: {
                ...prev_state.metas,
                [property_name]: new_metas
            }
        }));
    };

    handleSelectChange = (property_name, selected_option) => {
        if(selected_option) {
            this.setState((prev_state) => ({
                form_data: {
                    ...prev_state.form_data,
                    [property_name]: selected_option.value
                }
            }), () => this.checkPendingData());
        }
    };

    handleInputChange = (e, property_name) => {
        e.preventDefault();
        const value = e.target.value;
        this.setState((prev_state) => {
            // check form error
            let error_messages = {...prev_state.error_messages};
            const { t } = this.props;
            switch (property_name) {
                case 'slogan' :
                    let slogan = [];
                    if(value.length>180) slogan.push(`${t('general:length_of_text_limit__180_characters')}`);
                    error_messages = {
                        ...error_messages,
                        slogan
                    };
                    break;
                case 'contact_name' :
                    let contact_name = [];
                    if(value.length<=0) contact_name.push(`${t('general:required')}`);
                    error_messages = {
                        ...error_messages,
                        'contact.name': contact_name
                    };
                    break;
                case 'contact_phone_number' :
                    let contact_phone_number = [];
                    if(value.length<=0) contact_phone_number.push(`${t('general:required')}`);
                    error_messages = {
                        ...error_messages,
                        'contact.mobile': contact_phone_number
                    };
                    break;
                default :
                    error_messages = {
                        ...error_messages,
                        [property_name]: []
                    };
                    break;
            }

            return ({
                ...prev_state,
                form_data: {
                    ...prev_state.form_data,
                    [property_name]: value
                },
                error_messages
            });
        }, () => this.checkPendingData());
    };

    handleDescriptionChange = content => {
        this.setState((prev_state) => {
            let error_messages = {...prev_state.error_messages};
            const { t } = this.props;
            let description = [];
            if(content.replace(/(<([^>]+)>)/ig, "").length>6000) description.push(`${t('general:length_of_text_limit__6000_characters')}`);
            error_messages = {
                ...error_messages,
                description
            };

            return ({
                ...prev_state,
                form_data: {
                    ...prev_state.form_data,
                    description: content
                },
                error_messages
            })
        }, () => this.checkPendingData());
    };

    handleLogoChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if(file) {
            const sizeLimit = 30*1024000;
            let logo = [];
            if(file.size>sizeLimit) {
                const { t } = this.props;
                logo.push(`${t('general:upload_size_limit__30MB')}`);
                this.setState((prev_state) => ({
                    ...prev_state,
                    error_messages: {
                        ...prev_state.error_messages,
                        logo
                    }
                }));
            } else {
                reader.onloadend = () => {
                    this.setState((prev_state) => ({
                        ...prev_state,
                        form_data: {
                            ...prev_state.form_data,
                            logo: {
                                file: file,
                                image_preview_url: reader.result
                            }
                        },
                        error_messages: {
                            ...prev_state.error_messages,
                            logo
                        }
                    }), () => this.checkPendingData());
                };
                reader.readAsDataURL(file);
            }
        }
    };

    addPhoto = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if(file) {
            const sizeLimit = 10*1024000;
            let photos = [];
            if(file.size>sizeLimit) {
                const { t } = this.props;
                photos.push(`${t('general:upload_size_limit__30MB')}`);
                this.setState((prev_state) => ({
                    ...prev_state,
                    error_messages: {
                        ...prev_state.error_messages,
                        photos
                    }
                }));
            } else {
                reader.onloadend = () => {
                    this.setState((prev_state) => ({
                        ...prev_state,
                        form_data: {
                            ...prev_state.form_data,
                            photos: [
                                ...prev_state.form_data.photos,
                                {
                                    id: null,
                                    file,
                                    image_preview_url: reader.result,
                                    "_destroy": false,
                                }
                            ]
                        },
                        error_messages: {
                            ...prev_state.error_messages,
                            photos
                        }
                    }), () => this.checkPendingData());
                };
                reader.readAsDataURL(file);
            }
        }
    };

    deletePhoto = (e, index) => {
        e.preventDefault();
        this.setState((prev_state) => {
            let photos = prev_state.form_data.photos;
            if(photos[index].id!==null) {
                photos[index]['_destroy'] = true;
            } else {
                photos.splice(index, 1);
            }
            return ({
                ...prev_state,
                form_data: {
                    ...prev_state.form_data,
                    photos
                }
            })
        }, () => this.checkPendingData());
    };

    addAddress = e => {
        e.preventDefault();
        this.setState((prev_state) => ({
            ...prev_state,
            form_data: {
                ...prev_state.form_data,
                addresses: [
                    ...prev_state.form_data.addresses,
                    {
                        id: null,
                        countries_selected_value: 'TW',
                        city: '',
                        street: '',
                        // 若該公司沒有任何 address 時，設第一個 address 為 is_main_office: true
                        is_main_office: (prev_state.form_data.addresses.length===0),
                        latitude: '',
                        longitude: '',
                        "_destroy": false,
                    }
                ]
            }
        }), () => this.checkPendingData());
    };

    deleteAddress = (e, index) => {
        e.preventDefault();
        this.setState((prev_state) => {
            let addresses = prev_state.form_data.addresses;

            // 先轉移 is_main_office
            if(addresses[index].is_main_office) {
                addresses[index]['is_main_office'] = false;
                for(let i=0; i<addresses.length; i++) {
                    if(i !== index && !addresses[i]['_destroy']) {
                        addresses[i]['is_main_office'] = true;
                        break;
                    }
                }
            }

            if(addresses[index].id !== null) { // 刪除已建立的地址
                addresses[index]['_destroy'] = true;
            } else { // 刪除尚未建立的地址
                addresses.splice(index, 1);
            }

            // delete address 時，要再 refresh 各 address 的值。
            // check form error
            let error_messages = {...prev_state.error_messages};
            const { t } = this.props;
            // TODO: 寫法應做調整
            let countries_selected_value = [], city = [];
            let country_has_blank = false, city_has_blank = false;
            for(let i=0; i<addresses.length; i++) {
                let address = addresses[i];
                if(!address['_destroy']) {
                    if(address.countries_selected_value.length<=0) country_has_blank = true;
                    if(address.city.length<=0) city_has_blank = true;
                }
            }
            if(country_has_blank) countries_selected_value.push(`${t('general:required')}`);
            if(city_has_blank) city.push(`${t('general:required')}`);

            error_messages = {
                ...error_messages,
                'addresses.handwriting_country': countries_selected_value,
                'addresses.handwriting_city': city,
                'addresses.handwriting_street': [],
            };

            return ({
                ...prev_state,
                form_data: {
                    ...prev_state.form_data,
                    addresses
                },
                error_messages
            })
        }, () => this.checkPendingData());
    };

    handleAddressCountrySelectChange = (index, selected_option) => {
        if(selected_option) {
            this.setState((prev_state) => {
                let addresses = prev_state.form_data.addresses;
                addresses[index]['countries_selected_value'] = selected_option.value;

                // check form error
                let error_messages = {...prev_state.error_messages};
                const { t } = this.props;
                let countries_selected_value = [];
                let country_has_blank = false;
                for(let i=0; i<addresses.length; i++) {
                    let address = addresses[i];
                    if(!address['_destroy']) {
                        if(address.countries_selected_value.length<=0) country_has_blank = true;
                    }
                }
                if(country_has_blank) countries_selected_value.push(`${t('general:required')}`);
                error_messages = {
                    ...error_messages,
                    'addresses.handwriting_country': countries_selected_value,
                };

                return ({
                    ...prev_state,
                    form_data: {
                        ...prev_state.form_data,
                        addresses
                    },
                    error_messages
                });
            }, () => this.checkPendingData());
        }
    };

    handleAddressInputChange = (e, index, property_name) => {
        e.preventDefault();
        const value = e.target.value;
        this.setState((prev_state) => {
            let addresses = prev_state.form_data.addresses;
            addresses[index][property_name] = value;

            // check form error
            let error_messages = {...prev_state.error_messages};
            const { t } = this.props;
            switch (property_name) {
                case 'city' :
                    let city = [];
                    let city_has_blank = false;
                    for(let i=0; i<addresses.length; i++) {
                        let address = addresses[i];
                        if(!address['_destroy']) {
                            if(address.city.length<=0) city_has_blank = true;
                        }
                    }
                    if(city_has_blank) city.push(`${t('general:required')}`);
                    error_messages = {
                        ...error_messages,
                        'addresses.handwriting_city': city,
                    };
                    break;
                case 'street' :
                    error_messages = {
                        ...error_messages,
                        'addresses.handwriting_street': [],
                    };
                    break;
                default :
                    error_messages = {
                        ...error_messages,
                        [property_name]: []
                    };
                    break;
            }

            return ({
                ...prev_state,
                form_data: {
                    ...prev_state.form_data,
                    addresses
                },
                error_messages
            });
        }, () => this.checkPendingData());
    };

    handleMainOfficeChange(e, index) {
        e.preventDefault();
        this.setState((prev_state) => {
            let addresses = prev_state.form_data.addresses;
            for(let i=0; i<addresses.length; i++) {
                addresses[i].is_main_office = false;
            }
            addresses[index].is_main_office = true;
            return ({
                form_data: {
                    ...prev_state.form_data,
                    addresses
                }
            })
        }, () => this.checkPendingData());
    };

    //check pending data

    checkPendingData() {
        const { form_data, original_form_data } = this.state;
        const has_pending_data = !compare(JSON.parse(JSON.stringify(form_data)), original_form_data);
        this.setState({ has_pending_data });
        this.props.updateEmployerProfilePendingDegreeOfCompletion(this.getCurrentEmployerProfileDegreeOfCompletion(form_data));
    };

    getCurrentEmployerProfileDegreeOfCompletion(form_data) {
        let employer_profile_completion_count = 1;

        if(form_data.logo.image_preview_url !== default_logo) employer_profile_completion_count++;
        if(parseInt(form_data.company_sizes_selected_value, 10) !== 0) employer_profile_completion_count++;
        if(form_data.addresses && form_data.addresses.length) {
            for(let i=0; i<form_data.addresses.length; i++) {
                if(
                    form_data.addresses[i].countries_selected_value
                    && contain_not_only_whitespace(form_data.addresses[i].city)
                    && contain_not_only_whitespace(form_data.addresses[i].street)
                ) {
                    employer_profile_completion_count++;
                    break;
                }
            }
        }
        if(contain_not_only_whitespace(form_data.url)) employer_profile_completion_count++;
        if(contain_not_only_whitespace(form_data.slogan)) employer_profile_completion_count++;
        if(contain_not_only_whitespace(form_data.description.replace(/(<([^>]+)>)/ig, "").replace(/&nbsp;/g, " "))) employer_profile_completion_count++;
        if(form_data.photos && form_data.photos.length) employer_profile_completion_count++;
        if(contain_not_only_whitespace(form_data.contact_name)) employer_profile_completion_count++;
        if(contain_not_only_whitespace(form_data.contact_phone_number)) employer_profile_completion_count++;

        return (employer_profile_completion_count/10) * 100
    };

    // actions bar

    discard = e => {
        e.preventDefault();
        this.setState((prev_state) => ({
            ...prev_state,
            form_data: JSON.parse(JSON.stringify(prev_state.original_form_data)),
            error_messages: {}
        }), () => this.checkPendingData());
    };

    submitForm = e => {
        e.preventDefault();
        this.setState({ publishing: true });
        const { form_data, metas } = this.state;
        let formData = new FormData();
        if(typeof form_data.logo.file !== 'undefined') formData.append('employer[logo]', form_data.logo.file);
        formData.append('employer[industry]', form_data.industries_selected_value);
        formData.append('employer[company_size]', form_data.company_sizes_selected_value);
        formData.append('employer[url]', form_data.url);
        formData.append('employer[slogan]', form_data.slogan);
        formData.append('employer[description]', form_data.description);
        formData.append('employer[video]', form_data.video);
        formData.append('employer[contact_attributes][name]', form_data.contact_name);
        formData.append('employer[contact_attributes][mobile]', form_data.contact_phone_number);

        for(let i=0; i<form_data.photos.length; i++) {
            const photo = form_data.photos[i];
            if(photo.id!==null && photo["_destroy"]===true) {
                // 刪除 photo
                formData.append(`employer[photos_attributes][${i}][id]`, photo.id);
                formData.append(`employer[photos_attributes][${i}][_destroy]`, true);
            } else if(photo.id===null && photo.file) {
                // 新增 photo
                formData.append(`employer[photos_attributes][${i}][asset]`, photo.file);
                // formData.append(`employer[photos_attributes][${i}][seq]`, (i+1));
            } else {
                // 更新 photo
                formData.append(`employer[photos_attributes][${i}][id]`, photo.id);
                // formData.append(`employer[photos_attributes][${i}][seq]`, (i+1));
            }
        }

        for(let i=0; i<form_data.addresses.length; i++) {
            const address = form_data.addresses[i];
            if(address.id!==null && address["_destroy"]===true) {
                // 刪除 address
                formData.append(`employer[addresses_attributes][${i}][id]`, address.id);
                formData.append(`employer[addresses_attributes][${i}][_destroy]`, true);
            } else if(address.id===null) {
                // 新增 address
                formData.append(`employer[addresses_attributes][${i}][handwriting_country]`, metas.countries.value(address.countries_selected_value));
                formData.append(`employer[addresses_attributes][${i}][handwriting_city]`, address.city);
                formData.append(`employer[addresses_attributes][${i}][handwriting_street]`, address.street);
                if(address.is_main_office) {
                    formData.append(`employer[addresses_attributes][${i}][is_main_office]`, address.is_main_office);
                }
            } else {
                // 更新 address
                formData.append(`employer[addresses_attributes][${i}][id]`, address.id);
                formData.append(`employer[addresses_attributes][${i}][handwriting_country]`, metas.countries.value(address.countries_selected_value));
                formData.append(`employer[addresses_attributes][${i}][handwriting_city]`, address.city);
                formData.append(`employer[addresses_attributes][${i}][handwriting_street]`, address.street);
                if(address.is_main_office) {
                    formData.append(`employer[addresses_attributes][${i}][is_main_office]`, address.is_main_office);
                }
            }
        }

        let options = {
            method: 'PUT',
            url: config.api_url(`/employers/${this.state.active_company_id}`),
            headers: headers.getItemsFromLocalStorage('multipart/form-data'),
            data: formData,
            json: true
        };
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    this.setState((prev_state) => ({
                        ...prev_state,
                        error_messages: {},
                        publishing: false
                    }), () => {
                        this.updateActiveCompany(this.state.active_company_id);
                    });
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => {
                        if(error.response && error.response.data && error.response.data.status === 'error') {
                            // 將 errors.contact 分別塞進 errors.contact.name & errors.contact.mobile
                            if(error.response.data.errors && error.response.data.errors.contact) {
                                if(error.response.data.errors['contact.name']) error.response.data.errors['contact.name'].concat(error.response.data.errors.contact);
                                else error.response.data.errors['contact.name'] = error.response.data.errors.contact;

                                if(error.response.data.errors['contact.mobile']) error.response.data.errors['contact.mobile'].concat(error.response.data.errors.contact);
                                else error.response.data.errors['contact.mobile'] = error.response.data.errors.contact;

                                error.response.data.errors.contact = [];
                            }

                            // 將 errors.addresses 分別塞進 errors.addresses.handwriting_country & errors.addresses.handwriting_city
                            if(error.response.data.errors && error.response.data.errors.addresses) {
                                if(error.response.data.errors['addresses.handwriting_country']) error.response.data.errors['addresses.handwriting_country'].concat(error.response.data.errors.addresses);
                                else error.response.data.errors['addresses.handwriting_country'] = error.response.data.errors.addresses;

                                if(error.response.data.errors['addresses.handwriting_city']) error.response.data.errors['addresses.handwriting_city'].concat(error.response.data.errors.addresses);
                                else error.response.data.errors['addresses.handwriting_city'] = error.response.data.errors.addresses;

                                error.response.data.errors.addresses = [];
                            }
                        }
                        return ({
                            ...prev_state,
                            error_messages:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: "There was an error. Please try again later."}
                            ,
                            publishing: false
                        })
                    });

                    this.setState((prev_state) => ({
                        ...prev_state,
                        publishing: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));
                }
            })
            .then(() => {
                this.checkPendingData();
                page_smothly_scroll_to(0, 300);
            });
    };

    render() {

        const { employer, access_control_list, form_data, options, has_pending_data, loading, publishing, error_messages, force_switch_to_anothor_employer }  = this.state;
        const { handleSelectChange, handleInputChange, handleDescriptionChange } = this;
        const { discard, submitForm } = this;
        const { t } = this.props;
        const internal_uri = new InternalUri();

        const {
            addAddress,
            deleteAddress,
            handleAddressInputChange,
            handleAddressCountrySelectChange,
            handleMainOfficeChange
        } = this;

        const {
            handleLogoChange,
            addPhoto,
            deletePhoto,
        } = this;

        if(loading)
            return (
                <div className="loading-skeleton-wrapper">
                    <div className="dashboard-employer-profile">
                        <div className="container-fluid">
                            <h6 className="breadcrumb">
                                <Link to={internal_uri.formatted_dashboard_index_page_path()}>{t('general:home')}</Link>
                                {' / '}
                                {t('general:employer_profile')}
                            </h6>
                            <div className="title-with-buttons">
                                <h2>{t('general:employer_profile')}</h2>
                                <Link
                                    to={internal_uri.formatted_frontend_employer_page_path(employer.id, employer.slug)}
                                    className="btn btn-larger btn-float btn-hollow skip-loading-skeleton"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <h5>{t('view_employer_page')}</h5>
                                </Link>
                            </div>

                            <h4 className="title-outside">{t('basic_information')}</h4>
                            <div className="block">
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <label htmlFor="" className="in-line">
                                            <h5>{t('employer_log')}</h5>
                                            <h6>{t('_recommend_size__300x300px_')}</h6>
                                        </label>
                                    </div>
                                    <div className="form-col">
                                        <div className="logo-wrapper">
                                            <div className="logo">
                                                <div className='image-wrapper' />
                                            </div>
                                            <label className="btn btn-larger">{t('general:choose_file')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col" />
                                </div>

                                <label htmlFor=""><h5>{t('industry')}</h5></label>
                                <div className="form-row form-row-dire-col industry">
                                    <div className="form-col">
                                        <div className="select-wrapper">
                                            <Select className="drop-down drop-down-larger" />
                                        </div>
                                    </div>
                                    <div className="form-col" />
                                </div>

                                <label><h5>{t('employer_size')}</h5></label>
                                <div className="form-row form-row-dire-col employer-size">
                                    <div className="form-col">
                                        <div className="select-wrapper">
                                            <Select className="drop-down drop-down-larger" />
                                        </div>
                                    </div>
                                    <div className="form-col" />
                                </div>

                                <label><h5>{t('address')}</h5></label>
                                <div className="form-row form-row-dire-col">
                                    <div className="addresses">
                                        <div className="address">
                                            <Select className="drop-down drop-down-larger" />
                                            <input className="input" type="text" />
                                            <Textarea
                                                className="textarea"
                                                minRows={3}
                                                maxRows={6}
                                            />
                                        </div>
                                    </div>
                                    <div className="link-wrapper">
                                        <div className="link text text-80">{t('add_address')}</div>
                                    </div>
                                </div>

                                <label htmlFor=""><h5>{t('website')}</h5></label>
                                <div className="form-row form-row-dire-col website">
                                    <div className="form-col">
                                        <input className="input" type="text"/>
                                    </div>
                                    <div className="form-col" />
                                </div>
                            </div>

                            <h4 className="title-outside">{t('employer_description')}</h4>
                            <div className="block">
                                <label htmlFor="skills" className="lines">
                                    <h5>{t('describe_your_organization_in_one_sentence')}</h5>
                                    <h6>
                                        <Interpolate
                                            i18nKey="make_candidates_understand_your_company_quickly____180_"
                                            useDangerouslySetInnerHTML={true}
                                            count={0}
                                        />
                                    </h6>
                                </label>
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <Textarea
                                            className="textarea"
                                            minRows={2}
                                            maxRows={3}
                                        />
                                    </div>
                                    <div className="form-col" />
                                </div>

                                <label htmlFor="skills" className="lines">
                                    <h5>{t('description')}</h5>
                                    <h6>{t('introduce_your_products_or_corporate_culture')}</h6>
                                </label>
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <Editor />
                                    </div>
                                    <div className="form-col" />
                                </div>
                            </div>

                            <h4 className="title-outside">{t('photos_and_videos')}</h4>
                            <div className="block">
                                <label htmlFor=""><h5>{t('photos')}</h5></label>
                                <div className="photos">
                                    <div className="photo">
                                        <div className='image-wrapper' />
                                        <div className="btn-close" />
                                    </div>
                                </div>

                                <label htmlFor=""><h5>{t('youtube_video_link')}</h5></label>
                                <div className="form-row form-row-dire-col youtube-link">
                                    <div className="form-col">
                                        <input className="input" type="text" />
                                    </div>
                                    <div className="form-col" />
                                </div>
                            </div>

                            <h4 className="title-outside">{t('employer_contact')}</h4>
                            <div className="block">
                                <div className="form-row form-row-2">
                                    <div className="form-col">
                                        <div className="form-row form-row-dire-col">
                                            <div className="form-col">
                                                <label htmlFor=""><h5>{t('name')}</h5></label>
                                                <input className="input" type="text" />
                                            </div>
                                            <div className="form-col" />
                                        </div>
                                    </div>
                                    <div className="form-col">
                                        <div className="form-row form-row-dire-col">
                                            <div className="form-col">
                                                <label htmlFor=""><h5>{t('phone_number')}</h5></label>
                                                <input className="input" type="text" />
                                            </div>
                                            <div className="form-col" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        if(access_control_list && !access_control_list.setting_manageable) {
            return (
                <div className="dashboard-employer-profile">
                    <div className="container-fluid">
                        <h6 className="breadcrumb">
                            <Link to={internal_uri.formatted_dashboard_index_page_path()}>{t('general:home')}</Link>
                            {' / '}
                            {t('general:employer_profile')}
                        </h6>
                        <div className="title-with-buttons">
                            <h2>{t('general:employer_profile')}</h2>
                        </div>
                        {t('general:you_don_t_have_the_proper_privilege_level_to_visit_this_page_')}
                    </div>
                </div>
            );
        }

        let count_of_addresses = 0;
        if(form_data.addresses && form_data.addresses.length) {
            for(let i=0; i<form_data.addresses.length; i++) {
                if(form_data.addresses[i]['_destroy'] === false) {
                    count_of_addresses++;
                }
            }
        }

        let has_errors = false;
        const white_list = ['full_message', 'photos', 'logo'];
        for(let key in error_messages) {
            if(white_list.indexOf(key) === -1 && error_messages[key].length) has_errors = true;
        }

        return (
            <div className="dashboard-employer-profile">
                <Prompt when={
                    this.props.current_user.active_company.employer_profile_degree_of_completion < 100 &&
                    !force_switch_to_anothor_employer
                }>
                    {(isOpen, onConfirm, onCancel) => (
                        (isOpen) ?
                            <div className="modal-wrapper double-confirm-modal-wrapper">
                                <div className="modal-inner-wrapper">
                                    <div className="modal-bg" />
                                    <div className="modal double-confirm-modal">
                                        <div className="content">
                                            <h4 className="modal-title">
                                                {t('your_employer_profile_is_not_complete__are_you_sure_you_want_to_leave_this_page_')}
                                            </h4>
                                            <div className="buttons-wrapper">
                                                <button
                                                    className="btn btn-larger btn-flat btn-ghost"
                                                    onClick={onConfirm}
                                                >
                                                    <h5>{t('finish_later')}</h5>
                                                </button>
                                                <button
                                                    className="btn btn-larger btn-flat btn-fill"
                                                    onClick={onCancel}
                                                >
                                                    <h5>{t('keep_writing')}</h5>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''
                    )}
                </Prompt>

                <div className="container-fluid">
                    <h6 className="breadcrumb">
                        <Link to={internal_uri.formatted_dashboard_index_page_path()}>{t('general:home')}</Link>
                        {' / '}
                        {t('general:employer_profile')}
                    </h6>
                    <div className="title-with-buttons">
                        <h2>{t('general:employer_profile')}</h2>
                        <Link
                            to={internal_uri.formatted_frontend_employer_page_path(employer.id, employer.slug)}
                            className="btn btn-larger btn-float btn-hollow"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h5>{t('view_employer_page')}</h5>
                        </Link>
                    </div>

                    <h4 className="title-outside">{t('basic_information')}</h4>
                    <div className="block">
                        {(error_messages.full_message) ? <h6 className="message error-message spacer">{error_messages.full_message}</h6> : ''}
                        <div className="form-row form-row-dire-col">
                            <div className="form-col">
                                <label htmlFor="" className="in-line">
                                    <h5>{t('employer_log')}</h5>
                                    <h6>{t('_recommend_size__300x300px_')}</h6>
                                </label>
                            </div>
                            <div className="form-col">
                                <div className="logo-wrapper">
                                    <div className="logo">
                                        {
                                            (form_data.logo && form_data.logo.image_preview_url) ?
                                                <div
                                                    className='image-wrapper'
                                                    style={{backgroundImage: `url(${form_data.logo.image_preview_url})`}}
                                                /> :
                                                <div className='image-wrapper' />
                                        }
                                    </div>
                                    <input
                                        id="upload-logo"
                                        type="file"
                                        accept="image/png, image/jpeg, image/jpg, image/gif"
                                        onChange={ e => handleLogoChange(e) }
                                    />
                                    <label htmlFor="upload-logo" className="btn btn-larger btn-flat btn-hollow">{t('general:choose_file')}</label>
                                </div>
                            </div>
                            <div className="form-col">
                                {(error_messages.logo && error_messages.logo.length) ? <h6 className="message error-message">{error_messages.logo.join(', ')}</h6> : ''}
                            </div>
                        </div>

                        <label htmlFor=""><h5>{t('industry')}</h5></label>
                        <div className="form-row form-row-dire-col industry">
                            <div className="form-col">
                                <div className="select-wrapper">
                                    <Select
                                        className="drop-down drop-down-larger drop-down-grey"
                                        clearable={false}
                                        value={form_data.industries_selected_value}
                                        onChange={ value => handleSelectChange('industries_selected_value', value) }
                                        options={options.industries}
                                    />
                                </div>
                            </div>
                            <div className="form-col">
                                {(error_messages.industry && error_messages.industry.length) ? <h6 className="message error-message">{error_messages.industry.join(', ')}</h6> : ''}
                            </div>
                        </div>

                        <label htmlFor=""><h5>{t('employer_size')}</h5></label>
                        <div className="form-row form-row-dire-col employer-size">
                            <div className="form-col">
                                <div className="select-wrapper">
                                    <Select
                                        className="drop-down drop-down-larger drop-down-grey"
                                        clearable={false}
                                        value={form_data.company_sizes_selected_value}
                                        onChange={ value => handleSelectChange(`company_sizes_selected_value`, value) }
                                        options={options.company_sizes}
                                    />
                                </div>
                            </div>
                            <div className="form-col">
                                {(error_messages.company_size && error_messages.company_size.length) ? <h6 className="message error-message">{error_messages.company_size.join(', ')}</h6> : ''}
                            </div>
                        </div>

                        <label htmlFor=""><h5>{t('address')}</h5></label>
                        <div className="form-row form-row-dire-col">
                            <div className="addresses">
                                {
                                    (form_data.addresses && form_data.addresses.length) ?
                                        form_data.addresses.map((address, i) => {
                                            if(!address['_destroy']) {
                                                return (
                                                    <div className="address" key={i}>
                                                        {
                                                            (
                                                                !contain_not_only_whitespace(address.countries_selected_value) &&
                                                                error_messages['addresses.handwriting_country'] &&
                                                                error_messages['addresses.handwriting_country'].length
                                                            ) ?
                                                                <h6 className="message error-message">
                                                                    {error_messages['addresses.handwriting_country'].join(', ')}
                                                                </h6> : ''
                                                        }
                                                        <Select
                                                            className={`drop-down drop-down-larger drop-down-grey ${
                                                                (
                                                                    !contain_not_only_whitespace(address.countries_selected_value) &&
                                                                    error_messages['addresses.handwriting_country'] &&
                                                                    error_messages['addresses.handwriting_country'].length
                                                                ) ? 'input-danger' : ''
                                                                }`}
                                                            clearable={true}
                                                            searchable={true}
                                                            value={address.countries_selected_value}
                                                            onChange={(value) => handleAddressCountrySelectChange(i, value)}
                                                            options={options.countries}
                                                        />
                                                        {
                                                            (
                                                                !contain_not_only_whitespace(address.city) &&
                                                                error_messages['addresses.handwriting_city'] &&
                                                                error_messages['addresses.handwriting_city'].length
                                                            ) ?
                                                                <h6 className="message error-message">
                                                                    {error_messages['addresses.handwriting_city'].join(', ')}
                                                                </h6> : ''
                                                        }
                                                        <input
                                                            className={`input ${
                                                                (
                                                                    !contain_not_only_whitespace(address.city) &&
                                                                    error_messages['addresses.handwriting_city'] &&
                                                                    error_messages['addresses.handwriting_city'].length
                                                                ) ?
                                                                    'input-danger' : ''
                                                                }`}
                                                            type="text"
                                                            value={address.city}
                                                            onChange={ e => handleAddressInputChange(e, i, 'city')}
                                                            placeholder={t('city')}
                                                        />
                                                        <Textarea
                                                            className="textarea"
                                                            minRows={3}
                                                            maxRows={6}
                                                            value={address.street}
                                                            onChange={e => handleAddressInputChange(e, i, 'street')}
                                                            placeholder={t('full_address')}
                                                        />
                                                        {/*<a className="btn btn-flat btn-hollow">*/}
                                                        {/*<h5>Show on map</h5>*/}
                                                        {/*</a>*/}
                                                        {
                                                            (count_of_addresses > 1) ?
                                                                <div>
                                                                    <input
                                                                        className="radio-input"
                                                                        type="radio"
                                                                        id="set_main_office"
                                                                        name="is_main_office"
                                                                        value={i}
                                                                        checked={address.is_main_office}
                                                                        readOnly={true}
                                                                    />
                                                                    <label
                                                                        htmlFor="set_main_office"
                                                                        className="radio"
                                                                        onClick={e => handleMainOfficeChange(e, i)}
                                                                    >
                                                                        <p>{t('set_as_main_office')}</p>
                                                                    </label>

                                                                    <div className="delete-wrapper">
                                                                        <button
                                                                            className="link link-delete"
                                                                            onClick={e => deleteAddress(e, i)}
                                                                            disabled={address.is_main_office}
                                                                        >
                                                                            {t('delete_address')}
                                                                        </button>
                                                                    </div>
                                                                </div> : ''
                                                        }
                                                    </div>
                                                )
                                            } else {
                                                return null;
                                            }
                                        }) : ''
                                }
                            </div>
                            <div className="link-wrapper">
                                <button className="link" onClick={ e => addAddress(e) }>{t('add_address')}</button>
                            </div>
                        </div>

                        <label htmlFor=""><h5>{t('website')}</h5></label>
                        <div className="form-row form-row-dire-col website">
                            <div className="form-col">
                                <input
                                    className={`input ${(error_messages.url && error_messages.url.length) ? 'input-danger' : ''}`}
                                    type="text"
                                    value={form_data.url}
                                    onChange={ e => handleInputChange(e, `url`) }
                                    placeholder={`${t('general:e_g__')} https://meet.jobs`}
                                />
                            </div>
                            <div className="form-col">
                                {
                                    (error_messages.url && error_messages.url.length) ?
                                        <h6 className="message error-message">{error_messages.url.join(', ')}</h6> : ''
                                }
                            </div>
                        </div>
                    </div>

                    <h4 className="title-outside">{t('employer_description')}</h4>
                    <div className="block">
                        <label htmlFor="skills" className="lines">
                            <h5>{t('describe_your_organization_in_one_sentence')}</h5>
                            <h6>
                                <Interpolate
                                    i18nKey="make_candidates_understand_your_company_quickly____180_"
                                    useDangerouslySetInnerHTML={true}
                                    count={(form_data.slogan) ? form_data.slogan.length : 0}
                                />
                            </h6>
                        </label>
                        <div className="form-row form-row-dire-col">
                            <div className="form-col">
                        <Textarea
                            className={`textarea ${(error_messages.slogan && error_messages.slogan.length) ? 'input-danger' : ''}`}
                            minRows={2}
                            maxRows={3}
                            value={ (form_data.slogan) ? (form_data.slogan) : '' }
                            onChange={ e => handleInputChange(e, 'slogan') }
                        />
                            </div>
                            <div className="form-col">
                                {
                                    (error_messages.slogan && error_messages.slogan.length) ?
                                        <h6 className="message error-message">{error_messages.slogan.join(', ')}</h6> : ''
                                }
                            </div>
                        </div>

                        <label htmlFor="skills" className="lines">
                            <h5>{t('description')}</h5>
                            <h6>{t('introduce_your_products_or_corporate_culture')}</h6>
                        </label>
                        <div className="form-row form-row-dire-col">
                            <div className="form-col">
                                <Editor
                                    content={form_data.description}
                                    className={(error_messages.description && error_messages.description.length) ? 'input-danger' : ''}
                                    updateContent={ value => handleDescriptionChange(value) }
                                />
                            </div>
                            <div className="form-col">
                                {
                                    (error_messages.description && error_messages.description.length) ?
                                        <h6 className="message error-message">{error_messages.description.join(', ')}</h6> : ''
                                }
                            </div>
                        </div>
                    </div>

                    <h4 className="title-outside">{t('photos_and_videos')}</h4>
                    <div className="block">
                        <label htmlFor=""><h5>{t('photos')}</h5></label>
                        <div className="photos">
                            {
                                (form_data.photos && form_data.photos.length) ?
                                    form_data.photos.map((photo, i) => {
                                        if(!photo['_destroy']) {
                                            return (
                                                <div className="photo" key={i}>
                                                    <div
                                                        className='image-wrapper'
                                                        style={{backgroundImage: `url(${photo.image_preview_url})`}}
                                                    />
                                                    <div
                                                        className="btn-close"
                                                        onClick={e => deletePhoto(e, i)}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    }) : ''
                            }
                            <input
                                id="upload-photo"
                                type="file"
                                accept="image/png, image/jpeg, image/jpg, image/gif"
                                onChange={ e => addPhoto(e) }
                            />
                            <label htmlFor="upload-photo" className="btn-upload-photo">
                                <div className='image-wrapper'/>
                                <div className="btn btn-larger btn-flat"><img src={icon_upload} alt="" /><h5>{t('general:upload')}</h5></div>
                            </label>
                            {(error_messages.photos && error_messages.photos.length) ? <h6 className="message error-message">{error_messages.photos.join(', ')}</h6> : ''}
                        </div>

                        <label htmlFor=""><h5>{t('youtube_video_link')}</h5></label>
                        <div className="form-row form-row-dire-col youtube-link">
                            <div className="form-col">
                                <input
                                    className={`input ${(error_messages.video && error_messages.video.length) ? 'input-danger' : ''}`}
                                    type="text"
                                    value={ form_data.video }
                                    onChange={ e => handleInputChange(e, 'video') }
                                    placeholder={`${t('general:e_g__')} https://www.youtube.com/watch?v=T5uqpG4quZk`}
                                />
                            </div>
                            <div className="form-col">
                                {(error_messages.video && error_messages.video.length) ? <h6 className="message error-message">{error_messages.video.join(', ')}</h6> : ''}
                            </div>
                        </div>
                    </div>

                    <h4 className="title-outside">{t('employer_contact')}</h4>
                    <div className="block">
                        <div className="form-row form-row-2">
                            <div className="form-col">
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <label htmlFor=""><h5>{t('name')}</h5></label>
                                        <input
                                            className={`input ${(error_messages['contact.name'] && error_messages['contact.name'].length) ? 'input-danger' : ''}`}
                                            type="text"
                                            value={ form_data.contact_name }
                                            onChange={ e => handleInputChange(e, 'contact_name') }
                                            placeholder={t('full_name')}
                                        />
                                    </div>
                                    <div className="form-col">
                                        {(error_messages['contact.name'] && error_messages['contact.name'].length) ? <h6 className="message error-message">{error_messages['contact.name'].join(', ')}</h6> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="form-col">
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <label htmlFor=""><h5>{t('phone_number')}</h5></label>
                                        <input
                                            className={`input ${(error_messages['contact.mobile'] && error_messages['contact.mobile'].length) ? 'input-danger' : ''}`}
                                            type="text"
                                            value={ form_data.contact_phone_number }
                                            onChange={ e => handleInputChange(e, 'contact_phone_number') }
                                            placeholder={t('phone_number')}
                                        />
                                    </div>
                                    <div className="form-col">
                                        {(error_messages['contact.mobile'] && error_messages['contact.mobile'].length) ? <h6 className="message error-message">{error_messages['contact.mobile'].join(', ')}</h6> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CSSTransitionGroup
                    component="div"
                    transitionName="actions-bar-slide"
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={100}
                >
                    {
                        (has_pending_data) ?
                            <div className="actions-bar-wrapper">
                                <div className="actions-bar">
                                    <p>
                                        {
                                            (publishing) ?
                                                `${t('general:publishing')}...` :
                                                `${t('these_information_will_be_seen_on')} ${window.location.origin}${internal_uri.formatted_frontend_employer_page_path(employer.id, employer.slug)}.`
                                        }
                                    </p>
                                    <div className="buttons-wrapper">
                                        {
                                            (publishing) ?
                                                <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-fill disabled">
                                                    <h5>{t('general:discard')}</h5>
                                                </button> :
                                                <button
                                                    className="btn btn-larger btn-flat btn-fill"
                                                    onClick={ e => discard(e) }
                                                >
                                                    <h5>{t('general:discard')}</h5>
                                                </button>
                                        }
                                        {
                                            (publishing || has_errors) ?
                                                <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-hollow disabled">
                                                    <h5>{t('general:publish')}</h5>
                                                </button> :
                                                <button
                                                    className="btn btn-larger btn-flat btn-hollow"
                                                    onClick={ e => submitForm(e) }
                                                >
                                                    <h5>{t('general:publish')}</h5>
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div> : ''
                    }
                </CSSTransitionGroup>
            </div>
        );
    };
}

export default withNamespaces(['dashboard_employer_profile_page', 'general', 'states'])(CompanyProfilePage);
