import React from "react";
import {ReactComponent as Icon} from "../../../../../assets/images/icon/icon_24_invoice_white.svg";
import {withNamespaces} from "react-i18next";
import axios from "axios";
import ApiHeaders from "../../../../models/api_headers";
import Config from "../../../../models/config";

const config = new Config();
const headers = new ApiHeaders();

class InvoiceSummaryCard extends React.Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = {
            employerId: -1,
            invoice_count: {
                opening: null,
                total: null
            },
            loading: false,
            error_messages: {},
        };
    };

    componentDidMount() {
        this._isMounted = true;
        this.setState({ employerId: this.props.employerId }, () => this.fetchData());
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(this.props.employerId !== next_props.employerId) {
            this.setState({ employerId: next_props.employerId }, () => this.fetchData());
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    fetchData() {
        this.setState({ loading: true });

        const { employerId } = this.state;
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${employerId}/invoices`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);

                if(this._isMounted) {
                    this.setState({
                        invoice_count: {
                            opening: response.data.collection.filter(invoice => invoice.payment_status === 'unpaid').length,
                            total: response.data.paginator.total_entries
                        },
                        loading: false,
                        error_messages: {}
                    });
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));
                }
            });
    };

    render() {

        const { loading, invoice_count } = this.state;
        const { t } = this.props;

        if(loading) {
            return (
                <div className="loading-skeleton-wrapper">
                    <div className="col-md-4">
                        <div className="summary-card block">
                            <h4>{t('general:invoices')}</h4>
                            <Icon className="icon-bg" />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="col-md-4">
                <div className="summary-card block">
                    <h4>{t('general:invoices')}</h4>
                    <h3 className="content">{t('summaryCard.invoice.content', { number: invoice_count.opening })}</h3>
                    <p>{t('summaryCard.invoice.subContent', { number: invoice_count.total })}</p>
                    <Icon className="icon-bg" />
                </div>
            </div>
        );
    }
}

export default withNamespaces(['dashboard_index_page'])(InvoiceSummaryCard);