import {
	CookiePolicyCornerModalWrapper,
	AdCornerModalWrapper,
} from "../../../containers/frontend";
import React from "react";

const CornerModals = (props) => {
	return (
		<div className="corner-modals">
			<AdCornerModalWrapper />
			<CookiePolicyCornerModalWrapper validating={props.validating} />
		</div>
	);
};

export default CornerModals;
