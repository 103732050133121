import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HeaderWrapper, FooterWrapper } from './../../containers/general.jsx';
import {
    AccountSettingPageWrapper,
    MyApplicationsPageWrapper,
    SavedJobsPageWrapper,
    MyReferralsPageWrapper,
    CandidateMessageModalWrapper,
    ReferralMessageModalWrapper,
    AddReferenceNotesModalWrapper,
    ReadReferenceNotesModalWrapper,
    ClaimReferralRewardModalWrapper,
    MyReferralLinkModalWrapper,
    MyResumePageWrapper
} from './../../containers/userpanel';
import InternalUri from "../../models/internal_uri";
import TopBar from "./layouts/TopBar";

class Userpanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    UNSAFE_componentWillMount() {
        const internal_uri = new InternalUri();
        const { reduxTokenAuth } = this.props;
        if(!reduxTokenAuth.currentUser.isSignedIn) {
            this.props.history.push(`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${this.props.location.pathname}${this.props.location.search}`);
        }
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        const internal_uri = new InternalUri();
        const { reduxTokenAuth } = next_props;
        if(!reduxTokenAuth.currentUser.isSignedIn) {
            this.props.history.push(`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${this.props.location.pathname}${this.props.location.search}`);
        }
    };

    render() {

        const { match } = this.props;

        return(
            <div className="Userpanel">
                <HeaderWrapper />
                <TopBar />
                <div className="content-wrapper">
                    <Switch>
                        <Route exact path={`${match.url}/`} render={ () => <AccountSettingPageWrapper /> } />
                        <Route path={`${match.url}/my-applications`} render={ () => <MyApplicationsPageWrapper /> } />
                        <Route path={`${match.url}/saved-jobs`} render={ () => <SavedJobsPageWrapper /> } />
                        <Route path={`${match.url}/my-referrals`} render={ () => <MyReferralsPageWrapper /> } />
                        <Route path={`${match.url}/my-resume`} render={ () => <MyResumePageWrapper /> } />
                    </Switch>
                    <CandidateMessageModalWrapper />
                    <AddReferenceNotesModalWrapper />
                    <ReadReferenceNotesModalWrapper />
                    <ClaimReferralRewardModalWrapper />
                    <MyReferralLinkModalWrapper />
                    <ReferralMessageModalWrapper />
                </div>
                <FooterWrapper />
            </div>
        );
    };
}

export default Userpanel;