import React, { useEffect, useCallback } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const Lightbox = function({
	currentImage,
	images,
	isOpen,
	onClickImage,
	onClickNext,
	onClickPrev,
	onClickThumbnail,
	onClose,
	showThumbnails,
}) {
	const escFunction = useCallback((event) => {
		if (event.key === "Escape") {
			onClose();
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);

		return () => {
			document.removeEventListener("keydown", escFunction, false);
		};
	}, []);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.removeProperty("overflow");
		}
	}, [isOpen]);

	if (!isOpen) return "";

	return (
		<div id="EmployeeLightbox">
			<div className="content">
				<header>
					<button title="Close (Esc)" onClick={onClose}>
						<IoCloseSharp />
					</button>
				</header>
				<figure onClick={onClickImage}>
					<img
						src={images[currentImage].src}
						alt={images[currentImage].alt || ""}
					/>
				</figure>
				<div className="footer">
					<small>
						{currentImage + 1} of {images.length}{" "}
					</small>
				</div>
			</div>
			{images.length > 1 && showThumbnails ? (
				<div className="thumbnails">
					{images.map(({ src }, idx) => (
						<button
							title={src}
							onClick={() => {
								onClickThumbnail(idx);
							}}
							className={`${currentImage === idx ? "active" : ""}`}>
							<img src={src} alt="" />
						</button>
					))}
				</div>
			) : null}
			{images.length > 1 ? (
				<React.Fragment>
					{currentImage > 0 ? (
						<button
							className="lightbox-btn lightbox-btn-left"
							onClick={onClickPrev}>
							<BiChevronLeft />
						</button>
					) : null}
					{currentImage < images.length - 1 ? (
						<button
							className="lightbox-btn lightbox-btn-right"
							onClick={onClickNext}>
							<BiChevronRight />
						</button>
					) : null}
				</React.Fragment>
			) : null}
		</div>
	);
};

export default Lightbox;
