import React from 'react';
import { withNamespaces } from "react-i18next";
import { CSSTransitionGroup } from 'react-transition-group'

const WelcomeEmployerModal = props => {

    const { site_state, t } = props;

    const closeModal = e => {
        e.preventDefault();
        props.updateWelcomeEmployerModal(false, null, '', '', null, null);
    };

    return (
        <CSSTransitionGroup
            component="div"
            transitionName="modal-wrapper-with-modal-slide"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
        >
            {(site_state.welcome_employer_modal.open) ?
                <div className="modal-wrapper welcome-employer-modal-wrapper">
                    <div className="modal-inner-wrapper">
                        <div className="modal-bg" onClick={ e => closeModal(e) } />
                        <div className="modal welcome-employer-modal">
                            {(site_state.welcome_employer_modal.banner_img_url) ?
                                <div className="modal-banner">
                                    <div
                                        className="image-wrapper"
                                        style={{backgroundImage: `url(${site_state.welcome_employer_modal.banner_img_url})`}}
                                    />
                                </div> : ''
                            }
                            <div className="modal-content">
                                <h4 className="modal-title">{t(`${site_state.welcome_employer_modal.title}`)}</h4>
                                <p>{t(`${site_state.welcome_employer_modal.content}`)}</p>
                                {(site_state.welcome_employer_modal.and_content) ?
                                    <div>
                                        <div className="separator"><span>&</span></div>
                                        <p>{t(`${site_state.welcome_employer_modal.and_content}`)}</p>
                                    </div> : ''
                                }
                                {(site_state.welcome_employer_modal.plus_content) ?
                                    <div>
                                        <div className="separator"><span>{t('general:plus')}</span></div>
                                        <p dangerouslySetInnerHTML={{__html: t(`${site_state.welcome_employer_modal.plus_content}`) }} />
                                    </div> : ''
                                }
                                <button className="btn btn-larger btn-flat btn-fill" onClick={ e => closeModal(e) }>{t('general:ok')}</button>
                            </div>
                        </div>
                    </div>
                </div> : ''
            }
        </CSSTransitionGroup>
    );
};

export default withNamespaces(['dashboard_modals', 'general'])(WelcomeEmployerModal);
