export default {

    // apply modal
    name: "姓名",
    first_name: "名字",
    last_name: "姓氏",
    email: "Email",
    phone_number: "聯絡電話",
    your_phone_number: "你的聯絡電話",
    https___linkedin_com_in_your_name: "https://linkedin.com/in/your-name",
    cover_letter: "求職訊息",
    write_cover_letter_here_: "寫下想對徵才企業說的話",
    referral: "推薦人",
    i_want_to_apply_without_a_referee_: "不設定推薦人，直接應徵。",
    or_enter_a_referral_code: "或輸入推薦碼",
    sync_to_my_profile: "將這些資訊儲存起來",
    you_can_t_use_your_referral_code_: "你不能使用你自己的推薦碼。",
    applySuccessfullyTitle: "應徵已成功送出！",
    applySuccessfullyDescription: "您可以在 {{my_applications}} 查看您的應徵狀態。",
    applySuccessfullyOnboardRewardReminding: "若您順利就職這份工作，別忘了到 Meet.jobs 回報就職，我們將提供 <span class='reward'>100 USD</span> 的就職祝賀金。",
    no_resume___cv_file_is_attached_do_you_want_to_apply_without_an_attachment_: "你並未上傳任何個人履歷檔案，確認要不附加履歷檔案直接應徵？",

    // invite to apply
    referrer_info: "推薦人資訊",
    talent_info: "人才資訊",
    ref_: "推薦訊息",
    choose_from_previous_referral: "從之前的推薦中選擇",
    talent_s_first_name: "人才的名字",
    talent_s_last_name: "人才的姓氏",
    talent_s_email: "人才的 email",
    talent_s_phone_number: "人才的聯絡電話",
    recommendation_for_talent: "給人才的推薦訊息",
    tell_the_talent_why_this_job_is_perfect_for_him_her_: "告訴你的朋友這個工作機會為什麼適合他。",
    i_found_this_job_that_is_perfect_for_you_accept_my_referral_and_apply_for_it_on_meet_jobs_: "我認為這個職位很適合你，所以在 Meet.jobs 幫你應徵了這個職位。如果有問題的話，都歡迎跟我討論喔！",
    tell_a_little_bit_more_to_the_employer_why_you_recommend_this_talent_: "告訴這家企業為什麼你推薦你的朋友。",
    invite: "邀請",
    submit: "送出",
    be_sure_everything_s_correct: "請確認填寫的內容",
    you_cannot_modify_the_reference_letter_afterwards_are_you_sure_to_send_it_right_away_: "推薦內容送出後將不能夠再次修改，您確定要送出嗎？",

    // cookie policy
    we_use_cookies_to_give_you_the_best_experience__we_suggest_you_accept_to_our___: "我們使用 Cookie 來增進您在此使用的經驗，請先瀏覽我們的 {{cookie_policy}}。",
    cookie_policy: "Cookie 政策",
    got_it: "我知道了",
    // 聲明
    disclaimer: "聲明",
    disclaimer_content: `
    <p>請注意！</p>
    <p>您選擇幫您朋友代為投遞履歷，即代表您已獲得該位朋友的授權，可以在 Meet.jobs 網站上使用其個人資料。</p>
    <p>如您並未獲得授權，請不要使用此功能。</p>
    <p>如因此違反您所在國家關於隱私權、個人資料等相關法規，造成您或該位朋友的任何損失，Meet.jobs 概不負一切責任。</p>
    `,
    proceed: "我知道了",
};
