import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
	Element as ScrollElement,
	Link as ScrollLink,
} from "react-scroll/modules";
import Scroll from "react-scroll";
import axios from "axios";
import { JobInfoWrapper } from "../../../containers/frontend";
import Job from "../../../models/job.js";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers.js";
import InternalUri from "../../../models/internal_uri";
import {
	contain_not_only_whitespace,
	get_value_from_query_string,
	page_smothly_scroll_to,
} from "../../../../assets/js/functions";
import { withNamespaces, Interpolate } from "react-i18next";
import { Helmet } from "react-helmet";
import Employer from "../../../models/employer";
import { CSSTransitionGroup } from "react-transition-group";
import modal_pop_up_warning from "../../../../assets/images/i050-pop-ups-warning.svg";
import { ReactComponent as SpeechBubble } from "../../../../assets/images/speech_bubble.svg";
import User from "../../../models/user";
import i18n from "../../../../i18n";
import Textarea from "react-textarea-autosize";
import onboardRewardGif from "../../../../assets/images/onboard_reward.gif";
import { set } from "react-ga";

const config = new Config();
const headers = new ApiHeaders();
const JobInvitationPagef = (props) => {
	let preProps = props;
	const handleScroll = () => {
		// TODO: 其實可以去掉 scrollTop，也可以拉出成 function 共用
		const scrollTop =
			window.pageYOffset ||
			document.body.scrollTop ||
			document.documentElement.scrollTop;
		const screenHeight =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;
		const scrollBottom = scrollTop + screenHeight;
		const element = document.querySelector(
			".call-to-action-invitation-to-apply-wrapper",
		);
		if (element) {
			const benchmark = element.getBoundingClientRect().top + scrollTop;
			if (scrollBottom <= benchmark + 100) {
				if (!main.show_mobile_call_to_actions) {
					setMain({
						...main,
						show_mobile_call_to_actions: true,
					});
				}
			} else {
				if (main.show_mobile_call_to_actions) {
					setMain({
						...main,
						show_mobile_call_to_actions: false,
					});
				}
			}
		}
	};
	const fetchData = () => {
		const { active_job_id, active_invitation_id, token } = main;
		let job = {},
			employer = {},
			invitation = {},
			referrer = {},
			talent = {};
		let options = {
			method: "GET",
			url: config.api_url(`/jobs/${active_job_id}?include=required_skills`),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				props.updateConnectionOffModal(false);

				if (isMounted.current) {
					const raw_job = response.data;
					job = new Job(raw_job);

					if (job.has_employer()) {
						options = {
							method: "GET",
							url: config.api_url(
								`/employers/${job.employer.id}?include=published_jobs`,
							),
							headers: headers.getItemsFromLocalStorage(),
							json: true,
						};

						return axios(options);
					} else {
						// is external job
						setMain({
							...main,
							job,
							loading: false,
							error_messages: {},
						});

						// return new Promise(() => {});
					}
				}
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				if (isMounted.current) {
					const raw_employer = response.data;
					employer = new Employer(raw_employer);

					options = {
						method: "GET",
						url: config.api_url(
							`/jobs/${active_job_id}/talent_invitations/${active_invitation_id}?token=${token}`,
						),
						headers: headers.getItemsFromLocalStorage(),
						json: true,
					};

					return axios(options);
				}
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				if (isMounted.current) {
					invitation = response.data;

					referrer = new User({
						id: invitation.referrer.id,
						avatar: {
							...invitation.referrer.avatar,
						},
						first_name: invitation.referrer_first_name,
						last_name: invitation.referrer_last_name,
					});

					talent = new User({
						first_name: invitation.talent_first_name,
						last_name: invitation.talent_last_name,
						email: invitation.talent_email,
						phone: invitation.talent_phone,
						applied_profile: {
							portfolio_url: invitation.talent_portfolio_url,
						},
						attachment: invitation.attachment
							? {
									...invitation.attachment,
							  }
							: null,
					});

					const { reduxTokenAuth } = props;
					setMain({
						...main,
						job,
						invitation,
						invitation_accepted:
							reduxTokenAuth.currentUser.isSignedIn &&
							invitation.state === "accepted",
						form_data: {
							attend_event: true,
							first_name: talent.formatted_form_first_name(),
							last_name: talent.formatted_form_last_name(),
							email: talent.formatted_email(),
							phone: talent.formatted_form_phone(),
							resume: {
								file_url_from_referrer: talent.formatted_attachment_asset_url(),
								file_name_from_referrer: talent.formatted_attachment_asset_filename(),
							},
							portfolio_url: talent.formatted_portfolio_url(),
							cover_letter: "",
						},
						referrer,
						employer,
						loading: false,
						error_messages: {},
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					const internal_uri = new InternalUri();
					const { t } = props;
					const { reduxTokenAuth } = props;
					if (error.response.status === 403) {
						if (reduxTokenAuth.currentUser.isSignedIn) {
							props.history.push(
								internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
							);
							props.addFlashMessage(
								true,
								t(
									"flash_messages:you_need_permission_to_browse_the_page_please_ask_for_access_or_switch_to_an_account_with_permission_",
								),
								"danger",
								"with-dismiss",
								"",
							);
						} else {
							const { location } = props;
							props.history.push(
								`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${encodeURIComponent(
									`${location.pathname}${location.search}`,
								)}`,
							);
						}
					}
					if (error.response.status === 422 || error.response.status === 404) {
						if (error.response.data && error.response.data.data) {
							if (error.response.data.data.state === "rejected") {
								props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								props.addFlashMessage(
									true,
									t("flash_messages:you_have_rejected_this_invitation_on__", {
										updated_at: error.response.data.data.updated_at,
									}),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (error.response.data.data.state === "applied") {
								props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								props.addFlashMessage(
									true,
									t(
										"flash_messages:you_have_accepted_this_invitation_and_applied_on__",
										{
											updated_at: error.response.data.data.updated_at,
										},
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (
								error.response.data.data.state === "expired" &&
								job.is_closed()
							) {
								props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								props.addFlashMessage(
									true,
									t(
										"flash_messages:this_invitation_is_not_available_because_the_job_is_closed_",
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (error.response.data.data.state === "expired") {
								props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								props.addFlashMessage(
									true,
									t(
										"flash_messages:your_link_has_expired_please_send_the_request_again_",
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
						} else {
							props.history.push(
								internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
							);
							props.addFlashMessage(
								true,
								t(
									"flash_messages:your_link_has_expired_please_send_the_request_again_",
								),
								"danger",
								"with-dismiss",
								"",
							);
						}
					}
				}

				if (isMounted.current) {
					if (typeof error.response === "undefined")
						props.updateConnectionOffModal(true);

					setMain({
						...main,
						loading: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					});
				}

				page_smothly_scroll_to(0, 300);
			});
	};
	const handleToggleChange = (e, attr) => {
		e.preventDefault();
		setMain({
			...main,
			form_data: {
				...main.form_data,
				[attr]: !main.form_data[attr],
			},
		});
	};
	const handleInputChange = (e) => {
		e.preventDefault();
		let value = e.target.value;
		const dataset = e.target.dataset;
		const property_name = e.target.id;

		// if(property_name==='email') value = value.toLowerCase();
		let error_messages = { ...main.error_messages };
		const { t } = props;
		if (dataset.val) {
			let errors = [];
			if (dataset.valRequired) {
				if (value.length <= 0) errors.push(`${t("general:required")}`);
			}

			error_messages = {
				...error_messages,
				[property_name]: errors,
			};
		}
		setMain({
			...main,
			form_data: {
				...main.form_data,
				[property_name]: value,
			},
			error_messages,
		});
	};
	const handleFileChange = (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		const property_name = e.target.id;

		if (file) {
			setMain({
				...main,
				form_data: {
					...main.form_data,
					[property_name]: {
						...main.form_data[property_name],
						file,
					},
				},
			});
		}
	};
	const openLogInModal = (e) => {
		e.preventDefault();
		props.updateEntranceFormModal("log-in");
	};
	const updateReferenceLetterModal = (e) => {
		e.preventDefault();
		setMain({
			...main,
			reference_letter_modal: {
				...main.reference_letter_modal,
				open: !main.reference_letter_modal.open,
			},
		});
	};

	const submitChangingInvitationState = (e, next_state) => {
		e.preventDefault();
		setMain({ ...main, changing_state: true });
		const { active_job_id, job, active_invitation_id, token } = main;
		let options = {
			method: "PATCH",
			url: config.api_url(
				`/jobs/${active_job_id}/talent_invitations/${active_invitation_id}?token=${token}`,
			),
			headers: headers.getItemsFromLocalStorage(),
			data: {
				state: next_state,
			},
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				props.updateConnectionOffModal(false);

				if (isMounted.current) {
					if (next_state === "reject") {
						const internal_uri = new InternalUri();
						props.history.push(
							internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
						);
					}
					if (next_state === "accept") {
						setMain({
							...main,
							invitation_accepted: true,
							changing_state: false,
						});
					}
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					const internal_uri = new InternalUri();
					const { t } = props;
					if (error.response.status === 401) {
						const { location } = props;
						props.history.push(
							`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${encodeURIComponent(
								`${location.pathname}${location.search}`,
							)}`,
						);
					}
					if (error.response.status === 403) {
						props.history.push(
							internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
						);
						props.addFlashMessage(
							true,
							t(
								"flash_messages:you_need_permission_to_browse_the_page_please_ask_for_access_or_switch_to_an_account_with_permission_",
							),
							"danger",
							"with-dismiss",
							"",
						);
					}
					if (error.response.status === 422 || error.response.status === 404) {
						if (error.response.data && error.response.data.data) {
							if (error.response.data.data.state === "rejected") {
								props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								props.addFlashMessage(
									true,
									t("flash_messages:you_have_rejected_this_invitation_on__", {
										updated_at: error.response.data.data.updated_at,
									}),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (error.response.data.data.state === "applied") {
								props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								props.addFlashMessage(
									true,
									t(
										"flash_messages:you_have_accepted_this_invitation_and_applied_on__",
										{ updated_at: error.response.data.data.updated_at },
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (
								error.response.data.data.state === "expired" &&
								job.is_closed()
							) {
								props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								props.addFlashMessage(
									true,
									t(
										"flash_messages:this_invitation_is_not_available_because_the_job_is_closed_",
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (error.response.data.data.state === "expired") {
								props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								props.addFlashMessage(
									true,
									t(
										"flash_messages:your_link_has_expired_please_send_the_request_again_",
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
						} else {
							// this.props.history.push(internal_uri.formatted_frontend_job_page_path(job.id, job.slug));
							// this.props.addFlashMessage(
							//     true,
							//     t('flash_messages:your_link_has_expired_please_send_the_request_again_'),
							//     'danger',
							//     'with-dismiss',
							//     ''
							// );
						}
					}
				}

				if (isMounted.current) {
					if (typeof error.response === "undefined")
						props.updateConnectionOffModal(true);
					setMain({
						...main,
						loading: false,
						changing_state: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					});
				}

				Scroll.scroller.scrollTo("invitation_to_apply", {
					spy: true,
					smooth: true,
					offset: -80,
					duration: 400,
				});
			});
	};
	const closeApplyingSuccessModal = (e) => {
		e.preventDefault();
		setMain({
			...main,
			applying_success_modal: {
				open: false,
			},
		});
	};

	const closeEmptyFileWarningModal = (e) => {
		e.preventDefault();
		setMain({
			...main,
			empty_file_warning_modal: {
				open: false,
			},
		});
	};

	const submitApplication = (e) => {
		e.preventDefault();
		const { form_data } = main;

		let has_errors = false;
		let error_messages = { ...main.error_messages };
		const { t } = props;
		let form = document.querySelector("form");
		if (form) {
			const inputs = form.querySelectorAll("input");
			for (let i = 0; i < inputs.length; i++) {
				const input = inputs[i];
				let errors = [];

				if (input.dataset.val) {
					if (input.dataset.valRequired) {
						if (input.value.length <= 0) {
							errors.push(`${t("general:required")}`);
							has_errors = true;
						}
					}
				}

				error_messages = {
					...error_messages,
					[input.id]: errors,
				};
			}
		}

		if (has_errors) {
			setMain({ ...main, error_messages });
			page_smothly_scroll_to(0, 300);
		} else if (
			typeof form_data.resume.file === "undefined" &&
			!form_data.resume.file_url_from_referrer
		) {
			setMain({
				...main,
				empty_file_warning_modal: {
					open: true,
				},
			});
		} else {
			continueSubmitApplication(e);
		}
	};
	const continueSubmitApplication = (e) => {
		e.preventDefault();
		setMain({ ...main, applying: true });
		const { active_job_id, form_data, active_invitation_id, job } = main;
		const { reduxTokenAuth } = props;

		let formData = new FormData();
		formData.append("talent_invitation_id", active_invitation_id);

		formData.append("job_application[job_id]", active_job_id);
		formData.append(
			"job_application[applier_name]",
			`${form_data.last_name}, ${form_data.first_name}`,
		);
		formData.append("job_application[applier_email]", form_data.email);
		formData.append("job_application[applier_phone]", form_data.phone);
		formData.append("job_application[cover_letter]", form_data.cover_letter);
		formData.append("job_application[portfolio_url]", form_data.portfolio_url);
		// TODO: 檢查上傳 file 的大小？
		if (typeof form_data.resume.file !== "undefined")
			formData.append("resume_attachment[asset]", form_data.resume.file);

		if (form_data.attend_event && job.has_event())
			formData.append("job_application[event_id]", job.events[0].id);

		let options = {
			method: "POST",
			url: config.api_url(
				`/users/${reduxTokenAuth.currentUser.attributes.id}/job_applications`,
			),
			headers: headers.getItemsFromLocalStorage("multipart/form-data"),
			data: formData,
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				props.updateConnectionOffModal(false);

				if (isMounted.current) {
					setMain({
						...main,
						loading: false,
						applying: false,
						applying_success_modal: {
							open: true,
						},
						empty_file_warning_modal: {
							open: false,
						},
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					const internal_uri = new InternalUri();
					const { job } = main;
					const { t } = props;
					if (error.response.status === 401) window.location.reload();
					if (error.response.status === 404) {
						props.history.push(
							internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
						);
						props.addFlashMessage(
							true,
							t(
								"flash_messages:your_link_has_expired_please_send_the_request_again_",
							),
							"danger",
							"with-dismiss",
							"",
						);
					}
				}

				if (isMounted.current) {
					if (typeof error.response === "undefined")
						props.updateConnectionOffModal(true);
					setMain({
						...main,
						loading: false,
						applying: false,
						empty_file_warning_modal: {
							open: false,
						},
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					});
				}

				page_smothly_scroll_to(0, 300);
			});
	};
	const isMounted = useRef(false);
	const [main, setMain] = useState({
		active_job_id: -1,
		job: {},
		active_invitation_id: -1,
		invitation: {},
		invitation_accepted: false,
		form_data: {},
		token: "",
		employer: {},
		reference_letter_modal: {
			open: false,
		},
		applying_success_modal: {
			open: false,
		},
		empty_file_warning_modal: {
			open: false,
		},
		show_mobile_call_to_actions: true,
		loading: true,
		changing_state: false,
		applying: false,
		error_messages: {},
	});
	useEffect(() => {
		if (isMounted.current) {
		} else {
			isMounted.current = true;
			window.addEventListener("scroll", handleScroll);
			let token = "",
				job_id = "";
			const query = decodeURI(props.location.search);
			if (query) {
				token = get_value_from_query_string("token", query);
				job_id = get_value_from_query_string("job_id", query);

				const invitation_id = props.match.params.id_slug;

				setMain({
					...main,
					active_job_id: job_id,
					active_invitation_id: invitation_id,
					token,
				});
			}
		}
		// componentWillUnmount
		return () => {
			isMounted.current = false;
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	// componentDidMount and setState's callback
	useEffect(() => {
		if (isMounted.current) {
			fetchData();
			const internal_uri = new InternalUri();
			props.history.push(internal_uri.formatted_frontend_jobs_page_path());

			const { location } = props;
			if (
				main.invitation.state === "pending" &&
				location.search.search("talent_original_email") === -1
			) {
				props.history.replace(
					`${location.pathname}${location.search}&talent_original_email=${main.invitation.talent_email}&referrer_id=${main.referrer.id}`,
				);
			}
			Scroll.scroller.scrollTo("invitation_to_apply", {
				spy: true,
				smooth: true,
				offset: -80,
				duration: 400,
			});

			const { job } = main;

			props.history.push(
				internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
			);
		}
	}, [main]);
	// UNSAFE_componentWillReceiveProps
	useEffect(() => {
		const { reduxTokenAuth } = props;
		if (
			reduxTokenAuth.currentUser.isSignedIn !==
			preProps.reduxTokenAuth.currentUser.isSignedIn
		) {
			setMain({ ...main, loading: true });
			fetchData();
		}
	}, [props]);

	const {
		job,
		employer,
		invitation,
		invitation_accepted,
		form_data,
		referrer,
		applying_success_modal,
		empty_file_warning_modal,
		reference_letter_modal,
		loading,
		applying,
		changing_state,
		error_messages,
		show_mobile_call_to_actions,
	} = main;
	const { reduxTokenAuth, t } = props;
	const internal_uri = new InternalUri();
	if (loading || !Object.keys(job).length) {
		return (
			<div className="loading-skeleton-wrapper">
				<div className="page-job-invitation content-wrapper with-gradient reward75-to-belize">
					<div className="container">
						<div className="row">
							<div className="col-md-8">
								<div className="tablet-wrapper">
									<JobInfoWrapper loading={true} />
								</div>
							</div>

							<div className="col-md-4">
								<div className="tablet-wrapper">
									<div className="call-to-action-invitation-to-apply-wrapper block">
										<h4>{t("general:invitation_to_apply")}</h4>
										<div className="buttons-wrapper">
											<button className="btn btn-larger btn-flat btn-fill">
												<h5>{`${t("general:loading")}...`}</h5>
											</button>
											<button className="btn btn-larger">
												<h5>{`${t("general:loading")}...`}</h5>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	let has_errors = false;
	const black_list = ["last_name", "first_name", "email", "phone"];
	for (let key in error_messages) {
		if (black_list.indexOf(key) !== -1 && error_messages[key].length)
			has_errors = true;
	}

	return (
		<div className="page-job-invitation content-wrapper with-gradient reward75-to-belize">
			<Helmet>
				<title>
					{job.formatted_title()} - {job.formatted_employer_name()}｜Meet.jobs
				</title>
			</Helmet>
			<div className="container">
				<div className="row">
					<div className="col-md-8">
						<div className="tablet-wrapper">
							<JobInfoWrapper
								job={{ ...job }}
								employer={{ ...employer }}
								loading={loading}
								show_link_for_job_page
								eventCategories={{
									headEmployer: "382_receiver_link_employer",
									headLocation: "383_receiver_link_location",
									requiredSkill: "384_receiver_link_skill_tag",
									employerInfo: "385_receiver_employer_info",
								}}
							/>
						</div>
					</div>
					<div className="col-md-4">
						<div className="tablet-wrapper">
							<ScrollElement name="invitation_to_apply">
								{!invitation_accepted ? (
									<div className="call-to-action-invitation-to-apply-wrapper block">
										<h4>{t("general:invitation_to_apply")}</h4>
										{error_messages.full_message ? (
											<h6 className="message error-message">
												{error_messages.full_message}
											</h6>
										) : (
											""
										)}
										{/*{(error_messages.talent_email && error_messages.talent_email.length) ? <h6 className="message error-message">{error_messages.talent_email.join(', ')}</h6> : ''}*/}
										<div className="dialog-wrapper">
											<div className="dialog green-reward from-editor">
												<p>
													{contain_not_only_whitespace(
														invitation.comment_to_talent,
													)
														? invitation.comment_to_talent
														: t(
																"i_found_this_job_that_is_perfect_for_you_accept_my_referral_and_apply_for_it_on_meet_jobs_",
														  )}
												</p>
												<SpeechBubble className="speech-bubble" />
											</div>
											<div className="speaker-info">
												<div className="avatar">
													<div
														className="image-wrapper"
														style={{
															backgroundImage: `url(${referrer.formatted_avatar_url()})`,
														}}
													/>
												</div>
												<h6>{referrer.formatted_name()}</h6>
											</div>
										</div>

										{reduxTokenAuth.currentUser.isSignedIn ? (
											changing_state ? (
												<div className="buttons-wrapper">
													<button
														onClick={(e) => e.preventDefault()}
														className="btn btn-larger btn-flat btn-ghost disabled">
														<h5>{t("not_interested")}</h5>
													</button>
													<button
														onClick={(e) => e.preventDefault()}
														className="btn btn-larger btn-flat btn-fill disabled">
														<h5>{t("accept_referral")}</h5>
													</button>
												</div>
											) : (
												<div className="buttons-wrapper">
													<button
														className="btn btn-larger btn-flat btn-ghost"
														onClick={(e) =>
															submitChangingInvitationState(e, "reject")
														}>
														<h5>{t("not_interested")}</h5>
													</button>
													<button
														className="btn btn-larger btn-flat btn-fill"
														onClick={(e) =>
															submitChangingInvitationState(e, "accept")
														}>
														<h5>{t("accept_referral")}</h5>
													</button>
												</div>
											)
										) : (
											<div className="buttons-wrapper">
												<button
													className="btn btn-larger btn-flat btn-ghost"
													onClick={(e) => openLogInModal(e)}>
													<h5>{t("not_interested")}</h5>
												</button>
												<button
													className="btn btn-larger btn-flat btn-fill"
													onClick={(e) => openLogInModal(e)}>
													<h5>{t("accept_referral")}</h5>
												</button>
											</div>
										)}
									</div>
								) : (
									<div className="call-to-action-invitation-to-apply-wrapper block">
										<h4>{t("general:invitation_to_apply")}</h4>
										{contain_not_only_whitespace(invitation.referrer_note) ? (
											<div className="referrer-wrapper">
												<div className="referrer-inner-wrapper">
													<div className="referrer">
														<div className="avatar">
															<div
																className="image-wrapper"
																style={{
																	backgroundImage: `url(${referrer.formatted_avatar_url()})`,
																}}
															/>
														</div>
														<h6>{referrer.formatted_name()}</h6>
													</div>
													<button
														className="btn btn-smaller btn-flat btn-hollow"
														onClick={(e) => updateReferenceLetterModal(e)}
														data-event-category="373_receiver_read_letter">
														<h5>{t("read_reference_letter")}</h5>
													</button>
												</div>
											</div>
										) : (
											""
										)}

										<form>
											{job.has_event() ? (
												<div
													className="event-switcher-wrapper"
													onClick={(e) =>
														handleToggleChange(e, "attend_event")
													}>
													<div
														className="banner"
														style={{
															backgroundImage: `url(${job.events[0].get_banner_url()})`,
														}}
														data-event-category="316_modal_apply_banner"
													/>
													<div
														className="event-switcher"
														data-event-category="317_modal_apply_switch">
														<input
															className="radio-input"
															type="radio"
															checked={form_data.attend_event}
															readOnly
														/>
														<label
															htmlFor="remote"
															className="radio-switcher"
														/>
														<div className="text">
															<p>
																{form_data.attend_event
																	? t("events:yes__i_m_here_for__", {
																			event_name: t(
																				job.events[0].get_i18n_key(),
																			),
																	  })
																	: t(
																			"events:no__i_don_t_want_to_attend_this_event_",
																	  )}
															</p>
														</div>
													</div>
												</div>
											) : (
												""
											)}
											<label htmlFor="first_name">
												<h5>{t("name")}</h5>
											</label>
											{i18n.language === "zh-TW" ? (
												<div className="form-row form-row-2">
													<div className="form-col">
														<div className="form-row form-row-dire-col">
															<div className="form-col">
																<input
																	className={`input ${
																		error_messages.last_name &&
																		error_messages.last_name.length
																			? "input-danger"
																			: ""
																	}`}
																	type="text"
																	value={form_data.last_name}
																	placeholder={t("last_name")}
																	onChange={(e) => handleInputChange(e)}
																	id="last_name"
																	data-val="true"
																	data-val-required="true"
																/>
															</div>
															<div className="form-col">
																{error_messages.last_name &&
																error_messages.last_name.length ? (
																	<h6 className="message error-message">
																		{error_messages.last_name.join(", ")}
																	</h6>
																) : (
																	""
																)}
															</div>
														</div>
													</div>
													<div className="form-col">
														<div className="form-row form-row-dire-col">
															<div className="form-col">
																<input
																	className={`input ${
																		error_messages.first_name &&
																		error_messages.first_name.length
																			? "input-danger"
																			: ""
																	}`}
																	type="text"
																	value={form_data.first_name}
																	placeholder={t("first_name")}
																	onChange={(e) => handleInputChange(e)}
																	id="first_name"
																	data-val="true"
																	data-val-required="true"
																/>
															</div>
															<div className="form-col">
																{error_messages.first_name &&
																error_messages.first_name.length ? (
																	<h6 className="message error-message">
																		{error_messages.first_name.join(", ")}
																	</h6>
																) : (
																	""
																)}
															</div>
														</div>
													</div>
												</div>
											) : (
												<div className="form-row form-row-2">
													<div className="form-col">
														<div className="form-row form-row-dire-col">
															<div className="form-col">
																<input
																	className={`input ${
																		error_messages.first_name &&
																		error_messages.first_name.length
																			? "input-danger"
																			: ""
																	}`}
																	type="text"
																	value={form_data.first_name}
																	placeholder={t("first_name")}
																	onChange={(e) => handleInputChange(e)}
																	id="first_name"
																	data-val="true"
																	data-val-required="true"
																/>
															</div>
															<div className="form-col">
																{error_messages.first_name &&
																error_messages.first_name.length ? (
																	<h6 className="message error-message">
																		{error_messages.first_name.join(", ")}
																	</h6>
																) : (
																	""
																)}
															</div>
														</div>
													</div>
													<div className="form-col">
														<div className="form-row form-row-dire-col">
															<div className="form-col">
																<input
																	className={`input ${
																		error_messages.last_name &&
																		error_messages.last_name.length
																			? "input-danger"
																			: ""
																	}`}
																	type="text"
																	value={form_data.last_name}
																	placeholder={t("last_name")}
																	onChange={(e) => handleInputChange(e)}
																	id="last_name"
																	data-val="true"
																	data-val-required="true"
																/>
															</div>
															<div className="form-col">
																{error_messages.last_name &&
																error_messages.last_name.length ? (
																	<h6 className="message error-message">
																		{error_messages.last_name.join(", ")}
																	</h6>
																) : (
																	""
																)}
															</div>
														</div>
													</div>
												</div>
											)}

											<label htmlFor="email">
												<h5>{t("email")}</h5>
											</label>
											<div className="form-row form-row-dire-col">
												<div className="form-col">
													<input
														className={`input ${
															error_messages.email &&
															error_messages.email.length
																? "input-danger"
																: ""
														}`}
														type="email"
														value={form_data.email}
														placeholder={t("email")}
														onChange={(e) => handleInputChange(e)}
														id="email"
														data-val="true"
														data-val-required="true"
													/>
												</div>
												<div className="form-col">
													{error_messages.email &&
													error_messages.email.length ? (
														<h6 className="message error-message">
															{error_messages.email.join(", ")}
														</h6>
													) : (
														""
													)}
												</div>
											</div>

											<label htmlFor="phone">
												<h5>{t("phone_number")}</h5>
											</label>
											<div className="form-row form-row-dire-col">
												<div className="form-col">
													<input
														className={`input ${
															error_messages.phone &&
															error_messages.phone.length
																? "input-danger"
																: ""
														}`}
														type="text"
														value={form_data.phone}
														placeholder={t("your_phone_number")}
														onChange={(e) => handleInputChange(e)}
														id="phone"
														data-val="true"
														data-val-required="true"
													/>
												</div>
												<div className="form-col">
													{error_messages.phone &&
													error_messages.phone.length ? (
														<h6 className="message error-message">
															{error_messages.phone.join(", ")}
														</h6>
													) : (
														""
													)}
												</div>
											</div>

											<label htmlFor="resume" className="lines">
												<h5>{t("general:resume___cv_file")}</h5>
												{typeof form_data.resume.file !== "undefined" ? (
													<h6>{form_data.resume.file.name}</h6>
												) : form_data.resume.file_url_from_referrer ? (
													<h6>
														<a
															className="link"
															href={form_data.resume.file_url_from_referrer}
															target="_blank"
															rel="noopener noreferrer"
															data-event-category="374_receiver_cv">
															<h5>
																{form_data.resume.file_name_from_referrer}
															</h5>
														</a>
													</h6>
												) : (
													<h6>{t("general:no_resume___cv_file")}</h6>
												)}
											</label>
											<div className="form-row">
												<input
													className="input-upload-file"
													id="resume"
													type="file"
													accept={
														`.pdf, ` +
														`.doc, ` +
														`.docx, ` +
														`application/pdf, ` +
														`application/msword, ` +
														`application/vnd.openxmlformats-officedocument.wordprocessingml.document`
													}
													onChange={(e) => handleFileChange(e)}
												/>
												<label
													htmlFor="resume"
													className="btn btn-larger btn-flat btn-hollow btn-upload-file">
													{t("general:choose_file")}
												</label>
											</div>

											<label htmlFor="portfolio_url">
												<h5>{t("general:reference_link")}</h5>
											</label>
											<div className="form-row form-row-dire-col">
												<div className="form-col">
													<input
														className={`input ${
															error_messages.portfolio_url &&
															error_messages.portfolio_url.length
																? "input-danger"
																: ""
														}`}
														type="text"
														value={form_data.portfolio_url}
														placeholder={t("https___linkedin_com_in_your_name")}
														onChange={(e) => handleInputChange(e)}
														id="portfolio_url"
														data-val="true"
													/>
												</div>
												<div className="form-col">
													{error_messages.portfolio_url &&
													error_messages.portfolio_url.length ? (
														<h6 className="message error-message">
															{error_messages.portfolio_url.join(", ")}
														</h6>
													) : (
														""
													)}
												</div>
											</div>

											<label htmlFor="cover_letter">
												<h5>{t("cover_letter")}</h5>
											</label>
											<div className="form-row">
												<Textarea
													className="textarea"
													minRows={5}
													maxRows={10}
													placeholder={t("write_cover_letter_here_")}
													value={form_data.cover_letter}
													onChange={(e) => handleInputChange(e)}
													id="cover_letter"
												/>
											</div>

											<div className="buttons-wrapper">
												{changing_state || applying ? (
													<button
														onClick={(e) => e.preventDefault()}
														className="btn btn-larger btn-flat btn-ghost disabled">
														<h5>{t("general:decline")}</h5>
													</button>
												) : (
													<button
														className="btn btn-larger btn-flat btn-ghost"
														onClick={(e) =>
															submitChangingInvitationState(e, "reject")
														}
														data-event-category="375_receiver_decline">
														<h5>{t("general:decline")}</h5>
													</button>
												)}
												{has_errors || changing_state || applying ? (
													<button
														onClick={(e) => e.preventDefault()}
														className="btn btn-larger btn-flat btn-fill disabled">
														<h5>{t("general:apply")}</h5>
													</button>
												) : (
													<button
														className="btn btn-larger btn-flat btn-fill"
														onClick={(e) => submitApplication(e)}
														data-event-category="376_receiver_apply">
														<h5>{t("general:apply")}</h5>
													</button>
												)}
											</div>
										</form>
									</div>
								)}
							</ScrollElement>
						</div>
					</div>
				</div>
			</div>

			<CSSTransitionGroup
				component="div"
				transitionName="fade-in-out"
				transitionEnterTimeout={300}
				transitionLeaveTimeout={300}>
				{!invitation_accepted && show_mobile_call_to_actions ? (
					<div className="mobile-call-to-action-wrapper">
						<div className="container">
							<div className="mobile-call-to-action">
								<div className="referrer-wrapper">
									<ScrollLink
										to="invitation_to_apply"
										spy={true}
										smooth={true}
										offset={-80}
										duration={400}>
										<div className="referrer">
											<div className="avatar">
												<div
													className="image-wrapper"
													style={{
														backgroundImage: `url(${referrer.formatted_avatar_url()})`,
													}}
												/>
											</div>
											<h6>{referrer.formatted_name()}</h6>
										</div>
									</ScrollLink>
								</div>
								{reduxTokenAuth.currentUser.isSignedIn ? (
									<button
										className="btn btn-larger btn-flat btn-ghost"
										onClick={(e) => submitChangingInvitationState(e, "reject")}
										data-event-category="371_receiver_not_interested">
										<h5>{t("not_interested")}</h5>
									</button>
								) : (
									<button
										className="btn btn-larger btn-flat btn-ghost"
										onClick={(e) => openLogInModal(e)}
										data-event-category="371_receiver_not_interested">
										<h5>{t("not_interested")}</h5>
									</button>
								)}
								{reduxTokenAuth.currentUser.isSignedIn ? (
									<button
										className="btn btn-larger btn-flat btn-fill"
										onClick={(e) => submitChangingInvitationState(e, "accept")}
										data-event-category="372_receiver_accept">
										<h5>{t("accept_referral")}</h5>
									</button>
								) : (
									<button
										className="btn btn-larger btn-flat btn-fill"
										onClick={(e) => openLogInModal(e)}
										data-event-category="372_receiver_accept">
										<h5>{t("accept_referral")}</h5>
									</button>
								)}
							</div>
						</div>
					</div>
				) : (
					""
				)}
			</CSSTransitionGroup>

			<CSSTransitionGroup
				component="div"
				transitionName="modal-wrapper-with-modal-slide"
				transitionEnterTimeout={300}
				transitionLeaveTimeout={300}>
				{reference_letter_modal.open ? (
					<div className="modal-wrapper reference-letter-modal-wrapper">
						<div className="modal-inner-wrapper">
							<div
								className="modal-bg"
								onClick={(e) => updateReferenceLetterModal(e)}
							/>
							<div className="modal reference-letter-modal">
								<div
									className="btn-close"
									onClick={(e) => updateReferenceLetterModal(e)}
								/>
								<div className="referrer">
									<div className="avatar">
										<div
											className="image-wrapper"
											style={{
												backgroundImage: `url(${referrer.formatted_avatar_url()})`,
											}}
										/>
									</div>
									<h6 className="name">{referrer.formatted_name()}</h6>
								</div>
								<h5>{t("general:reference_letter")}</h5>
								<p className="from-editor referral-letter">
									{invitation.referrer_note}
								</p>
								<div className="button-wrapper">
									<button
										className="btn btn-larger btn-flat btn-fill"
										onClick={(e) => updateReferenceLetterModal(e)}>
										<h5>{t("general:close")}</h5>
									</button>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				{empty_file_warning_modal.open ? (
					<div className="modal-wrapper double-confirm-modal-wrapper">
						<div className="modal-inner-wrapper">
							<div className="modal-bg" />
							<div className="modal double-confirm-modal with-pop-up-icon">
								<div
									className="image-wrapper"
									style={{ backgroundImage: `url(${modal_pop_up_warning}` }}
								/>
								<div className="content">
									<h4 className="modal-title">
										{t(
											"no_resume___cv_file_is_attached_do_you_want_to_apply_without_an_attachment_",
										)}
									</h4>
									{applying ? (
										<div className="buttons-wrapper">
											<button
												onClick={(e) => e.preventDefault()}
												className="btn btn-larger btn-flat btn-ghost disabled">
												<h5>{t("general:back")}</h5>
											</button>
											<button
												onClick={(e) => e.preventDefault()}
												className="btn btn-larger btn-flat btn-fill disabled">
												<h5>{t("general:continue")}</h5>
											</button>
										</div>
									) : (
										<div className="buttons-wrapper">
											<button
												className="btn btn-larger btn-flat btn-ghost"
												onClick={(e) => closeEmptyFileWarningModal(e)}
												data-event-category="377_receiver_back">
												<h5>{t("general:back")}</h5>
											</button>
											<button
												className="btn btn-larger btn-flat btn-fill"
												onClick={(e) => continueSubmitApplication(e)}
												data-event-category="378_receiver_continue">
												<h5>{t("general:continue")}</h5>
											</button>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				{applying_success_modal.open ? (
					<div className="modal-wrapper applying-success-modal-wrapper">
						<div className="modal-inner-wrapper">
							<div
								className="modal-bg"
								onClick={(e) => closeApplyingSuccessModal(e)}
							/>
							<div className="modal applying-success-modal">
								<h4 className="modal-title">{t("applySuccessfullyTitle")}</h4>
								<p>
									<Interpolate
										i18nKey="applySuccessfullyDescription"
										useDangerouslySetInnerHTML={true}
										my_applications={
											<NavLink
												to={internal_uri.formatted_userpanel_my_applications_page_path()}
												className="link"
												target="_blank"
												data-event-category="379_receiver_my_applications">
												{t("general:my_applications")}
											</NavLink>
										}
									/>
								</p>
								<div className="separator">
									<span>{t("general:also")}</span>
								</div>
								<div className="onboard-reward-reminding-wrapper">
									<div>
										<img src={onboardRewardGif} alt="" />
									</div>
									<p
										dangerouslySetInnerHTML={{
											__html: t("applySuccessfullyOnboardRewardReminding"),
										}}
									/>
								</div>
								<button
									className="btn btn-larger btn-flat btn-fill"
									onClick={(e) => closeApplyingSuccessModal(e)}
									data-event-category="380_receiver_ok">
									<h5>{t("general:ok")}</h5>
								</button>
							</div>
						</div>
					</div>
				) : (
					""
				)}
			</CSSTransitionGroup>
		</div>
	);
};
class JobInvitationPage extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.openLogInModal = this.openLogInModal.bind(this);
		this.updateReferenceLetterModal = this.updateReferenceLetterModal.bind(
			this,
		);
		this.fetchData = this.fetchData.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleToggleChange = this.handleToggleChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.submitChangingInvitationState = this.submitChangingInvitationState.bind(
			this,
		);
		this.closeApplyingSuccessModal = this.closeApplyingSuccessModal.bind(this);
		this.closeEmptyFileWarningModal = this.closeEmptyFileWarningModal.bind(
			this,
		);
		this.submitApplication = this.submitApplication.bind(this);
		this.continueSubmitApplication = this.continueSubmitApplication.bind(this);
		this.state = {
			active_job_id: -1,
			job: {},
			active_invitation_id: -1,
			invitation: {},
			invitation_accepted: false,
			form_data: {},
			token: "",
			employer: {},
			reference_letter_modal: {
				open: false,
			},
			applying_success_modal: {
				open: false,
			},
			empty_file_warning_modal: {
				open: false,
			},
			show_mobile_call_to_actions: true,
			loading: true,
			changing_state: false,
			applying: false,
			error_messages: {},
		};
	}

	componentDidMount() {
		this._isMounted = true;

		window.addEventListener("scroll", this.handleScroll);
		// this.handleScroll();

		let token = "",
			job_id = "";
		const query = decodeURI(this.props.location.search);
		if (query) {
			token = get_value_from_query_string("token", query);
			job_id = get_value_from_query_string("job_id", query);

			const invitation_id = this.props.match.params.id_slug;

			this.setState(
				(prev_state) => ({
					...prev_state,
					active_job_id: job_id,
					active_invitation_id: invitation_id,
					token,
				}),
				() => this.fetchData(),
			);
		}
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		const { reduxTokenAuth } = next_props;
		if (
			reduxTokenAuth.currentUser.isSignedIn !==
			this.props.reduxTokenAuth.currentUser.isSignedIn
		) {
			this.setState(
				{
					loading: true,
				},
				() => this.fetchData(),
			);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;

		window.removeEventListener("scroll", this.handleScroll);
	}

	fetchData() {
		const { active_job_id, active_invitation_id, token } = this.state;
		let job = {},
			employer = {},
			invitation = {},
			referrer = {},
			talent = {};
		let options = {
			method: "GET",
			url: config.api_url(`/jobs/${active_job_id}?include=required_skills`),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					const raw_job = response.data;
					job = new Job(raw_job);

					if (job.has_employer()) {
						options = {
							method: "GET",
							url: config.api_url(
								`/employers/${job.employer.id}?include=published_jobs`,
							),
							headers: headers.getItemsFromLocalStorage(),
							json: true,
						};

						return axios(options);
					} else {
						// is external job
						this.setState(
							(prev_state) => ({
								...prev_state,
								job,
								loading: false,
								error_messages: {},
							}),
							() => {
								const internal_uri = new InternalUri();
								this.props.history.push(
									internal_uri.formatted_frontend_jobs_page_path(),
								);
							},
						);

						return new Promise(() => {});
					}
				}
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				if (this._isMounted) {
					const raw_employer = response.data;
					employer = new Employer(raw_employer);

					options = {
						method: "GET",
						url: config.api_url(
							`/jobs/${active_job_id}/talent_invitations/${active_invitation_id}?token=${token}`,
						),
						headers: headers.getItemsFromLocalStorage(),
						json: true,
					};

					return axios(options);
				}
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				if (this._isMounted) {
					invitation = response.data;

					referrer = new User({
						id: invitation.referrer.id,
						avatar: { ...invitation.referrer.avatar },
						first_name: invitation.referrer_first_name,
						last_name: invitation.referrer_last_name,
					});

					talent = new User({
						first_name: invitation.talent_first_name,
						last_name: invitation.talent_last_name,
						email: invitation.talent_email,
						phone: invitation.talent_phone,
						applied_profile: {
							portfolio_url: invitation.talent_portfolio_url,
						},
						attachment: invitation.attachment
							? { ...invitation.attachment }
							: null,
					});

					const { reduxTokenAuth } = this.props;
					this.setState(
						(prev_state) => ({
							...prev_state,
							job,
							invitation,
							invitation_accepted:
								reduxTokenAuth.currentUser.isSignedIn &&
								invitation.state === "accepted",
							form_data: {
								attend_event: true,
								first_name: talent.formatted_form_first_name(),
								last_name: talent.formatted_form_last_name(),
								email: talent.formatted_email(),
								phone: talent.formatted_form_phone(),
								resume: {
									file_url_from_referrer: talent.formatted_attachment_asset_url(),
									file_name_from_referrer: talent.formatted_attachment_asset_filename(),
								},
								portfolio_url: talent.formatted_portfolio_url(),
								cover_letter: "",
							},
							referrer,
							employer,
							loading: false,
							error_messages: {},
						}),
						() => {
							const { location } = this.props;
							if (
								invitation.state === "pending" &&
								location.search.search("talent_original_email") === -1
							) {
								this.props.history.replace(
									`${location.pathname}${location.search}&talent_original_email=${invitation.talent_email}&referrer_id=${referrer.id}`,
								);
							}
						},
					);
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					const internal_uri = new InternalUri();
					const { t } = this.props;
					const { reduxTokenAuth } = this.props;
					if (error.response.status === 403) {
						if (reduxTokenAuth.currentUser.isSignedIn) {
							this.props.history.push(
								internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
							);
							this.props.addFlashMessage(
								true,
								t(
									"flash_messages:you_need_permission_to_browse_the_page_please_ask_for_access_or_switch_to_an_account_with_permission_",
								),
								"danger",
								"with-dismiss",
								"",
							);
						} else {
							const { location } = this.props;
							this.props.history.push(
								`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${encodeURIComponent(
									`${location.pathname}${location.search}`,
								)}`,
							);
						}
					}
					if (error.response.status === 422 || error.response.status === 404) {
						if (error.response.data && error.response.data.data) {
							if (error.response.data.data.state === "rejected") {
								this.props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								this.props.addFlashMessage(
									true,
									t("flash_messages:you_have_rejected_this_invitation_on__", {
										updated_at: error.response.data.data.updated_at,
									}),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (error.response.data.data.state === "applied") {
								this.props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								this.props.addFlashMessage(
									true,
									t(
										"flash_messages:you_have_accepted_this_invitation_and_applied_on__",
										{ updated_at: error.response.data.data.updated_at },
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (
								error.response.data.data.state === "expired" &&
								job.is_closed()
							) {
								this.props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								this.props.addFlashMessage(
									true,
									t(
										"flash_messages:this_invitation_is_not_available_because_the_job_is_closed_",
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (error.response.data.data.state === "expired") {
								this.props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								this.props.addFlashMessage(
									true,
									t(
										"flash_messages:your_link_has_expired_please_send_the_request_again_",
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
						} else {
							this.props.history.push(
								internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
							);
							this.props.addFlashMessage(
								true,
								t(
									"flash_messages:your_link_has_expired_please_send_the_request_again_",
								),
								"danger",
								"with-dismiss",
								"",
							);
						}
					}
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined")
						this.props.updateConnectionOffModal(true);

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				}

				page_smothly_scroll_to(0, 300);
			});
	}

	handleScroll() {
		// TODO: 其實可以去掉 scrollTop，也可以拉出成 function 共用
		const scrollTop =
			window.pageYOffset ||
			document.body.scrollTop ||
			document.documentElement.scrollTop;
		const screenHeight =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;
		const scrollBottom = scrollTop + screenHeight;
		const element = document.querySelector(
			".call-to-action-invitation-to-apply-wrapper",
		);
		if (element) {
			const benchmark = element.getBoundingClientRect().top + scrollTop;
			if (scrollBottom <= benchmark + 100) {
				if (!this.state.show_mobile_call_to_actions) {
					this.setState({ show_mobile_call_to_actions: true });
				}
			} else {
				if (this.state.show_mobile_call_to_actions) {
					this.setState({ show_mobile_call_to_actions: false });
				}
			}
		}
	}

	handleToggleChange = (e, attr) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			form_data: {
				...prev_state.form_data,
				[attr]: !prev_state.form_data[attr],
			},
		}));
	};

	handleInputChange = (e) => {
		e.preventDefault();
		let value = e.target.value;
		const dataset = e.target.dataset;
		const property_name = e.target.id;

		// if(property_name==='email') value = value.toLowerCase();

		this.setState((prev_state) => {
			let error_messages = { ...prev_state.error_messages };
			const { t } = this.props;
			if (dataset.val) {
				let errors = [];

				if (dataset.valRequired) {
					if (value.length <= 0) errors.push(`${t("general:required")}`);
				}

				error_messages = {
					...error_messages,
					[property_name]: errors,
				};
			}

			return {
				...prev_state,
				form_data: {
					...prev_state.form_data,
					[property_name]: value,
				},
				error_messages,
			};
		});
	};

	handleFileChange = (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		const property_name = e.target.id;

		if (file) {
			this.setState((prev_state) => ({
				...prev_state,
				form_data: {
					...prev_state.form_data,
					[property_name]: {
						...prev_state.form_data[property_name],
						file,
					},
				},
			}));
		}
	};

	openLogInModal = (e) => {
		e.preventDefault();
		this.props.updateEntranceFormModal("log-in");
	};

	updateReferenceLetterModal = (e) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			reference_letter_modal: {
				...prev_state.reference_letter_modal,
				open: !prev_state.reference_letter_modal.open,
			},
		}));
	};

	submitChangingInvitationState = (e, next_state) => {
		e.preventDefault();
		this.setState({ changing_state: true });
		const { active_job_id, job, active_invitation_id, token } = this.state;
		let options = {
			method: "PATCH",
			url: config.api_url(
				`/jobs/${active_job_id}/talent_invitations/${active_invitation_id}?token=${token}`,
			),
			headers: headers.getItemsFromLocalStorage(),
			data: {
				state: next_state,
			},
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					if (next_state === "reject") {
						const internal_uri = new InternalUri();
						this.props.history.push(
							internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
						);
					}
					if (next_state === "accept") {
						this.setState(
							{
								invitation_accepted: true,
								changing_state: false,
							},
							() => {
								Scroll.scroller.scrollTo("invitation_to_apply", {
									spy: true,
									smooth: true,
									offset: -80,
									duration: 400,
								});
							},
						);
					}
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					const internal_uri = new InternalUri();
					const { t } = this.props;
					if (error.response.status === 401) {
						const { location } = this.props;
						this.props.history.push(
							`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${encodeURIComponent(
								`${location.pathname}${location.search}`,
							)}`,
						);
					}
					if (error.response.status === 403) {
						this.props.history.push(
							internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
						);
						this.props.addFlashMessage(
							true,
							t(
								"flash_messages:you_need_permission_to_browse_the_page_please_ask_for_access_or_switch_to_an_account_with_permission_",
							),
							"danger",
							"with-dismiss",
							"",
						);
					}
					if (error.response.status === 422 || error.response.status === 404) {
						if (error.response.data && error.response.data.data) {
							if (error.response.data.data.state === "rejected") {
								this.props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								this.props.addFlashMessage(
									true,
									t("flash_messages:you_have_rejected_this_invitation_on__", {
										updated_at: error.response.data.data.updated_at,
									}),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (error.response.data.data.state === "applied") {
								this.props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								this.props.addFlashMessage(
									true,
									t(
										"flash_messages:you_have_accepted_this_invitation_and_applied_on__",
										{ updated_at: error.response.data.data.updated_at },
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (
								error.response.data.data.state === "expired" &&
								job.is_closed()
							) {
								this.props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								this.props.addFlashMessage(
									true,
									t(
										"flash_messages:this_invitation_is_not_available_because_the_job_is_closed_",
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
							if (error.response.data.data.state === "expired") {
								this.props.history.push(
									internal_uri.formatted_frontend_job_page_path(
										job.id,
										job.slug,
									),
								);
								this.props.addFlashMessage(
									true,
									t(
										"flash_messages:your_link_has_expired_please_send_the_request_again_",
									),
									"danger",
									"with-dismiss",
									"",
								);
							}
						} else {
							// this.props.history.push(internal_uri.formatted_frontend_job_page_path(job.id, job.slug));
							// this.props.addFlashMessage(
							//     true,
							//     t('flash_messages:your_link_has_expired_please_send_the_request_again_'),
							//     'danger',
							//     'with-dismiss',
							//     ''
							// );
						}
					}
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined")
						this.props.updateConnectionOffModal(true);

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						changing_state: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				}

				Scroll.scroller.scrollTo("invitation_to_apply", {
					spy: true,
					smooth: true,
					offset: -80,
					duration: 400,
				});
			});
	};

	closeApplyingSuccessModal = (e) => {
		e.preventDefault();
		this.setState(
			(prev_state) => ({
				...prev_state,
				applying_success_modal: {
					open: false,
				},
			}),
			() => {
				const { job } = this.state;
				const internal_uri = new InternalUri();
				this.props.history.push(
					internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
				);
			},
		);
	};

	closeEmptyFileWarningModal = (e) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			empty_file_warning_modal: {
				open: false,
			},
		}));
	};

	submitApplication = (e) => {
		e.preventDefault();
		const { form_data } = this.state;

		let has_errors = false;
		let error_messages = { ...this.state.error_messages };
		const { t } = this.props;
		let form = document.querySelector("form");
		if (form) {
			const inputs = form.querySelectorAll("input");
			for (let i = 0; i < inputs.length; i++) {
				const input = inputs[i];
				let errors = [];

				if (input.dataset.val) {
					if (input.dataset.valRequired) {
						if (input.value.length <= 0) {
							errors.push(`${t("general:required")}`);
							has_errors = true;
						}
					}
				}

				error_messages = {
					...error_messages,
					[input.id]: errors,
				};
			}
		}

		if (has_errors) {
			this.setState({ error_messages });
			page_smothly_scroll_to(0, 300);
		} else if (
			typeof form_data.resume.file === "undefined" &&
			!form_data.resume.file_url_from_referrer
		) {
			this.setState((prev_state) => ({
				...prev_state,
				empty_file_warning_modal: {
					open: true,
				},
			}));
		} else {
			this.continueSubmitApplication(e);
		}
	};

	continueSubmitApplication = (e) => {
		e.preventDefault();
		this.setState({ applying: true });
		const { active_job_id, form_data, active_invitation_id, job } = this.state;
		const { reduxTokenAuth } = this.props;

		let formData = new FormData();
		formData.append("talent_invitation_id", active_invitation_id);

		formData.append("job_application[job_id]", active_job_id);
		formData.append(
			"job_application[applier_name]",
			`${form_data.last_name}, ${form_data.first_name}`,
		);
		formData.append("job_application[applier_email]", form_data.email);
		formData.append("job_application[applier_phone]", form_data.phone);
		formData.append("job_application[cover_letter]", form_data.cover_letter);
		formData.append("job_application[portfolio_url]", form_data.portfolio_url);
		// TODO: 檢查上傳 file 的大小？
		if (typeof form_data.resume.file !== "undefined")
			formData.append("resume_attachment[asset]", form_data.resume.file);

		if (form_data.attend_event && job.has_event())
			formData.append("job_application[event_id]", job.events[0].id);

		let options = {
			method: "POST",
			url: config.api_url(
				`/users/${reduxTokenAuth.currentUser.attributes.id}/job_applications`,
			),
			headers: headers.getItemsFromLocalStorage("multipart/form-data"),
			data: formData,
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						applying: false,
						applying_success_modal: {
							open: true,
						},
						empty_file_warning_modal: {
							open: false,
						},
					}));
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					const internal_uri = new InternalUri();
					const { job } = this.state;
					const { t } = this.props;
					if (error.response.status === 401) window.location.reload();
					if (error.response.status === 404) {
						this.props.history.push(
							internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
						);
						this.props.addFlashMessage(
							true,
							t(
								"flash_messages:your_link_has_expired_please_send_the_request_again_",
							),
							"danger",
							"with-dismiss",
							"",
						);
					}
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined")
						this.props.updateConnectionOffModal(true);

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						applying: false,
						empty_file_warning_modal: {
							open: false,
						},
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				}

				page_smothly_scroll_to(0, 300);
			});
	};

	render() {
		const {
			handleToggleChange,
			handleInputChange,
			handleFileChange,
			openLogInModal,
			updateReferenceLetterModal,
			submitChangingInvitationState,
			submitApplication,
			continueSubmitApplication,
			closeApplyingSuccessModal,
			closeEmptyFileWarningModal,
		} = this;
		const { show_mobile_call_to_actions } = this.state;
		const {
			job,
			employer,
			invitation,
			invitation_accepted,
			form_data,
			referrer,
			applying_success_modal,
			empty_file_warning_modal,
			reference_letter_modal,
			loading,
			applying,
			changing_state,
			error_messages,
		} = this.state;
		const { reduxTokenAuth } = this.props;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		if (loading || !Object.keys(job).length) {
			return (
				<div className="loading-skeleton-wrapper">
					<div className="page-job-invitation content-wrapper with-gradient reward75-to-belize">
						<div className="container">
							<div className="row">
								<div className="col-md-8">
									<div className="tablet-wrapper">
										<JobInfoWrapper loading={true} />
									</div>
								</div>

								<div className="col-md-4">
									<div className="tablet-wrapper">
										<div className="call-to-action-invitation-to-apply-wrapper block">
											<h4>{t("general:invitation_to_apply")}</h4>
											<div className="buttons-wrapper">
												<button className="btn btn-larger btn-flat btn-fill">
													<h5>{`${t("general:loading")}...`}</h5>
												</button>
												<button className="btn btn-larger">
													<h5>{`${t("general:loading")}...`}</h5>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		let has_errors = false;
		const black_list = ["last_name", "first_name", "email", "phone"];
		for (let key in error_messages) {
			if (black_list.indexOf(key) !== -1 && error_messages[key].length)
				has_errors = true;
		}

		return (
			<div className="page-job-invitation content-wrapper with-gradient reward75-to-belize">
				<Helmet>
					<title>
						{job.formatted_title()} - {job.formatted_employer_name()}｜Meet.jobs
					</title>
				</Helmet>
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<div className="tablet-wrapper">
								<JobInfoWrapper
									job={{ ...job }}
									employer={{ ...employer }}
									loading={loading}
									show_link_for_job_page
									eventCategories={{
										headEmployer: "382_receiver_link_employer",
										headLocation: "383_receiver_link_location",
										requiredSkill: "384_receiver_link_skill_tag",
										employerInfo: "385_receiver_employer_info",
									}}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="tablet-wrapper">
								<ScrollElement name="invitation_to_apply">
									{!invitation_accepted ? (
										<div className="call-to-action-invitation-to-apply-wrapper block">
											<h4>{t("general:invitation_to_apply")}</h4>
											{error_messages.full_message ? (
												<h6 className="message error-message">
													{error_messages.full_message}
												</h6>
											) : (
												""
											)}
											{/*{(error_messages.talent_email && error_messages.talent_email.length) ? <h6 className="message error-message">{error_messages.talent_email.join(', ')}</h6> : ''}*/}
											<div className="dialog-wrapper">
												<div className="dialog green-reward from-editor">
													<p>
														{contain_not_only_whitespace(
															invitation.comment_to_talent,
														)
															? invitation.comment_to_talent
															: t(
																	"i_found_this_job_that_is_perfect_for_you_accept_my_referral_and_apply_for_it_on_meet_jobs_",
															  )}
													</p>
													<SpeechBubble className="speech-bubble" />
												</div>
												<div className="speaker-info">
													<div className="avatar">
														<div
															className="image-wrapper"
															style={{
																backgroundImage: `url(${referrer.formatted_avatar_url()})`,
															}}
														/>
													</div>
													<h6>{referrer.formatted_name()}</h6>
												</div>
											</div>

											{reduxTokenAuth.currentUser.isSignedIn ? (
												changing_state ? (
													<div className="buttons-wrapper">
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-larger btn-flat btn-ghost disabled">
															<h5>{t("not_interested")}</h5>
														</button>
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-larger btn-flat btn-fill disabled">
															<h5>{t("accept_referral")}</h5>
														</button>
													</div>
												) : (
													<div className="buttons-wrapper">
														<button
															className="btn btn-larger btn-flat btn-ghost"
															onClick={(e) =>
																submitChangingInvitationState(e, "reject")
															}>
															<h5>{t("not_interested")}</h5>
														</button>
														<button
															className="btn btn-larger btn-flat btn-fill"
															onClick={(e) =>
																submitChangingInvitationState(e, "accept")
															}>
															<h5>{t("accept_referral")}</h5>
														</button>
													</div>
												)
											) : (
												<div className="buttons-wrapper">
													<button
														className="btn btn-larger btn-flat btn-ghost"
														onClick={(e) => openLogInModal(e)}>
														<h5>{t("not_interested")}</h5>
													</button>
													<button
														className="btn btn-larger btn-flat btn-fill"
														onClick={(e) => openLogInModal(e)}>
														<h5>{t("accept_referral")}</h5>
													</button>
												</div>
											)}
										</div>
									) : (
										<div className="call-to-action-invitation-to-apply-wrapper block">
											<h4>{t("general:invitation_to_apply")}</h4>
											{contain_not_only_whitespace(invitation.referrer_note) ? (
												<div className="referrer-wrapper">
													<div className="referrer-inner-wrapper">
														<div className="referrer">
															<div className="avatar">
																<div
																	className="image-wrapper"
																	style={{
																		backgroundImage: `url(${referrer.formatted_avatar_url()})`,
																	}}
																/>
															</div>
															<h6>{referrer.formatted_name()}</h6>
														</div>
														<button
															className="btn btn-smaller btn-flat btn-hollow"
															onClick={(e) => updateReferenceLetterModal(e)}
															data-event-category="373_receiver_read_letter">
															<h5>{t("read_reference_letter")}</h5>
														</button>
													</div>
												</div>
											) : (
												""
											)}

											<form>
												{job.has_event() ? (
													<div
														className="event-switcher-wrapper"
														onClick={(e) =>
															handleToggleChange(e, "attend_event")
														}>
														<div
															className="banner"
															style={{
																backgroundImage: `url(${job.events[0].get_banner_url()})`,
															}}
															data-event-category="316_modal_apply_banner"
														/>
														<div
															className="event-switcher"
															data-event-category="317_modal_apply_switch">
															<input
																className="radio-input"
																type="radio"
																checked={form_data.attend_event}
																readOnly
															/>
															<label
																htmlFor="remote"
																className="radio-switcher"
															/>
															<div className="text">
																<p>
																	{form_data.attend_event
																		? t("events:yes__i_m_here_for__", {
																				event_name: t(
																					job.events[0].get_i18n_key(),
																				),
																		  })
																		: t(
																				"events:no__i_don_t_want_to_attend_this_event_",
																		  )}
																</p>
															</div>
														</div>
													</div>
												) : (
													""
												)}
												<label htmlFor="first_name">
													<h5>{t("name")}</h5>
												</label>
												{i18n.language === "zh-TW" ? (
													<div className="form-row form-row-2">
														<div className="form-col">
															<div className="form-row form-row-dire-col">
																<div className="form-col">
																	<input
																		className={`input ${
																			error_messages.last_name &&
																			error_messages.last_name.length
																				? "input-danger"
																				: ""
																		}`}
																		type="text"
																		value={form_data.last_name}
																		placeholder={t("last_name")}
																		onChange={(e) => handleInputChange(e)}
																		id="last_name"
																		data-val="true"
																		data-val-required="true"
																	/>
																</div>
																<div className="form-col">
																	{error_messages.last_name &&
																	error_messages.last_name.length ? (
																		<h6 className="message error-message">
																			{error_messages.last_name.join(", ")}
																		</h6>
																	) : (
																		""
																	)}
																</div>
															</div>
														</div>
														<div className="form-col">
															<div className="form-row form-row-dire-col">
																<div className="form-col">
																	<input
																		className={`input ${
																			error_messages.first_name &&
																			error_messages.first_name.length
																				? "input-danger"
																				: ""
																		}`}
																		type="text"
																		value={form_data.first_name}
																		placeholder={t("first_name")}
																		onChange={(e) => handleInputChange(e)}
																		id="first_name"
																		data-val="true"
																		data-val-required="true"
																	/>
																</div>
																<div className="form-col">
																	{error_messages.first_name &&
																	error_messages.first_name.length ? (
																		<h6 className="message error-message">
																			{error_messages.first_name.join(", ")}
																		</h6>
																	) : (
																		""
																	)}
																</div>
															</div>
														</div>
													</div>
												) : (
													<div className="form-row form-row-2">
														<div className="form-col">
															<div className="form-row form-row-dire-col">
																<div className="form-col">
																	<input
																		className={`input ${
																			error_messages.first_name &&
																			error_messages.first_name.length
																				? "input-danger"
																				: ""
																		}`}
																		type="text"
																		value={form_data.first_name}
																		placeholder={t("first_name")}
																		onChange={(e) => handleInputChange(e)}
																		id="first_name"
																		data-val="true"
																		data-val-required="true"
																	/>
																</div>
																<div className="form-col">
																	{error_messages.first_name &&
																	error_messages.first_name.length ? (
																		<h6 className="message error-message">
																			{error_messages.first_name.join(", ")}
																		</h6>
																	) : (
																		""
																	)}
																</div>
															</div>
														</div>
														<div className="form-col">
															<div className="form-row form-row-dire-col">
																<div className="form-col">
																	<input
																		className={`input ${
																			error_messages.last_name &&
																			error_messages.last_name.length
																				? "input-danger"
																				: ""
																		}`}
																		type="text"
																		value={form_data.last_name}
																		placeholder={t("last_name")}
																		onChange={(e) => handleInputChange(e)}
																		id="last_name"
																		data-val="true"
																		data-val-required="true"
																	/>
																</div>
																<div className="form-col">
																	{error_messages.last_name &&
																	error_messages.last_name.length ? (
																		<h6 className="message error-message">
																			{error_messages.last_name.join(", ")}
																		</h6>
																	) : (
																		""
																	)}
																</div>
															</div>
														</div>
													</div>
												)}

												<label htmlFor="email">
													<h5>{t("email")}</h5>
												</label>
												<div className="form-row form-row-dire-col">
													<div className="form-col">
														<input
															className={`input ${
																error_messages.email &&
																error_messages.email.length
																	? "input-danger"
																	: ""
															}`}
															type="email"
															value={form_data.email}
															placeholder={t("email")}
															onChange={(e) => handleInputChange(e)}
															id="email"
															data-val="true"
															data-val-required="true"
														/>
													</div>
													<div className="form-col">
														{error_messages.email &&
														error_messages.email.length ? (
															<h6 className="message error-message">
																{error_messages.email.join(", ")}
															</h6>
														) : (
															""
														)}
													</div>
												</div>

												<label htmlFor="phone">
													<h5>{t("phone_number")}</h5>
												</label>
												<div className="form-row form-row-dire-col">
													<div className="form-col">
														<input
															className={`input ${
																error_messages.phone &&
																error_messages.phone.length
																	? "input-danger"
																	: ""
															}`}
															type="text"
															value={form_data.phone}
															placeholder={t("your_phone_number")}
															onChange={(e) => handleInputChange(e)}
															id="phone"
															data-val="true"
															data-val-required="true"
														/>
													</div>
													<div className="form-col">
														{error_messages.phone &&
														error_messages.phone.length ? (
															<h6 className="message error-message">
																{error_messages.phone.join(", ")}
															</h6>
														) : (
															""
														)}
													</div>
												</div>

												<label htmlFor="resume" className="lines">
													<h5>{t("general:resume___cv_file")}</h5>
													{typeof form_data.resume.file !== "undefined" ? (
														<h6>{form_data.resume.file.name}</h6>
													) : form_data.resume.file_url_from_referrer ? (
														<h6>
															<a
																className="link"
																href={form_data.resume.file_url_from_referrer}
																target="_blank"
																rel="noopener noreferrer"
																data-event-category="374_receiver_cv">
																<h5>
																	{form_data.resume.file_name_from_referrer}
																</h5>
															</a>
														</h6>
													) : (
														<h6>{t("general:no_resume___cv_file")}</h6>
													)}
												</label>
												<div className="form-row">
													<input
														className="input-upload-file"
														id="resume"
														type="file"
														accept={
															`.pdf, ` +
															`.doc, ` +
															`.docx, ` +
															`application/pdf, ` +
															`application/msword, ` +
															`application/vnd.openxmlformats-officedocument.wordprocessingml.document`
														}
														onChange={(e) => handleFileChange(e)}
													/>
													<label
														htmlFor="resume"
														className="btn btn-larger btn-flat btn-hollow btn-upload-file">
														{t("general:choose_file")}
													</label>
												</div>

												<label htmlFor="portfolio_url">
													<h5>{t("general:reference_link")}</h5>
												</label>
												<div className="form-row form-row-dire-col">
													<div className="form-col">
														<input
															className={`input ${
																error_messages.portfolio_url &&
																error_messages.portfolio_url.length
																	? "input-danger"
																	: ""
															}`}
															type="text"
															value={form_data.portfolio_url}
															placeholder={t(
																"https___linkedin_com_in_your_name",
															)}
															onChange={(e) => handleInputChange(e)}
															id="portfolio_url"
															data-val="true"
														/>
													</div>
													<div className="form-col">
														{error_messages.portfolio_url &&
														error_messages.portfolio_url.length ? (
															<h6 className="message error-message">
																{error_messages.portfolio_url.join(", ")}
															</h6>
														) : (
															""
														)}
													</div>
												</div>

												<label htmlFor="cover_letter">
													<h5>{t("cover_letter")}</h5>
												</label>
												<div className="form-row">
													<Textarea
														className="textarea"
														minRows={5}
														maxRows={10}
														placeholder={t("write_cover_letter_here_")}
														value={form_data.cover_letter}
														onChange={(e) => handleInputChange(e)}
														id="cover_letter"
													/>
												</div>

												<div className="buttons-wrapper">
													{changing_state || applying ? (
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-larger btn-flat btn-ghost disabled">
															<h5>{t("general:decline")}</h5>
														</button>
													) : (
														<button
															className="btn btn-larger btn-flat btn-ghost"
															onClick={(e) =>
																submitChangingInvitationState(e, "reject")
															}
															data-event-category="375_receiver_decline">
															<h5>{t("general:decline")}</h5>
														</button>
													)}
													{has_errors || changing_state || applying ? (
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-larger btn-flat btn-fill disabled">
															<h5>{t("general:apply")}</h5>
														</button>
													) : (
														<button
															className="btn btn-larger btn-flat btn-fill"
															onClick={(e) => submitApplication(e)}
															data-event-category="376_receiver_apply">
															<h5>{t("general:apply")}</h5>
														</button>
													)}
												</div>
											</form>
										</div>
									)}
								</ScrollElement>
							</div>
						</div>
					</div>
				</div>

				<CSSTransitionGroup
					component="div"
					transitionName="fade-in-out"
					transitionEnterTimeout={300}
					transitionLeaveTimeout={300}>
					{!invitation_accepted && show_mobile_call_to_actions ? (
						<div className="mobile-call-to-action-wrapper">
							<div className="container">
								<div className="mobile-call-to-action">
									<div className="referrer-wrapper">
										<ScrollLink
											to="invitation_to_apply"
											spy={true}
											smooth={true}
											offset={-80}
											duration={400}>
											<div className="referrer">
												<div className="avatar">
													<div
														className="image-wrapper"
														style={{
															backgroundImage: `url(${referrer.formatted_avatar_url()})`,
														}}
													/>
												</div>
												<h6>{referrer.formatted_name()}</h6>
											</div>
										</ScrollLink>
									</div>
									{reduxTokenAuth.currentUser.isSignedIn ? (
										<button
											className="btn btn-larger btn-flat btn-ghost"
											onClick={(e) =>
												submitChangingInvitationState(e, "reject")
											}
											data-event-category="371_receiver_not_interested">
											<h5>{t("not_interested")}</h5>
										</button>
									) : (
										<button
											className="btn btn-larger btn-flat btn-ghost"
											onClick={(e) => openLogInModal(e)}
											data-event-category="371_receiver_not_interested">
											<h5>{t("not_interested")}</h5>
										</button>
									)}
									{reduxTokenAuth.currentUser.isSignedIn ? (
										<button
											className="btn btn-larger btn-flat btn-fill"
											onClick={(e) =>
												submitChangingInvitationState(e, "accept")
											}
											data-event-category="372_receiver_accept">
											<h5>{t("accept_referral")}</h5>
										</button>
									) : (
										<button
											className="btn btn-larger btn-flat btn-fill"
											onClick={(e) => openLogInModal(e)}
											data-event-category="372_receiver_accept">
											<h5>{t("accept_referral")}</h5>
										</button>
									)}
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</CSSTransitionGroup>

				<CSSTransitionGroup
					component="div"
					transitionName="modal-wrapper-with-modal-slide"
					transitionEnterTimeout={300}
					transitionLeaveTimeout={300}>
					{reference_letter_modal.open ? (
						<div className="modal-wrapper reference-letter-modal-wrapper">
							<div className="modal-inner-wrapper">
								<div
									className="modal-bg"
									onClick={(e) => updateReferenceLetterModal(e)}
								/>
								<div className="modal reference-letter-modal">
									<div
										className="btn-close"
										onClick={(e) => updateReferenceLetterModal(e)}
									/>
									<div className="referrer">
										<div className="avatar">
											<div
												className="image-wrapper"
												style={{
													backgroundImage: `url(${referrer.formatted_avatar_url()})`,
												}}
											/>
										</div>
										<h6 className="name">{referrer.formatted_name()}</h6>
									</div>
									<h5>{t("general:reference_letter")}</h5>
									<p className="from-editor referral-letter">
										{invitation.referrer_note}
									</p>
									<div className="button-wrapper">
										<button
											className="btn btn-larger btn-flat btn-fill"
											onClick={(e) => updateReferenceLetterModal(e)}>
											<h5>{t("general:close")}</h5>
										</button>
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}
					{empty_file_warning_modal.open ? (
						<div className="modal-wrapper double-confirm-modal-wrapper">
							<div className="modal-inner-wrapper">
								<div className="modal-bg" />
								<div className="modal double-confirm-modal with-pop-up-icon">
									<div
										className="image-wrapper"
										style={{ backgroundImage: `url(${modal_pop_up_warning}` }}
									/>
									<div className="content">
										<h4 className="modal-title">
											{t(
												"no_resume___cv_file_is_attached_do_you_want_to_apply_without_an_attachment_",
											)}
										</h4>
										{applying ? (
											<div className="buttons-wrapper">
												<button
													onClick={(e) => e.preventDefault()}
													className="btn btn-larger btn-flat btn-ghost disabled">
													<h5>{t("general:back")}</h5>
												</button>
												<button
													onClick={(e) => e.preventDefault()}
													className="btn btn-larger btn-flat btn-fill disabled">
													<h5>{t("general:continue")}</h5>
												</button>
											</div>
										) : (
											<div className="buttons-wrapper">
												<button
													className="btn btn-larger btn-flat btn-ghost"
													onClick={(e) => closeEmptyFileWarningModal(e)}
													data-event-category="377_receiver_back">
													<h5>{t("general:back")}</h5>
												</button>
												<button
													className="btn btn-larger btn-flat btn-fill"
													onClick={(e) => continueSubmitApplication(e)}
													data-event-category="378_receiver_continue">
													<h5>{t("general:continue")}</h5>
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}
					{applying_success_modal.open ? (
						<div className="modal-wrapper applying-success-modal-wrapper">
							<div className="modal-inner-wrapper">
								<div
									className="modal-bg"
									onClick={(e) => closeApplyingSuccessModal(e)}
								/>
								<div className="modal applying-success-modal">
									<h4 className="modal-title">{t("applySuccessfullyTitle")}</h4>
									<p>
										<Interpolate
											i18nKey="applySuccessfullyDescription"
											useDangerouslySetInnerHTML={true}
											my_applications={
												<NavLink
													to={internal_uri.formatted_userpanel_my_applications_page_path()}
													className="link"
													target="_blank"
													data-event-category="379_receiver_my_applications">
													{t("general:my_applications")}
												</NavLink>
											}
										/>
									</p>
									<div className="separator">
										<span>{t("general:also")}</span>
									</div>
									<div className="onboard-reward-reminding-wrapper">
										<div>
											<img src={onboardRewardGif} alt="" />
										</div>
										<p
											dangerouslySetInnerHTML={{
												__html: t("applySuccessfullyOnboardRewardReminding"),
											}}
										/>
									</div>
									<button
										className="btn btn-larger btn-flat btn-fill"
										onClick={(e) => closeApplyingSuccessModal(e)}
										data-event-category="380_receiver_ok">
										<h5>{t("general:ok")}</h5>
									</button>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</CSSTransitionGroup>
			</div>
		);
	}
}

export default withNamespaces(["frontend_job_invitation_page", "general"])(
	JobInvitationPagef,
);
