import Abstract from "./abstract";
import {contain_not_only_whitespace} from "../../assets/js/functions";

class Address extends Abstract {
    constructor(attrs) {
        super(attrs);

        this.latitude = parseFloat(this.latitude);
        this.longitude = parseFloat(this.longitude);
    }

    // formatted_country() {
    //     if(this.place) {
    //         if(this.place.country) return this.place.country;
    //     }
    //     if(this.)
    // };

    formatted_form_city() {
        if(this.handwriting_city) return this.handwriting_city;
        return '';
    };

    formatted_form_street() {
        if(this.handwriting_street) return this.handwriting_street;
        return '';
    };

    has_place() {
        if(this.place) return true;
        else return false;
    }

    has_latitude_and_longitude() {
        if(this.has_place()) {
            if(this.place.latitude && this.place.longitude) return true;
        }
        if(this.latitude && this.longitude) return true;
        return false;
    };

    formatted_address() {
        // if(this.has_place()) {
        //     if(this.place.formatted_address) return this.place.formatted_address;
        // }
        if(this.handwriting_country || this.handwriting_city || this.handwriting_street) {
            let location = '';
            if(this.handwriting_street) location += this.handwriting_street;
            if(this.handwriting_street && this.handwriting_city) location += ', ';
            if(this.handwriting_city) location += this.handwriting_city;
            if(this.handwriting_city && this.handwriting_country) location += ', ';
            if(this.handwriting_street && this.handwriting_country && !contain_not_only_whitespace(this.handwriting_city)) location += ', ';
            if(this.handwriting_country) location += this.handwriting_country;
            return location;
        }
        return '(not provided)';
    };

    formatted_latitude() {
        if(this.has_place()) {
            if(this.place.latitude) return this.place.latitude;
        }
        if(this.latitude) return this.latitude;
    };

    formatted_longitude() {
        if(this.has_place()) {
            if(this.place.longitude) return this.place.longitude;
        }
        if(this.longitude) return this.longitude;
    };
}

export default Address;
