import React, { useEffect, useState } from "react";
import geoJson from "./worldmap.json";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";

const getPointByISO = (iso) => {
  let point = 0;
  if (iso === "TWN" || iso === "HKG" || iso === "JPN" || iso === "SGP") {
    point = 5;
  }
  if (
    iso === "MYS" ||
    iso === "DEU" ||
    iso === "PRT" ||
    iso === "USA" ||
    iso === "CAN"
  ) {
    point = 4;
  }
  if (
    iso === "CHN" ||
    iso === "IDN" ||
    iso === "PHL" ||
    iso === "VNM" ||
    iso === "KHM" ||
    iso === "AUS" ||
    iso === "ARE" ||
    iso === "NLD" ||
    iso === "PYF" ||
    iso === "GRB" ||
    iso === "HTI"
  ) {
    point = 3;
  }
  if (
    iso === "KOR" ||
    iso === "THA" ||
    iso === "NZL" ||
    iso === "IND" ||
    iso === "QAT" ||
    iso === "SAU" ||
    iso === "ISR" ||
    iso === "TUR" ||
    iso === "RUS" ||
    iso === "EST" ||
    iso === "POL"
  ) {
    point = 2;
  }
  if (
    iso === "MAC" ||
    iso === "SLB" ||
    iso === "PAK" ||
    iso === "LKA" ||
    iso === "BHR" ||
    iso === "JOR" ||
    iso === "LBN" ||
    iso === "IRQ" ||
    iso === "IRN" ||
    iso === "EGY" ||
    iso === "AUT" ||
    iso === "CHE" ||
    iso === "ITA" ||
    iso === "ESP" ||
    iso === "SWE" ||
    iso === "FIN" ||
    iso === "IRL" ||
    iso === "UKR" ||
    iso === "BLR" ||
    iso === "LTU" ||
    iso === "HUN" ||
    iso === "CZE" ||
    iso === "ROU" ||
    iso === "BRG" ||
    iso === "ARM" ||
    iso === "GEO" ||
    iso === "ZAF" ||
    iso === "NGA" ||
    iso === "GHA" ||
    iso === "CIV" ||
    iso === "KEN" ||
    iso === "MEX" ||
    iso === "NIC" ||
    iso === "CRI" ||
    iso === "ARG" ||
    iso === "PER" ||
    iso === "CHL"
  ) {
    point = 1;
  }
  return point;
};
function getBaseLog(x, y) {
  return Math.log(y) / Math.log(x);
}
// a function take a number from 0 to 7961 ,if the number is 0 return #eee else retun a hex color from #c5d9ff to #05327f

const getColor = async (value) => {
  // console.log("value", value);
  const d3 = await import("d3");
  const myColor = d3
    .scaleLinear()
    .domain([getBaseLog(1.6, 1), getBaseLog(1.6, 7961)])
    .range(["#E8F0FF", "#2768F6"]);
  const color = myColor(value);

  return color;
};

const WorldMap = ({ t }) => {
  const [geoData, setGeoData] = useState(geoJson);

  useEffect(() => {
    async function drawMap() {
      const geometries = await Promise.all(
        geoJson.objects.world.geometries.map(async (geo) => {
          const valueLog = getBaseLog(1.6, geo.properties.value);
          const color = valueLog < 0.1 ? "#eee" : await getColor(valueLog);

          return {
            ...geo,
            properties: { ...geo.properties, color },
          };
        })
      );

      setGeoData({
        ...geoJson,
        objects: {
          ...geoJson.objects,
          world: { ...geoJson.objects.world, geometries },
        },
      });
    }
    drawMap();
  }, []);

  useEffect(() => {
    const drawLegend = async () => {
      const D3 = await import("d3");
      // const colorScale = await D3.scaleLinear()
      //   .domain([getBaseLog(1.6, 1), getBaseLog(1.6, 7961)])
      //   .range(["#5660C2", "#2768F6"]);
      const LEGEND_WIDTH = 150;
      const LEGEND_HEIGHT = 20;
      // const LABEL_PADDING = 5;

      // Create the SVG element
      const svg = await D3.select("#world-map-legend")
        .attr("width", LEGEND_WIDTH)
        .attr("height", LEGEND_HEIGHT)

        .style("stroke-width", 0);

      // Create the gradient indicating variable values
      const grad = svg
        .append("defs")
        .append("linearGradient")
        .attr("id", "grad")
        .attr("x1", "100%")
        .attr("x2", "0%")
        .attr("y1", "0%")
        .attr("y2", "0%");

      grad
        .selectAll("stop")
        .data(["#2768F6", "#E8F0FF"])
        .enter()
        .append("stop")
        .style("stop-color", function(d) {
          return d;
        })
        .attr("offset", function(d, i) {
          return 100 * (i / (["#06327F", "#E8F0FF"].length - 1)) + "%";
        });

      svg
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", LEGEND_WIDTH)
        .attr("height", LEGEND_HEIGHT)
        .style("fill", "url(#grad)")
        .style("stroke-width", 2)
        .style("stroke", "#E8F0FF");
      // // append the label svg next to the y-axis on the right side
      // svg
      // 	.append("text")
      // 	.attr("class", "legend-label")
      // 	.attr("x", LEGEND_WIDTH / 2)
      // 	.attr("y", LEGEND_HEIGHT + LABEL_PADDING)
      // 	.style("text-anchor", "middle")
      // 	.text("0");
    };
    drawLegend();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {/* <svg className="world-map" width={1200} height={850}></svg> */}
      <ComposableMap
        width={1800}
        height={900}
        projection="geoMercator"
        projectionConfig={{
          center: [0, 30],
          scale: 210,
        }}
      >
        <Geographies geography={geoData}>
          {({ geographies }) =>
            geographies.map((geo) => {
              if (geo.properties.color === undefined) {
                // console.log(geo);
              }
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  stroke="#FFF"
                  strokeWidth="1"
                  fill={geo.properties.color}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          gap: "5%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            // position: "absolute",
            // bottom: "10%",
            // left: "10%",
            gap: "0.6rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            padding: "1%",

            borderRadius: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1%",
              fontSize: "1rem",
              color: "rgb(6, 50, 127)",
              marginBottom: "0.5rem",
            }}
          >
            <div>{t("worldmap_less")}</div>
            <svg id="world-map-legend"></svg>
            <div>{t("worldmap_more")}</div>
          </div>
          <div
            style={{
              fontSize: "12px",
              lineHeight: "1.2rem",
              color: "#06327F",
              whiteSpace: "nowrap",
            }}
          >
            {/* +1 在該國家/地區有 +1 在該國家/地區有企業客戶 +1
					在該國家/地區有當地合作夥伴 +1 有團隊成員來自在該國家/地區 +1
					在該國家/地區有投資人 */}
            {t("worldmap_user_distribution")}
          </div>
        </div>
        <div
          style={{
            width: "fit-content",
            color: "rgb(6, 50, 127)",
            fontWeight: "600",
            // marginTop: "30px",
            marginBottom: "3rem",
            maxWidth: "50%",
            fontSize: "1.4rem",
            // position: "absolute",
            // bottom: "10%",
            // right: "10%",

            textAlign: "start",
          }}
        >
          <div>{t("worldmap_explain_1")}</div>
          <div>{t("worldmap_explain_2")}</div>
        </div>
      </div>
    </div>
  );
};

export default WorldMap;
