export default {
    this_job_is_no_longer_accepting_applicants_: "This job is no longer accepting applicants.",
    this_job_is_in_the_process_of_editing_and_not_accepting_applicants_: "This job is in the process of editing and not accepting applicants.",
    browse_more_jobs: "Browse more jobs",
    salary: "Salary",
    job_type: "Job type",
    remote: "Remote",
    allowed: "Allowed",
    last_updated: "Last updated",
    required_skills: "Required skills",
    job_description: "Job description",
    employer_info: "Employer info",
    other_jobs_from_this_employer: "Other jobs from this employer",
    no_other_jobs_: "No other jobs.",
    get___reward_from_meet_jobs_when_you_are_successfully_hired_: "Get <span class='reward'>100 USD</span> reward from Meet.jobs when you are successfully hired.",
    we_will_redirect_you: "We will redirect you",
    redirect_description: "Please apply this job on the redirected website. Meet.jobs provides  <span class='reward'>100 USD</span> Onboard Reward when you are successfully hired. Remember to come back and share your good news.",
    you_have_a_pending_invitation: "You have a pending invitation",
    if_you_refer_without___you_will_not_get_any_referral_reward_: "If you refer without {{log_in}}, you will NOT get any Referral Reward.",
    hi__i_found_a_great_job_you_might_be_interested_come_and_check_it_out_: "Hi! I found a great job you might be interested. Come and check it out.",
    refer_to_a_friend_and_get___reward_with_successful_hire_: "Refer to a friend and get {{reward}} reward with successful hire.",
    referred_by: "Referred by",
    you_have_applied_on___: "You have applied on {{applied_at}}.",
    you_have_applied_before: "You have applied before",
    applied_reminder_description: "You have applied on {{applied_at}}. You can check your progress in My applications or apply this position again.",
    apply_again: "Apply again",
    go_to_job_page: "Go to job page",
    ok: "I know.",
};