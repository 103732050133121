import React from 'react';
import {ReactComponent as IconLocation} from "../../../assets/images/icon/icon_24_location_BG400.svg";
import {withNamespaces} from "react-i18next";

const JobCardLoader = props => {
    const { t } = props;

    return (
        <div className="job-card block">
            <div className="card-body">
                <div className="logo">
                    <div className="image-wrapper" />
                </div>
                <div className="subscribe subscribe-save-grey-24" />
                <h3 className="job-title">
                    <span className="text text-240">{`${t('loading')}...`}</span>
                </h3>
                <h5 className="employer-name text text-160">{`${t('loading')}...`}</h5>

                <ul className="company-items">
                    <li>
                        <h6 className="location">
                            <IconLocation />
                            <span className="text text-160">{`${t('loading')}...${t('loading')}...${t('loading')}...`}</span>
                        </h6>
                    </li>
                </ul>
            </div>

            <div className="card-bottom">
                <div className="item">
                    <p className="salary text text-160">{`${t('loading')}...`}</p>
                </div>
            </div>
        </div>
    )
};

export default withNamespaces(['general'])(JobCardLoader);