import React from "react";
import { NavLink } from "react-router-dom";
import Textarea from "react-textarea-autosize";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import {
	contain_not_only_whitespace,
	smoothly_scroll_to,
} from "../../../../assets/js/functions.js";
import User from "../../../models/user.js";
import { withNamespaces, Interpolate } from "react-i18next";
import Job from "../../../models/job";
import { CSSTransitionGroup } from "react-transition-group";
import InternalUri from "../../../models/internal_uri";
import modal_pop_up_warning from "../../../../assets/images/i050-pop-ups-warning.svg";
import i18n from "../../../../i18n";
import onboardRewardGif from "../../../../assets/images/onboard_reward.gif";
import facebookEvents from "../../../models/facebook_events";

const config = new Config();
const headers = new ApiHeaders();

class ApplyModal extends React.Component {
	constructor(props) {
		super(props);
		this.updateReferrals = this.updateReferrals.bind(this);
		this.fetchReferralcode = this.fetchReferralcode.bind(this);
		this.submitAddReferralCode = this.submitAddReferralCode.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleToggleChange = this.handleToggleChange.bind(this);
		this.handleReferralChange = this.handleReferralChange.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.cancelTheAction = this.cancelTheAction.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.continueSubmitForm = this.continueSubmitForm.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.toggleUploadSection = this.toggleUploadSection.bind(this);
		this.handleSetAsDefaultResume = this.handleSetAsDefaultResume.bind(this);
		this.state = {
			active_user: {},
			form_data: {},
			default_resume_attachment: null,
			set_as_default_resume: false,
			show_upload_resume: true,
			use_default_resume: true,
			active_job: {},
			active_referral_users: [],
			error_messages: {},
			loading: true,
			applying: false,
			sending_referral_code: false,
			applying_success_modal: {
				open: false,
			},
			empty_file_warning_modal: {
				open: false,
			},
		};
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		if (
			next_props.site_state.apply_modal.open !==
			this.props.site_state.apply_modal.open
		) {
			if (next_props.site_state.apply_modal.open) {
				this.setState({ loading: true });
				const job = new Job(next_props.site_state.apply_modal.job);
				const referrer = next_props.site_state.apply_modal.referrer;

				const { reduxTokenAuth } = next_props;
				let options = {
					method: "GET",
					url: config.api_url(
						`/users/${reduxTokenAuth.currentUser.attributes.id}`,
					),
					headers: headers.getItemsFromLocalStorage(),
					json: true,
				};
				axios(options)
					.then((response) => {
						headers.updateItemsToLocalStorage(response.headers);
						this.props.updateConnectionOffModal(false);

						const raw_user = response.data;
						const user = new User(raw_user);
						let referral_codes_selected_value = "none";
						if (Object.keys(referrer).length && referrer.id !== user.id) {
							referral_codes_selected_value = `${referrer.id}`;
						}

						let form_data = {
							attend_event: true,
							first_name: user.formatted_form_applier_first_name(),
							last_name: user.formatted_form_applier_last_name(),
							email: user.formatted_form_email(),
							phone: user.formatted_form_applier_phone(),
							resume: {},
							portfolio_url: user.formatted_portfolio_url(),
							cover_letter: "",
							referral_codes_selected_value,
							sync_to_my_profile: true,
							new_referral_code: "",
							public_to_employer: !!user.resume_setting.public_to_employer,
						};

						if (job.is_referralable()) {
							this.setState(
								(prev_state) => ({
									...prev_state,
									active_user: user,
									form_data,
									active_job: job,
									error_messages: {},
									default_resume_attachment: raw_user.default_resume_attachment,
									use_default_resume: raw_user.default_resume_attachment
										? true
										: false,
									show_upload_resume: raw_user.default_resume_attachment
										? false
										: true,
									applying_success_modal: {
										open: false,
									},
									empty_file_warning_modal: {
										open: false,
									},
								}),
								() =>
									this.updateReferrals(next_props.current_user.referral_codes),
							);
						} else {
							this.setState((prev_state) => ({
								...prev_state,
								active_user: user,
								form_data,
								active_job: job,
								error_messages: {},
								default_resume_attachment: raw_user.default_resume_attachment,
								applying_success_modal: {
									open: false,
								},
								empty_file_warning_modal: {
									open: false,
								},
								loading: false,
							}));
						}
					})
					.catch((error) => {
						if (typeof error.response === "undefined")
							this.props.updateConnectionOffModal(true);

						if (error.response) {
							headers.updateItemsToLocalStorage(error.response.headers);
							if (error.response.status === 401) window.location.reload();
						}

						this.setState((prev_state) => ({
							...prev_state,
							loading: false,
							error_messages:
								error.response &&
								error.response.data &&
								error.response.data.status === "error"
									? error.response.data.errors
									: {
											full_message:
												"There was an error. Please try again later.",
									  },
						}));

						smoothly_scroll_to(
							document.querySelector(".modal-wrapper"),
							0,
							200,
						);
					});
			}
		}
	}

	updateReferrals(prev_referral_codes) {
		if (prev_referral_codes.length === 0) {
			this.setState({ loading: false });
		} else {
			const checkReferrals = async () => {
				let active_referral_users = [],
					referral_codes = [];
				const { active_user } = this.state;
				for (let i = 0; i < prev_referral_codes.length; i++) {
					const referral_code = prev_referral_codes[i];

					if (active_user.formatted_referral_code() !== referral_code) {
						let formData = new FormData();
						formData.append("referral_code", referral_code);
						let options = {
							method: "POST",
							url: config.api_url("/users/referral_info"),
							headers: headers.getItemsFromLocalStorage("multipart/form-data"),
							data: formData,
							json: true,
						};
						let user = await this.fetchReferralcode(options, referral_code);
						if (user) {
							active_referral_users.push(user);
							referral_codes.push(referral_code);
						}
					}
				}
				this.setState(
					(prev_state) => ({
						...prev_state,
						active_referral_users,
						loading: false,
					}),
					() => this.props.updateCurrentUserReferralCodes(referral_codes),
				);
			};
			checkReferrals();
		}
	}

	fetchReferralcode = (options, referral_code) => {
		return axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				const user = new User({
					...response.data,
					referral_code,
				});
				return user;
			})
			.catch((error) => {
				if (typeof error.response === "undefined")
					this.props.updateConnectionOffModal(true);

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}
				return false;
			});
	};

	submitAddReferralCode = (e) => {
		e.preventDefault();
		const { form_data, sending_referral_code } = this.state;
		const { active_referral_users, active_user } = this.state;

		let referral_can_send = true;
		for (let i = 0; i < active_referral_users.length; i++) {
			if (
				active_referral_users[i].formatted_referral_code() ===
				form_data.new_referral_code
			) {
				referral_can_send = false;
				this.setState((prev_state) => ({
					...prev_state,
					form_data: {
						...prev_state.form_data,
						referral_codes_selected_value: `${active_referral_users[i].id}`,
						new_referral_code: "",
					},
					error_messages: {},
				}));
			}
		}
		if (active_user.formatted_referral_code() === form_data.new_referral_code) {
			referral_can_send = false;
			this.setState((prev_state) => ({
				...prev_state,
				error_messages: {
					new_referral_code: [
						this.props.t("you_can_t_use_your_referral_code_"),
					],
				},
			}));
		}

		if (
			contain_not_only_whitespace(form_data.new_referral_code) &&
			!sending_referral_code &&
			referral_can_send
		) {
			this.setState({ sending_referral_code: true });
			let formData = new FormData();
			formData.append("referral_code", form_data.new_referral_code);
			let options = {
				method: "POST",
				url: config.api_url("/users/referral_info"),
				headers: headers.getItemsFromLocalStorage("multipart/form-data"),
				data: formData,
				json: true,
			};
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					const user = new User({
						...response.data,
						referral_code: form_data.new_referral_code,
					});
					this.setState(
						(prev_state) => ({
							...prev_state,
							active_referral_users: [
								...prev_state.active_referral_users,
								user,
							],
							form_data: {
								...prev_state.form_data,
								referral_codes_selected_value: `${user.id}`,
								new_referral_code: "",
							},
							error_messages: {},
							sending_referral_code: false,
						}),
						() =>
							this.props.addCurrentUserReferralCode(
								form_data.new_referral_code,
							),
					);
				})
				.catch((error) => {
					if (typeof error.response === "undefined")
						this.props.updateConnectionOffModal(true);

					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					if (
						error.response &&
						error.response.data &&
						error.response.data.status === "error"
					) {
						this.setState((prev_state) => ({
							...prev_state,
							error_messages: {
								...prev_state.error_messages,
								new_referral_code: [
									error.response &&
									error.response.data &&
									error.response.data.status === "error"
										? error.response.data.errors.full_message
										: "There was an error. Please try again later.",
								],
							},
							sending_referral_code: false,
						}));
					}
				});
		}
	};

	handleInputChange = (e) => {
		e.preventDefault();
		let value = e.target.value;
		const dataset = e.target.dataset;
		const property_name = e.target.id;

		this.setState((prev_state) => {
			let error_messages = { ...prev_state.error_messages };
			const { t } = this.props;
			if (dataset.val) {
				let errors = [];

				if (dataset.valRequired) {
					if (value.length <= 0) errors.push(`${t("general:required")}`);
				}

				error_messages = {
					...error_messages,
					[property_name]: errors,
				};
			}

			return {
				...prev_state,
				form_data: {
					...prev_state.form_data,
					[property_name]: value,
				},
				error_messages,
			};
		});
	};

	handleToggleChange = (e, attr) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			form_data: {
				...prev_state.form_data,
				[attr]: !prev_state.form_data[attr],
			},
		}));
	};

	handleReferralChange(e, value) {
		e.preventDefault();
		this.setState((prev_state) => ({
			form_data: {
				...prev_state.form_data,
				referral_codes_selected_value: value,
			},
		}));
	}

	handleFileChange = (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		const property_name = e.target.id;

		if (file) {
			this.setState((prev_state) => ({
				...prev_state,
				set_as_default_resume: prev_state.default_resume_attachment
					? false
					: true,
				form_data: {
					...prev_state.form_data,
					[property_name]: {
						...prev_state.form_data[property_name],
						file,
					},
				},
			}));
		}
	};

	cancelTheAction = (e) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			empty_file_warning_modal: {
				open: false,
			},
		}));
	};

	toggleUploadSection = (e) => {
		e.preventDefault();
		const newValue = !this.state.show_upload_resume;
		this.setState({
			show_upload_resume: newValue,
			use_default_resume: newValue ? false : true,
		});
	};

	submitForm = (e) => {
		e.preventDefault();
		const { form_data, use_default_resume } = this.state;
		let has_errors = false;
		let error_messages = { ...this.state.error_messages };
		const { t } = this.props;
		let step = document.querySelector(".apply-modal");
		if (step) {
			const inputs = step.querySelectorAll("input");
			for (let i = 0; i < inputs.length; i++) {
				const input = inputs[i];
				let errors = [];

				if (input.dataset.val) {
					if (input.dataset.valRequired) {
						if (input.value.length <= 0) {
							errors.push(`${t("general:required")}`);
							has_errors = true;
						}
					}
				}

				error_messages = {
					...error_messages,
					[input.id]: errors,
				};
			}
		}

		if (has_errors) {
			this.setState({ error_messages });
			smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
		} else if (
			typeof form_data.resume.file === "undefined" &&
			use_default_resume === false
		) {
			this.setState((prev_state) => ({
				...prev_state,
				empty_file_warning_modal: {
					open: true,
				},
			}));
		} else {
			this.continueSubmitForm(e);
		}
	};

	uploadDefaultResume = () => {};

	continueSubmitForm = (e) => {
		e.preventDefault();

		const {
			form_data,
			active_job,
			applying,
			use_default_resume,
			set_as_default_resume,
			active_user,
		} = this.state;
		const public_to_employer = active_user.resume_setting.public_to_employer;
		const { reduxTokenAuth } = this.props;
		new facebookEvents({ eventName: "SubmitApplication" });
		if (!applying) {
			this.setState({ applying: true });
			let formData = new FormData();
			formData.append("job_application[job_id]", active_job.id);
			formData.append(
				"job_application[applier_name]",
				`${form_data.last_name}, ${form_data.first_name}`,
			);
			formData.append("job_application[applier_email]", form_data.email);
			formData.append("job_application[applier_phone]", form_data.phone);
			formData.append("job_application[cover_letter]", form_data.cover_letter);
			formData.append(
				"job_application[portfolio_url]",
				form_data.portfolio_url,
			);

			// 使用預設的履歷
			if (use_default_resume) {
				formData.append("resume_attachment[official]", use_default_resume);
			}

			// TODO: 檢查上傳 file 的大小？
			if (typeof form_data.resume.file !== "undefined")
				formData.append("resume_attachment[asset]", form_data.resume.file);
			if (
				active_job.is_referralable() &&
				form_data.referral_codes_selected_value !== "none"
			) {
				formData.append(
					"job_application[referrers][][id]",
					form_data.referral_codes_selected_value,
				);
			}

			if (form_data.attend_event && active_job.has_event())
				formData.append("job_application[event_id]", active_job.events[0].id);

			// set up utm info
			const { utm } = this.props;
			if (utm.source || utm.medium || utm.campaign) {
				formData.append("utm_source", utm.source);
				formData.append("utm_medium", utm.medium);
				formData.append("utm_campaign", utm.campaign);
			}

			let options = {
				method: "POST",
				url: config.api_url(
					`/users/${reduxTokenAuth.currentUser.attributes.id}/job_applications`,
				),
				headers: headers.getItemsFromLocalStorage("multipart/form-data"),
				data: formData,
				json: true,
			};

			if (
				form_data.sync_to_my_profile ||
				(set_as_default_resume && !use_default_resume) ||
				public_to_employer !== form_data.public_to_employer
			) {
				axios(options)
					.then((response) => {
						headers.updateItemsToLocalStorage(response.headers);
						this.props.updateConnectionOffModal(false);

						let formData = new FormData();
						if (set_as_default_resume) {
							formData.append(
								"user[resume_attachments_attributes][0][asset]",
								form_data.resume.file,
							);
							formData.append(
								"user[resume_attachments_attributes][0][official]",
								set_as_default_resume,
							);
						}
						if (form_data.sync_to_my_profile) {
							formData.append(
								"user[applied_profile_attributes][first_name]",
								form_data.first_name,
							);
							formData.append(
								"user[applied_profile_attributes][last_name]",
								form_data.last_name,
							);
							formData.append(
								"user[applied_profile_attributes][email]",
								form_data.email,
							);
							formData.append(
								"user[applied_profile_attributes][phone]",
								form_data.phone,
							);
							formData.append(
								"user[applied_profile_attributes][portfolio_url]",
								form_data.portfolio_url,
							);
						}

						// 允許活動搜尋
						formData.append(
							"user[resume_setting_attributes][public_to_employer]",
							form_data.public_to_employer,
						);

						let options = {
							method: "PUT",
							url: config.api_url(
								`/users/${reduxTokenAuth.currentUser.attributes.id}`,
							),
							headers: headers.getItemsFromLocalStorage("multipart/form-data"),
							data: formData,
							json: true,
						};

						return axios(options);
					})
					.then((response) => {
						headers.updateItemsToLocalStorage(response.headers);

						this.setState((prev_state) => ({
							...prev_state,
							error_messages: {},
							applying_success_modal: {
								open: true,
							},
							empty_file_warning_modal: {
								open: false,
							},
							applying: false,
						}));
					})
					.catch((error) => {
						if (typeof error.response === "undefined")
							this.props.updateConnectionOffModal(true);

						if (error.response) {
							headers.updateItemsToLocalStorage(error.response.headers);
							if (error.response.status === 401) window.location.reload();
						}

						if (
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
						) {
							this.setState((prev_state) => ({
								...prev_state,
								error_messages: error.response.data.errors,
								empty_file_warning_modal: {
									open: false,
								},
								applying: false,
							}));
						}

						smoothly_scroll_to(
							document.querySelector(".modal-wrapper"),
							0,
							200,
						);
					});
			} else {
				axios(options)
					.then((response) => {
						headers.updateItemsToLocalStorage(response.headers);
						this.props.updateConnectionOffModal(false);

						this.setState((prev_state) => ({
							...prev_state,
							error_messages: {},
							applying_success_modal: {
								open: true,
							},
							empty_file_warning_modal: {
								open: false,
							},
							applying: false,
						}));
					})
					.catch((error) => {
						if (typeof error.response === "undefined")
							this.props.updateConnectionOffModal(true);

						if (error.response) {
							headers.updateItemsToLocalStorage(error.response.headers);
							if (error.response.status === 401) window.location.reload();
						}

						if (
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
						) {
							this.setState((prev_state) => ({
								...prev_state,
								error_messages: error.response.data.errors,
								empty_file_warning_modal: {
									open: false,
								},
								applying: false,
							}));
						}

						smoothly_scroll_to(
							document.querySelector(".modal-wrapper"),
							0,
							200,
						);
					});
			}
		}
	};

	closeModal = (e) => {
		e.preventDefault();
		this.setState(
			(prev_state) => ({
				...prev_state,
				applying_success_modal: {
					open: false,
				},
				empty_file_warning_modal: {
					open: false,
				},
				error_messages: {},
				loading: true,
			}),
			() => {
				if (Object.keys(this.state.active_job).length !== 0) {
					const { active_job } = this.state;
					const internal_uri = new InternalUri();
					this.props.history.push(
						`${internal_uri.formatted_frontend_job_page_path(
							active_job.id,
							active_job.slug,
						)}?refresh=true`,
					);
				}
				this.props.updateApplyModal(false, {}, {});
			},
		);
	};

	handleSetAsDefaultResume = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({
			...prevState,
			set_as_default_resume: !prevState.set_as_default_resume,
		}));
	};

	render() {
		const {
			set_as_default_resume,
			show_upload_resume,
			default_resume_attachment,
			form_data,
			active_referral_users,
			error_messages,
			loading,
			applying,
			applying_success_modal,
			active_job,
			empty_file_warning_modal,
		} = this.state;
		const {
			handleSetAsDefaultResume,
			handleReferralChange,
			handleFileChange,
			submitAddReferralCode,
		} = this;
		const {
			toggleUploadSection,
			handleInputChange,
			handleToggleChange,
			submitForm,
			continueSubmitForm,
			cancelTheAction,
			closeModal,
		} = this;
		const { site_state } = this.props;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		let has_errors = false;
		const white_list = [
			"full_message",
			"resume_attachment.asset",
			"new_referral_code",
			"referrers",
		];
		for (let key in error_messages) {
			if (white_list.indexOf(key) === -1 && error_messages[key].length)
				has_errors = true;
		}

		return (
			<CSSTransitionGroup
				component="div"
				transitionName="modal-wrapper-slide"
				transitionEnterTimeout={300}
				transitionLeaveTimeout={300}>
				{site_state.apply_modal.open ? (
					<div className="modal-wrapper apply-modal-wrapper">
						<CSSTransitionGroup
							component="div"
							className="modal-inner-wrapper"
							transitionName="modal-slide"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={300}>
							<div className="modal-bg" onClick={(e) => closeModal(e)} />
							{!loading ? (
								<div className="modal apply-modal">
									<div className="btn-close" onClick={(e) => closeModal(e)} />
									<h4 className="modal-title">{t("general:apply")}</h4>
									<h6 className="job-title">{active_job.formatted_title()}</h6>
									<form>
										{error_messages.full_message ? (
											<h6 className="message error-message">
												{error_messages.full_message}
											</h6>
										) : (
											""
										)}
										{active_job.has_event() ? (
											<div
												className="event-switcher-wrapper"
												onClick={(e) => handleToggleChange(e, "attend_event")}>
												<div
													className="banner"
													style={{
														backgroundImage: `url(${active_job.events[0].get_banner_url()})`,
													}}
													data-event-category="316_modal_apply_banner"
												/>
												<div
													className="event-switcher"
													data-event-category="317_modal_apply_switch">
													<input
														className="radio-input"
														type="radio"
														checked={form_data.attend_event}
														readOnly
													/>
													<label htmlFor="remote" className="radio-switcher" />
													<div className="text">
														<p>
															{form_data.attend_event
																? t("events:yes__i_m_here_for__", {
																		event_name: t(
																			active_job.events[0].get_i18n_key(),
																		),
																  })
																: t(
																		"events:no__i_don_t_want_to_attend_this_event_",
																  )}
														</p>
													</div>
												</div>
											</div>
										) : (
											""
										)}
										{/* remember to remove id 39630  */}
										{active_job.has_event() && active_job.id !== 39630 ? (
											<div className="form-row">
												<input
													type="checkbox"
													className="checkbox-input"
													id="public_to_employer"
													checked={form_data.public_to_employer}
													readOnly
												/>
												<label
													htmlFor="public_to_employer"
													className="checkbox"
													onClick={(e) =>
														handleToggleChange(e, "public_to_employer")
													}
													data-event-category="318_modal_sync">
													<p>
														{t("general:open_resume_for_employers", {
															event_name:
																i18n.language === "zh-TW"
																	? active_job.events[0].name_zh_tw
																	: active_job.events[0].name_en,
														})}
														<br />
														<small>
															{t("general:same_setting_as_upload_resume")}
														</small>
													</p>
												</label>
											</div>
										) : null}

										<label htmlFor="first_name">
											<h5>{t("name")}</h5>
										</label>
										<div
											className={`form-row form-row-2 form-row-name ${
												i18n.language === "zh-TW" ? "last-first" : "first-last"
											}`}>
											<div className="form-col">
												<div className="form-row form-row-dire-col">
													<div className="form-col">
														<input
															className={`input ${
																error_messages.first_name &&
																error_messages.first_name.length
																	? "input-danger"
																	: ""
															}`}
															type="text"
															value={form_data.first_name}
															placeholder={t("first_name")}
															onChange={(e) => handleInputChange(e)}
															id="first_name"
															data-val="true"
															data-val-required="true"
														/>
													</div>
													<div className="form-col">
														{error_messages.first_name &&
														error_messages.first_name.length ? (
															<h6 className="message error-message">
																{error_messages.first_name.join(", ")}
															</h6>
														) : (
															""
														)}
													</div>
												</div>
											</div>
											<div className="form-col">
												<div className="form-row form-row-dire-col">
													<div className="form-col">
														<input
															className={`input ${
																error_messages.last_name &&
																error_messages.last_name.length
																	? "input-danger"
																	: ""
															}`}
															type="text"
															value={form_data.last_name}
															placeholder={t("last_name")}
															onChange={(e) => handleInputChange(e)}
															id="last_name"
															data-val="true"
															data-val-required="true"
														/>
													</div>
													<div className="form-col">
														{error_messages.last_name &&
														error_messages.last_name.length ? (
															<h6 className="message error-message">
																{error_messages.last_name.join(", ")}
															</h6>
														) : (
															""
														)}
													</div>
												</div>
											</div>
										</div>

										<label htmlFor="email">
											<h5>{t("email")}</h5>
										</label>
										<div className="form-row">
											<div className="form-col">
												<input
													className="input input-readonly"
													type="email"
													value={form_data.email}
													placeholder={t("email")}
													id="email"
													readOnly
												/>
											</div>
										</div>

										<label htmlFor="phone">
											<h5>{t("phone_number")}</h5>
										</label>
										<div className="form-row form-row-dire-col">
											<div className="form-col">
												<input
													className={`input ${
														error_messages.phone && error_messages.phone.length
															? "input-danger"
															: ""
													}`}
													type="text"
													value={form_data.phone}
													placeholder={t("your_phone_number")}
													onChange={(e) => handleInputChange(e)}
													id="phone"
													data-val="true"
													data-val-required="true"
												/>
											</div>
											<div className="form-col">
												{error_messages.phone && error_messages.phone.length ? (
													<h6 className="message error-message">
														{error_messages.phone.join(", ")}
													</h6>
												) : (
													""
												)}
											</div>
										</div>

										{default_resume_attachment && !show_upload_resume ? (
											<div className="form-row form-row-dire-col">
												{/*{JSON.stringify(default_resume_attachment,null,4) }*/}
												<div className="form-col">
													<h5>{t("general:resume___cv_file")}</h5>
													<h5>
														{t("general:file_name")}{" "}
														{default_resume_attachment.asset.filename}
													</h5>
													<a
														href={default_resume_attachment.asset.url}
														target="_blank"
														rel="noopener noreferrer"
														className="btn btn-smallest btn-fill btn-flat">
														{t("general:view_default_resume")}
													</a>
													{!show_upload_resume ? (
														<button
															onClick={toggleUploadSection}
															className="btn btn-smallest btn-flat btn-hollow btn-extend">
															{t("general:use_another_resume")}
														</button>
													) : null}
												</div>
											</div>
										) : null}
										{show_upload_resume ? (
											<React.Fragment>
												<label htmlFor="resume" className="lines">
													<h5>{t("general:resume___cv_file")}</h5>
													<h6>
														{form_data.resume &&
														typeof form_data.resume.file !== "undefined"
															? form_data.resume.file.name
															: t("general:no_resume___cv_file")}
													</h6>
												</label>
												<div className="form-row">
													<input
														className="input-upload-file"
														id="resume"
														type="file"
														accept={
															`.pdf, ` +
															`.doc, ` +
															`.docx, ` +
															`application/pdf, ` +
															`application/msword, ` +
															`application/vnd.openxmlformats-officedocument.wordprocessingml.document`
														}
														onChange={(e) => handleFileChange(e)}
													/>
													<label
														htmlFor="resume"
														className="btn btn-larger btn-flat btn-hollow btn-upload-file">
														{t("general:choose_file")}
													</label>
												</div>
												<div>
													<input
														id="set_as_default_resume"
														checked={set_as_default_resume}
														name="set_as_default_resume"
														value="set_as_default_resume"
														readOnly
														type="checkbox"
													/>
													&nbsp;
													<label
														onClick={(e) => {
															handleSetAsDefaultResume(e);
														}}
														htmlFor="set_as_default_resume">
														{t("general:set_as_default_resume")}
													</label>
												</div>
												{default_resume_attachment && show_upload_resume ? (
													<button
														onClick={toggleUploadSection}
														className="btn btn-smallest btn-flat btn-hollow btn-extend">
														{t("general:use_default_resume")}
													</button>
												) : null}
											</React.Fragment>
										) : null}

										<label htmlFor="portfolio_url">
											<h5>{t("general:reference_link")}</h5>
										</label>
										<div className="form-row form-row-dire-col">
											<div className="form-col">
												<input
													className={`input ${
														error_messages.portfolio_url &&
														error_messages.portfolio_url.length
															? "input-danger"
															: ""
													}`}
													type="text"
													value={form_data.portfolio_url}
													placeholder={t("https___linkedin_com_in_your_name")}
													onChange={(e) => handleInputChange(e)}
													id="portfolio_url"
													data-val="true"
												/>
											</div>
											<div className="form-col">
												{error_messages.portfolio_url &&
												error_messages.portfolio_url.length ? (
													<h6 className="message error-message">
														{error_messages.portfolio_url.join(", ")}
													</h6>
												) : (
													""
												)}
											</div>
										</div>

										<label htmlFor="cover_letter">
											<h5>{t("cover_letter")}</h5>
										</label>
										<div className="form-row">
											<Textarea
												className="textarea"
												minRows={5}
												maxRows={10}
												placeholder={t("write_cover_letter_here_")}
												value={form_data.cover_letter}
												onChange={(e) => handleInputChange(e)}
												id="cover_letter"
											/>
										</div>
										{active_job.is_referralable() ? (
											<div>
												<label htmlFor="referral">
													<h5>{t("referral")}</h5>
												</label>
												<div className="form-row form-row-dire-col">
													<div className="referral-codes">
														{active_referral_users.length !== 0
															? active_referral_users.map(
																	(referral_user, i) => (
																		<div key={i}>
																			<input
																				name="referral_code"
																				className="radio-input"
																				type="radio"
																				id={`referral_code_${referral_user.formatted_referral_code()}`}
																				checked={
																					form_data.referral_codes_selected_value ===
																					`${referral_user.id}`
																				}
																				readOnly={true}
																			/>
																			<label
																				htmlFor={`referral_code_${referral_user.formatted_referral_code()}`}
																				className="radio referral-user"
																				onClick={(e) =>
																					handleReferralChange(
																						e,
																						`${referral_user.id}`,
																					)
																				}>
																				<div className="avatar">
																					<div
																						className="image-wrapper"
																						style={{
																							backgroundImage: `url(${referral_user.formatted_avatar_applying_url()})`,
																						}}
																					/>
																				</div>
																				<p>{referral_user.formatted_name()}</p>
																			</label>
																		</div>
																	),
															  )
															: ""}

														<input
															name="referral_code"
															className="radio-input"
															type="radio"
															id="referral_code_none"
															checked={
																form_data.referral_codes_selected_value ===
																"none"
															}
															readOnly={true}
														/>
														<label
															htmlFor="referral_code_none"
															className="radio"
															onClick={(e) => handleReferralChange(e, `none`)}>
															<p>{t("i_want_to_apply_without_a_referee_")}</p>
														</label>

														<div className="add-referral-code">
															<p>{t("or_enter_a_referral_code")}</p>
															<div className="input-btn-wrapper">
																<input
																	className={`input ${
																		error_messages.new_referral_code &&
																		error_messages.new_referral_code.length
																			? "input-danger"
																			: ""
																	}`}
																	type="text"
																	placeholder={`${t("general:e_g__")} m33tjb`}
																	value={form_data.new_referral_code}
																	onChange={(e) => handleInputChange(e)}
																	id="new_referral_code"
																/>
																<div
																	className="btn btn-larger btn-flat btn-fill"
																	onClick={(e) => submitAddReferralCode(e)}>
																	<h5>{t("general:ok")}</h5>
																</div>
															</div>
															{error_messages.new_referral_code &&
															error_messages.new_referral_code.length ? (
																<h6 className="message error-message">
																	{error_messages.new_referral_code.join(", ")}
																</h6>
															) : (
																""
															)}
														</div>
													</div>
												</div>
												<div className="form-row" />
											</div>
										) : (
											""
										)}

										<div className="form-row">
											<input
												type="checkbox"
												className="checkbox-input"
												id="sync-to-my-profile"
												checked={form_data.sync_to_my_profile}
												readOnly
											/>
											<label
												htmlFor="sync-to-my-profile"
												className="checkbox"
												onClick={(e) =>
													handleToggleChange(e, "sync_to_my_profile")
												}
												data-event-category="318_modal_sync">
												<p>{t("sync_to_my_profile")}</p>
											</label>
										</div>
									</form>
									<div className="buttons-wrapper">
										{applying ? (
											<button
												onClick={(e) => e.preventDefault()}
												className="btn btn-flat btn-ghost disabled">
												<h5>{t("general:cancel")}</h5>
											</button>
										) : (
											<button
												className="btn btn-flat btn-ghost"
												onClick={(e) => closeModal(e)}
												data-event-category="319_modal_cancel">
												<h5>{t("general:cancel")}</h5>
											</button>
										)}
										{applying || has_errors ? (
											<button
												onClick={(e) => e.preventDefault()}
												className="btn btn-flat btn-fill disabled">
												<h5>{t("general:apply")}</h5>
											</button>
										) : (
											<button
												className="btn btn-flat btn-fill"
												onClick={(e) => submitForm(e)}
												data-event-category="320_modal_apply">
												<h5>{t("general:apply")}</h5>
											</button>
										)}
									</div>
								</div>
							) : (
								""
							)}
						</CSSTransitionGroup>

						<CSSTransitionGroup
							component="div"
							transitionName="modal-wrapper-with-modal-slide"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={300}>
							{empty_file_warning_modal.open ? (
								<div className="modal-wrapper double-confirm-modal-wrapper">
									<div className="modal-inner-wrapper">
										<div className="modal-bg" />
										<div className="modal double-confirm-modal with-pop-up-icon">
											<div
												className="image-wrapper"
												style={{
													backgroundImage: `url(${modal_pop_up_warning}`,
												}}
											/>
											<div className="content">
												<h4 className="modal-title">
													{t(
														"no_resume___cv_file_is_attached_do_you_want_to_apply_without_an_attachment_",
													)}
												</h4>
												<div className="buttons-wrapper">
													<button
														className="btn btn-larger btn-flat btn-ghost"
														onClick={(e) => cancelTheAction(e)}>
														<h5>{t("general:back")}</h5>
													</button>
													<button
														className="btn btn-larger btn-flat btn-fill"
														onClick={(e) => continueSubmitForm(e)}>
														<h5>{t("general:continue")}</h5>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								""
							)}
							{applying_success_modal.open ? (
								<div className="modal-wrapper applying-success-modal-wrapper">
									<div className="modal-inner-wrapper">
										<div className="modal-bg" onClick={(e) => closeModal(e)} />
										<div className="modal applying-success-modal">
											<h4 className="modal-title">
												{t("applySuccessfullyTitle")}
											</h4>
											<p>
												<Interpolate
													i18nKey="applySuccessfullyDescription"
													useDangerouslySetInnerHTML={true}
													my_applications={
														<NavLink
															to={internal_uri.formatted_userpanel_my_applications_page_path()}
															className="link"
															target="_blank">
															{t("general:my_applications")}
														</NavLink>
													}
												/>
											</p>
											{active_job.is_referralable() ? (
												<div>
													<div className="separator">
														<span>{t("general:also")}</span>
													</div>
													<div className="onboard-reward-reminding-wrapper">
														<div>
															<img src={onboardRewardGif} alt="" />
														</div>
														<p
															dangerouslySetInnerHTML={{
																__html: t(
																	"applySuccessfullyOnboardRewardReminding",
																),
															}}
														/>
													</div>
												</div>
											) : (
												""
											)}
											<button
												className="btn btn-larger btn-flat btn-fill"
												onClick={(e) => closeModal(e)}>
												<h5>{t("general:ok")}</h5>
											</button>
										</div>
									</div>
								</div>
							) : (
								""
							)}
						</CSSTransitionGroup>
					</div>
				) : (
					""
				)}
			</CSSTransitionGroup>
		);
	}
}

export default withNamespaces(["frontend_modals", "general", "events"])(
	ApplyModal,
);
