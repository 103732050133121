import { NavLink } from "react-router-dom";
import React from "react";
import InternalUri from "../../../models/internal_uri";
import { withNamespaces } from "react-i18next";
import DashboardMenuInner from "../../dashboard/DashboardMenuInner";

const TopBar = props => {
    const internal_uri = new InternalUri();
    const { t } = props;

    return (
        <div className="topbar">
            <div className="container">
                <div className="inner-wrapper">
                    <ul>
                        {
                            DashboardMenuInner.map(({ name, path, category },idx) => (
                                <li key={idx}>
                                    <NavLink
                                        exact
                                        to={internal_uri[path]()}
                                        data-event-category={category.topBar}
                                    >
                                        <h6>{t(name)}</h6>
                                    </NavLink>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces(['general'])(TopBar);