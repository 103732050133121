import Abstract from './abstract';
import User from './user.js';

class Roleship extends Abstract {
    constructor(attrs) {
        super(attrs);

        let raw_member = attrs.member;
        if(raw_member) {
            delete attrs['member'];
            this._build_association({
                name:  'member',
                klass: User,
                attrs: raw_member
            });
        }
    };

    formatted_title() {
        if(this.title) return this.title;
        return this.member.formatted_name();
    };

    formatted_form_title() {
        if(this.title) return this.title;
        return '';
    };
}

export default Roleship;