import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import Gallery from "./../Gallery.jsx";
import Map from "./../Map.jsx";
import {
	JobCardWrapper,
	UseReferralUrlWrapper,
} from "./../../../containers/general.jsx";
import Config from "./../../../models/config.js";
import ApiHeaders from "./../../../models/api_headers.js";
import Employer from "./../../../models/employer.js";
import User from "../../../models/user.js";
import {
	get_value_from_query_string,
	page_smothly_scroll_to,
} from "../../../../assets/js/functions.js";
import { withNamespaces } from "react-i18next";
import icon_refer from "./../../../../assets/images/icon/icon_24_refer_B100.svg";
import { ReactComponent as IconPeople } from "./../../../../assets/images/icon/icon_24_people_BG400.svg";
import { ReactComponent as IconLocation } from "./../../../../assets/images/icon/icon_24_location_BG400.svg";
import { ReactComponent as IconLink } from "./../../../../assets/images/icon/icon_24_link_BG400.svg";
import InternalUri from "../../../models/internal_uri";
import DeviceDetector from "../../../models/device_detector";
import JobCardLoader from "../../general/JobCardLoader";
import MarketingBanner from "../MarketingBanner";
import facebookEvents from "../../../models/facebook_events";
import AdEmployer from "../modals/AdEmployer";
const config = new Config();
const headers = new ApiHeaders();

class EmployerPage extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.mapsSelector = this.mapsSelector.bind(this);
		this.changeActiveMap = this.changeActiveMap.bind(this);
		this.goLocation = this.goLocation.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getEmployerAd = this.getEmployerAd.bind(this);
		this.state = {
			employer: {},
			referral_user: {},
			active_addresses_index: 0,
			loading: true,
			loading_current_user_data: false,
			error_messages: {},
			ads: [],
		};

		// this.eventMarketingParameters = {
		//     utm_source: "Meet.jobs",
		//     utm_medium: "Employer_Page_Banner",
		//     utm_campaign: ""
		// };
	}

	componentDidMount() {
		this._isMounted = true;
		new facebookEvents({ eventName: "ViewContent" });
		const query = decodeURI(this.props.location.search);
		if (query) {
			let referral_code = get_value_from_query_string("referral_code", query);
			if (referral_code) {
				let formData = new FormData();
				formData.append("referral_code", referral_code);
				let options = {
					method: "POST",
					url: config.api_url("/users/referral_info"),
					headers: headers.getItemsFromLocalStorage("multipart/form-data"),
					data: formData,
					json: true,
				};
				axios(options)
					.then((response) => {
						headers.updateItemsToLocalStorage(response.headers);
						this.props.updateConnectionOffModal(false);

						if (this._isMounted) {
							const raw_user = response.data;
							const user = new User({
								...raw_user,
								referral_code,
							});

							this.setState(
								{
									referral_user: user,
								},
								() => this.props.addCurrentUserReferralCode(referral_code),
							);
						}
					})
					.catch((error) => {
						if (error.response) {
							headers.updateItemsToLocalStorage(error.response.headers);
							if (error.response.status === 401) window.location.reload();
						}

						if (this._isMounted) {
							if (typeof error.response === "undefined")
								this.props.updateConnectionOffModal(true);

							this.setState((prev_state) => ({
								...prev_state,
								error_messages:
									error.response &&
									error.response.data &&
									error.response.data.status === "error"
										? error.response.data.errors
										: {
												full_message:
													"There was an error. Please try again later.",
										  },
							}));

							page_smothly_scroll_to(0, 300);
						}
					});
			}
		}

		const employer_id = this.props.match.params.id_slug.split("-")[0];
		this.setState(
			{
				loading: true,
			},
			() => this.fetchData(employer_id),
		);
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		const employer_id = this.props.match.params.id_slug.split("-")[0];

		const { reduxTokenAuth } = next_props;
		if (
			reduxTokenAuth.currentUser.isSignedIn !==
			this.props.reduxTokenAuth.currentUser.isSignedIn
		) {
			this.setState(
				{
					loading_current_user_data: true,
				},
				() => this.fetchData(employer_id),
			);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	changeActiveMap = (e, i) => {
		e.preventDefault();
		this.setState({ active_addresses_index: i });
	};

	goLocation(e, query_string) {
		e.preventDefault();
		e.stopPropagation();
		const internal_uri = new InternalUri();
		this.props.updateSearchKeywords("", query_string);
		this.props.history.push(internal_uri.formatted_frontend_jobs_page_path());
	}

	mapsSelector = (e, lat, lng) => {
		e.preventDefault();
		lat = parseFloat(lat);
		lng = parseFloat(lng);
		const device_detector = new DeviceDetector();
		window.open(
			`${
				device_detector.is_mobile_or_tablet() ? "maps" : "https"
			}://maps.google.com/maps?daddr=${lat},${lng}&amp;ll=`,
		);
	};

	fetchData(employer_id) {
		const url = config.api_url(
			`/employers/${employer_id}?include=addresses,published_jobs,photos`,
		);
		const options = {
			method: "GET",
			url: url,
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					const raw_employer = response.data;
					const employer = new Employer(raw_employer);

					this.setState((prev_state) => ({
						...prev_state,
						employer,
						loading: false,
						loading_current_user_data: false,
						error_messages: {},
					}));
				}
				this.getEmployerAd();
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					const internal_uri = new InternalUri();
					if (error.response.status === 401) window.location.reload();
					if (error.response.status === 404)
						this.props.history.push(
							internal_uri.formatted_frontend_error_404_page_path(),
						);
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined")
						this.props.updateConnectionOffModal(true);

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						loading_current_user_data: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));

					page_smothly_scroll_to(0, 300);
				}
			});
	}

	getEmployerAd() {
		const url = config.api_url(`/ads?position=employers`);
		const options = {
			method: "GET",
			url: url,
		};
		axios(options)
			.then((response) => {
				const ads = [...response.data];
				this.setState({ ads });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		const {
			employer,
			referral_user,
			active_addresses_index,
			loading_current_user_data,
			loading,
			error_messages,
		} = this.state;
		const { changeActiveMap, goLocation, mapsSelector } = this;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		if (loading || !Object.keys(employer).length) {
			return (
				<div className="loading-skeleton-wrapper">
					<div className="page-employer content-wrapper with-gradient blue-to-cyan">
						<div className="container">
							<div className="tablet-wrapper">
								<div className="employer-header block">
									<div className="logo">
										<div className="image-wrapper" />
									</div>
									<h2 className="text text-240">{`${t("loading")}...`}</h2>
									<ul className="company-items">
										<li>
											<h6 className="num-of-employees">
												<IconPeople />
												<span className="text text-80">{`${t(
													"loading",
												)}...`}</span>
											</h6>
										</li>
										<li>
											<h6 className="location">
												<IconLocation />
												<span className="text text-80">{`${t(
													"loading",
												)}...`}</span>
											</h6>
										</li>
										<li>
											<h6 className="website">
												<IconLink />
												<span className="text text-80">{`${t(
													"loading",
												)}...`}</span>
											</h6>
										</li>
									</ul>
								</div>
								<div className="row">
									<div className="col-xs-12 col-sm-5 col-md-4 col-sm-push-7 col-md-push-8">
										<div className="gallery-wrapper block">
											<div className="gallery">
												<div className="media-item-wrapper media-item-wrapper-three">
													<div className="media-item">
														<div className="image-wrapper" />
													</div>
													<div className="media-item">
														<div className="image-wrapper" />
													</div>
													<div className="media-item">
														<div className="image-wrapper" />
													</div>
												</div>
											</div>
										</div>
										<div className="location block">
											<div>
												<div
													className="image-wrapper"
													style={{ paddingBottom: "0", height: "240px" }}
												/>
											</div>

											<div className="content can_view_on_map">
												<h4>{t("address")}</h4>
												<h6 className="text text-160">{`${t(
													"loading",
												)}...`}</h6>
												<button
													onClick={(e) => e.preventDefault()}
													className="btn btn-larger btn-flat btn-ghost skip-loading-skeleton">
													<h5>{t("general:view_on_map")}</h5>
												</button>
											</div>
										</div>
									</div>
									<div className="col-xs-12 col-sm-7 col-md-8 col-sm-pull-5 col-md-pull-4">
										<div className="information block clearfix">
											<h4>{t("employer_info")}</h4>
											<p className="slogan from-editor">
												<span className="text text-240 text-center">{`${t(
													"loading",
												)}...`}</span>
											</p>
											<div className="from-editor">
												<p className="text text-240">{`${t("loading")}...`}</p>
												<p className="text text-160">{`${t("loading")}...`}</p>
												<p className="text text-480">{`${t("loading")}...`}</p>
												<p className="text text-240">{`${t("loading")}...`}</p>
												<p className="text text-160">{`${t("loading")}...`}</p>
											</div>
										</div>
										<h4 className="title-outside">{t("job_openings")}</h4>
										<div className="job-cards">
											<JobCardLoader />
											<JobCardLoader />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="page-employer content-wrapper with-gradient blue-to-cyan">
				<UseReferralUrlWrapper />
				<Helmet>
					<title>{employer.formatted_name()}｜Meet.jobs</title>
				</Helmet>
				<div className="container">
					<div className="tablet-wrapper">
						{error_messages.full_message ? (
							<h6 className="message error-message">
								{error_messages.full_message}
							</h6>
						) : (
							""
						)}
						<AdEmployer
							employer={employer}
							ads={this.state.ads}
							lang={this.props.lng}
						/>
						<div className="employer-header block">
							<div className="logo">
								<div
									className="image-wrapper"
									style={{
										backgroundImage: `url('${employer.formatted_logo_dashboard_url()}')`,
									}}
								/>
							</div>
							<h2>{employer.formatted_name()}</h2>
							<ul className="company-items">
								{/*<li>*/}
								{/*<h6 className="category">*/}
								{/*<IconIndustry />*/}
								{/*{employer.formatted_industry()}*/}
								{/*</h6>*/}
								{/*</li>*/}
								<li>
									<h6 className="num-of-employees">
										<IconPeople />
										{employer.formatted_company_size(t)}
									</h6>
								</li>
								<li>
									{employer.has_addresses() ? (
										<button
											onClick={(e) =>
												goLocation(e, employer.formatted_primary_location(t))
											}
											data-event-category="402_link_location">
											<h6 className="location">
												<IconLocation />
												{employer.formatted_primary_location(t)}
											</h6>
										</button>
									) : (
										<h6 className="location">
											<IconLocation />
											{employer.formatted_primary_location(t)}
										</h6>
									)}
								</li>
								<li>
									{employer.has_website_url() ? (
										<a
											href={employer.url}
											target="_blank"
											rel="noopener noreferrer"
											data-event-category="403_employer_website">
											<h6 className="website">
												<IconLink />
												{employer.formatted_website_url()}
											</h6>
										</a>
									) : (
										<h6 className="website">
											<IconLink />
											{employer.formatted_website_url()}
										</h6>
									)}
								</li>
							</ul>

							{employer.is_referralable() ? (
								<NavLink
									to={internal_uri.formatted_frontend_refer_page_path(
										"employer",
										employer.id,
									)}
									className="btn btn-larger btn-flat btn-ghost btn-with-icon"
									data-event-category="409_refer_employer">
									<h5>
										<img src={icon_refer} alt="" />
										{t("general:refer_this_employer")}
									</h5>
								</NavLink>
							) : (
								""
							)}
						</div>
						<div className="row">
							<div className="col-xs-12 col-sm-5 col-md-4 col-sm-push-7 col-md-push-8">
								{employer.has_event() ? (
									<MarketingBanner
										ads={this.state.ads}
										type="employers"
										url={employer.events[0].get_url()}
										banner_url={employer.events[0].get_banner_url()}
										content={t(employer.events[0].get_i18n_key())}
										// parameters={{...this.eventMarketingParameters, utm_campaign: t(employer.events[0].get_i18n_key())}}
										eventCategory="416_marketing_employer"
									/>
								) : (
									""
								)}
								{employer.has_photo() || employer.get_youtube_vcode() ? (
									<div className="gallery-wrapper block">
										<Gallery
											images={employer.photos}
											alt={`${employer.formatted_name()}${t(
												"_is_hiring_on_meet_jobs_",
											)}`}
											youtube_vcode={employer.get_youtube_vcode()}
											showThumbnails
										/>
									</div>
								) : (
									""
								)}
								{employer.has_addresses() ? (
									<div className="location block">
										{employer.has_address_has_latitude_and_longitude() &&
										employer.addresses[
											active_addresses_index
										].has_latitude_and_longitude() ? (
											<div>
												<Map
													isMarkerShown
													lat={employer.addresses[
														active_addresses_index
													].formatted_latitude()}
													lng={employer.addresses[
														active_addresses_index
													].formatted_longitude()}
													loadingElement={<div style={{ height: `100%` }} />}
													containerElement={<div style={{ height: `240px` }} />}
													mapElement={<div style={{ height: `100%` }} />}
												/>
											</div>
										) : (
											""
										)}
										{employer.addresses.map((address, i) => (
											<div
												className={`content ${
													active_addresses_index === i ? "active" : ""
												} ${
													address.has_latitude_and_longitude()
														? "can_view_on_map"
														: ""
												}`}
												key={i}
												onClick={(e) => changeActiveMap(e, i)}>
												<h4>{t("address")}</h4>
												<h6>{address.formatted_address()}</h6>
												<button
													className="btn btn-larger btn-flat btn-ghost"
													onClick={(e) =>
														mapsSelector(
															e,
															address.formatted_latitude(),
															address.formatted_longitude(),
														)
													}
													data-event-category="408_map">
													<h5>{t("general:view_on_map")}</h5>
												</button>
											</div>
										))}
									</div>
								) : (
									""
								)}
							</div>
							<div className="col-xs-12 col-sm-7 col-md-8 col-sm-pull-5 col-md-pull-4">
								<div className="information block clearfix">
									<h4>{t("employer_info")}</h4>
									{employer.has_slogan() ? (
										<p className="slogan from-editor">{employer.slogan}</p>
									) : (
										""
									)}
									{employer.description ? (
										<div
											className="from-editor"
											dangerouslySetInnerHTML={{
												__html: employer.description,
											}}
										/>
									) : (
										t("general:_not_provided_")
									)}
								</div>
								<h4 className="title-outside">{t("job_openings")}</h4>
								{loading_current_user_data ? (
									<p>{`${t("general:loading")}...`}</p>
								) : employer.has_published_jobs() ? (
									<div className="job-cards">
										{employer.published_jobs.map((job, i) => (
											<JobCardWrapper
												key={i}
												job={job}
												referral_code={
													Object.keys(referral_user).length
														? referral_user.referral_code
														: null
												}
												eventCategories={{
													whole: "414_employer_other_jobs",
													employer: "417_card_employer_employer",
													location: "418_card_employer_location",
													save: "419_card_employer_save",
												}}
											/>
										))}
									</div>
								) : (
									<p>{t("no_jobs_")}</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withNamespaces(["frontend_employer_page", "general"])(
	EmployerPage,
);
