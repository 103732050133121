export default {
    refer_this_job_to_your_friends__and_get___reward_: "將這份工作推薦給朋友，<br />順利就職後就可獲得 {{reward}} 的推薦感謝金。",
    refer_this_employer_to_your_friends_and_get_reward_: "推薦這家徵才企業給您的朋友，並獲得推薦獎金。",

    if_you_refer_anonymously__you_will_not_get_any_referral_reward_: "請注意，如果您匿名推薦，就損失了獲得高額推薦感謝金的機會唷！",
    or___here: "或現在就{{log_in}}",

    your_referral_link: "你的推薦連結",
    you_can_manage_your_referral_at___: "你可以在{{my_referrals}}裡管理。",
    share_with_: "分享給朋友：",

    // mail
    a_great_job_you_might_be_interested_: "A Great Job You Might Be Interested!",
    hello_my_friend__i_think_this_is_a_good_job_opportunity_that_you_might_be_interested_: "Hello my friend, I think this is a good job opportunity that you might be interested!",

    // social
    hi__i_found_a_great_job_you_might_be_interested_come_and_check_it_out_: "嗨！我覺得這個工作機會很不錯，推薦你看看～",
};