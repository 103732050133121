import React from "react";

const Repeat = (props) => {
    let items = [];
    for (let i = 0; i < props.numTimes; i++) {
        items.push(props.children(i));
    }
    return <div>{items}</div>;
};

export default Repeat;