import React from "react";
import { Editor as TheEditor, EditorState, RichUtils, CompositeDecorator, Modifier } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import icon_bold from "../../../assets/images/icon/icon_24_b_BG400.svg";
import icon_head from "../../../assets/images/icon/icon_24_h_BG400.svg";
import icon_link from "../../../assets/images/icon/icon_24_link_BG400.svg";
import icon_ol from "../../../assets/images/icon/icon_24_ol_BG400.svg";
import icon_ul from "../../../assets/images/icon/icon_24_ul_BG400.svg";
import icon_ok from "../../../assets/images/icon/icon_28_ok_white.svg";
import {contain_not_only_whitespace, raw_html_to_editor_content} from "../../../assets/js/functions";
import {withNamespaces} from "react-i18next";

class Editor extends React.Component {

    constructor(props) {
        super(props);
        this.focus = () => this.refs.editor.focus();
        this.onChange = this.onChange.bind(this);
        this.handleKeyCommand = (command) => this._handleKeyCommand(command);
        this.handlePastedText = this.handlePastedText.bind(this);
        this.onTab = (e) => this._onTab(e);
        this.toggleBlockType = (type) => this._toggleBlockType(type);
        this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
        this.state = {
            editorState: EditorState.createEmpty(),
            showURLInput: false,
            urlValue: '',
            content_has_anchor_tag: false,
        };
        this.html = ``;

        this.decorator = new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: Link,
            },
        ]);
        this.promptForLink = this._promptForLink.bind(this);
        this.onURLChange = (e) => this.setState({urlValue: e.target.value});
        this.confirmLink = this._confirmLink.bind(this);
        this.cancelLink = this._cancelLink.bind(this);
        this.onLinkInputKeyDown = this._onLinkInputKeyDown.bind(this);
        this.removeLink = this._removeLink.bind(this);
    };

    _promptForLink(e) {
        e.preventDefault();
        e.stopPropagation();
        let { editorState } = this.state;
        const selection = editorState.getSelection();
        if(!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            let url = '';
            let content_has_anchor_tag = false;
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
                content_has_anchor_tag = true;
            }
            this.setState({
                // editorState: RichUtils.toggleInlineStyle(editorState, 'HIGHLIGHT'),
                showURLInput: true,
                urlValue: url,
                content_has_anchor_tag
            });
        }
    };

    _confirmLink(e) {
        e.preventDefault();
        e.stopPropagation();
        const {editorState, urlValue} = this.state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {
                url: urlValue,
                target: '_blank',
                className: 'link'
            }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        this.setState({
            editorState: RichUtils.toggleLink(
                newEditorState,
                newEditorState.getSelection(),
                entityKey
            ),
            showURLInput: false,
            urlValue: '',
            content_has_anchor_tag: false,
        }, () => this.focus());
    };

    _cancelLink(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            showURLInput: false,
            urlValue: '',
            content_has_anchor_tag: false,
        }, () => this.focus());
    }

    _onLinkInputKeyDown(e) {
        if (e.which === 13) {
            this._confirmLink(e);
        }
    };

    _removeLink(e) {
        e.preventDefault();
        e.stopPropagation();
        const {editorState} = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            this.setState({
                editorState: RichUtils.toggleLink(editorState, selection, null),
                showURLInput: false,
                urlValue: '',
                content_has_anchor_tag: false,
            }, () => this.focus());
        }
    };

    componentDidMount() {
        // TODO: 資料庫整理後可調整
        this.setState({
            editorState: EditorState.createWithContent(stateFromHTML(this.props.content), this.decorator)
        }, () => this.html = stateToHTML(this.state.editorState.getCurrentContent()));
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        // discard 時用到
        if(JSON.stringify(this.html) !== JSON.stringify(next_props.content)) {
            this.setState({
                editorState: EditorState.createWithContent(stateFromHTML(next_props.content), this.decorator)
            }, () => this.html = stateToHTML(this.state.editorState.getCurrentContent()));
        }
    };

    _handleKeyCommand (command) {
        const { editorState } = this.state;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    };

    handlePastedText(text, html) {
        const { editorState } = this.state;
        if(typeof html === 'undefined') {
            text = text.replace(/\n/g, "</p><p>");
            html = `<p>` + text + `</p>`;
        }
        html = stateToHTML(EditorState.createWithContent(stateFromHTML(html), this.decorator).getCurrentContent());

        html = raw_html_to_editor_content(html);
        const blockMap = stateFromHTML(html).blockMap;

        const newContentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            blockMap
        );

        this.onChange(EditorState.push(
            editorState,
            newContentState,
            'insert-fragment'
        ));

        return true;
    };

    _onTab(e) {
        const maxDepth = 4;
        this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    };

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    };

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    };

    onChange = (editorState) => {
        let next_html = stateToHTML(editorState.getCurrentContent());
        if(this.html !== next_html) {
            this.html = next_html;
            return this.setState({ editorState }, () => this.props.updateContent(this.html));
        } else {
            return this.setState({ editorState });
        }
    };

    render() {

        const { editorState, showURLInput, urlValue, content_has_anchor_tag } = this.state;
        const { confirmLink, cancelLink, removeLink, promptForLink } = this;
        const { t } = this.props;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        let contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className={`RichEditor-root ${document.activeElement === document.querySelector('.public-DraftEditor-content') ? 'focus' : ''} ${this.props.className}`}>
                <div className="RichEditor-controls-main-panel">
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={this.toggleInlineStyle}
                        t={t}
                    />
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={this.toggleBlockType}
                        t={t}
                    />
                    <div className="anchor-button-wrapper">
                        <span
                            className={`RichEditor-styleButton tooltip-wrapper ${(showURLInput) ? 'RichEditor-activeButton' : ''}`}
                            onClick={ e => {
                                if(showURLInput) cancelLink(e);
                                else promptForLink(e);
                            }}
                        >
                            <img src={icon_link} alt="" className="icon-link" />
                            <div className="tooltip">
                                <h6>{t('hyperlink')}</h6>
                            </div>
                        </span>
                        {
                            (showURLInput) ?
                                <div className="anchor-panel-wrapper">
                                    <div className="anchor-panel">
                                        <input
                                            className='input'
                                            onChange={this.onURLChange}
                                            ref="url"
                                            type="text"
                                            value={urlValue}
                                            onKeyDown={this.onLinkInputKeyDown}
                                            placeholder="https://..."
                                        />
                                        {
                                            (contain_not_only_whitespace(urlValue)) ?
                                                <a
                                                    href="/#confirm"
                                                    className="btn btn-smallest btn-flat btn-ghost btn-confirm"
                                                    onClick={ e => {contain_not_only_whitespace(urlValue) ? confirmLink(e) : cancelLink(e)} }
                                                >
                                                    <img src={icon_ok} alt="" />
                                                </a> : ''
                                        }
                                        {
                                            (content_has_anchor_tag) ?
                                                <a
                                                    href="/#cancel"
                                                    className="btn btn-smallest btn-flat btn-ghost btn-remove"
                                                    onClick={ e => removeLink(e) }
                                                >
                                                    <h5>remove</h5>
                                                </a> : ''
                                        }
                                        <button
                                            className="btn btn-smallest btn-flat btn-ghost btn-close"
                                            onClick={ e => cancelLink(e) }
                                        ><span className="hidden">Cancel</span></button>
                                    </div>
                                </div> : ''
                        }
                    </div>
                </div>
                <div className={className} onClick={this.focus}>
                    <TheEditor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        handlePastedText={this.handlePastedText}
                        onChange={this.onChange}
                        onTab={this.onTab}
                        ref="editor"
                        spellCheck={true}
                    />
                </div>
            </div>
        );
    };
}

Editor.defaultProps = {
    content: '',
    className: '',
    updateContent: f=>f,
};

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}

const Link = (props) => {
    const {url} = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} target={`_blank`} rel={`noopener noreferrer`}>
            {props.children}
        </a>
    );
};

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    'HIGHLIGHT': {
        backgroundColor: 'lightgreen'
    }
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

class StyleButton extends React.Component {
    constructor(props) {
        super(props);
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        const { t } = this.props;

        let className = 'RichEditor-styleButton tooltip-wrapper';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
              {this.props.label}
                <div className="tooltip">
                    <h6>{t(this.props.i18n_text)}</h6>
                </div>
            </span>
        );
    }
}

const BLOCK_TYPES = [
    // {label: 'H1', style: 'header-one'},
    // {label: 'H2', style: 'header-two'},
    // {label: 'H3', style: 'header-three'},
    {label: <img src={icon_head} alt="" className="icon-H" />, style: 'header-four', i18n_text: 'heading'},
    // {label: 'H5', style: 'header-five'},
    // {label: 'H6', style: 'header-six'},
    // {label: 'Blockquote', style: 'blockquote'},
    {label: <img src={icon_ul} alt="" className="icon-ul" />, style: 'unordered-list-item', i18n_text: 'bulleted_list'},
    {label: <img src={icon_ol} alt="" className="icon-ol" />, style: 'ordered-list-item', i18n_text: 'numbered_list'},
    // {label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type, i) =>
                <StyleButton
                    key={i}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                    t={props.t}
                    i18n_text={type.i18n_text}
                />
            )}
        </div>
    );
};

let INLINE_STYLES = [
    {label: <img src={icon_bold} alt="" className="icon-B" />, style: 'BOLD', i18n_text: 'bold'},
    // {label: 'Italic', style: 'ITALIC'},
    // {label: 'Underline', style: 'UNDERLINE'},
    // {label: 'Monospace', style: 'CODE'},
];

const InlineStyleControls = (props) => {
    let currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                    t={props.t}
                    i18n_text={type.i18n_text}
                />
            )}
        </div>
    );
};

export default withNamespaces(['general'])(Editor);
