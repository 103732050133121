import React, { useState, useEffect } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { getRandomInt } from "../../../../assets/js/functions";
import Ad from "../../../models/ad";

const AdCoverModal = (props) => {
	const {
		current_user,
		ad_cover_modal,
		updateCoverAdsModalDismiss,
		ads_cover,
	} = props;
	// console.log(props);
	const [ads, setAds] = useState(ads_cover);

	useEffect(() => {
		const _ads = [...ads_cover].filter(({ id }) => {
			return current_user.cover_ads_dismiss.every((dismiss) => {
				return dismiss.id !== id;
			});
		});
		setAds(_ads);
	}, [ads_cover, current_user]);

	// const ads = []
	if (ads.length < 1) return null;

	const ad_index = getRandomInt(ads.length);
	const ad = new Ad(ads[ad_index]);
	const dismiss = () => {
		updateCoverAdsModalDismiss({
			id: ad.id,
			timeStamp: new Date().getTime(),
		});
	};

	const close = (e) => {
		e.preventDefault();
		dismiss();
	};

	const handleClickLink = (e) => {
		e.preventDefault();
		const link = e.target;
		window.open(ad.get_url(), "_blank");
		dismiss();
	};

	return (
		<CSSTransitionGroup
			component="div"
			transitionName="modal-wrapper-slide"
			transitionEnterTimeout={300}
			transitionLeaveTimeout={300}>
			{ad_cover_modal ? (
				<div
					className="modal-wrapper cover-ad-modal"
					onClick={(e) => {
						close(e);
					}}>
					<div
						onClick={(e) => {
							e.stopPropagation();
						}}>
						<div
							className="btn-close"
							onClick={(e) => {
								close(e);
							}}
						/>
						<a href={ad.get_url()} onClick={(e) => handleClickLink(e)}>
							<img src={ad.get_img_url()} alt={ad.formatted_title()} />
						</a>
					</div>
				</div>
			) : (
				""
			)}
		</CSSTransitionGroup>
	);
};

export default AdCoverModal;
