import React from "react";
import Textarea from "react-textarea-autosize";
import moment from "moment";
import {
	contain_not_only_whitespace,
	linkify,
	download_file,
	smoothly_scroll_to,
} from "../../../../assets/js/functions.js";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import JobApplication from "../../../models/job_application.js";
import Message from "../../../models/message.js";
import { withNamespaces, Interpolate } from "react-i18next";
import icon_attachment from "../../../../assets/images/icon/icon_24_attachment_BG400.svg";
import icon_ok from "../../../../assets/images/icon/icon_28_ok_white.svg";
import { CSSTransitionGroup } from "react-transition-group";
import MeetJobsLoader from "../../general/MeetJobsLoader.jsx";
import { Link } from "react-router-dom";
import icon_redirect from "../../../../assets/images/icon/icon_24_redirect_B100.svg";
import InternalUri from "../../../models/internal_uri";
import modal_banner_report from "../../../../assets/images/i043-got-offer.svg";
import modal_pop_up_cancel from "../../../../assets/images/i048-pop-ups-cancel.svg";
import DeviceDetector from "../../../models/device_detector";

const config = new Config();
const headers = new ApiHeaders();

class CandidateMessageModal extends React.Component {
	constructor(props) {
		super(props);
		this.fetchData = this.fetchData.bind(this);
		this.fetchMessages = this.fetchMessages.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.submitChatForm = this.submitChatForm.bind(this);
		this.messagesScrollToBottom = this.messagesScrollToBottom.bind(this);
		this.cancelChangeState = this.cancelChangeState.bind(this);
		this.confirmApplication = this.confirmApplication.bind(this);
		this.confirmExternalApplication = this.confirmExternalApplication.bind(
			this,
		);
		this.rejectApplication = this.rejectApplication.bind(this);
		this.handleMessagesWrapperScroll = this.handleMessagesWrapperScroll.bind(
			this,
		);
		this.loadMoreData = this.loadMoreData.bind(this);
		// attachment
		this.handleAttachment = this.handleAttachment.bind(this);
		this.deleteAttachment = this.deleteAttachment.bind(this);
		this.handleDownload = this.handleDownload.bind(this);

		this.state = {
			application: {},
			active_job_id: -1,
			active_application_id: -1,
			active_user_id: -1,
			messages: [],
			form_data: {
				message: {
					content: "",
					attachment: {},
				},
			},
			loading: true,
			loading_conversations: true,
			sending: false,
			sending_change_state: false,
			loading_more: false,
			change_state_message_modal: {
				open: false,
				action_type: "",
				title: "",
				text: "",
				application_type: "",
				next_state: "",
			},
			error_messages: {},
		};
		this.paginator = {
			message: {},
		};
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		const {
			open,
			job_id,
			application_id,
		} = next_props.site_state.candidate_message_modal;
		const active_user_id = next_props.reduxTokenAuth.currentUser.attributes.id;
		if (
			this.props.site_state.candidate_message_modal.open !== open ||
			this.props.site_state.candidate_message_modal.job_id !== job_id ||
			this.props.site_state.candidate_message_modal.application_id !==
				application_id
		) {
			if (job_id && application_id && active_user_id && open) {
				this.setState(
					(prev_state) => ({
						...prev_state,
						messages: [],
						active_job_id: job_id,
						active_application_id: application_id,
						active_user_id,
						loading: true,
					}),
					() => this.fetchData(),
				);
			}
		}
	}

	fetchData() {
		const { active_job_id, active_application_id, active_user_id } = this.state;
		let options = {
			method: "GET",
			url: config.api_url(
				`/users/${active_user_id}/job_applications/${active_application_id}`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let application,
			messages = [];
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				application = new JobApplication(response.data);

				options = {
					method: "GET",
					url: config.api_url(
						`/jobs/${active_job_id}/job_applications/${active_application_id}/messages`,
					),
					headers: headers.getItemsFromLocalStorage(),
					json: true,
				};
				return axios(options);
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				messages = response.data.collection.reverse();
				this.paginator.message = response.data.paginator;
				for (let i = 0; i < messages.length; i++) {
					messages[i] = new Message(messages[i]);
				}

				let message_ids = [];
				for (let i = 0; i < messages.length; i++) {
					message_ids.push(messages[i].id);
				}
				let data = {
					reading: {
						readable_type: "Job::JobApplication::Message",
						readable_ids: message_ids,
					},
				};
				options = {
					method: "POST",
					url: config.api_url(`/users/${active_user_id}/readings`),
					headers: headers.getItemsFromLocalStorage(),
					data: data,
					json: true,
				};
				return axios(options);
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				this.setState(
					(prev_state) => ({
						...prev_state,
						application,
						messages,
						loading: false,
						sending_change_state: false,
						loading_conversations: false,
						error_messages: {},
					}),
					() => this.messagesScrollToBottom(),
				);
			})
			.catch((error) => {
				// console.log(error.response);
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState(
					(prev_state) => ({
						...prev_state,
						loading: true,
						sending_change_state: false,
						loading_conversations: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}),
					() => {
						const internal_uri = new InternalUri();
						this.props.history.push(
							`${internal_uri.formatted_userpanel_my_applications_page_path()}?refresh=true`,
						);
						this.props.updateCandidateMessageModal(false, -1, -1);
					},
				);
			});
	}

	fetchMessages() {
		const { active_job_id, active_application_id, active_user_id } = this.state;
		let options = {
			method: "GET",
			url: config.api_url(
				`/jobs/${active_job_id}/job_applications/${active_application_id}/messages`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let messages = [];
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				messages = response.data.collection.reverse();
				this.paginator.message = response.data.paginator;
				for (let i = 0; i < messages.length; i++) {
					messages[i] = new Message(messages[i]);
				}

				let message_ids = [];
				for (let i = 0; i < messages.length; i++) {
					message_ids.push(messages[i].id);
				}
				let data = {
					reading: {
						readable_type: "Job::JobApplication::Message",
						readable_ids: message_ids,
					},
				};
				options = {
					method: "POST",
					url: config.api_url(`/users/${active_user_id}/readings`),
					headers: headers.getItemsFromLocalStorage(),
					data: data,
					json: true,
				};
				return axios(options);
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				this.setState((prev_state) => ({
					...prev_state,
					error_messages: {},
				}));
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState((prev_state) => ({
					...prev_state,
					error_messages:
						error.response &&
						error.response.data &&
						error.response.data.status === "error"
							? error.response.data.errors
							: { full_message: "There was an error. Please try again later." },
				}));
			})
			.then(() => {
				this.setState(
					(prev_state) => ({
						...prev_state,
						messages,
						loading: false,
						loading_conversations: false,
					}),
					() => this.messagesScrollToBottom(),
				);
			});
	}

	handleInputChange = (e) => {
		e.preventDefault();
		const { sending } = this.state;
		if (!sending) {
			const value = e.target.value;
			this.setState((prev_state) => ({
				...prev_state,
				form_data: {
					...prev_state.form_data,
					message: {
						...prev_state.form_data.message,
						content: value,
					},
				},
			}));
		}
	};

	closeModal = (e) => {
		e.preventDefault();
		// TODO: 一定還有更好的做法！
		const internal_uri = new InternalUri();
		this.props.history.push(
			`${internal_uri.formatted_userpanel_my_applications_page_path()}?refresh=true`,
		);
		this.props.updateCandidateMessageModal(false, -1, -1);
	};

	// handleKeyPress = (e) => {
	//     if(e.key === 'Enter' && !e.shiftKey) {
	//         this.submitChatForm(e);
	//     }
	// };

	// submit form

	submitChatForm = (e) => {
		e.preventDefault();
		const { form_data } = this.state;

		this.setState({ sending: true }, () => this.messagesScrollToBottom());
		const { active_job_id, active_application_id } = this.state;
		let formData = new FormData();
		if (contain_not_only_whitespace(form_data.message.content))
			formData.append(`message[content]`, form_data.message.content);
		if (form_data.message.attachment.file)
			formData.append(
				`message[attachment_attributes][asset]`,
				form_data.message.attachment.file,
			);
		let options = {
			method: "POST",
			url: config.api_url(
				`/jobs/${active_job_id}/job_applications/${active_application_id}/messages`,
			),
			headers: headers.getItemsFromLocalStorage("multipart/form-data"),
			data: formData,
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				this.setState(
					(prev_state) => ({
						...prev_state,
						form_data: {
							...prev_state.form_data,
							message: {
								...prev_state.form_data.message,
								content: "",
								attachment: {},
							},
						},
						loading_conversations: true,
						sending: false,
						error_messages: {},
					}),
					() => this.fetchMessages(),
				);
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState((prev_state) => ({
					...prev_state,
					sending: false,
					error_messages:
						error.response &&
						error.response.data &&
						error.response.data.status === "error"
							? error.response.data.errors
							: { full_message: "There was an error. Please try again later." },
				}));
			});
	};

	// change state

	confirmApplication(e) {
		e.preventDefault();
		const { t } = this.props;
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: true,
				action_type: "report",
				title: t("got_the_job_offer"),
				text: t(
					"please_be_sure_you_have_passed_the_interview_and_processed_all_the_details_of_this_role_",
				),
				application_type: "job_application",
				next_state: "reported",
			},
		}));
	}

	confirmExternalApplication(e) {
		e.preventDefault();
		const { t } = this.props;
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: true,
				action_type: "report",
				title: t("got_the_job_offer"),
				text: t(
					"would_you_like_to_confirm_your_job_offer_and_get_100_usd_onboard_reward_from_meet_jobs_",
				),
				application_type: "external_job_application",
				next_state: "reported",
			},
		}));
	}

	rejectApplication(e) {
		e.preventDefault();
		const { t } = this.props;
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: true,
				action_type: "cancel",
				title: t("are_you_sure_to_cancel_your_application_"),
				text: "",
				application_type: "job_application",
				next_state: "cancelled",
			},
		}));
	}

	cancelChangeState(e) {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: false,
				action_type: "",
				title: "",
				text: "",
				application_type: "job_application",
				next_state: "",
			},
		}));
	}

	submitChangeState = () => {
		this.setState({ sending_change_state: true });

		const {
			active_user_id,
			active_application_id,
			change_state_message_modal,
		} = this.state;
		let formData = new FormData();
		formData.append(
			`${change_state_message_modal.application_type}[applier_state]`,
			change_state_message_modal.next_state,
		);
		let options = {
			method: "PATCH",
			url: config.api_url(
				`/users/${active_user_id}/${change_state_message_modal.application_type}s/${active_application_id}`,
			),
			headers: headers.getItemsFromLocalStorage("multipart/form-data"),
			data: formData,
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				this.setState(
					(prev_state) => ({
						...prev_state,
						change_state_message_modal: {
							open: false,
							action_type: "",
							title: "",
							text: "",
							application_type: "",
							next_state: "",
						},
						error_messages: {},
					}),
					() => this.fetchData(),
				);
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState((prev_state) => ({
					...prev_state,
					change_state_message_modal: {
						open: false,
						action_type: "",
						title: "",
						text: "",
						application_type: "",
						next_state: "",
					},
					sending_change_state: false,
					error_messages:
						error.response &&
						error.response.data &&
						error.response.data.status === "error"
							? error.response.data.errors
							: { full_message: "There was an error. Please try again later." },
				}));
			});
	};

	// message scroll to bottom

	messagesScrollToBottom() {
		const element = document.querySelector(".messages-wrapper");
		if (element) smoothly_scroll_to(element, element.scrollHeight, 200);
	}

	// attachment

	handleAttachment = (e) => {
		e.preventDefault();
		let file = e.target.files[0];
		let file_type = "";
		if (file) {
			e.target.value = null;
			if (file.type === "application/msword" || file.type === ".doc")
				file_type = "DOC";
			if (file.type === "application/pdf" || file.type === ".pdf")
				file_type = "PDF";
			if (
				file.type ===
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
				file.type === ".docx"
			)
				file_type = "DOCX";
			this.setState(
				(prev_state) => ({
					...prev_state,
					form_data: {
						...prev_state.form_data,
						message: {
							...prev_state.form_data.message,
							attachment: {
								file,
								file_type,
							},
						},
					},
				}),
				() => this.messagesScrollToBottom(),
			);
		}
	};

	deleteAttachment = (e) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			form_data: {
				...prev_state.form_data,
				message: {
					...prev_state.form_data.message,
					attachment: {},
				},
			},
		}));
	};

	handleDownload = (e, url, filename) => {
		e.preventDefault();
		download_file(url, filename);
	};

	// load more

	handleMessagesWrapperScroll = (e) => {
		e.preventDefault();
		const element = document.querySelector(".messages-wrapper");
		if (element && element.scrollTop === 0) this.loadMoreData();
	};

	loadMoreData() {
		const {
			active_job_id,
			active_application_id,
			loading_more,
			active_user_id,
		} = this.state;

		if (this.paginator.message.next_page && !loading_more) {
			this.setState({ loading_more: true });
			let options = {
				method: "GET",
				url: config.api_url(
					`/jobs/${active_job_id}/job_applications/${active_application_id}/messages?page=${this.paginator.message.next_page}`,
				),
				headers: headers.getItemsFromLocalStorage(),
				json: true,
			};
			let conversations = [];
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					conversations = response.data.collection.reverse();
					this.paginator.message = response.data.paginator;

					for (let i = 0; i < conversations.length; i++) {
						conversations[i] = new Message(conversations[i]);
					}
					let message_ids = [];
					for (let i = 0; i < conversations.length; i++) {
						message_ids.push(conversations[i].id);
					}
					let data = {
						reading: {
							readable_type: "Job::JobApplication::Message",
							readable_ids: message_ids,
						},
					};
					options = {
						method: "POST",
						url: config.api_url(`/users/${active_user_id}/readings`),
						headers: headers.getItemsFromLocalStorage(),
						data: data,
						json: true,
					};
					return axios(options);
				})
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);

					this.setState((prev_state) => ({
						...prev_state,
						error_messages: {},
					}));
				})
				.catch((error) => {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					this.setState((prev_state) => ({
						...prev_state,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				})
				.then(() => {
					this.setState(
						(prev_state) => ({
							...prev_state,
							messages: [...conversations, ...prev_state.messages],
							loading_more: false,
						}),
						() => {
							let wrappers = document.querySelectorAll(".messages-wrapper");
							let elements = document.querySelectorAll(".message-wrapper");
							wrappers[0].scrollTop =
								elements[conversations.length - 1].offsetTop -
								wrappers[0].offsetTop;
						},
					);
				});
		}
	}

	render() {
		const { handleInputChange, closeModal, submitChatForm } = this;
		const {
			handleAttachment,
			deleteAttachment,
			handleDownload,
			handleMessagesWrapperScroll,
		} = this;
		const { paginator } = this;
		const {
			active_user_id,
			application,
			messages,
			loading,
			sending,
			sending_change_state,
			loading_more,
			loading_conversations,
			form_data,
			error_messages,
		} = this.state;
		const { site_state } = this.props;
		const {
			confirmApplication,
			confirmExternalApplication,
			rejectApplication,
			cancelChangeState,
			submitChangeState,
		} = this;
		const { change_state_message_modal } = this.state;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		return (
			<CSSTransitionGroup
				component="div"
				transitionName="modal-wrapper-slide"
				transitionEnterTimeout={300}
				transitionLeaveTimeout={300}>
				{site_state.candidate_message_modal.open ? (
					<div className="modal-wrapper candidate-message-modal-wrapper">
						<CSSTransitionGroup
							component="div"
							className="modal-inner-wrapper"
							transitionName="modal-slide"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={300}>
							<div className="modal-bg" onClick={(e) => closeModal(e)} />
							{!loading ? (
								<div
									className={`modal candidate-message-modal ${
										application.has_referrer() ? "with-referred-wrapper" : ""
									}`}>
									<div className="btn-close" onClick={(e) => closeModal(e)} />
									<div className="modal-header">
										<h4 className="modal-title">
											{`${application.formatted_state(t)} - `}
											{!application.is_employer_archived() ? (
												<Link
													to={internal_uri.formatted_frontend_job_page_path(
														application.job.id,
														application.job.slug,
													)}
													target="_blank"
													rel="noopener noreferrer">
													{application.job.formatted_title()}
													<img src={icon_redirect} alt="" />
												</Link>
											) : (
												application.job.formatted_title()
											)}
										</h4>
										{application.has_referrer() ? (
											<div className="referred-wrapper">
												<div className="referred">
													<span className="badge badge-green-reward">
														{t("general:referred")}
													</span>
													<p>
														<Interpolate
															i18nKey="by__"
															useDangerouslySetInnerHTML={true}
															referrer_name={application.formatted_referrer_user_name()}
														/>
													</p>
												</div>
											</div>
										) : (
											""
										)}
										{error_messages.full_message ? (
											<h6 className="message error-message">
												{error_messages.full_message}
											</h6>
										) : (
											""
										)}
									</div>
									<div className="modal-body">
										<div className="left">
											<div className="messages-box">
												<div className="messages-box-header">
													<h5>{t("general:messages")}</h5>
												</div>
												<div className="messages-box-body">
													<div
														className={`messages-wrapper${
															form_data.message.attachment.file
																? " has-pending-file"
																: ""
														}${
															!application.job.has_employer()
																? " external-job-application"
																: ""
														}`}
														onScroll={(e) => handleMessagesWrapperScroll(e)}>
														<div className="messages">
															{loading_more ? (
																<div className="loading-more-message-wrapper">
																	<p className="loading-more-message">{`${t(
																		"general:loading",
																	)}...`}</p>
																</div>
															) : (
																""
															)}
															{!paginator.message.next_page &&
															paginator.message.total_pages >= 2 ? (
																<div className="loading-more-message-wrapper">
																	<p className="loading-more-message">
																		{t("general:no_more_messages")}
																	</p>
																</div>
															) : (
																""
															)}
															{messages.length > 0
																? messages.map((message, i) => {
																		const message_content = linkify(
																			message.content,
																		).replace(/\n/g, `<br />`);
																		if (message.sender_type === "system") {
																			if (message.employer_state === "reverted")
																				// reverted
																				return (
																					<div
																						className="message-wrapper"
																						key={i}>
																						<div className="message message-center">
																							<div className="content">
																								<h5>
																									{t(
																										"system_messages:revertedText",
																									)}
																								</h5>
																							</div>
																							<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																						</div>
																					</div>
																				);
																			else if (message.integrated_state)
																				// reset
																				return (
																					<div
																						className="message-wrapper"
																						key={i}>
																						<div className="message message-center">
																							<div className="content">
																								<h4>
																									{t(
																										"system_messages:resetText",
																										{
																											state: t(
																												`states:${message.integrated_state}`,
																											),
																										},
																									)}
																								</h4>
																								<p
																									dangerouslySetInnerHTML={{
																										__html: message_content,
																									}}
																								/>
																							</div>
																							<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																						</div>
																					</div>
																				);
																			else
																				return (
																					<div
																						className="message-wrapper"
																						key={i}>
																						<div className="message message-center">
																							<div className="content">
																								<p
																									dangerouslySetInnerHTML={{
																										__html: message_content,
																									}}
																								/>
																							</div>
																							<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																						</div>
																					</div>
																				);
																		} else if (
																			message.applier_state === "initialized"
																		) {
																			if (
																				contain_not_only_whitespace(
																					message.content,
																				)
																			)
																				return (
																					<div key={i}>
																						<div className="message-wrapper">
																							<div className="message message-center">
																								<div className="content">
																									<h5>
																										<Interpolate
																											i18nKey="system_messages:appliedText"
																											useDangerouslySetInnerHTML={
																												true
																											}
																											applier_name={application.formatted_applier_name()}
																											job_title={application.job.formatted_title()}
																										/>
																									</h5>
																								</div>
																								<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																							</div>
																						</div>
																						{message.sender.id ===
																						active_user_id ? (
																							<div className="message-wrapper">
																								<div className="message message-right">
																									<div className="content">
																										<p
																											dangerouslySetInnerHTML={{
																												__html: message_content,
																											}}
																										/>
																									</div>
																									<h6 className="time">
																										{message.formatted_created_at()}
																									</h6>
																								</div>
																							</div>
																						) : (
																							<div className="message-wrapper">
																								<div className="message message-left">
																									<div className="user">
																										<div className="avatar">
																											<div
																												className="image-wrapper"
																												style={{
																													backgroundImage: `url(${
																														application.job.has_employer()
																															? application.job.employer.formatted_logo_toolbar_url()
																															: application.job.formatted_external_employer_logo_url()
																													})`,
																												}}
																											/>
																										</div>
																										<h5>
																											{message.formatted_employer_name()}
																										</h5>
																									</div>
																									<div className="content">
																										<p
																											dangerouslySetInnerHTML={{
																												__html: message_content,
																											}}
																										/>
																									</div>
																									<h6 className="time">
																										{message.formatted_created_at()}
																									</h6>
																								</div>
																							</div>
																						)}
																					</div>
																				);
																			else
																				return (
																					<div
																						className="message-wrapper"
																						key={i}>
																						<div className="message message-center">
																							<div className="content">
																								<h5>
																									<Interpolate
																										i18nKey="system_messages:appliedText"
																										useDangerouslySetInnerHTML={
																											true
																										}
																										applier_name={application.formatted_applier_name()}
																										job_title={application.job.formatted_title()}
																									/>
																								</h5>
																							</div>
																							<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																						</div>
																					</div>
																				);
																		} else if (
																			message.applier_state === "cancelled"
																		)
																			return (
																				<div
																					className="message-wrapper"
																					key={i}>
																					<div className="message message-center red">
																						<div className="content">
																							<h5>
																								<Interpolate
																									i18nKey="system_messages:cancelledText"
																									useDangerouslySetInnerHTML={
																										true
																									}
																									applier_name={application.formatted_applier_name()}
																								/>
																							</h5>
																						</div>
																						<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																					</div>
																				</div>
																			);
																		else if (
																			message.applier_state === "reported"
																		)
																			return (
																				<div
																					className="message-wrapper"
																					key={i}>
																					<div className="message message-center green">
																						<div className="content">
																							<h5>
																								<Interpolate
																									i18nKey="system_messages:claimedByApplierText"
																									useDangerouslySetInnerHTML={
																										true
																									}
																									applier_name={application.formatted_applier_name()}
																								/>
																							</h5>
																						</div>
																						<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																					</div>
																				</div>
																			);
																		else if (
																			message.employer_state === "rejected"
																		)
																			return (
																				<div
																					className="message-wrapper"
																					key={i}>
																					<div className="message message-center red">
																						<div className="content">
																							<h5>
																								<Interpolate
																									i18nKey="system_messages:rejectedText"
																									useDangerouslySetInnerHTML={
																										true
																									}
																									employer_name={application.job.formatted_employer_name()}
																								/>
																							</h5>
																						</div>
																						<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																					</div>
																				</div>
																			);
																		else if (
																			message.employer_state === "confirmed"
																		)
																			return (
																				<div
																					className="message-wrapper"
																					key={i}>
																					<div className="message message-center green">
																						<div className="content">
																							<h5>
																								<Interpolate
																									i18nKey="system_messages:confirmedText"
																									useDangerouslySetInnerHTML={
																										true
																									}
																									employer_name={application.job.formatted_employer_name()}
																								/>
																							</h5>
																						</div>
																						<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																					</div>
																				</div>
																			);
																		else if (
																			message.referrer_state === "claimed"
																		)
																			return (
																				<div
																					className="message-wrapper"
																					key={i}>
																					<div className="message message-center green-reward">
																						<div className="content">
																							<h5>
																								<Interpolate
																									i18nKey="system_messages:claimedByReferralText"
																									useDangerouslySetInnerHTML={
																										true
																									}
																									applier_name={application.formatted_applier_name()}
																									referrer_name={application.formatted_referrer_user_name()}
																								/>
																							</h5>
																						</div>
																						<h6 className="time">{`— Meet.jobs ${message.formatted_created_at()} —`}</h6>
																					</div>
																				</div>
																			);
																		else if (
																			message.sender.id === active_user_id
																		) {
																			if (message.interview) {
																				return (
																					<div
																						className="message-wrapper"
																						key={i}>
																						<div className="message message-right">
																							<div className="content interview">
																								<h6>{t("title")}</h6>
																								<p>{message.interview.name}</p>

																								<h6>{t("location")}</h6>
																								<p>
																									{message.interview.location}
																								</p>

																								<h6>{t("date")}</h6>
																								<p>
																									{message.interview.started_at
																										? moment(
																												message.interview
																													.started_at,
																										  ).format("YYYY/MM/DD")
																										: ""}
																								</p>

																								<h6>{t("time")}</h6>
																								<p>
																									{message.interview.started_at
																										? moment(
																												message.interview
																													.started_at,
																										  ).format("HH:mm")
																										: ""}{" "}
																									{t("to")}{" "}
																									{message.interview.ended_at
																										? moment(
																												message.interview
																													.ended_at,
																										  ).format("HH:mm")
																										: ""}
																								</p>

																								<a
																									className="btn btn-smaller btn-flat btn-hollow btn-google-calendar"
																									href={
																										`https://www.google.com/calendar/event?action=TEMPLATE` +
																										`&dates=${moment(
																											message.interview
																												.started_at,
																										)
																											.utc()
																											.format(
																												"YYYYMMDDThhmmss",
																											)}Z/${moment(
																											message.interview
																												.ended_at,
																										)
																											.utc()
																											.format(
																												"YYYYMMDDThhmmss",
																											)}Z` +
																										`&text=${message.interview.name}` +
																										`&location=${message.interview.location}` +
																										`&details=`
																									}
																									target="_blank"
																									rel="noopener noreferrer">
																									<span />
																									<h5>Google calendar</h5>
																								</a>
																							</div>
																							<h6 className="time">
																								{message.formatted_created_at()}
																							</h6>
																						</div>
																					</div>
																				);
																			} else {
																				if (message.has_attachment()) {
																					const device_detector = new DeviceDetector();
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-right">
																								<div className="content attachment">
																									<p
																										dangerouslySetInnerHTML={{
																											__html: message_content,
																										}}
																									/>
																									<h6>{t("file_name")}</h6>
																									{device_detector.is_mobile_or_tablet() ? (
																										<a
																											className="file-wrapper"
																											href={message.attachment.formatted_url()}
																											target="_blank"
																											rel="noopener noreferrer">
																											<span className="file-type">
																												<h5>
																													{message.attachment.formatted_file_type()}
																												</h5>
																											</span>
																											<span className="file-name">
																												<h5>
																													{message.attachment.formatted_filename()}
																												</h5>
																												<span className="btn-download" />
																											</span>
																										</a>
																									) : (
																										<button
																											className="file-wrapper"
																											href="/#"
																											onClick={(e) =>
																												handleDownload(
																													e,
																													message.attachment.formatted_url(),
																													message.attachment.formatted_filename(),
																												)
																											}>
																											<span className="file-type">
																												<h5>
																													{message.attachment.formatted_file_type()}
																												</h5>
																											</span>
																											<span className="file-name">
																												<h5>
																													{message.attachment.formatted_filename()}
																												</h5>
																												<span className="btn-download" />
																											</span>
																										</button>
																									)}
																								</div>
																								<h6 className="time">
																									{message.formatted_created_at()}
																								</h6>
																							</div>
																						</div>
																					);
																				} else {
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-right">
																								<div className="content">
																									<p
																										dangerouslySetInnerHTML={{
																											__html: message_content,
																										}}
																									/>
																								</div>
																								<h6 className="time">
																									{message.formatted_created_at()}
																								</h6>
																							</div>
																						</div>
																					);
																				}
																			}
																		} else {
																			if (message.interview) {
																				return (
																					<div
																						className="message-wrapper"
																						key={i}>
																						<div className="message message-left">
																							<div className="user">
																								<div className="avatar">
																									<div
																										className="image-wrapper"
																										style={{
																											backgroundImage: `url(${
																												application.job.has_employer()
																													? application.job.employer.formatted_logo_toolbar_url()
																													: application.job.formatted_external_employer_logo_url()
																											})`,
																										}}
																									/>
																								</div>
																								<h5>
																									{message.formatted_employer_name()}
																								</h5>
																							</div>
																							<div className="content interview">
																								<h6>{t("title")}</h6>
																								<p>{message.interview.name}</p>

																								<h6>{t("location")}</h6>
																								<p>
																									{message.interview.location}
																								</p>

																								<h6>{t("date")}</h6>
																								<p>
																									{message.interview.started_at
																										? moment(
																												message.interview
																													.started_at,
																										  ).format("YYYY/MM/DD")
																										: ""}
																								</p>

																								<h6>{t("time")}</h6>
																								<p>
																									{message.interview.started_at
																										? moment(
																												message.interview
																													.started_at,
																										  ).format("HH:mm")
																										: ""}{" "}
																									{t("to")}{" "}
																									{message.interview.ended_at
																										? moment(
																												message.interview
																													.ended_at,
																										  ).format("HH:mm")
																										: ""}
																								</p>

																								<a
																									className="btn btn-smaller btn-flat btn-hollow btn-google-calendar"
																									href={
																										`https://www.google.com/calendar/event?action=TEMPLATE` +
																										`&dates=${moment(
																											message.interview
																												.started_at,
																										)
																											.utc()
																											.format(
																												"YYYYMMDDThhmmss",
																											)}Z/${moment(
																											message.interview
																												.ended_at,
																										)
																											.utc()
																											.format(
																												"YYYYMMDDThhmmss",
																											)}Z` +
																										`&text=${message.interview.name}` +
																										`&location=${message.interview.location}` +
																										`&details=`
																									}
																									target="_blank"
																									rel="noopener noreferrer">
																									<span />
																									<h5>Google calendar</h5>
																								</a>
																							</div>
																							<h6 className="time">
																								{message.formatted_created_at()}
																							</h6>
																						</div>
																					</div>
																				);
																			} else {
																				if (message.has_attachment()) {
																					const device_detector = new DeviceDetector();
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-left">
																								<div className="user">
																									<div className="avatar">
																										<div
																											className="image-wrapper"
																											style={{
																												backgroundImage: `url(${
																													application.job.has_employer()
																														? application.job.employer.formatted_logo_toolbar_url()
																														: application.job.formatted_external_employer_logo_url()
																												})`,
																											}}
																										/>
																									</div>
																									<h5>
																										{message.formatted_employer_name()}
																									</h5>
																								</div>
																								<div className="content attachment">
																									<p
																										dangerouslySetInnerHTML={{
																											__html: message_content,
																										}}
																									/>
																									<h6>{t("file_name")}</h6>
																									{device_detector.is_mobile_or_tablet() ? (
																										<a
																											className="file-wrapper"
																											href={message.attachment.formatted_url()}
																											target="_blank"
																											rel="noopener noreferrer">
																											<span className="file-type">
																												<h5>
																													{message.attachment.formatted_file_type()}
																												</h5>
																											</span>
																											<span className="file-name">
																												<h5>
																													{message.attachment.formatted_filename()}
																												</h5>
																												<span className="btn-download" />
																											</span>
																										</a>
																									) : (
																										<a
																											className="file-wrapper"
																											href="/#"
																											onClick={(e) =>
																												handleDownload(
																													e,
																													message.attachment.formatted_url(),
																													message.attachment.formatted_filename(),
																												)
																											}>
																											<span className="file-type">
																												<h5>
																													{message.attachment.formatted_file_type()}
																												</h5>
																											</span>
																											<span className="file-name">
																												<h5>
																													{message.attachment.formatted_filename()}
																												</h5>
																												<span className="btn-download" />
																											</span>
																										</a>
																									)}
																								</div>
																								<h6 className="time">
																									{message.formatted_created_at()}
																								</h6>
																							</div>
																						</div>
																					);
																				} else {
																					return (
																						<div
																							className="message-wrapper"
																							key={i}>
																							<div className="message message-left">
																								<div className="user">
																									<div className="avatar">
																										<div
																											className="image-wrapper"
																											style={{
																												backgroundImage: `url(${
																													application.job.has_employer()
																														? application.job.employer.formatted_logo_toolbar_url()
																														: application.job.formatted_external_employer_logo_url()
																												})`,
																											}}
																										/>
																									</div>
																									<h5>
																										{message.formatted_employer_name()}
																									</h5>
																								</div>
																								<div className="content">
																									<p
																										dangerouslySetInnerHTML={{
																											__html: message_content,
																										}}
																									/>
																								</div>
																								<h6 className="time">
																									{message.formatted_created_at()}
																								</h6>
																							</div>
																						</div>
																					);
																				}
																			}
																		}
																  })
																: ""}
															{sending || loading_conversations ? (
																<div className="message-wrapper">
																	<div className="message message-right">
																		<div className="content sending">
																			<MeetJobsLoader />
																		</div>
																		<h6 className="time">
																			{t("general:sending")}...
																		</h6>
																	</div>
																</div>
															) : null}
														</div>
													</div>
													{application.job.has_employer() ? (
														<div className="chat-form">
															<form>
																<div className="input-wrapper">
																	{form_data.message.attachment.file ? (
																		<div className="file-wrapper">
																			<span className="file-type">
																				<h5>
																					{
																						form_data.message.attachment
																							.file_type
																					}
																				</h5>
																			</span>
																			<span className="file-name">
																				<h5>
																					{
																						form_data.message.attachment.file
																							.name
																					}
																				</h5>
																				<div
																					className="btn-close"
																					onClick={(e) => deleteAttachment(e)}
																				/>
																			</span>
																		</div>
																	) : (
																		""
																	)}
																	<Textarea
																		className="textarea"
																		minRows={1}
																		maxRows={8}
																		placeholder={t("type_something___")}
																		value={form_data.message.content}
																		onChange={(e) => handleInputChange(e)}
																	/>
																	{!sending &&
																	!sending_change_state &&
																	(contain_not_only_whitespace(
																		form_data.message.content,
																	) ||
																		form_data.message.attachment.file) &&
																	!application.is_employer_archived() ? (
																		<button
																			className="btn btn-smaller btn-flat btn-fill"
																			onClick={(e) => submitChatForm(e)}>
																			<h5>{t("general:send")}</h5>
																		</button>
																	) : (
																		<button
																			className={`btn btn-smaller btn-flat btn-ghost ${
																				application.is_employer_archived()
																					? "disabled"
																					: ""
																			}`}
																			onClick={(e) => e.preventDefault()}>
																			<h5>{t("general:send")}</h5>
																		</button>
																	)}
																</div>
																<div className="widget-wrapper">
																	<ul>
																		<li>
																			<a
																				onClick={(e) => e.stopPropagation()}
																				href="/#">
																				<input
																					id="upload-attachment"
																					type="file"
																					accept={
																						`.pdf, ` +
																						`.doc, ` +
																						`.docx, ` +
																						`application/pdf, ` +
																						`application/msword, ` +
																						`application/vnd.openxmlformats-officedocument.wordprocessingml.document`
																					}
																					onChange={(e) => handleAttachment(e)}
																				/>
																				<label htmlFor="upload-attachment">
																					<img src={icon_attachment} alt="" />
																					<h5>{t("general:add_file")}</h5>
																				</label>
																			</a>
																		</li>
																	</ul>
																</div>
															</form>
														</div>
													) : (
														""
													)}
												</div>
											</div>
										</div>
										<div className="right">
											<h6
												className="description-reward"
												dangerouslySetInnerHTML={{
													__html: t(
														"after_you_got_the_job_offer__please_let_meet_jobs_know_about_it__we_would_grant_you___reward_",
													),
												}}
											/>
											{sending_change_state ||
											application.is_cancelled() ||
											application.is_rejected() ||
											application.is_confirmed() ||
											application.is_reported() ? (
												application.is_reported() ? (
													<a
														className="btn btn-flat btn-fill btn-success disabled btn-with-icon"
														href="/#"
														onClick={(e) => e.preventDefault()}>
														<h5>
															<img src={icon_ok} alt="" />
															{t("general:confirmed")}
														</h5>
													</a>
												) : (
													<a
														className="btn btn-flat btn-fill btn-success disabled"
														href="/#"
														onClick={(e) => e.preventDefault()}>
														<h5>{t("got_the_job_offer")}</h5>
													</a>
												)
											) : application.job.has_employer() ? (
												<a
													className="btn btn-flat btn-fill btn-success"
													href="/#"
													onClick={(e) => confirmApplication(e)}>
													<h5>{t("got_the_job_offer")}</h5>
												</a>
											) : (
												<a
													className="btn btn-flat btn-fill btn-success"
													href="/#"
													onClick={(e) => confirmExternalApplication(e)}>
													<h5>{t("got_the_job_offer")}</h5>
												</a>
											)}
											{application.job.has_employer() ? (
												sending_change_state ||
												application.is_rejected() ||
												application.is_confirmed() ||
												application.is_cancelled() ? (
													!application.is_cancelled() ? (
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-flat btn-fill btn-danger disabled btn-with-icon">
															<h5>
																<img src={icon_ok} alt="" />
																{t("general:cancelled")}
															</h5>
														</button>
													) : (
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-flat btn-fill btn-danger disabled">
															<h5>{t("cancel_application")}</h5>
														</button>
													)
												) : (
													<button
														className="btn btn-flat btn-fill btn-danger"
														onClick={(e) => rejectApplication(e)}>
														<h5>{t("cancel_application")}</h5>
													</button>
												)
											) : (
												""
											)}
											<div className="bottom-wrapper">
												{application.has_attachment_asset_url() ? (
													<a
														href={application.attachment.formatted_url()}
														className="btn btn-smaller btn-flat btn-hollow"
														target="_blank"
														rel="noopener noreferrer">
														<h5>{t("general:resume___cv_file")}</h5>
													</a>
												) : (
													""
												)}
												{application.has_portfolio_link_url() ? (
													<a
														href={application.formatted_portfolio_link_url()}
														className="btn btn-smaller btn-flat btn-hollow"
														target="_blank"
														rel="noopener noreferrer">
														<h5>{t("general:reference_link")}</h5>
													</a>
												) : (
													""
												)}
												<div className="applied-at-wrapper">
													<h6>{t("general:applied_at")}</h6>
													<p>
														{application.formatted_applied_at()}
														<span className="description">
															({application.formatted_applied_at_past_time(t)})
														</span>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button
											className="btn btn-flat btn-fill"
											onClick={(e) => closeModal(e)}>
											<h5>{t("general:close")}</h5>
										</button>
									</div>
								</div>
							) : (
								""
							)}
						</CSSTransitionGroup>

						<CSSTransitionGroup
							component="div"
							transitionName="modal-wrapper-with-modal-slide"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={300}>
							{change_state_message_modal.open ? (
								<div className="modal-wrapper double-confirm-modal-wrapper">
									<div className="modal-inner-wrapper">
										<div className="modal-bg" />
										<div
											className={
												`modal double-confirm-modal ` +
												`${
													change_state_message_modal.action_type === "report"
														? "with-banner"
														: ""
												} ` +
												`${
													change_state_message_modal.action_type === "cancel"
														? "with-pop-up-icon"
														: ""
												}`
											}>
											<div
												className="image-wrapper"
												style={{
													backgroundImage: `url(${
														change_state_message_modal.action_type === "report"
															? modal_banner_report
															: change_state_message_modal.action_type ===
															  "cancel"
															? modal_pop_up_cancel
															: ""
													})`,
												}}
											/>
											<div className="content">
												<h4 className="modal-title">
													{change_state_message_modal.title}
												</h4>
												{error_messages.full_message ? (
													<h6 className="message error-message">
														{error_messages.full_message}
													</h6>
												) : (
													""
												)}
												{contain_not_only_whitespace(
													change_state_message_modal.text,
												) ? (
													<p>{change_state_message_modal.text}</p>
												) : (
													""
												)}
												<div className="buttons-wrapper">
													{!sending && !sending_change_state ? (
														change_state_message_modal.action_type ===
														"report" ? (
															<button
																className="btn btn-larger btn-flat btn-ghost"
																onClick={(e) => cancelChangeState(e)}>
																<h5>{t("general:cancel")}</h5>
															</button>
														) : change_state_message_modal.action_type ===
														  "cancel" ? (
															<button
																className="btn btn-larger btn-flat btn-ghost"
																onClick={(e) => cancelChangeState(e)}>
																<h5>{t("general:back")}</h5>
															</button>
														) : (
															<button
																className="btn btn-larger btn-flat btn-ghost"
																onClick={(e) => cancelChangeState(e)}>
																<h5>{t("general:cancel")}</h5>
															</button>
														)
													) : (
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-larger btn-flat btn-ghost disabled">
															<h5>{t("general:cancel")}</h5>
														</button>
													)}
													{!sending && !sending_change_state ? (
														change_state_message_modal.action_type ===
														"report" ? (
															<button
																className="btn btn-larger btn-flat btn-fill btn-success"
																onClick={(e) => submitChangeState(e)}>
																<h5>{t("general:confirm")}</h5>
															</button>
														) : change_state_message_modal.action_type ===
														  "cancel" ? (
															<button
																className="btn btn-larger btn-flat btn-fill btn-danger"
																onClick={(e) => submitChangeState(e)}>
																<h5>{t("cancel_application")}</h5>
															</button>
														) : (
															<button
																className="btn btn-larger btn-flat btn-fill"
																onClick={(e) => submitChangeState(e)}>
																<h5>{t("general:confirm")}</h5>
															</button>
														)
													) : (
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-larger btn-flat btn-fill disabled">
															<h5>{t("general:continue")}</h5>
														</button>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								""
							)}
						</CSSTransitionGroup>
					</div>
				) : (
					""
				)}
			</CSSTransitionGroup>
		);
	}
}
export default withNamespaces([
	"userpanel_modals",
	"system_messages",
	"general",
	"states",
])(CandidateMessageModal);
