import Abstract from './abstract';
import i18n from "../../i18n";
import {contain_not_only_whitespace} from "../../assets/js/functions";

class Ad extends Abstract {

    get_url() {
        if(i18n.language === 'zh-TW') {
            return contain_not_only_whitespace(this.url_zh_tw) ?
                this.url_zh_tw :
                contain_not_only_whitespace(this.url_en) ? this.url_en : '';
        }
        if(i18n.language === 'en') {
            return contain_not_only_whitespace(this.url_en) ?
                this.url_en :
                contain_not_only_whitespace(this.url_zh_tw) ? this.url_zh_tw : '';
        }
        return '';
    }

    get_img_url() {
        if(i18n.language === 'zh-TW') {
            return contain_not_only_whitespace(this.image_url_zh_tw) ?
                this.image_url_zh_tw :
                contain_not_only_whitespace(this.image_url_en) ? this.image_url_en : '';
        }
        if(i18n.language === 'en') {
            return contain_not_only_whitespace(this.image_url_en) ?
                this.image_url_en :
                contain_not_only_whitespace(this.image_url_zh_tw) ? this.image_url_zh_tw : '';
        }
        return '';
    }

    formatted_title() {
        if(i18n.language === 'zh-TW') {
            return contain_not_only_whitespace(this.title_zh_tw) ?
                this.title_zh_tw :
                contain_not_only_whitespace(this.title_en) ? this.title_en : i18n.t('general:_not_provided_');
        }
        if(i18n.language === 'en') {
            return contain_not_only_whitespace(this.title_en) ?
                this.title_en :
                contain_not_only_whitespace(this.title_zh_tw) ? this.title_zh_tw : i18n.t('general:_not_provided_');
        }
        return i18n.t('general:_not_provided_');
    }
}

export default Ad;
