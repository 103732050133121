import React from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers.js";
import axios from "axios";
import JobApplication from "../../../models/job_application.js";
import Pagination from "../../general/Pagination.jsx";
import { get_value_from_query_string, page_smothly_scroll_to } from "../../../../assets/js/functions.js";
import { withNamespaces } from "react-i18next";
import icon_message_b from '../../../../assets/images/icon/icon_24_message_B100.svg';
import icon_message_w from '../../../../assets/images/icon/icon_24_message_white.svg';
import blank_application from "../../../../assets/images/i026-blank_user_application.svg";
import InternalUri from "../../../models/internal_uri";
import Invitation from "../../../models/invitation";

const config = new Config();
const headers = new ApiHeaders();

class MyApplicationsPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.fetchApplications = this.fetchApplications.bind(this);
        this.handleCandidateMessageModal = this.handleCandidateMessageModal.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.state = {
            active_user_id: -1,
            data: {},
            active_tab_index: 0,
            invitations: [],
            loading: true,
            changing_page: false,
            error_messages: {},
        };
        this.paginator = {};
        this.application_states = ['applying', 'rejected', 'cancelled', 'confirmed'];
    };

    componentDidMount() {
        this._isMounted = true;
        page_smothly_scroll_to(0, 300);

        const query = decodeURI(this.props.location.search);
        if(query) {
            // user_id 在 App.jsx 已檢查過，這邊是 double check。
            let message_modal_open = get_value_from_query_string('message_modal_open', query);
            if(message_modal_open) {
                let user_id = parseInt(get_value_from_query_string('user_id', query), 10);
                const internal_uri = new InternalUri();

                if(user_id && user_id !== this.props.reduxTokenAuth.currentUser.attributes.id) {
                    this.props.history.replace(internal_uri.formatted_userpanel_my_applications_page_path());
                    this.props.updateLogOutModal(true);
                    // console.log('不對的使用者');
                } else {
                    let job_id = parseInt(get_value_from_query_string('job_id', query), 10);
                    let job_application_id = parseInt(get_value_from_query_string('job_application_id', query), 10);

                    this.props.history.replace(internal_uri.formatted_userpanel_my_applications_page_path());
                    this.props.updateCandidateMessageModal(true, job_id, job_application_id);
                }
            }
        }

        let options = {
            method: 'GET',
            url: config.api_url(`/users/${this.props.reduxTokenAuth.currentUser.attributes.id}/job_applications/accepted_talent_invitations`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let invitations = [];
        axios(options)
            .then((response) => {

                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_invitations = response.data.collection;
                    for(let i=0; i<raw_invitations.length; i++) {
                        invitations.push(new Invitation({
                            ...raw_invitations[i],
                            referrer: {
                                ...raw_invitations[i].referrer, // avatar
                                first_name: raw_invitations[i].referrer_first_name,
                                last_name: raw_invitations[i].referrer_last_name,
                            }
                        }))
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        invitations,
                        active_user_id: this.props.reduxTokenAuth.currentUser.attributes.id,
                    }), () => this.fetchApplications());
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                }
            });
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        // TODO: 如果 CandidateMessageModal 關閉後的寫法有更換，這邊也要做調整！
        let refresh = get_value_from_query_string('refresh', next_props.location.search);
        if(refresh === 'true') {
            const internal_uri = new InternalUri();
            this.props.history.push(internal_uri.formatted_userpanel_my_applications_page_path());
            this.fetchApplications();
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    fetchApplications() {
        const { active_user_id } = this.state;
        let options;

        let promises_applications = [];
        for(let i=0; i<this.application_states.length; i++) {
            const key = this.application_states[i];
            let current_page = (
                this.paginator &&
                this.paginator[key] &&
                this.paginator[key].current_page
            ) ? this.paginator[key].current_page : 1;
            options = {
                method: 'GET',
                url: config.api_url(`/users/${active_user_id}/job_applications/state/${key}?page=${current_page}&external_job=true&criteria[0][name]=updated_at&criteria[0][sort_by]=desc`),
                headers: headers.getItemsFromLocalStorage(),
                json: true
            };
            promises_applications.push(axios(options));
        }
        axios.all(promises_applications)
            .then((results) => {
                for(let i=0; i<results.length; i++) {
                    headers.updateItemsToLocalStorage(results[i].headers);
                }
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    let data = this.state.data;

                    let i=0;
                    for(let key_i = 0; key_i<this.application_states.length; key_i++) {
                        const key = this.application_states[key_i];
                        let raw_applications = results[i].data.collection;
                        this.paginator[key] = results[i].data.paginator;

                        if(raw_applications.length > 0) {
                            let applications = [];
                            const { t } = this.props;
                            for(let j=0; j<raw_applications.length; j++) {
                                let application = new JobApplication(raw_applications[j]);
                                applications.push({
                                    job_title: [
                                        application.job.formatted_title(), // for sorting
                                        (key === 'applying' && (application.is_reported() || application.is_claimed())),
                                        JSON.stringify(application)
                                    ],
                                    applied_at: [application.applied_at, application.formatted_applied_at_past_time(t), application.formatted_applied_at()],
                                    updated_at: [application.updated_at, application.formatted_updated_at_past_time(t), application.formatted_updated_at()]
                                });
                            }

                            data[key] = applications;
                        } else {
                            data[key] = [];
                        }
                        i++;
                    }
                    this.setState({
                        data,
                        loading: false,
                        error_messages: {}
                    });
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                }
            });
    };

    handleCandidateMessageModal = (e, job_id, application_id) => {
        e.preventDefault();
        this.props.updateCandidateMessageModal(true, job_id, application_id);
    };

    handleTabChange(tab_index) {
        this.setState({ active_tab_index: tab_index });
    };

    setCurrentPage = (key, currentPage) => {
        page_smothly_scroll_to(0, 300);
        this.setState({ changing_page: true });

        const { active_user_id } = this.state;
        let options = {
            method: 'GET',
            url: config.api_url(`/users/${active_user_id}/job_applications/state/${key}?page=${currentPage}&external_job=true&criteria[0][name]=updated_at&criteria[0][sort_by]=desc`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_applications = response.data.collection;
                    this.paginator[key] = response.data.paginator;

                    this.setState((prev_state) => {
                        const { t } = this.props;
                        let data = prev_state.data;
                        let applications = [];
                        for(let i=0; i<raw_applications.length; i++) {
                            let application = new JobApplication(raw_applications[i]);

                            applications.push({
                                job_title: [
                                    application.job.formatted_title(), // for sorting
                                    (key === 'applying' && (application.is_reported() || application.is_claimed())),
                                    JSON.stringify(application)
                                ],
                                applied_at: [application.applied_at, application.formatted_applied_at_past_time(t), application.formatted_applied_at()],
                                updated_at: [application.updated_at, application.formatted_updated_at_past_time(t), application.formatted_updated_at()]
                            });
                        }
                        data[key] = applications;

                        return ({
                            ...prev_state,
                            data,
                            changing_page: false,
                            error_messages: {},
                        });
                    });
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        changing_page: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                }
            });
    };

    render() {

        const { data, invitations, active_tab_index, changing_page, loading, error_messages } = this.state;
        const { paginator } = this;
        const { handleCandidateMessageModal, handleTabChange, setCurrentPage } = this;
        const { t } = this.props;
        const internal_uri = new InternalUri();

        if(loading) {
            return (
                <div className="userpanel-my-applications">
                    <div className="container-fluid">
                        <div className="inner-wrapper">
                            <h2>{t('general:my_applications')}</h2>
                            {`${t('general:loading')}...`}
                        </div>
                    </div>
                </div>
            );
        }

        const applications_columns_pc = [{
            Header: () => <h5>{t('job_title')}</h5>,
            accessor: 'job_title',
            Cell: props => {
                const awaiting_confirmation = props.value[1];
                const application = new JobApplication(JSON.parse(props.value[2]));
                return (
                    <div className="job-title-wrapper">
                        <div className="job-title-inner-wrapper">
                            <div className="badges">
                                {
                                    (awaiting_confirmation) ?
                                        <span className="badge badge-green">{t('general:awaiting_confirmation')}</span> :
                                        application.has_event() ?
                                            <span className="badge badge-story-m">{t(application.events[0].get_i18n_key())}</span> :
                                            ''
                                }
                                {(!application.job.has_employer()) ? <span className="badge badge-blue">{t('general:external_website')}</span> : ''}
                                {
                                    (application.has_referrer()) ?
                                        <span className="badge badge-green-reward tooltip-wrapper right">
                                            {t('general:referred')}
                                            <div className="tooltip">
                                                <h6 dangerouslySetInnerHTML={{__html: t('referred_by__', { referrer_name: application.formatted_referrer_user_name() })}} />
                                            </div>
                                        </span> : ''
                                }
                            </div>
                            <div className="content">
                                <Link
                                    to={internal_uri.formatted_frontend_job_page_path(application.job.id, application.job.slug)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <h5 className="job-title">{application.job.formatted_title()}</h5>
                                </Link>
                                {
                                    (application.job.has_employer()) ?
                                        <Link
                                            to={internal_uri.formatted_frontend_employer_page_path(application.job.employer.id, application.job.employer.slug)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <h5 className="employer-name">{application.job.formatted_employer_name()}</h5>
                                        </Link> :
                                        <h5 className="employer-name">{application.job.formatted_employer_name()}</h5>
                                }
                            </div>
                            {
                                (application.job.has_employer()) ?
                                    <button
                                        className={`btn btn-smaller btn-flat ${(application.has_unread_messages() ? 'btn-fill' : 'btn-ghost')}`}
                                        onClick={ e => handleCandidateMessageModal(e, application.job.id, application.id) }
                                    >
                                        <h5>
                                            <img src={(application.has_unread_messages()) ? icon_message_w : icon_message_b} alt="" />
                                            {application.messages_count}
                                            <span>{t('general:_messages')}</span>
                                        </h5>
                                    </button> :
                                    <button
                                        className={`btn btn-smaller btn-flat ${(application.has_unread_messages() ? 'btn-fill' : 'btn-hollow')}`}
                                        onClick={ e => handleCandidateMessageModal(e, application.job.id, application.id) }
                                    >
                                        <h5 className="details">{t('general:details')}</h5>
                                    </button>
                            }
                        </div>
                    </div>
                );
            },
            //364 寬度在 CSS 那邊是寫死的
            // width: 364
        }, {
            Header: () => <h5>{t('general:applied')}</h5>,
            accessor: 'applied_at',
            Cell: props => (
                <div className="time-wrapper tooltip-wrapper">
                    <h6 className="time">{props.value[1]}</h6>
                    <div className="tooltip">
                        <h6>{props.value[2]}</h6>
                    </div>
                </div>
            ),
            width: 134
        }, {
            Header: () => <h5>{t('general:updated')}</h5>,
            accessor: 'updated_at',
            Cell: props => (
                <div className="time-wrapper tooltip-wrapper">
                    <h6 className="time">{props.value[1]}</h6>
                    <div className="tooltip">
                        <h6>{props.value[2]}</h6>
                    </div>
                </div>
            ),
            width: 134
        }];

        // 992px
        const applications_columns_tl = [{
            Header: () => <h5>{t('job_title')}</h5>,
            accessor: 'job_title',
            Cell: props => {
                const awaiting_confirmation = props.value[1];
                const application = new JobApplication(JSON.parse(props.value[2]));
                return (
                    <div className="job-title-wrapper">
                        <div className="job-title-inner-wrapper">
                            <div className="badges">
                                {
                                    (awaiting_confirmation) ?
                                        <span className="badge badge-green">{t('general:awaiting_confirmation')}</span> :
                                        application.has_event() ?
                                            <span className="badge badge-story-m">{t(application.events[0].get_i18n_key())}</span> :
                                            ''
                                }
                                {(!application.job.has_employer()) ? <span className="badge badge-blue">{t('general:external_website')}</span> : ''}
                                {
                                    (application.has_referrer()) ?
                                        <span className="badge badge-green-reward tooltip-wrapper">
                                            {t('general:referred')}
                                            <div className="tooltip">
                                                <h6 dangerouslySetInnerHTML={{__html: t('referred_by__', { referrer_name: application.formatted_referrer_user_name() })}} />
                                            </div>
                                        </span> : ''
                                }
                            </div>
                            <div className="content">
                                <Link
                                    to={internal_uri.formatted_frontend_job_page_path(application.job.id, application.job.slug)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <h5 className="job-title">{application.job.formatted_title()}</h5>
                                </Link>
                                {
                                    (application.job.has_employer()) ?
                                        <Link
                                            to={internal_uri.formatted_frontend_employer_page_path(application.job.employer.id, application.job.employer.slug)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <h5 className="employer-name">{application.job.formatted_employer_name()}</h5>
                                        </Link> :
                                        <h5 className="employer-name">{application.job.formatted_employer_name()}</h5>
                                }
                            </div>
                            {
                                (application.job.has_employer()) ?
                                    <button
                                        className={`btn btn-smaller btn-flat ${(application.has_unread_messages() ? 'btn-fill' : 'btn-ghost')}`}
                                        onClick={ e => handleCandidateMessageModal(e, application.job.id, application.id) }
                                    >
                                        <h5>
                                            <img src={(application.has_unread_messages()) ? icon_message_w : icon_message_b} alt="" />
                                            {application.messages_count}
                                        </h5>
                                    </button> :
                                    <button
                                        className={`btn btn-smaller btn-flat ${(application.has_unread_messages() ? 'btn-fill' : 'btn-hollow')}`}
                                        onClick={ e => handleCandidateMessageModal(e, application.job.id, application.id) }
                                    >
                                        <h5 className="details">{t('general:details')}</h5>
                                    </button>
                            }
                        </div>
                    </div>
                );
            },
        }, {
            Header: () => <h5>{t('general:updated')}</h5>,
            accessor: 'updated_at',
            Cell: props =>
                <div className="time-wrapper tooltip-wrapper">
                    <h6 className="time">{props.value[1]}</h6>
                    <div className="tooltip">
                        <h6>{props.value[2]}</h6>
                    </div>
                </div>,
            width: 134
        }];

        // 600 px
        const applications_columns_mb = [{
            Header: () => <h5>{t('job_title')}</h5>,
            accessor: 'job_title',
            Cell: props => {
                const awaiting_confirmation = props.value[1];
                const application = new JobApplication(JSON.parse(props.value[2]));
                return (
                    <div className="job-title-wrapper">
                        <div className="job-title-inner-wrapper">
                            <div className="badges">
                                {
                                    (awaiting_confirmation) ?
                                        <span className="badge badge-green">{t('general:awaiting_confirmation')}</span> :
                                        application.has_event() ?
                                            <span className="badge badge-story-m">{t(application.events[0].get_i18n_key())}</span> :
                                            ''
                                }
                                {(!application.job.has_employer()) ? <span className="badge badge-blue">{t('general:external_website')}</span> : ''}
                                {(application.has_referrer()) ? <span className="badge badge-green-reward">{t('general:referred')}</span> : ''}
                            </div>
                            <div className="content">
                                <Link
                                    to={internal_uri.formatted_frontend_job_page_path(application.job.id, application.job.slug)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <h5 className="job-title">{application.job.formatted_title()}</h5>
                                </Link>
                                {
                                    (application.job.has_employer()) ?
                                        <Link
                                            to={internal_uri.formatted_frontend_employer_page_path(application.job.employer.id, application.job.employer.slug)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <h5 className="employer-name">{application.job.formatted_employer_name()}</h5>
                                        </Link> :
                                        <h5 className="employer-name">{application.job.formatted_employer_name()}</h5>
                                }
                                {
                                    (application.job.has_employer()) ?
                                        <button
                                            className={`btn btn-smaller btn-flat ${(application.has_unread_messages() ? 'btn-fill' : 'btn-ghost')}`}
                                            onClick={ e => handleCandidateMessageModal(e, application.job.id, application.id) }
                                        >
                                            <h5>
                                                <img src={(application.has_unread_messages()) ? icon_message_w : icon_message_b} alt="" />
                                                {application.messages_count}
                                            </h5>
                                        </button> :
                                        <button
                                            className={`btn btn-smaller btn-flat ${(application.has_unread_messages() ? 'btn-fill' : 'btn-hollow')}`}
                                            onClick={ e => handleCandidateMessageModal(e, application.job.id, application.id) }
                                        >
                                            <h5 className="details">{t('general:details')}</h5>
                                        </button>
                                }
                            </div>
                            <h6 className="updated-at">{t('general:updated')} {application.formatted_updated_at_past_time(t)}</h6>
                        </div>
                    </div>
                );
            },
        }];

        return (
            <div className="userpanel-my-applications">
                <div className="container-fluid">
                    <div className="inner-wrapper">
                        {
                            (invitations.length) ?
                                <div className="invitations">
                                    {
                                        invitations.map((invitation, i) =>
                                            <div className="invitation" key={i}>
                                                <div className="left">
                                                    <div className="referrer">
                                                        <div className="avatar">
                                                            <div
                                                                className='image-wrapper'
                                                                style={{backgroundImage: `url(${invitation.referrer.formatted_avatar_url()})`}}
                                                            />
                                                        </div>
                                                        <h5>{invitation.referrer.formatted_name()}</h5>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <Link
                                                        to={internal_uri.formatted_frontend_job_page_path(invitation.job.id, invitation.job.slug)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <h5 className="job-title">{invitation.job.formatted_title()}</h5>
                                                    </Link>
                                                    <Link
                                                        to={internal_uri.formatted_frontend_employer_page_path(invitation.job.employer.id, invitation.job.employer.slug)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <h5 className="employer-name">{invitation.job.formatted_employer_name()}</h5>
                                                    </Link>
                                                    <Link
                                                        className="btn btn-flat btn-hollow"
                                                        to={`${internal_uri.formatted_frontend_job_invitation_page_path(invitation.id)}?job_id=${invitation.job.id}`}
                                                        data-event-category="381_receiver_details"
                                                    >
                                                        <h5>{t('details')}</h5>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div> : ''
                        }
                        <h2>{t('general:my_applications')}</h2>
                        {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                        {
                            (Object.keys(data).length && (data.applying.length || data.cancelled.length || data.confirmed.length || data.rejected.length)) ?
                                <Tabs
                                    className="react-tabs tabs"
                                    selectedIndex={active_tab_index}
                                    onSelect={index => handleTabChange(index)}
                                >
                                    <TabList>
                                        {
                                            this.application_states.map((key, i) => (
                                                <Tab key={i}>
                                                    <h5>
                                                        {t(`states:${key}`)}
                                                        {(paginator[key].total_entries) ? <span className="counting-block">{paginator[key].total_entries}</span> : ''}
                                                    </h5>
                                                </Tab>
                                            ))
                                        }
                                    </TabList>
                                    <div className="tabs-mb">
                                        <div className="select-wrapper">
                                            <Select
                                                className="drop-down drop-down-larger drop-down-grey"
                                                clearable={false}
                                                searchable={false}
                                                value={active_tab_index}
                                                onChange={ value => handleTabChange(value.value) }
                                                options={
                                                    this.application_states.map((key, i) => ({
                                                        value: i,
                                                        label: t(`states:${key}`),
                                                        count: paginator[key].total_entries
                                                    }))
                                                }
                                                valueRenderer={(option) =>
                                                    <div>
                                                        {option.label}
                                                        {option.count ? <span className="counting-block">{option.count}</span> : ''}
                                                    </div>
                                                }
                                                optionRenderer={(option) =>
                                                    <div>
                                                        {option.label}
                                                        {option.count ? <span className="counting-block">{option.count}</span> : ''}
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.application_states.map((key, i) => (
                                            <TabPanel key={i}>
                                                <div className="table-wrapper-pc table-wrapper block">
                                                    <ReactTable
                                                        loading={changing_page}
                                                        data={data[key]}
                                                        columns={applications_columns_pc}
                                                        defaultPageSize={20}
                                                        minRows={0}
                                                        resizable={false}
                                                        showPagination={false}
                                                        getTrGroupProps={(state, rowInfo, column, instance) => {
                                                            const application = new JobApplication(JSON.parse(rowInfo.original.job_title[2]));
                                                            if(application.has_unread_messages()) {
                                                                return {className: 'message_unread'}
                                                            }
                                                        }}
                                                        noDataText={t('general:your_list_is_empty_')}
                                                    />
                                                </div>
                                                <div className="table-wrapper-tl table-wrapper block">
                                                    <ReactTable
                                                        loading={changing_page}
                                                        data={data[key]}
                                                        columns={applications_columns_tl}
                                                        defaultPageSize={20}
                                                        minRows={0}
                                                        resizable={false}
                                                        showPagination={false}
                                                        getTrGroupProps={(state, rowInfo, column, instance) => {
                                                            const application = new JobApplication(JSON.parse(rowInfo.original.job_title[2]));
                                                            if(application.has_unread_messages()) {
                                                                return {className: 'message_unread'}
                                                            }
                                                        }}
                                                        noDataText={t('general:your_list_is_empty_')}
                                                    />
                                                </div>
                                                <div className="table-wrapper-mb table-wrapper block">
                                                    <ReactTable
                                                        loading={changing_page}
                                                        data={data[key]}
                                                        columns={applications_columns_mb}
                                                        defaultPageSize={20}
                                                        minRows={0}
                                                        resizable={false}
                                                        showPagination={false}
                                                        getTrGroupProps={(state, rowInfo, column, instance) => {
                                                            const application = new JobApplication(JSON.parse(rowInfo.original.job_title[2]));
                                                            if(application.has_unread_messages()) {
                                                                return {className: 'message_unread'}
                                                            }
                                                        }}
                                                        noDataText={t('general:your_list_is_empty_')}
                                                    />
                                                </div>
                                                {
                                                    (paginator[key].total_entries) ?
                                                        <div>
                                                            <div className="pagination-wrapper-pc hidden-xs hidden-sm">
                                                                <Pagination
                                                                    currentPage={paginator[key].current_page}
                                                                    pagesCount={paginator[key].total_pages}
                                                                    pagedCount={5}
                                                                    previous_page={paginator[key].previous_page}
                                                                    next_page={paginator[key].next_page}
                                                                    setCurrentPage={(page) => setCurrentPage(key, page)}
                                                                />
                                                            </div>
                                                            <div className="pagination-wrapper-mb">
                                                                <Pagination
                                                                    currentPage={paginator[key].current_page}
                                                                    pagesCount={paginator[key].total_pages}
                                                                    pagedCount={4}
                                                                    previous_page={paginator[key].previous_page}
                                                                    next_page={paginator[key].next_page}
                                                                    setCurrentPage={(page) => setCurrentPage(key, page)}
                                                                />
                                                            </div>
                                                        </div> : ''
                                                }
                                            </TabPanel>
                                        ))
                                    }
                                </Tabs> :
                                <div className="blank-application-wrapper">
                                    <div className="graph">
                                        <div
                                            className="image-wrapper"
                                            style={{backgroundImage: `url(${blank_application})`}}
                                        />
                                    </div>
                                    <h2>{t('no_applications_yet')}</h2>
                                    <p>{t('apply_jobs_and_track_your_applications_here_')}</p>
                                    <Link
                                        to={internal_uri.formatted_frontend_jobs_page_path()}
                                        className="btn btn-larger btn-flat btn-fill"
                                    >
                                        <h5>{t('view_jobs')}</h5>
                                    </Link>
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}

export default withNamespaces(['userpanel_my_applications', 'general', 'states'])(MyApplicationsPage);
