import React from 'react';
import { withNamespaces } from "react-i18next";
import connection_off from '../../../../assets/images/i028-disconnected.svg';

class ConnectionOffModal extends React.Component {

    constructor(props) {
        super(props);
        this.checkConnection = this.checkConnection.bind(this);
        this.state = {};
    };

    componentDidMount() {
        this.interval = setInterval(() => this.checkConnection(), 1000);
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    };

    checkConnection() {
        if(navigator.onLine && this.props.site_state.connection_off_modal) this.props.updateConnectionOffModal(false);
    };

    render() {

        const { site_state } = this.props;
        const { t } = this.props;

        return (
            <div className={`connection-off-modal-wrapper ${(site_state.connection_off_modal) ? 'open' : ''}`}>
                <div className="connection-off-modal">
                    <img src={connection_off} alt="" />
                    <div className="content">
                        <h4 className="modal-title">{t('oops_')}</h4>
                        <p>{t('unable_to_connect_to_meet_jobs')}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces(['general'])(ConnectionOffModal);