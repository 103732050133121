import C from "./constants";
import Employer from "./components/models/employer";
import { uuidv4 } from "./assets/js/functions";

// frontend

export const updateApplyModal = (open, job, referrer) => ({
  type: C.UPDATE_APPLY_MODAL,
  open,
  job,
  referrer,
});

export const updateInviteToApplyModal = (open, job) => ({
  type: C.UPDATE_INVITE_TO_APPLY_MODAL,
  open,
  job,
});

// dashboard

export const updateEmployerMessageModal = (
  open,
  company_id,
  job_id,
  application_id
) => ({
  type: C.UPDATE_EMPLOYER_MESSAGE_MODAL,
  open,
  company_id,
  job_id,
  application_id,
});

export const updateCreateEmployerModal = (create_employer_modal) => ({
  type: C.UPDATE_CREATE_EMPLOYER_MODAL,
  create_employer_modal,
});

export const updateUpdateDefaultPlanModal = (
  open,
  plan_name,
  contract_url
) => ({
  type: C.UPDATE_UPDATE_DEFAULT_PLAN_MODAL,
  open,
  plan_name,
  contract_url,
});

export const updateAddMemberModal = (open, company_id, role_id) => ({
  type: C.UPDATE_ADD_MEMBER_MODAL,
  open,
  company_id,
  role_id,
});

export const updateEditMemberModal = (
  open,
  roleship,
  role,
  creator_user_id
) => ({
  type: C.UPDATE_EDIT_MEMBER_MODAL,
  open,
  roleship,
  role,
  creator_user_id,
});

export const updateEditInvitedMemberModal = (
  open,
  employer_id,
  invited_roleship,
  role,
  creator_user_id
) => ({
  type: C.UPDATE_EDIT_INVITED_MEMBER_MODAL,
  open,
  employer_id,
  invited_roleship,
  role,
  creator_user_id,
});

export const updateContactMeetJobsModal = (contact_meet_jobs_modal) => ({
  type: C.UPDATE_CONTACT_MEET_JOBS_MODAL,
  contact_meet_jobs_modal,
});

export const updateWelcomeEmployerModal = (
  open,
  banner_img_url,
  title,
  content,
  and_content,
  plus_content
) => ({
  type: C.UPDATE_WELCOME_EMPLOYER_MODAL,
  open,
  banner_img_url,
  title,
  content,
  and_content,
  plus_content,
});

export const updateEmployerProfilePendingDegreeOfCompletion = (
  employer_profile_pending_degree_of_completion
) => ({
  type: C.UPDATE_EMPLOYER_PROFILE_PENDING_DEGREE_OF_COMPLETION,
  employer_profile_pending_degree_of_completion,
});

// userpanel

export const updateCandidateMessageModal = (open, job_id, application_id) => ({
  type: C.UPDATE_CANDIDATE_MESSAGE_MODAL,
  open,
  job_id,
  application_id,
});

export const updateReferralMessageModal = (open, job_id, application_id) => {
  console.log("updateReferralMessageModal", open);
  return {
    type: C.UPDATE_REFERRAL_MESSAGE_MODAL,
    open,
    job_id,
    application_id,
  };
};

export const updateAddReferenceNotesModal = (open, job, application) => ({
  type: C.UPDATE_ADD_REFERENCE_NOTES_MODAL,
  open,
  job,
  application,
});

export const updateReadReferenceNotesModal = (open, application) => ({
  type: C.UPDATE_READ_REFERENCE_NOTES_MODAL,
  open,
  application,
});

export const updateClaimReferralRewardModal = (open, application) => ({
  type: C.UPDATE_CLAIM_REFERRAL_REWARD_MODAL,
  open,
  application,
});

export const updateMyReferralLinkModal = (open) => ({
  type: C.UPDATE_MY_REFERRAL_LINK_MODAL,
  open,
});

// general

export const updateSearchBar = (search_bar) => ({
  type: C.UPDATE_SEARCH_BAR,
  search_bar,
});

export const updateKeepSearchBar = (keep_search_bar) => ({
  type: C.UPDATE_KEEP_SEARCH_BAR,
  keep_search_bar,
});

export const updateMobileMenu = (mobile_menu) => ({
  type: C.UPDATE_MOBILE_MENU,
  mobile_menu,
});

export const updateSubMenu = (sub_menu) => ({
  type: C.UPDATE_SUB_MENU,
  sub_menu,
});

export const updateDashboardSidebarCollapsed = (
  dashboard_sidebar_collapsed
) => ({
  type: C.UPDATE_DASHBOARD_SIDEBAR_COLLAPSED,
  dashboard_sidebar_collapsed,
});

export const updateEntranceFormModal = (entrance_form_modal) => ({
  type: C.UPDATE_ENTRANCE_FORM_MODAL,
  entrance_form_modal,
});

export const updateLogOutModal = (log_out_modal) => ({
  type: C.UPDATE_LOG_OUT_MODAL,
  log_out_modal,
});

export const updateCookiePolicyCornerModal = (accepted_cookie_policy) => ({
  type: C.UPDATE_COOKIE_POLICY_CORNER_MODAL,
  accepted_cookie_policy,
});

export const updateConnectionOffModal = (connection_off_modal) => ({
  type: C.UPDATE_CONNECTION_OFF_MODAL,
  connection_off_modal,
});

export const addFlashMessage = (
  open,
  text,
  status,
  display_type,
  action_button_text
) => ({
  type: C.ADD_FLASH_MESSAGE,
  id: uuidv4(),
  open,
  text,
  status,
  display_type,
  action_button_text,
});

export const removeFlashMessage = (id) => ({
  type: C.REMOVE_FLASH_MESSAGE,
  id,
});

export const openFlashMessage = (id) => ({
  type: C.OPEN_FLASH_MESSAGE,
  id,
});

export const updateAdCornerModal = (ad_corner_modal) => ({
  type: C.UPDATE_AD_CORNER_MODAL,
  ad_corner_modal,
});

// export const updateLoading = (loading) =>
//     ({
//         type: C.UPDATE_LOADING,
//         loading
//     });

export const closeAll = () => ({
  type: C.CLOSE_ALL,
  search_bar: false,
  mobile_menu: "",
  entrance_form_modal: "",
  apply_modal: false,
  employer_message_modal: false,
  contact_meet_jobs_modal: false,
  welcome_employer_modal: false,
  create_employer_modal: false,
  add_member_modal: false,
  add_reference_notes_modal: false,
  read_reference_notes_modal: false,
  claim_referral_reward_modal: false,
  log_out_modal: false,
});

export const closeHeader = () => ({
  type: C.CLOSE_HEADER,
  search_bar: false,
  mobile_menu: "",
  sub_menu: false,
});

// search words

export const updateSearchKeywords = (keyword, keyword_place) => ({
  type: C.UPDATE_SEARCH_KEYWORDS,
  keyword,
  keyword_place,
});

// current user

export const updateCurrentUserCompanies = (raw_employers) => {
  let employers = [];
  for (let i = 0; i < raw_employers.length; i++) {
    const employer = raw_employers[i];
    employers.push({
      id: employer.id,
      name: employer.name,
      slug: employer.slug,
      logo: { ...employer.logo },
      actived_state: employer.actived_state,
      apply_weekly_read_rate: employer.apply_weekly_read_rate,
      apply_weekly_replied_rate: employer.apply_weekly_replied_rate,
      latest_plan: { ...employer.latest_plan },
      contact: { ...employer.contact },
      assistant: { ...employer.assistant },
    });
  }
  return {
    type: C.UPDATE_CURRENT_USER_COMPANIES,
    companies: employers,
  };
};

export const updateCurrentUserActiveCompany = (raw_employer) => {
  const employer = new Employer(JSON.parse(JSON.stringify(raw_employer)));
  return {
    type: C.UPDATE_CURRENT_USER_ACTIVE_COMPANY,
    active_company: {
      id: raw_employer.id,
      name: raw_employer.name,
      slug: raw_employer.slug,
      logo: { ...raw_employer.logo },
      actived_state: raw_employer.actived_state,
      apply_weekly_read_rate: raw_employer.apply_weekly_read_rate,
      apply_weekly_replied_rate: raw_employer.apply_weekly_replied_rate,
      latest_plan: { ...raw_employer.latest_plan },
      contact: { ...raw_employer.contact },
      assistant: { ...raw_employer.assistant },

      employer_profile_degree_of_completion: employer.get_employer_profile_degree_of_completion(),
    },
  };
};

export const updateCurrentUserEmployerRoles = (employer_roles) => ({
  type: C.UPDATE_CURRENT_USER_EMPLOYER_ROLES,
  employer_roles,
});

export const updateCurrentUserReferralCodes = (referral_codes) => ({
  type: C.UPDATE_CURRENT_USER_REFERRAL_CODES,
  referral_codes,
});

export const addCurrentUserReferralCode = (referral_code) => ({
  type: C.ADD_CURRENT_USER_REFERRAL_CODE,
  referral_code,
});

export const deleteCurrentUserReferralCode = (referral_code) => ({
  type: C.DELETE_CURRENT_USER_REFERRAL_CODE,
  referral_code,
});

export const updateCurrentUserIsReferralUser = (is_referral_user) => ({
  type: C.UPDATE_CURRENT_USER_IS_REFERRAL_USER,
  is_referral_user,
});

export const updateCurrentUserIsEmployerUser = (is_employer_user) => ({
  type: C.UPDATE_CURRENT_USER_IS_EMPLOYER_USER,
  is_employer_user,
});

// events

export const updateAds = (ads) => ({
  type: C.UPDATE_ADS,
  ads,
});

// utm

export const updateUtm = (utm) => ({
  type: C.UPDATE_UTM,
  utm: {
    source: utm.source ? utm.source : null,
    medium: utm.medium ? utm.medium : null,
    campaign: utm.campaign ? utm.campaign : null,
  },
});

// cover ads
export const updateAdsCover = (ads) => ({
  type: C.UPDATE_ADS_COVER,
  ads,
});

export const updateCoverAdsModalDismiss = (ad_dismissed) => ({
  type: C.UPDATE_COVER_ADS_MODAL_DISMISS,
  ad_dismissed,
});

export const updateAdCoverModal = (ad_cover_modal) => ({
  type: C.UPDATE_AD_COVER_MODAL,
  ad_cover_modal,
});
