export default {
    // matching
    processing: "Processing",
    not_recommended: "Not recommended",
    recommended: "Recommended",

    // applications
    applying: "Applying",
    rejected: "Not selected",
    cancelled: "Cancelled",
    confirmed: "Confirmed",

    // applier state key
    // applying: "Applying",
    // cancelled: "Cancelled",
    reported: "Awaiting confirmation",

    // employer state key
    initialized: "Initialized",
    // rejected: "Not selected",
    // confirmed: "Confirmed",
    archived: "Archived",
    processed: "Processed",

    // referrer state key
    reviewed: "Reviewed",
    claimed: "Claimed",

    // jobs
    all: "All",
    draft: "Draft",
    published: "Published",
    closed: "Closed",

    // paid period
    annually: "Annum",
    monthly: "Month",
    daily: "Day",
    hourly: "Hour",

    // work type
    full_time: "Full time",
    part_time: "Part time",
    internship: "Internship",
    volunteer: "Volunteer",

    // contract type
    permanent: "Permanent",
    contract: "Contract",

    // company size
    company_size_0: "Not provided",
    company_size_1: "1 - 10",
    company_size_2: "11 - 50",
    company_size_3: "51 - 200",
    company_size_4: "201 - 500",
    company_size_5: "501 - 1,000",
    company_size_6: "1,001 - 5,000",
    company_size_7: "5,001 - 10,000",
    company_size_8: "10,000+",

    // invoice payment status
    paid: "Paid",
    // cancelled: "Cancelled",
    overdue: "Overdue",

    // paid type
    remittance: "Remittance",
    stripe: "Stripe",
};