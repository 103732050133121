import React from 'react';
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import { withNamespaces, Interpolate } from "react-i18next";
import { CSSTransitionGroup } from 'react-transition-group'
import InternalUri from "../../../models/internal_uri";
import modal_banner from "../../../../assets/images/i045-claim-referral.svg";

const config = new Config();
const headers = new ApiHeaders();

class ClaimReferralRewardModal extends React.Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            active_application: {},
            sending: false,
            error_messages: {}
        };
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(next_props.site_state.claim_referral_reward_modal.open !== this.props.site_state.claim_referral_reward_modal.open) {
            if(next_props.site_state.claim_referral_reward_modal.open) {
                this.setState((prev_state) => ({
                    ...prev_state,
                    active_application: next_props.site_state.claim_referral_reward_modal.application,
                    error_messages: {},
                }));
            }
        }
    };

    closeModal = e => {
        e.preventDefault();
        this.props.updateClaimReferralRewardModal(false, {});
    };

    submitForm = e => {
        e.preventDefault();
        const { active_application, sending } = this.state;
        const { reduxTokenAuth } = this.props;
        if(!sending) {
            this.setState({ sending: true });
            let options = {
                method: 'PUT',
                url: config.api_url(`/users/${reduxTokenAuth.currentUser.attributes.id}/referrals/${active_application.get_referral_id()}`),
                headers: headers.getItemsFromLocalStorage(),
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    this.setState({
                        error_messages: {},
                        sending: false
                    }, () => {
                        const internal_uri = new InternalUri();
                        this.props.history.push(`${internal_uri.formatted_userpanel_my_referrals_page_path()}?refresh=true`);
                        this.props.updateClaimReferralRewardModal(false, {});
                    });
                })
                .catch((error) => {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    this.setState({
                        error_messages: {
                            full_message:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors.full_message :
                                    'There was an error. Please try again later.'
                        },
                        sending: false
                    });
                })
        }
    };

    render() {

        const { site_state } = this.props;
        const { active_application, sending, error_messages } = this.state;
        const { closeModal, submitForm } = this;
        const { t } = this.props;

        return (
            <CSSTransitionGroup
                component="div"
                transitionName="modal-wrapper-with-modal-slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {(site_state.claim_referral_reward_modal.open) ?
                    <div className="modal-wrapper double-confirm-modal-wrapper">
                        <div className="modal-inner-wrapper">
                            <div className="modal-bg" />
                            <div className="modal double-confirm-modal with-banner">
                                <div
                                    className="image-wrapper"
                                    style={{backgroundImage: `url(${modal_banner})`}}
                                />
                                <div className="content">
                                    <h4 className="modal-title">{t('claim_referral_reward')}</h4>
                                    {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                                    <p>
                                        <Interpolate
                                            i18nKey="please_be_sure___has_got_the_offer__the_employer_would_also_receive_a_notice_to_approve_"
                                            useDangerouslySetInnerHTML={true}
                                            applier_name={active_application.formatted_applier_name()}
                                        />
                                    </p>
                                    <div className="buttons-wrapper">
                                        {(!sending) ?
                                            <button className="btn btn-larger btn-flat btn-ghost" onClick={ e => closeModal(e) }>
                                                <h5>{t('general:cancel')}</h5>
                                            </button> :
                                            <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-ghost disabled">
                                                <h5>{t('general:cancel')}</h5>
                                            </button>
                                        }
                                        {(!sending) ?
                                            <button className="btn btn-larger btn-flat btn-fill btn-success" onClick={ e => submitForm(e) }>
                                                <h5>{t('general:claim')}</h5>
                                            </button> :
                                            <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-fill btn-success disabled">
                                                <h5>{t('general:claim')}</h5>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''
                }
            </CSSTransitionGroup>
        );
    };
}

export default withNamespaces(['userpanel_modals', 'general'])(ClaimReferralRewardModal);
