export default {
    edit_employer_profile: "Edit employer profile",
    view_employer_page: "View employer page",

    summaryCard: {
        job: {
            content: "{{number}} job openings",
            subContent: "Total {{number}} jobs"
        },
        invoice: {
            content: "{{number}} unpaid invoice",
            subContent: "Total {{number}} invoices"
        },
        cvReadRate: {
            title: "CV Read Rate",
            leftSubTitle: "Average read rate",
            rightSubTitle: "Read rate last 7 days",
            tooltipContent: "Based on the number of applications you viewed in the past 7 days.",
            noDataContent: "No application yet",
        },
        messageReplyRate: {
            title: "Application Reply Rate",
            leftSubTitle: "Average reply rate",
            rightSubTitle: "Reply rate last 7 days",
            tooltipContent: "Based on the number of applications you replied in the past 7 days.",
            noDataContent: "No application yet",
        }
    },

    contact: "Contact",
    account_manager: "Account Manager",
};