import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group'
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Job from "../../../models/job.js";
import Employer from "../../../models/employer.js";
import User from "../../../models/user";
import { withNamespaces, Interpolate } from "react-i18next";
import icon_fb from '../../../../assets/images/icon/icon_fb_color.svg';
import icon_linkedin from '../../../../assets/images/icon/icon_linkedin_color.svg';
import icon_twitter from '../../../../assets/images/icon/icon_twitter_color.svg';
import icon_email from '../../../../assets/images/icon/icon_email_color.svg';
import icon_ok from '../../../../assets/images/icon/icon_28_ok_white.svg';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import InternalUri from "../../../models/internal_uri";
import {copy_string} from "../../../../assets/js/functions";

const config = new Config();
const headers = new ApiHeaders();

class ReferPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleCopy = this.handleCopy.bind(this);
        this.acceptWarningMessage = this.acceptWarningMessage.bind(this);
        this.updateReferDetails = this.updateReferDetails.bind(this);
        this.openLogInModal = this.openLogInModal.bind(this);
        this.handleSharing = this.handleSharing.bind(this);
        this.state = {
            active_type: '',
            active_id: -1,
            employer: {},
            job: {},
            text: {},
            show_warning: false,
            finish_copy_link: false,
            loading: true,
            error_messages: {},
        };
    };

    componentDidMount() {
        this._isMounted = true;
        const active_type = this.props.match.params.referral_type;
        const active_id = this.props.match.params.referral_type_id;

        let api_uri;
        switch (active_type) {
            case 'employer':
                api_uri = config.api_url(`/employers/${active_id}`);
                break;
            case 'job':
            default:
                api_uri = config.api_url(`/jobs/${active_id}`);
                break;
        }
        let options = {
            method: 'GET',
            url: api_uri,
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let employer, job, text;
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_data = response.data;
                    let host = window.location.origin;
                    const { t } = this.props;
                    const internal_uri = new InternalUri();

                    switch (active_type) {
                        case 'employer':
                            employer = new Employer(raw_data);
                            text = {
                                description: t('refer_this_employer_to_your_friends_and_get_reward_'),
                                link: host + internal_uri.formatted_frontend_employer_page_path(employer.id, employer.slug),
                                referral_link: host + internal_uri.formatted_frontend_employer_page_path(employer.id, employer.slug),
                            };
                            break;
                        case 'job':
                        default:
                            job = new Job(raw_data);
                            text = {
                                description:
                                    <Interpolate
                                        i18nKey="refer_this_job_to_your_friends__and_get___reward_"
                                        useDangerouslySetInnerHTML={true}
                                        reward={<span className="reward">{job.formatted_reward()}</span>}
                                    />,
                                link: host + internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
                                referral_link: host + internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
                            };
                            break;
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        active_type,
                        active_id,
                        employer,
                        job,
                        text,
                        loading: true,
                        error_messages: {},
                    }), () => this.updateReferDetails());
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    const internal_uri = new InternalUri();
                    if(error.response.status === 401) window.location.reload();
                    if(error.response.status === 404) this.props.history.push(internal_uri.formatted_frontend_error_404_page_path());
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));
                }
            });
    }

    UNSAFE_componentWillReceiveProps(next_props) {
        const { reduxTokenAuth } = next_props;
        if(reduxTokenAuth.currentUser.isSignedIn !== this.props.reduxTokenAuth.currentUser.isSignedIn) {
            this.setState({
                loading: true,
            }, () => this.updateReferDetails());
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    updateReferDetails() {
        const { reduxTokenAuth } = this.props;
        if(!reduxTokenAuth.currentUser.isSignedIn) {
            this.setState((prev_state) => ({
                ...prev_state,
                show_warning: true,
                loading: false,
                text: {
                    ...prev_state.text,
                    referral_link: `${prev_state.text.link}`,
                }
            }));
        } else {
            const user = new User(this.props.reduxTokenAuth.currentUser.attributes);
            this.setState((prev_state) => ({
                ...prev_state,
                show_warning: false,
                text: {
                    ...prev_state.text,
                    referral_link: `${prev_state.text.link}?referral_code=${user.referral_code}`,
                },
                loading: false,
                error_messages: {}
            }));
        }
    };

    openLogInModal = e => {
        e.preventDefault();
        this.props.updateEntranceFormModal('log-in');
    };

    handleCopy = (e, string) => {
        e.preventDefault();
        copy_string(e, string);
        this.setState({ finish_copy_link: true });
    };

    acceptWarningMessage = () => {
        this.setState({ show_warning: false });
    };

    handleSharing = (e, href) => {
        e.preventDefault();
        window.open(href, '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
    };

    render() {

        const { text } = this.state;
        const { employer, job, active_type, show_warning, finish_copy_link, loading, error_messages } = this.state;
        const { handleCopy, acceptWarningMessage, openLogInModal, handleSharing } = this;
        const { t } = this.props;
        const internal_uri = new InternalUri();

        if(loading) {
            return (
                <div className='page-refer content-wrapper with-gradient higher'>
                    <div className="container">
                        <div className="refer-wrapper">
                            <h3>{`${t('general:loading')}...`}</h3>
                            <div className="job-title block clearfix">
                                <h2>{`${t('general:loading')}...`}</h2>
                                <p>{`${t('general:loading')}...`}</p>
                                {
                                    (error_messages.full_message) ?
                                        <h6 className="message error-message">{error_messages.full_message}</h6> :
                                        ''
                                }
                            </div>
                            <div className='description block'>
                                <h4>{t('your_referral_link')}</h4>
                                <div className="form-row form-row-dire-col">
                                    <div className="input-btn-wrapper">
                                        <input
                                            readOnly
                                            className="input"
                                            type="text"
                                            value={`${t('general:loading')}...`}
                                        />
                                        <div className="btn btn-larger btn-flat btn-fill">
                                            <h5>{t('general:copy_link')}</h5>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    <Interpolate
                                        i18nKey="you_can_manage_your_referral_at___"
                                        useDangerouslySetInnerHTML={true}
                                        my_referrals={
                                            <Link
                                                className="link"
                                                to={internal_uri.formatted_userpanel_my_referrals_page_path()}
                                            >
                                                {t('general:my_referrals')}
                                            </Link>
                                        }
                                    />
                                </p>
                                <hr />
                                <p>{t('share_with_')}</p>
                                <div className="social-icons">
                                    <button onClick={ e => e.preventDefault() }><img src={icon_fb} alt="" /></button>
                                    <button onClick={ e => e.preventDefault() }><img src={icon_linkedin} alt="" /></button>
                                    <button onClick={ e => e.preventDefault() }><img src={icon_twitter} alt="" /></button>
                                    <button onClick={ e => e.preventDefault() }><img src={icon_email} alt="" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className='page-refer content-wrapper with-gradient higher'>
                <Helmet>
                    <title>{t('general:refer_your_friends_for')} - {(active_type === 'employer') ? employer.formatted_name() : job.formatted_title()}｜Meet.jobs</title>
                </Helmet>
                <div className="container">
                    <div className="refer-wrapper">
                        <h3>{text.description}</h3>
                        {
                            (active_type === 'employer') ?
                                <div className="job-title block clearfix">
                                    <Link
                                        to={internal_uri.formatted_frontend_employer_page_path(employer.id, employer.slug)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <h2>{employer.formatted_name()}</h2>
                                    </Link>
                                    {(employer.has_slogan()) ? <p className="slogan from-editor">{employer.slogan}</p> : ''}
                                </div> :
                                <div className="job-title block clearfix">
                                    <Link
                                        to={internal_uri.formatted_frontend_job_page_path(job.id, job.slug)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <h2>{job.formatted_title()}</h2>
                                    </Link>
                                    {
                                        (job.has_employer()) ?
                                            <Link
                                                to={internal_uri.formatted_frontend_employer_page_path(job.employer.id, job.employer.slug)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <p>{job.formatted_employer_name()}</p>
                                            </Link> :
                                            <p>{job.formatted_employer_name()}</p>
                                    }
                                </div>
                        }
                        <div className={`description block ${(show_warning) ? 'warning' : ''}`}>
                            <CSSTransitionGroup
                                component="div"
                                transitionName="refer-slide"
                                transitionEnterTimeout={500}
                                transitionLeaveTimeout={300}
                            >
                                {
                                    (show_warning) ?
                                        <div className="message-block-warning clearfix">
                                            <p>{t('if_you_refer_anonymously__you_will_not_get_any_referral_reward_')}</p>
                                            <button
                                                className="btn btn-larger btn-flat"
                                                onClick={acceptWarningMessage}
                                                data-event-category={(active_type === 'employer') ? "410_refer_employer_accept" : "321_refer_job_accept"}
                                            >
                                                {t('general:i_accept')}
                                            </button>
                                            <p>
                                                <Interpolate
                                                    i18nKey="or___here"
                                                    useDangerouslySetInnerHTML={true}
                                                    log_in={
                                                        <button
                                                            className="link"
                                                            onClick={ e => openLogInModal(e) }
                                                        >{t('general:log_in')}</button>
                                                    }
                                                />
                                            </p>
                                        </div> : ''
                                }
                            </CSSTransitionGroup>
                            <h4>{t('your_referral_link')}</h4>
                            <div className="form-row form-row-dire-col">
                                <div className="input-btn-wrapper">
                                    <input
                                        readOnly
                                        className="input"
                                        type="text"
                                        value={text.referral_link}
                                        data-event-category={(active_type === 'employer') ? "411_refer_employer_copy_link" : "322_refer_job_copy_link"}
                                    />
                                    <div
                                        className="btn btn-larger btn-flat btn-fill"
                                        onClick={ e => handleCopy(e, text.referral_link) }
                                        data-event-category={(active_type === 'employer') ? "411_refer_employer_copy_link" : "322_refer_job_copy_link"}
                                    >
                                        {
                                            (finish_copy_link) ?
                                                <h5>
                                                    <img src={icon_ok} alt="" />
                                                    {t('general:copied')}
                                                </h5> :
                                                <h5>{t('general:copy_link')}</h5>
                                        }
                                    </div>
                                </div>
                                {(finish_copy_link) ? <h6 className="message notice-message">{t('general:link_is_copied')}</h6> : ''}
                            </div>
                            <Interpolate
                                i18nKey="you_can_manage_your_referral_at___"
                                useDangerouslySetInnerHTML={true}
                                my_referrals={
                                    <Link
                                        className="link"
                                        to={internal_uri.formatted_userpanel_my_referrals_page_path()}
                                        data-event-category={(active_type === 'employer') ? "412_refer_employer_my_referrals" : "323_refer_job_my_referrals"}
                                    >
                                        {t('general:my_referrals')}
                                    </Link>
                                }
                            />
                            <hr />
                            <p>{t('share_with_')}</p>
                            <div className="social-icons">
                                <button
                                    onClick={ e => handleSharing(e,
                                        `https://www.facebook.com/dialog/feed?` +
                                        `app_id=2467049316703304&` +
                                        `display=popup&` +
                                        `link=${text.referral_link}`
                                    )}
                                    className="social-icon"
                                    data-event-category={(active_type === 'employer') ? "413_refer_employer_share" : "324_refer_job_share"}
                                >
                                    <img src={icon_fb} alt="" />
                                </button>
                                <button
                                    onClick={ e => handleSharing(e,
                                        `http://www.linkedin.com/shareArticle?` +
                                        `mini=true&` +
                                        `url=${text.referral_link}&` +
                                        `content=${t('hi__i_found_a_great_job_you_might_be_interested_come_and_check_it_out_')}`
                                    )}
                                    className="social-icon"
                                    data-event-category={(active_type === 'employer') ? "413_refer_employer_share" : "324_refer_job_share"}
                                >
                                    <img src={icon_linkedin} alt="" />
                                </button>
                                <button
                                    onClick={ e => handleSharing(e,
                                        `https://twitter.com/share?` +
                                        `url=${text.referral_link}&` +
                                        `text=${t('hi__i_found_a_great_job_you_might_be_interested_come_and_check_it_out_')}%0A&` +
                                        `hashtags=GetRewardsfromMeetjobs`
                                    )}
                                    className="social-icon"
                                    data-event-category={(active_type === 'employer') ? "413_refer_employer_share" : "324_refer_job_share"}
                                >
                                    <img src={icon_twitter} alt="" />
                                </button>
                                <a
                                    href={
                                        `mailto:?` +
                                        `subject=${t('a_great_job_you_might_be_interested_')}&` +
                                        `body=${t('hello_my_friend__i_think_this_is_a_good_job_opportunity_that_you_might_be_interested_')}%0A%0A${t('general:referral_link')}%3A%20%20%20${text.referral_link}`
                                    }
                                    className="social-icon"
                                    data-event-category={(active_type === 'employer') ? "413_refer_employer_share" : "324_refer_job_share"}
                                >
                                    <img src={icon_email} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default withNamespaces(['frontend_refer_page', 'general'])(ReferPage);
