import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { withNamespaces } from "react-i18next";

const JobFunctionModal = (props) => {
	// console.log(props);
	const {
		group,
		jobFunctions,
		form_data,
		t,
		showJobFunctions,
		handleJobFunctionShow,
		error,
		handleJobFunctionsInputChange,
	} = props;
	const close = () => {
		if (!error) {
			handleJobFunctionShow(false);
		}
	};
	return (
		<CSSTransitionGroup
			component="div"
			transitionName="modal-wrapper-slide"
			transitionEnterTimeout={300}
			transitionLeaveTimeout={300}>
			{showJobFunctions ? (
				<div className="modal-wrapper job-functions-modal-wrapper">
					<CSSTransitionGroup
						component="div"
						className="modal-inner-wrapper"
						transitionName="modal-slide"
						transitionEnterTimeout={300}
						transitionLeaveTimeout={300}>
						<div className="modal-bg" onClick={(e) => close(e)} />
						<div className="modal job-functions-modal">
							<div className="job-functions-modal-header">
								<div>
									<h2>
										{t("general:job_function")}{" "}
										<small>{t("_select_up_to_3_")}</small>
									</h2>
									{error ? (
										<h6 className="message error-message hidden-xs">{error}</h6>
									) : (
										""
									)}
								</div>
								<button
									onClick={(e) => {
										e.preventDefault();
										close();
									}}
									className={`btn btn-larger btn-flat btn-fill ${
										error ? "disabled" : ""
									}`}>
									<h5>{t("apply")}</h5>
								</button>
							</div>
							{group.map((group) => (
								<div className="job-function-group" key={`group-${group[0]}`}>
									{group.map((_id, _idx) => {
										const opt = jobFunctions.find((_jFn) => {
											return _jFn.value === _id;
										});
										if (opt === undefined) return null;
										const { value, label } = opt;
										return (
											<div
												className={[_idx === 0 ? "main" : null]}
												key={`checkbox-opt-${value}`}>
												<input
													id={`jf-opt-${value}`}
													className="checkbox-input"
													type="checkbox"
													value={value}
													checked={form_data.job_functions_selected_values.some(
														(jb) => {
															return jb.value === value;
														},
													)}
													onChange={(e) => {
														// e.preventDefault()
														handleJobFunctionsInputChange(opt);
													}}
												/>
												<label htmlFor={`jf-opt-${value}`} className="checkbox">
													<h6>{t(label)}</h6>
												</label>
											</div>
										);
									})}
								</div>
							))}
							<div className="bottom-controls">
								{error ? (
									<h6 className="message error-message">{error}</h6>
								) : (
									""
								)}
								<button
									onClick={(e) => {
										e.preventDefault();
										close();
									}}
									className={`btn btn-larger btn-flat btn-fill ${
										error ? "disabled" : ""
									}`}>
									<h5>{t("apply")}</h5>
								</button>
							</div>
						</div>
					</CSSTransitionGroup>
				</div>
			) : (
				""
			)}
		</CSSTransitionGroup>
	);
};

export default withNamespaces([
	"dashboard_job_page",
	"general",
	"states",
	"error_messages",
])(JobFunctionModal);
