import React from "react";
import Slider from "react-slick";

export default function TestimonialSlider({ testimonial = [] }) {
	if (!testimonial) {
		return null;
	}

	return (
		<Slider
			arrows={true}
			dots={false}
			centerMode={false}
			autoplay={true}
			infinite={true}
			draggable={true}
			fade={false}
			pauseOnHover={true}
			autoplaySpeed={4500}
			slidesToShow={2}
			slidesToScroll={2}
			centerPadding={"60px"}
			responsive={[
				{
					breakpoint: 990,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						initialSlide: 2,
						centerPadding: "60px",
						centerMode: true,
						arrows: true,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerPadding: "40px",
						centerMode: true,
						arrows: false,
					},
				},
			]}>
			{testimonial.map((slide, i) => (
				<div className="item" key={i}>
					<div className="testimonial-card">
						<div>
							<img src={slide.image} className="rounded-l box-shadow" alt="" />
						</div>
					</div>
				</div>
			))}
		</Slider>
	);
}
