export default {
    // job_title: "Job title",
    all_applications: "All applications",
    applying: "Applying",
    rejected: "Not selected",
    cancelled: "Cancelled",
    confirmed: "Confirmed",
    candidate: "Candidate",
    talent_match: "Talent Match",
    referral: "Referral",
    referred_by__: "Referred by {{referrer_name}}",
    assessment_for_suitable_candidates_to_invite_to_interviews__the_specific_service_for_hire_and_hire_plus_plan_: `Assessment for suitable candidates to invite to interviews. The specific service for <b>Social</b> & <b>Hunter Plan</b>!`,
    sit_back_and_relax: "Sit back and relax",
    you_have_no_applications_yet_: "You have no applications yet.",
    no_jobs_posted: "No jobs posted",
    you_haven_t_posted_any_jobs_yet_: "You haven't posted any jobs yet.",
    show_all_closed_jobs: "Show all closed jobs",
}
