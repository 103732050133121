export default {
	your_email_account_has_been_used_to_register_meet_jobs__we_suggest_resetting_your_password_right_away_:
		"Your Email account has been used to register Meet.jobs. We suggest resetting your password right away.",
	reset_password: "Reset password",
	welcome_: "Welcome,",
	you_re_all_set_: "You're all set!",
	start_to_search_and_view_all_jobs: "Start to search and view all jobs",
	be_a_referrer_and_get_reward: "Be a Referrer and get Reward",
	know_more_about_employer_plans: "Know more about employer plans",
	see_all_jobs: "See all jobs",
	see_plans: "See plans",
	upload_for_better_service:
		"Upload your resume to provide you with better service!",
	benefit_of_uploading_resume: "Benefit of uploading resume",
	only_need_upload_title: "Directly upload",
	free_consoltant_title: "Free career consultation",
	let_employer_see_title: "Job opportunity recommendations",
	only_need_upload:
		"At Meet.jobs, you don't need to fill out your resume again. Simply upload a file or provide an external resume link.",
	free_consoltant_1:
		"Uploading your resume entitles you to a",
	free_consultation:" Free Career Consultation. ",
	free_consoltant_2:
		"Feel free to schedule one online.",
	enable: "Enable",
	open_resume: " \"Open Resume\" ",
	let_employer_see:
		" to receive recommendations for excellent jobs from headhunters and companies.",
	upload_for_reason: "upload your resume for better experience!",
};