import React from "react";
import {formatted_locale, get_value_from_query_string} from "../../../assets/js/functions";
import Config from "../../models/config.js";
import ApiHeaders from "../../models/api_headers";
import axios from "axios";
import User from "../../models/user";
import InternalUri from "../../models/internal_uri";
import i18n from "../../../i18n";
import {sendDataLayer} from "../../../assets/js/dataLayer";
import MeetJobsLoader from "../general/MeetJobsLoader";

const config = new Config();
const headers = new ApiHeaders();

class RedirectFromSignInMail extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            validating: true,
            error_messages: {},
        };
    };

    componentDidMount() {
        this._isMounted = true;

        let go_validation = true;
        const query = decodeURI(this.props.location.search);
        if(go_validation) {
            let access_token = get_value_from_query_string('access-token', query);
            let client = get_value_from_query_string('client', query);
            let uid = get_value_from_query_string('uid', query);
            let expiry = get_value_from_query_string('expiry', query);

            let userAttributes = {};
            let options = {
                method: 'GET',
                url: config.api_url('/auth/validate_token'),
                headers: {
                    "uid": uid,
                    "client": client,
                    "expiry": expiry,
                    "access-token": access_token
                },
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    userAttributes = response.data.data;
                    this.props.verifyTokenRequestSucceeded(userAttributes);
                    i18n.changeLanguage(formatted_locale(userAttributes.locale));
                    sendDataLayer('userId', userAttributes.id);

                    options = {
                        method: 'GET',
                        url: config.api_url(`/users/${userAttributes.id}?include=employer_roles`),
                        headers: headers.getItemsFromLocalStorage(),
                        json: true
                    };
                    return axios(options);
                })
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);

                    const user = new User(response.data);
                    this.props.updateCurrentUserIsReferralUser(user.is_referrals_exist);

                    let employer_roles = {}, promises_employers = [];
                    const raw_employer_roles = response.data.employer_roles;
                    for(let i=0; i<raw_employer_roles.length; i++) {
                        options = {
                            method: 'GET',
                            url: config.api_url(`/employers/${raw_employer_roles[i].employer_id}`),
                            headers: headers.getItemsFromLocalStorage(),
                            json: true
                        };
                        promises_employers.push(axios(options));
                        employer_roles[raw_employer_roles[i].employer_id] = raw_employer_roles[i];
                    }
                    this.props.updateCurrentUserEmployerRoles(employer_roles);

                    return axios.all(promises_employers);
                })
                .then((results) => {
                    for(let i=0; i<results.length; i++) {
                        headers.updateItemsToLocalStorage(results[i].headers);
                    }

                    const { current_user } = this.props;
                    let active_employer_id = current_user.active_company.id || 0;
                    let companies = [], company;
                    let active_company_index = 0;
                    for(let i=0; i<results.length; i++) {
                        company = results[i].data;
                        companies.push(company);
                        if(active_employer_id === company.id) {
                            active_company_index = i;
                        }
                    }

                    if(companies.length > 0) {
                        this.props.updateCurrentUserIsEmployerUser(true);
                        this.props.updateCurrentUserCompanies([...companies]);
                        this.props.updateCurrentUserActiveCompany({...companies[active_company_index]});
                    } else {
                        this.props.updateCurrentUserIsEmployerUser(false);
                        this.props.updateCurrentUserCompanies([]);
                        this.props.updateCurrentUserActiveCompany({});
                    }

                    this.setState({
                        validating: false,
                        error_messages: {}
                    }, () => {
                        const internal_uri = new InternalUri();
                        this.props.history.replace(internal_uri.formatted_frontend_confirm_done_page_path());
                    });
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                    }

                    if(this._isMounted) {
                        if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                        this.setState((prev_state) => ({
                            ...prev_state,
                            validating: false,
                            error_messages:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: 'There was an error. Please try again later.'},
                        }), () => {
                            const internal_uri = new InternalUri();
                            this.props.history.replace(internal_uri.formatted_frontend_index_page_path())
                        });
                    }
                });
        } else {
            this.setState({
                validating: false,
                error_messages: {
                    full_message: "There was an error. Please try again later."
                }
            }, () => {
                setTimeout(() => {
                    const internal_uri = new InternalUri();
                    this.props.history.replace(internal_uri.formatted_frontend_index_page_path());
                }, 1500);
            });
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    render() {

        const { error_messages, validating } = this.state;

        if(validating) {
            return (
                <div className="content-wrapper">
                    <div className="container">
                        <div className="meet-jobs-loader-wrapper" style={{
                            position:"absolute",
                            width:"100%",
                            height:"100%",
                            top:0,
                            left:0,
                            zIndex:9
                        }}>
                            <MeetJobsLoader />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="content-wrapper">
                <div className="container">
                    {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                </div>
            </div>
        );
    };
}

export default RedirectFromSignInMail;