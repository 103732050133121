import React, { useState, useEffect } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { getRandomInt } from "../../../../assets/js/functions";
import Ad from "../../../models/ad";
import MarketingBanner from "../MarketingBanner";
import { set } from "react-ga";

const AdCornerModal = (props) => {
	const { ad_corner_modal, ads } = props;
	if (ads.length < 1) return null;
	const ad_index = getRandomInt(ads.length);
	const ad = new Ad(ads[ad_index]);

	const eventMarketingParameters = {
		utm_source: "Meet.jobs",
		utm_medium: "Homepage_Banner",
		utm_campaign: ad.formatted_title(),
	};

	// const [adIndex, setAdIndex] = useState(0);
	// const [ad, setAd] = useState(new Ad());
	// const [onHover, setOnHover] = useState(false);
	// let timeout;
	// useEffect(() => {
	// 	if (onHover) {
	// 		clearTimeout(timeout);
	// 		return;
	// 	}
	// 	// console.log(onHover);
	// 	if (ads.length > 1) {
	// 		clearTimeout(timeout);
	// 		if (adIndex === ads.length - 1) {
	// 			timeout = setTimeout(() => {
	// 				setAdIndex(0);
	// 				setAd(new Ad(ads[0]));
	// 			}, 5000);
	// 		} else {
	// 			timeout = setTimeout(() => {
	// 				setAdIndex(adIndex + 1);
	// 				setAd(new Ad(ads[adIndex + 1]));
	// 			}, 5000);
	// 		}
	// 	}
	// }, [adIndex, ads, onHover]);
	return (
		ads.length > 0 &&
		ad_corner_modal && (
			<CSSTransitionGroup
				component="div"
				className="corner-modal-wrapper event-marketing-corner-modal-wrapper"
				transitionName="modal-slide"
				transitionEnterTimeout={300}
				transitionLeaveTimeout={300}>
				<MarketingBanner
					type="corner-modal"
					// parameters={eventMarketingParameters}
					ads={ads}
					eventCategory="112_marketing_home"
				/>
			</CSSTransitionGroup>
		)
	);
};

export default AdCornerModal;
