import React from 'react';
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { signOutUser } from '../../../../redux-token-auth-config';
import is_that_you from '../../../../assets/images/i029-id_conflict.svg';
import {sendDataLayer} from "../../../../assets/js/dataLayer";

const LogOutModal = props => {

    const { site_state, t } = props;

    const closeModal = e => {
        e.preventDefault();
        props.updateLogOutModal(false);
    };

    const signOut = e => {
        e.preventDefault();
        const { signOutUser } = props;
        signOutUser()
            .then(() => {
                props.updateConnectionOffModal(false);
                props.closeAll();
                sendDataLayer('userId', -1);
            })
            .catch((error) => {
                if(typeof error.response === 'undefined') props.updateConnectionOffModal(true);

                if(error.response) {
                    if(error.response.status === 404) {
                        window.location.reload();
                    }
                }
            });
    };

    if(site_state.log_out_modal) {
        return (
            <div className="modal-wrapper log-out-modal-wrapper">
                <div className="modal-inner-wrapper">
                    <div className="modal-bg" />
                    <div className="modal log-out-modal">
                        <img className="top-banner" src={is_that_you} alt="" />
                        <div className="content">
                            <h4 className="modal-title">{t('is_that_you_')}</h4>
                            <p>{t('we_notice_that_you_re_already_logged_in_as_different_account_would_you_like_to_proceed_')}</p>
                            <div className="buttons-wrapper">
                                <a className="btn btn-flat btn-ghost" href="/#" onClick={ e => signOut(e) }>
                                    <h5>{t('log_out')}</h5>
                                </a>
                                <a className="btn btn-flat btn-fill" href="/#" onClick={ e => closeModal(e) }>
                                    <h5>{t('continue')}</h5>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
};

export default withRouter(connect(null, { signOutUser })(withNamespaces(['general'])(LogOutModal)));
