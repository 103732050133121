import React from "react";
import axios from "axios";
import Config from "../../models/config.js";
import ApiHeaders from "../../models/api_headers";
import Job from "../../models/job.js";
import { withNamespaces } from "react-i18next";

const config = new Config();
const headers = new ApiHeaders();

class SaveJobButton extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.saveJob = this.saveJob.bind(this);
		this.unsaveJob = this.unsaveJob.bind(this);
		this.openEntranceModal = this.openEntranceModal.bind(this);
		this.state = {
			active_job_id: -1,
			current_user_status: {},
		};
	}

	componentDidMount() {
		this._isMounted = true;

		const { job, reduxTokenAuth } = this.props;
		this.setState((prev_state) => ({
			...prev_state,
			active_job_id: job.id,
			current_user_status: reduxTokenAuth.currentUser.isSignedIn
				? job.current_user_status
				: { saved: false },
		}));
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		if (
			JSON.stringify(this.props.job.current_user_status) !==
			JSON.stringify(next_props.job.current_user_status)
		) {
			this.setState({
				current_user_status: next_props.reduxTokenAuth.currentUser.isSignedIn
					? next_props.job.current_user_status
					: { saved: false },
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	saveJob = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const { reduxTokenAuth } = this.props;
		const { active_job_id } = this.state;
		const url = config.api_url(
			`/users/${reduxTokenAuth.currentUser.attributes.id}/saved_jobs`,
		);
		const options = {
			method: "POST",
			url: url,
			data: { job: { id: active_job_id } },
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					const raw_job = response.data;
					const job = new Job(raw_job);
					this.setState(
						{
							current_user_status: job.current_user_status,
						},
						() => {
							if (this.props.updateJob) {
								this.props.updateJob(job);
							}
						},
					);
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined")
						this.props.updateConnectionOffModal(true);
				}
			});
	};

	unsaveJob = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const { reduxTokenAuth } = this.props;
		const { active_job_id } = this.state;
		const url = config.api_url(
			`/users/${reduxTokenAuth.currentUser.attributes.id}/saved_jobs/${active_job_id}`,
		);
		const options = {
			method: "DELETE",
			url: url,
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					const raw_job = response.data;
					const job = new Job(raw_job);
					this.setState(
						{
							current_user_status: job.current_user_status,
						},
						() => {
							if (this.props.updateJob) {
								this.props.updateJob(job);
							}
						},
					);
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined")
						this.props.updateConnectionOffModal(true);
				}
			});
	};

	openEntranceModal = (e) => {
		e.preventDefault();
		this.props.updateEntranceFormModal("log-in");
	};

	render() {
		const { type, color, size, reduxTokenAuth, eventCategory } = this.props;
		const { openEntranceModal } = this;
		const { current_user_status } = this.state;
		const { saveJob, unsaveJob } = this;
		const { t } = this.props;

		if (type === "button") {
			if (reduxTokenAuth.currentUser.isSignedIn) {
				const saved = current_user_status.saved;
				return saved ? (
					<button
						className="btn btn-larger btn-flat btn-hollow btn-with-icon saved"
						onClick={(e) => unsaveJob(e)}
						data-event-category={eventCategory}>
						<h5>
							<div className={`subscribe subscribe-saved-${color}-${size}`} />
							{t("saved")}
						</h5>
					</button>
				) : (
					<button
						className="btn btn-larger btn-flat btn-hollow btn-with-icon"
						onClick={(e) => saveJob(e)}
						data-event-category={eventCategory}>
						<h5>
							<div className={`subscribe subscribe-save-${color}-${size}`} />
							{t("save")}
						</h5>
					</button>
				);
			} else {
				return (
					<button
						className="btn btn-larger btn-flat btn-hollow btn-with-icon"
						onClick={(e) => openEntranceModal(e)}
						data-event-category={eventCategory}>
						<h5>
							<div className={`subscribe subscribe-save-${color}-${size}`} />
							{t("save")}
						</h5>
					</button>
				);
			}
		} else {
			if (reduxTokenAuth.currentUser.isSignedIn) {
				const saved = current_user_status.saved;
				return saved ? (
					<div
						className={`subscribe subscribe-saved-${color}-${size}`}
						onClick={(e) => unsaveJob(e)}
						data-event-category={eventCategory}
					/>
				) : (
					<div
						className={`subscribe subscribe-save-${color}-${size}`}
						onClick={(e) => saveJob(e)}
						data-event-category={eventCategory}
					/>
				);
			} else {
				return (
					<div
						className={`subscribe subscribe-save-${color}-${size}`}
						onClick={(e) => openEntranceModal(e)}
						data-event-category={eventCategory}
					/>
				);
			}
		}
	}
}

SaveJobButton.defaultProps = {
	updateJob: (f) => f,
};

export default withNamespaces(["general"])(SaveJobButton);
