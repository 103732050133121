export default {

    text_version:

`企業用戶合約 

本合約係由 

名稱：      （以下簡稱甲方、客戶、僱主、買方、徵才企業等） 

電話：____________________ 

Email：___________________ 

授權 一起工作科技有限公司（以下簡稱 Meet.jobs、乙方、服務提供者、賣方等） 透過所屬平台與服務委託協助聘用人才之合約。 

引言及說明 

1 本合約係 一起工作科技有限公司 與所屬網站、平台，及網路服務提供人才招募解決方案服務時所使用。 

2 本合約所有名詞解釋、定義等皆與 Meet.jobs 的「個人用戶協議」、「隱私權政 策」及一切相關文件相同。 

3 客戶 必須先有負責招募的聯絡人註冊成為 Meet.jobs 服務的「會員」，才能進一步 註冊企業帳號。 

4 除 客戶 與 Meet.jobs 以書面另外所達成的協議以外，一切資料認定皆以 Meet.jobs 網站、系統與延伸服務上的紀錄為準。 

客戶的義務 

5 客戶 張貼職缺無須支付張貼費用。 

6 客戶 必須於 Meet.jobs 之網站、平台與服務內容中採用服務方案。同一個企業帳號 只能一次採用一種方案。特定時間所選用的方案以 Meet.jobs 服務系統上所顯示的紀錄為準。

7 客戶 可將要招募的工作資訊張貼於 Meet.jobs 網站及所有延伸之網路平台， 並保證所提供資訊正確無誤。若 客戶 提供之資訊有誤而造成 Meet.jobs 之損害 ，客戶 應對 Meet.jobs 負擔損害賠償責任；若因此而造成求職者之損害，客戶 應 自行負責，概與 Meet.jobs 無涉。 

8 「資訊透明」是 Meet.jobs 最重要的核心價值。企業客戶 透過 Meet.jobs 服務發 佈職缺時，最底限度必須公開該職位的薪資區間或薪資下限的資訊。 

說明：「信任」為 Meet.jobs 所相信的核心價值，而「資訊透明」能夠給予人才對僱主充份的信任。當 僱主 所提供的資訊愈加透明、完整、準確，便能透過 Meet.jobs 更有效率地錄取合適的人選。 反之，如因無法公開資訊而需採用獵才方案，則獵才服務之收費將以人才的專業性、資深程度等收取不同比例的服務費用。

9 客戶 得以 Meet.jobs 之專業顧問建議提供招募人才用的必要相關資訊，包括但不限於影片、圖片、錄音、文字訊息，以及現職員工、招聘主管或企業創辦人專訪及/或簡歷等素材。 

10 前項所提之素材不包含 客戶 因商務考量而必須保密的資訊和材料。包括 客戶 的企業名稱在內，Meet.jobs 須獲得 客戶 以任何可留下紀錄之方式許可，才可公佈該項資訊。如未獲許可擅自公佈，則 客戶 有權立即解除合約。如因此造成客戶 至或任何損失，Meet.jobs 當負一切賠償與法律責任。 

11 客戶 須於 Meet.jobs 網站、平台及網路服務上回覆求職者的應徵訊息，包括拒絕 應徵（即感謝信）。特別強調如該筆應徵未被拒絕，狀態仍顯示為「應徵中」， 則無 論經過多久，也無論是否錄取同一職位，一旦 客戶 錄取該人選，則視為透過 Meet.jobs 錄取，Meet.jobs 仍保有收費之權利。 

12 客戶 須於 3 個工作日內回覆求職者或推薦人回報錄取之訊息，確認是否錄取該人選。如確認錄取與人選就職非同一日期，請以可留下紀錄之方式告知 Meet.jobs 該人選的就職日期即可。 

13 客戶 於確認錄取時，須同步提供該人選之薪資資訊，包括但不限於聘書（Offer Letter）、僱傭合約、薪水單等，作為 Meet.jobs 收費之依據。 

14 客戶 須在收到 Meet.jobs 的 invoice 後的 30 個日曆天內完成付款。 

Meet.jobs 的義務 

15 客戶 在 Meet.jobs 系統註冊 企業帳號 以後，Meet.jobs 將有專業顧問於 1 個工作天內與 客戶聯繫，提供協助。 

16 Meet.jobs 將依本身專業判斷，視 客戶 招募需求之不同，提供目標行銷、文字或 影音專訪、線上或線下活動推廣、社群推薦等方式，協助 客戶 徵才。

17 Meet.jobs 將於社群網路分享並推廣 客戶 之企業和職缺資訊，包括且不限於在 Facebook、LinkedIn、Youtube、Instagram、WeChat、TikTok 等，以及客戶 有特殊需求之網站或社群。 

18 承上，如 Meet.jobs 所提供的招募方案有任何衍生的額外費用，則在取得 客戶 同 意，並以任何可留下紀錄的方式佐證之後，雙方議定由哪方負擔該費用，並另行 約定付款、憑證、墊款等細節事項。 

19 Meet.jobs 須追蹤每一筆應徵紀錄之狀態，直至人選錄取、取消應徵或被 客戶拒 絕為止。如 客戶 有未讀及未處理之應徵人選，Meet.jobs 必須以可留下紀錄之方 式，提醒 客戶 儘快處理之。 

20 確認人選就職後，Meet.jobs 必須依 客戶 所提供的薪資資訊開立 invoice，並有 義務建議 客戶 最為方便且節省成本的付款方式。 

21 一旦 客戶 完成付款，Meet.jobs 必須於 3 個工作日內確認並告知客戶。 

錄取與就職的認定 

22 在 Meet.jobs 服務上確認錄取有以下三種方式： 

A. 客戶 確認：客戶 可直接在 Meet.jobs 系統上確認就職，或以任何可留下紀錄的方式告知 Meet.jobs。 

B. 求職者回報後 客戶 確認：一旦錄取工作，求職者將可在 Meet.jobs 系統上回報就職，Meet.jobs 系統將自動向 客戶 確認是否錄用該求職者。客戶 可在 Meet.jobs 系統上確認就職，或以任何可留下紀錄的方式告知。 

C. 推薦人回報後 客戶 確認：如該人選有推薦人，則推薦人將可在 Meet.jobs 系統上回報就職，Meet.jobs 系統將自動向 客戶 確認是否錄用該人選。客戶 可在 Meet.jobs 系統上確認就職，或以任何可留下紀錄的方式告知。 

23 承上，客戶 在確認錄取人選時，須同時告知該人選就職日期，以當日人選就職為 準。如期間就職日期有所變更，以雙方可留下紀錄之方式同意之。 

24 前項 求職者 與 推薦人 回報就職之情況，如確認為「未就職」，客戶 須在 3 個工 作日內於 Meet.jobs 系統回覆，或以任何可留下紀錄的方式告知 Meet.jobs。如未告知，則 Meet.jobs 仍保有收費的權利。 

25 如該人選在並未就職 客戶 的工作職位，則 客戶 須在 Meet.jobs 系統「拒絕」其應徵。如有未「拒絕」的求職者，皆視為仍然處於應徵狀態。則無論時間經過多久 ，一旦就職，Meet.jobs 仍有收費的權利。 

26 如該人選在 Meet.jobs 系統上是應徵狀態，則一旦就職 客戶 的工作職位，無論最 後就任的是否為最初所應徵的職位，Meet.jobs 都保有收費的權利。亦即如果 客戶 無意錄用該人選，請務必在 Meet.jobs 上「拒絕」該人選，切勿有「無聲卡」 等損害 客戶 商譽與信任的行為。 

27 凡是透過 Meet.jobs 系統應徵的求職者，以及 Meet.jobs 以任何可留下紀錄的方式所推薦的人選，一旦就職，Meet.jobs 即有權利向 客戶 收費。特別說明， 此與 僱主 是否透過其他管道得知此位人選無關，Meet.jobs 仍有向 客戶 收費之權利。 

28 如該人選已透過其他管道應徵，且仍在「應徵中」，則該人選再透過 Meet.jobs 應 徵，亦不計為 Meet.jobs 的推薦人選。注意此處「應徵中」的定義為「客戶已透過其他管道聯繫該人選面試，人選已回覆接受面試且有聯繫紀錄，而該次應徵尚 未『拒絕』或『取消應徵』，最後一次的『客戶 與人選聯繫』與『人選透過Meet.jobs 應徵的日期』相距 10 個工作日以內」。在此情況下，將視為該人選前一次透過其他管道的應徵尚未結束，Meet.jobs 將不收取服務費用。 

29 如 客戶 認定已就職的求職者並非透過 Meet.jobs 的服務所錄用，則須取得該名求 職者以任何可留下紀錄的方式，確認並非透過 Meet.jobs 就職之聲明。在此情況 下，則 Meet.jobs 將不收取服務費用。 

就職保障期 

30 凡透過 Meet.jobs 錄取之人選，Meet.jobs 保障該名錄取人選任滿 30 個日曆天。 除 客戶 與 Meet.jobs 另有議定且留下紀錄同意者，不在此限。 

31 如在任職的前 30 個日曆天內，該人選自行離職，則無論客戶選擇何種服務方案，Meet.jobs 都將退還 50% 的服務費用。

32 承上，如 客戶 對於該職位仍有招募人選之需求，則 Meet.jobs 亦將提供相應服務，協助招募替代人選。唯此一情況下，客戶 仍須支付該人選之招募服務費用。

33 特別強調，如該人選在前 30 個日曆天內因工作專業表現等問題，無法過試用期而被 客戶 解任，則不在 Meet.jobs 的保障範圍之內。 

34 上述 30 – 33 條內容為「社群方案」所提供之標準服務內容。如 客戶 另有特殊服務需求（例如希望延長試用期等），請選擇「獵才方案」提供客製化招募服務。

實質薪資（年薪）的認定 

35 一旦確認錄取，客戶 必須提供薪資資訊，例如僱傭合約、錄取信或薪資單作為佐 證，以當作 Meet.jobs 收費的依據。如該資訊由求職者或推薦人提供，也必須獲得 客戶 以任何可留下紀錄形式，回覆確認。 

36 Meet.jobs 對於薪資資訊負有保密責任，只能做為收取服務費用的佐證，而不可以用於其他用途。如因資訊外洩造成 客戶 損失，Meet.jobs 必須負賠償之責任。

37 客戶 在透過 Meet.jobs 系統發佈職缺時，可選擇年薪、月薪、日薪或時薪的計算 方式。而 Meet.jobs 根據 客戶 所選擇的服務方案不同，收取服務費用。 

38 如該職位的薪資計算方式為年薪，則以正式聘書（Offer Letter）或僱傭合約上所記 載之正職錄用金額為實質薪資。 

39 如該職位的薪資計算方式為月薪，則以正式聘書（Offer Letter）或僱傭合約上所記 載之正職錄用金額，乘上該職位所保障的月份數字（例如 14 個月。如無規範則為 12 個月）為實質薪資。如保障月份未達 12 個月，則以 12 個月計算之。 

40 如該職位的薪資計算方式為日薪，則以正式聘書（Offer Letter）或僱傭合約上所記 載之正職錄用金額，乘以 20（每月工作日數），再乘以 客戶 所保障的月份數字 （例如 14 個月，如無規範則為 12 個月）為實質薪資。如保障月份未達 12 個月， 則以 12 個月計算之。 

41 如該職位的薪資計算方式為時薪，則以正式聘書（Offer Letter）或僱傭合約上所記 載之正職錄用金額，乘以 160（每月工作時數），再乘以 客戶 所保障的月份數字 （例如 14 個月，如無規範則為 12 個月）為實質薪資。如保障月份未達 12 個月， 則以 12 個月計算之。 

42 客戶 在 Meet.jobs 系統發佈職缺時，可選擇全職、兼職、實習和志願者等 4 種不同的工作型態。凡選擇「全職」工作的職缺，無論是否永久合約 （Permanent）或契約聘用（Contract），實質薪資都以上述的方式計算之。 

43 如該職位為「兼職」或「實習」職位，則為「全職」職位實質薪資的 60%。然而當 客戶 先以「兼職」或「實習」招募「全職」人員時，其實質薪資與服務費用仍以「全職」 工作計算之。 

44 如遇 客戶 在試用期間的薪資與正式通過試用時不同，則以正式聘用時的薪資作 為計算實質薪資的基準。 

費用支付 

45 一旦 客戶 在 Meet.jobs 系統上確認就職，並告知薪資資訊，Meet.jobs 將提供帳單（invoice）及付款所需文件（如發票）。收到帳單以後，客戶 須在 30 個日曆天內 完成付款。 

46 Meet.jobs 現可接受「信用卡」、「轉帳匯款」和「虛擬貨幣」（目前僅接受 USDT 及 USDC）三種付費方式，客戶 可從中選擇， 並顯示於 Meet.jobs 系統上。 

47 如 客戶 選擇信用卡，則只需要在 Meet.jobs 系統上註冊信用卡資料即可。 Meet.jobs 會依就職確認開立帳單，並於每個月的結帳日（每月 5 日）收費。信用卡為 Meet.jobs 建議最佳的付費方式。 

48 如 客戶 選擇轉帳匯款，則 Meet.jobs 將提供銀行帳戶，供 客戶 進行匯款。

49 Meet.jobs 有責任告知 客戶 最為方便且節省成本的付款方式。然 Meet.jobs 的收費為「全額的服務費用」，如有其他衍生費用，例如手續費、匯費、各項相關租稅等 ，皆由 客戶 自行負擔。 

社群方案 

50 社群方案（Social Plan）的收費為招募職位實質薪資年薪的 16%，以確認錄取人選 就職為依據，向 客戶 收取服務費用。 

51 採用 社群方案 的 客戶，將獲得 Meet.jobs 所有用戶推薦合適人選。一旦推薦人選成功錄取，Meet.jobs 將提供就職祝賀金、推薦感謝金等，並邀請人選分享錄取 心得。 

52 採用 社群方案 的 客戶，Meet.jobs 將以本身專業判斷，於線上、線下社群，針對 客戶 的企業及職缺資訊，進行目標行銷推廣。 

53 採用 社群方案 的 客戶，可參與 Meet.jobs 所舉辦線上、線下整合行銷招募活動。如該活動有其他衍生費用，則直接以可留下紀錄之方式由雙方議定之。 

54 社群方案（Social Plan）合約為標準服務合約，不接受修改。惟合約中未明確規範 之細節，可由雙方以合約附註方式說明之。但附註之內容，不可違背主合約之內容與精神。如遇相抵觸者，則附註之內容自動失效。 

55 如 客戶 要從其他方案切換至 社群方案，則需另行以書面或可留下紀錄之方式， 告知 Meet.jobs，由系統管理者協助完成切換。 

獵才方案 

56 獵才方案（Hunter Plan）的收費為招募職位實質薪資年薪的 20% 以上（含），以 確認錄取人選就職為依據，向 客戶 收取服務費用。 

57 採用 獵才方案 的 客戶，可享有 社群方案 所有的服務內容。此外，Meet.jobs 本 身所配置的獵才顧問，將會主動協助推薦、篩選人選。 

58 採用 獵才方案 的 客戶，Meet.jobs 將有專業獵才顧問，針對招募職位的工作內 容、薪資範圍與招募方式（例如活動等）等提出建議。 

59 採用 獵才方案 的 客戶，Meet.jobs 將可協助進行語言、專業技術、適性測試等人 才篩選測驗，惟須與 客戶 詳細確認測驗之方式與標準。 

60 其他客製化服務，例如挖角人選、薪資談判、保障面試人數等，依服務內容需求 不同，Meet.jobs 將收取 20% 以上的服務費用。費用與收費方式由 客戶 和 Meet.jobs 雙方以可留下紀錄之方式，共同議定之。如有需求，可另擬附約，以茲紀錄。 

61 如 客戶 在已選擇其他方案的情況下，要升級到 獵才方案，只需在 Meet.jobs 系統上點選升級方案，或者以可留下紀錄的形式通知 Meet.jobs 即可。確認 客戶 升級方案需求的以後，Meet.jobs 將有專業顧問與 客戶 聯繫，提供協助。

道德宣告 

62 人才招募的成敗往往決定一家企業的成敗。而企業能夠成功招募到優秀人才，必 須基於企業、人才和服務提供者間的互相信任。因此，如有求職者或其推薦人以 「不向 Meet.jobs 回報就任工作」為條件，要求 客戶 提供任何額外的報酬、獎金或 其他形式的利益回饋，Meet.jobs 強烈建議 客戶 不要錄取該求職者。如向 Meet.jobs 舉報該求職者或推薦人，Meet.jobs 也將負查證之責任；一經查證屬實 ，即將該求職者或推薦人列為「排除名單」，告知 Meet.jobs 所有的企業客戶。 

63 如 客戶 以「不向 Meet.jobs 回報就任工作」為條件，主動提供求職者或推薦人任 何形式的利益交換，則求職者或推薦人亦有權利舉報該企業。如查證屬實，考量此 一行為有違企業徵才時的誠信原則，Meet.jobs 將在網站上公開 客戶 企業名稱， 並列入「不推薦企業」名單。 

64 如 客戶 無法在收到帳單後的 30 個日曆天內完成付費，必須以可留下紀錄的形式取得 Meet.jobs 的同意，雙方並約定付款時間。如 客戶 有刻意拖延，甚至在未取得 Meet.jobs 同意下未準時付費的情況，則 Meet.jobs 將會在網站上公開企業名稱，並列入「不推薦企業」名單。 

65 如 客戶 提供的職缺資訊，與最後錄取之條件不符，尤其當錄取薪資低於招聘職缺薪資區間的最低薪資時，企業 須在 72 小時內主動以書面或可留下記錄的方式提出說明，並取得求職者和推薦人同意的聲明，作為佐證。如未獲得求職者和推薦人的同意，且該情事發生超過三次，Meet.jobs 將會在網站上公開 客戶 名稱，並列入「不推薦企業」名單。 

個資保密 

66 由 Meet.jobs 應徵工作或推薦的求職者，其履歷、作品集、技術測試結果等所有資 料，僅供 客戶 評估該求職者是否錄取使用，客戶 不可用於其他用途。若因此而 造成求職者之損害，由 客戶 對求職者負擔損害賠償責任，概與 Meet.jobs 無涉。 

簽核方式 

67 Meet.jobs 提供以下方式供 客戶 簽核本合約。客戶 只需擇一採用即可。 

A. 直接在 Meet.jobs 系統上勾選同意合約。此為 Meet.jobs 所建議具備法 律效力且最有效率的方式。 

B. 採用 Meet.jobs 所認同的第三方電子簽核系統，簽核後上傳到 Meet.jobs 系統。

C. 下載 Meet.jobs 已簽核完成的合約，由 客戶 列印，簽核完成後，掃描上傳到 Meet.jobs 系統。 

D. 在 Meet.jobs 系統上點選寄送紙本合約，並填入收件地址，或聯絡 Meet.jobs 要求提供紙本合約。Meet.jobs 收到要求後將寄送已簽核完成的紙本合約共一式兩份，由 客戶 簽核完成後，掃描上傳到Meet.jobs 系統，並將一份紙本寄回到 Meet.jobs 備存。 

合約修改與終止 

68 無論選擇任何方案，只要未張貼職缺或職缺關閉，則除未完成之義務以外，客戶無新的費用產生。故絕大部份的情況下，本合約沒有終止的必要。 

69 如有需要，Meet.jobs 與 客戶 雙方皆具有單方面終止合約之權利。如一方欲終止合約，必須於終止日前一個月內告知對方。並於履行本合約全部義務後，合約方得終止。屆時 Meet.jobs 也將移除 客戶 在系統上所有資訊。惟第三方平台如 Google 等係由 SEO 排名效果，所搜尋到之資訊，非 Meet.jobs 所能控制修改。 

70 前項全部義務包括一切錄取與就職確認、款項支付、資訊提供以及資訊關閉等事項。 

71 若客戶有違約或違反法令之情事，經 Meet.jobs 認定有必要者，Meet.jobs 得自行移除客戶所張貼之職缺，並得立即終止合約。 

72 如遇不可抗力之因素，如其中有一方受災難、政治事件、宗教、種族等衝突以致 無法履行合約，另一方對他方不負任何法律責任。 

73 除獵才方案 可能有客製化合約以外，如 Meet.jobs 針對本合約有所修改，將透過系統以及可留下紀錄的方式通知 客戶。如 客戶 不同意修改的內容，一般情況下 ，只需不再使用 Meet.jobs 服務即可。 

74 對於客戶所要張貼之雇主資訊與職缺，Meet.jobs 保留接受或拒絕張貼之權利。 

準據法及爭議解決 

75 如本合約及／或「服務」引發任何爭議，皆以中華民國自由地區相關法規及法務 單位為主，並以臺灣臺北地方法院為第一審管轄法院。 

76 如本合約有任何無法履行的部分或條款，雙方均同意應以保留原始目的且又能履行的方式修改本合約條款。如果無法修改，雙方同意移除無法履行的部分，並 履行合約中的其餘條款。 

77 在法律允許的範圍內，本合約的英文版本及繁體中文版本均具有法律約束效力 ， 其他譯本皆為方便閱讀使用。如不同版本間條文、用詞有所歧異，則以繁體中文版本為主。本合約為雙方最終且完整之合意，有取代磋商過程中所提出之任何口頭或書面上的承諾、理解或其他意思表示之效力，且本合約之條款標題，僅為參考之用，雙方同意標題之內容不能作為解釋本合約之依據。

78 如果 Meet.jobs 對違約行為未採取法律行動，並不代表 Meet.jobs 放棄履行本合約的權利。未經 Meet.jobs 的同意，客戶 不得指定或轉讓此合約（或會員身分或服 務使用）。然而，客戶 您同意 Meet.jobs 得將此合約指定給其相關機構或收購 Meet.jobs 的機構。 

79 您同意以本合約所述的聯絡方式為提供 Meet.jobs 法律通知唯一的方式。 

舊合約適用

80 如 客戶 在 2023 年 05 月 01 日 以後仍繼續採用 Meet.jobs，則視為同意新合約。

聯絡我們 

81 線上聯絡：回覆留言機器人、留言板，或來信 service@meet.jobs 

82 電話聯絡：來電 +886 2 2577 5800。 

83 郵寄聯絡：台灣台北市松山區南京東路四段 106 號 5 樓。

`

};

