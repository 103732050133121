import React from 'react';
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers.js";
import axios from "axios";
import User from "../../../models/user.js";
import { withNamespaces } from "react-i18next";
import MeetJobsLoader from "../../general/MeetJobsLoader.jsx";
import {compare, page_smothly_scroll_to} from "../../../../assets/js/functions";
import {isEmail} from "../../../../assets/js/validators.js";
import { CSSTransitionGroup } from 'react-transition-group'
import {ReactComponent as IconClear} from "../../../../assets/images/icon/icon_clear_BG400.svg";

const config = new Config();
const headers = new ApiHeaders();

class AccountSettingPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.handleAvatarChange = this.handleAvatarChange.bind(this);
        this.handleNotificationChange = this.handleNotificationChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePasswordInputChange = this.handlePasswordInputChange.bind(this);
        this.togglePasswordSettingForm = this.togglePasswordSettingForm.bind(this);
        this.checkPendingData = this.checkPendingData.bind(this);
        this.discard = this.discard.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.submitResendChangeEmail = this.submitResendChangeEmail.bind(this);
        this.submitCancelChangeEmail = this.submitCancelChangeEmail.bind(this);
        this.state = {
            active_user_id: -1,
            user: {},
            form_data: {},
            original_form_data: {},
            form_data_password: {
                current_password: '',
                new_password: '',
                new_password_confirmation: '',
            },
            open_password_setting_form: false,
            error_messages: {},
            error_messages_password: {},
            has_pending_data: false,
            loading: true,
            publishing: false
        };
        this.once_sent_out = false;
        this.resent_change_email = false;
    };

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            active_user_id: this.props.reduxTokenAuth.currentUser.attributes.id
        }, () => this.fetchData());
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    fetchData() {
        let options = {
            method: 'GET',
            url: config.api_url(`/users/${this.state.active_user_id}?include=resume_attachments`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };


        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_user = response.data;
                    const user = new User(raw_user);

                    let form_data = {
                        avatar: {
                            image_preview_url: user.formatted_avatar_setting_url(),
                        },
                        name: user.formatted_form_name(),
                        email: user.formatted_form_email(),
                        phone: user.formatted_form_phone(),

                        // profile for application
                        applier_first_name: user.formatted_form_applier_first_name(),
                        applier_last_name: user.formatted_form_applier_last_name(),
                        // applier_email: user.formatted_form_applier_email(),
                        applier_phone: user.formatted_form_applier_phone(),
                        resume: {
                            file: {}
                        },
                        portfolio_url: user.formatted_portfolio_url(),
                        // email_notification_checked: true,
                        marketing_email:user.formatted_notification_setting_marketing_email(),
                        jobs_recommendation_email:user.formatted_notification_setting_job_recommendation_email()

                    };

                    this.setState((prev_state) => ({
                        ...prev_state,
                        user,
                        form_data,
                        original_form_data: JSON.parse(JSON.stringify(form_data)),
                        has_pending_data: false,
                        loading: false,
                        error_messages: {}
                    }));
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));
                }
            });
    };

    handleInputChange = (e, property_name) => {
        e.preventDefault();
        let value = e.target.value;

        // if(property_name==='email') value = value.toLowerCase();

        this.setState((prev_state) => {
            // check form error
            let error_messages = {...prev_state.error_messages};
            const { t } = this.props;
            switch (property_name) {
                case 'name' :
                    let name = [];
                    if(value.length<=0) name.push(`${t('general:required')}`);
                    error_messages = {
                        ...error_messages,
                        'name': name
                    };
                    break;
                case 'email' :
                    let email = [];
                    if(!isEmail(value)) email.push(`${t('general:invalid_email_format')}`);
                    error_messages = {
                        ...error_messages,
                        email
                    };
                    break;
                case 'portfolio_url':
                    let portfolio_url = [];
                    error_messages = {
                        ...error_messages,
                        'applied_profile.portfolio_url': portfolio_url
                    };
                    break;
                default :
                    error_messages = {
                        ...error_messages,
                        [property_name]: []
                    };
            }

            return ({
                ...prev_state,
                form_data: {
                    ...prev_state.form_data,
                    [property_name]: value
                },
                error_messages
            });
        }, () => this.checkPendingData());
    };

    handleNotificationChange = (e,property_name) =>{

        // e.preventDefault();
        let key = e.target.value;

        this.setState((prev_state) => {

            let error_messages = {...prev_state.error_messages};
            // const { t } = this.props;
            // check form error
            return ({
                ...prev_state,
                form_data: {
                    ...prev_state.form_data,
                    [key]: !prev_state.form_data[key]
                },
                error_messages
            });
        }, () => this.checkPendingData());

    }

    handlePasswordInputChange = (e, property_name) => {
        e.preventDefault();
        const value = e.target.value;
        this.setState((prev_state) => {
            // check form error
            let error_messages_password = {...prev_state.error_messages_password};
            const { t } = this.props;
            switch (property_name) {
                case 'current_password' :
                    let current_password = [];
                    if(value.length<=0) current_password.push(`${t('general:required')}`);
                    error_messages_password = {
                        ...error_messages_password,
                        current_password
                    };
                    break;
                case 'new_password' :
                    let new_password = [];
                    if(this.once_sent_out) {
                        if (value.length < 8) new_password.push(`${t('general:password_contains_at_least_8_characters_is_required')}`);
                    }
                    error_messages_password = {
                        ...error_messages_password,
                        new_password
                    };
                    break;
                case 'new_password_confirmation':
                    let new_password_confirmation = [];
                    if(this.once_sent_out) {
                        if (value.length < 8) new_password_confirmation.push(`${t('general:password_contains_at_least_8_characters_is_required')}`);
                        if (prev_state.form_data_password.new_password !== value) new_password_confirmation.push(`${t('general:the_passwords_you_entered_do_not_match')}`);
                    }
                    error_messages_password = {
                        ...error_messages_password,
                        new_password_confirmation
                    };
                    break;
                default :
                    error_messages_password = {
                        ...error_messages_password,
                        [property_name]: []
                    };
            }

            return ({
                ...prev_state,
                form_data_password: {
                    ...prev_state.form_data_password,
                    [property_name]: value
                },
                error_messages_password
            });
        });
    };

    togglePasswordSettingForm = e => {
        e.preventDefault();
        this.setState((prev_state) => ({
            ...prev_state,
            form_data_password: {
                current_password: '',
                new_password: '',
                new_password_confirmation: '',
            },
            error_messages_password: {},
            open_password_setting_form: !prev_state.open_password_setting_form
        }));
    };

    handleAvatarChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if(file) {
            const sizeLimit = 30*1024000;
            let avatar = [];
            if(file.size>sizeLimit) {
                const { t } = this.props;
                avatar.push(`${t('general:upload_size_limit__30MB')}`);
                this.setState((prev_state) => ({
                    ...prev_state,
                    error_messages: {
                        ...prev_state.error_messages,
                        avatar
                    }
                }));
            } else {
                reader.onloadend = () => {
                    this.setState((prev_state) => ({
                        ...prev_state,
                        form_data: {
                            ...prev_state.form_data,
                            avatar: {
                                ...prev_state.form_data.avatar,
                                file: file,
                                image_preview_url: reader.result
                            }
                        },
                        error_messages: {
                            ...prev_state.error_messages,
                            avatar
                        }
                    }), () => this.checkPendingData());
                };
                reader.readAsDataURL(file);
            }
        }
    };

    // handleResumeChange = e => {
    //     e.preventDefault();
    //     let file = e.target.files[0];
    //     if(file) {
    //         this.setState((prev_state) => ({
    //             ...prev_state,
    //             form_data: {
    //                 ...prev_state.form_data,
    //                 resume: {
    //                     ...prev_state.form_data.resume,
    //                     file
    //                 },
    //             },
    //         }), () => this.checkPendingData());
    //     }
    // };

    //check pending data

    checkPendingData() {
        const { form_data, original_form_data } = this.state;
        const has_pending_data = !compare(JSON.parse(JSON.stringify(form_data)), original_form_data);
        this.setState({ has_pending_data });
    };

    // actions bar

    discard = e => {
        e.preventDefault();
        this.setState((prev_state) => ({
            ...prev_state,
            form_data: JSON.parse(JSON.stringify(prev_state.original_form_data)),
            error_messages: {}
        }), () => this.checkPendingData());
    };

    submitForm = e => {
        e.preventDefault();
        const { user, form_data, active_user_id, publishing } = this.state;
        if(!publishing) {
            this.setState({ publishing: true });

            const change_email = (user.unverified_email === null && form_data.email !== user.formatted_email());

            let formData = new FormData();
            if(typeof form_data.avatar.file !== 'undefined') formData.append('user[avatar]', form_data.avatar.file);
            formData.append('user[name]', form_data.name);
            formData.append('user[phone]', form_data.phone);
            if(change_email) formData.append('user[email]', form_data.email);
            formData.append('user[applied_profile_attributes][first_name]', form_data.applier_first_name);
            formData.append('user[applied_profile_attributes][last_name]', form_data.applier_last_name);
            // formData.append('user[applied_profile_attributes][email]', form_data.applier_email);
            formData.append('user[applied_profile_attributes][phone]', form_data.applier_phone);
            formData.append('user[applied_profile_attributes][portfolio_url]', form_data.portfolio_url);
            formData.append('user[notification_setting_attributes][marketing_email]', form_data.marketing_email);
            formData.append('user[notification_setting_attributes][jobs_recommendation_email]', form_data.jobs_recommendation_email);


            let options = {
                method: 'PUT',
                url: config.api_url(`/users/${active_user_id}`),
                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                data: formData,
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    this.props.verifyTokenRequestSucceeded(response.data);

                    if(this._isMounted) {
                        const { t } = this.props;
                        this.setState((prev_state) => ({
                            ...prev_state,
                            error_messages: {},
                            publishing: false
                        }), () => {
                            if(change_email) {
                                this.props.addFlashMessage(
                                    true,
                                    t('flash_messages:please_check_the_verification_link_with_your_new_email__', { email: form_data.email }),
                                    '',
                                    'with-dismiss',
                                    ''
                                );
                            }
                            this.fetchData();
                        });
                    }
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) {
                            this.props.verifyTokenRequestFailed();
                            window.location.reload();
                        }
                    }

                    if(this._isMounted) {
                        if(typeof error.response === 'undefined') {
                            window.location.reload();
                            this.props.updateConnectionOffModal(true);
                        }

                        this.setState((prev_state) => ({
                            ...prev_state,
                            publishing: false,
                            error_messages:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: 'There was an error. Please try again later.'},
                        }));
                    }
                });
        }
    };

    submitResendChangeEmail = e => {
        e.preventDefault();
        const { user, active_user_id, publishing } = this.state;
        if(!publishing) {
            this.setState({ publishing: true });
            let formData = new FormData();
            formData.append('user[email]', user.unverified_email);

            let options = {
                method: 'PATCH',
                url: config.api_url(`/users/${active_user_id}`),
                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                data: formData,
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    if(this._isMounted) {
                        const { t } = this.props;
                        this.setState((prev_state) => ({
                            ...prev_state,
                            error_messages: {},
                            publishing: false
                        }), () => {
                            this.props.addFlashMessage(
                                true,
                                t('flash_messages:please_check_the_verification_link_with_your_new_email__', { email: user.unverified_email }),
                                '',
                                'with-dismiss',
                                ''
                            );
                            this.fetchData();
                        });
                    }
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) {
                            window.location.reload();
                        }
                    }

                    if(this._isMounted) {
                        if(typeof error.response === 'undefined') {
                            window.location.reload();
                            this.props.updateConnectionOffModal(true);
                        }

                        this.setState((prev_state) => ({
                            ...prev_state,
                            publishing: false,
                            error_messages: {
                                ...prev_state.error_messages,
                                unverified_change_emails: ['There was an error. Please try again later.']
                            }
                        }));
                    }
                })
                .then(() => {
                    this.resent_change_email = true;
                });
        }
    };

    submitCancelChangeEmail = e => {
        e.preventDefault();
        const { user, active_user_id, publishing } = this.state;
        if(!publishing) {
            this.setState({ publishing: true });

            let options = {
                method: 'DELETE',
                url: config.api_url(`/users/${active_user_id}/unverified_change_emails/${user.unverified_email_id}`),
                headers: headers.getItemsFromLocalStorage(),
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    if(this._isMounted) {
                        this.setState((prev_state) => ({
                            ...prev_state,
                            error_messages: {},
                            publishing: false
                        }), () => {
                            this.resent_change_email = false;
                            this.fetchData();
                        });
                    }
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) {
                            window.location.reload();
                        }
                    }

                    if(this._isMounted) {
                        if(typeof error.response === 'undefined') {
                            window.location.reload();
                            this.props.updateConnectionOffModal(true);
                        }

                        this.setState((prev_state) => ({
                            ...prev_state,
                            publishing: false,
                            error_messages: {
                                ...prev_state.error_messages,
                                unverified_change_emails: ['There was an error. Please try again later.']
                            }
                        }));
                    }
                });
        }
    };

    submitPasswordForm = e => {
        e.preventDefault();
        const { form_data_password, publishing, user } = this.state;
        let { error_messages_password } = this.state;

        // for 第一次送出前的檢查
        let has_errors = false;
        if(!this.once_sent_out) {
            const { t } = this.props;
            let current_password = [], new_password = [], new_password_confirmation = [];
            // current_password
            if(user.has_current_password()) {
                if(form_data_password.current_password.length<=0) {
                    current_password.push(`${t('general:required')}`);
                    has_errors = true;
                }
            }

            // new_password
            if (form_data_password.new_password.length < 8) {
                new_password.push(`${t('general:password_contains_at_least_8_characters_is_required')}`);
                has_errors = true;
            }

            // new_password_confirmation
            if (form_data_password.new_password_confirmation.length < 8) {
                new_password_confirmation.push(`${t('general:password_contains_at_least_8_characters_is_required')}`);
                has_errors = true;
            }
            if (form_data_password.new_password !== form_data_password.new_password_confirmation) {
                new_password_confirmation.push(`${t('general:the_passwords_you_entered_do_not_match')}`);
                has_errors = true;
            }

            error_messages_password = {
                ...error_messages_password,
                current_password,
                new_password,
                new_password_confirmation
            };

            this.once_sent_out = true;
        }

        if(has_errors) {
            this.setState({ error_messages_password });
        } else if(!publishing) {
            this.setState({ publishing: true });
            let formData = new FormData();
            if(user.has_current_password()) formData.append('current_password', form_data_password.current_password);
            formData.append('password', form_data_password.new_password);
            formData.append('password_confirmation', form_data_password.new_password_confirmation);
            let options = {
                method: 'PATCH',
                url: config.api_url('/auth/password'),
                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                data: formData,
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    if(this._isMounted) {
                        this.setState((prev_state) => ({
                            ...prev_state,
                            form_data_password: {
                                current_password: '',
                                new_password: '',
                                new_password_confirmation: '',
                            },
                            error_messages_password: {},
                            open_password_setting_form: false,
                            publishing: false,
                        }), () => this.fetchData());
                    }
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) {
                            window.location.reload();
                        }
                    }

                    if(this._isMounted) {
                        if(typeof error.response === 'undefined') {
                            window.location.reload();
                            this.props.updateConnectionOffModal(true);
                        }

                        this.setState((prev_state) => ({
                            ...prev_state,
                            publishing: false,
                            error_messages_password:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: 'There was an error. Please try again later.'},
                        }));

                        page_smothly_scroll_to(document.querySelector(".block.password").offsetTop - 120, 300);
                    }
                });
        }
    };

    render() {

        const { user, form_data, form_data_password, open_password_setting_form, loading, publishing, has_pending_data } = this.state;
        const { error_messages, error_messages_password } = this.state;
        const { handleAvatarChange, handleInputChange, handlePasswordInputChange, togglePasswordSettingForm ,handleNotificationChange } = this;
        const { discard, submitForm, submitResendChangeEmail, submitCancelChangeEmail, submitPasswordForm } = this;
        const { t } = this.props;

        if(loading) {
            return (
                <div className="loading-skeleton-wrapper">
                    <div className="userpanel-account-setting">
                        <div className="container-fluid">
                            <div className="inner-wrapper">
                                <form>
                                    <h2>{t('general:account_setting')}</h2>
                                    <h4 className="title-outside">{t('account')}</h4>
                                    <div className="block account">
                                        <label className="lines">
                                            <h5>{t('profile_picture')}</h5>
                                            <h6>{t('_recommend_size__160x160px_')}</h6>
                                        </label>
                                        <div className="form-row">
                                            <div className="avatar-wrapper">
                                                <div className="avatar">
                                                    <div className='image-wrapper' />
                                                </div>
                                                <label htmlFor="upload-avatar" className="btn">{t('general:choose_file')}</label>
                                            </div>
                                        </div>

                                        <label><h5>{t('user_name')}</h5></label>
                                        <div className="form-row">
                                            <input className="input" type="text"/>
                                        </div>

                                        <label><h5>{t('email')}</h5></label>
                                        <div className="form-row">
                                            <input className="input" type="text"/>
                                        </div>

                                        <label htmlFor="phone"><h5>{t('phone_number')}</h5></label>
                                        <div className="form-row">
                                            <input className="input" type="text"/>
                                        </div>
                                    </div>

                                    <h4 className="title-outside">{t('password')}</h4>
                                    <div className="block password">
                                        <button onClick={ e => e.preventDefault() } className="btn btn-larger">
                                            <h5>{t('change_password')}</h5>
                                        </button>
                                    </div>


                                    <h4 className="title-outside">{t('profile_for_application')}</h4>
                                    <div className="block profile-for-application">
                                        <label htmlFor="applier_first_name">
                                            <h5>{t('name')}</h5>
                                        </label>
                                        <div className="form-row form-row-2">
                                            <div className="form-col">
                                                <input className="input" type="text" />
                                            </div>
                                            <div className="form-col">
                                                <input className="input" type="text" />
                                            </div>
                                        </div>
                                        <label htmlFor="applier_phone">
                                            <h5>{t('phone_number')}</h5>
                                        </label>
                                        <div className="form-row">
                                            <input className="input" type="text" />
                                        </div>

                                        <label htmlFor="portfolio_url">
                                            <h5>{t('general:reference_link')}</h5>
                                        </label>
                                        <div className="form-row">
                                            <input className="input" type="text" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let has_errors = false;
        const white_list = ['full_message', 'avatar', 'unverified_change_emails'];
        for(let key in error_messages) {
            if(white_list.indexOf(key) === -1 && error_messages[key].length) has_errors = true;
        }

        let has_errors_password = false;
        const white_list_password = ['full_message'];
        for(let key in error_messages_password) {
            if(white_list_password.indexOf(key) === -1 && error_messages_password[key].length) has_errors_password = true;
        }

        return(
            <div className="userpanel-account-setting">
                <div className="container-fluid">
                    <div className="inner-wrapper">
                        <form>
                            <h2>{t('general:account_setting')}</h2>
                            {/*{(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}*/}
                            <h4 className="title-outside">{t('account')}</h4>
                            <div className="block account">
                                <label htmlFor="" className="lines">
                                    <h5>{t('profile_picture')}</h5>
                                    <h6>{t('_recommend_size__160x160px_')}</h6>
                                </label>
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <div className="avatar-wrapper">
                                            <div className="avatar">
                                                {
                                                    (form_data.avatar.image_preview_url) ?
                                                        <div
                                                            className='image-wrapper'
                                                            style={{backgroundImage: `url(${form_data.avatar.image_preview_url})`}}
                                                        /> :
                                                        <div className='image-wrapper' />
                                                }
                                                {
                                                    (publishing && typeof form_data.avatar.file !== 'undefined') ?
                                                        <div className="loading-cover">
                                                            <MeetJobsLoader />
                                                        </div> : ''
                                                }
                                            </div>
                                            <input
                                                id="upload-avatar"
                                                type="file"
                                                accept="image/png, image/jpeg, image/jpg, image/gif"
                                                onChange={ e => handleAvatarChange(e) }
                                            />
                                            <label htmlFor="upload-avatar" className="btn btn-flat btn-hollow btn">{t('general:choose_file')}</label>
                                        </div>
                                    </div>
                                    <div className="form-col">
                                        {(error_messages.avatar && error_messages.avatar.length) ? <h6 className="message error-message">{error_messages.avatar.join(', ')}</h6> : ''}
                                    </div>
                                </div>

                                <label htmlFor="name"><h5>{t('user_name')}</h5></label>
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <input
                                            className={`input ${(error_messages.name && error_messages.name.length) ? 'input-danger' : ''}`}
                                            type="text"
                                            value={form_data.name}
                                            placeholder={`${t('general:e_g__')} John Doe`}
                                            onChange={ e => handleInputChange(e, `name`) }
                                            id="name"
                                        />
                                    </div>
                                    <div className="form-col">
                                        {(error_messages.name && error_messages.name.length) ? <h6 className="message error-message">{error_messages.name.join(', ')}</h6> : ''}
                                    </div>
                                </div>

                                <label htmlFor="email"><h5>{t('email')}</h5></label>
                                {
                                    (!user.unverified_email) ?
                                        <div className="form-row form-row-dire-col">
                                            <div className="form-col">
                                                <input
                                                    className={`input ${(error_messages.email && error_messages.email.length) ? 'input-danger' : ''}`}
                                                    type="text"
                                                    value={form_data.email}
                                                    onChange={ e => handleInputChange(e, `email`) }
                                                    id="email"
                                                />
                                            </div>
                                            <div className="form-col">
                                                {(error_messages.email && error_messages.email.length) ? <h6 className="message error-message">{error_messages.email.join(', ')}</h6> : ''}
                                            </div>
                                        </div> :
                                        <div className="form-row form-row-dire-col">
                                            <div className="form-col">
                                                <input
                                                    className="input input-readonly"
                                                    type="text"
                                                    value={user.unverified_email}
                                                    id="email"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-col">
                                                <h6 className="message error-message">{t('not_verify_yet_we_will_still_send_email_to___', { email: user.formatted_email() })}</h6>
                                            </div>
                                            <div className="form-col">
                                                <div className="unverified-wrapper">
                                                    {
                                                        (!this.resent_change_email) ?
                                                            <button className="link" onClick={ e => submitResendChangeEmail(e) }>
                                                                <h5>{t('resend_verification')}</h5>
                                                            </button> :
                                                            <button onClick={ e => e.preventDefault() } className="link disabled">
                                                                <h5>{t('resend_verification')}</h5>
                                                            </button>
                                                    }
                                                    <button className="link link-discard" onClick={ e => submitCancelChangeEmail(e) }>
                                                        <IconClear />
                                                        <h5>{t('discard_change')}</h5>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-col">
                                                {
                                                    (error_messages.unverified_change_emails && error_messages.unverified_change_emails.length) ?
                                                        <h6 className="message error-message">{error_messages.unverified_change_emails.join(', ')}</h6> : ''
                                                }
                                            </div>
                                        </div>
                                }

                                <label htmlFor="phone"><h5>{t('phone_number')}</h5></label>
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <input
                                            className={`input ${(error_messages.phone && error_messages.phone.length) ? 'input-danger' : ''}`}
                                            type="text"
                                            value={form_data.phone}
                                            placeholder={t('phone_number')}
                                            onChange={ e => handleInputChange(e, `phone`) }
                                            id="phone"
                                        />
                                    </div>
                                    <div className="form-col">
                                        {(error_messages.phone && error_messages.phone.length) ? <h6 className="message error-message">{error_messages.phone.join(', ')}</h6> : ''}
                                    </div>
                                </div>
                            </div>

                            <h4 className="title-outside">{t('newsletter_title')}</h4>
                            <div className={'block'}>

                                <div className="hidden">
                                    <input id="jobs_recommendation_email"
                                           checked={form_data.jobs_recommendation_email}
                                           onChange={ e => handleNotificationChange(e, `jobs_recommendation_email`) }
                                           value="jobs_recommendation_email"
                                           type="checkbox"/>
                                    &nbsp;<label
                                    htmlFor="jobs_recommendation_email">{t('jobs_recommendation_email')}</label>
                                </div>

                                <div>
                                    <input
                                        id="marketing_email"
                                        onChange={ e => handleNotificationChange(e, `marketing_email`) }
                                        checked={form_data.marketing_email}
                                        value="marketing_email"
                                        type="checkbox"/>
                                    &nbsp;<label
                                        htmlFor="marketing_email">{t('newsletter')}</label>
                                </div>

                            </div>

                            <h4 className="title-outside">{t('password')}</h4>
                            <div className="block password">
                                {(error_messages_password.full_message) ? <h6 className="message error-message">{error_messages_password.full_message}</h6> : ''}
                                {
                                    (!open_password_setting_form) ?
                                        (
                                            (user.has_current_password()) ?
                                                <button
                                                    className="btn btn-larger btn-flat btn-hollow"
                                                    onClick={ e => togglePasswordSettingForm(e) }
                                                >
                                                    <h5>{t('change_password')}</h5>
                                                </button> :
                                                <div>
                                                    <p>{t('you_currently_do_not_have_a_password_you_may_add_one_to_your_account_')}</p>
                                                    <button
                                                        className="btn btn-larger btn-flat btn-hollow"
                                                        onClick={ e => togglePasswordSettingForm(e) }
                                                    >
                                                        <h5>{t('add_password')}</h5>
                                                    </button>
                                                </div>
                                        ) :
                                        <div>
                                            {
                                                (user.has_current_password()) ?
                                                    <div>
                                                        <label htmlFor="current_password"><h5>{t('current_password')}</h5></label>
                                                        <div className="form-row form-row-dire-col">
                                                            <div className="form-col">
                                                                <input
                                                                    className={`password input ${
                                                                        (
                                                                            error_messages_password.current_password &&
                                                                            error_messages_password.current_password.length
                                                                        ) ? 'input-danger' : ''
                                                                    }`}
                                                                    type="text"
                                                                    value={form_data_password.current_password}
                                                                    placeholder={t('your_current_password')}
                                                                    onChange={ e => handlePasswordInputChange(e, `current_password`) }
                                                                    id="current_password"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div className="form-col">
                                                                {
                                                                    (error_messages_password.current_password && error_messages_password.current_password.length) ?
                                                                        <h6 className="message error-message">
                                                                            {error_messages_password.current_password.join(', ')}
                                                                        </h6> : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="form-row" />
                                                    </div> : ''
                                            }

                                            <label htmlFor="new_password"><h5>{t('new_password')}</h5></label>
                                            <div className="form-row form-row-dire-col">
                                                <div className="form-col">
                                                    <input
                                                        className={`password input ${
                                                            (
                                                                error_messages_password.new_password &&
                                                                error_messages_password.new_password.length
                                                            ) ? 'input-danger' : ''
                                                        }`}
                                                        type="text"
                                                        value={form_data_password.new_password}
                                                        placeholder={t('your_new_password')}
                                                        onChange={ e => handlePasswordInputChange(e, `new_password`) }
                                                        id="new_password"
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="form-col">
                                                    {
                                                        (error_messages_password.new_password && error_messages_password.new_password.length) ?
                                                            <h6 className="message error-message">
                                                                {error_messages_password.new_password.join(', ')}
                                                            </h6> : ''
                                                    }
                                                </div>
                                            </div>

                                            <label htmlFor="new_password_confirmation"><h5>{t('new_password_confirmation')}</h5></label>
                                            <div className="form-row form-row-dire-col">
                                                <div className="form-col">
                                                    <input
                                                        className={`password input ${
                                                            (
                                                                error_messages_password.new_password_confirmation &&
                                                                error_messages_password.new_password_confirmation.length
                                                            ) ? 'input-danger' : ''
                                                        }`}
                                                        type="text"
                                                        value={form_data_password.new_password_confirmation}
                                                        placeholder={t('re_enter_new_password')}
                                                        onChange={ e => handlePasswordInputChange(e, `new_password_confirmation`) }
                                                        id="new_password_confirmation"
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="form-col">
                                                    {
                                                        (error_messages_password.new_password_confirmation && error_messages_password.new_password_confirmation.length) ?
                                                            <h6 className="message error-message">
                                                                {error_messages_password.new_password_confirmation.join(', ')}
                                                            </h6> : ''
                                                    }
                                                </div>
                                            </div>

                                            <div className="buttons-wrapper">
                                                <button
                                                    className="btn btn-larger btn-flat btn-ghost"
                                                    onClick={ e => togglePasswordSettingForm(e) }
                                                >
                                                    <h5>{t('general:cancel')}</h5>
                                                </button>
                                                {
                                                    (!has_errors_password) ?
                                                        <button
                                                            className="btn btn-larger btn-flat btn-fill"
                                                            onClick={ e => submitPasswordForm(e) }
                                                        >
                                                            <h5>{t('general:save')}</h5>
                                                        </button> :
                                                        <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-fill disabled">
                                                            <h5>{t('general:save')}</h5>
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                }
                            </div>

                            {/*<h4 className="title-outside">Social login</h4>*/}
                            {/*<div className="block social-media-connect-buttons">*/}
                                {/*<label htmlFor=""><h5>Google</h5></label>*/}
                                {/*<div className="form-row">*/}
                                    {/*<a className="btn btn-flat btn-hollow"><h5>Disconnect</h5></a>*/}
                                {/*</div>*/}
                                {/*<label htmlFor=""><h5>Facebook</h5></label>*/}
                                {/*<div className="form-row">*/}
                                    {/*<a className="btn btn-flat btn-fill"><h5>Connect</h5></a>*/}
                                {/*</div>*/}
                            {/*</div>*/}

                            <h4 className="title-outside">{t('profile_for_application')}</h4>
                            <div className="block profile-for-application">
                                <label htmlFor="applier_first_name">
                                    <h5>{t('name')}</h5>
                                </label>
                                <div className="form-row form-row-2">
                                    <div className="form-col">
                                        <input
                                            className="input"
                                            type="text"
                                            value={form_data.applier_first_name}
                                            placeholder={t('first_name')}
                                            onChange={ e => handleInputChange(e, `applier_first_name`) }
                                            id="applier_first_name"
                                        />
                                    </div>
                                    <div className="form-col">
                                        <input
                                            className="input"
                                            type="text"
                                            value={form_data.applier_last_name}
                                            placeholder={t('last_name')}
                                            onChange={ e => handleInputChange(e, `applier_last_name`) }
                                            id="applier_last_name"
                                        />
                                    </div>
                                </div>

                                {/*<label htmlFor="applier_email">*/}
                                    {/*<h5>{t('email')}</h5>*/}
                                {/*</label>*/}
                                {/*<div className="form-row">*/}
                                    {/*<input*/}
                                        {/*className="input"*/}
                                        {/*type="text"*/}
                                        {/*value={form_data.applier_email}*/}
                                        {/*placeholder={t('email')}*/}
                                        {/*onChange={ e => handleInputChange(e, `applier_email`) }*/}
                                        {/*id="applier_email"*/}
                                    {/*/>*/}
                                {/*</div>*/}

                                <label htmlFor="applier_phone">
                                    <h5>{t('phone_number')}</h5>
                                </label>
                                <div className="form-row">
                                    <input
                                        className="input"
                                        type="text"
                                        value={form_data.applier_phone}
                                        placeholder={t('phone_number')}
                                        onChange={ e => handleInputChange(e, `applier_phone`) }
                                        id="applier_phone"
                                    />
                                </div>

                                {/*<label htmlFor="resume" className="lines">*/}
                                    {/*<h5>Resume / CV</h5>*/}
                                    {/*<h6>{(form_data.resume.file) ? (form_data.resume.file.name) : user.formatted_default_resume_file_name()}</h6>*/}
                                {/*</label>*/}
                                {/*<div className="form-row">*/}
                                    {/*<input*/}
                                        {/*id="upload-resume"*/}
                                        {/*type="file"*/}
                                        {/*onChange={ e => handleResumeChange(e) }*/}
                                    {/*/>*/}
                                    {/*<label htmlFor="upload-resume" className="btn btn-larger btn-flat btn-hollow btn-upload-resume">Choose file</label>*/}
                                {/*</div>*/}

                                <label htmlFor="portfolio_url">
                                    <h5>{t('general:reference_link')}</h5>
                                </label>
                                <div className="form-row form-row-dire-col">
                                    <div className="form-col">
                                        <input
                                            className={`input ${
                                                (
                                                    error_messages['applied_profile.portfolio_url'] &&
                                                    error_messages['applied_profile.portfolio_url'].length
                                                ) ? 'input-danger' : ''
                                            }`}
                                            type="text"
                                            value={form_data.portfolio_url}
                                            placeholder={t('https___linkedin_com_in_your_name')}
                                            onChange={ e => handleInputChange(e, `portfolio_url`) }
                                            id="portfolio_url"
                                        />
                                    </div>
                                    <div className="form-col">
                                        {
                                            (error_messages['applied_profile.portfolio_url'] && error_messages['applied_profile.portfolio_url'].length) ?
                                                <h6 className="message error-message">
                                                    {error_messages['applied_profile.portfolio_url'].join(', ')}
                                                </h6> : ''
                                        }
                                    </div>
                                </div>
                            </div>

                            {/*<h4 className="title-outside">Email notification</h4>*/}
                            {/*<div className="block">*/}
                                {/*<input type="checkbox" className="checkbox-input" id="accept-agreement" />*/}
                                {/*<label htmlFor="accept-agreement" className="checkbox">*/}
                                    {/*<p>I would like to receive latest job information from Meet.jobs.</p>*/}
                                {/*</label>*/}
                            {/*</div>*/}
                        </form>
                    </div>
                </div>

                <CSSTransitionGroup
                    component="div"
                    transitionName="actions-bar-slide"
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={100}
                >
                    {
                        (has_pending_data) ?
                            <div className="actions-bar-wrapper">
                                <div className="container">
                                    <div className="actions-bar">
                                        <p>{(publishing) ? `${t('general:saving')}...` : t('to_save_your_changes__click_save_')}</p>
                                        <div className="buttons-wrapper">
                                            {
                                                (publishing) ?
                                                    <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-fill disabled">
                                                        <h5>{t('general:cancel')}</h5>
                                                    </button> :
                                                    <button
                                                        className="btn btn-larger btn-flat btn-fill"
                                                        onClick={ e => discard(e) }
                                                    >
                                                        <h5>{t('general:cancel')}</h5>
                                                    </button>
                                            }
                                            {
                                                (publishing || has_errors) ?
                                                    <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-hollow disabled">
                                                        <h5>{t('general:save')}</h5>
                                                    </button> :
                                                    <button
                                                        className="btn btn-larger btn-flat btn-hollow"
                                                        onClick={ e => submitForm(e) }
                                                    >
                                                        <h5>{t('general:save')}</h5>
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> : ''
                    }
                </CSSTransitionGroup>
            </div>
        );
    };
}

export default withNamespaces(['userpanel_account_setting', 'flash_messages', 'general'])(AccountSettingPage);
