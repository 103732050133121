export default {
    account: "Account",
    profile_picture: "Profile picture",
    _recommend_size__160x160px_: "(recommend_size: 160x160px)",
    user_name: "User name",
    email: "Email",
    not_verify_yet_we_will_still_send_email_to___: "Not verify yet. We will still send email to {{email}}.",
    resend_verification: "Resend verification",
    discard_change: "Discard change",
    newsletter:"Newsletter",
    newsletter_title: "Newsletter",
    jobs_recommendation_email: "Jobs recommended by Meet.jobs",
    password: "Password",
    change_password: "Change password",
    current_password: "Current password",
    your_current_password: "Your current password",
    new_password: "New password",
    your_new_password: "Your new password",
    new_password_confirmation: "New password confirmation",
    re_enter_new_password: "Re-enter new password",
    you_currently_do_not_have_a_password_you_may_add_one_to_your_account_: "You currently do not have a password. You may add one to your account.",
    add_password: "Add password",

    phone_number: "Phone number",
    profile_for_application: "Profile for application",
    name: "Name",
    first_name: "First name",
    last_name: "Last name",
    https___linkedin_com_in_your_name: "https://linkedin.com/in/your-name",
    to_save_your_changes__click_save_: "To save your changes, click Save.",
};