import React, { useEffect, useState } from "react";
export default ({ ads, lang, employer, ...props }) => {
	const employer_id = employer.id;
	const [filtered, setFiltered] = useState([]);
	useEffect(() => {
		const _ads = ads.filter((ad) => {
			if (ad.title_en === "") return true;
			const allowed = ad.title_en
				.trim()
				.split(" ")
				.map((p) => {
					return Number(p);
				});
			return allowed.includes(employer_id);
		});
		setFiltered(_ads);
	}, [ads]);
	return (
		<div className="employer-page-ad">
			{filtered.map(
				({ id, image_url_zh_tw, image_url_en, url_en, url_zh_tw }) => (
					<div key={`ad-${id}`}>
						<a href={lang === "en" ? url_en : url_zh_tw}>
							<img className="ad-image-pc" alt="" src={image_url_zh_tw} />
							<img className="ad-image-mobile" alt="" src={image_url_en} />
						</a>
					</div>
				),
			)}
		</div>
	);
};
