export default {

    // add reference notes modal
    add_reference_letter: "新增推薦訊息",
    name: "姓名",
    first_name: "名字",
    last_name: "姓氏",
    email: "Email",
    phone_number: "電話號碼",
    let_us_know_more_about_this_candidate_: "請與我們分享這位優秀的求職者。",
    i_realize_that_the_reference_letter_can_not_be_modified_once_it_is_sent_: "我已了解，推薦信在送出後是不能任意修改的。",

    // candidate message modal
    title: "標題",
    location: "地點",
    date: "日期",
    time: "時間",
    to: "至",
    file_name: "檔案名稱",
    type_something___: "輸入些什麼...",
    after_you_got_the_job_offer__please_let_meet_jobs_know_about_it__we_would_grant_you___reward_: "若您已被企業錄取，請務必在此進行錄取回報。Meet.jobs 將在您正式就職後，提供 <span>美金100元</span> 的就職祝賀金。",
    got_the_job_offer: "回報錄取",
    cancel_application: "取消應徵",
    please_be_sure_you_have_passed_the_interview_and_processed_all_the_details_of_this_role_: "是否要回報您已通過企業面試，並順利錄取該工作機會？",
    would_you_like_to_confirm_your_job_offer_and_get_100_usd_onboard_reward_from_meet_jobs_: "是否要回報您已經順利錄取該工作機會，並領取 Meet.jobs 提供的 100 USD 就職祝賀金？",
    are_you_sure_to_cancel_your_application_: "確定要取消這個應徵嗎？",
    by__: "推薦人：{{referrer_name}}",

    // read reference notes modal
    read_reference_letter: "閱讀推薦訊息",

    // claim referral reward modal
    claim_referral_reward: "領取推薦感謝金",
    please_be_sure___has_got_the_offer__the_employer_would_also_receive_a_notice_to_approve_: "回報 {{applier_name}} 已經順利錄取，Meet.jobs 也將發出通知與企業確認就職情況。",

    // my referral link modal
    my_referral_link: "我的推薦連結",
    how_it_works_: "如何運作？",
    _1__share_your_referral_link_to_friends_: "1. 分享您的推薦連結給朋友們",
    _2__or_enter_your_referral_code___when_he_or_she_applies_: "2. 或讓他（她）在應徵時輸入您的應徵代碼 {{referral_code}}",
    _3__when_candidates_are_successfully_hired_and_pass_the_probation_claim_your_referral_cash_reward_: "3. 成功錄取並順利就職 90 天後，您可在此回報錄取並獲得推薦感謝金！",
};