export default {
    maximize_your_value_in_the_world_: "讓全世界一起幫你找工作",
    // reach_a_bigger_world_: "遇見更寬廣的世界",
    trending: "熱門",

    refer_talents: "推薦朋友找工作",
    refer_your_friends_to_get_hired_and_earn_referral_reward__1_000__usd_: `幫助朋友找工作，賺獎金<br>”推薦感謝金“ 1,000 美元起`,
    hire_talents: "我是企業要徵才",
    headhunter_services_with_less_than_half_cost_and_it_technologies_: "平台的速度，獵才的服務<br>收費不到獵頭的 50%",

    latest_jobs: "最新工作機會",
    more_latest_jobs: "更多最新工作機會",

    how_does_meet_jobs_work_: "如何使用 Meet.jobs",
    feature_title:"Meet.jobs - 專業人才的推薦工作平台",
    featured_jobs: "精選工作機會",
    feature_1_title:'專注國際',
    feature_2_title:'薪資透明',
    feature_3_title:'消滅無聲卡',
    feature_4_title:'就職祝賀金',
    feature_1_des : '企業、工作機會和人才用戶來自 71 個國家，是專業人才走向國際的第一站。',
    feature_2_des : '沒有「面議」，所有的工作機會都標明薪資範圍。對求職者公平，也提高招募效率。',
    feature_3_des : '企業回覆率高達 99%！每一筆應徵，Meet.jobs 都會追蹤到底，督促企業回覆、邀約面試。',
    feature_4_des : '錄取者可獲得 100 美元「就職祝賀金」。歡迎您分享錄取經驗，幫助更多的人。（非強制）',

    reward_and_sharing:'就職祝賀金與錄取心得分享',

    good_jobs_and_employers_selected_by_meet_jobs: "直接在 Meet.jobs 網站應徵有興趣的優質工作。",
    _100_usd_onboard_reward_when_you_getting_hired: "錄取後在系統上回報，告知我們您的好消息！",
    _1_000__usd_referral_reward_for_a_successful_hire: "Meet.jobs 將頒發 100 美元就職獎金，並邀請您分享錄取心得。",

    featured_employers: "重視人才的優質企業",

    sign_up_meet_jobs:'註冊 meet.jobs<br> 獲取更多全球工作資訊',
    benefit_to_meet_jobs:'Meet.jobs 會員可獲得',
    benefit_1:' 1. 一次免費職涯諮詢服務',
    benefit_2:'2. 電子報分享最新工作資訊',
    one_click:'＊一鍵註冊，不用填寫履歷！',

    step_one:"Step 1. 應徵工作",
    step_two:"Step 2. 回報錄取",
    step_three:"Step 3. 領取獎金",

    sign_up_call_to_action_index:"註冊網站獲得更多資訊",
    remote: "遠端",
};