import Config from "../components/models/config";

const config = new Config();

class ThirdPartScript {
    constructor() {
        this.scripts = {
					googleMaps: {
						// src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBqqDkaQSDC8135BAeY8casgGN3F4owk7M&v=3.35&libraries=geometry,drawing,places'
						src:
							"https://maps.googleapis.com/maps/api/js?key=AIzaSyA0OYT9Fi3iAihsgiwKPKDMItZ9UcpisiE&v=3.35&libraries=geometry,drawing,places",
					},
					tawkTo: {
						src: `https://embed.tawk.to/${config.getTawkToSiteId()}/${config.getTawkToChannelHash()}`,
						async: true,
						charset: "UTF-8",
						crossorigin: "*",
					},
				};
    };

    isExist(id) {
        if(this.scripts[id]) return true;
        return false;
    }

    getDetail(id) {
        return this.scripts[id];
    }
}

export default ThirdPartScript;
