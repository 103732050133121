import React from 'react';

const MeetJobsLoader = () => (
    <svg className="meet-jobs-loader" width="46px" height="30px" viewBox="0 0 46 30" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="meetjobs_loading" transform="translate(-123.000000, -30.000000)" stroke="#FFFFFF"
               strokeWidth="2.6">
                <g id="logo_meetjobs" transform="translate(125.000000, 32.000000)">
                    <path d="M15.178573,1.34671487 C14.7786057,0.548199181 13.9527422,0 12.9988649,0 C12.0468677,0 11.2224229,0.546035471 10.8215689,1.3419618 C10.8206467,1.34373533 10.8197599,1.34550887 10.8188731,1.3472824 L0.256381992,22.4480737 C0.0922946794,22.7758226 2.27373675e-13,23.1457108 2.27373675e-13,23.5372005 C2.27373675e-13,24.8827449 1.090794,25.9735744 2.43637381,25.9735744 L12.1561528,25.9735744 C13.7427913,25.9735744 15.116854,25.0636098 15.7849795,23.7370423 L27.0639427,1.3419618 C27.4647967,0.546035471 28.2892769,0 29.2412387,0 C30.1965348,0 31.0233915,0.549795362 31.4226849,1.35026194 L41.9493151,22.4510177 C42.1124802,22.7780573 42.2042428,23.1469168 42.2042428,23.5372005 C42.2042428,24.8827449 41.1134134,25.9735744 39.767869,25.9735744 L30.0225512,25.9735744 C28.4327913,25.9735744 27.0563874,25.0599563 26.3897517,23.7291323 L15.178573,1.34671487 Z" id="logo_base_lower" opacity="0.1" />
                    <path className="loader-path" d="M15.178573,1.34671487 C14.7786057,0.548199181 13.9527422,0 12.9988649,0 C12.0468677,0 11.2224229,0.546035471 10.8215689,1.3419618 C10.8206467,1.34373533 10.8197599,1.34550887 10.8188731,1.3472824 L0.256381992,22.4480737 C0.0922946794,22.7758226 2.27373675e-13,23.1457108 2.27373675e-13,23.5372005 C2.27373675e-13,24.8827449 1.090794,25.9735744 2.43637381,25.9735744 L12.1561528,25.9735744 C13.7427913,25.9735744 15.116854,25.0636098 15.7849795,23.7370423 L27.0639427,1.3419618 C27.4647967,0.546035471 28.2892769,0 29.2412387,0 C30.1965348,0 31.0233915,0.549795362 31.4226849,1.35026194 L41.9493151,22.4510177 C42.1124802,22.7780573 42.2042428,23.1469168 42.2042428,23.5372005 C42.2042428,24.8827449 41.1134134,25.9735744 39.767869,25.9735744 L30.0225512,25.9735744 C28.4327913,25.9735744 27.0563874,25.0599563 26.3897517,23.7291323 L15.178573,1.34671487 Z" id="logo_base_upper" strokeLinecap="round" />
                </g>
            </g>
        </g>
    </svg>
);

export default MeetJobsLoader;