export default {
    i_found_this_job_that_is_perfect_for_you_accept_my_referral_and_apply_for_it_on_meet_jobs_: "I found this job is a perfect fit and have applied for you as your behavior on Meet.jobs. Please contact with me if any further questions.",
    not_interested: "Not interested",
    accept_referral: "Accept referral",
    read_reference_letter: "Read reference",

    name: "Name",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    phone_number: "Phone number",
    your_phone_number: "Your phone number",
    https___linkedin_com_in_your_name: "https://linkedin.com/in/your-name",
    cover_letter: "Cover letter",
    write_cover_letter_here_: "Write cover letter here.",
    applySuccessfullyTitle: "Apply successfully!",
    applySuccessfullyDescription: "Check your application status in {{my_applications}}.",
    applySuccessfullyOnboardRewardReminding: "Remember to confirm your employment when hired. You will receive <span class='reward'>100 USD</span> reward form Meet.jobs.",
    also_remember_to_confirm_your_employment_when_hired_you_will_receive_100_usd_reward_form_meet_jobs_: "Also, remember to confirm your employment when hired. You will receive 100 USD reward form Meet.jobs.",
    no_resume___cv_file_is_attached_do_you_want_to_apply_without_an_attachment_: "No Resume / CV file is attached. Do you want to Apply without an attachment?",
}
