export default {
    efficient_recruitment_with_social_referral: "推薦讓求職更有效率",
    speed_of_internet_startup_and_quality_of_headhunter_service_to_leverage_on_professional_social_networks_let_the_whole_world_get_you_right_talent_: "眼花撩亂的求職平台，讓你的轉職之路冗長又繁瑣嗎？<br />串起你的人才鏈，讓全世界一起幫你和你的朋友找工作，<br />同時獲取高額的推薦感謝金",
    refer_a_job: "開始推薦工作",

    _01_title: "輕鬆推薦",
    _01_content: "點擊精選工作機會旁的小飛機，<br />立即傳送專屬推薦連結給朋友",
    _02_title: "賺取獎金",
    _02_content: "無論是應徵者或推薦人，<br />都可在 Meet.jobs 上回報錄取，並在就職後獲得獎金",

    sharings_of_our_referral_specialists: "推薦專家的分享",
    browse_featured_jobs_and_refer_to_your_friend_today_: "<span>今天起，</span>成為 Meet.jobs 推薦專家！",
};