export default {
    i_found_this_job_that_is_perfect_for_you_accept_my_referral_and_apply_for_it_on_meet_jobs_: "我認為這個職位很適合你，所以在 Meet.jobs 幫你應徵了這個職位。如果有問題的話，都歡迎跟我討論喔！",
    not_interested: "不感興趣",
    accept_referral: "接受邀請",
    read_reference_letter: "閱讀推薦訊息",

    name: "姓名",
    first_name: "名字",
    last_name: "姓氏",
    email: "Email",
    phone_number: "聯絡電話",
    your_phone_number: "你的聯絡電話",
    https___linkedin_com_in_your_name: "https://linkedin.com/in/your-name",
    cover_letter: "求職訊息",
    write_cover_letter_here_: "寫下想對徵才企業說的話",
    applySuccessfullyTitle: "應徵已成功送出！",
    applySuccessfullyDescription: "您可以在 {{my_applications}} 查看您的應徵狀態。",
    applySuccessfullyOnboardRewardReminding: "若您順利就職這份工作，別忘了到 Meet.jobs 回報就職，我們將提供 <span class='reward'>100 USD</span> 的就職祝賀金。",
    also_remember_to_confirm_your_employment_when_hired_you_will_receive_100_usd_reward_form_meet_jobs_: "若您順利就職這份工作，別忘了到 Meet.jobs 回報就職，我們將提供 100 USD 的就職祝賀金。",
    no_resume___cv_file_is_attached_do_you_want_to_apply_without_an_attachment_: "你並未上傳任何個人履歷檔案，確認要不附加履歷檔案直接應徵？",
}
