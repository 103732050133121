import React from "react";
import ReactTable from "react-table";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import User from "../../../models/user.js";
import {
	get_value_from_query_string,
	page_smothly_scroll_to,
} from "../../../../assets/js/functions";
import Pagination from "../../general/Pagination";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import JobApplication from "../../../models/job_application";
import InternalUri from "../../../models/internal_uri";
import Invitation from "../../../models/invitation";
import icon_message_b from "../../../../assets/images/icon/icon_24_message_B100.svg";
import icon_message_w from "../../../../assets/images/icon/icon_24_message_white.svg";

const config = new Config();
const headers = new ApiHeaders();

class MyReferralsPage extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.handleAddReferenceNotesModal = this.handleAddReferenceNotesModal.bind(
			this,
		);
		this.handleReadReferenceNotesModal = this.handleReadReferenceNotesModal.bind(
			this,
		);
		this.handleClaimReferralRewardModal = this.handleClaimReferralRewardModal.bind(
			this,
		);
		this.handleMyReferralLinkModal = this.handleMyReferralLinkModal.bind(this);
		this.handleCandidateMessageModal = this.handleCandidateMessageModal.bind(
			this,
		);
		this.handleExpandedChange = this.handleExpandedChange.bind(this);
		this.setCurrentPage = this.setCurrentPage.bind(this);
		this.state = {
			active_user_id: -1,
			metas: {},
			data: {},
			active_tab_index: 0,
			expanded_index: -1,
			loading: true,
			error_messages: {},
		};
		this.paginator = {};
		this.types = ["ongoing-referrals"];
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		// TODO: 如果 AddReferenceNotesModal 關閉後的寫法有更換，這邊也要做調整！
		let refresh = get_value_from_query_string(
			"refresh",
			next_props.location.search,
		);
		if (refresh === "true") {
			const internal_uri = new InternalUri();
			this.props.history.replace(
				internal_uri.formatted_userpanel_my_referrals_page_path(),
			);
			this.fetchReferrals(this.paginator["referrals"].current_page, true);
		}
	}

	componentDidMount() {
		this._isMounted = true;
		page_smothly_scroll_to(0, 300);

		this.setState(
			(prev_state) => ({
				...prev_state,
				loading: true,
				active_user_id: this.props.reduxTokenAuth.currentUser.attributes.id,
			}),
			() => this.fetchData(),
		);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchData() {
		this.setState({ loading: true });

		const { active_user_id } = this.state;
		let options = {
			method: "GET",
			url: config.api_url(`/users/${active_user_id}/referrals`),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let referrals = [],
			invitations = [],
			appliers = [];
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					const raw_appliers = response.data.collection;
					this.paginator["referrals"] = response.data.paginator;

					let promises_appliers = [];
					for (let i = 0; i < raw_appliers.length; i++) {
						options = {
							method: "GET",
							url: config.api_url(
								`/users/${active_user_id}/referrals/job_applications?applier_id=${raw_appliers[i].user_id}&applier_name=${raw_appliers[i].applier_name}`,
							),
							headers: headers.getItemsFromLocalStorage(),
							json: true,
						};
						promises_appliers.push(axios(options));

						const applier = new User({
							name: raw_appliers[i].applier_name,
							id: raw_appliers[i].user_id,
							avatar: { ...raw_appliers[i].avatar },
						});
						appliers.push(applier);

						referrals[i] = {
							applier: JSON.stringify(applier),
							application: raw_appliers[i].count,
						};
					}

					return axios.all(promises_appliers);
				}
			})
			.then((results) => {
				for (let i = 0; i < results.length; i++) {
					headers.updateItemsToLocalStorage(results[i].headers);
				}

				if (this._isMounted) {
					for (let i = 0; i < results.length; i++) {
						const raw_applications = results[i].data.collection;
						let applications = [];
						if (raw_applications.length > 0) {
							for (let j = 0; j < raw_applications.length; j++) {
								const application = new JobApplication({
									...raw_applications[j],
									applier: { ...appliers[i] },
									referrer_note:
										raw_applications[j].referrer_notes.length > 0
											? { ...raw_applications[j].referrer_notes[0] }
											: null,
								});

								applications[j] = {
									job_title: JSON.stringify(application),
									status: JSON.stringify(application),
								};
							}
							referrals[i].applications = applications;
						}
					}

					options = {
						method: "GET",
						url: config.api_url(
							`/users/${active_user_id}/referrals/jobs/talent_invitations?page=1&criteria[0][name]=updated_at&criteria[0][sort_by]=desc`,
						),
						headers: headers.getItemsFromLocalStorage(),
						json: true,
					};
					return axios(options);
				}
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				if (this._isMounted) {
					const raw_invitations = response.data.collection;
					this.paginator["invitations"] = response.data.paginator;

					for (let i = 0; i < raw_invitations.length; i++) {
						const invitation = new Invitation({
							...raw_invitations[i],
							talent: {
								email: raw_invitations[i].talent_email,
								first_name: raw_invitations[i].talent_first_name,
								last_name: raw_invitations[i].talent_last_name,
							},
						});
						invitations.push({
							talent: JSON.stringify(invitation.talent),
							job_title: JSON.stringify(invitation.job),
							date: JSON.stringify(invitation),
							invitation: JSON.stringify(invitation),
						});
					}

					this.setState((prev_state) => ({
						...prev_state,
						data: {
							referrals,
							invitations,
						},
						expanded_index: -1,
						loading: false,
						error_messages: {},
					}));
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));

					page_smothly_scroll_to(0, 300);
				}
			});
	}

	fetchReferrals(page, changing_page = false) {
		if (changing_page) page_smothly_scroll_to(0, 300);
		this.setState({ loading: true });

		const { active_user_id } = this.state;
		let options = {
			method: "GET",
			url: config.api_url(`/users/${active_user_id}/referrals?page=${page}`),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let referrals = [];
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					const raw_appliers = response.data.collection;
					this.paginator["referrals"] = response.data.paginator;

					let promises_appliers = [];
					for (let i = 0; i < raw_appliers.length; i++) {
						options = {
							method: "GET",
							url: config.api_url(
								`/users/${active_user_id}/referrals/job_applications?applier_id=${raw_appliers[i].user_id}&applier_name=${raw_appliers[i].applier_name}`,
							),
							headers: headers.getItemsFromLocalStorage(),
							json: true,
						};
						promises_appliers.push(axios(options));

						const applier = new User({
							name: raw_appliers[i].applier_name,
							id: raw_appliers[i].user_id,
							avatar: { ...raw_appliers[i].avatar },
						});

						referrals[i] = {
							applier: JSON.stringify(applier),
							application: raw_appliers[i].count,
						};
					}

					return axios.all(promises_appliers);
				}
			})
			.then((results) => {
				for (let i = 0; i < results.length; i++) {
					headers.updateItemsToLocalStorage(results[i].headers);
				}

				if (this._isMounted) {
					for (let i = 0; i < results.length; i++) {
						const raw_applications = results[i].data.collection;
						let applications = [];
						if (raw_applications.length > 0) {
							for (let j = 0; j < raw_applications.length; j++) {
								const application = new JobApplication({
									...raw_applications[j],
									referrer_note:
										raw_applications[j].referrer_notes.length > 0
											? { ...raw_applications[j].referrer_notes[0] }
											: null,
								});

								applications[j] = {
									job_title: JSON.stringify(application),
									status: JSON.stringify(application),
								};
							}
							referrals[i].applications = applications;
						}
					}

					this.setState((prev_state) => ({
						...prev_state,
						data: {
							...prev_state.data,
							referrals,
						},
						// expanded_index: (changing_page) ? -1 : prev_state.expanded_index,
						expanded_index: changing_page ? 0 : prev_state.expanded_index,
						loading: false,
						error_messages: {},
					}));
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));

					page_smothly_scroll_to(0, 300);
				}
			});
	}

	fetchInvitations(page) {
		page_smothly_scroll_to(0, 300);
		this.setState({ loading: true });

		const { active_user_id } = this.state;
		let options = {
			method: "GET",
			url: config.api_url(
				`/users/${active_user_id}/referrals/jobs/talent_invitations?page=${page}&criteria[0][name]=updated_at&criteria[0][sort_by]=desc`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let invitations = [];
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				if (this._isMounted) {
					const raw_invitations = response.data.collection;
					this.paginator["invitations"] = response.data.paginator;

					for (let i = 0; i < raw_invitations.length; i++) {
						const invitation = new Invitation({
							...raw_invitations[i],
							talent: {
								email: raw_invitations[i].talent_email,
								first_name: raw_invitations[i].talent_first_name,
								last_name: raw_invitations[i].talent_last_name,
							},
						});
						invitations.push({
							talent: JSON.stringify(invitation.talent),
							job_title: JSON.stringify(invitation.job),
							date: JSON.stringify(invitation),
							invitation: JSON.stringify(invitation),
						});
					}

					this.setState((prev_state) => ({
						...prev_state,
						data: {
							...prev_state.data,
							invitations,
						},
						loading: false,
						error_messages: {},
					}));
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));

					page_smothly_scroll_to(0, 300);
				}
			});
	}

	handleAddReferenceNotesModal = (e, job, application) => {
		e.preventDefault();
		this.props.updateAddReferenceNotesModal(true, job, application);
	};

	handleReadReferenceNotesModal = (e, job, application) => {
		e.preventDefault();
		this.props.updateReadReferenceNotesModal(true, job, application);
	};

	handleClaimReferralRewardModal = (e, application) => {
		e.preventDefault();
		this.props.updateClaimReferralRewardModal(true, application);
	};

	handleMyReferralLinkModal = (e) => {
		e.preventDefault();
		this.props.updateMyReferralLinkModal(true);
	};

	handleExpandedChange(index) {
		const { expanded_index } = this.state;
		let new_expanded_index = -1;
		if (expanded_index !== index) new_expanded_index = index;
		this.setState({
			expanded_index: new_expanded_index,
		});
	}

	handleCandidateMessageModal(e, job_id, application_id) {
		e.preventDefault();
		console.log(job_id, application_id, this.props.updateReferralMessageModal);
		this.props.updateReferralMessageModal(true, job_id, application_id);
	}

	setCurrentPage(property_name, current_page) {
		if (property_name === "referrals") {
			// console.log('referrals',current_page)
			this.fetchReferrals(current_page, true);
		}
		if (property_name === "invitations") {
			this.fetchInvitations(current_page);
			// console.log(current_page)
		}
	}

	render() {
		const { data, loading, error_messages, expanded_index } = this.state;
		const {
			handleCandidateMessageModal,
			handleAddReferenceNotesModal,
			handleReadReferenceNotesModal,
			handleClaimReferralRewardModal,
			handleMyReferralLinkModal,
			handleExpandedChange,
			setCurrentPage,
		} = this;
		const { paginator } = this;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		const columns_my_referrals_pc = [
			{
				Header: () => <h5>{t("applier")}</h5>,
				accessor: "applier",
				Cell: (props) => {
					const applier = new User(JSON.parse(props.value));
					return (
						<div className="applier-wrapper">
							<div className="avatar">
								<div
									className="image-wrapper"
									style={{
										backgroundImage: `url(${applier.formatted_avatar_url()})`,
									}}
								/>
							</div>
							<h5 className="name">{applier.formatted_name()}</h5>
						</div>
					);
				},
			},
			{
				Header: () => <h5>{t("applications")}</h5>,
				accessor: "application",
				Cell: (props) => (
					<div className="application-wrapper">
						<h5 className="application-number"></h5>
					</div>
				),
			},
		];

		const columns_my_referrals_mb = [
			{
				Header: () => <h5>{t("applier")}</h5>,
				accessor: "applier",
				Cell: (props) => {
					const applier = new User(JSON.parse(props.value));
					return (
						<div className="applier-wrapper">
							<div className="avatar">
								<div
									className="image-wrapper"
									style={{
										backgroundImage: `url(${applier.formatted_avatar_url()})`,
									}}
								/>
							</div>
							<h5 className="name">{applier.formatted_name()}</h5>
						</div>
					);
				},
			},
		];

		const columns_my_referrals_applications_pc = [
			{
				Header: () => <h5>{t("job_title")}</h5>,
				accessor: "job_title",
				Cell: (props) => {
					const application = new JobApplication(JSON.parse(props.value));
					return (
						<div>
							<Link
								to={internal_uri.formatted_frontend_job_page_path(
									application.job.id,
									application.job.slug,
								)}>
								<h5 className="job-title">
									{application.job.formatted_title()}
								</h5>
							</Link>
							<Link
								to={internal_uri.formatted_frontend_employer_page_path(
									application.job.employer.id,
									application.job.employer.slug,
								)}>
								<h6 className="employer-name">
									{application.job.formatted_employer_name()}
								</h6>
							</Link>
						</div>
					);
				},
				width: 220,
			},
			{
				Header: () => <h5>{t("status")}</h5>,
				accessor: "status",
				Cell: (props) => {
					const application = new JobApplication(JSON.parse(props.value));
					console.log(application);
					if (!window.application) {
						window.application = [application];
					} else {
						window.application.push(application);
					}
					return (
						<div className="status-wrapper">
							<div className="left">
								<h6 className="status">{application.formatted_state(t)}</h6>
								<h6 className="time">
									{t("general:updated_at")} {application.formatted_updated_at()}
								</h6>
							</div>
							<div className="right">
								{application.job.has_employer() ? (
									<button
										style={{ width: "48px", height: "auto" }}
										onClick={(e) =>
											handleCandidateMessageModal(
												e,
												application.job.id,
												application.id,
											)
										}
										className={`btn btn-smaller btn-flat ${
											application.has_unread_messages()
												? "btn-fill"
												: "btn-ghost"
										}`}>
										<h5>
											<img
												src={
													application.has_unread_messages()
														? icon_message_w
														: icon_message_b
												}
												alt=""
											/>
											{application.messages_count}
											<span>{t("general:_messages")}</span>
										</h5>
									</button>
								) : (
									<button
										onClick={(e) =>
											handleCandidateMessageModal(
												e,
												application.job.id,
												application.id,
											)
										}
										className={`btn btn-smaller btn-flat ${
											application.has_unread_messages()
												? "btn-fill"
												: "btn-hollow"
										}`}>
										<h5 className="details">{t("general:details")}</h5>
									</button>
								)}
								{application.referrer_note ? (
									<button
										className="btn btn-smaller btn-flat btn-hollow"
										onClick={(e) =>
											handleReadReferenceNotesModal(e, application)
										}>
										<h5>{t("general:reference_letter")}</h5>
									</button>
								) : (
									<button
										className="btn btn-smaller btn-flat btn-fill"
										onClick={(e) =>
											handleAddReferenceNotesModal(
												e,
												application.job,
												application,
											)
										}>
										<h5>{t("general:reference_letter")}</h5>
									</button>
								)}
								{application.referrer_state_changeable() ? (
									<button
										className="btn btn-smaller btn-flat btn-fill"
										onClick={(e) =>
											handleClaimReferralRewardModal(e, application)
										}>
										<h5>{t("claim_rewards")}</h5>
									</button>
								) : (
									<button
										onClick={(e) => e.preventDefault()}
										className="btn btn-smaller btn-flat btn-fill disabled">
										<h5>{t("claim_rewards")}</h5>
									</button>
								)}
							</div>
						</div>
					);
				},
			},
		];

		const columns_my_referrals_applications_mb = [
			{
				Header: () => <h5>{t("job_title")}</h5>,
				accessor: "job_title",
				Cell: (props) => {
					const application = new JobApplication(JSON.parse(props.value));
					return (
						<div className="job-title-wrapper">
							<Link
								to={internal_uri.formatted_frontend_job_page_path(
									application.job.id,
									application.job.slug,
								)}>
								<h5 className="job-title">
									{application.job.formatted_title()}
								</h5>
							</Link>
							<Link
								to={internal_uri.formatted_frontend_employer_page_path(
									application.job.employer.id,
									application.job.employer.slug,
								)}>
								<h6 className="employer-name">
									{application.job.formatted_employer_name()}
								</h6>
							</Link>
							<h6 className="status">{application.formatted_state(t)}</h6>
							<h6 className="time">
								{t("general:updated_at")} {application.formatted_updated_at()}
							</h6>
						</div>
					);
				},
			},
			{
				Header: () => <h5>{t("reference_status")}</h5>,
				accessor: "status",
				Cell: (props) => {
					const application = new JobApplication(JSON.parse(props.value));
					return (
						<div className="reference-status-wrapper">
							<div className="reference-letter-wrapper">
								<h6>{t("general:reference_letter")}</h6>
								{application.referrer_note ? (
									<button
										className="btn btn-smaller btn-flat btn-hollow"
										onClick={(e) =>
											handleReadReferenceNotesModal(e, application)
										}>
										<h5>{t("general:read")}</h5>
									</button>
								) : (
									<button
										className="btn btn-smaller btn-flat btn-fill"
										onClick={(e) =>
											handleAddReferenceNotesModal(
												e,
												application.job,
												application,
											)
										}>
										<h5>{t("general:write")}</h5>
									</button>
								)}
							</div>
							<div className="referral-reward-wrapper">
								<h6>{t("general:referral_reward")}</h6>
								{application.referrer_state_changeable() ? (
									<button
										className="btn btn-smaller btn-flat btn-fill"
										onClick={(e) =>
											handleClaimReferralRewardModal(e, application)
										}>
										<h5>{t("general:claim")}</h5>
									</button>
								) : (
									<button
										onClick={(e) => e.preventDefault()}
										className="btn btn-smaller btn-flat btn-fill disabled">
										<h5>{t("general:claim")}</h5>
									</button>
								)}
							</div>
						</div>
					);
				},
				sortable: false,
				width: 140,
			},
		];

		return (
			<div className="userpanel-my-referrals">
				<div className="container-fluid">
					<div className="inner-wrapper">
						{error_messages.full_message ? (
							<h6 className="message error-message">
								{error_messages.full_message}
							</h6>
						) : (
							""
						)}
						<div className="title-with-buttons">
							<h2>{t("general:my_referrals")}</h2>
							<button
								className="btn btn-larger btn-flat btn-hollow"
								onClick={(e) => handleMyReferralLinkModal(e)}>
								<h5>{t("my_referral_link")}</h5>
							</button>
						</div>
						<div>
							<div className="ongoing-referrals-wrapper">
								<div className="table-wrapper-pc table-wrapper block hidden-xs">
									<ReactTable
										loading={loading}
										data={data["referrals"]}
										columns={columns_my_referrals_pc}
										defaultPageSize={20}
										minRows={0}
										showPagination={false}
										resizable={false}
										noDataText={t("general:your_list_is_empty_")}
										SubComponent={(row) => {
											return (
												<div className="sub-component-wrapper">
													{/*<div className="notice-message-reward">*/}
													{/*<h5>*/}
													{/*<Interpolate*/}
													{/*i18nKey="when_candidates_get_hired_you_can_claim___referral_reward_"*/}
													{/*useDangerouslySetInnerHTML={true}*/}
													{/*reward={<span className="reward">{row.original.reward}</span>}*/}
													{/*/>*/}
													{/*</h5>*/}
													{/*</div>*/}
													<ReactTable
														data={row.original.applications}
														columns={columns_my_referrals_applications_pc}
														minRows={0}
														showPagination={false}
														className="sub-table"
														noDataText={t("general:your_list_is_empty_")}
													/>
												</div>
											);
										}}
										expanded={
											expanded_index !== -1 ? { [expanded_index]: true } : {}
										}
										onExpandedChange={(newExpanded, index, event) => {
											handleExpandedChange(index[0]);
										}}
									/>
								</div>
								<div className="table-wrapper-mb table-wrapper block">
									<ReactTable
										loading={loading}
										data={data["referrals"]}
										columns={columns_my_referrals_mb}
										defaultPageSize={20}
										minRows={0}
										showPagination={false}
										resizable={false}
										noDataText={t("general:your_list_is_empty_")}
										SubComponent={(row) => {
											return (
												<div>
													{/*<div className="notice-message-reward">*/}
													{/*<h5>*/}
													{/*<Interpolate*/}
													{/*i18nKey="when_candidates_get_hired_you_can_claim___referral_reward_"*/}
													{/*useDangerouslySetInnerHTML={true}*/}
													{/*reward={<span className="reward">{row.original.reward}</span>}*/}
													{/*/>*/}
													{/*</h5>*/}
													{/*</div>*/}
													<ReactTable
														data={row.original.applications}
														columns={columns_my_referrals_applications_mb}
														minRows={0}
														showPagination={false}
														className="sub-table"
														noDataText={t("general:your_list_is_empty_")}
													/>
												</div>
											);
										}}
										expanded={
											expanded_index !== -1 ? { [expanded_index]: true } : {}
										}
										onExpandedChange={(newExpanded, index, event) => {
											handleExpandedChange(index[0]);
										}}
									/>
								</div>
								{paginator["referrals"] &&
								paginator["referrals"].total_entries ? (
									<div>
										<div className="pagination-wrapper-pc hidden-xs hidden-sm">
											<Pagination
												currentPage={paginator["referrals"].current_page}
												pagesCount={paginator["referrals"].total_pages}
												pagedCount={5}
												previous_page={paginator["referrals"].previous_page}
												next_page={paginator["referrals"].next_page}
												setCurrentPage={(page) =>
													setCurrentPage("referrals", page)
												}
											/>
										</div>
										<div className="pagination-wrapper-mb">
											<Pagination
												currentPage={paginator["referrals"].current_page}
												pagesCount={paginator["referrals"].total_pages}
												pagedCount={4}
												previous_page={paginator["referrals"].previous_page}
												next_page={paginator["referrals"].next_page}
												setCurrentPage={(page) =>
													setCurrentPage("referrals", page)
												}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withNamespaces(["userpanel_my_referrals", "general", "states"])(
	MyReferralsPage,
);
