const res = [
    {
        name: 'upload_resume',
        path: 'formatted_userpanel_my_resume_page_path',
        category: {
            header: '016_upload_resume',
            topBar: '505_sub_nav_upload_resume'
        }
    },
    {
        name: 'my_applications',
        path: 'formatted_userpanel_my_applications_page_path',
        category: {
            header: '013_my_applications',
            topBar: '502_sub_nav_my_applications'
        }
    },
    {
        name: 'my_referrals',
        path: 'formatted_userpanel_my_referrals_page_path',
        category: {
            header: '015_my_referrals',
            topBar: '504_sub_nav_my_referrals'
        }
    },
    {
        name: 'saved_jobs',
        path: 'formatted_userpanel_saved_jobs_page_path',
        category: {
            header: '014_saved_jobs',
            topBar: '503_sub_nav_saved_jobs'
        }
    },
    {
        name: 'account_setting',
        path: 'formatted_userpanel_index_page_path',
        category: {
            header: '012_account_setting',
            topBar: '501_sub_nav_account_setting'
        }
    },
]

export default res


