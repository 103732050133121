import React from 'react';
import ThirdPartScript from "../../assets/thirdPartScript";

const loadScript = scriptID => ChildComponent => {

    const thirdPartScript = new ThirdPartScript();
    if(!thirdPartScript.isExist(scriptID)) return () => null;

    class ComposedComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                scriptReady: false
            };
        };

        componentDidMount() {
            const existingScript = document.getElementById(scriptID);

            if(!existingScript) {
                const thirdPartScript = new ThirdPartScript();

                const script = document.createElement('script');
                script.id = scriptID;
                Object.keys(thirdPartScript.getDetail(scriptID)).forEach(key => {
                    script.setAttribute(key, thirdPartScript.getDetail(scriptID)[key]);
                });
                document.body.appendChild(script);

                script.onload = () => {
                    this.setState({ scriptReady: true });
                };
            }

            if(existingScript) {
                this.setState({ scriptReady: true });
            }
        };

        render() {
            if(!this.state.scriptReady) return null;
            return <ChildComponent {...this.props} />;
        }
    }

    return ComposedComponent;
};

export default loadScript;
