export default {
    required: "Required",
    textLimitCharacters: "Length of text limit: {{number}} characters",
    // length_of_text_limit__180_characters: "Length of text limit: 180 characters",
    // length_of_text_limit__6000_characters: "Length of text limit: 6000 characters",
    uploadLimitSize: "Upload size limit: {{size}}",
    // upload_size_limit__30MB: "Upload size limit: 30MB",
    invalidFormat: "Invalid {{type}} format",
    // invalid_email_format: "Invalid Email format",
    // invalid_slug_format: "Invalid Slug format",

    // end_time_must_be_greater_than_start_time: "End time must be greater than start time",
    // the_passwords_you_entered_do_not_match: "The passwords you entered do not match",
    // password_contains_at_least_8_characters_is_required: "Password contains at least 8 characters is required",
    mustBeNumber: "Must be a number",
    // must_be_a_number: "Must be a number",
    maximumSalaryMustBeGreater: "Must be greater than or equal to minimum salary",
    // must_be_greater_than_or_equal_to__: "Must be greater than or equal to {{minimum_salary}}",
    selectMaximumLimit: "Select up to {{number}} options only",
};