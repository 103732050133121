import React from "react";
import { withNamespaces, Interpolate } from "react-i18next";
import icon_fb from "../../../assets/images/icon/icon_28_facebook.svg";
import {
	page_smothly_scroll_to,
	smoothly_scroll_to,
	get_value_from_query_string,
} from "../../../assets/js/functions.js";
import { isEmail } from "../../../assets/js/validators.js";
import Config from "../../models/config.js";
import InternalUri from "../../models/internal_uri.js";
import i18n from "../../../i18n.js";
import axios from "axios";
import ApiHeaders from "../../models/api_headers";

const config = new Config();
const headers = new ApiHeaders();

class SignUpForm extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.goPage = this.goPage.bind(this);
		this.submit = this.submit.bind(this);
		this.state = {
			email: "",
			password: "",
			fbAuthLink: "",
			emailAuthData: {},
			error_messages: {},
			sending: false,
		};
		this.once_sent_out = false;
		this.talentOriginalEmail = null;
	}

	componentDidMount() {
		// console.log("SignUpForm.componentDidMount", this.props);
		this._isMounted = true;

		const { reduxTokenAuth } = this.props;
		if (reduxTokenAuth.currentUser.isSignedIn) {
			const internal_uri = new InternalUri();
			this.props.history.push(
				internal_uri.formatted_frontend_index_page_path(),
			);
			this.props.updateEntranceFormModal("");
		}

		// set up sign in data
		let fbAuthLink = "",
			emailAuthData = {};
		emailAuthData["confirm_success_url"] = "";
		emailAuthData["locale"] = i18n.language;

		// set up utm info
		const { utm } = this.props;
		let utm_parameters = "";
		if (utm.source || utm.medium || utm.campaign) {
			utm_parameters += `&utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`;
			emailAuthData["utm_source"] = utm.source;
			emailAuthData["utm_medium"] = utm.medium;
			emailAuthData["utm_campaign"] = utm.campaign;
		}

		// get info from url query
		const internal_uri = new InternalUri();
		const query = decodeURI(this.props.location.search);
		const pathname = decodeURI(this.props.location.pathname);
		let token, job_id, referrer_id, invitation_id;
		if (query) {
			token = get_value_from_query_string("token", query);
			job_id = get_value_from_query_string("job_id", query);
			referrer_id = get_value_from_query_string("referrer_id", query);
			this.talentOriginalEmail = get_value_from_query_string(
				"talent_original_email",
				query,
			);
			const pathname_array = pathname.split("/");
			invitation_id = pathname_array[pathname_array.length - 1];
		}

		if (token && job_id && invitation_id) {
			// sign up with talent invitation info
			const auth_origin_url = encodeURIComponent(
				`callback-handler?callback_key=assign_redirect_url&callback_resource=jobs/talent_invitation&callback_params[id]=${invitation_id}&callback_params[job_id]=${job_id}&callback_params[token]=${token}`,
			);
			fbAuthLink = config.api_url(
				`/auth/facebook?auth_origin_url=${auth_origin_url}${utm_parameters}&referrer_id=${referrer_id}`,
			);
			emailAuthData["confirm_success_url"] = auth_origin_url;
			emailAuthData["referrer_id"] = referrer_id;
		} else {
			const { location } = this.props;
			const auth_origin_url = encodeURIComponent(
				`${
					window.location.origin
				}${internal_uri.formatted_frontend_redirect_from_facebook_page_path()}?redirect_pathname=${encodeURIComponent(
					`${location.pathname}${location.search}`,
				)}`,
			);

			const { referral_codes } = this.props;
			if (referral_codes.length) {
				// sign up with the latest referral code
				fbAuthLink = config.api_url(
					`/auth/facebook?auth_origin_url=${auth_origin_url}${utm_parameters}&referral_code=${
						referral_codes[referral_codes.length - 1]
					}`,
				);
				emailAuthData["referral_code"] =
					referral_codes[referral_codes.length - 1];
			} else {
				// normal sign up
				fbAuthLink = config.api_url(
					`/auth/facebook?auth_origin_url=${auth_origin_url}${utm_parameters}`,
				);
			}
		}

		this.setState({
			fbAuthLink,
			emailAuthData,
			email: this.talentOriginalEmail || "",
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleInputChange = (e, property_name) => {
		e.preventDefault();
		let value = e.target.value;

		// if(property_name==='email') value = value.toLowerCase();

		this.setState((prev_state) => {
			// check form error
			let error_messages = { ...prev_state.error_messages };
			const { t } = this.props;
			switch (property_name) {
				case "email":
					let email = [];
					if (this.once_sent_out) {
						if (!isEmail(value))
							email.push(`${t("general:invalid_email_format")}`);
					}
					error_messages = {
						...error_messages,
						email,
					};
					break;
				case "password":
					let password = [];
					if (this.once_sent_out) {
						if (value.length < 8)
							password.push(
								`${t(
									"general:password_contains_at_least_8_characters_is_required",
								)}`,
							);
					}
					error_messages = {
						...error_messages,
						password,
					};
					break;
				default:
					error_messages = {
						...error_messages,
						[property_name]: [],
					};
					break;
			}

			return {
				...prev_state,
				[property_name]: value,
				error_messages,
			};
		});
	};

	goPage = (e, pathname) => {
		e.preventDefault();
		this.props.history.push(pathname);
		this.props.updateEntranceFormModal("");
	};

	submit = (e) => {
		e.preventDefault();
		const { email, password, sending } = this.state;
		// console.log(this.state);
		// return;
		let { error_messages } = this.state;

		// for 第一次送出前的檢查
		let has_errors = false;
		if (!this.once_sent_out) {
			const { t } = this.props;
			let email_errors = [],
				password_errors = [];
			// email
			if (!isEmail(email)) {
				email_errors.push(`${t("general:invalid_email_format")}`);
				has_errors = true;
			}

			// password
			if (password.length < 8) {
				password_errors.push(
					`${t("general:password_contains_at_least_8_characters_is_required")}`,
				);
				has_errors = true;
			}

			error_messages = {
				...error_messages,
				email: email_errors,
				password: password_errors,
			};

			this.once_sent_out = true;
		}

		if (has_errors) {
			this.setState({ error_messages });
			smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
		} else if (!sending) {
			this.setState({ sending: true });

			let options = {
				method: "POST",
				url: config.api_url(`/auth`),
				data: {
					...this.state.emailAuthData,
					email,
					password,
					password_confirmation: password,
				},
				json: true,
			};
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					if (this._isMounted) {
						this.setState(
							{
								sending: false,
								error_messages: {},
							},
							() => {
								if (this.state.email === this.talentOriginalEmail) {
									window.location.reload();
									this.props.updateEntranceFormModal("");
								} else {
									const internal_uri = new InternalUri();
									this.props.history.push(
										`${internal_uri.formatted_frontend_confirm_page_path()}?email=${
											this.state.email
										}`,
									);
									this.props.updateEntranceFormModal("");
								}
							},
						);
					}
				})
				.catch((error) => {
					if (this._isMounted) {
						if (typeof error.response === "undefined")
							this.props.updateConnectionOffModal(true);

						this.setState(
							(prev_state) => ({
								...prev_state,
								sending: false,
								error_messages: {
									full_message:
										error.response &&
										error.response.data &&
										error.response.data.status === "error"
											? error.response.data.errors.full_message
											: "There was an error. Please try again later.",
								},
							}),
							() => {
								if (
									error.response &&
									error.response.data &&
									error.response.data.data
								) {
									if (
										typeof error.response.data.data.confirmed !== "undefined" &&
										!error.response.data.data.confirmed
									) {
										setTimeout(() => {
											const internal_uri = new InternalUri();
											this.props.history.push(
												`${internal_uri.formatted_frontend_confirm_page_path()}?email=${
													this.state.email
												}`,
											);
											this.props.updateEntranceFormModal("");
										}, 1500);
									}
								}

								if (this.props.site_state.entrance_form_modal === "sign-up") {
									smoothly_scroll_to(
										document.querySelector(".modal-wrapper"),
										0,
										200,
									);
								} else {
									page_smothly_scroll_to(0, 300);
								}
							},
						);
					}
				});
		}
	};

	render() {
		const { email, password, fbAuthLink, error_messages, sending } = this.state;
		const { site_state } = this.props;
		const { submit, handleInputChange, goPage } = this;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		let has_errors = false;
		const white_list = ["full_message"];
		for (let key in error_messages) {
			if (white_list.indexOf(key) === -1 && error_messages[key].length)
				has_errors = true;
		}

		return (
			<form>
				{error_messages.full_message ? (
					<div className="form-row">
						<h6 className="message error-message">
							{error_messages.full_message}
						</h6>
					</div>
				) : (
					""
				)}
				<div className="form-row form-row-2 form-row-dire-col">
					<div className="form-col">
						<input
							className={`input ${
								error_messages.email && error_messages.email.length
									? "input-danger"
									: ""
							}`}
							type="email"
							value={email}
							onChange={(e) => handleInputChange(e, "email")}
							placeholder={t("email_address")}
							autoComplete="username"
						/>
					</div>
					<div className="form-col">
						{error_messages.email && error_messages.email.length ? (
							<h6 className="message error-message">
								{error_messages.email.join(", ")}
							</h6>
						) : (
							""
						)}
					</div>
				</div>
				<div className="form-row form-row-2 form-row-dire-col">
					<div className="form-col">
						<input
							className={`input ${
								error_messages.password && error_messages.password.length
									? "input-danger"
									: ""
							}`}
							type="password"
							value={password}
							onChange={(e) => handleInputChange(e, "password")}
							placeholder={t("password")}
							autoComplete="current-password"
						/>
					</div>
					<div className="form-col">
						{error_messages.password && error_messages.password.length ? (
							<h6 className="message error-message">
								{error_messages.password.join(", ")}
							</h6>
						) : (
							""
						)}
					</div>
				</div>
				<div className="form-row">
					{sending || has_errors ? (
						<button
							onClick={(e) => e.preventDefault()}
							className="btn btn-larger btn-flat btn-fill disabled">
							<h5>{t("general:sign_up_with_email")}</h5>
						</button>
					) : (
						<button
							className="btn btn-larger btn-flat btn-fill"
							type="submit"
							value="Submit"
							onClick={(e) => submit(e)}
							data-event-category={
								site_state.entrance_form_modal === "sign-up"
									? "034_modal_sign_up"
									: "040_sign_up_fullpage"
							}>
							<h5>{t("general:sign_up_with_email")}</h5>
						</button>
					)}
				</div>
				<div className="separator">
					<span>{t("general:or")}</span>
				</div>
				<a
					className="btn btn-larger btn-flat btn-fill btn-facebook"
					href={fbAuthLink}
					data-event-category="038_sign_up_facebook">
					<img src={icon_fb} alt="" />
					<h6>{t("general:sign_up_with_facebook")}</h6>
				</a>
				<h6 className="description">
					<Interpolate
						i18nKey="by_continuing__you_agree_to_our___and_acknowledge_our___"
						useDangerouslySetInnerHTML={true}
						terms_of_use={
							<a
								href={`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=user-agreement`}
								onClick={(e) =>
									goPage(
										e,
										`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=user-agreement`,
									)
								}
								className="link">
								{t("general:user_agreement")}
							</a>
						}
						privacy_policy={
							<a
								href={`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=privacy-policy`}
								onClick={(e) =>
									goPage(
										e,
										`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=privacy-policy`,
									)
								}
								className="link">
								{t("general:privacy_policy")}
							</a>
						}
					/>
				</h6>
			</form>
		);
	}
}

export default withNamespaces(["frontend_authentication", "general"])(
	SignUpForm,
);
