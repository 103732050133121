import React from 'react';
import { withNamespaces } from "react-i18next";
import Config from "../../../models/config.js";
import { withRouter } from "react-router-dom";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import {get_value_from_query_string, contain_not_only_whitespace, page_smothly_scroll_to} from "../../../../assets/js/functions";
import icon_ok from "../../../../assets/images/icon/icon_28_ok_B100.svg";
import InternalUri from "../../../models/internal_uri";

const config = new Config();
const headers = new ApiHeaders();

class ConfirmPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleQuery = this.handleQuery.bind(this);
        this.sendConfirmationEmail = this.sendConfirmationEmail.bind(this);
        this.state = {
            email: '',
            sending: false,
            error_messages: {},
            sent: false,
        };
    };

    componentDidMount() {
        this._isMounted = true;

        if(this.props.reduxTokenAuth.currentUser.isSignedIn) {
            const internal_uri = new InternalUri();
            this.props.history.push(internal_uri.formatted_frontend_index_page_path());
        }

        this.handleQuery(this.props);
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(this.props.reduxTokenAuth.currentUser.isSignedIn !== next_props.reduxTokenAuth.currentUser.isSignedIn) {
            if(next_props.reduxTokenAuth.currentUser.isSignedIn) {
                const internal_uri = new InternalUri();
                this.props.history.push(internal_uri.formatted_frontend_index_page_path());
            }
        }

        if(this.props.location.search !== next_props.location.search) {
            this.handleQuery(next_props);
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    handleQuery(props) {
        const query = props.location.search;
        if(query) {
            let email = get_value_from_query_string('email', query);
            if(email) {
                this.setState((prev_state) => ({
                    ...prev_state,
                    email: email
                }));
            }
        }
    };

    sendConfirmationEmail = e => {
        e.preventDefault();
        const { email, sending } = this.state;

        if(contain_not_only_whitespace(email) && !sending) {
            this.setState({ sending: true });
            let options = {
                method: 'POST',
                url: config.api_url('/auth/resend_confirmation_email'),
                headers: headers.getItemsFromLocalStorage(),
                data: {
                    email,
                    confirm_success_url: ""
                },
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    if(this._isMounted) {
                        this.setState({
                            sending: false,
                            error_messages: {},
                            sent: true,
                        });
                    }
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    if(this._isMounted) {
                        if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                        this.setState((prev_state) => ({
                            ...prev_state,
                            sending: false,
                            error_messages:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: 'There was an error. Please try again later.'},
                        }));

                        page_smothly_scroll_to(0, 300);
                    }
                })
        }
    };

    render() {
        let { sending, sent, error_messages } = this.state;
        const { sendConfirmationEmail } = this;
        const { t } = this.props;

        return (
            <div className={`page-entrance content-wrapper white ${(sent) ? 'email-sent' : ''}`}>
                <div className="container">
                    <section className="page-title">
                        <h2>{t('you_re_so_close_')}</h2>
                        <p>{t('please_check_your_email_to_confirm_your_registration')}</p>
                        {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                    </section>
                    <section className="page-content">
                        <div className="entrance-wrapper-confirm">
                            <hr/>
                            <div className="text-sent-animation-wrapper">
                                <p className="description text">{t('didn_t_receive_confirmation_email_')}</p>
                                <p className="description text-sent">{t('we_have_sent_confirmation_email_to_you_again_')}</p>
                            </div>
                            <div className="email-sent-animation-wrapper">
                                <div className="resent-button-wrapper">
                                    {
                                        (!sending) ?
                                            <button
                                                className="btn btn-larger btn-flat btn-ghost btn-single"
                                                onClick={ e => sendConfirmationEmail(e) }
                                            >
                                                <h5>{t('send_me_again')}</h5>
                                            </button> :
                                            <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-ghost btn-single disabled">
                                                <h5>{t('send_me_again')}</h5>
                                            </button>
                                    }
                                </div>
                                <div className="btn-check-wrapper">
                                    <div className="btn-check"><img src={icon_ok} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default withRouter(withNamespaces(['frontend_confirm_page', 'general'])(ConfirmPage));
