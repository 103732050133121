import React from 'react';
import axios from 'axios';
import { JobCardWrapper } from '../../../containers/general.jsx';
import Job from "../../../models/job";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import JobCardLoader from "../../general/JobCardLoader";
import { withNamespaces } from "react-i18next";
import blank_saved_job from "../../../../assets/images/i025-blank_saved.svg";
import {Link} from "react-router-dom";
import Pagination from "../../general/Pagination";
import {page_smothly_scroll_to} from "../../../../assets/js/functions";
import InternalUri from "../../../models/internal_uri";

const config = new Config();
const headers = new ApiHeaders();

class SavedJobsPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.state = {
            jobs: [],
            error_messages: {},
            loading: true
        };
        this.paginator = {
            total_entries: 0,
            total_pages: 1,
            per_age: 1,
            current_page: 1,
            previous_page: null,
            next_page: null
        }
    };

    componentDidMount() {
        this._isMounted = true;
        const { reduxTokenAuth } = this.props;
        this.fetchData(reduxTokenAuth.currentUser.attributes.id, 1);
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    fetchData(user_id, page) {
        page_smothly_scroll_to(0, 300);
        this.setState({ loading: true });
        const url = config.api_url(`/users/${user_id}/saved_jobs?page=${page}`);
        const options = {
            method: 'GET',
            url: url,
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_jobs = response.data;
                    let jobs = [];
                    this.paginator = raw_jobs.paginator;
                    for(let i=0; i<raw_jobs.collection.length; i++) {
                        jobs.push(new Job(raw_jobs.collection[i]));
                    }
                    this.setState(() => ({
                        jobs,
                        error_messages: {},
                        loading: false,
                    }));
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                }
            });
    };

    setCurrentPage = (currentPage) => {
        this.fetchData(this.props.reduxTokenAuth.currentUser.attributes.id, currentPage);
    };

    render() {
        const { jobs, error_messages, loading } = this.state;
        const { paginator, setCurrentPage } = this;
        const { t } = this.props;
        const internal_uri = new InternalUri();

        return (
            <div className="userpanel-saved-jobs">
                <div className="container-fluid">
                    <div className="inner-wrapper">
                        <h2>{t('general:saved_jobs')}</h2>
                        {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                        {
                            (!loading) ?
                                <div>
                                    <div className="job-cards">
                                        {
                                            (jobs.length !== 0) ?
                                                jobs.map((job, i) =>
                                                    <JobCardWrapper
                                                        key={i}
                                                        job={job}
                                                    />
                                                ) :
                                                <div className="blank-saved-job-wrapper">
                                                    <div className="graph">
                                                        <div
                                                            className="image-wrapper"
                                                            style={{backgroundImage: `url(${blank_saved_job})`}}
                                                        />
                                                    </div>
                                                    <h2>{t('no_jobs_saved')}</h2>
                                                    <p>{t('click_the_bookmark_icon_to_save_jobs_and_track_then_here_')}</p>
                                                    <Link
                                                        to={internal_uri.formatted_frontend_jobs_page_path()}
                                                        className="btn btn-larger btn-flat btn-fill"
                                                    >
                                                        <h5>{t('view_jobs')}</h5>
                                                    </Link>
                                                </div>
                                        }
                                    </div>
                                    {
                                        (paginator.total_entries) ?
                                            <div>
                                                <div className="pagination-wrapper-pc hidden-xs hidden-sm">
                                                    <Pagination
                                                        currentPage={paginator.current_page}
                                                        pagesCount={paginator.total_pages}
                                                        pagedCount={5}
                                                        previous_page={paginator.previous_page}
                                                        next_page={paginator.next_page}
                                                        setCurrentPage={setCurrentPage}
                                                    />
                                                </div>
                                                <div className="pagination-wrapper-mb">
                                                    <Pagination
                                                        currentPage={paginator.current_page}
                                                        pagesCount={paginator.total_pages}
                                                        pagedCount={4}
                                                        previous_page={paginator.previous_page}
                                                        next_page={paginator.next_page}
                                                        setCurrentPage={setCurrentPage}
                                                    />
                                                </div>
                                            </div> : ''
                                    }
                                </div> :
                                <div className="loading-skeleton-wrapper">
                                    <div className="job-cards">
                                        <JobCardLoader />
                                        <JobCardLoader />
                                        <JobCardLoader />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}

export default withNamespaces(['userpanel_saved_jobs', 'general'])(SavedJobsPage);