export default {
    your_link_has_expired_please_send_the_request_again_: "Your link has expired. Please send the request again.",
    you_have_successfully_joined_the_employer_dashboard_of___: "You have successfully joined the employer dashboard of {{employer_name}}.",
    your_invitation_has_expired_please_ask_for_a_new_invitation_: "Your invitation has expired. Please ask for a new invitation.",
    you_have_left___: "You have left {{employer_name}}.",
    you_need_permission_to_browse_the_page_please_ask_for_access_or_switch_to_an_account_with_permission_: "You need permission to browse the page. Please ask for access or switch to an account with permission.",
    please_check_the_verification_link_with_your_new_email__: "Please check the verification link with your new email: {{email}}",
    you_are_now_logged_in_as__: "You are now logged in as: {{email}}",
    your_invitation_has_been_sent_you_can_find_it_in___: "The application for your friend has been submitted. You can find it in {{my_referrals}}.",
    you_have_rejected_this_invitation_on__: "You have rejected this invitation on {{- updated_at}}",
    you_have_accepted_this_invitation_and_applied_on__: "You have accepted this invitation and applied on {{- updated_at}}",
    this_invitation_is_not_available_because_the_job_is_closed_: "This invitation is not available because the job is closed.",
    link_copied_: "Link copied!",
    can_t_get_your_email_address_from_the_service_Please_try_to_sign_up_with_another_method_: "Can't get your email address from the service. Please try to sign up with another method.",
    all_published_jobs_have_been_updated_and_will_last_for_60_days_: "All published jobs have been updated and will last for 60 days.",
    job_post_published_successfully_: "Job post published successfully!",
};
