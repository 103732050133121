export default {
    welcome_back_: "歡迎再次來訪！",
    welcome_to_meet_jobs: "歡迎來到 Meet.jobs",
    don_t_have_an_account____now_: "沒有帳戶嗎？立即{{sign_up}}",
    already_have_an_account___: "已有帳戶？ {{log_in}}",
    forgot_password_: "忘記密碼？",
    by_continuing__you_agree_to_our___and_acknowledge_our___: "當您繼續註冊，即表示已了解並同意我們的{{terms_of_use}}以及{{privacy_policy}}.",
    email_address: "Email address",
    password: "Password",
    forgot_password: "忘記密碼",
    confirm_password: "重新輸入密碼",
    enter_your_registered_email: "請輸入您註冊時使用的 Email",
    password_reset_link_has_been_sent_to_your_mail_box_: "重新設定密碼的連結已經寄送到您的 Email 信箱",
    password_is_changed_: "密碼已更新！",
    please_log_in_with_your_new_password_next_time_: "請使用您的新密碼重新登入。",
    reset_password: "重設密碼",
    please_type_in_your_new_password: "請輸入您的新密碼",
};