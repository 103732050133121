import React from 'react';
import {withRouter} from "react-router-dom";
import loadScript from "../../hocs/loadScript";
import User from "../../models/user";

class TawkTo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.Tawk_API = {};
    }

    componentDidMount() {
        this.Tawk_API = window.Tawk_API||{};

        const { reduxTokenAuth } = this.props;
        if(reduxTokenAuth.currentUser.isSignedIn) {
            const user = new User(reduxTokenAuth.currentUser.attributes);
            Object.keys(this.Tawk_API).length && this.Tawk_API.setAttributes({
                'name': user.formatted_name(),
                'email': user.formatted_email(),
                // 'hash': 'hash-value'
            });
        }

        Object.keys(this.Tawk_API).length && this.Tawk_API.showWidget();
    };

    componentWillUnmount() {
        Object.keys(this.Tawk_API).length && this.Tawk_API.hideWidget();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(
            this.props.reduxTokenAuth.currentUser.isSignedIn !== nextProps.reduxTokenAuth.currentUser.isSignedIn
            && nextProps.reduxTokenAuth.currentUser.isSignedIn
        ) {
            const user = new User(nextProps.reduxTokenAuth.currentUser.attributes);

            Object.keys(this.Tawk_API).length && this.Tawk_API.setAttributes({
                'name': user.formatted_name(),
                'email': user.formatted_email(),
                // 'hash': 'hash-value'
            });
        }
    };

    render() {
        return null;
    }
}

export default loadScript('tawkTo')(withRouter(TawkTo));
