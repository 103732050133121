import React from "react";
import { Link } from "react-router-dom";
import InternalUri from "../../../../../models/internal_uri";
const internalUri = new InternalUri();
const RemindUpLoadResumeBanner = (props) => {
	console.log("remind upload resume banner", props);
	const { close } = props;
	return props.reduxTokenAuth.currentUser.isSignedIn &&
		!props.location.pathname.includes("my-resume") &&
		!props.reduxTokenAuth.currentUser.attributes.default_resume_attachment ? (
		<div
			style={{
				// position: "absolute",
				// top: "100%",
				backgroundColor: "#2768f6",
				width: "100%",
				padding: ".8% 3%",
				color: "white",
				fontWeight: "600",
				fontSize: ".8rem",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}>
			<div>upload resume for better experience!</div>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					alignContent: "center",
					gap: "10%",
					width: "10%",
				}}>
				<Link
					style={{
						flex: "1",
						display: "block",
						color: "white",
						whiteSpace: "nowrap",
						fontSize: ".8rem",
						padding: " 5%",
						borderRadius: ".5rem",
						borderColor: "white !important",
						backgroundColor: "white",
						color: "#2768f6",
						borderWidth: "1px !important",
						textAlign: "center",
					}}
					to={internalUri.formatted_userpanel_my_resume_page_path()}>
					<div>{props.t("general:upload_resume")}</div>
				</Link>
				<button
					onClick={() => {
						close();
					}}
					style={{ color: "white", fontSize: "1.5rem" }}>
					x
				</button>
			</div>
		</div>
	) : null;
};

export default RemindUpLoadResumeBanner;
