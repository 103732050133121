import React from "react";
import { Route, Switch } from "react-router-dom";
import "../../assets/css/main.css";
import {
	FrontendWrapper,
	UserpanelWrapper,
	DashboardWrapper,
	TalentsWrapper,
	LogOutModalWrapper,
	ConnectionOffModalWrapper,
	ExternalJobRedirectPageWrapper,
	FlashMessagesWrapper,
} from "./../containers/general.jsx";
import facebookEvents from "../models/facebook_events";
import axios from "axios";
import i18n from "../../i18n";
import {
	change_language_with_redirect,
	formatted_locale,
	get_value_from_query_string,
	page_smothly_scroll_to,
} from "../../assets/js/functions";
import { Helmet } from "react-helmet";
import { withNamespaces } from "react-i18next";
import User from "../models/user";
import Config from "../models/config.js";
import ApiHeaders from "../models/api_headers";
import MeetJobsLoader from "./general/MeetJobsLoader.jsx";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import InternalUri from "../models/internal_uri";

import { pdfjs } from "react-pdf";
import { gaEvent } from "../../assets/js/gtag";
import { TawkToWrapper } from "../containers/general";
import { sendDataLayer } from "../../assets/js/dataLayer";
pdfjs.GlobalWorkerOptions.workerSrc =
	"https://assets.meet.jobs/js_packages/pdf.worker.js";

if (typeof localStorage === "undefined" || localStorage === null) {
	let LocalStorage = require("node-localstorage").LocalStorage;
	global.localStorage = new LocalStorage("./scratch");
}

const config = new Config();
const headers = new ApiHeaders();

class App extends React.Component {
	constructor(props) {
		super(props);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
		this.handleLocationChange = this.handleLocationChange.bind(this);
		this.state = {
			validating: true,
		};

		const locale = formatted_locale(props.match.params.locale);
		if (props.match.params.locale !== locale) {
			// locale 端的語系校正機制
			const internal_uri = new InternalUri();
			props.history.replace(
				`${internal_uri.formatted_path_with_new_language(
					props.location.pathname,
					locale,
				)}${props.location.search}`,
			);
		} else {
			i18n.changeLanguage(locale);
		}
	}

	componentDidMount() {
		// console.log("App componentDidMount", this.props);
		ReactGA.initialize(config.gaCode());
		TagManager.initialize({ gtmId: config.gtmCode() });
		new facebookEvents({
			eventName: "PageView",
			userData: null,
		});

		document
			.querySelector(".App")
			.addEventListener("click", (e) => this.handleOnClick(e));

		const { history } = this.props;
		this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
		this.handleLocationChange(history.location);

		const query = decodeURI(this.props.location.search);
		if (query) {
			let utm_source = get_value_from_query_string("utm_source", query);
			let utm_medium = get_value_from_query_string("utm_medium", query);
			let utm_campaign = get_value_from_query_string("utm_campaign", query);
			if (
				utm_source &&
				(utm_source !== this.props.utm.source ||
					utm_medium !== this.props.utm.medium ||
					utm_campaign !== this.props.utm.campaign)
			) {
				this.props.updateUtm({
					source: utm_source,
					medium: utm_medium,
					campaign: utm_campaign,
				});
			}
		}

		let pathname = window.location.pathname;
		if (
			pathname.search("/redirect-from-sign-in-email") !== -1 ||
			pathname.search("/redirect-from-facebook") !== -1 ||
			pathname.search("/redirect-from-forgot-password-email") !== -1 ||
			pathname.search("/callback-handler") !== -1
		) {
			this.setState({ validating: false });
		} else {
			let userAttributes = {};
			let options = {
				method: "GET",
				url: config.api_url("/auth/validate_token"),
				headers: headers.getItemsFromLocalStorage(),
				json: true,
			};
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					userAttributes = response.data.data;
					this.props.verifyTokenRequestSucceeded(userAttributes);
					sendDataLayer("userId", userAttributes.id);

					const user_locale = formatted_locale(userAttributes.locale);
					change_language_with_redirect(user_locale, this.props.history);

					options = {
						method: "GET",
						url: config.api_url(
							`/users/${userAttributes.id}?include=employer_roles`,
						),
						headers: headers.getItemsFromLocalStorage(),
						json: true,
					};

					return axios(options);
				})
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);

					const user = new User(response.data);
					this.props.updateCurrentUserIsReferralUser(user.is_referrals_exist);

					let promises_employers = [],
						employer_roles = {};
					const raw_employer_roles = response.data.employer_roles;
					for (let i = 0; i < raw_employer_roles.length; i++) {
						options = {
							method: "GET",
							url: config.api_url(
								`/employers/${raw_employer_roles[i].employer_id}`,
							),
							headers: headers.getItemsFromLocalStorage(),
							json: true,
						};
						promises_employers.push(axios(options));
						employer_roles[raw_employer_roles[i].employer_id] =
							raw_employer_roles[i];
					}
					this.props.updateCurrentUserEmployerRoles(employer_roles);

					return axios.all(promises_employers);
				})
				.then((results) => {
					for (let i = 0; i < results.length; i++) {
						headers.updateItemsToLocalStorage(results[i].headers);
					}

					const { current_user } = this.props;
					let active_employer_id = current_user.active_company.id || 0;
					if (query) {
						let employer_id = parseInt(
							get_value_from_query_string("employer_id", query),
							10,
						);
						if (employer_id) active_employer_id = employer_id;
					}

					let companies = [],
						company;
					let active_company_index = 0;
					for (let i = 0; i < results.length; i++) {
						company = results[i].data;
						companies.push(company);
						if (active_employer_id === company.id) {
							active_company_index = i;
						}
					}

					if (companies.length > 0) {
						this.props.updateCurrentUserIsEmployerUser(true);
						this.props.updateCurrentUserCompanies([...companies]);
						this.props.updateCurrentUserActiveCompany({
							...companies[active_company_index],
						});
					} else {
						this.props.updateCurrentUserIsEmployerUser(false);
						this.props.updateCurrentUserCompanies([]);
						this.props.updateCurrentUserActiveCompany({});
					}

					this.setState({ validating: false });
				})
				.catch((error) => {
					if (typeof error.response === "undefined")
						this.props.updateConnectionOffModal(true);

					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401)
							this.props.verifyTokenRequestFailed();
					}

					this.setState({ validating: false });
				})
				.then(() => {
					options = {
						method: "GET",
						url: config.api_url(`/ads?locale=${i18n.language}`),
						headers: headers.getItemsFromLocalStorage(),
						json: true,
					};
					return axios(options);
				})
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateAds([...response.data]);
					options = {
						method: "GET",
						url: config.api_url(`/ads?position=cover`),
						headers: headers.getItemsFromLocalStorage(),
						json: true,
					};
					return axios(options);
				})
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateAdsCover([...response.data]);
				});
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.isAnyModalOpen !== nextProps.isAnyModalOpen) {
			document.querySelector("html#HTML").className = nextProps.isAnyModalOpen
				? "overflow-hidden"
				: "";
		}
		return null;
	}

	componentWillUnmount() {
		if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
		document
			.querySelector(".App")
			.removeEventListener("click", (e) => this.handleOnClick(e));
	}

	handleLocationChange = (location) => {
		ReactGA.pageview(location.pathname + location.search);
		this.props.closeHeader();
		if (
			location.pathname.match(/^((?!dashboard\/applications).)*$/) &&
			location.pathname.match(/^((?!userpanel\/my-applications).)*$/) &&
			location.pathname.match(/^((?!userpanel\/my-referrals).)*$/)
		) {
			page_smothly_scroll_to(0, 0);
		}
	};

	handleKeyDown = (e) => {
		if (e.keyCode === 27 || e.charCode === 27) {
			this.props.closeAll();
		}
	};

	handleOnClick = (e) => {
		// e.preventDefault();
		const target = e.target.outerHTML;
		const header = document.querySelector(".App header");
		const jobs_page_filter_bar_btn = document.querySelector(
			".App .page-jobs .filter-bar-wrapper .btn-input-keywords",
		);
		if (
			(!header || (header && header.outerHTML.indexOf(target) === -1)) &&
			(!jobs_page_filter_bar_btn ||
				(jobs_page_filter_bar_btn &&
					jobs_page_filter_bar_btn.outerHTML.indexOf(target) === -1))
		) {
			this.props.closeHeader();
		}

		// data-event-category
		const dataset = e.target.dataset;
		if (dataset && dataset.eventCategory) {
			const label = JSON.stringify({
				current_uri: `${window.location.origin}${window.location.pathname}${window.location.search}`,
				is_signed_in: `${this.props.reduxTokenAuth.currentUser.isSignedIn}`,
			});
			gaEvent(dataset.eventCategory, "Click", label);
		}
	};

	render() {
		const { handleKeyDown } = this;
		const { validating } = this.state;
		const { t } = this.props;
		const { match } = this.props;

		return (
			<div className="App">
				<Helmet>
					<title>{t("meet_jobs___global_talent__global_job")}</title>
					<link rel="shortcut icon" href={config.favicon_pathname()} />
				</Helmet>
				<div className="app-wrapper" tabIndex="0" onKeyDown={handleKeyDown}>
					<Switch>
						<Route
							path={`${match.url}/userpanel`}
							render={() =>
								!validating ? (
									<UserpanelWrapper />
								) : (
									<div className="meet-jobs-loader-wrapper">
										<MeetJobsLoader />
									</div>
								)
							}
						/>
						<Route
							path={`${match.url}/dashboard`}
							render={() =>
								!validating ? (
									<DashboardWrapper />
								) : (
									<div className="meet-jobs-loader-wrapper">
										<MeetJobsLoader />
									</div>
								)
							}
						/>
						<Route
							path={`${match.url}/talents`}
							render={() =>
								!validating ? (
									<TalentsWrapper />
								) : (
									<div className="meet-jobs-loader-wrapper">
										<MeetJobsLoader />
									</div>
								)
							}
						/>
						<Route
							path={`${match.url}/external-job-redirect`}
							render={() => <ExternalJobRedirectPageWrapper />}
						/>
						<Route
							path={`${match.url}`}
							render={() => <FrontendWrapper validating={validating} />}
						/>
					</Switch>

					<FlashMessagesWrapper />
					<ConnectionOffModalWrapper />
					<LogOutModalWrapper />
					{this.props.location.pathname.search(`/${i18n.language}/jobs`) ===
						-1 &&
					this.props.location.pathname.search(
						`/${i18n.language}/employers/`,
					) === -1 &&
					!validating &&
					!this.props.isAnyModalOpen ? (
						<TawkToWrapper />
					) : (
						""
					)}
				</div>
			</div>
		);
	}
}

export default withNamespaces(["general"])(App);
