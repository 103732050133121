export default {
    meet_jobs_is_: "Meet.jobs 是 ⋯",
    meet_jobs_is_content:
        `<p>Meet.jobs 是一個面向全球，專為國際專業人才所打造的跨境社交求職平台。</p>` +
        `<p>Meet.jobs 的使命是讓每個人都能夠找到發揮自身最大價值的舞台，透過 Meet.jobs 上公開、透明的工作機會資訊，及與社群的交流與分享，開拓視野、獲得更多挑戰與機會。</p>`,
    sharing__trust__and_diversity: "「分享」、「信任」、「多元」",
    sharing__trust__and_diversity_content:
        `<p>「分享」、「信任」和「多元」，是 Meet.jobs 的核心價值，分別體現在「社群推薦」、「資訊透明」和「專注國際」三個特色。</p>`,

    sharing___community_referral: "分享－社群推薦",
    sharing___community_referral_content:
        `<p>凡透過 Meet.jobs 錄取工作的用戶，可獲得 100 USD 的「就職祝賀金」。藉此，我們邀請您分享應徵、職場、簽證以及當地生活的經歷，幫助更多的求職朋友。</p>` +
        `<p>另外，除了幫自己找工作，您也可以推薦朋友，透過 Meet.jobs 錄取工作。Meet.jobs 也提供「推薦感謝金」（約為年薪的 2% 到 4%）給與成功推薦人選的用戶！</p>`,
    trust___information_transparency: "信任－資訊透明",
    trust___information_transparency_content:
        `<p>精準而完整的資訊不但對求職者公平，也提高雇主的徵才效率。Meet.Jobs 上的所有工作機會資訊，都清楚載明薪資範圍區間，而不是「面議」或「依公司規定」，這是 Meet.Jobs 的堅持。</p>` +
        `<p>我們希望透過公開、透明的工作機會資訊，建立「求職者」、「雇主」與「推薦人」互相信任的基礎，幫助求職者快速判斷要不要應徵一份工作，加速企業徵才的效率。</p>`,

    diversity___international_focused: "多元－專注國際",
    diversity___international_focused_content:
        `<p>Meet.Jobs 深信這個世界上的每一個人，都有獨一無二的潛能與經驗。在這個世界上一定會有一個舞台，可以讓你發揮自己最大的價值。</p>` +
        `<p>保有開放的心胸與態度（Open-minded）尊重與自己不同的人，並在彼此的差異中學習，不僅是職場工作中最重要的軟技能（Soft skills），也是 Meet.Jobs 的核心價值。</p>`,

    our_team: "我們的團隊",
    news_and_press: "新聞及報導",
    call_to_action_content: "跟 Meet.jobs 一起打造更好的求職／求才未來",
    join_us: "加入我們",
};