import React from 'react';
import { withNamespaces } from "react-i18next";

class FlashMessage extends React.Component {

    constructor(props) {
        super(props);
        this.closeFlashMessage = this.closeFlashMessage.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.tick = this.tick.bind(this);
        this.state = {
            remaining_seconds: 3,
        };
        this.timer = false;
    };

    componentDidMount() {
        const flash_message_element = document.querySelector(`.flash-message[data-id="${this.props.flash_message.id}"]`);
        if(this.props.flash_message.open && flash_message_element) {
            flash_message_element.addEventListener('click', e => this.handleOnClick(e) );
            this.timer = setInterval(this.tick, 1000);
        }
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        const flash_message_element = document.querySelector(`.flash-message[data-id="${next_props.flash_message.id}"]`);
        if(this.props.flash_message.open !== next_props.flash_message.open && flash_message_element) {
            if(next_props.flash_message.open) {
                flash_message_element.addEventListener('click', e => this.handleOnClick(e) );
                this.timer = setInterval(this.tick, 1000);
            } else {
                flash_message_element.removeEventListener('click', e => this.handleOnClick(e) );
                clearInterval(this.timer);
                this.timer = false;
            }
        }
    };

    closeFlashMessage = e => {
        e.preventDefault();
        this.props.removeFlashMessage(this.props.flash_message.id);
    };

    handleOnClick = e => {
        const dataset = e.target.dataset;
        if(dataset && dataset.closeAfterClick === 'true') {
            this.props.removeFlashMessage(this.props.flash_message.id);
        }
    };

    tick = () => {
        if(this.props.flash_message.display_type === 'with-auto-disappear') {
            let remaining_seconds = this.state.remaining_seconds - 1;
            if(remaining_seconds <= 0) {
                this.props.removeFlashMessage(this.props.flash_message.id);
            } else {
                this.setState({ remaining_seconds });
            }
        }
    };

    render() {

        const { closeFlashMessage } = this;
        const { flash_message } = this.props;

        return (
            (flash_message.open) ?
                <div
                    className={`flash-message ${flash_message.status ? `flash-message-${flash_message.status}` : ''} ${flash_message.display_type}`}
                    data-id={flash_message.id}
                >
                    <p>{flash_message.text}</p>
                    {
                        (flash_message.display_type==='with-dismiss') ?
                            <button
                                className="btn btn-close"
                                onClick={ e => closeFlashMessage(e) }
                            >
                                <span className="hidden">Close</span>
                            </button> : ''
                    }
                    {
                        (flash_message.display_type==='with-action-button') ?
                            <button onClick={ e => e.preventDefault() } className="btn btn-smallest btn-flat btn-fill">
                                <h5>{flash_message.action_button_text}</h5>
                            </button> : ''
                    }
                </div> : ''
        );
    }
}

export default withNamespaces(['flash_messages'])(FlashMessage)
