export default {
    // contact meet jobs modal
    contact_meet_jobs: "聯繫 Meet.jobs",
    business_development: "Business Development",

    // employer message modal
    candidate_info: "求職者資訊",
    candidate_name: "求職者名稱",
    email: "Email",
    phone: "電話",
    talent_match: "建議面試",
    referral: "推薦",
    confirm_hire: "確認錄取",
    title: "標題",
    location: "地點",
    date: "日期",
    time: "時間",
    to: "至",
    file_name: "檔案名稱",
    type_something___: "輸入些什麼...",
    invite_to_an_interview: "面試邀約",
    confirmTitle: "您要確認 {{applier_name}} 的錄取嗎？",
    confirmDescription: "Meet.jobs 會盡快跟您聯絡後續事宜。",
    assessment_for_suitable_candidates_to_invite_to_interviews__the_specific_service_for_hire_and_hire_plus_plan_: `依照求職者的資料，建議您是否進行面試邀約。此功能僅提供 <b>社群 / 獵才 方案</b>用戶使用。`,
    by__: "推薦人：{{referrer_name}}",
    internal_comments_will_not_be_seen_by_candidates: "內部留言不會顯示給求職者",
    no_reference_letter: "沒有推薦訊息",
    send_a_thank_you_letter: "寄送感謝信",
    thank_you_letter_warning: "寄出感謝信給求職者，並將此應徵將標註為未錄取",
    thank_you_letter_content: "{{candidate_name}} 你好，<br/><br/>感謝您在 Meet.jobs 上應徵了： {{employer_name}} 的 {{job_title}}。<br/><br/>經過慎重的篩選之後，您的應徵未能進入下一階段。<br/><br/>邀請您持續關注 Meet.jobs 上的其他工作機會，也祝您順利找到理想的工作。<br/><br/>Meet.jobs",
    send: "送出",

    // locked modal
    your_account_is_temporarily_locked_: "您的帳戶目前已被鎖定",
    locked_content: "與您收款時遭遇到某些錯誤，請聯繫 Meet.jobs 已為您解鎖。",
    locked_content_post_plan: "與您收款時遭遇到某些錯誤，請新增一張可使用的信用卡。",
    go_to_payment_setting: "前往付款設定",

    // welcome employer modal
    you_are_all_set: "準備就緒",
    you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_: "馬上填寫詳細的徵才企業資訊，並張貼完整工作機會來開始人才招募",
    we_are_pleased_to_provide_you__60_days_free_trial__: `限時優惠，為您提供<span class='free-trial'> 60 天免費試用</span>！`,
    we_have_placed_you_in_high_priority: "您已搶得先機",
    we_will_reach_you_via_the_information_you_have_provided_: "專屬的 Meet.jobs 徵才顧問，將會透過您提供的資訊進行聯繫",

    // edit member modal
    remove_member: "移除成員",
    transfer_ownership: "移轉擁有權",
    select_member: "選擇成員",
    are_you_sure_to_remove___: "您確定要將 {{member_name}} 由後台移除？",
    are_you_sure_to_leave___: "您確定要離開 {{employer_name}}？",

    // edit invited member modal
    resend_invitation: "重寄邀請",
    resend_member_invitation_to___: "確定要再次送出邀請到 {{invited_roleship_email}}？",
};