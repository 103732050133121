import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import i18n from "../../../../i18n";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import logo from "../../../../assets/images/logo_meetjobs_standard_white.svg";
import icon_fb_group from "../../../../assets/images/icon/icon_fb_group.svg";
import icon_fb from "../../../../assets/images/icon/icon_fb.svg";
import icon_instagram from "../../../../assets/images/icon/icon_instagram.svg";
import icon_linkedin from "../../../../assets/images/icon/icon_linkedin.svg";
import icon_youtube from "../../../../assets/images/icon/icon_youtube.svg";
import { change_language_with_redirect } from "../../../../assets/js/functions";
import InternalUri from "../../../models/internal_uri";

const config = new Config();
const headers = new ApiHeaders();

class Footer extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.state = {
			language: "en",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		this.setState({ language: i18n.language });
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		this.setState({ language: i18n.language });
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleSelectChange = (selected_option) => {
		if (selected_option) {
			this.setState({ language: selected_option.value });
			change_language_with_redirect(selected_option.value, this.props.history);

			const { reduxTokenAuth } = this.props;
			if (reduxTokenAuth.currentUser.isSignedIn) {
				let formData = new FormData();
				formData.append("user[locale]", selected_option.value);
				let options = {
					method: "PUT",
					url: config.api_url(
						`/users/${reduxTokenAuth.currentUser.attributes.id}`,
					),
					headers: headers.getItemsFromLocalStorage("multipart/form-data"),
					data: formData,
					json: true,
				};
				axios(options)
					.then((response) => {
						headers.updateItemsToLocalStorage(response.headers);
						this.props.updateConnectionOffModal(false);
					})
					.catch((error) => {
						if (error.response) {
							headers.updateItemsToLocalStorage(error.response.headers);
							if (error.response.status === 401) window.location.reload();
						}

						if (this._isMounted) {
							if (typeof error.response === "undefined")
								this.props.updateConnectionOffModal(true);
						}
					});
			}
		}
	};

	render() {
		const { language } = this.state;
		const { handleSelectChange } = this;
		const { t } = this.props;
		const internal_uri = new InternalUri();
		const date = new Date();

		return (
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-4">
							<div className="footer-logo-wrapper">
								<img src={logo} alt="meet.jobs logo" />
							</div>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-4">
							<div className="footer-menu-wrapper">
								<div className="footer-menu-1">
									<ul>
										<li>
											<Link
												to={internal_uri.formatted_frontend_about_us_page_path()}>
												<h6>{t("about_us").toUpperCase()}</h6>
											</Link>
										</li>
										<li>
											<a href="https://column.meet.jobs">
												<h6>{t("column").toUpperCase()}</h6>
											</a>
										</li>
										<li>
											<a
												href={
													`mailto:service@meet.jobs?` +
													// `subject=${t('hello__meet_jobs__i_have_some_questions___')}`
													`&body=${t(
														"hello__meet_jobs__i_have_some_questions___",
													)}`
												}>
												<h6>{t("contact_us").toUpperCase()}</h6>
											</a>
										</li>
										<li>
											<Link
												to={`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=employer-agreement`}>
												<h6>{t("employer_agreement").toUpperCase()}</h6>
											</Link>
										</li>
									</ul>
								</div>
								<div className="footer-menu-2">
									<ul>
										<li>
											<Link
												to={internal_uri.formatted_frontend_jobs_page_path()}>
												<h6>{t("all_jobs").toUpperCase()}</h6>
											</Link>
										</li>
										<li>
											<Link
												to={internal_uri.formatted_frontend_employer_landing_page_path()}>
												<h6>{t("employer").toUpperCase()}</h6>
											</Link>
										</li>
										<li>
											<Link
												to={internal_uri.formatted_frontend_referrer_landing_page_path()}>
												<h6>{t("referrer").toUpperCase()}</h6>
											</Link>
										</li>
										<li>
											<Link
												to={internal_uri.formatted_frontend_privacy_and_terms_page_path()}>
												<h6>{t("privacy_and_terms").toUpperCase()}</h6>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-4">
							<div className="footer-right-wrapper">
								<div className="select-wrapper">
									<Select
										className="drop-down drop-down-larger drop-down-no-border"
										clearable={false}
										searchable={false}
										value={language}
										onChange={handleSelectChange}
										options={[
											{ value: "en", label: "English" },
											{ value: "zh-TW", label: "繁體中文" },
										]}
									/>
								</div>
								<div className="social-icons">
									<a
										className="social-icon social-icon-fb"
										href="https://www.facebook.com/gomeetjobs"
										target="_blank"
										rel="noopener noreferrer"
										data-event-category="052_footer_fb">
										<img src={icon_fb} alt="Meet.jobs facebook" />
									</a>
									<a
										className="social-icon social-icon-fb-group"
										href="https://www.facebook.com/groups/877821818922805"
										target="_blank"
										rel="noopener noreferrer"
										data-event-category="053_footer_group">
										<img src={icon_fb_group} alt="Meet.jobs facebook group" />
									</a>
									<a
										className="social-icon social-icon-linkedin"
										href="https://www.linkedin.com/company/meetjobs"
										target="_blank"
										rel="noopener noreferrer"
										data-event-category="054_footer_linkedin">
										<img src={icon_linkedin} alt="Meet.jobs linkedin" />
									</a>
									<a
										className="social-icon social-icon-instagram"
										href="https://www.instagram.com/meet.jobs"
										target="_blank"
										rel="noopener noreferrer"
										data-event-category="055_footer_ig">
										<img src={icon_instagram} alt="Meet.jobs instagram" />
									</a>
									<a
										className="social-icon social-icon-youtube"
										href="https://www.youtube.com/meetjobs"
										target="_blank"
										rel="noopener noreferrer"
										data-event-category="056_footer_youtube">
										<img src={icon_youtube} alt="Meet.jobs youtube" />
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="copyright-wrapper">
						<h5>
							{t("copyright___meet_jobs__", { year: date.getFullYear() })}
						</h5>

						<h5>{t("taipei_number")}</h5>
					</div>
				</div>
				<div
					style={{
						position: "absolute",
						bottom: "0",
						left: "0",
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						color: "#fff",
						padding: "1%",
						marginTop: "5%",
						gap: "3%",
					}}>
					<a
						style={{
							color: "#fff",
							fontSize: "12px",
							whiteSpace: "nowrap",
							fontWeight: "lighter",
						}}
						href="https://www.wda.gov.tw/News_Content.aspx?n=08A702A6C5B2C6C0&sms=9ED4619202FF31CC&s=0ED17EE4778BCEEE"
						target="_blank">
						求職防詐騙宣導
					</a>
					<a
						style={{
							color: "#fff",
							fontSize: "12px",
							whiteSpace: "nowrap",
							fontWeight: "lighter",
						}}
						href="https://eeweb.mol.gov.tw/"
						target="_blank">
						防制就業歧視宣導
					</a>
					<a
						style={{
							color: "#fff",
							fontSize: "12px",
							whiteSpace: "nowrap",
							fontWeight: "lighter",
						}}
						href="https://job.taiwanjobs.gov.tw/Internet/Index/docDetail.aspx?uk=2084&docid=37136"
						target="_blank">
						簽訂職業介紹服務定型化契約注意事項宣導
					</a>
					<a
						style={{
							color: "#fff",
							fontSize: "12px",
							whiteSpace: "nowrap",
							fontWeight: "lighter",
						}}
						href="https://www.mol.gov.tw/1607/28162/28166/28246/28250/29097/"
						target="_blank">
						事業單位違法勞動法令名單
					</a>
				</div>
			</footer>
		);
	}
}

export default withNamespaces(["general"])(Footer);
