export default {
    edit_employer_profile: "編輯企業資訊",
    view_employer_page: "瀏覽企業專頁",

    summaryCard: {
        job: {
            content: "{{number}} 個張貼中的工作機會",
            subContent: "{{number}} 個全部的工作機會"
        },
        invoice: {
            content: "{{number}} 筆未付款的收據",
            subContent: "{{number}} 筆全部的收據"
        },
        cvReadRate: {
            title: "履歷讀取率",
            leftSubTitle: "平均讀取率",
            rightSubTitle: "最近七天讀取率",
            tooltipContent: "根據最近七天您讀取的應徵來計算",
            noDataContent: "暫無應徵",
        },
        messageReplyRate: {
            title: "應徵回覆率",
            leftSubTitle: "平均回覆率",
            rightSubTitle: "最近七天回覆率",
            tooltipContent: "根據最近七天您回覆的應徵來計算",
            noDataContent: "暫無應徵",
        }
    },

    contact: "聯絡資訊",
    account_manager: "客戶經理",
};