import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import {
    updateReferralMessageModal,
    updateCandidateMessageModal,
    updateAddReferenceNotesModal,
    updateEntranceFormModal,
    updateReadReferenceNotesModal,
    updateClaimReferralRewardModal,
    updateLogOutModal,
    updateConnectionOffModal,
    updateMyReferralLinkModal,
    addFlashMessage
} from '../../actions';

import CandidateMessageModal from "../views/userpanel/modals/CandidateMessageModal.jsx";
import AddReferenceNotesModal from "../views/userpanel/modals/AddReferenceNotesModal.jsx";
import ReadReferenceNotesModal from "../views/userpanel/modals/ReadReferenceNotesModal.jsx";
import ClaimReferralRewardModal from "../views/userpanel/modals/ClaimReferralRewardModal.jsx";

import AccountSettingPage from "../views/userpanel/pages/AccountSettingPage.jsx";
import MyApplicationsPage from "../views/userpanel/pages/MyApplicationsPage.jsx";
import SavedJobsPage from "../views/userpanel/pages/SavedJobsPage.jsx";
import MyReferralsPage from "../views/userpanel/pages/MyReferralsPage.jsx";
import MyReferralLinkModal from "../views/userpanel/modals/MyReferralLinkModal";
import MyResumePage from "../views/userpanel/pages/MyResumePage.jsx";
import ReferralMessageModal from '../views/userpanel/modals/ReferralMessageModal';

const VERIFY_TOKEN_REQUEST_SUCCEEDED = 'redux-token-auth/VERIFY_TOKEN_REQUEST_SUCCEEDED';
const VERIFY_TOKEN_REQUEST_FAILED = 'redux-token-auth/VERIFY_TOKEN_REQUEST_FAILED';

export const AccountSettingPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        verifyTokenRequestSucceeded(userAttributes) {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
                payload: {
                    userAttributes,
                },
            })
        },
        verifyTokenRequestFailed() {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_FAILED
            })
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        addFlashMessage(open, text, status, display_type, action_button_text) {
            dispatch(addFlashMessage(open, text, status, display_type, action_button_text))
        },
    })
)(AccountSettingPage));

export const MyReferralsPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        updateReferralMessageModal(referral_message_modal, job_id, application_id) {
            dispatch(updateReferralMessageModal(referral_message_modal, job_id, application_id))
        },
        updateAddReferenceNotesModal(open, job, application) {
            dispatch(updateAddReferenceNotesModal(open, job, application))
        },
        updateReadReferenceNotesModal(open, application) {
            dispatch(updateReadReferenceNotesModal(open, application))
        },
        updateClaimReferralRewardModal(open, application) {
            dispatch(updateClaimReferralRewardModal(open, application))
        },
        updateMyReferralLinkModal(open) {
            dispatch(updateMyReferralLinkModal(open))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(MyReferralsPage));

export const MyResumePageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        verifyTokenRequestSucceeded(userAttributes) {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
                payload: {
                    userAttributes,
                },
            })
        },
        verifyTokenRequestFailed() {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_FAILED
            })
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        addFlashMessage(open, text, status, display_type, action_button_text) {
            dispatch(addFlashMessage(open, text, status, display_type, action_button_text))
        },
    })
)(MyResumePage));

export const MyApplicationsPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        updateCandidateMessageModal(candidate_message_modal, job_id, application_id) {
            dispatch(updateCandidateMessageModal(candidate_message_modal, job_id, application_id))
        },
        updateLogOutModal(log_out_modal) {
            dispatch(updateLogOutModal(log_out_modal))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(MyApplicationsPage));

export const SavedJobsPageWrapper = connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(SavedJobsPage);

export const CandidateMessageModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        site_state: state.site_state,
    }),
    dispatch => ({
        updateCandidateMessageModal(candidate_message_modal, job_id, application_id) {
            dispatch(updateCandidateMessageModal(candidate_message_modal, job_id, application_id))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(CandidateMessageModal));

export const ReferralMessageModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        site_state: state.site_state,
    }),
    dispatch => ({
        updateReferralMessageModal(candidate_message_modal, job_id, application_id) {
            dispatch(updateReferralMessageModal(candidate_message_modal, job_id, application_id))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(ReferralMessageModal));

export const AddReferenceNotesModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        site_state: state.site_state,
    }),
    dispatch => ({
        updateAddReferenceNotesModal(open, job, application) {
            dispatch(updateAddReferenceNotesModal(open, job, application))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(AddReferenceNotesModal));

export const ReadReferenceNotesModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        site_state: state.site_state,
    }),
    dispatch => ({
        updateReadReferenceNotesModal(open, application) {
            dispatch(updateReadReferenceNotesModal(open, application))
        },
    })
)(ReadReferenceNotesModal));

export const ClaimReferralRewardModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        site_state: state.site_state,
    }),
    dispatch => ({
        updateClaimReferralRewardModal(open, application) {
            dispatch(updateClaimReferralRewardModal(open, application))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(ClaimReferralRewardModal));

export const MyReferralLinkModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        site_state: state.site_state,
    }),
    dispatch => ({
        updateMyReferralLinkModal(open) {
            dispatch(updateMyReferralLinkModal(open))
        }
    })
)(MyReferralLinkModal));
