import serialize from 'serialize-javascript';
import i18n from "../../i18n";
import InternalUri from "../../components/models/internal_uri";
import downloadjs from "downloadjs";
import 'url-search-params-polyfill';

export const compare = (obj1, obj2) => {
    for(let p in obj1) {
        //Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        switch (typeof (obj1[p])) {
            //Deep compare objects
            case 'object':
                if (!compare(obj1[p], obj2[p])) return false;
                break;
            //Compare function code
            case 'function':
                if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
                break;
            //Compare values
            default:
                if (obj1[p] !== obj2[p]) return false;
        }
    }

    //Check object 2 for any extra properties
    for(let p in obj2) {
        if (typeof (obj1[p]) === 'undefined') return false;
    }
    return true;
};

export const contain_not_only_whitespace = (string) => {
    if(string && string.trim().length) return true;
    return false;
};

export const get_value_from_query_string = (name, url) => {
    name = name.replace(/[[\]]/g, '\\$&');
    url = serialize(url).toString();
    url = url.substring(1, url.length - 1);
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return unescape(JSON.parse(`"${decodeURIComponent(results[2])}"`));
};

export const get_object_from_query_string = (name, url) => {
    name = name.replace(/[[\]]/g, '\\$&');
    url = serialize(url).toString();
    url = url.substring(1, url.length - 1);
    let match, results={};
    const regex = new RegExp('[?&]' + name + '(\\[(.*?)\\]=([^&#]*)|&|#|$)', 'g');
    while (match = regex.exec(url)) {
        // match is now the next match, in array form.
        let key = match[2];
        let value = match[3];
        results[key] = unescape(JSON.parse(`"${decodeURIComponent(value)}"`));
    }

    return results;
};

export const get_options = (type, raw_data, t = null) => {
    let options = [], i = 0;

    switch(type) {
        case 'countries':
            for(let key in raw_data) {
                options.push({ value: key, label: raw_data[key].name });
                i++;
            }
            break;
        case 'phone_codes':
            for(let key in raw_data) {
                options.push({ value: raw_data[key].phone.country_code, label: `(${raw_data[key].phone.country_code}) ${raw_data[key].name}` });
                i++;
            }
            break;
        case 'locations':
            for(i = 0; i<raw_data.length; i++) {
                options.push({ value: raw_data[i].id, label: raw_data[i].formatted_address() });
            }
            break;
        case 'currency_types':
            for(let key in raw_data) {
                options.push({ value: key, label: `${raw_data[key].name} (${key})` });
                i++;
            }
            break;
        case 'requirement_skills':
            for(i = 0; i<raw_data.collection.length; i++) {
                options.push({ value: raw_data.collection[i].id, label: raw_data.collection[i].name });
            }
            break;
        case 'roles':
            for(i = 0; i<raw_data.length; i++) {
                options.push({ value: raw_data[i].id, label: raw_data[i].name });
            }
            break;
        case 'roleships':
            for(i = 0; i<raw_data.collection.length; i++) {
                let role = raw_data.collection[i];
                // TODO: role 可課製、新增時，要修改。
                let color = '';
                if(role.name === 'Admin') color = 'badge-blue';
                if(role.name === 'Hiring Team') color = 'badge-cyan';
                for(let j = 0; j<role.roleships.length; j++) {
                    let roleship = role.roleships[j];
                    options.push({
                        user_id: roleship.member.id,
                        roleship_id: roleship.id,
                        title: roleship.title,
                        email: roleship.member.email,
                        role_name: role.name,
                        color
                    });
                }
            }
            break;
        case 'company_sizes':
            for(let key in raw_data) {
                if(t) options.push({ value: key, label: t(`states:company_size_${key}`) });
                else options.push({ value: key, label: raw_data[key] });
                i++;
            }
            break;
        default:
            for(let key in raw_data) {
                options.push({ value: key, label: raw_data[key] });
                i++;
            }
    }

    return options;
};

export const linkify = (text) => {
    if(!text) text = '';
    text = text
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");

    let urlRegex = /https?:\/\/(www\.)?[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
    text = text.replace(urlRegex, function(url) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="link">${url}</a>`;
    });
    return text;
};

export const removeHtmlTags = (string) => {
    string = (string||'').toString();
    return string.replace(/<[^>]*>/g, '');
};

export const htmlEntities = string => {
    if(!string) string = '';
    return string
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
};

export const raw_html_to_editor_content = (string) => {
    let temp = document.createElement('div');
    temp.innerHTML = string;
    // let htmlObject = temp;
    // let styles = htmlObject.getElementsByTagName('style');
    // for(let i=0; i<styles.length; i++) {
    //     styles[i].innerHTML = "";
    // }
    // string = htmlObject.outerHTML;
    string = temp.outerHTML;
    return string
        .slice(5, -6)
        // b blockquote pre h1 h2 h3 h5 h6 br 留著 for 轉換
        // a h4 p ul ol li strong div 留著 for 新的編輯器
        .replace(/<(?!\/?(b|blockquote|pre|h1|h2|h3|h5|h6|br|a|h4|p|ul|ol|li|strong|div)(>|\s))[^<]+?>/g, "")
        // .replace(/<(\/?)(html|head|body|pre|address|div|em|u|span|img|table|tbody|tr|th|td)(.*?)>/g, "")
        .replace(/<(blockquote|pre)(.*?)>/g, "<p>")
        .replace(/<(\/)(blockquote|pre)(.*?)>/g, "</p>")
        .replace(/<(h1|h2|h3|h5|h6)(.*?)>/g, "<h4>")
        .replace(/<(\/)(h1|h2|h3|h5|h6)(.*?)>/g, "</h4>")
    // .replace(/<(\/?)(br)(.*?)>/g, "</p><p></p><p>")
    // .replace(/<(b)(.*?)>/g, "<strong>")
    // .replace(/<(\/)(b)(.*?)>/g, "</strong>");
};

export const smoothly_scroll_to = (element, to, duration) => {
    if(element) {
        let start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        let animateScroll = function() {
            currentTime += increment;
            let val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }
};

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};

export const page_smothly_scroll_to = (to, duration) => {
    smoothly_scroll_to(document.body, to, duration);
    smoothly_scroll_to(document.documentElement, to, duration);
};

export const formatted_locale = raw_language => {
    // zh-TW {{language}}-{{REGION}}
    const language = raw_language ? raw_language.split('-')[0].toLowerCase() : '';
    switch (language) {
        case 'zh':
            return 'zh-TW';
        case 'en':
        default:
            return 'en';
    }
};

export const change_language_with_redirect = (new_language, history) => {
    new_language = formatted_locale(new_language);
    if(i18n.language !== new_language) {
        i18n.changeLanguage(new_language);
        page_smothly_scroll_to(0, 300);
        const internal_uri = new InternalUri();
        history.replace(
            `${internal_uri.formatted_path_with_new_language(window.location.pathname)}` +
            `${window.location.search}`
        );
    }
};

export const download_file = (url, filename) => {
    let x = new XMLHttpRequest();
    x.open("GET", url, true);
    x.responseType = 'blob';
    x.onload = () => downloadjs(x.response, filename);
    x.send();
};

export const copy_string = (e, string) => {
    e.preventDefault();
    let el = document.createElement('textarea');
    el.value = string;
    // console.log(el);

    // Place in top-left corner of screen regardless of scroll position.
    el.style.position = 'fixed';
    el.style.top = 0;
    el.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    el.style.width = '2em';
    el.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    el.style.padding = 0;

    // Clean up any borders.
    el.style.border = 'none';
    el.style.outline = 'none';
    el.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    el.style.background = 'transparent';

    document.body.appendChild(el);
    if(navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        let editable = el.contentEditable;
        let readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        let range = document.createRange();
        range.selectNodeContents(el);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;
    } else {
        el.select();
    }
    document.execCommand('copy');
    el.blur();
    document.body.removeChild(el);
};

export const addCommasToNumber = (string) => {
    // let numbersByPoint = removeCommasToNumber((string||'').toString()).split(".");
    // TODO: 要確定進來的字串是?
    let numbersByPoint = (string||'').toString().split(".");
    numbersByPoint[0] = numbersByPoint[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numbersByPoint.join(".");
};

export const removeCommasToNumber = (string) => {
    return (string||'').toString().replace(/(,| )/g, '');
};

export const addDateFormat = (string) => {
    // TODO: 要確定進來的字串是?
    string = (string||'').toString();
    let newString = '';
    for(let i=0; i<4; i++) {
        newString += string[i] || '0';
        if(i===1) newString += ":";
    }
    return newString;
};

export const removeDateFormat = (string) => {
    return (string||'').toString().replace(/(:| )/g, '');
};

export const formatted_meta_description = (string) => {
    string = String(string).slice(0, 160);
    if(string.length === 160) string += '...';
    return string;
};

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const to_timestamp = (string_date) => {
    let datum = Date.parse(string_date);
    return datum ? parseInt(datum/1000) : 0;
};

export const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
};

export const addOrReplaceUrlParameters = (url, parameters={}) => {
    const urlWithoutParameters = url.split('?')[0];
    const parametersString = url.split('?')[1];

    const newSearch = new URLSearchParams(parametersString);
    Object.keys(parameters).map(key => newSearch.set(key, parameters[key]));

    const newSearchString = newSearch.toString();
    return newSearchString.length ? urlWithoutParameters + '?' + newSearchString : urlWithoutParameters;
};

export function debounce(func, delay){
    // timeout 初始值
    let timeout = null;
    return function () {
        let context = this;  // 指向 myDebounce 這個 input
        let args = arguments;  // KeyboardEvent
        clearTimeout(timeout)   
        timeout = setTimeout(function(){
            console.log(context, args)
            func.apply(context, args)
        }, delay)
    }
}