import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from "react-i18next";
import InternalUri from "../../../models/internal_uri";

const PasswordChangedPage = ({t}) => {

    const internal_uri = new InternalUri();

    return (
        <div className="page-password content-wrapper white">
            <div className="container">
                <section className="page-title">
                    <h2>{t('password_is_changed_')}</h2>
                    <p>{t('please_log_in_with_your_new_password_next_time_')}</p>
                </section>
                <section className="page-content">
                    <Link
                        to={internal_uri.formatted_frontend_index_page_path()}
                        className="btn btn-larger btn-flat btn-fill btn-single"
                    >
                        <h6>{t('general:home_to_index')}</h6>
                    </Link>
                </section>
            </div>
        </div>
    );
};

export default withNamespaces(['frontend_authentication', 'general'])(PasswordChangedPage);
