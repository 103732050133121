import React from 'react';
import {withNamespaces} from "react-i18next";
import { Link } from "react-router-dom";
import InternalUri from "../../../models/internal_uri";

const Error404Page = ({t}) => {

    const internal_uri = new InternalUri();

    return (
        <div className="page-404 content-wrapper">
            <div className="container">
                <div className="diagram">
                    <div className="image-wrapper" />
                </div>
                <h1>{t('we_can_t_find_the_page_you_re_looking_for_')}</h1>
                <p>{t('_404_page_note_found')}</p>
                <Link
                    to={internal_uri.formatted_frontend_index_page_path()}
                    className="btn btn-larger btn-flat btn-fill"
                >
                    <h5>{t('go_to_home_page')}</h5>
                </Link>
            </div>
        </div>
    );
};

export default withNamespaces(['frontend_error_404_page'])(Error404Page);