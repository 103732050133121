export default {
    // job_title: "工作機會名稱",
    all_applications: "所有應徵",
    applying: "應徵中",
    rejected: "未錄取",
    cancelled: "已取消",
    confirmed: "已錄取",
    candidate: "求職者",
    talent_match: "建議面試",
    referral: "推薦",
    referred_by__: "獲得 {{referrer_name}} 推薦",
    assessment_for_suitable_candidates_to_invite_to_interviews__the_specific_service_for_hire_and_hire_plus_plan_: `依照求職者的資料，建議您是否進行面試邀約。此功能僅提供 <b>社群 / 獵才 方案</b>用戶使用。`,
    sit_back_and_relax: "讓子彈飛一會兒",
    you_have_no_applications_yet_: "尚未收到任何應徵。",
    no_jobs_posted: "無張貼的工作機會",
    you_haven_t_posted_any_jobs_yet_: "您還沒張貼任何工作機會。",
    show_all_closed_jobs: "顯示所有已關閉工作機會",
}
