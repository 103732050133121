import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    HeaderWrapper,

    // page
    TalentPageWrapper,
} from '../../containers/talents';

import InternalUri from "../../models/internal_uri";

class Talents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    UNSAFE_componentWillMount() {
        const internal_uri = new InternalUri();
        const { reduxTokenAuth } = this.props;
        if(!reduxTokenAuth.currentUser.isSignedIn) {
            this.props.history.push(`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${encodeURIComponent(`${this.props.location.pathname}${this.props.location.search}`)}`);
        }
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        const internal_uri = new InternalUri();
        const { reduxTokenAuth } = next_props;
        if(!reduxTokenAuth.currentUser.isSignedIn) {
            this.props.history.push(`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${encodeURIComponent(`${this.props.location.pathname}${this.props.location.search}`)}`);
        }
    };

    render() {

        const { match } = this.props;

        return(
            <div className="Talents">
                <HeaderWrapper match={match} />
                <Switch>
                    <Route path={`${match.url}/:id`} render={ () => <TalentPageWrapper /> } />
                </Switch>
            </div>
        );
    };
}

export default Talents;