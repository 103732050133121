export default {
    // member_information: "Member information",
    // role_management: "Role management",
    owner_of__: "Owner of {{employer_name}}",
    owner: "Owner",

    email: "Email",
    username: "Username",
    title: "Title",
    role: "Role",
    add_member: "Add member",
    you: "you",
};