import React from "react";
import Slider from "react-slick";

export default function TopSlider({images=[]}){

    if(!images){
        return null
    }

    return (
        <Slider  autoplay={true} draggable={false} fade={true} pauseOnHover={false} autoplaySpeed={4500}>
            {
                images.map((img,idx)=>
                    <div key={`top-slider-${idx}`}>
                        <div className="item">
                            <img src={img} loading="lazy" />
                        </div>
                    </div>
                )
            }
        </Slider>
    )
}