import React from "react";
import { CreateEmployerFormWrapper } from "../../../containers/general.jsx";

const CreateEmployerPage = () => (
	<div className="page-create-employer content-wrapper">
		<CreateEmployerFormWrapper />
	</div>
);

export default CreateEmployerPage;
