const stateData = {
  "site_state": {
    "isAnyModalOpen": false,

    "_frontend": true,
    "apply_modal": {
      "open": false,
      "job": {},
      "referrer": {}
    },
    "invite_to_apply_modal": {
      "open": false,
      "job": {}
    },

    "_dashboard": true,
    "employer_message_modal": {
      "open": false,
      "company_id": -1,
      "job_id": -1,
      "application_id": -1
    },
    "add_member_modal": {
      "open": false,
      "company_id": -1,
      "role_id": -1
    },
    "edit_member_modal": {
      "open": false,
      "roleship": {},
      "role": {},
      "creator_user_id": -1,
    },
    "edit_invited_member_modal": {
        "open": false,
        "employer_id": -1,
        "invited_roleship": {},
        "role": {},
        "creator_user_id": -1,
    },
    "contact_meet_jobs_modal": false,
    "welcome_employer_modal": {
      "open": false,
      "banner_img_url": null,
      "title": "",
      "content": "",
      "and_content": null,
      "plus_content": null,
    },
    "create_employer_modal": false,

    "employer_profile_pending_degree_of_completion": null,


    "_userpanel": true,
    "candidate_message_modal": {
      "open": false,
      "job_id": -1,
      "application_id": -1
    },
    "referral_message_modal": {
      "open": false,
      "job_id": -1,
      "application_id": -1
    },
    "add_reference_notes_modal": {
      "open": false,
      "job": {},
      "application": {}
    },
    "read_reference_notes_modal": {
      "open": false,
      "application": {}
    },
    "claim_referral_reward_modal": {
      "open": false
    },
    "my_referral_link_modal": {
      "open": false
    },


    "_general": true,
    "search_bar": false,
    "keep_search_bar": false,
    "_mobile_menu": "['', 'main_menu', 'sub_menu']",
    "mobile_menu": "",
    "sub_menu": false,
    "dashboard_sidebar_collapsed": false,
    "_entrance_form_modal": "['sign-up', 'log-in', '']",
    "entrance_form_modal": "",
    "log_out_modal": false,
    "connection_off_modal": false,
    "flash_messages": [],
    "_flash_message" : {
      "id": "xxxxx",
      "open": false,
      "text": "",
      "_status": "['success', 'warning', 'danger']",
      "status": "",
      "_display_type": "['with-dismiss', 'with-action-button', 'with-auto-disappear']",
      "display_type": "",
      "action_button_text": ""
    },
    "ad_corner_modal": false,
    "ad_cover_modal": false
  },
  "search_words": {
    "keyword": "",
    "keyword_place": ""
  },
  "reduxTokenAuth": {
    "currentUser": {
      "isLoading": false,
      "isSignedIn": false,
      "attributes": {
        "name": null
      }
    }
  },
  "current_user": {
    "accepted_cookie_policy": false,
    "companies": [],
    "active_company": {},
    "employer_roles": {},
    "is_referral_user": false,
    "is_employer_user": false,
    "referral_codes": [],
    "cover_ads_dismiss": []
  },
  "ads": [],
  "ads_cover": [],
  "utm": {
    "source": null,
    "medium": null,
    "campaign": null
  }
};

export default stateData;
