import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
// import {addOrReplaceUrlParameters} from "../../../assets/js/functions";
import i18n from "../../../i18n";
import Ad from "../../models/ad";
const MarketingBanner = (props) => {
	const { t, ads } = props;
	// console.log("adsxyz", ads);
	// const url = addOrReplaceUrlParameters(props.url, props.parameters);

	const isZh_consult = i18n.language === "zh-TW" && props.id && props.id === 3;
	const [adIndex, setAdIndex] = useState(0);
	const [ad, setAd] = useState(new Ad());
	const [onHover, setOnHover] = useState(false);

	const eventMarketingParameters = {
		utm_source: "Meet.jobs",
		utm_medium: "Homepage_Banner",
		utm_campaign: ad.formatted_title(),
	};

	let timeout;
	useEffect(() => {
		// console.log("ad", ad);
		if (onHover && ads && ads.length > 1) {
			clearTimeout(timeout);
			return;
		}
		if (ads && ads.length >= 1) {
			clearTimeout(timeout);
			if (adIndex === ads.length - 1) {
				timeout = setTimeout(() => {
					setAdIndex(0);
					setAd(new Ad(ads[0]));
				}, 3000);
			} else {
				timeout = setTimeout(() => {
					setAdIndex(adIndex + 1);
					setAd(new Ad(ads[adIndex + 1]));
				}, 3000);
			}
		}
	}, [adIndex, ads, onHover]);
	return (
		<a
			onMouseEnter={() => setOnHover(true)}
			onMouseLeave={() =>
				setTimeout(() => {
					setOnHover(false);
				}, 1000)
			}
			className={`event-marketing block ${props.type}`}
			href={ad.get_url() === "" ? props.url : ad.get_url()}
			target="_blank"
			data-event-category={props.eventCategory}
			rel="noopener noreferrer">
			<div
				className="banner"
				style={{
					backgroundImage: `url(${
						ad.get_img_url() == "" ? props.banner_url : ad.get_img_url()
					})`,
				}}>
				<img
					src={ad.get_img_url() == "" ? props.banner_url : ad.get_img_url()}
					alt="meet.jobs"
				/>
			</div>
			<div className="content">
				<p
					dangerouslySetInnerHTML={{
						__html:
							ad.formatted_title() == t("general:_not_provided_")
								? props.content
								: ad.formatted_title(),
					}}
				/>
				<div
					className="btn btn-flat btn-fill"
					data-event-category={props.eventCategory}>
					{isZh_consult ? "申請諮詢" : <h5>{t("join_now")}</h5>}
				</div>
			</div>
		</a>
	);
};

MarketingBanner.defaultProps = {
	type: "",
	url: "",
	banner_url: "",
	content: "",
	parameters: {
		utm_source: "",
		utm_medium: "",
		utm_campaign: "",
	},
};

export default withNamespaces(["events"])(MarketingBanner);
