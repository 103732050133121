import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { UseReferralUrlWrapper } from "../../../containers/general.jsx";
import ApiHeaders from "../../../models/api_headers.js";
import { getTopCountry } from "../../../hooks/getTopLocation";
import axios from "axios";
import Config from "../../../models/config.js";
import {
	get_value_from_query_string,
	getRandomInt,
} from "../../../../assets/js/functions";
import User from "../../../models/user";
import { withNamespaces } from "react-i18next";
import icon_search from "../../../../assets/images/icon/icon_28_search_BG400.svg";
import InternalUri from "../../../models/internal_uri";
import PartnerLogos from "../PartnerLogos";
import Ad from "../../../models/ad";
import MarketingBanner from "../MarketingBanner";
import TopSlider from "../TopSlider";
import TestimonialSlider from "../TestimonialSlider";
import ColumnPosts from "../../general/ColumnPosts";

import adv_1 from "./../../../../assets/images/index-4.svg";
import adv_2 from "./../../../../assets/images/index-5.svg";
import adv_3 from "./../../../../assets/images/index-1.svg";
import adv_4 from "./../../../../assets/images/money.svg";

import how_1 from "./../../../../assets/images/index-3.svg";
import how_2 from "./../../../../assets/images/index-2.svg";
import how_3 from "./../../../../assets/images/index-6.svg";

import slider_01 from "../../../../assets/images/slider-1.jpg";
import slider_02 from "../../../../assets/images/slider-2.jpg";
import slider_03 from "../../../../assets/images/slider-3.jpg";

import testimonial_bg from "../../../../assets/images/wall.jpg";
import testimonial_pic_01 from "../../../../assets/images/HireReport_Riedel.jpg";
import testimonial_pic_02 from "../../../../assets/images/HireReport_Shopee.jpg";
import testimonial_pic_03 from "../../../../assets/images/testimonial_03.jpg";
import testimonial_pic_04 from "../../../../assets/images/HireReport_TrendMicro_01.png";
import testimonial_pic_05 from "../../../../assets/images/HireReport_XREX.jpg";
import testimonial_pic_06 from "../../../../assets/images/testimonial_06.jpg";

const testimonial = [
	{
		name: "Richard Lin",
		company: "SWAG",
		position: "Actor",
		image: testimonial_pic_01,
	},
	{
		name: "White Girl",
		company: "Garmin",
		position: "Frontend",
		image: testimonial_pic_02,
	},
	{
		name: "Bucky Barnes",
		company: "Avengers",
		position: "Winter Soldier",
		image: testimonial_pic_03,
	},
	{
		name: "Momo Shiro",
		company: "男塾",
		position: "二年級生",
		image: testimonial_pic_04,
	},
	{
		name: "Javale McGee",
		company: "LA. Lakers",
		position: "Center",
		image: testimonial_pic_05,
	},
	{
		name: "Indian Girl",
		company: "Garmin",
		position: "Frontend",
		image: testimonial_pic_06,
	},
];

const CalltoAction = {
	consolute: {
		image:
			"https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80",
		url: "/jobs/7669-career-consulting-service-職涯諮詢服務",
		text: "一次免費職涯諮詢",
	},
	newsletter: {
		image:
			"https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80",
		url: "/jobs/7669-career-consulting-service-職涯諮詢服務",
		text: "電子報/工作機會快報",
	},
	alljobs: {
		image:
			"https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80",
		url: "/jobs/7669-career-consulting-service-職涯諮詢服務",
		text: "general:all_jobs",
	},
};

const config = new Config();
const headers = new ApiHeaders();

// export const IndexPagef = (props) => {
// 	const isMounted = useRef(false);
// 	const [main, setMain] = useState({
// 		jobs: [],
// 		keyword_input_focused: false,
// 		keyword_place_input_focused: false,
// 		loading: true,
// 		error_messages: {},
// 		active_user: {},
// 		posts: [],
// 		trending_keyword_place: [],
// 		keyword: "",
// 		keyword_place: "",
// 	});
// 	const trending_keyword = [
// 		"Software",
// 		"Web",
// 		"App",
// 		"Data",
// 		"Product Manager",
// 		"Design",
// 		"Marketing",
// 		"Business Development",
// 	];
// 	useEffect(() => {
// 		const onMountFunction = async () => {
// 			if (!isMounted.current) {
// 				isMounted.current = true;
// 				props.updateAdCornerModal(true);
// 				const query = decodeURI(props.location.search);
// 				if (query) {
// 					let referral_code = get_value_from_query_string(
// 						"referral_code",
// 						query,
// 					);
// 					if (referral_code) {
// 						let formData = new FormData();
// 						formData.append("referral_code", referral_code);
// 						let options = {
// 							method: "POST",
// 							url: config.api_url("/users/referral_info"),
// 							headers: headers.getItemsFromLocalStorage("multipart/form-data"),
// 							data: formData,
// 							json: true,
// 						};
// 						try {
// 							await axios(options).then((response) => {
// 								headers.updateItemsToLocalStorage(response.headers);
// 								props.updateConnectionOffModal(false);

// 								if (isMounted.current) {
// 									const raw_user = response.data;
// 									const user = new User(raw_user);
// 									setMain({ ...main, referral_user: user });
// 									props.addCurrentUserReferralCode(referral_code);
// 								}
// 							});
// 						} catch (error) {
// 							if (error.response) {
// 								headers.updateItemsToLocalStorage(error.response.headers);
// 								if (error.response.status === 401) window.location.reload();
// 							}

// 							if (isMounted.current) {
// 								if (typeof error.response === "undefined")
// 									props.updateConnectionOffModal(true);
// 								setMain({
// 									...main,
// 									error_messages:
// 										error.response &&
// 										error.response.data &&
// 										error.response.data.status === "error"
// 											? error.response.data.errors
// 											: {
// 													full_message:
// 														"There was an error. Please try again later.",
// 											  },
// 								});
// 							}
// 						}
// 					}
// 				}
// 				await GetColumnPosts();
// 				await GetCountryList();
// 			} else {
// 				GetCountryList();
// 			}
// 		};
// 		onMountFunction();

// 		return () => {
// 			isMounted.current = false;
// 			props.updateAdCornerModal(false);
// 		};
// 	}, []);
// 	// functions
// 	async function GetCountryList() {
// 		const response = await getTopCountry();
// 		if (isMounted.current) return;
// 		let countries = [];
// 		for (let country in response.data) {
// 			const { name, translated_name, jobs_count } = response.data[country];
// 			countries.push({
// 				name,
// 				translated_name,
// 				jobs_count,
// 			});
// 		}
// 		countries.sort((a, b) => {
// 			return b.jobs_count - a.jobs_count;
// 		});
// 		const countriesDisplay = countries.slice(0, 6);
// 		setMain({ ...main, trending_keyword_place: countriesDisplay });
// 	}

// 	async function GetColumnPosts() {
// 		let options = {
// 			method: "GET",
// 			url: "https://column.meet.jobs/wp-json/wp/v2/posts?per_page=3&_embed",
// 			json: true,
// 		};
// 		return axios(options)
// 			.then((response) => {
// 				if (isMounted.current) return;
// 				setMain({
// 					...main,
// 					posts: response.data,
// 				});
// 			})
// 			.catch((error) => {
// 				console.log(error);
// 			});
// 	}

// 	const goTagPage = (e, keyword, keyword_place) => {
// 		e.preventDefault();
// 		e.stopPropagation();
// 		const internal_uri = new InternalUri();
// 		props.updateSearchKeywords(keyword, keyword_place);
// 		props.history.push(internal_uri.formatted_frontend_jobs_page_path());
// 	};

// 	const handleOnFocus = (e, property_name) => {
// 		e.preventDefault();
// 		setMain({
// 			...main,
// 			keyword_input_focused: property_name === "keyword_input_focused",
// 			keyword_place_input_focused:
// 				property_name === "keyword_place_input_focused",
// 		});
// 	};

// 	const handleOnBlur = (e) => {
// 		e.preventDefault();
// 		setTimeout(() => {
// 			const active_element = document.activeElement;
// 			if (
// 				active_element &&
// 				active_element.id !== "input_keyword" &&
// 				active_element.id !== "input_keyword_place"
// 			) {
// 				setTimeout(() => {
// 					if (isMounted.current) {
// 						setMain({
// 							...main,
// 							keyword_input_focused: false,
// 							keyword_place_input_focused: false,
// 						});
// 					}
// 				}, 300);
// 			}
// 		}, 0);
// 	};

// 	const submitSearchBar = (e) => {
// 		e.preventDefault();
// 		const internal_uri = new InternalUri();
// 		props.updateSearchKeywords(main.keyword, main.keyword_place);
// 		props.history.push(internal_uri.formatted_frontend_jobs_page_path());
// 	};

// 	const openSignUpModal = (e) => {
// 		e.preventDefault();
// 		props.updateEntranceFormModal("sign-up");
// 	};
// 	// functions
// 	const { t, ads, reduxTokenAuth } = props;
// 	const ad_index = getRandomInt(ads.length);
// 	const ad = new Ad(ads[ad_index]);

// 	const internal_uri = new InternalUri();
// 	const login = reduxTokenAuth.currentUser.isSignedIn;
// 	return (
// 		<div className="page-index content-wrapper">
// 			<UseReferralUrlWrapper />

// 			<section className="top-banner">
// 				<section className="overlay">
// 					<TopSlider images={[slider_01, slider_02, slider_03]} />
// 				</section>

// 				<div className="container">
// 					<div className="inner-wrapper">
// 						<h2 className="slogan">{t("maximize_your_value_in_the_world_")}</h2>
// 						<div className="search-wrapper">
// 							<form onSubmit={submitSearchBar} className={"attachment-open"}>
// 								<div className={`search-inputs`}>
// 									<div className="input-wrapper left">
// 										<img src={icon_search} alt="" />
// 										<input
// 											onChange={(e) =>
// 												setMain({ ...main, keyword: e.target.value })
// 											}
// 											// ref={(input) => (this.keyword = input)}
// 											className="input"
// 											type="text"
// 											placeholder={t("general:job__company__or_keyword")}
// 											onFocus={(e) => handleOnFocus(e, "keyword_input_focused")}
// 											onBlur={(e) => handleOnBlur(e)}
// 											id="input_keyword"
// 											autoComplete="off"
// 											data-event-category="101_search_with_keywords"
// 										/>
// 									</div>
// 									<div className="input-wrapper right">
// 										<input
// 											onChange={(e) =>
// 												setMain({ ...main, keyword_place: e.target.value })
// 											}
// 											// ref={(input) => (this.keyword_place = input)}
// 											className="input"
// 											type="text"
// 											placeholder={t("general:city_or_country")}
// 											onFocus={(e) =>
// 												handleOnFocus(e, "keyword_place_input_focused")
// 											}
// 											onBlur={(e) => handleOnBlur(e)}
// 											id="input_keyword_place"
// 											autoComplete="off"
// 											data-event-category="102_search_with_location"
// 										/>
// 										<button
// 											className="btn btn-larger btn-flat btn-fill"
// 											data-event-category="103_search_button">
// 											<h5>{t("general:search")}</h5>
// 										</button>
// 									</div>
// 									<button
// 										className="btn btn-larger btn-flat btn-fill mobile"
// 										data-event-category="103_search_button">
// 										<h5>{t("general:search")}</h5>
// 									</button>
// 								</div>
// 							</form>

// 							{1 > 2 ? (
// 								""
// 							) : (
// 								<div className="trending-tags-wrapper">
// 									<div className={`trending-tags open`}>
// 										<div className="box-items-wrapper">
// 											<ul className="box-items">
// 												{trending_keyword.map((keyword, i) => (
// 													<li key={i}>
// 														<button
// 															type="button"
// 															className="tag-box smallest-text"
// 															onClick={(e) => goTagPage(e, keyword, "")}
// 															data-event-category="104_trending_keywords">
// 															{keyword}
// 														</button>
// 													</li>
// 												))}
// 											</ul>
// 											<ul className="box-items box-items-location">
// 												<li>
// 													<a
// 														className="tag-box smallest-text"
// 														href={internal_uri.formatted_frontend_jobs_page_path(
// 															"?remote_working=true",
// 														)}
// 														data-event-category="105_trending_location">
// 														{t("remote")}
// 													</a>
// 												</li>
// 												{main.trending_keyword_place.map(
// 													({ name, translated_name }, i) => (
// 														<li key={i}>
// 															<button
// 																className="tag-box smallest-text"
// 																onClick={(e) => goTagPage(e, "", name)}
// 																data-event-category="105_trending_location">
// 																{translated_name}
// 															</button>
// 														</li>
// 													),
// 												)}
// 											</ul>
// 										</div>
// 									</div>
// 								</div>
// 							)}
// 						</div>
// 					</div>
// 				</div>
// 			</section>
// 			{ads && ads.length ? (
// 				<div className="sm:hidden mobile-event-banner">
// 					<MarketingBanner
// 						type=""
// 						id={ad.id}
// 						url={ad.get_url()}
// 						banner_url={ad.get_img_url()}
// 						content={ad.formatted_title()}
// 						// parameters={{...this.eventMarketingParameters, utm_campaign: ad.formatted_title()}}
// 						eventCategory="112_marketing_home"
// 					/>
// 				</div>
// 			) : (
// 				""
// 			)}

// 			<section className="advantages">
// 				<div className="container">
// 					<h2 className="title">{t("feature_title")}</h2>
// 					<div className="grid-row d-xs-grid grid-cols-2 grid-lg-cols-4 gap-10 gap-lg-20">
// 						<div>
// 							<img src={adv_1} alt="" />
// 							<h3>{t("feature_1_title")}</h3>
// 							<p dangerouslySetInnerHTML={{ __html: t("feature_1_des") }} />
// 						</div>
// 						<div>
// 							<img src={adv_2} alt="" />
// 							<h3>{t("feature_2_title")}</h3>
// 							<p dangerouslySetInnerHTML={{ __html: t("feature_2_des") }} />
// 						</div>
// 						<div>
// 							<img src={adv_3} alt="" />
// 							<h3>{t("feature_3_title")}</h3>
// 							<p dangerouslySetInnerHTML={{ __html: t("feature_3_des") }} />
// 						</div>
// 						<div>
// 							<img src={adv_4} alt="meet.jobs" />
// 							<h3>{t("feature_4_title")}</h3>
// 							<p dangerouslySetInnerHTML={{ __html: t("feature_4_des") }} />
// 						</div>
// 					</div>
// 				</div>
// 			</section>

// 			<section className="our-partners">
// 				<div className="container">
// 					<h2 className="title">{t("featured_employers")}</h2>
// 					<PartnerLogos eventCategory="110_logos_home" />
// 				</div>
// 			</section>

// 			<section
// 				className="testimonial"
// 				style={{ backgroundImage: `url(${testimonial_bg})` }}>
// 				<div className="container">
// 					<h2 className="title">{t("reward_and_sharing")}</h2>
// 					<TestimonialSlider testimonial={testimonial} />
// 				</div>
// 			</section>

// 			<section className="how-we-work">
// 				<div className="container">
// 					<h2 className="title">{t("how_does_meet_jobs_work_")}</h2>
// 					<div className="row">
// 						<div className="col-xs-12 col-sm-4 col-md-4">
// 							<img src={how_1} alt="" />
// 							<h3>{t("step_one")}</h3>
// 							<p
// 								dangerouslySetInnerHTML={{
// 									__html: t("good_jobs_and_employers_selected_by_meet_jobs"),
// 								}}
// 							/>
// 						</div>
// 						<div className="col-xs-12 col-sm-4 col-md-4">
// 							<img src={how_2} alt="" />
// 							<h3>{t("step_two")}</h3>
// 							<p
// 								dangerouslySetInnerHTML={{
// 									__html: t("_100_usd_onboard_reward_when_you_getting_hired"),
// 								}}
// 							/>
// 						</div>
// 						<div className="col-xs-12 col-sm-4 col-md-4">
// 							<img src={how_3} alt="" />
// 							<h3>{t("step_three")}</h3>
// 							<p
// 								dangerouslySetInnerHTML={{
// 									__html: t(
// 										"_1_000__usd_referral_reward_for_a_successful_hire",
// 									),
// 								}}
// 							/>
// 						</div>
// 					</div>
// 				</div>
// 			</section>

// 			<section
// 				className="call-to-action"
// 				style={{
// 					backgroundImage:
// 						"url(https://images.unsplash.com/photo-1573496130407-57329f01f769?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=3300&q=80)",
// 					backgroundPosition: "center",
// 					backgroundSize: "cover",
// 					position: "relative",
// 				}}>
// 				<div
// 					style={{
// 						backgroundColor: "rgba(0,0,0,.7)",
// 						position: "absolute",
// 						top: 0,
// 						left: 0,
// 						width: "100%",
// 						height: "100%",
// 					}}
// 				/>
// 				<div className="container text-white relative z-10">
// 					<div className="rules">
// 						<h2
// 							className="title leading-loose"
// 							dangerouslySetInnerHTML={{ __html: t("sign_up_meet_jobs") }}
// 						/>
// 						<div
// 							className={`singup-now ${
// 								props.lng === "en" ? "max-400px" : "max-200px"
// 							}`}>
// 							<h4 className="text-center">{t("benefit_to_meet_jobs")}</h4>
// 							<p>
// 								{t("benefit_1")}
// 								<br />
// 								{t("benefit_2")}
// 								<br />
// 								<small> {t("one_click")}</small>
// 							</p>
// 						</div>

// 						<div>
// 							{!login ? (
// 								<a
// 									href={CalltoAction.newsletter.url}
// 									onClick={(e) => openSignUpModal(e)}>
// 									<button
// 										className="btn btn-huge btn-flat btn-fill btn-call-to-action"
// 										data-event-category="103_search_button">
// 										{t("sign_up_call_to_action_index")}
// 									</button>
// 								</a>
// 							) : (
// 								<Link
// 									to={internal_uri.formatted_frontend_jobs_page_path()}
// 									data-event-category="002_nav_all_jobs">
// 									<button className="btn btn-larger btn-flat btn-fill btn-call-to-action">
// 										<h5>{t(CalltoAction.alljobs.text)}</h5>
// 									</button>
// 								</Link>
// 							)}
// 						</div>
// 					</div>
// 				</div>
// 			</section>

// 			{main.posts.length && props.lng !== "en" ? (
// 				<section className="column-posts">
// 					<div className={"container"}>
// 						<h2 className="title">{t("general:column")}</h2>

// 						{ColumnPosts(main.posts)}
// 					</div>
// 				</section>
// 			) : (
// 				""
// 			)}

// 			<section className="call-to-action">
// 				<div className="row no-margin actions">
// 					<div className="col-xs-12 col-sm-6 col-md-5 col-md-offset-1">
// 						<NavLink
// 							to={internal_uri.formatted_frontend_employer_landing_page_path()}
// 							data-event-category="106_section_referral">
// 							<div
// 								className="action-block box-shadow rounded-l"
// 								style={{
// 									backgroundImage: `url(${CalltoAction.newsletter.image})`,
// 								}}>
// 								<span className="btn btn-larger btn-flat btn-hollow">
// 									<h5>{t("hire_talents")}</h5>
// 								</span>
// 							</div>
// 						</NavLink>
// 					</div>
// 					<div className="col-xs-12 col-sm-6 col-md-5">
// 						<NavLink
// 							to={internal_uri.formatted_frontend_referrer_landing_page_path()}
// 							data-event-category="106_section_referral">
// 							<div
// 								className="action-block box-shadow rounded-l"
// 								style={{
// 									backgroundImage: `url(${CalltoAction.alljobs.image})`,
// 								}}>
// 								<span className="btn btn-larger btn-flat btn-hollow">
// 									<h5>{t("refer_talents")}</h5>
// 								</span>
// 							</div>
// 						</NavLink>
// 					</div>
// 				</div>
// 			</section>
// 		</div>
// 	);
// };

class IndexPage extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.goTagPage = this.goTagPage.bind(this);
		this.handleOnFocus = this.handleOnFocus.bind(this);
		this.handleOnBlur = this.handleOnBlur.bind(this);
		this.submitSearchBar = this.submitSearchBar.bind(this);
		this.GetCountryList = this.GetCountryList.bind(this);

		this.openSignUpModal = this.openSignUpModal.bind(this);
		this.GetColumnPosts = this.GetColumnPosts.bind(this);
		this.testimonial = testimonial;

		this.state = {
			jobs: [],
			keyword_input_focused: false,
			keyword_place_input_focused: false,
			loading: true,
			error_messages: {},
			active_user: {},
			posts: [],
			trending_keyword_place: [],
		};
		this.trending_keyword = [
			"Software",
			"Web",
			"App",
			"Data",
			"Product Manager",
			"Design",
			"Marketing",
			"Business Development",
		];
		// this.eventMarketingParameters = {
		//     utm_source: "Meet.jobs",
		//     utm_medium: "Homepage_Banner",
		//     utm_campaign: ""
		// };
	}

	async componentDidMount() {
		this._isMounted = true;
		this.props.updateAdCornerModal(true);
		const query = decodeURI(this.props.location.search);
		if (query) {
			let referral_code = get_value_from_query_string("referral_code", query);
			if (referral_code) {
				let formData = new FormData();
				formData.append("referral_code", referral_code);
				let options = {
					method: "POST",
					url: config.api_url("/users/referral_info"),
					headers: headers.getItemsFromLocalStorage("multipart/form-data"),
					data: formData,
					json: true,
				};
				try {
					await axios(options).then((response) => {
						headers.updateItemsToLocalStorage(response.headers);
						this.props.updateConnectionOffModal(false);

						if (this._isMounted) {
							const raw_user = response.data;
							const user = new User(raw_user);
							this.setState(
								{
									referral_user: user,
								},
								() => this.props.addCurrentUserReferralCode(referral_code),
							);
						}
					});
				} catch (error) {
					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					if (this._isMounted) {
						if (typeof error.response === "undefined")
							this.props.updateConnectionOffModal(true);

						this.setState((prev_state) => ({
							...prev_state,
							error_messages:
								error.response &&
								error.response.data &&
								error.response.data.status === "error"
									? error.response.data.errors
									: {
											full_message:
												"There was an error. Please try again later.",
									  },
						}));
					}
				}
			}
		}
		await this.GetColumnPosts();
		await this.GetCountryList();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.lng !== this.props.lng) {
			this.GetCountryList();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.props.updateAdCornerModal(false);
	}

	GetCountryList() {
		return getTopCountry().then((response) => {
			if (!this._isMounted) return;

			let countries = [];
			for (let country in response.data) {
				const { name, translated_name, jobs_count } = response.data[country];
				countries.push({
					name,
					translated_name,
					jobs_count,
				});
			}
			countries.sort((a, b) => {
				return b.jobs_count - a.jobs_count;
			});
			const countriesDisplay = countries.slice(0, 6);

			this.setState({
				trending_keyword_place: countriesDisplay,
			});
		});
	}

	GetColumnPosts() {
		let options = {
			method: "GET",
			url: "https://column.meet.jobs/wp-json/wp/v2/posts?per_page=3&_embed",
			json: true,
		};
		return axios(options)
			.then((response) => {
				if (!this._isMounted) return;
				this.setState({
					posts: response.data,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	goTagPage = (e, keyword, keyword_place) => {
		e.preventDefault();
		e.stopPropagation();
		const internal_uri = new InternalUri();
		this.props.updateSearchKeywords(keyword, keyword_place);
		this.props.history.push(internal_uri.formatted_frontend_jobs_page_path());
	};

	handleOnFocus = (e, property_name) => {
		e.preventDefault();
		this.setState({
			keyword_input_focused: property_name === "keyword_input_focused",
			keyword_place_input_focused:
				property_name === "keyword_place_input_focused",
		});
	};

	handleOnBlur = (e) => {
		e.preventDefault();
		setTimeout(() => {
			const active_element = document.activeElement;
			if (
				active_element &&
				active_element.id !== "input_keyword" &&
				active_element.id !== "input_keyword_place"
			) {
				setTimeout(() => {
					if (this._isMounted) {
						this.setState({
							keyword_input_focused: false,
							keyword_place_input_focused: false,
						});
					}
				}, 300);
			}
		}, 0);
	};

	submitSearchBar = (e) => {
		e.preventDefault();
		const internal_uri = new InternalUri();
		this.props.updateSearchKeywords(
			this.keyword.value,
			this.keyword_place.value,
		);
		this.props.history.push(internal_uri.formatted_frontend_jobs_page_path());
	};

	openSignUpModal = (e) => {
		e.preventDefault();
		this.props.updateEntranceFormModal("sign-up");
	};

	render() {
		const { submitSearchBar, goTagPage } = this;
		const { handleOnFocus, handleOnBlur } = this;
		const { t } = this.props;
		const { ads } = this.props;
		const ad_index = getRandomInt(ads.length);
		const ad = new Ad(ads[ad_index]);
		const { reduxTokenAuth } = this.props;
		const internal_uri = new InternalUri();
		const login = reduxTokenAuth.currentUser.isSignedIn;
		return (
			<div className="page-index content-wrapper">
				<UseReferralUrlWrapper />

				<section className="top-banner">
					<section className="overlay">
						<TopSlider images={[slider_01, slider_02, slider_03]} />
					</section>

					<div className="container">
						<div className="inner-wrapper">
							<h2 className="slogan">
								{t("maximize_your_value_in_the_world_")}
							</h2>
							<div className="search-wrapper">
								<form onSubmit={submitSearchBar} className={"attachment-open"}>
									<div className={`search-inputs`}>
										<div className="input-wrapper left">
											<img src={icon_search} alt="" />
											<input
												ref={(input) => (this.keyword = input)}
												className="input"
												type="text"
												placeholder={t("general:job__company__or_keyword")}
												onFocus={(e) =>
													handleOnFocus(e, "keyword_input_focused")
												}
												onBlur={(e) => handleOnBlur(e)}
												id="input_keyword"
												autoComplete="off"
												data-event-category="101_search_with_keywords"
											/>
										</div>
										<div className="input-wrapper right">
											<input
												ref={(input) => (this.keyword_place = input)}
												className="input"
												type="text"
												placeholder={t("general:city_or_country")}
												onFocus={(e) =>
													handleOnFocus(e, "keyword_place_input_focused")
												}
												onBlur={(e) => handleOnBlur(e)}
												id="input_keyword_place"
												autoComplete="off"
												data-event-category="102_search_with_location"
											/>
											<button
												className="btn btn-larger btn-flat btn-fill"
												data-event-category="103_search_button">
												<h5>{t("general:search")}</h5>
											</button>
										</div>
										<button
											className="btn btn-larger btn-flat btn-fill mobile"
											data-event-category="103_search_button">
											<h5>{t("general:search")}</h5>
										</button>
									</div>
								</form>

								{1 > 2 ? (
									""
								) : (
									<div className="trending-tags-wrapper">
										<div className={`trending-tags open`}>
											<div className="box-items-wrapper">
												<ul className="box-items">
													{this.trending_keyword.map((keyword, i) => (
														<li key={i}>
															<button
																type="button"
																className="tag-box smallest-text"
																onClick={(e) => goTagPage(e, keyword, "")}
																data-event-category="104_trending_keywords">
																{keyword}
															</button>
														</li>
													))}
												</ul>
												<ul className="box-items box-items-location">
													<li>
														<a
															className="tag-box smallest-text"
															href={internal_uri.formatted_frontend_jobs_page_path(
																"?remote_working=true",
															)}
															data-event-category="105_trending_location">
															{t("remote")}
														</a>
													</li>
													{this.state.trending_keyword_place.map(
														({ name, translated_name }, i) => (
															<li key={i}>
																<button
																	className="tag-box smallest-text"
																	onClick={(e) => goTagPage(e, "", name)}
																	data-event-category="105_trending_location">
																	{translated_name}
																</button>
															</li>
														),
													)}
												</ul>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
				{ads && ads.length ? (
					<div className="sm:hidden mobile-event-banner">
						<MarketingBanner
							type=""
							id={ad.id}
							url={ad.get_url()}
							banner_url={ad.get_img_url()}
							content={ad.formatted_title()}
							ads={ads}
							// parameters={{...this.eventMarketingParameters, utm_campaign: ad.formatted_title()}}
							eventCategory="112_marketing_home"
						/>
					</div>
				) : (
					""
				)}

				<section className="advantages">
					<div className="container">
						<h2 className="title">{t("feature_title")}</h2>
						<div className="grid-row d-xs-grid grid-cols-2 grid-lg-cols-4 gap-10 gap-lg-20">
							<div>
								<img src={adv_1} alt="" />
								<h3>{t("feature_1_title")}</h3>
								<p dangerouslySetInnerHTML={{ __html: t("feature_1_des") }} />
							</div>
							<div>
								<img src={adv_2} alt="" />
								<h3>{t("feature_2_title")}</h3>
								<p dangerouslySetInnerHTML={{ __html: t("feature_2_des") }} />
							</div>
							<div>
								<img src={adv_3} alt="" />
								<h3>{t("feature_3_title")}</h3>
								<p dangerouslySetInnerHTML={{ __html: t("feature_3_des") }} />
							</div>
							<div>
								<img src={adv_4} alt="meet.jobs" />
								<h3>{t("feature_4_title")}</h3>
								<p dangerouslySetInnerHTML={{ __html: t("feature_4_des") }} />
							</div>
						</div>
					</div>
				</section>

				<section className="our-partners">
					<div className="container">
						<h2 className="title">{t("featured_employers")}</h2>
						<PartnerLogos eventCategory="110_logos_home" />
					</div>
				</section>

				<section
					className="testimonial"
					style={{ backgroundImage: `url(${testimonial_bg})` }}>
					<div className="container">
						<h2 className="title">{t("reward_and_sharing")}</h2>
						<TestimonialSlider testimonial={this.testimonial} />
					</div>
				</section>

				<section className="how-we-work">
					<div className="container">
						<h2 className="title">{t("how_does_meet_jobs_work_")}</h2>
						<div className="row">
							<div className="col-xs-12 col-sm-4 col-md-4">
								<img src={how_1} alt="" />
								<h3>{t("step_one")}</h3>
								<p
									dangerouslySetInnerHTML={{
										__html: t("good_jobs_and_employers_selected_by_meet_jobs"),
									}}
								/>
							</div>
							<div className="col-xs-12 col-sm-4 col-md-4">
								<img src={how_2} alt="" />
								<h3>{t("step_two")}</h3>
								<p
									dangerouslySetInnerHTML={{
										__html: t("_100_usd_onboard_reward_when_you_getting_hired"),
									}}
								/>
							</div>
							<div className="col-xs-12 col-sm-4 col-md-4">
								<img src={how_3} alt="" />
								<h3>{t("step_three")}</h3>
								<p
									dangerouslySetInnerHTML={{
										__html: t(
											"_1_000__usd_referral_reward_for_a_successful_hire",
										),
									}}
								/>
							</div>
						</div>
					</div>
				</section>

				<section
					className="call-to-action"
					style={{
						backgroundImage:
							"url(https://images.unsplash.com/photo-1573496130407-57329f01f769?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=3300&q=80)",
						backgroundPosition: "center",
						backgroundSize: "cover",
						position: "relative",
					}}>
					<div
						style={{
							backgroundColor: "rgba(0,0,0,.7)",
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
						}}
					/>
					<div className="container text-white relative z-10">
						<div className="rules">
							<h2
								className="title leading-loose"
								dangerouslySetInnerHTML={{ __html: t("sign_up_meet_jobs") }}
							/>
							<div
								className={`singup-now ${
									this.props.lng === "en" ? "max-400px" : "max-200px"
								}`}>
								<h4 className="text-center">{t("benefit_to_meet_jobs")}</h4>
								<p>
									{t("benefit_1")}
									<br />
									{t("benefit_2")}
									<br />
									<small> {t("one_click")}</small>
								</p>
							</div>

							<div>
								{!login ? (
									<a
										href={CalltoAction.newsletter.url}
										onClick={(e) => this.openSignUpModal(e)}>
										<button
											className="btn btn-huge btn-flat btn-fill btn-call-to-action"
											data-event-category="103_search_button">
											{t("sign_up_call_to_action_index")}
										</button>
									</a>
								) : (
									<Link
										to={internal_uri.formatted_frontend_jobs_page_path()}
										data-event-category="002_nav_all_jobs">
										<button className="btn btn-larger btn-flat btn-fill btn-call-to-action">
											<h5>{t(CalltoAction.alljobs.text)}</h5>
										</button>
									</Link>
								)}
							</div>
						</div>
					</div>
				</section>

				{this.state.posts.length && this.props.lng !== "en" ? (
					<section className="column-posts">
						<div className={"container"}>
							<h2 className="title">{t("general:column")}</h2>

							{ColumnPosts(this.state.posts)}
						</div>
					</section>
				) : (
					""
				)}

				<section className="call-to-action">
					<div className="row no-margin actions">
						<div className="col-xs-12 col-sm-6 col-md-5 col-md-offset-1">
							<NavLink
								to={internal_uri.formatted_frontend_employer_landing_page_path()}
								data-event-category="106_section_referral">
								<div
									className="action-block box-shadow rounded-l"
									style={{
										backgroundImage: `url(${CalltoAction.newsletter.image})`,
									}}>
									<span className="btn btn-larger btn-flat btn-hollow">
										<h5>{t("hire_talents")}</h5>
									</span>
								</div>
							</NavLink>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-5">
							<NavLink
								to={internal_uri.formatted_frontend_referrer_landing_page_path()}
								data-event-category="106_section_referral">
								<div
									className="action-block box-shadow rounded-l"
									style={{
										backgroundImage: `url(${CalltoAction.alljobs.image})`,
									}}>
									<span className="btn btn-larger btn-flat btn-hollow">
										<h5>{t("refer_talents")}</h5>
									</span>
								</div>
							</NavLink>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default withNamespaces(["frontend_index_page", "general"])(IndexPage);
