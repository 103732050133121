export default {
    next_billing_date_: "Next billing date:",
    free_trial_expiration_date_: "Free trial expiration date:",
    to_save_your_changes__click_save_: "To save your changes, click Save.",
    credit_card: "Credit Card",
    remittance: "Remittance",
    post_your_jobs_today_: "Post your jobs today!",
    add_credit_card: "Add credit card",
    _meet_jobs_does_not_save_your_credit_card_information: "*Meet.jobs does not save your credit card information",
    please_contact_me_through_email___phone_number_: "Please contact me through email / phone number.",
    credit_or_debit_card: "Credit or debit card",
    current_card_: "Current card:",
    if_you_have_further_questions__please___meet_jobs_: "If you have further questions, please {{contact}} Meet.jobs.",
    contact: "contact",
};