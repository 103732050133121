import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {
    closeAll,
    updateMobileMenu,
    updateSubMenu,
    updateConnectionOffModal,
    addFlashMessage,
} from '../../actions';

import Header from "../views/talents/layouts/Header";
import TalentPage from "../views/talents/pages/TalentPage";

export const HeaderWrapper = withRouter(connect(
    state => ({
        site_state: state.site_state,
        current_user: state.current_user,
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        updateMobileMenu(mobile_menu) {
            dispatch(updateMobileMenu(mobile_menu))
        },
        updateSubMenu(sub_menu) {
            dispatch(updateSubMenu(sub_menu))
        },
        closeAll() {
            dispatch(closeAll())
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(Header));

export const TalentPageWrapper = withRouter(connect(
    state => ({
        current_user: state.current_user,
    }),
    dispatch => ({
        addFlashMessage(open, text, status, display_type, action_button_text) {
            dispatch(addFlashMessage(open, text, status, display_type, action_button_text))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(TalentPage));