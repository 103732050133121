import { generateAuthActions } from 'redux-token-auth'
import Config from "./components/models/config.js";
// import auth_data from './redux-token-auth-constants'

let config = new Config();
const url = config.api_url('/auth');

const {
    registerUser,
    signInUser,
    signOutUser,
} = generateAuthActions({
    authUrl: url,
    //keys for frontend, values for backend
    userAttributes: {
        id: 'id',
        name: 'name',
        first_name: 'first_name',
        last_name: 'last_name',
        email: 'email',
        time_zone: 'time_zone',
        locale: "locale",
        provider: "provider"
    },
    userRegistrationAttributes: {
    },
});

export {
    registerUser,
    signInUser,
    signOutUser,
}