let instance = null;

class DeviceDetector {
    constructor() {
        this.decive_info = navigator.userAgent;

        if(!instance) instance = this;
        return instance;
    }

    is_mobile_or_tablet() {
        if(
            this.decive_info.match(/Android/i)
            || this.decive_info.match(/webOS/i)
            || this.decive_info.match(/iPhone/i)
            || this.decive_info.match(/iPad/i)
            || this.decive_info.match(/iPod/i)
            || this.decive_info.match(/BlackBerry/i)
            || this.decive_info.match(/Windows Phone/i)
        ) return true;
        return false;
    }
}

export default DeviceDetector;