import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import loGet from 'lodash/get';
import {
    closeAll,
    updateCurrentUserActiveCompany,
    updateCurrentUserCompanies,
    updateSearchKeywords,
    updateSearchBar,
    updateKeepSearchBar,
    addCurrentUserReferralCode,
    updateCurrentUserReferralCodes,

    updateEntranceFormModal,
    updateApplyModal,
    updateInviteToApplyModal,
    updateCurrentUserIsReferralUser,
    updateCurrentUserIsEmployerUser,
    updateCurrentUserEmployerRoles,
    updateConnectionOffModal,
    addFlashMessage,
    openFlashMessage,
    removeFlashMessage,
    updateCookiePolicyCornerModal,
    updateAdCornerModal,
    updateAdCoverModal,
    updateCoverAdsModalDismiss
} from '../../actions';

import ConfirmDonePage from "../views/frontend/pages/ConfirmDonePage.jsx";
import ConfirmPage from "../views/frontend/pages/ConfirmPage.jsx";
import EmployerPage from '../views/frontend/pages/EmployerPage.jsx';
import IndexPage from '../views/frontend/pages/IndexPage.jsx';
import JobsPage from '../views/frontend/pages/JobsPage.jsx';
import JobPage from '../views/frontend/pages/JobPage.jsx';
import ReferPage from '../views/frontend/pages/ReferPage.jsx';
import ReferralLandingPage from '../views/frontend/pages/ReferralLandingPage.jsx';
import EmployerLandingPage from '../views/frontend/pages/EmployerLandingPage.jsx';

import LogInForm from "../views/frontend/LogInForm.jsx";
import SignUpForm from "../views/frontend/SignUpForm.jsx";
import EntranceModal from "../views/frontend/modals/EntranceModal.jsx";
import AdCoverModal from "../views/frontend/modals/AdCoverModal";
import ApplyModal from "../views/frontend/modals/ApplyModal.jsx";
import InviteToApplyModal from "../views/frontend/modals/InviteToApplyModal";
import CallbackHandler from "../views/frontend/CallbackHandler.jsx";
import RedirectFromFacebook from "../views/frontend/RedirectFromFacebook.jsx";
import RedirectFromSignInMail from "../views/frontend/RedirectFromSignInMail.jsx";
import RedirectFromForgotPasswordMail from "../views/frontend/RedirectFromForgotPasswordMail.jsx";
import ResetPasswordPage from "../views/frontend/pages/ResetPasswordPage.jsx";
import ForgotPasswordPage from "../views/frontend/pages/ForgotPasswordPage.jsx";
import JobInfo from "../views/frontend/JobInfo";
import JobInvitationPage from "../views/frontend/pages/JobInvitationPage";
import CookiePolicyCornerModal from "../views/frontend/modals/CookiePolicyCornerModal";
import AdCornerModal from "../views/frontend/modals/AdCornerModal";
// import ReferralUserBottom from "../views/frontend/ReferralUserBottom";

const VERIFY_TOKEN_REQUEST_SUCCEEDED = 'redux-token-auth/VERIFY_TOKEN_REQUEST_SUCCEEDED';
const VERIFY_TOKEN_REQUEST_FAILED = 'redux-token-auth/VERIFY_TOKEN_REQUEST_FAILED';

// export const  ReferralUserBottomWrapper  = withRouter(connect(
//     dispatch => ({
//         addCurrentUserReferralCode(referral_code) {
//             dispatch(addCurrentUserReferralCode(referral_code))
//         }
//     })
// )(ReferralUserBottom ));


export const ConfirmDonePageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    null
)(ConfirmDonePage));

export const ConfirmPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(ConfirmPage));

export const CallbackHandlerWrapper = withRouter(connect(
    state => ({
        current_user: state.current_user
    }),
    dispatch => ({
        verifyTokenRequestSucceeded(userAttributes) {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
                payload: {
                    userAttributes,
                },
            })
        },
        updateCurrentUserCompanies(companies) {
            dispatch(updateCurrentUserCompanies(companies))
        },
        updateCurrentUserActiveCompany(active_company) {
            dispatch(updateCurrentUserActiveCompany(active_company))
        },
        updateCurrentUserEmployerRoles(employer_roles) {
            dispatch(updateCurrentUserEmployerRoles(employer_roles))
        },
        updateCurrentUserIsReferralUser(is_referral_user) {
            dispatch(updateCurrentUserIsReferralUser(is_referral_user))
        },
        updateCurrentUserIsEmployerUser(is_employer_user) {
            dispatch(updateCurrentUserIsEmployerUser(is_employer_user))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        addFlashMessage(open, text, status, display_type, action_button_text) {
            const dispatch_object = dispatch(addFlashMessage(open, text, status, display_type, action_button_text));
            return dispatch_object.id
        },
    })
)(CallbackHandler));

export const RedirectFromFacebookWrapper = withRouter(connect(
    state => ({
        current_user: state.current_user
    }),
    dispatch => ({
        closeAll() {
            dispatch(closeAll())
        },
        verifyTokenRequestSucceeded(userAttributes) {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
                payload: {
                    userAttributes,
                },
            })
        },
        updateCurrentUserCompanies(companies) {
            dispatch(updateCurrentUserCompanies(companies))
        },
        updateCurrentUserActiveCompany(active_company) {
            dispatch(updateCurrentUserActiveCompany(active_company))
        },
        updateCurrentUserEmployerRoles(employer_roles) {
            dispatch(updateCurrentUserEmployerRoles(employer_roles))
        },
        updateCurrentUserIsReferralUser(is_referral_user) {
            dispatch(updateCurrentUserIsReferralUser(is_referral_user))
        },
        updateCurrentUserIsEmployerUser(is_employer_user) {
            dispatch(updateCurrentUserIsEmployerUser(is_employer_user))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(RedirectFromFacebook));

export const RedirectFromSignInMailWrapper = withRouter(connect(
    state => ({
        current_user: state.current_user
    }),
    dispatch => ({
        closeAll() {
            dispatch(closeAll())
        },
        verifyTokenRequestSucceeded(userAttributes) {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
                payload: {
                    userAttributes,
                },
            })
        },
        updateCurrentUserCompanies(companies) {
            dispatch(updateCurrentUserCompanies(companies))
        },
        updateCurrentUserActiveCompany(active_company) {
            dispatch(updateCurrentUserActiveCompany(active_company))
        },
        updateCurrentUserEmployerRoles(employer_roles) {
            dispatch(updateCurrentUserEmployerRoles(employer_roles))
        },
        updateCurrentUserIsReferralUser(is_referral_user) {
            dispatch(updateCurrentUserIsReferralUser(is_referral_user))
        },
        updateCurrentUserIsEmployerUser(is_employer_user) {
            dispatch(updateCurrentUserIsEmployerUser(is_employer_user))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(RedirectFromSignInMail));

export const RedirectFromForgotPasswordMailWrapper = withRouter(connect(
    state => ({
        current_user: state.current_user
    }),
    dispatch => ({
        closeAll() {
            dispatch(closeAll())
        },
        verifyTokenRequestSucceeded(userAttributes) {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
                payload: {
                    userAttributes,
                },
            })
        },
        updateCurrentUserCompanies(companies) {
            dispatch(updateCurrentUserCompanies(companies))
        },
        updateCurrentUserActiveCompany(active_company) {
            dispatch(updateCurrentUserActiveCompany(active_company))
        },
        updateCurrentUserEmployerRoles(employer_roles) {
            dispatch(updateCurrentUserEmployerRoles(employer_roles))
        },
        updateCurrentUserIsReferralUser(is_referral_user) {
            dispatch(updateCurrentUserIsReferralUser(is_referral_user))
        },
        updateCurrentUserIsEmployerUser(is_employer_user) {
            dispatch(updateCurrentUserIsEmployerUser(is_employer_user))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(RedirectFromForgotPasswordMail));

export const IndexPageWrapper = withRouter(connect(
    state => ({
        site_state: state.site_state,
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
        ads: state.ads
    }),
    dispatch => ({
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        addCurrentUserReferralCode(referral_code) {
            dispatch(addCurrentUserReferralCode(referral_code))
        },
        updateSearchKeywords(keyword, keyword_place) {
            dispatch(updateSearchKeywords(keyword, keyword_place))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        updateAdCornerModal(ad_corner_modal) {
            dispatch(updateAdCornerModal(ad_corner_modal))
        },
    })
)(IndexPage));

export const EmployerPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
    }),
    dispatch => ({
        addCurrentUserReferralCode(referral_code) {
            dispatch(addCurrentUserReferralCode(referral_code))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        updateSearchKeywords(keyword, keyword_place) {
            dispatch(updateSearchKeywords(keyword, keyword_place))
        },
    })
)(EmployerPage));

export const JobsPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        search_words: state.search_words,
        ads: state.ads,
    }),
    dispatch => ({
        updateKeepSearchBar(keep_search_bar) {
            dispatch(updateKeepSearchBar(keep_search_bar))
        },
        updateSearchBar(search_bar) {
            dispatch(updateSearchBar(search_bar))
        },
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        updateSearchKeywords(keyword, keyword_place) {
            dispatch(updateSearchKeywords(keyword, keyword_place))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        updateAdCoverModal(ad_cover_modal) {
            dispatch(updateAdCoverModal(ad_cover_modal))
        },
    })
)(JobsPage));

export const JobPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        ads: state.ads,
    }),
    dispatch => ({
        updateApplyModal(apply_modal, job, referrer) {
            dispatch(updateApplyModal(apply_modal, job, referrer))
        },
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        addCurrentUserReferralCode(referral_code) {
            dispatch(addCurrentUserReferralCode(referral_code))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        updateInviteToApplyModal(apply_modal, job) {
            dispatch(updateInviteToApplyModal(apply_modal, job))
        },
        addFlashMessage(open, text, status, display_type, action_button_text) {
            dispatch(addFlashMessage(open, text, status, display_type, action_button_text))
        },
    })
)(JobPage));

export const JobInvitationPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
    }),
    dispatch => ({
        updateApplyModal(apply_modal, job, referrer) {
            dispatch(updateApplyModal(apply_modal, job, referrer))
        },
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        addCurrentUserReferralCode(referral_code) {
            dispatch(addCurrentUserReferralCode(referral_code))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        updateInviteToApplyModal(apply_modal, job) {
            dispatch(updateInviteToApplyModal(apply_modal, job))
        },
        addFlashMessage(open, text, status, display_type, action_button_text) {
            dispatch(addFlashMessage(open, text, status, display_type, action_button_text))
        },
    })
)(JobInvitationPage));

export const JobInfoWrapper = withRouter(connect(
    null,
    dispatch => ({
        updateSearchKeywords(keyword, keyword_place) {
            dispatch(updateSearchKeywords(keyword, keyword_place))
        },
    })
)(JobInfo));

export const ReferPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    }),
)(ReferPage));

export const ReferralLandingPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
    }),
    dispatch => ({
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
    }),
)(ReferralLandingPage));

export const EmployerLandingPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user
    }),
    dispatch => ({
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
    }),
)(EmployerLandingPage));

export const ResetPasswordPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
    }),
    dispatch => ({
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        openFlashMessage(id) {
            dispatch(openFlashMessage(id))
        }
    })
)(ResetPasswordPage));

export const ForgotPasswordPageWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth
    }),
    dispatch => ({
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(ForgotPasswordPage));

export const EntranceModalWrapper = connect(
    state => ({
        site_state: state.site_state
    }),
    dispatch => ({
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
    })
)(EntranceModal);

export const LogInFormWrapper = withRouter(connect(
    state => ({
        site_state: state.site_state,
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
        referral_codes: state.current_user.referral_codes,
        utm: state.utm,
    }),
    dispatch => ({
        verifyTokenRequestSucceeded(userAttributes) {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
                payload: {
                    userAttributes,
                },
            })
        },
        verifyTokenRequestFailed() {
            dispatch({
                type: VERIFY_TOKEN_REQUEST_FAILED
            })
        },
        updateCurrentUserCompanies(companies) {
            dispatch(updateCurrentUserCompanies(companies))
        },
        updateCurrentUserActiveCompany(active_company) {
            dispatch(updateCurrentUserActiveCompany(active_company))
        },
        updateCurrentUserEmployerRoles(employer_roles) {
            dispatch(updateCurrentUserEmployerRoles(employer_roles))
        },
        updateCurrentUserIsReferralUser(is_referral_user) {
            dispatch(updateCurrentUserIsReferralUser(is_referral_user))
        },
        updateCurrentUserIsEmployerUser(is_employer_user) {
            dispatch(updateCurrentUserIsEmployerUser(is_employer_user))
        },
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(LogInForm));

export const SignUpFormWrapper = withRouter(connect(
    state => ({
        site_state: state.site_state,
        reduxTokenAuth: state.reduxTokenAuth,
        referral_codes: state.current_user.referral_codes,
        utm: state.utm,
    }),
    dispatch => ({
        updateEntranceFormModal(entrance_form_modal) {
            dispatch(updateEntranceFormModal(entrance_form_modal))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(SignUpForm));

// export const GoReferModalWrapper = connect(
//     state => ({
//         site_state: state.site_state
//     }),
//     dispatch => ({
//         updateGoReferModal(go_refer_modal) {
//             dispatch(updateGoReferModal(go_refer_modal))
//         }
//     })
// )(GoReferModal);

export const ApplyModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        site_state: state.site_state,
        current_user: state.current_user,
        utm: state.utm,
    }),
    dispatch => ({
        updateApplyModal(apply_modal, job, referrer) {
            dispatch(updateApplyModal(apply_modal, job, referrer))
        },
        updateCurrentUserReferralCodes(referral_codes) {
            dispatch(updateCurrentUserReferralCodes(referral_codes))
        },
        addCurrentUserReferralCode(referral_code) {
            dispatch(addCurrentUserReferralCode(referral_code))
        },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
    })
)(ApplyModal));

export const InviteToApplyModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        site_state: state.site_state,
        current_user: state.current_user
    }),
    dispatch => ({
        updateInviteToApplyModal(apply_modal, job) {
            dispatch(updateInviteToApplyModal(apply_modal, job))
        },
        // updateCurrentUserReferralCodes(referral_codes) {
        //     dispatch(updateCurrentUserReferralCodes(referral_codes))
        // },
        // addCurrentUserReferralCode(referral_code) {
        //     dispatch(addCurrentUserReferralCode(referral_code))
        // },
        updateConnectionOffModal(connection_off_modal) {
            dispatch(updateConnectionOffModal(connection_off_modal))
        },
        addFlashMessage(open, text, status, display_type, action_button_text) {
            dispatch(addFlashMessage(open, text, status, display_type, action_button_text))
        },
        removeFlashMessage(id) {
            dispatch(removeFlashMessage(id))
        },
    })
)(InviteToApplyModal));

export const CookiePolicyCornerModalWrapper = withRouter(connect(
    state => ({
        reduxTokenAuth: state.reduxTokenAuth,
        current_user: state.current_user,
    }),
    dispatch => ({
        updateCookiePolicyCornerModal(accept) {
            dispatch(updateCookiePolicyCornerModal(accept))
        },
    })
)(CookiePolicyCornerModal));

export const AdCornerModalWrapper = connect(
    state => ({
        ad_corner_modal: loGet(state, 'site_state.ad_corner_modal'),
        ads: state.ads,
    }),
    null
)(AdCornerModal);

export const AdCoverModalWrapper = connect(
    state => ({
        ad_cover_modal: loGet(state, 'site_state.ad_cover_modal'),
        current_user: state.current_user,
        ads_cover: state.ads_cover,
    }),
    dispatch => ({
        updateCoverAdsModalDismiss(ad_dismissed) {
            dispatch(updateCoverAdsModalDismiss(ad_dismissed))
        },
    })

)(AdCoverModal);
