import React, {Component} from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import {withNamespaces} from "react-i18next";

class SaveCardForm extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.update = this.update.bind(this);
        this.state = {
            updating: false,
            error_message: ''
        };
    };

    async submit(e) {
        e.preventDefault();
        let { token } = await this.props.stripe.createToken();
        if(token) {
            this.setState((prev_state) => ({
                ...prev_state,
                updating: false
            }));
            this.props.handleCardDetail(token);
        }
    };

    update(e) {
        e.preventDefault();
        this.setState({ updating: true });
    };

    render() {
        const { submit, update } = this;
        const { updating } = this.state;
        const { payment } = this.props;
        const { t } = this.props;

        return (
            <div className="stripe-element-wrapper">
                {
                    (!(payment && payment.token) || updating) ?
                        <div>
                            <p>{t('credit_or_debit_card')}</p>
                            <CardElement />
                            <button
                                onClick={ e => submit(e) }
                                className={`btn btn-larger btn-flat btn-fill add ${!(this.props.stripe) ? 'disabled' : ''}`}
                            >
                                <h5>{t('general:add')}</h5>
                            </button>
                        </div> :
                        <div>
                            <p>{t('current_card_')} ****-****-****-{(payment.last4) ? (payment.last4) : '****'}</p>
                            <button
                                onClick={ e => update(e) }
                                className={`btn btn-larger btn-flat btn-fill update ${!(this.props.stripe) ? 'disabled' : ''}`}
                            >
                                <h5>{t('general:update')}</h5>
                            </button>
                        </div>
                }
            </div>
        );
    };
}

SaveCardForm.defaultProps = {
    handleCardDetail: f=>f
};

export default injectStripe(withNamespaces(['dashboard_payment_page', 'general'])(SaveCardForm));
