export default {
	your_email_account_has_been_used_to_register_meet_jobs__we_suggest_resetting_your_password_right_away_:
		"您的 Email 先前已被使用來註冊 Meet.jobs，建議您立即重設帳戶密碼",
	reset_password: "重設密碼",
	welcome_: "你好，",
	you_re_all_set_: "註冊已完成！",
	start_to_search_and_view_all_jobs: "開始搜尋並瀏覽所有工作",
	be_a_referrer_and_get_reward: "成為推薦人，並賺取推薦感謝金",
	know_more_about_employer_plans: "了解我們的企業徵才方案",
	see_all_jobs: "瀏覽全球工作機會",
	see_plans: "徵才方案表",
	upload_for_better_service: "上傳履歷，讓我們為您提供更好的服務！",
	benefit_of_uploading_resume: "上傳履歷的好處",
	only_need_upload_title: "直接上傳，無需填寫",
	free_consoltant_title: "免費職涯諮詢服務",
	let_employer_see_title: "推薦優質工作機會",
	only_need_upload:
		"在 Meet.jobs 您無需再填寫履歷，只需上傳檔案或填寫外部履歷連結即可。",
	free_consoltant_1: "上傳履歷可以獲得一次",
	free_consultation: "免費職涯諮詢服務",
	free_consoltant_2: "，歡迎線上預約。",
	enable: "透過",
	open_resume: "「開放履歷」",
	let_employer_see: "讓專業獵頭與企業直接推薦您優質工作機會。",
	upload_for_reason: "上傳履歷，讓我們為您提供更好的服務！",
};