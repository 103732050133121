import React, { Fragment } from "react";
import Select from "react-select";
import Slider from "react-slick/lib";
import ReactTable from "react-table";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment/moment";
import metas from "../../../models/metas.js";
import Config from "../../../models/config.js";
import Job from "../../../models/job";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Employer from "../../../models/employer.js";
import {
  compare,
  get_options,
  page_smothly_scroll_to,
  removeCommasToNumber,
} from "../../../../assets/js/functions.js";
import { CSSTransitionGroup } from "react-transition-group";
import Editor from "../Editor";
import { withNamespaces, Interpolate } from "react-i18next";
import { Link } from "react-router-dom";
import plan_post from "../../../../assets/images/i010-plan_01.svg";
import plan_hire from "../../../../assets/images/i011-plan_02.svg";
import plan_hire_plus from "../../../../assets/images/i012-plan_03.svg";
import icon_arrow from "../../../../assets/images/icon/icon_24_arrow_left_BG400.svg";
import InternalUri from "../../../models/internal_uri";
import { isNumeral } from "../../../../assets/js/validators";
import InputField from "../../general/InputField";
import { numeral, required } from "../../../../assets/js/validations";
import Salary from "../../../../assets/js/formats/salary";
import JobFunction from "../../../models/job_function";
import JobFunctionModal from "../modals/JobFunctionModal";
import { ReactComponent as IconSpeedInterview } from "../../../../assets/images/icon/speed_interview.svg";
import blank_job from "../../../../assets/images/i027-blank_post.svg";
import Pagination from "../../general/Pagination";

const config = new Config();
const headers = new ApiHeaders();
const salary = new Salary();

const jobFunctionGroup = [
  [13, 5, 4, 3, 6, 7, 39, 17, 10, 40],
  [18, 31, 53, 52],
  [11, 12, 43, 42, 41],
  [23, 8, 49, 20, 50, 24, 51],
  [9, 44, 45, 16, 47, 46, 48],
  [22],
  [21],
  [25],
  [54],
  [26],
];

class PlansPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.handleRadioChange = this.handleRadioChange.bind(this);

    // check pending data
    this.checkPendingData = this.checkPendingData.bind(this);

    // actions bar
    this.getFormData = this.getFormData.bind(this);
    // this.saveDefaultPlan = this.saveDefaultPlan.bind(this);

    this.state = {
      active_company_id: -1,
      creator_user_id: -1,
      form_data: { default_plan_name: "post_only" },
      original_form_data: {
        default_plan_name: "post_only",
      },
      has_pending_data: false,
      options: {},
      error_messages: {},
      loading: true,
      showJobFunctions: false,
      data: {},
      active_tab_index: 0,
      contract_url: "",
    };

    this.defaultParameter = {
      paginator: { current_page: 1 },
      criteria: { name: "updated_at", sortBy: "desc" },
    };

    this.validateMethods = {};
  }

  componentDidMount() {
    this._isMounted = true;

    let options = {
      work_types: get_options("", {
        full_time: "states:full_time",
        part_time: "states:part_time",
        internship: "states:internship",
        volunteer: "states:volunteer",
      }),
      paid_periods: get_options("", {
        annually: "states:annually",
        monthly: "states:monthly",
        daily: "states:daily",
        hourly: "states:hourly",
      }),
      contract_types: get_options("", {
        permanent: "states:permanent",
        contract: "states:contract",
      }),
    };

    const { current_user } = this.props;
    // this.setState((prev_state) => ({
    //   ...prev_state,
    //   access_control_list:
    //     current_user.employer_roles[current_user.active_company.id]
    //       .access_control_list,
    // }));

    let axiosOptions = {
      method: "GET",
      // url: config.api_url(`/job_meta/currency_types`),
      url: config.api_url_v2(
        `/employers/${current_user.active_company.id}/plans`
      ),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(axiosOptions)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          // interface response.data = {
          //   "id": 2187,
          //   "name": "custom_referral",
          //   "plan_state": "chosen",
          //   "defaulted": true,
          //   "probation_days": 0,
          //   "referralable": true,
          //   "matchable": true,
          //   "marketable": true,
          //   "recruitable": true,
          //   "consultable": true,
          //   "next_payment_at": null,
          //   "legal_activated_at": null,
          //   "expired_at": null,
          //   "contract_url": ""
          // }[]

          let form_data = {
            default_plan_name:
              response.data &&
              response.data.find((item) => item.defaulted) &&
              response.data.find((item) => item.defaulted).name
                ? response.data.find((item) => item.defaulted).name
                : "post_only",
          };

          this.setState((prev_state) => ({
            ...prev_state,
            form_data: JSON.parse(JSON.stringify(form_data)),
            original_form_data: JSON.parse(JSON.stringify(form_data)),
            has_pending_data: false,
            error_messages: {},
            loading: false,
            contract_url: response.data.contract_url,
          }));
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleRadioChange(e, property_name, value) {
    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: {
          ...prev_state.form_data,
          [property_name]: value,
        },
      }),
      () => this.checkPendingData()
    );
  }

  //check pending data

  checkPendingData() {
    const { form_data, original_form_data } = this.state;
    const has_pending_data = !compare(
      JSON.parse(JSON.stringify(form_data)),
      original_form_data
    );
    this.setState({ has_pending_data });
  }

  acceptedValidateMethods(key, validationMethod) {
    this.validateMethods[key] = validationMethod;
  }

  validateAll = () => {
    Object.keys(this.validateMethods).map((key) => this.validateMethods[key]());

    // job functions checker
    const { t } = this.props;
    const countOfSelectedJobFunction = this.state.form_data
      .job_functions_selected_values.length;
    let job_functions_errors = [];
    if (countOfSelectedJobFunction <= 0)
      job_functions_errors.push(t("error_messages:required"));
    if (countOfSelectedJobFunction > 3)
      job_functions_errors.push(
        t("error_messages:selectMaximumLimit", { number: 3 })
      );
    if (job_functions_errors.length) {
      this.setState((prevState) => ({
        ...prevState,
        error_messages: {
          ...prevState.error_messages,
          job_functions: job_functions_errors,
        },
      }));
    }
  };

  hasErrors = () => {
    const { error_messages } = this.state;
    let hasErrors = false;
    const whiteList = ["full_message", "requiring_skills.skill"];
    for (let key in error_messages) {
      if (whiteList.indexOf(key) === -1 && error_messages[key].length)
        hasErrors = true;
    }
    return hasErrors;
  };

  moveToErrorMessage = () => {
    const el = document.querySelector(".message.error-message");
    el && window.scrollTo(0, el.offsetTop - 300);
  };

  // actions bar

  getFormData = async () => {
    let formData = new FormData();
    const { form_data } = this.state;

    formData.append("job[plan_name]", form_data.plan_name);

    this.setState((prev_state) => ({
      ...prev_state,
      form_data,
    }));
    return formData;
  };

  // saveDefaultPlan = (e) => {
  //   e.preventDefault();

  //   this.setState((prev_state) => ({
  //     ...prev_state,
  //     loading: true,
  //   }));

  //   let formData = new FormData();
  //   formData.append(
  //     "setting[default_plan_name]",
  //     this.state.form_data.default_plan_name
  //   );
  //   let options = {
  //     method: "PUT",
  //     url: config.api_url(
  //       `/employers/${this.props.current_user.active_company.id}/setting`
  //     ),
  //     headers: headers.getItemsFromLocalStorage("multipart/form-data"),
  //     data: formData,
  //     json: true,
  //   };
  //   return axios(options)
  //     .then((response) => {
  //       headers.updateItemsToLocalStorage(response.headers);
  //       this.props.updateConnectionOffModal(false);
  //       // interface response.data = {
  //       //   "id": 2,
  //       //   "onboard_reward": false,
  //       //   "locale": "en",
  //       //   "priority": 2,
  //       //   "review_resume": false,
  //       //   "default_plan_name": "post_only"
  //       // }

  //       let form_data = {
  //         default_plan_name:
  //           response.data && response.data.default_plan_name
  //             ? response.data.default_plan_name
  //             : "post_only",
  //       };

  //       this.setState((prev_state) => ({
  //         ...prev_state,
  //         form_data: JSON.parse(JSON.stringify(form_data)),
  //         original_form_data: JSON.parse(JSON.stringify(form_data)),
  //         has_pending_data: false,
  //         error_messages: {},
  //         loading: false,
  //       }));
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         headers.updateItemsToLocalStorage(error.response.headers);
  //         if (error.response.status === 401) window.location.reload();
  //       }

  //       if (this._isMounted) {
  //         if (typeof error.response === "undefined") {
  //           window.location.reload();
  //           this.props.updateConnectionOffModal(true);
  //         }

  //         this.setState((prev_state) => ({
  //           ...prev_state,
  //           loading: false,
  //           error_messages:
  //             error.response &&
  //             error.response.data &&
  //             error.response.data.status === "error"
  //               ? error.response.data.errors
  //               : {
  //                   full_message: "There was an error. Please try again later.",
  //                 },
  //         }));
  //       }

  //       return null;
  //     });
  // };

  handleTabChange(tab_index) {
    this.setState({ active_tab_index: tab_index });
  }

  toggleUpdateDefaultPlanModal = (e, plan_name) => {
    e.preventDefault();
    this.props.updateUpdateDefaultPlanModal(
      true,
      plan_name,
      this.state.contract_url
    );
  };

  render() {
    const {
      creator_user_id,
      access_control_list,
      form_data,
      original_form_data,
      options,
      has_pending_data,
      loading,
      error_messages,
      showJobFunctions,
      data,
      active_tab_index,
    } = this.state;

    const {
      handleRadioChange,
      saveDefaultPlan,
      toggleUpdateDefaultPlanModal,
    } = this;
    const { reduxTokenAuth } = this.props;
    const { t } = this.props;
    const internal_uri = new InternalUri();

    const has_errors = this.hasErrors();

    if (loading) {
      return (
        <div className="dashboard-applications blank">
          <div className="container-fluid">{`${t("general:loading")}...`}</div>
        </div>
      );
    }

    return (
      <div className="dashboard-plans test-note">
        <div className="container-fluid">
          <form>
            <h6 className="breadcrumb">
              <Link to={internal_uri.formatted_dashboard_index_page_path()}>
                {t("general:home")}
              </Link>
              {" / "}
              <Link
                to={internal_uri.formatted_dashboard_plans_page_path()}
                // onClick={() => {
                //   form_data.default_plan_name = "";
                // }}
              >
                {t("general:plans")}
              </Link>
            </h6>

            <h2>
              {t("general:default_plan")}
              {": "}
              {
                {
                  post_only: t("general:plan_post"),
                  social_referral: t("general:plan_hire"),
                  custom_referral: t("general:plan_hire_"),
                }[original_form_data.default_plan_name]
              }
            </h2>

            <h4>{t("general:default_plan_intro")}</h4>

            {/* {!form_data.default_plan_name && ( */}
            <div className="plans-wrapper">
              {error_messages.full_message ? (
                <h6 className="message error-message spacer">
                  {error_messages.full_message}
                </h6>
              ) : (
                ""
              )}
              <div className="plans block">
                <div className="plan-wrapper">
                  <input
                    name="plan"
                    checked={
                      original_form_data.default_plan_name === "post_only"
                    }
                    type="radio"
                    className="radio-input"
                    readOnly
                  />
                  <label
                    htmlFor="post_only"
                    className="radio-plan"
                    // onClick={(e) =>
                    //   handleRadioChange(e, "default_plan_name", "post_only")
                    // }
                  >
                    <div className="block plan plan-hire">
                      <h4>{t("general:plan_post")}</h4>
                      <div
                        className="image-wrapper"
                        style={{
                          backgroundImage: `url(${plan_post})`,
                        }}
                      >
                        {/*<span*/}
                        {/*    className="recommend">{t('recommend').toUpperCase()}</span>*/}
                      </div>
                      <div className="prices">
                        <h3>
                          <del style={{ marginRight: 8 }}>
                            {t("general:_175__usd_cost")}
                          </del>
                          <strong>{t("general:_175__usd_free")}</strong>
                        </h3>
                        <h5>{t("general:per_month_subscription_fee")}</h5>
                      </div>
                      <hr />
                      <p>{t("general:exposure_160_countries")}</p>
                      <p>{t("general:no_contracts_pay_usage")}</p>
                      <p>
                        {t("general:friendly_interface_managing_recruitment")}
                      </p>

                      <div
                        className="btn btn-larger btn-flat btn-hollow btn-call-to-action"
                        onClick={(e) => {
                          if (
                            original_form_data.default_plan_name === "post_only"
                          )
                            return;
                          toggleUpdateDefaultPlanModal(e, "post_only");
                        }}
                      >
                        {original_form_data.default_plan_name ===
                        "post_only" ? (
                          <h5 className="content">
                            {t("general:default_plan")}
                          </h5>
                        ) : (
                          <h5 className="content">
                            {t("general:select_plan")}
                          </h5>
                        )}
                      </div>
                    </div>
                  </label>
                </div>

                <div className="plan-wrapper">
                  <input
                    name="plan"
                    checked={form_data.default_plan_name === "social_referral"}
                    type="radio"
                    className="radio-input"
                    readOnly
                  />
                  <label
                    htmlFor="social_referral"
                    className="radio-plan"
                    // onClick={(e) =>
                    //   handleRadioChange(
                    //     e,
                    //     "default_plan_name",
                    //     "social_referral"
                    //   )
                    // }
                  >
                    <div className="block plan plan-hire">
                      <h4>{t("general:plan_hire")}</h4>
                      <div
                        className="image-wrapper"
                        style={{
                          backgroundImage: `url(${plan_hire})`,
                        }}
                      >
                        {/*<span*/}
                        {/*    className="recommend">{t('recommend').toUpperCase()}</span>*/}
                      </div>
                      <div className="prices">
                        <h3>{t("general:_16__annual_salary")}</h3>
                        <h5>{t("general:per_successful_hire")}</h5>
                      </div>
                      <hr />
                      <p>{t("general:commnuity_referral_quick_and_precise")}</p>
                      <p>
                        {t("general:emplouyer_brandind_and_dynamic_promotion")}
                      </p>
                      <p>
                        {t(
                          "general:integrated_marketing_and_speed_interview_event"
                        )}
                      </p>

                      <div
                        className="btn btn-larger btn-flat btn-hollow btn-call-to-action"
                        onClick={(e) => {
                          if (
                            original_form_data.default_plan_name ===
                            "social_referral"
                          )
                            return;
                          toggleUpdateDefaultPlanModal(e, "social_referral");
                        }}
                      >
                        {original_form_data.default_plan_name ===
                        "social_referral" ? (
                          <h5 className="content">
                            {t("general:default_plan")}
                          </h5>
                        ) : (
                          <h5 className="content">
                            {t("general:select_plan")}
                          </h5>
                        )}
                      </div>
                    </div>
                  </label>
                </div>

                <div className="plan-wrapper">
                  <input
                    name="plan"
                    checked={form_data.default_plan_name === "custom_referral"}
                    type="radio"
                    className="radio-input"
                    readOnly
                  />
                  <label
                    htmlFor="custom_referral"
                    className="radio-plan"
                    // onClick={(e) =>
                    //   handleRadioChange(
                    //     e,
                    //     "default_plan_name",
                    //     "custom_referral"
                    //   )
                    // }
                  >
                    <div className="block plan plan-custom">
                      <h4>{t("general:plan_hire_")}</h4>
                      <div
                        className="image-wrapper"
                        style={{
                          backgroundImage: `url(${plan_hire_plus})`,
                        }}
                      />
                      <div className="prices clearfix">
                        <h3>{t("general:_20__annual_salary")}</h3>
                        <h5>{t("general:per_successful_hire")}</h5>
                      </div>
                      <hr />
                      {/*<h6>{t('general:everything_in_hire_plan')}</h6>*/}
                      {/*<p>{t('general:recruitment_consulting_service')}</p>*/}
                      <p>{t("general:everything_in_social_plan")}</p>
                      <p>{t("general:professional_hunter_search")}</p>
                      <p>{t("general:double_screening_ai")}</p>
                      <p>
                        {t(
                          "general:professional_slill_language_adaptive_tests"
                        )}
                      </p>
                      <p>{t("general:customized_service_contract")}</p>

                      <div
                        className="btn btn-larger btn-flat btn-hollow btn-call-to-action"
                        onClick={(e) => {
                          if (
                            original_form_data.default_plan_name ===
                            "custom_referral"
                          )
                            return;
                          toggleUpdateDefaultPlanModal(e, "custom_referral");
                        }}
                      >
                        {original_form_data.default_plan_name ===
                        "custom_referral" ? (
                          <h5 className="content">
                            {t("general:default_plan")}
                          </h5>
                        ) : (
                          <h5 className="content">
                            {t("general:select_plan")}
                          </h5>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            {/* )} */}

            {/* <CSSTransitionGroup
              component="div"
              transitionName="actions-bar-slide"
              transitionEnterTimeout={200}
              transitionLeaveTimeout={100}
            >
              {has_pending_data ? (
                <div className="actions-bar-wrapper">
                  <div className="actions-bar">
                    <p>　</p>
                    <div className="buttons-wrapper">
                      <button
                        className="btn btn-larger btn-flat btn-hollow"
                        onClick={(e) => {
                          saveDefaultPlan(e);
                        }}
                      >
                        <h5>{t("general:save_default_plan")}</h5>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </CSSTransitionGroup> */}
          </form>
        </div>
      </div>
    );
  }
}

export default withNamespaces([
  "dashboard_plans_page",
  "general",
  "states",
  "error_messages",
])(PlansPage);
