import React, { useEffect, useRef, useState } from "react";
import { useStateWithCallback } from "../../../../customHooks/useStateWithCallback.js";
import Select from "react-select";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Metas from "../../../models/metas";
import Pagination from "../../general/Pagination";
import JobApplication from "../../../models/job_application.js";
import Job from "../../../models/job.js";
import {
	get_value_from_query_string,
	page_smothly_scroll_to,
	smoothly_scroll_to,
} from "../../../../assets/js/functions.js";
import { FaRegQuestionCircle } from "react-icons/fa";
import { withNamespaces } from "react-i18next";
import icon_message_w from "../../../../assets/images/icon/icon_24_message_white.svg";
import icon_message_b from "../../../../assets/images/icon/icon_24_message_B100.svg";
import icon_lock from "../../../../assets/images/icon/icon_24_lock_BG400.svg";
import Employer from "../../../models/employer";
import blank_application from "../../../../assets/images/i017-blank_application.svg";
import blank_job from "../../../../assets/images/i027-blank_post.svg";
import icon_redirect from "../../../../assets/images/icon/icon_24_redirect_B100.svg";
import icon_recommend from "../../../../assets/images/icon/icon_24_recommend_G100.svg";
import icon_not_recommend from "../../../../assets/images/icon/icon_24_not_recommend_Y200.svg";
import icon_arrow from "../../../../assets/images/icon/icon_24_arrow_left_BG400.svg";
import InternalUri from "../../../models/internal_uri";
import { ReactComponent as IconSpeedInterview } from "../../../../assets/images/icon/speed_interview.svg";
import { ReactComponent as IconArrow } from "../../../../assets/images/icon/icon_28_arrow_right_B100.svg";

const config = new Config();
const headers = new ApiHeaders();

class ApplicationsPage extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.updateActiveCompany = this.updateActiveCompany.bind(this);
		this.fetchJobs = this.fetchJobs.bind(this);
		this.fetchApplications = this.fetchApplications.bind(this);
		this.handleJobFilterSelectChange = this.handleJobFilterSelectChange.bind(
			this,
		);
		this.handleCurrentJobChange = this.handleCurrentJobChange.bind(this);
		this.openJobsMenu = this.openJobsMenu.bind(this);
		this.handleEmployerMessageModal = this.handleEmployerMessageModal.bind(
			this,
		);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.toggleClosedJobsSection = this.toggleClosedJobsSection.bind(this);
		this.setCurrentPage = this.setCurrentPage.bind(this);
		this.onSortedChange = this.onSortedChange.bind(this);
		this.state = {
			isReFetching: true,
			active_company_id: -1,
			active_company: {},
			active_job_id: -1,
			selectedOption: "",
			active_tab_index: 0,
			open_jobs_menu: true,
			data: {},
			data_sidebar: {
				keepExpandingSection: [],
				closedJobsSection: [],
			},
			isClosedJobsSectionExpanded: false,
			metas: {},
			loading: true,
			changing_page: false,
			error_messages: {},
			selected_option_job_filter: "0",
			search_key: "",
		};
		this.paginator = {};
		this.defaultParameter = {
			applying: {
				paginator: { current_page: 1 },
				criteria: { name: "updated_at", sortBy: "desc" },
			},
			rejected: {
				paginator: { current_page: 1 },
				criteria: { name: "updated_at", sortBy: "desc" },
			},
			cancelled: {
				paginator: { current_page: 1 },
				criteria: { name: "updated_at", sortBy: "desc" },
			},
			confirmed: {
				paginator: { current_page: 1 },
				criteria: { name: "updated_at", sortBy: "desc" },
			},
		};
		this.parameters = {};
		this.state.isReFetching = true;
	}

	componentDidMount() {
		this._isMounted = true;
		page_smothly_scroll_to(0, 300);

		const query = decodeURI(this.props.location.search);
		if (query) {
			let active_job_id = parseInt(
				get_value_from_query_string("job", query),
				10,
			);
			if (active_job_id) {
				this.setState({ active_job_id });
			}

			let message_modal_open = get_value_from_query_string(
				"message_modal_open",
				query,
			);
			if (message_modal_open) {
				let job_id = parseInt(get_value_from_query_string("job_id", query), 10);
				let employer_id = parseInt(
					get_value_from_query_string("employer_id", query),
					10,
				);
				let job_application_id = parseInt(
					get_value_from_query_string("job_application_id", query),
					10,
				);

				const { current_user } = this.props;
				this.setState(
					(prev_state) => ({
						...prev_state,
						active_company_id: employer_id,
						active_company: new Employer(current_user.active_company),
						active_job_id: job_id,
					}),
					() => {
						this.props.updateEmployerMessageModal(
							true,
							employer_id,
							job_id,
							job_application_id,
						);
						if (
							this.state.metas.application_states &&
							Object.keys(this.state.metas.application_states).length > 0 &&
							this.parameters[this.state.active_job_id]
						) {
							this.fetchApplications();
						}
					},
					// this.fetchApplications(),
				);
			}
		}

		let metas = {
			application_states: new Metas("application_states", {
				applying: "應徵中",
				rejected: "已拒絕",
				cancelled: "已取消",
				confirmed: "已錄取",
			}),
		};
		this.setState(
			(prev_state) => ({
				...prev_state,
				metas,
				loading: true,
			}),
			() => this.updateActiveCompany(this.props.current_user.active_company),
		);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	updateActiveCompany(company) {
		const { current_user } = this.props;
		this.parameters = {};
		if (
			current_user.employer_roles[company.id].access_control_list
				.job_applications_manageable
		) {
			this.setState(
				(prev_state) => ({
					...prev_state,
					active_company_id: company.id,
					active_company: new Employer(company),
					access_control_list:
						current_user.employer_roles[company.id].access_control_list,
					data: {},
					loading: true,
				}),
				() => this.fetchJobs(),
			);
		} else {
			this.setState((prev_state) => ({
				...prev_state,
				active_company_id: company.id,
				active_company: new Employer(company),
				access_control_list:
					current_user.employer_roles[company.id].access_control_list,
				data: {},
				loading: false,
			}));
		}
	}

	fetchJobs() {
		let options;
		let keys = ["published", "closed"];
		let promises_jobs = [];
		for (let i = 0; i < keys.length; i++) {
			options = {
				method: "GET",
				url: config.api_url(
					`/employers/${this.state.active_company_id}/jobs/state/${keys[i]}?per_page=99999&include=applying_job_applications_count&criteria[0][name]=updated_at&criteria[0][sort_by]=desc`,
				),
				headers: headers.getItemsFromLocalStorage(),
				json: true,
			};
			promises_jobs.push(axios(options));
		}
		let jobs = [],
			data_sidebar = this.state.data_sidebar;
		axios
			.all(promises_jobs)
			.then((results) => {
				for (let i = 0; i < results.length; i++) {
					headers.updateItemsToLocalStorage(results[i].headers);
				}
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					let active_job_id_in_jobs = false;
					let active_job_is_closed_job = false;
					const { active_company, active_job_id } = this.state;
					const { t } = this.props;

					jobs["all"] = {
						job: {},
						title: t("all_applications"),
						referable: active_company.is_matchable(),
						applications: {},
					};
					this.parameters["all"] = JSON.parse(
						JSON.stringify(this.defaultParameter),
					);

					data_sidebar["keepExpandingSection"].push({
						id: "all",
						title: t("all_applications"),
						location: null,
						updated_at: 0,
						unread_applications: 0,
						state: null,
						has_event: false,
						event_title_key: "",
					});

					for (let i = 0; i < keys.length; i++) {
						const raw_jobs = results[i].data.collection;
						const section =
							keys[i] === "published"
								? "keepExpandingSection"
								: "closedJobsSection";

						for (let j = 0; j < raw_jobs.length; j++) {
							let job = new Job(raw_jobs[j]);

							jobs[job.id] = {
								job,
								title: job.formatted_title(),
								referable: active_company.is_matchable(),
								applications: {},
							};
							this.parameters[job.id] = JSON.parse(
								JSON.stringify(this.defaultParameter),
							);

							data_sidebar[section].push({
								id: job.id,
								title: job.title,
								location: job.formatted_primary_location(t),
								updated_at: job.updated_at,
								unread_applications: job.applying_job_applications_count,
								state: keys[i],
								has_event: job.has_event(),
								event_title_key: job.has_event()
									? job.events[0].get_i18n_key()
									: "",
							});

							if (job.id === active_job_id) {
								active_job_id_in_jobs = true;
								active_job_is_closed_job = keys[i] === "closed";
							}
						}
					}

					// data_sidebar.sort((a, b) => (a.updated_at - b.updated_at));

					if (jobs.length !== 0) {
						let active_job_id = "all";
						if (active_job_id_in_jobs) {
							active_job_id = this.state.active_job_id;
						}

						this.setState(
							(prev_state) => ({
								...prev_state,
								data: jobs,
								data_sidebar,
								isClosedJobsSectionExpanded: active_job_is_closed_job,
								active_job_id,
								error_messages: {},
								loading: false,
							}),
							() => {
								this.fetchApplications();

								let wrappers = document.querySelectorAll(
									".dashboard-applications-sidebar-wrapper ul.sidebar",
								);
								let elements = document.querySelectorAll(
									".dashboard-applications-sidebar-wrapper li.active",
								);
								smoothly_scroll_to(
									wrappers[0],
									elements[0].offsetTop - wrappers[0].offsetTop - 50,
									200,
								);
							},
						);
					} else {
						this.setState((prev_state) => ({
							...prev_state,
							data: {},
							data_sidebar: { keepExpandingSection: [], closedJobsSection: [] },
							active_job_id: -1,
							error_messages: {},
							loading: false,
						}));
					}
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));

					page_smothly_scroll_to(0, 300);
				}
			});
	}

	fetchApplications(keys = Object.keys(this.state.metas.application_states)) {
		// console.warn("fetchApplications keys", keys);
		const { active_company_id, active_job_id } = this.state;
		let options;
		let promises = [];
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			const { paginator, criteria } = this.parameters[active_job_id][key];
			options = {
				method: "GET",
				url:
					active_job_id === "all"
						? config.api_url(
								`/employers/${active_company_id}/job_applications?state=${key}&page=${paginator.current_page}&criteria[0][name]=${criteria.name}&criteria[0][sort_by]=${criteria.sortBy}`,
						  )
						: config.api_url(
								`/employers/${active_company_id}/jobs/${active_job_id}/job_applications/state/${key}?page=${paginator.current_page}&criteria[0][name]=${criteria.name}&criteria[0][sort_by]=${criteria.sortBy}`,
						  ),
				headers: headers.getItemsFromLocalStorage(),
				json: true,
			};
			promises.push(axios(options));
		}
		axios
			.all(promises)
			.then((results) => {
				for (let i = 0; i < results.length; i++) {
					headers.updateItemsToLocalStorage(results[i].headers);
				}
				this.props.updateConnectionOffModal(false);

				if (this._isMounted) {
					let data = this.state.data;
					for (let i = 0; i < keys.length; i++) {
						const key = keys[i];
						let raw_applications = results[i].data.collection;
						this.parameters[active_job_id][key].paginator =
							results[i].data.paginator;

						if (raw_applications.length > 0) {
							let applications = [];
							for (let j = 0; j < raw_applications.length; j++) {
								let application = new JobApplication(raw_applications[j]);

								applications.push({
									application: JSON.stringify(application),
									candidate: [JSON.stringify(application), key],
									talent_match: application.matching_state_key,
								});
							}
							data[active_job_id].applications[key] = applications;
						} else {
							data[active_job_id].applications[key] = [];
						}
					}
					this.setState((prev_state) => ({
						...prev_state,
						data,
						error_messages: {},
						changing_page: false,
					}));
				}
			})
			.catch((error) => {
				if (error.response) {
					// console.log(error.response);
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					this.setState((prev_state) => ({
						...prev_state,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
						changing_page: false,
					}));

					page_smothly_scroll_to(0, 300);
				}
			});
	}

	handleJobFilterSelectChange = (selected_option) => {
		if (selected_option) {
			this.setState({ selected_option });
		}
	};

	handleCurrentJobChange = (e, id) => {
		e.preventDefault();
		const { data } = this.state;
		if (Object.keys(data[id].applications).length) {
			this.setState(
				{
					active_job_id: id,
					open_jobs_menu: false,
				},
				() => {
					let wrappers = document.querySelectorAll(
						".dashboard-applications-sidebar-wrapper ul.sidebar",
					);
					let elements = document.querySelectorAll(
						".dashboard-applications-sidebar-wrapper li.active",
					);
					smoothly_scroll_to(
						wrappers[0],
						elements[0].offsetTop - wrappers[0].offsetTop - 50,
						200,
					);
				},
			);
		} else {
			this.parameters[id] = JSON.parse(JSON.stringify(this.defaultParameter));
			this.setState(
				{
					active_job_id: id,
					open_jobs_menu: false,
				},
				() => {
					this.fetchApplications();

					let wrappers = document.querySelectorAll(
						".dashboard-applications-sidebar-wrapper ul.sidebar",
					);
					let elements = document.querySelectorAll(
						".dashboard-applications-sidebar-wrapper li.active",
					);
					smoothly_scroll_to(
						wrappers[0],
						elements[0].offsetTop - wrappers[0].offsetTop - 50,
						200,
					);
				},
			);
		}
		page_smothly_scroll_to(0, 300);
		this.fetchApplications();
	};

	openJobsMenu(e) {
		e.preventDefault();
		this.setState({ open_jobs_menu: true });
		// this.fetchApplications();
	}

	handleEmployerMessageModal = (
		e,
		job_id,
		job_application_id,
		updateAppListFunction,
	) => {
		e.preventDefault();
		const employer_id = this.state.active_company_id;
		const internal_uri = new InternalUri();
		this.props.updateEmployerMessageModal(
			true,
			employer_id,
			job_id,
			job_application_id,
			updateAppListFunction,
		);
		this.props.history.push(
			`${internal_uri.formatted_dashboard_applications_page_path()}?message_modal_open=true&employer_id=${employer_id}&job_id=${job_id}&job_application_id=${job_application_id}`,
		);
		this.fetchApplications();
	};

	handleTabChange(tab_index) {
		this.setState({ active_tab_index: tab_index });
	}

	toggleClosedJobsSection = (e) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			isClosedJobsSectionExpanded: !prev_state.isClosedJobsSectionExpanded,
		}));
		this.fetchApplications();
	};

	setCurrentPage = (key, currentPage) => {
		page_smothly_scroll_to(0, 300);
		this.setState({ changing_page: true });
		const { active_job_id } = this.state;
		this.parameters[active_job_id][key].paginator.current_page = currentPage;
		this.fetchApplications([key]);
	};

	onSortedChange = (newSorted, key) => {
		if (newSorted && newSorted.length) {
			const { active_job_id } = this.state;
			this.parameters[active_job_id][key].criteria.name = newSorted[0].id;
			this.parameters[active_job_id][key].criteria.sortBy = newSorted[0].desc
				? "desc"
				: "asc";
			this.setCurrentPage(key, 1);
		}
	};
	interval;
	timeout = "";
	render() {
		clearInterval(this.interval);
		this.interval = setInterval(() => {
			if (
				this.state.metas.application_states &&
				Object.keys(this.state.metas.application_states).length > 0 &&
				this.parameters[this.state.active_job_id] &&
				this.state.isReFetching == true
			) {
				this.fetchApplications();
				this.state.isReFetching = false;
			}
			if (this.timeout == "") {
				this.timeout = setTimeout(() => {
					this.state.isReFetching = true;
					clearTimeout(this.timeout);
					this.timeout = "";
				}, 30000);
			}
		}, 5000);

		const {
			access_control_list,
			active_job_id,
			data,
			active_tab_index,
			open_jobs_menu,
			loading,
			metas,
			changing_page,
			data_sidebar,
			isClosedJobsSectionExpanded,
			error_messages,
		} = this.state;
		const {
			handleCurrentJobChange,
			openJobsMenu,
			handleEmployerMessageModal,
			handleTabChange,
			toggleClosedJobsSection,
		} = this;
		const { setCurrentPage, parameters } = this;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		if (loading) {
			return (
				<div className="dashboard-applications blank">
					<div className="container-fluid">{`${t("general:loading")}...`}</div>
				</div>
			);
		}

		if (!access_control_list.job_applications_manageable) {
			return (
				<div className="dashboard-applications">
					<div className="dashboard-applications-sidebar-wrapper">
						{/*<div className="sidebar-title">*/}
						{/*<h5>{t('job_title')}</h5>*/}
						{/*</div>*/}
						<ul className="sidebar">
							<li />
						</ul>
					</div>
					<div className="container-fluid">
						{t(
							"general:you_don_t_have_the_proper_privilege_level_to_visit_this_page_",
						)}
					</div>
				</div>
			);
		}

		const columns_pc = [
			{
				Header: () => <h5>{t("candidate")}</h5>,
				accessor: "candidate",
				id: "applier_name",
				Cell: (props) => {
					const application = new JobApplication(JSON.parse(props.value[0]));
					const key = props.value[1];
					const awaiting_confirmation =
						key === "applying" &&
						(application.is_reported() || application.is_claimed());
					const formatted_message_count =
						application.messages_count > 999
							? "999+"
							: application.messages_count;
					return (
						<div className="candidate-wrapper">
							{
								<div className="content">
									<div className="badges">
										{awaiting_confirmation ? (
											<span className="badge badge-green">
												{t("general:awaiting_confirmation")}
											</span>
										) : (
											""
										)}
										{application.has_referrer() ? (
											<span className="badge badge-green-reward tooltip-wrapper right">
												{t("general:referred")}
												<div className="tooltip">
													<h6
														dangerouslySetInnerHTML={{
															__html: t("referred_by__", {
																referrer_name: application.formatted_referrer_user_name(),
															}),
														}}
													/>
												</div>
											</span>
										) : (
											""
										)}
									</div>
									{application.has_event() ? (
										<div className="candidate-name-wrapper">
											<span className="icon-event tooltip-wrapper right">
												<IconSpeedInterview />
												<div className="tooltip">
													<h6>{t(application.events[0].get_i18n_key())}</h6>
												</div>
											</span>
											<h5 className="candidate-name">
												{application.formatted_applier_name()}
											</h5>
										</div>
									) : (
										<h5 className="candidate-name">
											{application.formatted_applier_name()}
										</h5>
									)}
									{active_job_id === "all" ? (
										<h6
											className="job-title"
											// add line clamp style
											// style={{
											// 	display: "block",
											// 	overflow: "hidden",
											// 	textOverflow: "ellipsis",
											// 	whiteSpace: "nowrap",
											// 	width: "100%",
											// }}
										>
											{application.job.title}
										</h6>
									) : (
										""
									)}
								</div>
							}
							<button
								className={`btn btn-smaller btn-flat ${
									application.has_unread_messages() ? "btn-fill" : "btn-ghost "
								}`}
								style={{ border: "1px solid" }}
								onClick={(e) =>
									handleEmployerMessageModal(
										e,
										application.job.id,
										application.id,
										this.fetchApplications(),
									)
								}>
								{/* show red dot when theres new comments  */}
								{application.current_user_status.unread.comments > 0 && (
									<div
										style={{
											borderRadius: "999px",
											backgroundColor: "red",
											width: "15px",
											height: "15px",
											position: "absolute",
											top: "-5px",
											right: "-5px",
											border: "1px solid white",
										}}></div>
								)}
								<h5>
									<img
										src={
											application.has_unread_messages()
												? icon_message_w
												: icon_message_b
										}
										alt=""
									/>
									{formatted_message_count}
								</h5>
							</button>
						</div>
					);
				},
				width: 390,
			},
			{
				Header: () => (
					<h5 className="match-header">
						{t("talent_match")}
						<div className="tooltip-wrapper">
							<FaRegQuestionCircle className="icon react-icon" />
							<div className="tooltip long-text">
								<h6
									dangerouslySetInnerHTML={{
										__html: t(
											"assessment_for_suitable_candidates_to_invite_to_interviews__the_specific_service_for_hire_and_hire_plus_plan_",
										),
									}}
								/>
							</div>
						</div>
					</h5>
				),
				accessor: "talent_match",
				id: "matching_state",
				Cell: (props) =>
					data[active_job_id].referable ? (
						<div className={`match-wrapper ${props.value}`}>
							<img className="recommended" src={icon_recommend} alt="" />
							<img
								className="not_recommended"
								src={icon_not_recommend}
								alt=""
							/>
							<h6>{t(`states:${props.value}`)}</h6>
						</div>
					) : (
						<div className="match-wrapper">
							<img className="locked" src={icon_lock} alt="" />
						</div>
					),
			},
			{
				Header: () => <h5>{t("general:applied")}</h5>,
				accessor: "application",
				id: "created_at",
				Cell: (props) => {
					const application = new JobApplication(JSON.parse(props.value));
					return (
						<div className="time-wrapper tooltip-wrapper">
							<h6 className="time">
								{application.formatted_applied_at_past_time(t)}
							</h6>
							<div className="tooltip">
								<h6>{application.formatted_applied_at()}</h6>
							</div>
						</div>
					);
				},
				width: 130,
			},
			{
				Header: () => <h5>{t("general:updated")}</h5>,
				accessor: "application",
				id: "updated_at",
				Cell: (props) => {
					const application = new JobApplication(JSON.parse(props.value));
					return (
						<div className="time-wrapper tooltip-wrapper">
							<h6 className="time">
								{application.formatted_updated_at_past_time(t)}
							</h6>
							<div className="tooltip">
								<h6>{application.formatted_updated_at()}</h6>
							</div>
						</div>
					);
				},
				width: 130,
			},
		];

		// 992px
		const columns_tl = [
			{
				Header: () => <h5>{t("candidate")}</h5>,
				accessor: "candidate",
				id: "updated_at",
				Cell: (props) => {
					const application = new JobApplication(JSON.parse(props.value[0]));
					const key = props.value[1];
					const awaiting_confirmation =
						key === "applying" &&
						(application.is_reported() || application.is_claimed());
					const formatted_message_count =
						application.messages_count > 999
							? "999+"
							: application.messages_count;
					return (
						<div className="candidate-tl-wrapper">
							<div className="candidate-tl-inner-wrapper">
								<div className="badges">
									{awaiting_confirmation ? (
										<span className="badge badge-green">
											{t("general:awaiting_confirmation")}
										</span>
									) : (
										""
									)}
									{application.has_referrer() ? (
										<span className="badge badge-green-reward tooltip-wrapper right">
											{t("general:referred")}
											<div className="tooltip">
												<h6
													dangerouslySetInnerHTML={{
														__html: t("referred_by__", {
															referrer_name: application.formatted_referrer_user_name(),
														}),
													}}
												/>
											</div>
										</span>
									) : (
										""
									)}
								</div>
								<div className="content">
									{application.has_event() ? (
										<div className="candidate-name-wrapper">
											<span className="icon-event tooltip-wrapper right">
												<IconSpeedInterview />
												<div className="tooltip">
													<h6>{t(application.events[0].get_i18n_key())}</h6>
												</div>
											</span>
											<h5 className="candidate-name">
												{application.formatted_applier_name()}
											</h5>
										</div>
									) : (
										<h5 className="candidate-name">
											{application.formatted_applier_name()}
										</h5>
									)}
									{active_job_id === "all" ? (
										<h6 className="job-title">{application.job.title}</h6>
									) : (
										""
									)}
									<button
										className={`btn btn-smaller btn-flat ${
											application.has_unread_messages()
												? "btn-fill"
												: "btn-ghost"
										}`}
										style={{ border: "1px solid" }}
										onClick={(e) =>
											handleEmployerMessageModal(
												e,
												application.job.id,
												application.id,
												this.fetchApplications(),
											)
										}>
										{/* show red dot when theres new comments  */}
										{application.current_user_status.unread.comments > 0 && (
											<div
												style={{
													borderRadius: "999px",
													backgroundColor: "red",
													width: "15px",
													height: "15px",
													position: "absolute",
													top: "-5px",
													right: "-5px",
													border: "1px solid white",
												}}></div>
										)}
										<h5>
											<img
												src={
													application.has_unread_messages()
														? icon_message_w
														: icon_message_b
												}
												alt=""
											/>
											{formatted_message_count}
										</h5>
									</button>
								</div>
								<h6 className="updated-at">
									{t("general:updated")}{" "}
									{application.formatted_updated_at_past_time(t)}
								</h6>
							</div>
						</div>
					);
				},
			},
		];

		return active_job_id !== -1 ? (
			<div
				className={`dashboard-applications ${
					open_jobs_menu ? "open-jobs-menu" : "open-detail"
				}`}>
				<div className="search-bar">
					{/* <div className="input-btn-wrapper">*/}
					{/*<input*/}
					{/*className="input"*/}
					{/*type="text"*/}
					{/*placeholder="Search Job title or Candidate"*/}
					{/*value={search_key}*/}
					{/*onChange={e => this.setState({search_key: e.target.value})}*/}
					{/*/>*/}
					{/*<div className="btn btn-larger btn-flat btn-fill">*/}
					{/*<img src={icon_search} alt="" />*/}
					{/*</div>*/}
					{/*</div> */}
				</div>

				<div className="dashboard-applications-sidebar-wrapper">
					<ul className="sidebar">
						{data_sidebar.keepExpandingSection.map((job, i) => (
							<li
								key={i}
								onClick={(e) => handleCurrentJobChange(e, job.id)}
								className={`${job.id === "all" ? "all" : ""} ${
									active_job_id === job.id ? "active" : ""
								}`}>
								<div className="li-content-wrapper">
									<h5 className="job-title">
										{job.has_event ? (
											<span className="icon-event tooltip-wrapper right">
												<IconSpeedInterview />
												<div className="tooltip">
													<h6>{t(job.event_title_key)}</h6>
												</div>
											</span>
										) : (
											""
										)}
										{job.title}
									</h5>
									<h6 className="job-location">{job.location}</h6>
								</div>
								{job.unread_applications ? (
									<span className="counting-block">
										{job.unread_applications}
									</span>
								) : (
									""
								)}
							</li>
						))}

						{data_sidebar.closedJobsSection.length ? (
							<li
								onClick={(e) => toggleClosedJobsSection(e)}
								className={`expand-trigger ${
									isClosedJobsSectionExpanded ? "expanded" : ""
								}`}>
								<div className="li-content-wrapper">
									<h5>{t("show_all_closed_jobs")}</h5>
									<IconArrow />
								</div>
							</li>
						) : (
							""
						)}
						{isClosedJobsSectionExpanded ? (
							<ul className="sub-sidebar">
								{data_sidebar.closedJobsSection.map((job, i) => (
									<li
										key={i}
										onClick={(e) => handleCurrentJobChange(e, job.id)}
										className={active_job_id === job.id ? "active" : ""}>
										<div className="li-content-wrapper">
											<h5 className="job-title">
												<span className="badge">{t("general:closed")}</span>
												{job.has_event ? (
													<span className="icon-event tooltip-wrapper right">
														<IconSpeedInterview />
														<div className="tooltip">
															<h6>{t(job.event_title_key)}</h6>
														</div>
													</span>
												) : (
													""
												)}
												{job.title}
											</h5>
											<h6 className="job-location">{job.location}</h6>
										</div>
										{job.unread_applications ? (
											<span className="counting-block">
												{job.unread_applications}
											</span>
										) : (
											""
										)}
									</li>
								))}
							</ul>
						) : (
							""
						)}
					</ul>
				</div>
				<div className="dashboard-applications-inner-wrapper">
					<div className="container-fluid">
						{error_messages.full_message ? (
							<h6 className="message error-message">
								{error_messages.full_message}
							</h6>
						) : (
							""
						)}
						<h6 className="breadcrumb">
							<a
								href="/#back"
								onClick={(e) => openJobsMenu(e)}
								className="btn btn-smallest btn-flat btn-hollow">
								<img src={icon_arrow} alt="" />
								<h5>{t("general:back")}</h5>
							</a>
						</h6>

						<div className="title-with-buttons">
							{active_job_id !== "all" ? (
								<Link
									to={internal_uri.formatted_frontend_job_page_path(
										data[active_job_id].job.id,
										data[active_job_id].job.slug,
									)}
									target="_blank"
									rel="noopener noreferrer">
									<h2>
										{data[active_job_id].title}
										<img src={icon_redirect} alt="" />
									</h2>
								</Link>
							) : (
								<h2>{data[active_job_id].title}</h2>
							)}
						</div>

						{!changing_page &&
						// Object.keys(data[active_job_id].applications).length === 0 || // loading applications
						data[active_job_id].applications ? (
							<Tabs
								className="react-tabs tabs"
								selectedIndex={active_tab_index}
								onSelect={(index) => {
									handleTabChange(index);
									this.fetchApplications();
								}}>
								<TabList>
									{Object.keys(metas.application_states).map((key, i) => (
										<Tab key={i}>
											<h5>
												{t(`${key}`)}
												{parameters[active_job_id][key].paginator
													.total_entries ? (
													<span className="counting-block">
														{
															parameters[active_job_id][key].paginator
																.total_entries
														}
													</span>
												) : (
													""
												)}
											</h5>
										</Tab>
									))}
								</TabList>
								<div className="tabs-tl">
									<div className="select-wrapper">
										<Select
											className="drop-down drop-down-larger drop-down-grey"
											clearable={false}
											searchable={false}
											value={active_tab_index}
											onChange={(value) => handleTabChange(value.value)}
											options={Object.keys(metas.application_states).map(
												(key, i) => ({
													value: i,
													label: t(`states:${key}`),
													count:
														parameters[active_job_id][key].paginator
															.total_entries,
												}),
											)}
											valueRenderer={(option) => (
												<div>
													{option.label}
													{option.count ? (
														<span className="counting-block">
															{option.count}
														</span>
													) : (
														""
													)}
												</div>
											)}
											optionRenderer={(option) => (
												<div>
													{option.label}
													{option.count ? (
														<span className="counting-block">
															{option.count}
														</span>
													) : (
														""
													)}
												</div>
											)}
										/>
									</div>
								</div>

								{Object.keys(metas.application_states).map((key, i) => (
									<TabPanel key={i} onClick={(e) => {}}>
										<div className="table-wrapper-pc table-wrapper block bg-red-500">
											<ReactTable
												loading={
													changing_page ||
													Object.keys(data[active_job_id].applications)
														.length === 0
												}
												data={data[active_job_id].applications[key]}
												columns={columns_pc}
												defaultPageSize={20}
												minRows={0}
												resizable={false}
												showPagination={false}
												getTrGroupProps={(state, rowInfo, column, instance) => {
													const application = new JobApplication(
														JSON.parse(rowInfo.original.application),
													);
													if (application.has_unread_messages()) {
														return { className: "message_unread" };
													}
												}}
												sorted={[
													{
														id: parameters[active_job_id][key].criteria.name,
														desc:
															parameters[active_job_id][key].criteria.sortBy ===
															"desc",
													},
												]}
												defaultSortDesc={true}
												defaultSortMethod={(a, b, desc) => (desc ? -1 : 1)}
												onSortedChange={(newSorted, column, shiftKey) => {
													this.onSortedChange(newSorted, key);
													this.fetchApplications();
												}}
												noDataText={t("general:your_list_is_empty_")}
											/>
										</div>
										<div className="table-wrapper-tl table-wrapper block">
											<ReactTable
												loading={
													changing_page ||
													Object.keys(data[active_job_id].applications)
														.length === 0
												}
												data={data[active_job_id].applications[key]}
												columns={columns_tl}
												defaultPageSize={20}
												minRows={0}
												resizable={false}
												showPagination={false}
												getTrGroupProps={(state, rowInfo, column, instance) => {
													const application = new JobApplication(
														JSON.parse(rowInfo.original.application),
													);
													if (application.has_unread_messages()) {
														return { className: "message_unread" };
													}
												}}
												sorted={[
													{
														id: parameters[active_job_id][key].criteria.name,
														desc:
															parameters[active_job_id][key].criteria.sortBy ===
															"desc",
													},
												]}
												defaultSortDesc={true}
												defaultSortMethod={(a, b, desc) => (desc ? -1 : 1)}
												onSortedChange={(newSorted, column, shiftKey) => {
													this.onSortedChange(newSorted, key);
													this.fetchApplications();
												}}
												noDataText={t("general:your_list_is_empty_")}
											/>
										</div>
										{parameters[active_job_id][key].paginator.total_entries ? (
											<div>
												<div className="pagination-wrapper-pc hidden-xs hidden-sm">
													<Pagination
														currentPage={
															parameters[active_job_id][key].paginator
																.current_page
														}
														pagesCount={
															parameters[active_job_id][key].paginator
																.total_pages
														}
														pagedCount={5}
														previous_page={
															parameters[active_job_id][key].paginator
																.previous_page
														}
														next_page={
															parameters[active_job_id][key].paginator.next_page
														}
														setCurrentPage={(page) => setCurrentPage(key, page)}
													/>
												</div>
												<div className="pagination-wrapper-mb">
													<Pagination
														currentPage={
															parameters[active_job_id][key].paginator
																.current_page
														}
														pagesCount={
															parameters[active_job_id][key].paginator
																.total_pages
														}
														pagedCount={4}
														previous_page={
															parameters[active_job_id][key].paginator
																.previous_page
														}
														next_page={
															parameters[active_job_id][key].paginator.next_page
														}
														setCurrentPage={(page) => setCurrentPage(key, page)}
													/>
												</div>
											</div>
										) : (
											""
										)}
									</TabPanel>
								))}
							</Tabs>
						) : (
							<div className="blank-application-wrapper">
								<div className="graph">
									<div
										className="image-wrapper"
										style={{ backgroundImage: `url(${blank_application})` }}
									/>
								</div>
								<h2>{t("sit_back_and_relax")}</h2>
								<p>{t("you_have_no_applications_yet_")}</p>
							</div>
						)}
					</div>
				</div>
			</div>
		) : (
			<div
				className={`dashboard-applications blank ${
					open_jobs_menu ? "open-jobs-menu" : "open-detail"
				}`}>
				<div className="blank-job-wrapper">
					<div className="graph">
						<div
							className="image-wrapper"
							style={{ backgroundImage: `url(${blank_job})` }}
						/>
					</div>
					<h2>{t("no_jobs_posted")}</h2>
					<p>{t("you_haven_t_posted_any_jobs_yet_")}</p>
					<Link
						to={internal_uri.formatted_dashboard_create_job_page_path()}
						className="btn btn-larger btn-flat btn-fill">
						<h5>{t("general:post_a_job")}</h5>
					</Link>
				</div>
			</div>
		);
	}
}

export default withNamespaces([
	"dashboard_applications_page",
	"general",
	"states",
])(ApplicationsPage);
