import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Metas from "../../../models/metas";
import {
  compare,
  get_options,
  page_smothly_scroll_to,
} from "../../../../assets/js/functions";
import { Elements, StripeProvider } from "react-stripe-elements";
import SaveCardForm from "../SaveCardForm";
import { withNamespaces, Interpolate } from "react-i18next";
import Employer from "../../../models/employer.js";
import banner_plan_post from "../../../../assets/images/i013-plan_banner_01.svg";
import banner_plan_hire from "../../../../assets/images/i014-plan_banner_02.svg";
import banner_plan_hire_plus from "../../../../assets/images/i015-plan_banner_03.svg";
import powered_by_stripe from "../../../../assets/images/powered_by_stripe.png";
import moment from "moment";
import { CSSTransitionGroup } from "react-transition-group";
import InternalUri from "../../../models/internal_uri";

const config = new Config();
const headers = new ApiHeaders();

class PaymentPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.updateActiveCompany = this.updateActiveCompany.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleCardDetail = this.handleCardDetail.bind(this);
    this.contactMeetJobs = this.contactMeetJobs.bind(this);
    this.checkPendingData = this.checkPendingData.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.PlanNameFixer = this.PlanNameFixer.bind(this);
    this.state = {
      active_company_id: -1,
      active_company: {},
      latest_plan: "",
      latest_plan_key: "",
      form_data: {},
      original_form_data: {},
      has_pending_data: false,
      loading: true,
      sending: false,
      error_messages: {},
      metas: {},
      options: {},
      data: [
        { TITLE: "PROGRAM 1", AA: "MOCK1-1", BB: "MOCK1-2", CC: "MOCK1-3" },
        { TITLE: "PROGRAM 2", AA: "MOCK2-1", BB: "MOCK2-2", CC: "MOCK2-3" },
        { TITLE: "PROGRAM 3", AA: "MOCK3-1", BB: "MOCK3-2", CC: "MOCK3-3" },
      ],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });

    let axios_options = {
      method: "GET",
      url: config.api_url("/employers/plan_types"),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    let metas = {},
      options = {};
    axios(axios_options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          metas = {
            ...metas,
            plan_types: new Metas("plan_types", response.data),
          };
          options = {
            ...options,
            plan_types: get_options("plan_types", response.data),
          };

          axios_options = {
            method: "GET",
            url: config.api_url("/employers/payment_types"),
            headers: headers.getItemsFromLocalStorage(),
            json: true,
          };
          return axios(axios_options);
        }
      })
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);

        if (this._isMounted) {
          metas = {
            ...metas,
            payment_types: new Metas("payment_types", response.data),
          };
          options = {
            ...options,
            payment_types: get_options("payment_types", response.data),
          };

          this.setState(
            (prev_state) => ({
              ...prev_state,
              metas,
              options,
              error_messages: {},
            }),
            () =>
              this.updateActiveCompany(
                this.props.current_user.active_company.id
              )
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  UNSAFE_componentWillReceiveProps(next_props) {
    if (
      this.props.current_user.active_company.id !==
      next_props.current_user.active_company.id
    ) {
      this.updateActiveCompany(next_props.current_user.active_company.id);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateActiveCompany(company_id) {
    const { current_user } = this.props;
    if (
      current_user.employer_roles[company_id].access_control_list
        .billing_manageable
    ) {
      this.setState(
        (prev_state) => ({
          ...prev_state,
          active_company_id: company_id,
          access_control_list:
            current_user.employer_roles[company_id].access_control_list,
          loading: true,
        }),
        () => this.fetchData()
      );
    } else {
      this.setState((prev_state) => ({
        ...prev_state,
        active_company_id: company_id,
        access_control_list:
          current_user.employer_roles[company_id].access_control_list,
        loading: false,
      }));
    }
  }

  PlanNameFixer = (name) => {
    switch (name) {
      case "Hire":
        return "Social";
      case "Hire+":
        return "Hunter";
      default:
        return name;
    }
  };

  fetchData() {
    let options = {
      method: "GET",
      url: config.api_url(
        `/employers/${this.state.active_company_id}?include=addresses,contact,photos`
      ),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        const employer = new Employer(response.data);
        this.props.updateCurrentUserActiveCompany({ ...employer });

        if (this._isMounted) {
          const raw_latest_plan = response.data.latest_plan;
          const raw_payment_method = raw_latest_plan
            ? raw_latest_plan.payment_method
            : null;
          const { metas } = this.state;

          this.setState((prev_state) => ({
            ...prev_state,
            active_company: employer,
            latest_plan: raw_latest_plan,
            latest_plan_key: raw_latest_plan
              ? metas.plan_types.key(raw_latest_plan.name)
              : null,
            // TODO: tell 後端，payment method name 應該要給 value not key
            form_data: {
              payment: raw_payment_method,
              payment_checked: raw_payment_method
                ? raw_payment_method.name
                : "",
            },
            original_form_data: {
              payment: raw_payment_method,
              payment_checked: raw_payment_method
                ? raw_payment_method.name
                : "",
            },
            has_pending_data: false,
            loading: false,
            error_messages: {},
          }));
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  handlePaymentChange(e, value) {
    e.preventDefault();
    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: {
          ...prev_state.form_data,
          payment_checked: value,
        },
      }),
      () => this.checkPendingData()
    );
  }

  handleCardDetail(data) {
    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: {
          ...prev_state.form_data,
          payment: {
            brand: data.card.brand,
            country: data.card.country,
            token: data.id,
            last4: data.card.last4,
            registered_at: data.created,
          },
        },
      }),
      () => this.checkPendingData()
    );
  }

  contactMeetJobs(e) {
    e.preventDefault();
    this.props.updateContactMeetJobsModal(true);
  }

  checkPendingData() {
    const { form_data, original_form_data } = this.state;
    let has_pending_data = false;
    if (
      form_data.payment_checked !== original_form_data.payment_checked ||
      (form_data.payment_checked === "stripe" &&
        !compare(JSON.parse(JSON.stringify(form_data)), original_form_data))
    ) {
      has_pending_data = true;
    }
    this.setState({ has_pending_data });
  }

  discard = (e) => {
    e.preventDefault();
    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: JSON.parse(JSON.stringify(prev_state.original_form_data)),
      }),
      () => this.checkPendingData()
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    const { active_company, latest_plan_key, form_data, sending } = this.state;

    if (!sending && form_data.payment_checked) {
      this.setState({ sending: true });
      let formData = new FormData();
      const { t } = this.props;
      switch (form_data.payment_checked) {
        case "stripe":
          if (latest_plan_key)
            formData.append("employer[choose_plan]", latest_plan_key);
          formData.append("payment_method[name]", form_data.payment_checked);
          formData.append("payment_method[brand]", form_data.payment.brand);
          formData.append("payment_method[country]", form_data.payment.country);
          formData.append("payment_method[token]", form_data.payment.token);
          formData.append("payment_method[last4]", form_data.payment.last4);
          formData.append(
            "payment_method[registered_at]",
            form_data.payment.registered_at
          );
          break;
        case "offline_transfer":
          if (latest_plan_key)
            formData.append("employer[choose_plan]", latest_plan_key);
          formData.append("payment_method[name]", form_data.payment_checked);
          formData.append(
            "payment_method[customer_name]",
            active_company.contact.point_of_contact
              ? active_company.contact.point_of_contact
              : ""
          );
          formData.append("payment_method[identity]", "");
          formData.append(
            "payment_method[address]",
            active_company.formatted_primary_location(t)
          );
          formData.append("payment_method[remark]", "");
          // TODO: 請問 identity、remark 之類的要放什麼？
          break;
        default:
      }

      let options = {
        method: "PUT",
        url: config.api_url(`/employers/${this.state.active_company_id}`),
        headers: headers.getItemsFromLocalStorage("multipart/form-data"),
        data: formData,
        json: true,
      };
      axios(options)
        .then((response) => {
          headers.updateItemsToLocalStorage(response.headers);
          this.props.updateConnectionOffModal(false);

          if (this._isMounted) {
            this.setState(
              (prev_state) => ({
                ...prev_state,
                error_messages: {},
                sending: false,
              }),
              () => this.fetchData()
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            headers.updateItemsToLocalStorage(error.response.headers);
            if (error.response.status === 401) window.location.reload();
          }

          if (this._isMounted) {
            if (typeof error.response === "undefined") {
              window.location.reload();
              this.props.updateConnectionOffModal(true);
            }

            this.setState(
              (prev_state) => ({
                ...prev_state,
                form_data: JSON.parse(
                  JSON.stringify(prev_state.original_form_data)
                ),
                sending: false,
                error_messages:
                  error.response &&
                  error.response.data &&
                  error.response.data.status === "error"
                    ? error.response.data.errors
                    : {
                        full_message:
                          "There was an error. Please try again later.",
                      },
              }),
              () => {
                this.checkPendingData();
                page_smothly_scroll_to(0, 300);
              }
            );
          }
        });
    }
  };

  render() {
    const {
      access_control_list,
      active_company,
      latest_plan,
      latest_plan_key,
      loading,
      sending,
      error_messages,
      data,
    } = this.state;
    const { form_data } = this.state;
    const { has_pending_data } = this.state;
    const { handleCardDetail, contactMeetJobs } = this;
    const { handlePaymentChange, discard, submitForm } = this;
    const { t } = this.props;
    const internal_uri = new InternalUri();

    const columns_pc = [
      {
        Header: () => <h5></h5>,
        accessor: "TITLE",
        id: "title",
        Cell: (props) => {
          return <div>{props.value}</div>;
        },
        width: 140,
      },
      {
        Header: () => <h5>SEASON</h5>,
        accessor: "AA",
        id: "minimum_salary",
        Cell: (props) => {
          return props.value;
        },
        width: 200,
      },
      {
        Header: () => <h5>HALF</h5>,
        accessor: "BB",
        id: "job_applications_count",
        Cell: (props) => {
          return props.value;
        },
        width: 200,
      },
      {
        Header: () => <h5>ANNUAL</h5>,
        accessor: "CC",
        id: "updated_at",
        Cell: (props) => {
          return props.value;
        },
        width: 200,
      },
    ];

    // 992px
    const columns_tl = [
      // {
      //   Header: () => <h5></h5>,
      //   accessor: "TITLE",
      //   id: "title",
      //   Cell: (props) => {
      //     return <div>{props.value}</div>;
      //   },
      //   width: 120,
      // },
      // {
      //   Header: () => <h5>SEASON</h5>,
      //   accessor: "AA",
      //   id: "minimum_salary",
      //   Cell: (props) => {
      //     return <ProgramSelection value={props.value} />;
      //   },
      //   width: 140,
      // },
      // {
      //   Header: () => <h5>HALF</h5>,
      //   accessor: "BB",
      //   id: "job_applications_count",
      //   Cell: (props) => {
      //     return <ProgramSelection value={props.value} />;
      //   },
      //   width: 140,
      // },
      // {
      //   Header: () => <h5>ANNUAL</h5>,
      //   accessor: "CC",
      //   id: "updated_at",
      //   Cell: (props) => {
      //     return <ProgramSelection value={props.value} />;
      //   },
      //   width: 140,
      // },
    ];

    // 600px
    const columns_mb = [
      {
        Header: () => <h5>{t("title")}</h5>,
        accessor: "job",
        id: "title",
        Cell: (props) => {
          return <div>3</div>;
        },
      },
    ];

    let banner;
    switch (latest_plan_key) {
      case "post_only":
        banner = banner_plan_post;
        break;
      case "social_referral":
        banner = banner_plan_hire;
        break;
      case "custom_referral":
        banner = banner_plan_hire_plus;
        break;
      default:
        banner = "";
    }

    if (loading) {
      return (
        <div className="dashboard-payment">
          <div className="container-fluid">
            <h6 className="breadcrumb">
              <Link to={internal_uri.formatted_dashboard_index_page_path()}>
                {t("general:home")}
              </Link>
              {" / "}
              {t("general:payment_setting")}
            </h6>
            <h2>{t("general:payment_setting")}</h2>
            {`${t("general:loading")}...`}
          </div>
        </div>
      );
    }

    if (!access_control_list.billing_manageable) {
      return (
        <div className="dashboard-payment">
          <div className="container-fluid">
            <h6 className="breadcrumb">
              <Link to={internal_uri.formatted_dashboard_index_page_path()}>
                {t("general:home")}
              </Link>
              {" / "}
              {t("general:payment_setting")}
            </h6>
            <h2>{t("general:payment_setting")}</h2>
            {t(
              "general:you_don_t_have_the_proper_privilege_level_to_visit_this_page_"
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="dashboard-payment">
        <div className="container-fluid">
          <h6 className="breadcrumb">
            <Link to={internal_uri.formatted_dashboard_index_page_path()}>
              {t("general:home")}
            </Link>
            {" / "}
            {t("general:payment_setting")}
          </h6>
          <h2>{t("general:payment_setting")}</h2>
          {error_messages.full_message ? (
            <h6 className="message error-message">
              {error_messages.full_message}
            </h6>
          ) : (
            ""
          )}
          <div className="inner-wrapper">
            <div className="payment-header block">
              <div className="left">
                <div
                  className="image-wrapper"
                  style={{ backgroundImage: `url(${banner}` }}
                />
              </div>
              <div className="right">
                <div className="right-inner-wrapper">
                  <h3>
                    {t("general:current_plan_")}{" "}
                    {latest_plan
                      ? this.PlanNameFixer(latest_plan.name)
                      : "null"}
                  </h3>
                  {active_company.is_latest_plan_post() ? (
                    form_data.payment && form_data.payment.name === "stripe" ? (
                      latest_plan && latest_plan.next_payment_at ? (
                        <h5>
                          {t("next_billing_date_")}{" "}
                          {moment(latest_plan.next_payment_at).format(
                            "YYYY/MM/DD"
                          )}
                        </h5>
                      ) : (
                        ""
                      )
                    ) : latest_plan && latest_plan.expired_at ? (
                      <h5 className="expired-date">
                        {t("free_trial_expiration_date_")}{" "}
                        {moment(latest_plan.expired_at).format("YYYY/MM/DD")}
                      </h5>
                    ) : (
                      ""
                    )
                  ) : latest_plan && latest_plan.next_payment_at ? (
                    <h5>
                      {t("next_billing_date_")}{" "}
                      {moment(latest_plan.next_payment_at).format("YYYY/MM/DD")}
                    </h5>
                  ) : (
                    ""
                  )}
                  <div className="right-links-container">
                    <Link
                      to={internal_uri.formatted_dashboard_plans_page_path()}
                      className="btn btn-flat btn-fill"
                    >
                      <h5>LEARN MORE</h5>
                    </Link>
                    {/* <Link
                      to={internal_uri.formatted_dashboard_payment_postonly_programs_page_path()}
                      className="btn btn-flat btn-fill"
                    >
                      <h5>PAY FOR POST-ONLY PLAN</h5>
                    </Link> */}
                    {/* {latest_plan_key !== "custom_referral" ? (
                      <Link
                        to={internal_uri.formatted_dashboard_upgrade_plans_path()}
                        className="btn btn-flat btn-fill"
                      >
                        <h5>{t("general:upgrade")}</h5>
                      </Link>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`payment-cards ${
                latest_plan_key === "post_only" ? "for-post-only" : ""
              }`}
            >
              <div className="payment-card-wrapper">
                <input
                  id="payment_credit_card"
                  type="radio"
                  className="radio-input"
                  checked={form_data.payment_checked === "stripe"}
                  readOnly={true}
                />
                <label htmlFor="payment_credit_card" className="radio-payment">
                  <div
                    className="block payment-card stripe"
                    onClick={(e) => handlePaymentChange(e, "stripe")}
                  >
                    <h3>{t("credit_card")}</h3>
                    <a
                      className="power-by-stripe-wrapper"
                      href="https://stripe.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img
                        className="power-by-stripe"
                        src={powered_by_stripe}
                        alt=""
                      />
                    </a>
                    {form_data.payment_checked === "stripe" ||
                    (form_data.payment && form_data.payment.token) ? (
                      <StripeProvider apiKey="pk_live_twWDZOmxfIyHZXr4SAtCg5eF">
                        <Elements locale="en">
                          <SaveCardForm
                            handleCardDetail={handleCardDetail}
                            payment={form_data.payment}
                          />
                        </Elements>
                      </StripeProvider>
                    ) : (
                      <div>
                        <p>{t("post_your_jobs_today_")}</p>
                        <div className="btn btn-larger btn-flat btn-fill">
                          <h5>{t("add_credit_card")}</h5>
                        </div>
                      </div>
                    )}
                    <p className="smallest-text">
                      {t(
                        "_meet_jobs_does_not_save_your_credit_card_information"
                      )}
                    </p>
                  </div>
                </label>
              </div>
              {latest_plan_key === "post_only" ? (
                ""
              ) : (
                <div className="payment-card-wrapper">
                  <input
                    id="payment_other_method"
                    type="radio"
                    className="radio-input"
                    checked={form_data.payment_checked === "offline_transfer"}
                    readOnly={true}
                  />
                  <label
                    htmlFor="payment_other_method"
                    className="radio-payment"
                  >
                    <div
                      className="block payment-card"
                      onClick={(e) =>
                        handlePaymentChange(e, "offline_transfer")
                      }
                    >
                      <h4>{t("remittance")}</h4>
                      <p>
                        {t("please_contact_me_through_email___phone_number_")}
                      </p>
                    </div>
                  </label>
                </div>
              )}
            </div>

            <h2>POSTONLY PROGRAMS</h2>
            {error_messages.full_message ? (
              <h6 className="message error-message">
                {error_messages.full_message}
              </h6>
            ) : (
              ""
            )}
            <div>
              <div className="table-wrapper-pc table-wrapper block">
                <ReactTable
                  loading={loading}
                  data={data}
                  columns={columns_pc}
                  minRows={0}
                  showPagination={false}
                  noDataText={t("general:your_list_is_empty_")}
                />
              </div>
              <div className="table-wrapper-tl table-wrapper block">
                <div className="table-wrapper block">
                  <ReactTable
                    loading={loading}
                    data={data}
                    columns={columns_tl}
                    minRows={0}
                    showPagination={false}
                    noDataText={t("general:your_list_is_empty_")}
                  />
                </div>
              </div>
              <div className="table-wrapper-mb table-wrapper block">
                <div className="table-wrapper block">
                  <ReactTable
                    loading={loading}
                    data={data}
                    columns={columns_mb}
                    minRows={0}
                    showPagination={false}
                    noDataText={t("general:your_list_is_empty_")}
                  />
                </div>
              </div>
            </div>

            <p className="contact-text">
              <Interpolate
                i18nKey="if_you_have_further_questions__please___meet_jobs_"
                useDangerouslySetInnerHTML={true}
                contact={
                  <button className="link" onClick={(e) => contactMeetJobs(e)}>
                    {t("contact")}
                  </button>
                }
              />
            </p>
          </div>
        </div>

        <CSSTransitionGroup
          component="div"
          transitionName="actions-bar-slide"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={100}
        >
          {has_pending_data ? (
            <div className="actions-bar-wrapper">
              <div className="actions-bar">
                <p>{t("to_save_your_changes__click_save_")}</p>
                <div className="buttons-wrapper">
                  <button
                    className="btn btn-larger btn-flat btn-fill"
                    onClick={(e) => discard(e)}
                  >
                    <h5>{t("general:discard")}</h5>
                  </button>
                  {sending ||
                  form_data.payment_checked === "" ||
                  (form_data.payment_checked === "stripe" &&
                    !(form_data.payment && form_data.payment.token)) ||
                  !latest_plan_key ? (
                    <button
                      onClick={(e) => e.preventDefault()}
                      className="btn btn-larger btn-flat btn-hollow disabled"
                    >
                      <h5>{t("general:save")}</h5>
                    </button>
                  ) : (
                    <button
                      className="btn btn-larger btn-flat btn-hollow"
                      onClick={(e) => submitForm(e)}
                    >
                      <h5>{t("general:save")}</h5>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default withNamespaces(["dashboard_payment_page", "general"])(
  PaymentPage
);
