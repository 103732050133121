import React from 'react';
import { withNamespaces } from "react-i18next";
import {Link} from "react-router-dom";
import InternalUri from "../../models/internal_uri";
import icon_arrow from "../../../assets/images/icon/icon_24_arrow_left_BG400.svg";
import { Document, Page } from 'react-pdf';
import icon_download from "../../../assets/images/icon/icon_24_download_B100.svg";
import DeviceDetector from "../../models/device_detector";
import {download_file} from "../../../assets/js/functions";
import Invoice from "../../models/invoice";

class InvoiceDetail extends React.Component {
    constructor(props) {
        super(props);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.handleDownload  = this.handleDownload.bind(this);
        this.state = {
            invoice: {},
            numPages: null,
        };
    };

    UNSAFE_componentWillMount() {
        this.setState({ invoice: new Invoice(this.props.invoice) });
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    handleDownload = (e, url, filename) => {
        e.preventDefault();
        download_file(url, filename);
    };

    render () {
        const { invoice, numPages } = this.state;
        const { handleDownload } = this;
        const internal_uri = new InternalUri();
        const { t } = this.props;
        const device_detector = new DeviceDetector();

        let pages = [];
        for(let i=0; i<numPages; i++) {
            pages.push(<Page key={i} pageNumber={i+1} width={800} />);
        }

        return (
            <div className="dashboard-invoice">
                <div className="container-fluid">
                    <h6 className="breadcrumb">
                        <Link to={internal_uri.formatted_dashboard_invoices_page_path()} className="btn btn-smallest btn-flat btn-hollow">
                            <img src={icon_arrow} alt="" />
                            <h5>{t('general:back')}</h5>
                        </Link>
                        <Link to={internal_uri.formatted_dashboard_index_page_path()}>{t('general:home')}</Link>
                        {' / '}
                        <Link to={internal_uri.formatted_dashboard_invoices_page_path()}>{t('general:invoices')}</Link>
                        {' / '}
                        {invoice.invoice_number}
                    </h6>
                    <div className="title-with-buttons">
                        <h2>{invoice.invoice_number}</h2>
                        {
                            (invoice.invoice_pdf.url) ? (
                                (device_detector.is_mobile_or_tablet()) ?
                                    <a
                                        className="btn btn-larger btn-flat btn-hollow btn-with-icon"
                                        href={invoice.formatted_pdf_url()}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <h5><img src={icon_download} alt="" />{t('pdf')}</h5>
                                    </a> :
                                    <a
                                        className="btn btn-larger btn-flat btn-hollow btn-with-icon"
                                        href="/#"
                                        onClick={ e => handleDownload(e, invoice.formatted_pdf_url(), invoice.formatted_pdf_filename()) }
                                    >
                                        <h5><img src={icon_download} alt="" />{t('pdf')}</h5>
                                    </a>
                            ) : ''
                        }

                    </div>
                    <Document
                        file={invoice.formatted_pdf_url()}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                        {pages}
                    </Document>
                </div>
            </div>
        );
    }
}

export default withNamespaces(['dashboard_invoices_page', 'general'])(InvoiceDetail);
