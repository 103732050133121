import React, { Component, lazy } from "react";

class BarChart extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		import("d3").then((d3) => {
			this.drawChart(d3);
		});
	}
	drawChart(D3) {
		const { t } = this.props;
		//give me an array with 20 all different kind of blue order by saturation
		const colors = [
			"#2768F6", //Blue (100% saturation)
			"#3C73F6", //Blue (90% saturation)
			"#517DF6", //Blue (80% saturation)
			"#6688F6", //Blue (70% saturation)
			"#7A92F6", //Blue (60% saturation)
			"#8FAAF6", //Blue (50% saturation)
			"#A4B5F6", //Blue (40% saturation)
			"#B9C0F6", //Blue (30% saturation)
			"#CECBF6", //Blue (20% saturation)
			"#E3D6F6", //Blue (10% saturation)
			"#F8E1F6", //Blue (0% saturation)
			"#E6D4F0", //Blue (10% desaturated)
			"#D4C8EA", //Blue (20% desaturated)
			"#C2BBE4", //Blue (30% desaturated)
			"#B0AFE0", //Blue (40% desaturated)
			"#9EA3DA", //Blue (50% desaturated)
			"#8C96D4", //Blue (60% desaturated)
			"#7A89CE", //Blue (70% desaturated)
			"#687DC8", //Blue (80% desaturated)
			"#5660C2", //Blue (90% desaturated)
		];
		const data = [
			{
				country: "Taiwan",
				icon: "🇹🇼",
				percentage: 29.94,
			},
			{
				country: "Europe",
				icon: "🇪🇺",
				percentage: 13.99,
			},
			{
				country: "North_americas",
				icon: "🇺🇸",
				percentage: 12.82,
			},
			{
				country: "SEA",
				icon: "🇸🇬",
				percentage: 10.86,
			},

			{
				country: "China",
				icon: "🇨🇳",
				percentage: 8.35,
			},
			{
				country: "East_asia",
				icon: "E",
				percentage: 4.78,
			},
			{
				country: "South_asia",
				icon: "S",
				percentage: 3.93,
			},
			{
				country: "Middle_east",
				icon: "ME",
				percentage: 2.68,
			},
			{
				country: "ANZ",
				icon: "A ",
				percentage: 1.85,
			},
			{
				country: "Others",
				icon: "O",
				percentage: 10.79,
			},
		];

		// set the dimensions and margins of the graph
		const margin = 20,
			width = 350,
			height = 350;
		const radius = Math.min(width, height) / 2 - margin;

		const D3SVG = D3.select("#countryChart")
			.append("svg")
			.attr("width", width)
			.attr("height", height)
			.append("g")
			.attr("transform", `translate( ${width / 2}, ${height / 2})`);

		// set the color scale
		const color = D3.scaleOrdinal()
			.domain(data.map((d) => d.country))
			.range(colors);

		// Compute the position of each group on the pie:
		const computedPie = D3.pie()
			.value((d) => {
				return d[1].percentage;
			})
			.sort((a, b) => {
				if (a[1].country === "Others") {
					return 1;
				}
				if (b[1].country === "Others") {
					return -1;
				}
				return a[1].percentage - b[1].percentage > 0 ? -1 : 1;
			});
		const data_ready = computedPie(Object.entries(data));
		const arcGenerator = D3.arc()
			.innerRadius(0)
			.outerRadius(radius);

		D3SVG.selectAll("mySlices")
			.data(data_ready)
			.join("path")
			.attr("d", arcGenerator)
			.attr("fill", (d) => color(d.data[1].country))
			.attr("stroke", "white")
			.style("stroke-width", "1px")
			.style("opacity", 0.8)
			.on("mouseover", () => tooltip.style("visibility", "visible"))
			.on("mousemove", (event, d) => {
				return tooltip
					.style("top", event.layerY + 10 + "px")
					.style("left", event.layerX + 10 + "px")
					.html(`<div style="white-space:nowrap;font-weight:bold">
							${t(d.data[1].country)}
								<span style="color:#2768F6">
									${Math.round(d.data[1].percentage)}%
								</span>
							</div>
							`);
			})
			.on("mouseout", () => tooltip.style("visibility", "hidden"));

		//
		//  add tooltip
		const tooltip = D3.select(`#countryChart`)
			.append("div")
			.style("position", "absolute")
			.style("visibility", "hidden")
			.style("background-color", "white")
			.style("backdrop-filter", "blur(10px)")
			.style("border", "solid #ccc")
			.style("border-width", "1px")
			.style("border-radius", "5px")
			.style("padding", "10px")
			.style("color", "black")
			.style("box-shadow", "0 0 10px rgba(0,0,0,0.25)")
			.style("font-size", "1rem")
			.style("opacity", 0.95)
			.style("text-align", "left");

		// make axis has same color as bar
		D3SVG.selectAll("path").attr("stroke", "white");
		D3SVG.selectAll("line").attr("stroke", "white");
		D3SVG.selectAll("text")
			.attr("fill", "rgb(39, 104, 246)")
			.style("font-weight", "bold");
	}

	render() {
		return <div id="countryChart"></div>;
	}
}

export default BarChart;
