import React from 'react';
import { withNamespaces, Interpolate } from "react-i18next";
import InternalUri from "../../../models/internal_uri";
import { CSSTransitionGroup } from 'react-transition-group'

const CookiePolicyCornerModal = props => {

    const { validating, current_user, reduxTokenAuth, t } = props;
    const internal_uri = new InternalUri();

    const acceptCookiePolicy = (e) => {
        e.preventDefault();
        props.updateCookiePolicyCornerModal(true);
    };

    return (
        <CSSTransitionGroup
            component="div"
            className="corner-modal-wrapper"
            transitionName="modal-slide"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
        >
            {
                (!current_user.accepted_cookie_policy && !reduxTokenAuth.currentUser.isSignedIn && !validating) ?
                    <div className="cookie-policy-modal corner-modal">
                        <h6>
                            <Interpolate
                                i18nKey="we_use_cookies_to_give_you_the_best_experience__we_suggest_you_accept_to_our___"
                                useDangerouslySetInnerHTML={true}
                                cookie_policy={
                                    <a
                                        className="link link-white"
                                        href={`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=cookie-policy`}
                                    >
                                        {t('cookie_policy')}
                                    </a>
                                }
                            />
                        </h6>
                        <button className="btn btn-smaller btn-flat btn-fill" onClick={ e => acceptCookiePolicy(e) }>
                            <h5>{t('got_it')}</h5>
                        </button>
                    </div> : ''
            }
        </CSSTransitionGroup>
    );
};

export default withNamespaces(['frontend_modals', 'general'])(CookiePolicyCornerModal);
