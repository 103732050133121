import {addCommasToNumber, removeCommasToNumber} from "../functions";
import {isNumeral} from "../validators";

let instance = null;

class Salary {
    constructor() {
        if(!instance) instance = this;
        return instance;
    }

    applyFormat(value) {
        let pureValue = removeCommasToNumber(value);
        return isNumeral(pureValue) ? addCommasToNumber(pureValue) : value;
    };

    removeFormat(value) {
        return removeCommasToNumber(value);
    };
}

export default Salary;