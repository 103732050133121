import Abstract from './abstract';
import Job from './job';
import Address from './address';
import Photo from './photo';
import User from "./user";
import default_logo from '../../assets/images/image_default_logo.svg';
import meetjobs_avatar from '../../assets/images/meetjobs_logo_vertical.svg';
import { contain_not_only_whitespace } from "../../assets/js/functions";
import moment from "moment";
import i18n from "../../i18n";
import Event from "./event";

class Employer extends Abstract {
    constructor(attrs) {
        super(attrs);

        let raw_jobs = attrs.jobs;
        if(raw_jobs) {
            delete attrs['jobs'];
            this._build_associations({
                name:  'jobs',
                klass: Job,
                attrs: raw_jobs
            });
        }

        let raw_published_jobs = attrs.published_jobs;
        if(raw_published_jobs) {
            delete attrs['published_jobs'];
            this._build_associations({
                name:  'published_jobs',
                klass: Job,
                attrs: raw_published_jobs
            });
        }

        let raw_addresses = attrs.addresses;
        if(raw_addresses) {
            delete attrs['addresses'];
            this._build_associations({
                name: 'addresses',
                klass: Address,
                attrs: raw_addresses
            });
        }

        let raw_photos = attrs.photos;
        if(raw_photos) {
            delete attrs['photos'];
            this._build_associations({
                name:  'photos',
                klass: Photo,
                attrs: raw_photos
            });
        }

        // TODO: should find out other better way to callback redux state tree, and should be update to other models
        let raw_assistant = attrs.assistant;
        if(raw_assistant) {
            attrs['assistant'] = this._build_association({
                name:  'assistant',
                klass: User,
                attrs: raw_assistant
            });
        }

        let raw_events = attrs.events;
        if(raw_events) {
            delete attrs['events'];
            this._build_associations({
                name:  'events',
                klass: Event,
                attrs: raw_events
            });
        }
    }

    formatted_name() {
        return (this.name) ? this.name : '(not provided)';
    }

    has_logo() {
        if(!this.logo) return false;
        if(!this.logo.url) return false;
        return true;
    }

    formatted_logo_url() {
        // original
        if(!this.logo) return default_logo;
        if(!this.logo.url) return default_logo;
        return this.updated_at ? `${this.logo.url}?${moment(this.updated_at).unix()}` : this.logo.url;
    }

    formatted_logo_toolbar_url() {
        // [44, 44]
        if(!this.logo) return default_logo;
        if(!this.logo.toolbar) return default_logo;
        if(!this.logo.toolbar.url) return default_logo;
        return this.updated_at ? `${this.logo.toolbar.url}?${moment(this.updated_at).unix()}` : this.logo.toolbar.url;
    }

    formatted_logo_dashboard_url() {
        // [144, 144]
        if(!this.logo) return default_logo;
        if(!this.logo.dashboard) return default_logo;
        if(!this.logo.dashboard.url) return default_logo;
        return this.updated_at ? `${this.logo.dashboard.url}?${moment(this.updated_at).unix()}` : this.logo.dashboard.url;
    }

    formatted_logo_profile_url() {
        // [300, 300]
        if(!this.logo) return default_logo;
        if(!this.logo.profile) return default_logo;
        if(!this.logo.profile.url) return default_logo;
        return this.updated_at ? `${this.logo.profile.url}?${moment(this.updated_at).unix()}` : this.logo.profile.url;
    }

    formatted_logo_sharing_url() {
        // [600, 600]
        if(!this.logo) return default_logo;
        if(!this.logo.sharing) return default_logo;
        if(!this.logo.sharing.url) return default_logo;
        return this.updated_at ? `${this.logo.sharing.url}?${moment(this.updated_at).unix()}` : this.logo.sharing.url;
    }

    is_referralable() {
        if(!this.latest_plan) return false;
        return this.latest_plan.referralable;
    }

    is_matchable() {
        if(!this.latest_plan) return false;
        return this.latest_plan.matchable;
    }

    formatted_industry() {
        return (this.industry) ? this.industry : '(not provided)';
    }

    formatted_company_size(t) {
        return t(`states:company_size_${(this.company_size_key !== null) ? this.company_size_key : 0}`);
    }

    has_website_url() {
        return (this.url);
    }

    formatted_website_url() {
        return (this.has_website_url()) ? this.url : '(not provided)';
    }

    has_addresses() {
        if(!this.addresses) return false;
        return (this.addresses.length > 0);
    }

    has_address_has_latitude_and_longitude() {
        let has_one_latitude_and_longitude = false;
        if(this.has_addresses()) {
            for(let i=0; i<this.addresses.length; i++) {
                if(this.addresses[i].has_latitude_and_longitude()) has_one_latitude_and_longitude = true;
            }
        }
        return has_one_latitude_and_longitude;
    }

    first_address_index_has_latitude_and_longitude() {
        let first_address_has_latitude_and_longitude = -1;
        if(this.has_address_has_latitude_and_longitude) {
            for(let i=0; i<this.addresses.length; i++) {
                if(this.addresses[i].has_latitude_and_longitude()) first_address_has_latitude_and_longitude = i;
            }
        }
        return first_address_has_latitude_and_longitude;
    }

    formatted_primary_location(t) {
        if(!this.has_addresses()) return t('general:_not_provided_');

        let is_main_office_index = -1;
        for(let i=0; i<this.addresses.length; i++) {
            const address = this.addresses[i];
            if(address.is_main_office) {
                is_main_office_index = i;
            }
        }
        if(is_main_office_index === -1) is_main_office_index = 0;

        const is_main_office_address = this.addresses[is_main_office_index];
        // if(is_main_office_address.place) {
        //     if(is_main_office_address.place.country || is_main_office_address.place.city || is_main_office_address.place.state) {
        //         let location = '';
        //         if(is_main_office_address.place.city) location += is_main_office_address.place.city;
        //         else if(is_main_office_address.place.state) location += is_main_office_address.place.state;
        //         if(is_main_office_address.place.country && (is_main_office_address.place.city || is_main_office_address.place.state)) location += ', ';
        //         if(is_main_office_address.place.country) location += is_main_office_address.place.country;
        //         return location;
        //     }
        // }
        if(is_main_office_address.handwriting_country || is_main_office_address.handwriting_city) {
            let location = '';
            if(is_main_office_address.handwriting_city) location += is_main_office_address.handwriting_city;
            if(is_main_office_address.handwriting_country && is_main_office_address.handwriting_city) location += ', ';
            if(is_main_office_address.handwriting_country) location += is_main_office_address.handwriting_country;
            return location;
        }
        return t('general:_not_provided_');
    }

    has_published_jobs() {
        if(!this.published_jobs) return false;
        return (this.published_jobs.length > 0);
    }

    has_photo() {
        if(!this.photos) return false;
        return (this.photos.length > 0);
    }

    get_youtube_vcode() {
        if(!this.video) return null;
        const url = this.video;
        const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);
        if ( match && match[7].length === 11 ){
            return match[7];
        }
        return null;
    }

    has_slogan() {
        return contain_not_only_whitespace(this.slogan);
    }

    has_description() {
        return contain_not_only_whitespace(this.description);
    }

    has_assistant() {
        if(!this.assistant || Object.keys(this.assistant).length === 0) return false;
        return true;
    }

    formatted_assistant_avatar_url() {
        if(!this.has_assistant()) return meetjobs_avatar;
        return this.assistant.formatted_avatar_url();
    }

    formatted_assistant_title() {
        if(!this.has_assistant()) return '';
        if(!this.assistant.title) return i18n.t('general:_not_provided_');
        return this.assistant.title;
    }

    formatted_assistant_name() {
        if(!this.has_assistant()) return 'Meet.jobs';
        return this.assistant.formatted_name();
    }

    formatted_assistant_email() {
        if(!this.has_assistant()) return 'service@meet.jobs';
        return this.assistant.formatted_email();
    }

    formatted_assistant_phone() {
        if(!this.has_assistant()) return '+886-2-2577-5800';
        return this.assistant.formatted_phone();
    }

    formatted_form_contact_name() {
        if(!this.contact) return '';
        if(this.contact.point_of_contact) return this.contact.point_of_contact;
        return '';
    }

    formatted_form_contact_phone_number() {
        if(!this.contact) return '';
        if(this.contact.mobile) return this.contact.mobile;
        return '';
    }

    is_locked() {
        if(this.latest_plan) {
            if(this.latest_plan.plan_state_key === 'expired' || this.latest_plan.plan_state_key === 'archived') return true;
            else return false;
        }

        if(!this.activated_state) return true;
        return false;
    }

    is_latest_plan_post() {
        // TODO: 要求 plan_key
        if(this.latest_plan) {
            if(this.latest_plan.name === 'Post') return true;
            else return false;
        }
        return false;
    }

    formatted_latest_plan_name() {
        return this.latest_plan ? this.latest_plan.name : "(non)";
    }

    get_employer_profile_degree_of_completion() {
        let employer_profile_completion_count = 1;
        if(this.logo && this.logo.url !== null) employer_profile_completion_count++;
        if(this.company_size_key !== null && parseInt(this.company_size_key, 10) !== 0) employer_profile_completion_count++;
        if(this.addresses && this.addresses.length) {
            for(let i=0; i<this.addresses.length; i++) {
                if(
                    this.addresses[i].handwriting_country
                    && contain_not_only_whitespace(this.addresses[i].handwriting_city)
                    && contain_not_only_whitespace(this.addresses[i].handwriting_street)
                ) {
                    employer_profile_completion_count++;
                    break;
                }
            }
        }
        if(contain_not_only_whitespace(this.url)) employer_profile_completion_count++;
        if(contain_not_only_whitespace(this.slogan)) employer_profile_completion_count++;
        if(contain_not_only_whitespace(((this.description) ? this.description : '').replace(/(<([^>]+)>)/ig, "").replace(/&nbsp;/g, " "))) employer_profile_completion_count++;
        if(this.photos && this.photos.length) employer_profile_completion_count++;
        if(this.contact && contain_not_only_whitespace(this.contact.point_of_contact)) employer_profile_completion_count++;
        if(this.contact && contain_not_only_whitespace(this.contact.mobile)) employer_profile_completion_count++;

        return (employer_profile_completion_count/10) * 100
    }

    has_event() {
        if(!this.events) return false;
        if(this.events.length<=0) return false;
        return true;
    }
}

export default Employer;