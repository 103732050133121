import Abstract from './abstract';
import moment from "moment";
import default_avatar from "../../assets/images/image_default_avatar.svg";

class ReferrerNote extends Abstract {

    // constructor(attrs) {
    //     super(attrs);
    //
    //     // let raw_sender = attrs.sender;
    //     // if(raw_sender) {
    //     //     delete attrs['sender'];
    //     //     this._build_association({
    //     //         name:  'sender',
    //     //         klass: User,
    //     //         attrs: raw_sender
    //     //     });
    //     // };
    // };

    formatted_sender_name() {
        if(!this.referrer_first_name && !this.referrer_last_name) return '(no name)';
        let sender_name = '';
        if(this.referrer_first_name) sender_name += this.referrer_first_name;
        if(this.referrer_first_name && this.referrer_last_name) sender_name += ' ';
        if(this.referrer_last_name) sender_name += this.referrer_last_name;
        return sender_name;
    };

    formatted_sender_avatar_url() {
        if(!this.avatar) return default_avatar;
        if(!this.avatar.url) return default_avatar;
        return this.avatar.url;
    };

    formatted_referrer_email() {
        return (this.referrer_email) ? this.referrer_email : '(not provided)';
    };

    formatted_referrer_phone() {
        return (this.referrer_phone) ? this.referrer_phone : '(not provided)';
    };

    formatted_created_at() {
        if(!this.created_at) return '';
        return moment(this.created_at).format('YYYY/MM/DD HH:mm');
    };
}

export default ReferrerNote;
