import Abstract from './abstract';
import i18n from "../../i18n";

class Attachment extends Abstract {
    has_url() {
        if(!this.asset) return false;
        if(!this.asset.url) return false;
        return true;
    };

    formatted_url() {
        if(this.has_url()) return this.asset.url;
        return '';
    };

    formatted_filename() {
        if(!this.asset) return i18n.t('general:_not_provided_');
        if(!this.asset.filename) return i18n.t('general:_not_provided_');
        return this.asset.filename;
    };

    formatted_file_type() {
        if(this.has_url()) return this.asset.url.substring(this.asset.url.lastIndexOf('.') + 1).toUpperCase();
        return '';
    };
}

export default Attachment;
