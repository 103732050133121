export default {
    meet_jobs_featured: "Meet.jobs 精選",
    job_type: "工作類型",
    full_time: "全職",
    part_time: "兼職",
    internship: "實習",
    volunteer: "志工",
    all_filters: "所有篩選",
    more_job_functions: "更多職能",
    remote: "遠端",
    allowing_remote: "允許遠端",
    apply: "完成",

    best_match: "最相關",
    by_update: "最新",
    by_salary: "薪資最高",

    filters: "篩選",

    we_re_sorry_: "很抱歉!",
    we_can_t_seem_to_find_any_job_openings_that_match_your_search_: "我們沒辦法幫你找到相關的結果。",

    // earn_cash_reward_with_successful_referrals_: "推薦朋友找工作，成功錄取拿獎金！",
};