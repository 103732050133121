import moment from 'moment';
import React from 'react';

function ColumnPosts(posts){
     return(
         <div className={"row"}>
             {
                 posts.map((post,index) => {
                     return (
                     <div key={index} className="col-xs-12 col-md-4">
                         <a
                           href={post.link}
                         >
                             <div className="column-block">
                                 <div className="image-wrapper"
                                      style={
                                          {
                                              backgroundImage:`url(${post._embedded['wp:featuredmedia']['0'].source_url})`
                                          }
                                      }>
                                 </div>
                                <h3 className={"title"} dangerouslySetInnerHTML={{__html: post.title.rendered}} ></h3>
                                <h6 className={"excerpt"} dangerouslySetInnerHTML={{__html: post.excerpt.rendered.replace(/(<([^>]+)>)/gi, "")}}></h6>
                                <h6 className="author-and-date">{post._embedded.author[0].name} ‧ {moment(post.date_gmt).format('MMM DD, YYYY')}</h6>
                             </div>
                         </a>
                     </div>
                     )
                 })
             }

         </div>


     )



}


export default ColumnPosts;
