import axios from 'axios';
import Config from '../models/config';
import ApiHeaders from '../models/api_headers';
import { formatted_locale } from '../../assets/js/functions'
import i18n from '../../i18n';

const config = new Config()
const headers = new ApiHeaders()

export const getTopCountry = function () {

    const params = {
        locale: i18n.language,
    }

    const options = {
        method: 'get',
        url: config.api_url_v2('/job_locations/countries'),
        headers: headers.getItemsFromLocalStorage('multipart/form-data'),
        params,
    };

    return new Promise((resolve, reject) => {
        axios(options).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const getTopCity = function () {
    const options = {
        method: 'get',
        url: config.api_url_v2('/job_locations/cites'),
        headers: headers.getItemsFromLocalStorage('multipart/form-data'),
    };

    return new Promise((resolve, reject) => {
        axios(options).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}
