export default {
    // contact meet jobs modal
    contact_meet_jobs: "Contact Meet.jobs",
    business_development: "Business Development",

    // employer message modal
    candidate_info: "Candidate info",
    candidate_name: "Candidate name",
    email: "Email",
    phone: "Phone",
    talent_match: "Talent Match",
    referral: "Referral",
    confirm_hire: "Confirm hire",
    title: "Title",
    location: "Location",
    date: "Date",
    time: "Time",
    to: "to",
    file_name: "File name",
    type_something___: "Type something...",
    invite_to_an_interview: "Invite to an interview",
    confirmTitle: "Would you like to confirm {{applier_name}}'s employment?",
    confirmDescription: "Meet.jobs would contact you for further details as soon as possible.",
    assessment_for_suitable_candidates_to_invite_to_interviews__the_specific_service_for_hire_and_hire_plus_plan_: `Assessment for suitable candidates to invite to interviews. The specific service for <b>Social</b> & <b>Hunter Plan</b>!`,
    by__: "by {{referrer_name}}",
    internal_comments_will_not_be_seen_by_candidates: "Internal comments will not be seen by candidates.",
    no_reference_letter: "No reference",
    send_a_thank_you_letter: "Send a Thank-You Letter",
    thank_you_letter_warning: "Sending a Thank-you letter would mark this candidate as not selected.",
    thank_you_letter_content: "Hello {{candidate_name}},<br/><br/>Thank you for applying for {{job_title}} of {{employer_name}}<br/><br/>After careful consideration, it was decided not to proceed with your application.<br/><br/>Please visit Meet.jobs for other great positions. We wish you the best of luck in your career endeavors.<br/><br/>Meet.jobs",
    send: "Send",

    // locked modal
    your_account_is_temporarily_locked_: "Your account is temporarily locked.",
    locked_content: "We found some problems with your payment. Please contact us to unlock you account.",
    locked_content_post_plan: "We found some problems with your payment. Please update your credit card information in payment setting.",
    go_to_payment_setting: "Go to Payment Setting",

    // welcome employer modal
    you_are_all_set: "You are all set",
    you_are_ready_for_providing_more_details_of_your_company_and_posting_jobs_to_reach_the_candidates_worldwide_: "You are ready for providing more details of your company and posting jobs to reach the candidates worldwide.",
    we_are_pleased_to_provide_you__60_days_free_trial__: `We are pleased to provide you <span class='free-trial'>60 days free trial</span>.`,
    we_have_placed_you_in_high_priority: "We have placed you in high priority",
    we_will_reach_you_via_the_information_you_have_provided_: "We will reach you via the information you have provided.",

    // edit member modal
    remove_member: "Remove member",
    transfer_ownership: "Transfer ownership",
    select_member: "Select member",
    are_you_sure_to_remove___: "Are you sure to Remove {{member_name}}?",
    are_you_sure_to_leave___: "Are you sure to Leave {{employer_name}}?",

    // edit invited member modal
    resend_invitation: "Resend invitation",
    resend_member_invitation_to___: "Resend member invitation to {{invited_roleship_email}}?",
};