import React from 'react';
import { Link } from "react-router-dom";

const BreadCrumb = props => {
    const { items } = props;

    let breadcrumbContent = [];
    for(let i=0; i<items.length; i++) {
        const item = items[i];

        if(i!==0) breadcrumbContent.push(' / ');

        switch (item.uriType) {
            case 'internal':
                breadcrumbContent.push(<Link key={i} to={item.uri} data-event-category={item.eventCategory ? item.eventCategory : ''}>{item.text}</Link>);
                break;
            case 'external':
                breadcrumbContent.push(<a key={i} href={item.uri} data-event-category={item.eventCategory ? item.eventCategory : ''} target="_blank" rel="noopener noreferrer">{item.text}</a>);
                break;
            default:
                breadcrumbContent.push(<span key={i}>{item.text}</span>);
        }
    }

    return <h6 className="breadcrumb">{breadcrumbContent}</h6>;
};

BreadCrumb.defaultProps = {
    items: []
};

export default BreadCrumb;