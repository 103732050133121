export default {
    // member_information: "成員資訊",
    // role_management: "角色管理",
    owner_of__: "{{employer_name}} 的擁有者",
    owner: "擁有者",

    email: "Email",
    username: "使用者",
    title: "職稱",
    role: "角色",
    add_member: "新增成員",
    you: "你",
};