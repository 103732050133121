import Abstract from './abstract';
import moment from "moment";
import {to_timestamp} from "../../assets/js/functions";

class Invoice extends Abstract {
    formatted_primary_status_key() {
        if(this.payment_status_key==='paid') return 'paid';
        if(this.payment_status_key==='cancelled' || this.payment_status_key==='cancel') return 'cancelled';
        if(this.payment_status_key === 'unpaid') {
            const start_time = this.due_date;
            if(start_time) {
                const due_time = moment(start_time);
                const days_left = parseInt(moment(due_time).add(1, 'days').diff(moment(Date.now()), 'days'), 10);
                if(days_left <= -1) return 'overdue';
            }
        }
        return null;
    }

    formatted_pdf_url() {
        return `${this.invoice_pdf.url}?${to_timestamp(this.updated_at)}`;
    }

    formatted_pdf_filename() {
        return `invoice_${this.invoice_number}.pdf`;
    }
}

export default Invoice;
