import React from 'react';
import { LogInFormWrapper, SignUpFormWrapper } from '../../../containers/frontend.jsx';
import { withNamespaces, Interpolate } from "react-i18next";
import { CSSTransitionGroup } from 'react-transition-group'

const EntranceModal = ({ site_state={}, updateEntranceFormModal=f=>f, t }) => {

    const openLogInModal = e => {
        e.preventDefault();
        updateEntranceFormModal('log-in');
    };

    const openSignUpModal = e => {
        e.preventDefault();
        updateEntranceFormModal('sign-up');
    };

    const closeModal = e => {
        e.preventDefault();
        updateEntranceFormModal('');
    };

    return (
        <CSSTransitionGroup
            component="div"
            transitionName="modal-wrapper-with-modal-slide"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
        >
            {(site_state.entrance_form_modal) ?
                <div className="modal-wrapper entrance-modal-wrapper" >
                    <div className="modal-inner-wrapper">
                        <div className="modal-bg" onClick={ e => closeModal(e) } />
                        <div className="modal entrance-modal">
                            <div className="btn-close" onClick={ e => closeModal(e) } />
                            {(site_state.entrance_form_modal === 'sign-up') ?
                                <div>
                                    <h2>{t('welcome_to_meet_jobs')}</h2>
                                    <div className="entrance-form-wrapper">
                                        <SignUpFormWrapper />
                                    </div>
                                    <hr />
                                    <p>
                                        <Interpolate
                                            i18nKey="already_have_an_account___"
                                            useDangerouslySetInnerHTML={true}
                                            log_in={
                                                <a
                                                    href="/#login"
                                                    onClick={ e => openLogInModal(e) }
                                                    className="link"
                                                >
                                                    {t('general:log_in')}
                                                </a>
                                            }
                                        />
                                    </p>
                                </div> :
                                <div>
                                    <h2>{t('welcome_back_')}</h2>
                                    <div className="entrance-form-wrapper">
                                        <LogInFormWrapper />
                                    </div>
                                    <hr />
                                    <p>
                                        <Interpolate
                                            i18nKey="don_t_have_an_account____now_"
                                            useDangerouslySetInnerHTML={true}
                                            sign_up={
                                                <a
                                                    href="/#sign_up"
                                                    onClick={ e => openSignUpModal(e) }
                                                    className="link"
                                                >
                                                    {t('general:sign_up')}
                                                </a>
                                            }
                                        />
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div> : ''
            }
        </CSSTransitionGroup>
    );
};

export default withNamespaces(['frontend_authentication', 'general'])(EntranceModal);
