import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import axios, { CanceledError } from "axios";
import {
  JobCardWrapper,
  UseReferralUrlWrapper,
} from "./../../../containers/general.jsx";
import Pagination from "./../../general/Pagination";
import Select from "react-select";
import JobCardLoader from "./../../general/JobCardLoader";
import { Helmet } from "react-helmet";
import Job from "./../../../models/job.js";
import Config from "./../../../models/config.js";
import ApiHeaders from "./../../../models/api_headers.js";
import {
  get_value_from_query_string,
  contain_not_only_whitespace,
  page_smothly_scroll_to,
  smoothly_scroll_to,
} from "../../../../assets/js/functions.js";
import { withNamespaces } from "react-i18next";
import icon_search from "./../../../../assets/images/icon/icon_24_search_BG400.svg";
import icon_filter from "./../../../../assets/images/icon/icon_24_filter_B100.svg";
import blank_search from "./../../../../assets/images/i016-blank_search.svg";
import InternalUri from "../../../models/internal_uri";
import i18n from "../../../../i18n.js";
import Ad from "../../../models/ad";
import MarketingBanner from "../MarketingBanner";
import { ReactComponent as IconRefer } from "../../../../assets/images/icon/icon_24_refer_BG400.svg";
import JobFunction from "../../../models/job_function";
import { getTopCountry } from "../../../hooks/getTopLocation";
import { debounce } from "../../../../assets/js/functions.js";

const config = new Config();
const headers = new ApiHeaders();
const INIT_PAGINATOR = {
  total_entries: 0,
  total_pages: 1,
  per_age: 1,
  current_page: 1,
  previous_page: null,
  next_page: null,
};
const INIT_JOBS_PARAMETERS = {
  work_types: [],
  remote_working: false,
  order: "match",
  q: "",
  place: "",
  job_functions: [],
  referral_reward: false,
  page: 1,
};

function JobsPage(props) {
  const controller = new AbortController();
  const fetchController = useRef(null);
  // fetch jobs 的延遲時間
  const debounceDelay = 300;
  const {
    search_words,
    t,
    updateSearchBar,
    updateKeepSearchBar,
    location,
    ads,
    lng,
    updateConnectionOffModal,
    history,
    updateSearchKeywords,
  } = props;
  // console.log("props of job page", props);
  const { keyword, keyword_place } = search_words;
  const countryListPlaceHolder = Array(6).fill({
    name: null,
    translated_name: "Loading...",
  });

  const origin_options = {
    work_types: ["full_time", "part_time", "internship", "volunteer"],
    job_functions: [],
  };
  const [jobs, set_jobs] = useState([]);
  const [error_messages, set_error_messages] = useState({});
  const [job_functions, set_job_functions] = useState({ en: [], "zh-TW": [] });
  const [open_all_filters, set_open_all_filters] = useState(false);
  const [loading, set_loading] = useState(true);
  const [country_list, set_country_list] = useState([
    ...countryListPlaceHolder,
  ]);
  const [options, set_options] = useState({ ...origin_options });
  const [paginator, set_paginator] = useState({
    ...INIT_PAGINATOR,
  });
  const [jobs_parameters_copy, set_jobs_parameters_copy] = useState({
    ...INIT_JOBS_PARAMETERS,
  });

  // const [jobs_parameters, set_jobs_parameters] = useState()

  function makeQueryString({
    page,
    work_types,
    remote_working,
    order,
    q,
    place,
    job_functions,
    referral_reward,
    ...other
  }) {
    let query_string = "";
    let query = [];
    if (page) query.push(`page=${page}`);
    if (work_types.length !== 0)
      query.push(`work_types=${work_types.join(",")}`);
    if (remote_working) query.push(`remote_working=true`);
    if (order) query.push(`order=${order}`);
    if (q) query.push(`q=${encodeURIComponent(q)}`);
    if (place) query.push(`place=${encodeURIComponent(place)}`);
    if (job_functions.length !== 0)
      query.push(
        `job_functions=${encodeURIComponent(job_functions.join(",")).replace(
          "+",
          " "
        )}`
      );
    if (referral_reward) query.push(`referral_reward=true`);

    Object.keys(other).forEach((key) => {
      if (typeof other[key] === "string") query.push(`${key}=${other[key]}`);
    });

    if (query.length !== 0) {
      query_string = "?" + query.join("&");
    }
    return query_string;
  }

  function updateParametersToUri(replace = true, payload = {}) {
    const {
      page,
      work_types,
      remote_working,
      order,
      q,
      place,
      job_functions,
      referral_reward,
    } = jobs_parameters;
    const referral_code = get_value_from_query_string(
      "referral_code",
      location.search
    );
    const query_string = makeQueryString({
      page,
      work_types,
      remote_working,
      order,
      q,
      place,
      job_functions,
      referral_reward,
      referral_code,
      ...payload,
    });
    const internal_uri = new InternalUri();
    const url = `${internal_uri.formatted_frontend_jobs_page_path(
      query_string
    )}`;
    if (replace) {
      history.replace(url);
    } else {
      history.push(url);
    }
  }

  function makeQueryStringByJobsParameters() {
    const {
      page,
      work_types,
      remote_working,
      order,
      q,
      place,
      job_functions,
      referral_reward,
    } = jobs_parameters;
    const query_string = makeQueryString({
      page,
      work_types,
      remote_working,
      order,
      q,
      place,
      job_functions,
      referral_reward,
    });
    return query_string;
  }

  const toggleFiltersMenu = (e) => {
    e.preventDefault();
    let cur_state = open_all_filters;
    updateKeepSearchBar(cur_state);
    set_open_all_filters(!open_all_filters);
    smoothly_scroll_to(document.querySelector(".all-filters-wrapper"), 0, 100);
  };

  const applyFilters = function(e) {
    // 暫存尚未 submit 的 filters
    // e.preventDefault()
    jobs_parameters_copy.page = 1;
    updateParametersToUri(false, { ...jobs_parameters_copy });
    updateSearchKeywords(search_words.keyword, jobs_parameters_copy.place);
    if (open_all_filters) toggleFiltersMenu(e);
  };

  // const jobs_parameters_copy_timeout = useRef(null)
  // const applyFiltersWithDebounce = () => {
  //     clearTimeout(jobs_parameters_copy_timeout.current)
  //     jobs_parameters_copy_timeout.current = setTimeout(() => {
  //         applyFilters()
  //     }, debounceDelay)
  // }

  const handleJobsFilterChange = (
    e,
    variant,
    property_name,
    updateParametersLater = false
  ) => {
    e.preventDefault();
    const value = property_name;
    if (variant === "remote_working" || variant === "referral_reward") {
      // set_jobs_parameters_copy()
      jobs_parameters_copy[variant] = !jobs_parameters_copy[variant];
    } else if (variant === "place") {
      const place =
        jobs_parameters.place === property_name ? null : property_name;
      jobs_parameters_copy.place = place;
    } else {
      let arrays = jobs_parameters[variant];
      const index = arrays.indexOf(value);
      if (index === -1) {
        arrays.push(value);
      } else {
        arrays.splice(index, 1);
      }
      jobs_parameters_copy[variant] = arrays;
    }

    set_jobs_parameters_copy({ ...jobs_parameters_copy });

    if (updateParametersLater) {
      // this.forceUpdate();
      // this.isAllFiltersMenuChanged = true;
    } else {
      applyFilters();
      // applyFiltersWithDebounce()
      // useDebounce(applyFilters, debounceDelay);
    }
  };

  const clearJobsFilter = (e) => {
    e.preventDefault();
    if (open_all_filters) toggleFiltersMenu(e);
    updateSearchKeywords(jobs_parameters.q, "");
    updateParametersToUri(false, { ...INIT_JOBS_PARAMETERS });
  };

  // 排序邏輯
  const handleJobsSortingChange = (order) => {
    updateParametersToUri(false, {
      order: order.value,
      page: 1,
    });
  };

  const setCurrentPage = (currentPage) => {
    updateParametersToUri(false, { page: currentPage });
  };

  function fetchJobs(query_string) {
    // fetchController.abort()
    fetchController.current && fetchController.current.abort();
    fetchController.current = new AbortController();
    const url = config.api_url(
      `/jobs${query_string}&include=required_skills&external_job=true`
    );
    let options = {
      method: "GET",
      url: url,
      headers: headers.getItemsFromLocalStorage(),
      json: true,
      signal: fetchController.current.signal,
    };
    return axios(options);
  }

  const jobs_parameters = useMemo(() => {
    const next_query = location.search;
    const parameter_page = get_value_from_query_string("page", next_query);
    // console.log("parameter_page", parameter_page);
    const parameter_work_types = get_value_from_query_string(
      "work_types",
      next_query
    );
    const parameter_remote_working = get_value_from_query_string(
      "remote_working",
      next_query
    );
    const parameter_q = get_value_from_query_string("q", next_query);
    const parameter_place = get_value_from_query_string("place", next_query);
    const parameter_order = get_value_from_query_string("order", next_query);
    const parameter_job_functions = get_value_from_query_string(
      "job_functions",
      next_query
    );
    const parameter_referral_reward = get_value_from_query_string(
      "referral_reward",
      next_query
    );

    const original_jobs_parameters = {
      work_types: parameter_work_types ? parameter_work_types.split(",") : [],
      remote_working: parameter_remote_working === "true" ? true : false,
      order: parameter_order || "match",
      q: parameter_q ? parameter_q : search_words.keyword,
      place: parameter_place ? parameter_place : search_words.keyword_place,
      job_functions: parameter_job_functions
        ? parameter_job_functions.replace(/\+/g, "%20").split(",")
        : [],
      referral_reward: parameter_referral_reward === "true" ? true : false,
      page: Number(parameter_page) || 1,
    };

    return { ...original_jobs_parameters };
  }, [location.search]);

  const jobs_parameters_json = useMemo(() => {
    return JSON.stringify(jobs_parameters);
  }, [jobs_parameters]);

  const filter_count = useMemo(() => {
    let total = 0;
    if (jobs_parameters.remote_working) total += 1;
    if (jobs_parameters.referral_reward) total += 1;
    if (jobs_parameters.place) total += 1;
    total +=
      jobs_parameters.work_types.length + jobs_parameters.job_functions.length;
    return total;
  }, [jobs_parameters]);

  useEffect(() => {
    // 第先檢查網址是否有帶變數
    if (
      jobs_parameters.q !== search_words.keyword ||
      jobs_parameters.place !== search_words.keyword_place
    ) {
      updateSearchKeywords(
        jobs_parameters.q || search_words.keyword,
        jobs_parameters.place || search_words.keyword_place
      );
    }
  }, []);

  useEffect(() => {
    //  搜尋器變化
    updateParametersToUri(true, {
      q: search_words.keyword,
      place: search_words.keyword_place,
      page: get_value_from_query_string("page", location.search) || 1,
    });
  }, [search_words.keyword, search_words.keyword_place]);

  // 只需要執行一次 取得 job functions 和 countries 和打開搜索 bar
  useEffect(() => {
    function getJobFunctions() {
      const settings = {
        method: "GET",
        url: config.api_url("/job_meta/tags/job_functions"),
        headers: headers.getItemsFromLocalStorage(),
        json: true,
        signal: controller.signal,
      };

      axios(settings)
        .then((response) => {
          headers.updateItemsToLocalStorage(response.headers);
          updateConnectionOffModal(false);
          const job_functions_options_temp = [];
          const job_functions_temp = { ...job_functions };
          const raw_job_functions = response.data.sort((a, b) => {
            return a.seq_no - b.seq_no;
          });
          // 一次新增所有的翻譯
          const resourceEn = {};
          const resourceZh = {};
          raw_job_functions.forEach((job_function) => {
            const key = `${job_function.id}`;
            resourceEn[key] = job_function.name_en;
            resourceZh[key] = job_function.name_zh_tw;
          });

          i18n.addResourceBundle("en", "job_functions", {
            ...resourceEn,
          });
          i18n.addResourceBundle("zh-TW", "job_functions", {
            ...resourceZh,
          });

          for (let i = 0; i < raw_job_functions.length; i++) {
            // 這邊會一直觸發渲染
            const job_function = new JobFunction(raw_job_functions[i]);
            if (
              job_function.available_taggings_count > 0 &&
              // TODO: 確認是否有 query filter 的形式
              job_function.is_published
            ) {
              job_functions_options_temp.push(`${job_function.id}`);
              job_functions_temp["en"][job_function.id] = job_function.name_en;
              job_functions_temp["zh-TW"][job_function.id] =
                job_function.name_zh_tw;
            }
          }

          set_options({
            ...options,
            job_functions: [...job_functions_options_temp],
          });

          set_job_functions({
            ...job_functions_temp,
          });
        })
        .catch((error) => {
          if (error instanceof CanceledError) return;
          if (error.response) {
            headers.updateItemsToLocalStorage(error.response.headers);
            if (error.response.status === 401) window.location.reload();
          }

          if (typeof error.response === "undefined")
            updateConnectionOffModal(true);
          const error_messages =
            error.response &&
            error.response.data &&
            error.response.data.status === "error"
              ? error.response.data.errors
              : { full_message: "There was an error. Please try again later." };
          set_jobs([]);
          set_error_messages(error_messages);
          page_smothly_scroll_to(0, 300);
          set_paginator({ ...INIT_PAGINATOR });
          updateParametersToUri(true, {
            page: 1,
          });
          page_smothly_scroll_to(0, 300);
        });
    }

    // 打開搜尋 bar
    updateKeepSearchBar(true);

    // 取得 jobfunction
    getJobFunctions();

    return () => {
      // 收起搜尋 bar
      updateKeepSearchBar(false);
      // 重置搜尋器
      updateSearchKeywords("", "");
      // 取消所有非同步請求
      controller.abort();
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    getTopCountry().then((response) => {
      if (!isMounted) return;
      let countries = [];
      for (let country in response.data) {
        const { name, translated_name, jobs_count } = response.data[country];
        countries.push({
          name,
          translated_name,
          jobs_count,
        });
      }
      countries.sort((a, b) => {
        return b.jobs_count - a.jobs_count;
      });
      const countriesDisplay = countries.slice(0, 6);
      set_country_list([...countriesDisplay]);
    });
    return () => {
      isMounted = false;
    };
  }, [lng]);

  useEffect(() => {
    set_jobs_parameters_copy({ ...jobs_parameters });
    async function fetchIt() {
      const query_string = makeQueryStringByJobsParameters();
      set_loading(true);
      try {
        const response = await fetchJobs(query_string);
        headers.updateItemsToLocalStorage(response.headers);
        updateConnectionOffModal(false);
        const raw_jobs = response.data;
        let jobs = [];
        for (let i = 0; i < raw_jobs.collection.length; i++) {
          jobs.push(new Job(raw_jobs.collection[i]));
        }
        set_jobs(jobs);
        set_error_messages({});
        set_loading(false);
        set_paginator({
          ...raw_jobs.paginator,
        });
      } catch (error) {
        if (error instanceof CanceledError) return;
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (typeof error.response === "undefined")
          updateConnectionOffModal(true);
        const error_messages =
          error.response &&
          error.response.data &&
          error.response.data.status === "error"
            ? error.response.data.errors
            : { full_message: "There was an error. Please try again later." };
        set_jobs([]);
        set_loading(false);
        set_error_messages(error_messages);
        page_smothly_scroll_to(0, 300);
      }
    }
    // 如果沒有 page 和 order 就轉址
    const parameter_page = get_value_from_query_string("page", location.search);
    const parameter_order = get_value_from_query_string(
      "order",
      location.search
    );
    if (!parameter_page || !parameter_order) {
      updateParametersToUri();
    }

    fetchIt();

    return () => {
      fetchController.current && fetchController.current.abort();
    };
  }, [jobs_parameters_json]);

  return (
    <div
      className={`page-jobs content-wrapper ${
        open_all_filters ? "open-all-filters" : ""
      }`}
    >
      <Helmet>
        <title>
          {t("general:all__jobs_", {
            _q: contain_not_only_whitespace(keyword)
              ? t("general:_q", { keyword_q: keyword })
              : "",
            _in_place: contain_not_only_whitespace(keyword_place)
              ? t("general:_in_place", { keyword_place })
              : "",
          })}
          ｜Meet.jobs
        </title>
        <meta
          property="og:title"
          content={t("general:all__jobs_", {
            _q: contain_not_only_whitespace(keyword)
              ? t("general:_q", { keyword_q: keyword })
              : "",
            _in_place: contain_not_only_whitespace(keyword_place)
              ? t("general:_in_place", { keyword_place })
              : "",
          })}
        />
        <meta
          name="description"
          content={t(
            "general:browse_all___jobs___meet_jobs_is_a_headhunter_platform_focusing_on_international_talents_and_jobs_",
            {
              _q: contain_not_only_whitespace(keyword)
                ? t("general:_q", { keyword_q: keyword })
                : "",
              _near_place: contain_not_only_whitespace(keyword_place)
                ? t("general:_near_place", { keyword_place })
                : "",
            }
          )}
        />
      </Helmet>
      <UseReferralUrlWrapper />
      <div className="filter-bar-wrapper">
        <div className="container">
          <div className="tablet-wrapper">
            <div className="filter-bar">
              <button
                className="btn btn-smaller btn-input-keywords"
                onClick={(e) => {
                  e.preventDefault();
                  updateSearchBar(true);
                }}
              >
                <h5>
                  <img src={icon_search} alt="" />
                  {keyword ? keyword : t("general:keyword")} •{" "}
                  {keyword_place ? keyword_place : t("general:city_or_country")}
                </h5>
              </button>
              <button
                className="btn btn-smaller btn-flat btn-hollow btn-filters"
                onClick={(e) => toggleFiltersMenu(e)}
              >
                <img src={icon_filter} alt="" />
                <h5>{t("filters")}</h5>
                {filter_count ? (
                  <span className="counting-block counting-block-red">
                    {filter_count}
                  </span>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="all-filters-wrapper">
        <div
          className="all-filters-bg"
          onClick={(e) => applyFilters(e)}
          data-event-category="207_filter_click_outside"
        />
        <div className="all-filters">
          <div className="container">
            <div className="inner-wrapper">
              <h4>{t("all_filters")}</h4>
              <h6>{t("meet_jobs_featured")}</h6>
              <div className="checkboxs">
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={jobs_parameters_copy.referral_reward}
                    readOnly
                  />
                  <label
                    htmlFor="referral-reward"
                    className="checkbox"
                    onClick={(e) =>
                      handleJobsFilterChange(e, "referral_reward", "", true)
                    }
                    data-event-category="201_filter_featured"
                  >
                    <p className="referral-reward-box">
                      <IconRefer />
                      {t("general:referral_reward")}
                    </p>
                  </label>
                </div>
              </div>

              <h6>{t("remote")}</h6>
              <div className="checkboxs">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  id="remote-job"
                  checked={jobs_parameters_copy.remote_working}
                  readOnly
                />
                <label
                  htmlFor="remote-job"
                  className="checkbox"
                  onClick={(e) =>
                    handleJobsFilterChange(e, "remote_working", "", true)
                  }
                  data-event-category="202_filter_checkbox"
                >
                  <p>{t("allowing_remote")}</p>
                </label>
              </div>
              {country_list.length ? (
                <React.Fragment>
                  <h6>{t("general:country")}</h6>
                  <div className="checkboxs">
                    {country_list.map(({ name, translated_name }, i) => (
                      <div key={i} className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          id={name}
                          checked={jobs_parameters_copy.place === name}
                          readOnly
                        />
                        <label
                          htmlFor={name}
                          className="checkbox"
                          onClick={(e) =>
                            handleJobsFilterChange(e, "place", name, true)
                          }
                          data-event-category="202_filter_checkbox"
                        >
                          <p>{translated_name}</p>
                        </label>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              <h6>{t("job_type")}</h6>
              <div className="checkboxs">
                {options.work_types.map((work_type, i) => (
                  <div key={i} className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      id={work_type}
                      checked={
                        jobs_parameters_copy.work_types.indexOf(work_type) !==
                        -1
                      }
                      readOnly
                    />
                    <label
                      htmlFor={work_type}
                      className="checkbox"
                      onClick={(e) =>
                        handleJobsFilterChange(e, "work_types", work_type, true)
                      }
                      data-event-category="202_filter_checkbox"
                    >
                      <p>{t(work_type)}</p>
                    </label>
                  </div>
                ))}
              </div>

              {options.job_functions.length > 0 ? (
                <h6>{t("general:job_function")}</h6>
              ) : (
                ""
              )}
              {options.job_functions.length > 0 ? (
                <div className="checkboxs">
                  {options.job_functions.map((id, i) => (
                    <div key={i} className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        id={`job_function_${id}`}
                        checked={
                          jobs_parameters_copy.job_functions.indexOf(
                            job_functions[i18n.language][id]
                          ) !== -1
                        }
                        readOnly
                      />
                      <label
                        htmlFor={`job_function_${id}`}
                        className="checkbox"
                        onClick={(e) =>
                          handleJobsFilterChange(
                            e,
                            "job_functions",
                            job_functions[i18n.language][id],
                            true
                          )
                        }
                        data-event-category="202_filter_checkbox"
                      >
                        <p>{t(`job_functions:${id}`)}</p>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}

              <div className="buttons-wrapper">
                <button
                  className="btn btn-larger btn-flat btn-fill btn-ghost"
                  onClick={(e) => clearJobsFilter(e)}
                  data-event-category="205_filter_clear"
                >
                  <h5>{t("general:clear")}</h5>
                </button>
                <button
                  className="btn btn-larger btn-flat btn-fill"
                  onClick={(e) => applyFilters(e)}
                  data-event-category="206_filter_ok"
                >
                  <h5>{t("apply")}</h5>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container">
        <div className="row content-row">
          <div className="col-md-2 left-col-custom-padding content-top">
            <div className="sidebar-filters">
              <h6 className="hint-title">{t("meet_jobs_featured")}</h6>
              <div className="checkboxs">
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={jobs_parameters_copy.referral_reward}
                    readOnly
                  />
                  <label
                    htmlFor="referral-reward"
                    className="checkbox"
                    onClick={(e) =>
                      handleJobsFilterChange(e, "referral_reward", "")
                    }
                    data-event-category="201_filter_featured"
                  >
                    <p className="referral-reward-box">
                      <IconRefer />
                      {t("general:referral_reward")}
                    </p>
                  </label>
                </div>
              </div>
              <h6>{t("remote")}</h6>
              <div className="checkboxs">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  id="remote-job"
                  checked={jobs_parameters_copy.remote_working}
                  readOnly
                />
                <label
                  htmlFor="remote-job"
                  className="checkbox"
                  onClick={(e) =>
                    handleJobsFilterChange(e, "remote_working", "")
                  }
                  data-event-category="202_filter_checkbox"
                >
                  <p>{t("allowing_remote")}</p>
                </label>
              </div>

              {country_list.length ? (
                <React.Fragment>
                  <h6>{t("general:country")}</h6>
                  <div className="checkboxs">
                    {country_list.map(({ name, translated_name }, i) => (
                      <div key={i} className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          id={name}
                          checked={jobs_parameters_copy.place === name}
                          readOnly
                        />
                        <label
                          htmlFor={name}
                          className="checkbox"
                          onClick={(e) =>
                            handleJobsFilterChange(e, "place", name)
                          }
                          data-event-category="202_filter_checkbox"
                        >
                          <p>{t(translated_name)}</p>
                        </label>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              <h6 className="hint-title">{t("job_type")}</h6>
              <div className="checkboxs">
                {options.work_types.map((work_type, i) => (
                  <div key={i} className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      id={work_type}
                      checked={
                        jobs_parameters_copy.work_types.indexOf(work_type) !==
                        -1
                      }
                      readOnly
                    />
                    <label
                      htmlFor={work_type}
                      className="checkbox"
                      onClick={(e) =>
                        handleJobsFilterChange(e, "work_types", work_type)
                      }
                      data-event-category="202_filter_checkbox"
                    >
                      <p>{t(work_type)}</p>
                    </label>
                  </div>
                ))}
              </div>

              {options.job_functions.length > 0 ? (
                <h6 className="hint-title">{t("general:job_function")}</h6>
              ) : (
                ""
              )}
              {options.job_functions.length > 0 ? (
                <div className="checkboxs">
                  {options.job_functions.map((id, i) =>
                    i < 5 ? (
                      <div key={i} className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          id={`job_function_${id}`}
                          checked={
                            jobs_parameters_copy.job_functions.indexOf(
                              job_functions[i18n.language][id]
                            ) !== -1
                          }
                          readOnly
                        />
                        <label
                          htmlFor={`job_function_${id}`}
                          className="checkbox"
                          onClick={(e) =>
                            handleJobsFilterChange(
                              e,
                              "job_functions",
                              job_functions[i18n.language][id]
                            )
                          }
                          data-event-category="202_filter_checkbox"
                        >
                          <p>{t(`job_functions:${id}`)}</p>
                        </label>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                  <button
                    className="link"
                    onClick={(e) => toggleFiltersMenu(e)}
                    data-event-category="203_filter_more_function"
                  >
                    <h5>{t("more_job_functions").toLowerCase()}</h5>
                  </button>
                </div>
              ) : (
                ""
              )}

              <button
                className="btn btn-larger btn-flat btn-hollow btn-filters"
                onClick={(e) => toggleFiltersMenu(e)}
                data-event-category="204_filter_all"
              >
                <img src={icon_filter} alt="" />
                <h5>{t("all_filters")}</h5>
                {filter_count ? (
                  <span className="counting-block counting-block-light-grey">
                    {filter_count}
                  </span>
                ) : (
                  ""
                )}
              </button>
              {filter_count ? (
                <button
                  className="btn btn-larger btn-flat btn-fill btn-ghost"
                  onClick={(e) => clearJobsFilter(e)}
                  data-event-category="205_filter_clear"
                >
                  <h5>{t("general:clear")}</h5>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-7 content-left">
            <div className="tablet-wrapper">
              {!loading ? (
                <div>
                  <div className="job-sorting-states">
                    <h6 className="hint-title">
                      {paginator.total_entries > 0
                        ? `${
                            paginator.total_entries
                              ? (paginator.current_page - 1) * 20 + 1
                              : 0
                          } - ${
                            paginator.total_pages === paginator.current_page
                              ? paginator.total_entries
                              : paginator.current_page * 20
                          } of ${
                            paginator.total_entries
                              ? paginator.total_entries
                              : 0
                          } jobs`
                        : "no jobs found"}
                    </h6>
                    <div className="select-wrapper">
                      <Select
                        className="drop-down drop-down-larger drop-down-ghost"
                        clearable={false}
                        searchable={false}
                        value={jobs_parameters.order}
                        onChange={handleJobsSortingChange}
                        options={[
                          { value: "match", label: t("best_match") },
                          { value: "update", label: t("by_update") },
                          { value: "salary", label: t("by_salary") },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="job-cards">
                    {error_messages.full_message ? (
                      <h6 className="message error-message">
                        {error_messages.full_message}
                      </h6>
                    ) : (
                      ""
                    )}
                    {jobs.length !== 0 || paginator.total_entries ? (
                      jobs.map((job, i) => (
                        <JobCardWrapper
                          key={i}
                          job={job}
                          eventCategories={{
                            whole: "212_card_whole",
                            employer: "213_card_employer",
                            location: "214_card_location",
                            save: "215_card_save",
                          }}
                        />
                      ))
                    ) : (
                      <div className="blank-search-wrapper">
                        <div className="graph">
                          <div
                            className="image-wrapper"
                            style={{ backgroundImage: `url(${blank_search})` }}
                          />
                        </div>
                        <h2>{t("we_re_sorry_")}</h2>
                        <p>
                          {t(
                            "we_can_t_seem_to_find_any_job_openings_that_match_your_search_"
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                  {paginator.total_entries ? (
                    <div>
                      <div className="pagination-wrapper-pc hidden-xs hidden-sm">
                        <Pagination
                          currentPage={paginator.current_page}
                          pagesCount={paginator.total_pages}
                          pagedCount={5}
                          previous_page={paginator.previous_page}
                          next_page={paginator.next_page}
                          setCurrentPage={setCurrentPage}
                        />
                      </div>
                      <div className="pagination-wrapper-mb">
                        <Pagination
                          currentPage={paginator.current_page}
                          pagesCount={paginator.total_pages}
                          pagedCount={4}
                          previous_page={paginator.previous_page}
                          next_page={paginator.next_page}
                          setCurrentPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="loading-skeleton-wrapper">
                  <div className="job-sorting-states">
                    <h6 className="hint-title">00-00 of 000 jobs</h6>
                  </div>
                  <div className="job-cards">
                    <JobCardLoader />
                    <JobCardLoader />
                    <JobCardLoader />
                    <JobCardLoader />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3 right-col-custom-padding content-right">
            <div className="marketing-materials-wrapper">
              {ads && ads.length
                ? ads.map((raw_ad, i) => {
                    const ad = new Ad(raw_ad);
                    return (
                      <MarketingBanner
                        key={i}
                        type="vertical"
                        id={ad.id}
                        url={ad.get_url()}
                        banner_url={ad.get_img_url()}
                        content={ad.formatted_title()}
                        // parameters={{...this.eventMarketingParameters, utm_campaign: ad.formatted_title()}}
                        eventCategory="216_marketing_search"
                      />
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default withNamespaces(["frontend_jobs_page", "general"])(JobsPage);
