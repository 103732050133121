import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import InternalUri from "../../models/internal_uri";

class Prompt extends React.Component {
    constructor(props) {
        super(props);
        this.onCancel = this.onCancel.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.state = {
            nextLocation: null,
            openModal: false
        };
    };

    componentDidMount() {
        const internal_uri = new InternalUri();
        this.unblock = this.props.history.block((nextLocation) => {
            if(
                this.props.when
                && internal_uri.formatted_path_with_new_language(this.props.location.pathname, 'en') !== internal_uri.formatted_path_with_new_language(nextLocation.pathname, 'en')
            ) {
                this.setState({
                    openModal: true,
                    nextLocation: nextLocation
                });
            }
            return !this.props.when;
        });
    };

    componentWillUnmount() {
        this.unblock();
    };

    onCancel() {
        this.setState({nextLocation: null, openModal: false});
    };

    onConfirm() {
        this.navigateToNextLocation();
    };

    navigateToNextLocation() {
        this.unblock();
        this.props.history.push(this.state.nextLocation.pathname);
    };

    render() {
        return (
            <div>
                {this.props.children(this.state.openModal, this.onConfirm, this.onCancel)}
            </div>
        );
    };
}

Prompt.propTypes = {
    when: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired,
};

export default withRouter(Prompt);