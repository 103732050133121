export default {
    next_billing_date_: "下次收費日期：",
    free_trial_expiration_date_: "免費試用到期日：",
    to_save_your_changes__click_save_: "要儲存以下輸入的變更，請按「儲存」。",
    credit_card: "信用卡",
    remittance: "匯款",
    post_your_jobs_today_: "今天就來發佈工作機會吧！",
    add_credit_card: "新增信用卡",
    _meet_jobs_does_not_save_your_credit_card_information: "*Meet.jobs 並不會儲存您的信用卡資訊",
    please_contact_me_through_email___phone_number_: "請用Email / 電話來與我聯繫",
    credit_or_debit_card: "信用卡或簽帳金融卡",
    current_card_: "目前的卡：",
    if_you_have_further_questions__please___meet_jobs_: "如果你有任何問題，請{{contact}} Meet.jobs。",
    contact: "聯繫",
};