export default {

    // add reference notes modal
    add_reference_letter: "Add reference",
    name: "Name",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    phone_number: "Phone number",
    let_us_know_more_about_this_candidate_: "Let us know more about this candidate.",
    i_realize_that_the_reference_letter_can_not_be_modified_once_it_is_sent_: "I realize that the reference letter can NOT be modified once it is sent.",

    // candidate message modal
    title: "Title",
    location: "Location",
    date: "Date",
    time: "Time",
    to: "to",
    file_name: "File name",
    type_something___: "Type something...",
    after_you_got_the_job_offer__please_let_meet_jobs_know_about_it__we_would_grant_you___reward_: "After you got the job offer, please let Meet.jobs know about it. We would grant you <span>100 USD</span> reward.",
    got_the_job_offer: "Got the job offer",
    cancel_application: "Cancel application",
    please_be_sure_you_have_passed_the_interview_and_processed_all_the_details_of_this_role_: "Please be sure you have passed the interview and processed all the details of this role.",
    would_you_like_to_confirm_your_job_offer_and_get_100_usd_onboard_reward_from_meet_jobs_: "Would you like to confirm your job offer and get 100 USD Onboard Reward from Meet.jobs?",
    are_you_sure_to_cancel_your_application_: "Are you sure to Cancel your application?",
    by__: "by {{referrer_name}}",

    // read reference notes modal
    read_reference_letter: "Read reference",

    // claim referral reward modal
    claim_referral_reward: "Claim referral reward",
    please_be_sure___has_got_the_offer__the_employer_would_also_receive_a_notice_to_approve_: "Please be sure {{applier_name}} has got the offer. The employer would also receive a notice to approve.",

    // my referral link modal
    my_referral_link: "My referral link",
    how_it_works_: "How it works?",
    _1__share_your_referral_link_to_friends_: "1. Share your referral link to friends.",
    _2__or_enter_your_referral_code___when_he_or_she_applies_: "2. Or enter your referral code {{referral_code}} when he or she applies.",
    _3__when_candidates_are_successfully_hired_and_pass_the_probation_claim_your_referral_cash_reward_: "3. When candidates are successfully hired and pass the probation, claim your referral cash reward!",
};