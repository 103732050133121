import React from 'react';
import ReactTable from 'react-table';
import moment from "moment/moment";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import InvoiceDetail from "../../dashboard/InvoiceDetail.jsx";
import { withNamespaces } from "react-i18next";
import {Link} from "react-router-dom";
import {download_file, get_value_from_query_string, page_smothly_scroll_to} from "../../../../assets/js/functions";
import InternalUri from "../../../models/internal_uri";
import Invoice from "../../../models/invoice";
import DeviceDetector from "../../../models/device_detector";

const config = new Config();
const headers = new ApiHeaders();

class InvoicesPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.updateActiveCompany = this.updateActiveCompany.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.state = {
            active_company_id: -1,
            opening_invoice_number: null,
            data: [],
            invoices: {},
            loading: true,
            error_messages: {},
        };
    };

    componentDidMount() {
        this._isMounted = true;

        let invoice_number = null;
        const query = decodeURI(this.props.location.search);
        if(query) {
            invoice_number = get_value_from_query_string('number', query);
        }
        this.setState({
            opening_invoice_number: invoice_number
        }, () => this.updateActiveCompany(this.props.current_user.active_company.id));
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(this.props.current_user.active_company.id !== next_props.current_user.active_company.id) {
            this.updateActiveCompany(next_props.current_user.active_company.id);
        }

        if(next_props.location.search !== this.props.location.search) {
            let invoice_number = null;
            const query = decodeURI(next_props.location.search);
            if(query) {
                invoice_number = get_value_from_query_string('number', query);
            }
            this.setState({
                opening_invoice_number: invoice_number
            }, () => this.updateActiveCompany(next_props.current_user.active_company.id));
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    updateActiveCompany(company_id) {
        const { current_user } = this.props;
        this.setState((prev_state) => ({
            ...prev_state,
            active_company_id: company_id,
            access_control_list: current_user.employer_roles[company_id].access_control_list,
            loading: true,
        }), () => this.fetchData());
    };

    fetchData() {
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${this.state.active_company_id}/invoices`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    this.paginator = response.data.paginator;
                    const raw_invoices = response.data.collection;
                    let invoices_for_table = [], invoices = {}, invoice;
                    for(let i=0; i<raw_invoices.length; i++) {
                        invoice = raw_invoices[i];

                        invoices_for_table.push({
                            invoice: JSON.stringify(invoice),
                            number: JSON.stringify(invoice),
                            description: invoice.description,
                            cost: [invoice.cost, invoice.currency],
                            pay_type: invoice.payment,
                            status: JSON.stringify(invoice),
                            pdf: JSON.stringify(invoice)
                        });
                        invoices[invoice.invoice_number] = {...invoice};
                    }
                    this.setState((prev_state) => ({
                        ...prev_state,
                        data: invoices_for_table,
                        invoices,
                        error_messages: {},
                        loading: false,
                    }));
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState({
                        error_messages: {
                            full_message:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors.full_message :
                                    'There was an error. Please try again later.'
                        },
                        loading: false
                    });

                    page_smothly_scroll_to(0, 300);
                }
            });
    };

    handleDownload = (e, url, filename) => {
        e.preventDefault();
        download_file(url, filename);
    };

    render() {

        const { data, access_control_list, opening_invoice_number, invoices, loading } = this.state;
        const { handleDownload } = this;
        const { t } = this.props;
        const internal_uri = new InternalUri();
        const device_detector = new DeviceDetector();

        if( loading ) return <div className="dashboard-invoices" />;

        if(!access_control_list.billing_manageable) {
            return (
                <div className="dashboard-invoices">
                    <div className="container-fluid">
                        <h6 className="breadcrumb">
                            <Link to={internal_uri.formatted_dashboard_index_page_path()}>{t('general:home')}</Link>
                            {' / '}
                            {t('general:invoices')}
                        </h6>
                        <div className="title-with-buttons">
                            <h2>{t('general:invoices')}</h2>
                        </div>
                        {t('general:you_don_t_have_the_proper_privilege_level_to_visit_this_page_')}
                    </div>
                </div>
            );
        }

        const columns_pc = [{
            Header: () => <h5>{t('invoice_no_')}</h5>,
            id: 'number',
            accessor: 'number',
            Cell: props => {
                const invoice = new Invoice(JSON.parse(props.value));
                return (
                    (invoice.invoice_pdf.url) ?
                        <div>
                            <Link
                                className="link"
                                to={`${internal_uri.formatted_dashboard_invoices_page_path()}?number=${invoice.invoice_number}`}
                            >
                                <h5>{invoice.invoice_number}</h5>
                            </Link>
                            <Link className="row-link" to={`${internal_uri.formatted_dashboard_invoices_page_path()}?number=${invoice.invoice_number}`} />
                        </div> :
                        <div>
                            <button onClick={ e => e.preventDefault() } className="link disabled">
                                <h5>{invoice.invoice_number}</h5>
                            </button>
                        </div>
                )
            },
            width: 130
        }, {
            Header: () => <h5>{t('description')}</h5>,
            id: 'description',
            accessor: 'description',
            Cell: props => (
                <h6>{props.value}</h6>
            ),
        }, {
            Header: () => <h5>{t('cost')}</h5>,
            accessor: 'cost',
            Cell: props => (
                <div>
                    <h6 className='number'>{props.value[0]}<br/>({props.value[1]})</h6>
                </div>
            ),
            width: 120
        }, {
            Header: () => <h5>{t('pay_type')}</h5>,
            accessor: 'pay_type',
            Cell: props => {
                const pay_type = props.value;
                return <h6>{t(`states:${pay_type}`)}</h6>
            },
            width: 120
        }, {
            Header: () => <h5>{t('status')}</h5>,
            accessor: 'status',
            Cell: props => {
                const invoice = new Invoice(JSON.parse(props.value));
                return (
                    <div>
                        {(invoice.due_date) ? <h6 className="due-time">{t('general:due_on')} {moment(invoice.due_date).format('YYYY/MM/DD')}</h6> : ''}
                        {
                            (invoice.formatted_primary_status_key() === 'paid') ?
                                <div>
                                    <span className="badge badge-green">{t('states:paid')}</span>
                                    {(invoice.paid_at) ? <h6 className="paid-time">{moment(invoice.paid_at).format('YYYY/MM/DD')}</h6> : ''}
                                </div> : ''
                        }
                        {
                            (invoice.formatted_primary_status_key() === 'cancelled') ?
                                <div>
                                    <span className="badge badge-grey">{t('states:cancelled')}</span>
                                </div> : ''
                        }
                        {
                            (invoice.formatted_primary_status_key() === 'overdue') ?
                                <div>
                                    <span className="badge badge-yellow">{t('states:overdue')}</span>
                                </div> : ''
                        }
                    </div>
                );
            },
            width: 250
        }, {
            Header: () => <h5>{t('pdf')}</h5>,
            accessor: 'pdf',
            Cell: props => {
                const invoice = new Invoice(JSON.parse(props.value));
                return (
                    (invoice.invoice_pdf.url) ?
                        (
                            (device_detector.is_mobile_or_tablet()) ?
                                <a
                                    className="btn btn-smaller btn-ghost btn-flat btn-download"
                                    href={invoice.formatted_pdf_url()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <h5>{t('general:download')}</h5>
                                </a> :
                                <button
                                    className="btn btn-smaller btn-ghost btn-flat btn-download"
                                    onClick={ e => handleDownload(e, invoice.formatted_pdf_url(), invoice.formatted_pdf_filename()) }
                                >
                                    <h5>{t('general:download')}</h5>
                                </button>
                        ) :
                        <button onClick={ e => e.preventDefault() } className="btn btn-smaller btn-ghost btn-flat btn-download disabled">
                            <h5>{t('general:download')}</h5>
                        </button>
                )
            },
            width: 150
        }];

        // 992px
        const columns_tl = [{
            Header: () => null,
            accessor: 'invoice',
            Cell: props => {
                const invoice = new Invoice(JSON.parse(props.value));
                return (
                    <table>
                        {(invoice.invoice_pdf.url) ? <Link className="row-link" to={`${internal_uri.formatted_dashboard_invoices_page_path()}?number=${invoice.invoice_number}`} /> : ''}
                        <tbody>
                            <tr>
                                <th><h5>{t('invoice_no_')}</h5></th>
                                <td>
                                    {
                                        (invoice.invoice_pdf.url) ?
                                            <Link
                                                className="link"
                                                to={`${internal_uri.formatted_dashboard_invoices_page_path()}?number=${invoice.invoice_number}`}
                                            >
                                                <h5>{invoice.invoice_number}</h5>
                                            </Link> :
                                            <a onClick={ e => e.preventDefault() } href="/#invoice" className="link disabled">
                                                <h5>{invoice.invoice_number}</h5>
                                            </a>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th><h5>{t('description')}</h5></th>
                                <td>
                                    <h6>{invoice.description}</h6>
                                </td>
                            </tr>
                            <tr>
                                <th><h5>{t('cost')}</h5></th>
                                <td><h6 className='number'>{invoice.cost}<br/>({invoice.currency})</h6></td>
                            </tr>
                            <tr>
                                <th><h5>{t('pay_type')}</h5></th>
                                <td><h6>{t(`states:${invoice.payment}`)}</h6></td>
                            </tr>
                            <tr>
                                <th><h5>{t('status')}</h5></th>
                                <td>
                                    {(invoice.due_date) ? <h6 className="due-time">{t('general:due_on')} {moment(invoice.due_date).format('YYYY/MM/DD')}</h6> : ''}
                                    {
                                        (invoice.formatted_primary_status_key() === 'paid') ?
                                            <div>
                                                <span className="badge badge-green">{t('states:paid')}</span>
                                                {(invoice.paid_at) ? <h6 className="paid-time">{moment(invoice.paid_at).format('YYYY/MM/DD')}</h6> : ''}
                                            </div> : ''
                                    }
                                    {
                                        (invoice.formatted_primary_status_key() === 'cancelled') ?
                                            <div>
                                                <span className="badge badge-grey">{t('states:cancelled')}</span>
                                            </div> : ''
                                    }
                                    {
                                        (invoice.formatted_primary_status_key() === 'overdue') ?
                                            <div>
                                                <span className="badge badge-yellow">{t('states:overdue')}</span>
                                            </div> : ''
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th><h5>{t('pdf')}</h5></th>
                                <td>
                                    {
                                        (invoice.invoice_pdf.url) ? (
                                            (device_detector.is_mobile_or_tablet()) ?
                                                <a
                                                    className="link"
                                                    href={invoice.formatted_pdf_url()}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <h5>{t('general:download')}</h5>
                                                </a> :
                                                <a
                                                    href="/#download"
                                                    className="link"
                                                    onClick={ e => handleDownload(e, invoice.formatted_pdf_url(), invoice.formatted_pdf_filename()) }
                                                >
                                                    <h5>{t('general:download')}</h5>
                                                </a>
                                        ) :
                                        <button className="link disabled">
                                            <h5>{t('general:download')}</h5>
                                        </button>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )
            },
        }];

        return (
            (opening_invoice_number === null || !invoices[opening_invoice_number]) ?
                <div className="dashboard-invoices">
                    <div className="container-fluid">
                        <h6 className="breadcrumb">
                            <Link to={internal_uri.formatted_dashboard_index_page_path()}>{t('general:home')}</Link>
                            {' / '}
                            {t('general:invoices')}
                        </h6>
                        <h2>{t('general:invoices')}</h2>

                        <div className="table-wrapper-pc table-wrapper block">
                            <ReactTable
                                loading={loading}
                                data={data}
                                columns={columns_pc}
                                minRows={0}
                                showPagination={false}
                                noDataText={t('general:your_list_is_empty_')}
                            />
                        </div>
                        <div className="table-wrapper-tl table-wrapper block">
                            <ReactTable
                                loading={loading}
                                data={data}
                                columns={columns_tl}
                                minRows={0}
                                showPagination={false}
                                noDataText={t('general:your_list_is_empty_')}
                            />
                        </div>
                    </div>
                </div> :
                <InvoiceDetail invoice={invoices[opening_invoice_number]} />
        );
    };
}

export default withNamespaces(['dashboard_invoices_page', 'general', 'states'])(InvoicesPage);
