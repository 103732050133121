import React,{useEffect} from "react";
import {get_value_from_query_string} from "../../../assets/js/functions";
import ReferralUserBottom from "./ReferralUserBottom";
import Cookies from "js-cookie";
// import {ReferralUserBottomWrapper} from "../../containers/frontend";


const UseReferralUrl = ({location,reduxTokenAuth,addCurrentUserReferralCode, history, ...props})=>{ 


    useEffect(()=>{
        const query = decodeURI(location.search)
        let referral_code =null
        let user_referral_code = null

        if(reduxTokenAuth.currentUser.isSignedIn){
            user_referral_code = reduxTokenAuth.currentUser.attributes.referral_code;
        }else{
            // console.log('clear')
            if (typeof window !== undefined) {
                Cookies.remove('referral_code',{ domain: window.location.hostname })
            }
        }
        if(query){
            referral_code = get_value_from_query_string('referral_code', query);
        }
        if(!referral_code && user_referral_code) {
            if (typeof window !== undefined) {
                Cookies.set('referral_code', user_referral_code, { domain: window.location.hostname })
            }
            const url = `${location.pathname}${query ? `${query}&` : "?"}referral_code=${user_referral_code}`
            history.replace(url);
        }
    },[location.search])


    return (<ReferralUserBottom addCurrentUserReferralCode={addCurrentUserReferralCode} location={location} reduxTokenAuth={reduxTokenAuth}/>)
}


export default UseReferralUrl