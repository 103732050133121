import React from "react";
import Textarea from "react-textarea-autosize";
import moment from "moment";
import {
	contain_not_only_whitespace,
	linkify,
	download_file,
	smoothly_scroll_to,
} from "../../../../assets/js/functions.js";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import JobApplication from "../../../models/job_application.js";
import Message from "../../../models/message.js";
import { withNamespaces, Interpolate } from "react-i18next";
import icon_attachment from "../../../../assets/images/icon/icon_24_attachment_BG400.svg";
import icon_ok from "../../../../assets/images/icon/icon_28_ok_white.svg";
import { CSSTransitionGroup } from "react-transition-group";
import MeetJobsLoader from "../../general/MeetJobsLoader.jsx";
import { Link } from "react-router-dom";
import icon_redirect from "../../../../assets/images/icon/icon_24_redirect_B100.svg";
import InternalUri from "../../../models/internal_uri";
import modal_banner_report from "../../../../assets/images/i043-got-offer.svg";
import modal_pop_up_cancel from "../../../../assets/images/i048-pop-ups-cancel.svg";
import DeviceDetector from "../../../models/device_detector";

const config = new Config();
const headers = new ApiHeaders();

class ReferralMessageModal extends React.Component {
	constructor(props) {
		super(props);

		this.closeModal = this.closeModal.bind(this);

		this.state = {
			application: {},
			active_job_id: -1,
			active_application_id: -1,
			active_user_id: -1,
			messages: [],
			form_data: {
				message: {
					content: "",
					attachment: {},
				},
			},
			loading: true,
			loading_conversations: true,
			sending: false,
			sending_change_state: false,
			loading_more: false,
			change_state_message_modal: {
				open: false,
				action_type: "",
				title: "",
				text: "",
				application_type: "",
				next_state: "",
			},
			error_messages: {},
		};
		this.paginator = {
			message: {},
		};
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		console.log("refModal_UNSAFE_componentWillReceiveProps", next_props);
		const {
			open,
			job_id,
			application_id,
		} = next_props.site_state.referral_message_modal;
		const active_user_id = next_props.reduxTokenAuth.currentUser.attributes.id;
		if (
			this.props.site_state.referral_message_modal.open !== open ||
			this.props.site_state.referral_message_modal.job_id !== job_id ||
			this.props.site_state.referral_message_modal.application_id !==
				application_id
		) {
			if (job_id && application_id && active_user_id && open) {
				this.setState(
					(prev_state) => ({
						...prev_state,
						messages: [],
						active_job_id: job_id,
						active_application_id: application_id,
						active_user_id,
						loading: true,
					}),
					() => this.fetchData(),
				);
			}
		}
	}
	async fetchData() {
		const { active_job_id, active_application_id, active_user_id } = this.state;
		console.log("ref fetchData", this.state);
		let options = {
			method: "GET",
			url: config.api_url(
				`/users/${active_user_id}/job_applications/${active_application_id}`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let application;
		let messages = [];
		try {
			const resp = await axios(options);
			headers.updateItemsToLocalStorage(resp.headers);
			this.props.updateConnectionOffModal(false);
			application = new JobApplication(resp.data);
			options = {
				method: "GET",
				url: config.api_url(
					`/jobs/${active_job_id}/job_applications/${active_application_id}/messages`,
				),
				headers: headers.getItemsFromLocalStorage(),
				json: true,
			};
			const resp2 = await axios(options);
			headers.updateItemsToLocalStorage(resp2.headers);
			messages = await resp2.data.collection.reverse();
			this.paginator.message = resp2.data.paginator;
			for (let i = 0; i < messages.length; i++) {
				messages[i] = new Message(messages[i]);
			}

			let message_ids = [];
			for (let i = 0; i < messages.length; i++) {
				message_ids.push(messages[i].id);
			}
			let data = {
				reading: {
					readable_type: "Job::JobApplication::Message",
					readable_ids: message_ids,
				},
			};
			options = {
				method: "POST",
				url: config.api_url(`/users/${active_user_id}/readings`),
				headers: headers.getItemsFromLocalStorage(),
				data: data,
				json: true,
			};
			const resp3 = await axios(options);
			headers.updateItemsToLocalStorage(resp3.headers);

			this.setState(
				(prev_state) => ({
					...prev_state,
					application,
					messages,
					loading: false,
					sending_change_state: false,
					loading_conversations: false,
					error_messages: {},
				}),
				() => this.messagesScrollToBottom(),
			);
		} catch (error) {
			{
				// console.log(error.response);
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState(
					(prev_state) => ({
						...prev_state,
						loading: true,
						sending_change_state: false,
						loading_conversations: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}),
					() => {
						const internal_uri = new InternalUri();
						this.props.history.push(
							`${internal_uri.formatted_userpanel_my_applications_page_path()}?refresh=true`,
						);
						this.props.updateReferralMessageModal(false, -1, -1);
					},
				);
			}
		}
	}
	fetchMessages() {
		const { active_job_id, active_application_id, active_user_id } = this.state;
		let options = {
			method: "GET",
			url: config.api_url(
				`/jobs/${active_job_id}/job_applications/${active_application_id}/messages`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};
		let messages = [];
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				messages = response.data.collection.reverse();
				this.paginator.message = response.data.paginator;
				for (let i = 0; i < messages.length; i++) {
					messages[i] = new Message(messages[i]);
				}

				let message_ids = [];
				for (let i = 0; i < messages.length; i++) {
					message_ids.push(messages[i].id);
				}
				let data = {
					reading: {
						readable_type: "Job::JobApplication::Message",
						readable_ids: message_ids,
					},
				};
				options = {
					method: "POST",
					url: config.api_url(`/users/${active_user_id}/readings`),
					headers: headers.getItemsFromLocalStorage(),
					data: data,
					json: true,
				};
				return axios(options);
			})
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);

				this.setState((prev_state) => ({
					...prev_state,
					error_messages: {},
				}));
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState((prev_state) => ({
					...prev_state,
					error_messages:
						error.response &&
						error.response.data &&
						error.response.data.status === "error"
							? error.response.data.errors
							: { full_message: "There was an error. Please try again later." },
				}));
			})
			.then(() => {
				this.setState(
					(prev_state) => ({
						...prev_state,
						messages,
						loading: false,
						loading_conversations: false,
					}),
					() => this.messagesScrollToBottom(),
				);
			});
	}

	handleInputChange = (e) => {
		e.preventDefault();
		const { sending } = this.state;
		if (!sending) {
			const value = e.target.value;
			this.setState((prev_state) => ({
				...prev_state,
				form_data: {
					...prev_state.form_data,
					message: {
						...prev_state.form_data.message,
						content: value,
					},
				},
			}));
		}
	};
	// submit form

	submitChatForm = (e) => {
		e.preventDefault();
		const { form_data } = this.state;

		this.setState({ sending: true }, () => this.messagesScrollToBottom());
		const { active_job_id, active_application_id } = this.state;
		let formData = new FormData();
		if (contain_not_only_whitespace(form_data.message.content))
			formData.append(`message[content]`, form_data.message.content);
		if (form_data.message.attachment.file)
			formData.append(
				`message[attachment_attributes][asset]`,
				form_data.message.attachment.file,
			);
		let options = {
			method: "POST",
			url: config.api_url(
				`/jobs/${active_job_id}/job_applications/${active_application_id}/messages`,
			),
			headers: headers.getItemsFromLocalStorage("multipart/form-data"),
			data: formData,
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				this.setState(
					(prev_state) => ({
						...prev_state,
						form_data: {
							...prev_state.form_data,
							message: {
								...prev_state.form_data.message,
								content: "",
								attachment: {},
							},
						},
						loading_conversations: true,
						sending: false,
						error_messages: {},
					}),
					() => this.fetchMessages(),
				);
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState((prev_state) => ({
					...prev_state,
					sending: false,
					error_messages:
						error.response &&
						error.response.data &&
						error.response.data.status === "error"
							? error.response.data.errors
							: { full_message: "There was an error. Please try again later." },
				}));
			});
	};
	// change state

	confirmApplication(e) {
		e.preventDefault();
		const { t } = this.props;
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: true,
				action_type: "report",
				title: t("got_the_job_offer"),
				text: t(
					"please_be_sure_you_have_passed_the_interview_and_processed_all_the_details_of_this_role_",
				),
				application_type: "job_application",
				next_state: "reported",
			},
		}));
	}

	confirmExternalApplication(e) {
		e.preventDefault();
		const { t } = this.props;
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: true,
				action_type: "report",
				title: t("got_the_job_offer"),
				text: t(
					"would_you_like_to_confirm_your_job_offer_and_get_100_usd_onboard_reward_from_meet_jobs_",
				),
				application_type: "external_job_application",
				next_state: "reported",
			},
		}));
	}

	rejectApplication(e) {
		e.preventDefault();
		const { t } = this.props;
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: true,
				action_type: "cancel",
				title: t("are_you_sure_to_cancel_your_application_"),
				text: "",
				application_type: "job_application",
				next_state: "cancelled",
			},
		}));
	}

	cancelChangeState(e) {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			change_state_message_modal: {
				open: false,
				action_type: "",
				title: "",
				text: "",
				application_type: "job_application",
				next_state: "",
			},
		}));
	}

	submitChangeState = () => {
		this.setState({ sending_change_state: true });

		const {
			active_user_id,
			active_application_id,
			change_state_message_modal,
		} = this.state;
		let formData = new FormData();
		formData.append(
			`${change_state_message_modal.application_type}[applier_state]`,
			change_state_message_modal.next_state,
		);
		let options = {
			method: "PATCH",
			url: config.api_url(
				`/users/${active_user_id}/${change_state_message_modal.application_type}s/${active_application_id}`,
			),
			headers: headers.getItemsFromLocalStorage("multipart/form-data"),
			data: formData,
			json: true,
		};
		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);

				this.setState(
					(prev_state) => ({
						...prev_state,
						change_state_message_modal: {
							open: false,
							action_type: "",
							title: "",
							text: "",
							application_type: "",
							next_state: "",
						},
						error_messages: {},
					}),
					() => this.fetchData(),
				);
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				this.setState((prev_state) => ({
					...prev_state,
					change_state_message_modal: {
						open: false,
						action_type: "",
						title: "",
						text: "",
						application_type: "",
						next_state: "",
					},
					sending_change_state: false,
					error_messages:
						error.response &&
						error.response.data &&
						error.response.data.status === "error"
							? error.response.data.errors
							: { full_message: "There was an error. Please try again later." },
				}));
			});
	};

	// message scroll to bottom

	messagesScrollToBottom() {
		const element = document.querySelector(".messages-wrapper");
		if (element) smoothly_scroll_to(element, element.scrollHeight, 200);
	}

	// attachment

	handleAttachment = (e) => {
		e.preventDefault();
		let file = e.target.files[0];
		let file_type = "";
		if (file) {
			e.target.value = null;
			if (file.type === "application/msword" || file.type === ".doc")
				file_type = "DOC";
			if (file.type === "application/pdf" || file.type === ".pdf")
				file_type = "PDF";
			if (
				file.type ===
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
				file.type === ".docx"
			)
				file_type = "DOCX";
			this.setState(
				(prev_state) => ({
					...prev_state,
					form_data: {
						...prev_state.form_data,
						message: {
							...prev_state.form_data.message,
							attachment: {
								file,
								file_type,
							},
						},
					},
				}),
				() => this.messagesScrollToBottom(),
			);
		}
	};

	deleteAttachment = (e) => {
		e.preventDefault();
		this.setState((prev_state) => ({
			...prev_state,
			form_data: {
				...prev_state.form_data,
				message: {
					...prev_state.form_data.message,
					attachment: {},
				},
			},
		}));
	};

	handleDownload = (e, url, filename) => {
		e.preventDefault();
		download_file(url, filename);
	};

	// load more

	handleMessagesWrapperScroll = (e) => {
		e.preventDefault();
		const element = document.querySelector(".messages-wrapper");
		if (element && element.scrollTop === 0) this.loadMoreData();
	};

	loadMoreData() {
		const {
			active_job_id,
			active_application_id,
			loading_more,
			active_user_id,
		} = this.state;

		if (this.paginator.message.next_page && !loading_more) {
			this.setState({ loading_more: true });
			let options = {
				method: "GET",
				url: config.api_url(
					`/jobs/${active_job_id}/job_applications/${active_application_id}/messages?page=${this.paginator.message.next_page}`,
				),
				headers: headers.getItemsFromLocalStorage(),
				json: true,
			};
			let conversations = [];
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					conversations = response.data.collection.reverse();
					this.paginator.message = response.data.paginator;

					for (let i = 0; i < conversations.length; i++) {
						conversations[i] = new Message(conversations[i]);
					}
					let message_ids = [];
					for (let i = 0; i < conversations.length; i++) {
						message_ids.push(conversations[i].id);
					}
					let data = {
						reading: {
							readable_type: "Job::JobApplication::Message",
							readable_ids: message_ids,
						},
					};
					options = {
						method: "POST",
						url: config.api_url(`/users/${active_user_id}/readings`),
						headers: headers.getItemsFromLocalStorage(),
						data: data,
						json: true,
					};
					return axios(options);
				})
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);

					this.setState((prev_state) => ({
						...prev_state,
						error_messages: {},
					}));
				})
				.catch((error) => {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) window.location.reload();
					}

					this.setState((prev_state) => ({
						...prev_state,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				})
				.then(() => {
					this.setState(
						(prev_state) => ({
							...prev_state,
							messages: [...conversations, ...prev_state.messages],
							loading_more: false,
						}),
						() => {
							let wrappers = document.querySelectorAll(".messages-wrapper");
							let elements = document.querySelectorAll(".message-wrapper");
							wrappers[0].scrollTop =
								elements[conversations.length - 1].offsetTop -
								wrappers[0].offsetTop;
						},
					);
				});
		}
	}
	closeModal = (e) => {
		e.preventDefault();
		// TODO: 一定還有更好的做法！
		const internal_uri = new InternalUri();
		// this.props.history.push(`${internal_uri.formatted_userpanel_my_applications_page_path()}?refresh=true`);
		this.props.updateReferralMessageModal(false, -1, -1);
	};

	render() {
		const { closeModal } = this;
		const { site_state, t } = this.props;
		const internal_uri = new InternalUri();
		const application = {
			has_referrer() {
				return true;
			},
		};

		const loading = false;

		console.log("render", this.state, this.props);

		return (
			<CSSTransitionGroup
				component="div"
				transitionName="modal-wrapper-slide"
				transitionEnterTimeout={300}
				transitionLeaveTimeout={300}>
				{site_state.referral_message_modal.open ? (
					<div className="modal-wrapper candidate-message-modal-wrapper">
						<CSSTransitionGroup
							component="div"
							className="modal-inner-wrapper"
							transitionName="modal-slide"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={300}>
							<div className="modal-bg" onClick={(e) => closeModal(e)} />
							{!loading ? (
								<div
									className={`modal candidate-message-modal ${
										application.has_referrer() ? "with-referred-wrapper" : ""
									}`}>
									<div className="btn-close" onClick={(e) => closeModal(e)} />
									<div className="modal-header">
										<h4 className="modal-title">modal-title</h4>
										{/* has referer */}
										<div className="referred-wrapper">
											<div className="referred">
												<span className="badge badge-green-reward">
													{t("general:referred")}
												</span>
												<p>
													<Interpolate
														i18nKey="by__"
														useDangerouslySetInnerHTML={true}
														referrer_name={"hung chi hsueh"}
													/>
												</p>
											</div>
										</div>
									</div>
									<div className="modal-body">
										<div className="left">
											<div className="messages-box">
												<div className="messages-box-header">
													<h5>{t("general:messages")}</h5>
												</div>
												<div className="messages-box-body">
													<div className="messages-wrapper">
														<div className="messages"></div>
													</div>
													{// application.job.has_employer()
													true ? (
														<div className="chat-form">
															<form>
																<div className="input-wrapper">
																	{false ? (
																		// form_data.message.attachment.file
																		<div className="file-wrapper">
																			<span className="file-type">
																				<h5>
																					{`form_data.message.attachment.file_type`}
																				</h5>
																			</span>
																			<span className="file-name">
																				<h5>
																					{`form_data.message.attachment.file.name`}
																				</h5>
																				<div
																					className="btn-close"
																					// onClick={(e) => deleteAttachment(e)}
																				/>
																			</span>
																		</div>
																	) : (
																		""
																	)}
																	<Textarea
																		className="textarea"
																		minRows={1}
																		maxRows={8}
																		placeholder={t("type_something___")}
																		// value={form_data.message.content}
																		// onChange={(e) => handleInputChange(e)}
																	/>
																	{// 		!sending &&
																	// !sending_change_state &&
																	// (contain_not_only_whitespace(
																	// 	form_data.message.content,
																	// ) ||
																	// 	form_data.message.attachment.file) &&
																	// 			!application.is_employer_archived()
																	true ? (
																		<button
																			className="btn btn-smaller btn-flat btn-fill"
																			// onClick={(e) => submitChatForm(e)}
																		>
																			<h5>{t("general:send")}</h5>
																		</button>
																	) : (
																		<button
																			className={`btn btn-smaller btn-flat btn-ghost ${
																				application.is_employer_archived()
																					? "disabled"
																					: ""
																			}`}
																			onClick={(e) => e.preventDefault()}>
																			<h5>{t("general:send")}</h5>
																		</button>
																	)}
																</div>
																<div className="widget-wrapper">
																	<ul>
																		<li>
																			<a
																				onClick={(e) => e.stopPropagation()}
																				href="/#">
																				<input
																					id="upload-attachment"
																					type="file"
																					accept={
																						`.pdf, ` +
																						`.doc, ` +
																						`.docx, ` +
																						`application/pdf, ` +
																						`application/msword, ` +
																						`application/vnd.openxmlformats-officedocument.wordprocessingml.document`
																					}
																					// onChange={(e) => handleAttachment(e)}
																				/>
																				<label htmlFor="upload-attachment">
																					<img src={icon_attachment} alt="" />
																					<h5>{t("general:add_file")}</h5>
																				</label>
																			</a>
																		</li>
																	</ul>
																</div>
															</form>
														</div>
													) : (
														""
													)}
												</div>
											</div>
										</div>
										<div className="right">
											<h6 className="description-reward">
												依照CandidateMessageModal.jsx的寫法，這邊應該要說明推薦成功可以得到的獎勵
												{/* {t(
													"after_you_got_the_job_offer__please_let_meet_jobs_know_about_it__we_would_grant_you___reward_",
												)} */}
											</h6>

											{// 	sending_change_state ||
											// application.is_cancelled() ||
											// application.is_rejected() ||
											// application.is_confirmed() ||
											// 	application.is_reported()
											true ? (
												// application.is_reported()
												true ? (
													<a
														className="btn btn-flat btn-fill btn-success disabled btn-with-icon"
														href="/#"
														onClick={(e) => e.preventDefault()}>
														<h5>
															<img src={icon_ok} alt="" />
															{t("general:confirmed")}
														</h5>
													</a>
												) : (
													<a
														className="btn btn-flat btn-fill btn-success disabled"
														href="/#"
														onClick={(e) => e.preventDefault()}>
														<h5>{t("got_the_job_offer")}</h5>
													</a>
												)
											) : true ? (
												// application.job.has_employer()
												<a
													className="btn btn-flat btn-fill btn-success"
													href="/#"
													// onClick={(e) => confirmApplication(e)}
												>
													<h5>{t("got_the_job_offer")}</h5>
												</a>
											) : (
												<a
													className="btn btn-flat btn-fill btn-success"
													href="/#"
													// onClick={(e) => confirmExternalApplication(e)}
												>
													<h5>{t("got_the_job_offer")}</h5>
												</a>
											)}

											{/* application.job.has_employer() ? (
												sending_change_state ||
												application.is_rejected() ||
												application.is_confirmed() ||
												application.is_cancelled() ? (
														!application.is_cancelled() */}
											{true ? (
												true ? (
													true ? (
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-flat btn-fill btn-danger disabled btn-with-icon">
															<h5>
																<img src={icon_ok} alt="" />
																{t("general:cancelled")}
															</h5>
														</button>
													) : (
														<button
															onClick={(e) => e.preventDefault()}
															className="btn btn-flat btn-fill btn-danger disabled">
															<h5>{t("cancel_application")}</h5>
														</button>
													)
												) : (
													<button
														className="btn btn-flat btn-fill btn-danger"
														// onClick={(e) => rejectApplication(e)}
													>
														<h5>{t("cancel_application")}</h5>
													</button>
												)
											) : (
												""
											)}
											<div className="bottom-wrapper">
												{//application.has_attachment_asset_url()
												true ? (
													<a
														// href={application.attachment.formatted_url()}
														className="btn btn-smaller btn-flat btn-hollow"
														target="_blank"
														rel="noopener noreferrer">
														<h5>{t("general:resume___cv_file")}</h5>
													</a>
												) : (
													""
												)}
												{true ? (
													// application.has_portfolio_link_url()
													<a
														// href={application.formatted_portfolio_link_url()}
														className="btn btn-smaller btn-flat btn-hollow"
														target="_blank"
														rel="noopener noreferrer">
														<h5>{t("general:reference_link")}</h5>
													</a>
												) : (
													""
												)}
												<div className="applied-at-wrapper">
													<h6>{t("general:applied_at")}</h6>
													<p>
														{/* here is a function!!! */}
														{`application.formatted_applied_at()`}
														<span className="description">
															{`{application.formatted_applied_at_past_time(t)}`}
														</span>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button
											className="btn btn-flat btn-fill"
											onClick={(e) => closeModal(e)}>
											<h5>{t("general:close")}</h5>
										</button>
									</div>
								</div>
							) : (
								""
							)}
						</CSSTransitionGroup>
					</div>
				) : (
					""
				)}
			</CSSTransitionGroup>
		);
	}
}
export default withNamespaces([
	"userpanel_modals",
	"system_messages",
	"general",
	"states",
])(ReferralMessageModal);
