// import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppWrapper } from './components/containers/general';
import ErrorBoundary from './components/views/general/ErrorBoudary.jsx';
import { Route,Router,BrowserRouter } from 'react-router-dom';
import storeFactory from './store';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory({});

const store = storeFactory();

window.React = React; 
window.store = store;

ReactDOM.render(
	<Router history={history}>
		<Provider store={store}>
			<ErrorBoundary>
				<Route path={["/:locale", "/"]} render={() => <AppWrapper />} />

			</ErrorBoundary>
		</Provider>
	</Router>,
	document.getElementById("root"),
);
// registerServiceWorker();

