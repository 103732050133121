import React from 'react';
import {withGoogleMap, GoogleMap, Marker} from "react-google-maps";
import loadScript from "../../hocs/loadScript";

const Map = withGoogleMap(
    (props) =>
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: props.lat, lng: props.lng }}
            ref={(map) => map && map.panTo({lat: props.lat, lng: props.lng })}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
        </GoogleMap>
);

export default loadScript('googleMaps')(Map);

