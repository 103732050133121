import React from "react";
import { NavLink } from "react-router-dom";
import Config from "../../../../models/config.js";
import ApiHeaders from "../../../../models/api_headers";
import axios from "axios";
import Employer from "../../../../models/employer.js";
import { withNamespaces } from "react-i18next";
import { page_smothly_scroll_to } from "../../../../../assets/js/functions";
import InternalUri from "../../../../models/internal_uri";
import JobSummaryCard from "./JobSummaryCard";
import InvoiceSummaryCard from "./InvoiceSummaryCard";
import RateSummaryCard from "./RateSummaryCard";
import { ReactComponent as Icon } from "../../../../../assets/images/icon/icon_24_phone_BG400.svg";

const config = new Config();
const headers = new ApiHeaders();

class MyDate extends Date {
	yyyymmdd = function() {
		let mm = this.getMonth() + 1; // getMonth() is zero-based
		let dd = this.getDate();

		return [
			this.getFullYear(),
			(mm > 9 ? "" : "0") + mm,
			(dd > 9 ? "" : "0") + dd,
		].join("");
	};
}

class IndexPage extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.fetchData = this.fetchData.bind(this);
		this.PlanNameFixer = this.PlanNameFixer.bind(this);
		this.state = {
			active_employer: {},
			leaving_message: false,
			current_rates: {
				history: {
					read_rate: 0,
					replied_rate: 0,
				},
				in_time_range: {
					read_rate: 0,
					replied_rate: 0,
				},
			},
			loading: false,
			error_messages: {},
		};
		this.paginator = {};
	}

	UNSAFE_componentWillMount() {
		this._isMounted = true;

		const employer = new Employer(this.props.current_user.active_company);
		this.setState(
			{
				active_employer: employer,
				access_control_list: this.props.current_user.employer_roles[employer.id]
					.access_control_list,
			},
			() => this.fetchData(),
		);
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		if (
			this.props.current_user.active_company.id !==
			next_props.current_user.active_company.id
		) {
			const employer = new Employer(next_props.current_user.active_company);
			this.setState(
				{
					active_employer: employer,
					access_control_list:
						next_props.current_user.employer_roles[employer.id]
							.access_control_list,
				},
				() => this.fetchData(),
			);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	PlanNameFixer = (name) => {
		switch (name) {
			case "Hire":
				return "Social";
			case "Hire+":
				return "Hunter";
			default:
				return name;
		}
	};

	fetchData() {
		const today = new MyDate();
		const onWeekAgo = new MyDate(today.getTime() - 7 * 24 * 60 * 60 * 1000);

		const { active_employer } = this.state;
		this.setState({ loading: true });

		let options_v2 = {
			method: "GET",
			url: config.api_url_v2(`/employers/${active_employer.id}/statistics`),
			headers: headers.getItemsFromLocalStorage(),
			params: {
				started_at: onWeekAgo.yyyymmdd(),
				ended_at: today.yyyymmdd(),
			},
			json: true,
		};
		axios(options_v2)
			.then((response) => {
				const countRate = (value, count, precision) => {
					if (count === 0) return 0;
					const val = value / count;
					return (
						Math.round(
							Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10,
						) / Math.pow(10, precision || 0)
					);
				};
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);
				const { data } = response;
				const { history, in_time_range } = data;

				if (this._isMounted) {
					this.setState({
						...this.state,
						current_rates: {
							history: {
								read_rate: countRate(
									history.job_applications.read.count,
									history.job_applications.count,
									2,
								),
								replied_rate: countRate(
									history.job_applications.replied.count,
									history.job_applications.count,
									2,
								),
							},
							in_time_range: {
								read_rate: countRate(
									in_time_range.job_applications.read.count,
									in_time_range.job_applications.count,
									2,
								),
								replied_rate: countRate(
									in_time_range.job_applications.replied.count,
									in_time_range.job_applications.count,
									2,
								),
							},
						},
						// current_rates: response.data,
						loading: false,
						error_messages: {},
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));

					page_smothly_scroll_to(0, 300);
				}
			});
	}

	render() {
		const {
			access_control_list,
			active_employer,
			current_rates,
			loading,
			error_messages,
		} = this.state;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		return (
			<div className="dashboard-index">
				<div className="container-fluid">
					{error_messages.full_message ? (
						<h6 className="message error-message">
							{error_messages.full_message}
						</h6>
					) : (
						""
					)}
					<div className="employer-header block">
						<div className="logo">
							<div
								className="image-wrapper"
								style={{
									backgroundImage: `url('${active_employer.formatted_logo_dashboard_url()}')`,
								}}
							/>
						</div>
						<h2>{active_employer.formatted_name()}</h2>
						<h5 className="current-plan">
							{t("general:current_plan_")}{" "}
							{active_employer.formatted_latest_plan_name()
								? this.PlanNameFixer(
										active_employer.formatted_latest_plan_name(),
								  )
								: null}
						</h5>
						{access_control_list.plan_manageable &&
						access_control_list.billing_manageable ? (
							<NavLink
								to={internal_uri.formatted_dashboard_upgrade_plans_path()}
								className="link">
								{t("general:learn_more")}
							</NavLink>
						) : (
							""
						)}

						<div className="buttons-wrapper">
							{access_control_list.setting_manageable ? (
								<NavLink
									to={internal_uri.formatted_dashboard_employer_profile_page_path()}
									className="btn btn-larger btn-flat btn-hollow">
									<h5>{t("edit_employer_profile")}</h5>
								</NavLink>
							) : (
								""
							)}
							<NavLink
								to={internal_uri.formatted_frontend_employer_page_path(
									active_employer.id,
									active_employer.slug,
								)}
								className="btn btn-larger btn-flat btn-hollow"
								target="_blank"
								rel="noopener noreferrer">
								<h5>{t("view_employer_page")}</h5>
							</NavLink>
						</div>
					</div>
					<div className="summary-cards">
						<div className="row">
							<RateSummaryCard
								loading={loading}
								colors={{
									main: "#1cdfb3",
									light: "#E8FCF7",
								}}
								leftRate={current_rates.history.read_rate}
								rightRate={current_rates.in_time_range.read_rate}
								text={{
									title: t("summaryCard.cvReadRate.title"),
									leftSubTitle: t("summaryCard.cvReadRate.leftSubTitle"),
									rightSubTitle: t("summaryCard.cvReadRate.rightSubTitle"),
									tooltipContent: t("summaryCard.cvReadRate.tooltipContent"),
									noDataContent: t("summaryCard.cvReadRate.noDataContent"),
								}}
							/>
							<RateSummaryCard
								loading={loading}
								colors={{
									main: "#5DD4EF",
									light: "#F1FBFD",
								}}
								leftRate={current_rates.history.replied_rate}
								rightRate={current_rates.in_time_range.replied_rate}
								text={{
									title: t("summaryCard.messageReplyRate.title"),
									leftSubTitle: t("summaryCard.messageReplyRate.leftSubTitle"),
									rightSubTitle: t(
										"summaryCard.messageReplyRate.rightSubTitle",
									),
									tooltipContent: t(
										"summaryCard.messageReplyRate.tooltipContent",
									),
									noDataContent: t("summaryCard.cvReadRate.noDataContent"),
								}}
							/>
						</div>
						<div className="row">
							{access_control_list.jobs_manageable ? (
								<JobSummaryCard employerId={active_employer.id} />
							) : (
								<div />
							)}
							{access_control_list.billing_manageable &&
							access_control_list.plan_manageable ? (
								<InvoiceSummaryCard employerId={active_employer.id} />
							) : (
								<div />
							)}
							<div className="col-md-4">
								<div className="summary-card block">
									<h4>{t("contact")}</h4>
									<div className="main-info">
										<div className="avatar">
											<div
												className="image-wrapper"
												style={{
													backgroundImage: `url(${active_employer.formatted_assistant_avatar_url()})`,
												}}
											/>
										</div>
										<p>{active_employer.formatted_assistant_title()}</p>
										<h3>{active_employer.formatted_assistant_name()}</h3>
									</div>
									<p>{active_employer.formatted_assistant_email()}</p>
									<p>{active_employer.formatted_assistant_phone()}</p>
									<Icon className="icon-bg" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withNamespaces(["dashboard_index_page", "general"])(IndexPage);
