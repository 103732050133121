export default {
    required: "必填",
    textLimitCharacters: "最多 {{number}} 個字元",
    // length_of_text_limit__180_characters: "最多 180 個字元",
    // length_of_text_limit__6000_characters: "最多 6000 個字元",
    uploadLimitSize: "檔案大小上限： {{size}}",
    // upload_size_limit__30MB: "檔案大小上限： 30MB",
    invalidFormat: "您的 {{type}} 格式不正確",
    // invalid_email_format: "您的 Email 格式不正確",
    // invalid_slug_format: "您的 Slug 格式不正確",

    // end_time_must_be_greater_than_start_time: "結束時間必須比開始時間晚",
    // the_passwords_you_entered_do_not_match: "您輸入的兩個密碼不相同",
    // password_contains_at_least_8_characters_is_required: "請輸入至少8個字元的密碼",
    mustBeNumber: "必須為數字",
    // must_be_a_number: "必須為數字",
    maximumSalaryMustBeGreater: "必須大於或等於最小薪資",
    // must_be_greater_than_or_equal_to__: "必須大於或等於 {{minimum_salary}}",
    selectMaximumLimit: "最多選擇 {{number}} 個選項",
};