import React from "react";
import { withNamespaces } from "react-i18next";
import top_banner_bg from "../../../../assets/images/i006-main_referral.svg";
import referral_1 from "../../../../assets/images/i007-referral_01.svg";
import referral_2 from "../../../../assets/images/i008-referral_02.svg";
import { Helmet } from "react-helmet";
import InternalUri from "../../../models/internal_uri";
import { UseReferralUrlWrapper } from "../../../containers/general";

const ReferralLandingPage = (props) => {
	const { reduxTokenAuth, t } = props;
	const internal_uri = new InternalUri();

	const goRefer = (e) => {
		e.preventDefault();
		if (!reduxTokenAuth.currentUser.isSignedIn) {
			props.updateEntranceFormModal("log-in");
		} else {
			props.history.push(internal_uri.formatted_frontend_jobs_page_path());
		}
	};

	return (
		<div className="page-referral-landing content-wrapper white">
			<Helmet>
				<title>{t("general:be_a_referrer")}｜Meet.jobs</title>
			</Helmet>
			<UseReferralUrlWrapper />
			<section
				className="top-banner"
				style={{ backgroundImage: `url(${top_banner_bg})` }}>
				<div className="container">
					<h2 className="slogan">
						{t("efficient_recruitment_with_social_referral")}
					</h2>
					<p
						dangerouslySetInnerHTML={{
							__html: t(
								"speed_of_internet_startup_and_quality_of_headhunter_service_to_leverage_on_professional_social_networks_let_the_whole_world_get_you_right_talent_",
							),
						}}
					/>
					<button
						className="btn btn-larger btn-flat btn-fill"
						onClick={(e) => goRefer(e)}>
						<h5>{t("refer_a_job")}</h5>
					</button>
				</div>
			</section>

			<section className="about-referrals">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-6 no-padding">
							<div
								className="image-wrapper"
								style={{ backgroundImage: `url(${referral_1})` }}
							/>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-6">
							<div className="text-box about-referral-1">
								<h2>{t("_01_title")}</h2>
								<p dangerouslySetInnerHTML={{ __html: t("_01_content") }} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-6 col-md-push-6 no-padding">
							<div
								className="image-wrapper"
								style={{ backgroundImage: `url(${referral_2})` }}
							/>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-6 col-md-pull-6">
							<div className="text-box about-referral-2">
								<h2>{t("_02_title")}</h2>
								<p dangerouslySetInnerHTML={{ __html: t("_02_content") }} />
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* TODO: 專家推薦文章區塊 缺文案 */}
			{/*<section className="expert-sharing">*/}
			{/*<div className="container">*/}
			{/*<h2 className="title">{t('sharings_of_our_referral_specialists')}</h2>*/}
			{/*<div className="inner-wrapper">*/}
			{/*<div className="dialog-wrapper larger">*/}
			{/*<div className="dialog">*/}
			{/*<p>It was a humorously perilous business for both of us. For, before we proceed further, it must be said that the monkey-rope was fast at both ends; fast to Queequeg's broad canvas belt, and fast to my narrow leather one. So that for better or for worse, we two, for the time, were wedded; and should poor Queequeg sink to rise no more, then both usage and honour demanded, that instead of cutting the cord, it should drag me down in his wake. Queequeg's broad canvas belt, and fast to my narrow leather one.</p>*/}
			{/*<SpeechBubble className="speech-bubble" />*/}
			{/*</div>*/}
			{/*<div className="speaker-info with-title">*/}
			{/*<div className="avatar">*/}
			{/*<div*/}
			{/*className='image-wrapper'*/}
			{/*style={{backgroundImage: `url($)`}}*/}
			{/*/>*/}
			{/*</div>*/}
			{/*<h4>Darren Aronofsky</h4>*/}
			{/*<h6>Senior Developer @Flinto</h6>*/}
			{/*</div>*/}
			{/*</div>*/}
			{/*</div>*/}
			{/*</div>*/}
			{/*</section>*/}

			<section className="referral-call-to-action">
				<div className="container">
					<h2
						className="title"
						dangerouslySetInnerHTML={{
							__html: t("browse_featured_jobs_and_refer_to_your_friend_today_"),
						}}
					/>
					<button
						className="btn btn-larger btn-flat btn-hollow"
						onClick={(e) => goRefer(e)}>
						<h5>{t("refer_a_job")}</h5>
					</button>
				</div>
			</section>
		</div>
	);
};

export default withNamespaces(["frontend_referral_landing_page", "general"])(
	ReferralLandingPage,
);
