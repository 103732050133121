import Abstract from './abstract';
import Roleship from "./roleships";

class Role extends Abstract {
    constructor(attrs) {
        super(attrs);

        let raw_roleships = attrs.roleships;
        if(raw_roleships) {
            delete attrs['roleships'];
            this._build_associations({
                name:  'roleships',
                klass: Roleship,
                attrs: raw_roleships
            });
        }
    };

    formatted_name() {
        if(this.name) return this.name;
        return 'Not provided';
    };
    //
    // formatted_form_title() {
    //     if(this.title) return this.title;
    //     return '';
    // };
}

export default Role;