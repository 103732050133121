export default {
  high_quality_and_efficient_recruitment_service_with_competitive_price: `Find You the Best Talents in the World!`,
  meet_jobs_is_a_headhunting_platform_leveraging_on_social_referral_and_community_sharing_and_focusing_on_international_talents_and_job_opportunities_:
    "Meet.jobs is a Headhunting platform focusing on IT and International talents. Leveraging on AI technology and Professional headhunters, Meet.jobs connects corporates with the best candidates around the world!",

  our_plans: "Our Plans",

  our_advantages: "Our Advantages",
  _17_countries: "17 Countries",
  talents___employers___vacancies_across_17_countries:
    "Talents / Employers / Vacancies across 17 countries",
  _25_days: "25 Days",
  from_posting_job_to_offer_acceptance: "From posting job to offer acceptance",
  _16__service_fee: `16<span class="percent">%</span> Service Fee`,
  compare_to_20___charged_by_headhunters:
    "Compare to 20%↑ charged by headhunters",
  precise_matching_by_social_referral: "Precise Matching by Social Referral",
  machine_learning_and_ai_technology: "Machine Learning and AI Technology",
  employer_branding_and_integrated_marketing:
    "Employer Branding and Integrated Marketing",
  recruitment_consulting_service: "Recruitment Consulting Service",
  _hire_plan_: "(Social plan)",
  _hire_plus_plan_: "(Hunter plan)",
  _hire__hire_plus_plan_: "(Social /Hunter plan)",

  partners: "Partners",

  use_first__contract_later_: "Hunter First, Contract Later!",
  you_need_only_to_agree_with_3_Basic_Rules__:
    'You need only to agree with "3 Basic Rules".',
  note__contract_must_be_signed_before_your_first_candidate_s_onboarding:
    "Note: Contract must be signed before your first candidate's onboarding",
  start_recruiting: "Start recruiting",

  back_to_plan: "Back to plan",

  talents_dispatch: "International talent dispatch",
  corparation_branding: "Employer Branding",

  how_meetjob_acquire_talents: "How Meet.jobs acquire talents",
  ai_title: "A.I. Talent Matching",
  ai_content: "AI-powered resume parsing and global talent database search",
  headhunter_title: "Professional Headhunters",
  headhunter_content:
    "Expert headhunting teams for precise screening and matching",
  social_title: "Community Referral",
  social_content:
    "Job promotion through professional networks, recommending right candidates",
  events_title: "Recruitment Event",
  events_content:
    "Integrated recruitment events, Speed Interview builds employer branding",
  across_71_countries:
    "Users / Employers / Job Opportunities from 160+ Countries",
  across_71_countries_content:
    "Users / Employers / Job Opportunities from 160+ Countries",
  worldmap_more: "more",
  worldmap_less: "less",
  worldmap_user_distribution: "user distribution",
  worldmap_explain_1: `By country: about 30% users from Taiwan, then US (10.16%), China (8.35%) and Japan (4.17%).`,
  worldmap_explain_2:
    "By region: Taiwan + Europe + North America + SEA + China > 75%",
  wordmap_count_method: "",
  // charts in english
  chart_main_title:
    "40% talents are software/ hardware engineers in IT/ ICT industries.",
  jobFunctionChartTitle:
    "6 major professions: Software development / Business operation / Product management / Digital marketing / Product design / Hardware engineering. Over 90%.",
  experienceChartTitle:
    "60+% talents have over 3-year experience; 50% have 3 to 10 years professional experience.",
  chart_culture_title: "Talent's Cultural Background",
  chart_job_function_title: "Talent's Profession",
  chart_experience_title: "Talent's Experience",

  // countries in english
  // Europe: "歐洲",
  // North_americas: "北美洲",
  // SEA: "東南亞",
  // East_asia: "東亞",
  // South_asia: "南亞",
  // Middle_east: "中東",
  // ANZ: "澳紐",

  Europe: "Europe",
  North_americas: "North Americas",
  SEA: "SEA",
  East_asia: "East Asia",
  South_asia: "South Asia",
  Middle_east: "Middle East",
  ANZ: "ANZ",

  Taiwan: "Taiwan",
  United_States: "United States",
  China: "China",
  India: "India",
  Japan: "Japan",
  Singapore: "Singapore",
  Brazil: "Brazil",
  United_Kingdom: "United Kingdom",
  Philippines: "Philippines",
  Indonesia: "Indonesia",
  Germany: "Germany",
  Hong_Kong: "Hong Kong",
  Canada: "Canada",
  Australia: "Australia",

  // job functions in english
  software_development: "Software Development",
  business_operation: "Business Operation",
  product_development: "Product Development",
  marketing: "Marketing",
  design: "Design",
  hardware_development: "Hardware Development",
  human_resource: "Human Resource",
  finance: "finance",
  law: "Law",
  Others: "Others",

  // experience in english
  // y0_1: "0-1年",
  // y1_3: "1-3年",
  // y3_5: "3-5年",
  // y5_10: "5-10年",
  // y10: "10年以上",

  y0_1: "0-1 years",
  y1_3: "1-3 years",
  y3_5: "3-5 years",
  y5_7: "5-7 years",
  y7_10: "7-10 years",
  y5_10: "5-10 years",
  y10: "10 years or more",
};
