import React from 'react';
import { Link } from 'react-router-dom';
import Textarea from 'react-textarea-autosize';
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import User from "../../../models/user.js";
import SavedTalent from "../../../models/saved_talent.js";
import { withNamespaces, Interpolate } from "react-i18next";
import Job from "../../../models/job";
import Select from 'react-select';
import { CSSTransitionGroup } from 'react-transition-group'
import {smoothly_scroll_to} from "../../../../assets/js/functions";
import {isEmail} from "../../../../assets/js/validators";
import InternalUri from "../../../models/internal_uri";
import modal_pop_up_warning from "../../../../assets/images/i050-pop-ups-warning.svg";
import i18n from "../../../../i18n";
import StepProcessBar from "../../general/StepProcessBar";

const config = new Config();
const headers = new ApiHeaders();

class InviteToApplyModal extends React.Component {

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSavedTalentsSelectChange = this.handleSavedTalentsSelectChange.bind(this);
        this.handleActiveStep = this.handleActiveStep.bind(this);
        this.cancelTheAction = this.cancelTheAction.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.continueSubmitForm = this.continueSubmitForm.bind(this);
        this.closeModal = this.closeModal.bind(this);
        // this.deleteSavedTalent = this.deleteSavedTalent.bind(this);
        this.state = {
            steps: {},
            active_user: {},
            talents: {},
            form_data: {},
            options: {},
            active_job: {},
            active_referral_users: [],
            error_messages: {},
            loading: true,
            applying: false,
            double_confirm_modal: {
                open: false
            }
        };
    };

    UNSAFE_componentWillReceiveProps(next_props) {

        this.defaultSteps = {
            active: 1,
            total_number: 4,
            1: {
                visited: true,
                label: next_props.t('disclaimer'),
                eventCategory: '354_invite_back_1'
            },
            2: {
                visited: false,
                label: next_props.t('referrer_info'),
                eventCategory: '354_invite_back_1'
            },
            3: {
                visited: false,
                label: next_props.t('talent_info'),
                eventCategory: '357_invite_back_2'
            },
            4: {
                visited: false,
                label: next_props.t('ref_'),
                eventCategory: ''
            }
        };

        if(next_props.site_state.invite_to_apply_modal.open !== this.props.site_state.invite_to_apply_modal.open) {
            if(next_props.site_state.invite_to_apply_modal.open) {
                const job = new Job(next_props.site_state.invite_to_apply_modal.job);
                const { reduxTokenAuth } = next_props;
                const user = new User(reduxTokenAuth.currentUser.attributes);
                const { t } = this.props;
                this.setState((prev_state) => ({
                    ...prev_state,
                    steps: JSON.parse(JSON.stringify(this.defaultSteps)),
                    form_data: {
                        first_name: '',
                        last_name: '',
                        email: user.formatted_form_email(),
                        phone: '',
                        hide_info_to_employer: false,
                        saved_talents_selected_value: -1,
                        talent_first_name: '',
                        talent_last_name: '',
                        talent_email: '',
                        talent_phone: '',
                        talent_resume: {},
                        talent_portfolio_url: '',
                        letter_for_talent: t('i_found_this_job_that_is_perfect_for_you_accept_my_referral_and_apply_for_it_on_meet_jobs_'),
                        letter_for_employer: '',
                    },
                    options: {
                        talents: []
                    },
                    active_user: user,
                    talents: {},
                    active_job: job,
                    error_messages: {},
                    loading: true,
                    applying: false,
                    double_confirm_modal: {
                        open: false
                    }
                }), () => this.fetchData());
            }
        }
    };

    fetchData() {
        const { active_user } = this.state;

        let options = {
            method: 'GET',
            url: config.api_url(`/users/${active_user.id}/referrer_profile`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let referrer = {}, talents = {}, option_talents = [];
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);
                referrer = new User(response.data);

                options = {
                    method: 'GET',
                    url: config.api_url(`/users/${active_user.id}/saved_talents`),
                    // 取得 v2 url 要把 function 改成 config.api_url_v2
                    headers: headers.getItemsFromLocalStorage(),
                    json: true
                };
                return axios(options);
            })
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);

                const raw_saved_talents = response.data.collection.filter((item) => item.email);

                for(let i=0; i<raw_saved_talents.length; i++) {
                    const saved_talent= raw_saved_talents[i];

                    const talent = new SavedTalent({
                        email: saved_talent.email,
                        first_name: saved_talent.first_name,
                        last_name: saved_talent.last_name,
                        phone: saved_talent.phone,
                        portfolio_url: saved_talent.portfolio_url,
                        attachment: saved_talent.attachment ? {...saved_talent.attachment} : null,
                        avatar: saved_talent.talent ? {...saved_talent.talent.avatar} : null,
                        referrer_note: saved_talent.referrer_note
                    });
                    talents[saved_talent.id] = talent;

                    option_talents.push({
                        value: saved_talent.id,
                        avatar_url: talent.formatted_avatar_url(),
                        email: talent.formatted_email(),
                        label: talent.formatted_name()
                    });
                }

                this.setState((prev_state) => ({
                    ...prev_state,
                    form_data: {
                        ...prev_state.form_data,
                        first_name: referrer.formatted_form_first_name(),
                        last_name: referrer.formatted_form_last_name(),
                        phone: referrer.formatted_form_phone(),
                    },
                    options: {
                        talents: option_talents
                    },
                    talents,
                    error_messages: {},
                    loading: false,
                }));
            })
            .catch((error) => {
                if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                this.setState((prev_state) => ({
                    ...prev_state,
                    loading: false,
                    error_messages:
                        (error.response && error.response.data && error.response.data.status === 'error') ?
                            error.response.data.errors :
                            {full_message: 'There was an error. Please try again later.'},
                }));

                smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
            });
    };

    handleSavedTalentsSelectChange = (selected_option) => {
        if(selected_option) {
            const { talents } = this.state;
            this.setState((prev_state) => {
                const form_data = {
                    ...prev_state.form_data,
                    saved_talents_selected_value: selected_option.value,
                    talent_first_name: talents[selected_option.value].formatted_form_first_name(),
                    talent_last_name: talents[selected_option.value].formatted_form_last_name(),
                    talent_email: talents[selected_option.value].formatted_form_email(),
                    talent_phone: talents[selected_option.value].formatted_form_phone(),
                    talent_resume: {
                        file_url_from_referrer: talents[selected_option.value].formatted_attachment_asset_url(),
                        file_name_from_referrer: talents[selected_option.value].formatted_attachment_asset_filename()
                    },
                    talent_portfolio_url: talents[selected_option.value].formatted_portfolio_url(),
                    letter_for_employer: talents[selected_option.value].formatted_referrer_note()
                };

                const error_messages = {
                    ...prev_state.error_messages,
                    talent_first_name: [],
                    talent_last_name: [],
                    talent_email: [],
                    talent_phone: [],
                    talent_resume: [],
                    talent_portfolio_url: []
                };

                return ({
                    ...prev_state,
                    form_data,
                    error_messages
                });
            });
        }
    };

    handleInputChange = e => {
        e.preventDefault();
        let value = e.target.value;
        const dataset = e.target.dataset;
        const property_name = e.target.id;

        // if(property_name==='talent_email') value = value.toLowerCase();

        this.setState((prev_state) => {
            let error_messages = {...prev_state.error_messages};
            const { t } = this.props;
            if(dataset.val) {
                let errors = [];

                if(dataset.valRequired) {
                    if(value.length<=0) errors.push(`${t('general:required')}`);
                }

                error_messages = {
                    ...error_messages,
                    [property_name]: errors
                }
            }

            return ({
                ...prev_state,
                form_data: {
                    ...prev_state.form_data,
                    [property_name]: value
                },
                error_messages
            });
        });
    };

    handleToggleChange = (e, attr) => {
        e.preventDefault();
        this.setState((prev_state) => ({
            form_data: {
                ...prev_state.form_data,
                [attr]: !prev_state.form_data[attr]
            }
        }));
    };

    handleFileChange = e => {
        e.preventDefault();
        const file = e.target.files[0];
        const property_name = e.target.id;

        if(file) {
            this.setState((prev_state) => ({
                ...prev_state,
                form_data: {
                    ...prev_state.form_data,
                    [property_name]: {
                        ...prev_state.form_data[property_name],
                        file
                    },
                }
            }));
        }
    };

    handleActiveStep(e, new_step) {
        e.preventDefault();

        let has_errors = false;
        let error_messages = {...this.state.error_messages};
        const { t } = this.props;
        const { steps } = this.state
        let step = document.querySelector('.step');
        if(step && new_step >= steps.active) {
            const inputs = step.querySelectorAll('input');
            for(let i=0; i<inputs.length; i++) {
                const input = inputs[i];
                const dataset = input.dataset;
                const value = input.value;
                let errors = [];

                if(dataset.val) {
                    if(dataset.valRequired) {
                        if(value.length<=0) {
                            errors.push(`${t('general:required')}`);
                            has_errors = true;
                        }
                    }

                    if(dataset.valEmail) {
                        if(!isEmail(value)) {
                            errors.push(`${t('general:invalid_email_format')}`);
                            has_errors = true;
                        }
                    }
                }

                error_messages = {
                    ...error_messages,
                    [input.id]: errors
                };
            }
        }

        if(has_errors) {
            this.setState({ error_messages });
        } else {
            this.setState((prev_state) => ({
                ...prev_state,
                steps: {
                    ...prev_state.steps,
                    active: new_step,
                    [new_step]: {
                        ...prev_state.steps[new_step],
                        visited: true
                    }
                }
            }));
        }

        smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
    };

    cancelTheAction = e => {
        e.preventDefault();
        this.setState((prev_state) => ({
            ...prev_state,
            double_confirm_modal: {
                open: false
            }
        }));
    };

    submitForm = e => {
        e.preventDefault();
        this.setState((prev_state) => ({
            ...prev_state,
            double_confirm_modal: {
                open: true
            }
        }));
    };

    continueSubmitForm = e => {
        e.preventDefault();

        const { form_data, active_job, applying } = this.state;
        // const file = URL.revokeObjectURL(form_data.talent_resume.file_url_from_referrer)
        // console.log(form_data, file)
        // return
        if(!applying) {
            this.setState({ applying: true });
            const { reduxTokenAuth } = this.props;

            let formData = new FormData();
            formData.append('job_id', active_job.id);
            formData.append('referrer[first_name]', form_data.first_name);
            formData.append('referrer[last_name]', form_data.last_name);
            formData.append('referrer[phone]', form_data.phone);
            formData.append('referrer[comment]', form_data.letter_for_talent);
            formData.append('referrer[note]', form_data.letter_for_employer);
            formData.append('talent[email]', form_data.talent_email);
            formData.append('talent[first_name]', form_data.talent_first_name);
            formData.append('talent[last_name]', form_data.talent_last_name);
            formData.append('talent[phone]', form_data.talent_phone);
            formData.append('talent[portfolio_url]', form_data.talent_portfolio_url);
            if(typeof form_data.talent_resume.file !== 'undefined') formData.append('talent[attachment]', form_data.talent_resume.file);
            if(form_data.saved_talents_selected_value !== -1) formData.append('saved_talent[id]', form_data.saved_talents_selected_value);

            let options = {
                method: 'POST',
                url: config.api_url_v2(`/referees`),
                // url: config.api_url(`/jobs/${active_job.id}/talent_invitations`),
                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                data: formData,
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    let formData = new FormData();
                    formData.append('referrer_profile[first_name]', form_data.first_name);
                    formData.append('referrer_profile[last_name]', form_data.last_name);
                    formData.append('referrer_profile[phone]', form_data.phone);

                    let options = {
                        method: 'PATCH',
                        url: config.api_url(`/users/${reduxTokenAuth.currentUser.attributes.id}/update_referrer_profile`),
                        // 取得 v2 url 要把 function 改成 config.api_url_v2
                        headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                        data: formData,
                        json: true
                    };
                    return axios(options);
                })
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);

                    this.setState((prev_state) => ({
                        ...prev_state,
                        error_messages: {},
                        double_confirm_modal: {
                            open: false
                        },
                        applying: false
                    }), () => {
                        const { t } = this.props;
                        const internal_uri = new InternalUri();
                        this.closeModal(e);
                        this.props.addFlashMessage(
                            true,
                            <Interpolate
                                i18nKey="flash_messages:your_invitation_has_been_sent_you_can_find_it_in___"
                                useDangerouslySetInnerHTML={true}
                                my_referrals={
                                    <Link
                                        className="link"
                                        to={`${internal_uri.formatted_userpanel_my_referrals_page_path()}?type=pending-invitation`}
                                        data-close-after-click="true"
                                        data-event-category="361_flash_msg_my_referrals"
                                    >
                                        {t('general:my_referrals')}
                                    </Link>
                                }
                            />,
                            '',
                            'with-dismiss',
                            ''
                        );
                    });
                })
                .catch((error) => {
                    if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    if(error.response && error.response.data && error.response.data.status === 'error') {
                        this.setState((prev_state) => ({
                            ...prev_state,
                            error_messages: error.response.data.errors,
                            double_confirm_modal: {
                                open: false
                            },
                            applying: false
                        }));
                    }

                    smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
                });
        }
    };

    closeModal = e => {
        e.preventDefault();
        this.setState((prev_state) => ({
            ...prev_state,
            steps: JSON.parse(JSON.stringify(this.defaultSteps)),
            error_messages: {},
            loading: false,
            double_confirm_modal: {
                open: false
            },
        }), () => this.props.updateInviteToApplyModal(false, {}));
    };

    render() {

        const { steps, form_data, options, error_messages, loading, applying, double_confirm_modal } = this.state;
        const { handleFileChange, handleActiveStep } = this;
        const { handleInputChange, handleSavedTalentsSelectChange, submitForm, continueSubmitForm, cancelTheAction, closeModal } = this;
        // const { deleteSavedTalent } = this;
        const { site_state } = this.props;
        const { t } = this.props;

        let has_errors = false;
        const black_list = ['referrer_first_name', 'referrer_last_name', 'referrer_phone', 'talent_email', 'talent_first_name', 'talent_last_name', 'talent_phone', 'comment_to_candidate', 'referrer_note'];
        for(let key in error_messages) {
            if(black_list.indexOf(key) !== -1 && error_messages[key].length) has_errors = true;
        }

        return (
            <CSSTransitionGroup
                component="div"
                transitionName="modal-wrapper-slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {(site_state.invite_to_apply_modal.open) ?
                    <div className="modal-wrapper invite-to-apply-modal-wrapper">
                        <CSSTransitionGroup
                            component="div"
                            className="modal-inner-wrapper"
                            transitionName="modal-slide"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >

                            <div className="modal-bg" onClick={ e => closeModal(e) } />
                            {(!loading) ?
                                <div className="modal invite-to-apply-modal">
                                    <div className="btn-close" onClick={ e => closeModal(e) } />
                                    <h4 className="modal-title">{t('general:submit_for_friend')}</h4>
                                    <form>
                                        <StepProcessBar
                                            steps={steps}
                                            handleActiveStep={handleActiveStep}
                                        />

                                        {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                                        {(steps.active === 1) ?
                                            <div className="step step-1">
                                                <div dangerouslySetInnerHTML={{__html:t('disclaimer_content')}} />
                                                <div className="buttons-wrapper">
                                                    <button
                                                        className="btn btn-larger btn-flat btn-ghost"
                                                        onClick={ e => closeModal(e) }
                                                    >
                                                        <h5>{t('general:cancel')}</h5>
                                                    </button>
                                                    <button
                                                        className="btn btn-larger btn-flat btn-fill"
                                                        onClick={e => handleActiveStep(e, 2)}
                                                        data-event-category="353_invite_next_1"
                                                    >
                                                        <h5>{t('proceed')}</h5>
                                                    </button>
                                                </div>
                                            </div> : ''
                                        }
                                        {(steps.active === 2) ?
                                            <div className="step step-2">
                                                <label htmlFor="email"><h5>{t('email')}</h5></label>
                                                <div className="form-row form-row-dire-col">
                                                    <div className="form-col">
                                                        <input
                                                            className="input input-readonly"
                                                            type="email"
                                                            value={form_data.email}
                                                            placeholder={t('email')}
                                                            id="email"
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="form-col">
                                                        {(error_messages.email && error_messages.email.length) ? <h6 className="message error-message">{error_messages.email.join(', ')}</h6> : ''}
                                                    </div>
                                                </div>

                                                <label htmlFor="first_name">
                                                    <h5>{t('name')}</h5>
                                                </label>
                                                {
                                                    (i18n.language === 'zh-TW') ?
                                                        <div className="form-row form-row-2">
                                                            <div className="form-col">
                                                                <div className="form-row form-row-dire-col">
                                                                    <div className="form-col">
                                                                        <input
                                                                            className={`input ${
                                                                                (
                                                                                    error_messages.last_name &&
                                                                                    error_messages.last_name.length
                                                                                ) ? 'input-danger' : ''
                                                                                }`}
                                                                            type="text"
                                                                            value={form_data.last_name}
                                                                            placeholder={t('last_name')}
                                                                            onChange={ e => handleInputChange(e) }
                                                                            id="last_name"
                                                                            data-val="true"
                                                                            data-val-required="true"
                                                                        />
                                                                    </div>
                                                                    <div className="form-col">
                                                                        {
                                                                            (error_messages.last_name && error_messages.last_name.length) ?
                                                                                <h6 className="message error-message">{error_messages.last_name.join(', ')}</h6> :
                                                                                ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-col">
                                                                <div className="form-row form-row-dire-col">
                                                                    <div className="form-col">
                                                                        <input
                                                                            className={`input ${
                                                                                (
                                                                                    error_messages.first_name &&
                                                                                    error_messages.first_name.length
                                                                                ) ? 'input-danger' : ''
                                                                                }`}
                                                                            type="text"
                                                                            value={form_data.first_name}
                                                                            placeholder={t('first_name')}
                                                                            onChange={ e => handleInputChange(e) }
                                                                            id="first_name"
                                                                            data-val="true"
                                                                            data-val-required="true"
                                                                        />
                                                                    </div>
                                                                    <div className="form-col">
                                                                        {
                                                                            (error_messages.first_name && error_messages.first_name.length) ?
                                                                                <h6 className="message error-message">{error_messages.first_name.join(', ')}</h6> :
                                                                                ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div className="form-row form-row-2">
                                                            <div className="form-col">
                                                                <div className="form-row form-row-dire-col">
                                                                    <div className="form-col">
                                                                        <input
                                                                            className={`input ${
                                                                                (
                                                                                    error_messages.first_name &&
                                                                                    error_messages.first_name.length
                                                                                ) ? 'input-danger' : ''
                                                                                }`}
                                                                            type="text"
                                                                            value={form_data.first_name}
                                                                            placeholder={t('first_name')}
                                                                            onChange={ e => handleInputChange(e) }
                                                                            id="first_name"
                                                                            data-val="true"
                                                                            data-val-required="true"
                                                                        />
                                                                    </div>
                                                                    <div className="form-col">
                                                                        {(error_messages.first_name && error_messages.first_name.length) ? <h6 className="message error-message">{error_messages.first_name.join(', ')}</h6> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-col">
                                                                <div className="form-row form-row-dire-col">
                                                                    <div className="form-col">
                                                                        <input
                                                                            className={`input ${
                                                                                (
                                                                                    error_messages.last_name &&
                                                                                    error_messages.last_name.length
                                                                                ) ? 'input-danger' : ''
                                                                            }`}
                                                                            type="text"
                                                                            value={form_data.last_name}
                                                                            placeholder={t('last_name')}
                                                                            onChange={ e => handleInputChange(e) }
                                                                            id="last_name"
                                                                            data-val="true"
                                                                            data-val-required="true"
                                                                        />
                                                                    </div>
                                                                    <div className="form-col">
                                                                        {(error_messages.last_name && error_messages.last_name.length) ? <h6 className="message error-message">{error_messages.last_name.join(', ')}</h6> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }

                                                <label htmlFor="phone"><h5>{t('phone_number')}</h5></label>
                                                <div className="form-row form-row-dire-col">
                                                    <div className="form-col">
                                                        <input
                                                            className={`input ${
                                                                (
                                                                    error_messages.phone &&
                                                                    error_messages.phone.length
                                                                ) ? 'input-danger' : ''
                                                                }`}
                                                            type="text"
                                                            value={form_data.phone}
                                                            placeholder={t('your_phone_number')}
                                                            onChange={ e => handleInputChange(e) }
                                                            id="phone"
                                                            data-val="true"
                                                            data-val-required="true"
                                                        />
                                                    </div>
                                                    <div className="form-col">
                                                        {
                                                            (error_messages.phone && error_messages.phone.length) ?
                                                                <h6 className="message error-message">
                                                                    {error_messages.phone.join(', ')}
                                                                </h6> : ''
                                                        }
                                                    </div>
                                                </div>

                                                <div className="buttons-wrapper">
                                                    <button
                                                        className="btn btn-larger btn-flat btn-ghost"
                                                        onClick={e => handleActiveStep(e, 1)}
                                                        data-event-category="354_invite_back_1"
                                                    >
                                                        <h5>{t('general:back')}</h5>
                                                    </button>
                                                    <button
                                                        className="btn btn-larger btn-flat btn-fill"
                                                        onClick={e => handleActiveStep(e, 3)}
                                                        data-event-category="353_invite_next_1"
                                                    >
                                                        <h5>{t('general:next')}</h5>
                                                    </button>
                                                </div>
                                            </div> : ''
                                        }
                                        {(steps.active === 3) ?
                                            <div className="step step-3">
                                                <div className="form-row">
                                                    <div className="select-wrapper saved-talents">
                                                        <Select
                                                            className="drop-down drop-down-larger drop-down-grey"
                                                            menuIsOpen={true}
                                                            clearable={false}
                                                            value={form_data.saved_talents_selected_value}
                                                            placeholder={t('choose_from_previous_referral')}
                                                            onChange={value => handleSavedTalentsSelectChange(value)}
                                                            options={options.talents}
                                                            optionRenderer={(option) =>
                                                                <div className="option-wrapper">
                                                                    <div className="avatar">
                                                                        <div
                                                                            className='image-wrapper'
                                                                            style={{backgroundImage: `url(${option.avatar_url})`}}
                                                                        />
                                                                    </div>
                                                                    <h5 className="talent-name">{option.label}</h5>
                                                                    <h6 className="talent-email">{option.email}</h6>
                                                                </div>
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <label>
                                                    <h5>{t('name')}</h5>
                                                </label>
                                                {
                                                    (i18n.language === 'zh-TW') ?
                                                        <div className="form-row form-row-2">
                                                            <div className="form-col">
                                                                <div className="form-row form-row-dire-col">
                                                                    <div className="form-col">
                                                                        <input
                                                                            className={`input ${
                                                                                (
                                                                                    error_messages.talent_last_name &&
                                                                                    error_messages.talent_last_name.length
                                                                                ) ? 'input-danger' : ''
                                                                                }`}
                                                                            type="text"
                                                                            value={form_data.talent_last_name}
                                                                            placeholder={t('talent_s_last_name')}
                                                                            onChange={ e => handleInputChange(e) }
                                                                            id="talent_last_name"
                                                                            data-val="true"
                                                                            data-val-required="true"
                                                                        />
                                                                    </div>
                                                                    <div className="form-col">
                                                                        {
                                                                            (error_messages.talent_last_name && error_messages.talent_last_name.length) ?
                                                                                <h6 className="message error-message">{error_messages.talent_last_name.join(', ')}</h6> :
                                                                                ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-col">
                                                                <div className="form-row form-row-dire-col">
                                                                    <div className="form-col">
                                                                        <input
                                                                            className={`input ${
                                                                                (
                                                                                    error_messages.talent_first_name &&
                                                                                    error_messages.talent_first_name.length
                                                                                ) ? 'input-danger' : ''
                                                                                }`}
                                                                            type="text"
                                                                            value={form_data.talent_first_name}
                                                                            placeholder={t('talent_s_first_name')}
                                                                            onChange={ e => handleInputChange(e) }
                                                                            id="talent_first_name"
                                                                            data-val="true"
                                                                            data-val-required="true"
                                                                        />
                                                                    </div>
                                                                    <div className="form-col">
                                                                        {
                                                                            (error_messages.talent_first_name && error_messages.talent_first_name.length) ?
                                                                                <h6 className="message error-message">{error_messages.talent_first_name.join(', ')}</h6> :
                                                                                ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div className="form-row form-row-2">
                                                            <div className="form-col">
                                                                <div className="form-row form-row-dire-col">
                                                                    <div className="form-col">
                                                                        <input
                                                                            className={`input ${
                                                                                (
                                                                                    error_messages.talent_first_name &&
                                                                                    error_messages.talent_first_name.length
                                                                                ) ? 'input-danger' : ''
                                                                                }`}
                                                                            type="text"
                                                                            value={form_data.talent_first_name}
                                                                            placeholder={t('talent_s_first_name')}
                                                                            onChange={ e => handleInputChange(e) }
                                                                            id="talent_first_name"
                                                                            data-val="true"
                                                                            data-val-required="true"
                                                                        />
                                                                    </div>
                                                                    <div className="form-col">
                                                                        {
                                                                            (error_messages.talent_first_name && error_messages.talent_first_name.length) ?
                                                                                <h6 className="message error-message">{error_messages.talent_first_name.join(', ')}</h6> :
                                                                                ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-col">
                                                                <div className="form-row form-row-dire-col">
                                                                    <div className="form-col">
                                                                        <input
                                                                            className={`input ${
                                                                                (
                                                                                    error_messages.talent_last_name &&
                                                                                    error_messages.talent_last_name.length
                                                                                ) ? 'input-danger' : ''
                                                                                }`}
                                                                            type="text"
                                                                            value={form_data.talent_last_name}
                                                                            placeholder={t('talent_s_last_name')}
                                                                            onChange={ e => handleInputChange(e) }
                                                                            id="talent_last_name"
                                                                            data-val="true"
                                                                            data-val-required="true"
                                                                        />
                                                                    </div>
                                                                    <div className="form-col">
                                                                        {
                                                                            (error_messages.talent_last_name && error_messages.talent_last_name.length) ?
                                                                                <h6 className="message error-message">{error_messages.talent_last_name.join(', ')}</h6> :
                                                                                ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }

                                                <label htmlFor="talent_email"><h5>{t('email')}</h5></label>
                                                <div className="form-row form-row-dire-col">
                                                    <div className="form-col">
                                                        <input
                                                            className={`input ${
                                                                (
                                                                    error_messages.talent_email &&
                                                                    error_messages.talent_email.length
                                                                ) ? 'input-danger' : ''
                                                                }`}
                                                            type="email"
                                                            value={form_data.talent_email}
                                                            placeholder={t('talent_s_email')}
                                                            onChange={ e => handleInputChange(e) }
                                                            id="talent_email"
                                                            data-val="true"
                                                            data-val-required="true"
                                                            data-val-email="true"
                                                        />
                                                    </div>
                                                    <div className="form-col">
                                                        {
                                                            (error_messages.talent_email && error_messages.talent_email.length) ?
                                                                <h6 className="message error-message">{error_messages.talent_email.join(', ')}</h6> :
                                                                ''
                                                        }
                                                    </div>
                                                </div>

                                                <label htmlFor="talent_phone">
                                                    <h5>{t('phone_number')}<span className="lighter">{t('general:__optional_')}</span></h5>
                                                </label>
                                                <div className="form-row form-row-dire-col">
                                                    <div className="form-col">
                                                        <input
                                                            className={`input ${
                                                                (
                                                                    error_messages.talent_phone &&
                                                                    error_messages.talent_phone.length
                                                                ) ? 'input-danger' : ''
                                                                }`}
                                                            type="text"
                                                            value={form_data.talent_phone}
                                                            placeholder={t('talent_s_phone_number')}
                                                            onChange={ e => handleInputChange(e) }
                                                            id="talent_phone"
                                                        />
                                                    </div>
                                                    <div className="form-col">
                                                        {
                                                            (error_messages.talent_phone && error_messages.talent_phone.length) ?
                                                                <h6 className="message error-message">
                                                                    {error_messages.talent_phone.join(', ')}
                                                                </h6> : ''
                                                        }
                                                    </div>
                                                </div>

                                                <label htmlFor="resume" className="lines">
                                                    <h5>{t('general:resume___cv_file')}<span className="lighter">{t('general:__optional_')}</span></h5>
                                                    {
                                                        (typeof form_data.talent_resume.file !== 'undefined') ?
                                                            <h6>{form_data.talent_resume.file.name}</h6> :
                                                            (form_data.talent_resume.file_url_from_referrer) ?
                                                                <h6>
                                                                    <a
                                                                        className="link"
                                                                        href={form_data.talent_resume.file_url_from_referrer}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <h5> {form_data.talent_resume.file_name_from_referrer}</h5>
                                                                    </a>
                                                                </h6> :
                                                                <h6>{t('general:no_resume___cv_file')}</h6>
                                                    }
                                                </label>
                                                <div className="form-row">
                                                    <input
                                                        className="input-upload-file"
                                                        id="talent_resume"
                                                        type="file"
                                                        accept={
                                                            `.pdf, ` +
                                                            `.doc, ` +
                                                            `.docx, ` +
                                                            `application/pdf, ` +
                                                            `application/msword, ` +
                                                            `application/vnd.openxmlformats-officedocument.wordprocessingml.document`
                                                        }
                                                        onChange={ e => handleFileChange(e) }
                                                    />
                                                    <label
                                                        htmlFor="talent_resume"
                                                        className="btn btn-larger btn-flat btn-hollow btn-upload-file"
                                                    >
                                                        {t('general:choose_file')}
                                                    </label>
                                                </div>

                                                <label htmlFor="talent_portfolio_url">
                                                    <h5>{t('general:reference_link')}<span className="lighter">{t('general:__optional_')}</span></h5>
                                                </label>
                                                <div className="form-row">
                                                    <input
                                                        className="input"
                                                        type="text"
                                                        value={form_data.talent_portfolio_url}
                                                        placeholder={t('https___linkedin_com_in_your_name')}
                                                        onChange={ e => handleInputChange(e) }
                                                        id="talent_portfolio_url"
                                                    />
                                                </div>

                                                <div className="buttons-wrapper">
                                                    <button
                                                        className="btn btn-larger btn-flat btn-ghost"
                                                        onClick={e => handleActiveStep(e, 2)}
                                                        data-event-category="354_invite_back_1"
                                                    >
                                                        <h5>{t('general:back')}</h5>
                                                    </button>
                                                    <button
                                                        className="btn btn-larger btn-flat btn-fill"
                                                        onClick={e => handleActiveStep(e, 4)}
                                                        data-event-category="356_invite_next_2"
                                                    >
                                                        <h5>{t('general:next')}</h5>
                                                    </button>
                                                </div>
                                            </div> : ''
                                        }

                                        {(steps.active === 4) ?
                                            <div className="step step-4">
                                                <label htmlFor="letter_for_talent">
                                                    <h5>{t('recommendation_for_talent')}<span className="lighter">{t('general:__optional_')}</span></h5>
                                                </label>
                                                <div className="form-row">
                                                    <Textarea
                                                        className="textarea"
                                                        minRows={3}
                                                        maxRows={3}
                                                        placeholder={t('tell_the_talent_why_this_job_is_perfect_for_him_her_')}
                                                        value={form_data.letter_for_talent}
                                                        onChange={ e => handleInputChange(e) }
                                                        id="letter_for_talent"
                                                    />
                                                </div>

                                                <label htmlFor="letter_for_employer">
                                                    <h5>{t('general:reference_letter')}<span className="lighter">{t('general:__optional_')}</span></h5>
                                                </label>
                                                <div className="form-row">
                                                    <Textarea
                                                        className="textarea"
                                                        minRows={16}
                                                        maxRows={16}
                                                        placeholder={t('tell_a_little_bit_more_to_the_employer_why_you_recommend_this_talent_')}
                                                        value={form_data.letter_for_employer}
                                                        onChange={ e => handleInputChange(e) }
                                                        id="letter_for_employer"
                                                    />
                                                </div>

                                                <div className="buttons-wrapper">
                                                    <button
                                                        className="btn btn-larger btn-flat btn-ghost"
                                                        onClick={e => handleActiveStep(e, 3)}
                                                        data-event-category="357_invite_back_2"
                                                    >
                                                        <h5>{t('general:back')}</h5>
                                                    </button>
                                                    {
                                                        (has_errors) ?
                                                            <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-fill disabled">
                                                                <h5>{t('submit')}</h5>
                                                            </button> :
                                                            <button
                                                                className="btn btn-larger btn-flat btn-fill"
                                                                onClick={e => submitForm(e)}
                                                                data-event-category="358_invite"
                                                            >
                                                                <h5>{t('submit')}</h5>
                                                            </button>
                                                    }
                                                </div>
                                            </div> : ''
                                        }
                                    </form>
                                </div> : ''
                            }
                        </CSSTransitionGroup>

                        <CSSTransitionGroup
                            component="div"
                            transitionName="modal-wrapper-with-modal-slide"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >
                            {(double_confirm_modal.open) ?
                                <div className="modal-wrapper double-confirm-modal-wrapper">
                                    <div className="inner-wrapper">
                                        <div className="modal-bg" />
                                        <div className="modal double-confirm-modal with-pop-up-icon">
                                            <div
                                                className="image-wrapper"
                                                style={{backgroundImage: `url(${modal_pop_up_warning}`}}
                                            />
                                            <div className="content">
                                                <h4 className="modal-title">{t('be_sure_everything_s_correct')}</h4>
                                                <p>{t('you_cannot_modify_the_reference_letter_afterwards_are_you_sure_to_send_it_right_away_')}</p>
                                                <div className="buttons-wrapper">
                                                    <button
                                                        className="btn btn-larger btn-flat btn-ghost"
                                                        onClick={ e => cancelTheAction(e) }
                                                        data-event-category="359_invite_back_to_edit"
                                                    >
                                                        <h5>{t('general:back')}</h5>
                                                    </button>
                                                    {
                                                        (applying) ?
                                                            <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-fill disabled">
                                                                <h5>{t('general:send')}</h5>
                                                            </button> :
                                                            <button
                                                                className="btn btn-larger btn-flat btn-fill"
                                                                onClick={ e => continueSubmitForm(e) }
                                                                data-event-category="360_invite_send_anyway"
                                                            >
                                                                <h5>{t('general:send')}</h5>
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''
                            }
                        </CSSTransitionGroup>
                    </div> : ''
                }
            </CSSTransitionGroup>
        );
    }
}

export default withNamespaces(['frontend_modals', 'flash_messages', 'general'])(InviteToApplyModal);
