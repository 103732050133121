import React from "react";
import { NavLink, Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { signOutUser } from "./../../../../redux-token-auth-config";
import Employer from "../../../models/employer";
import { withNamespaces } from "react-i18next";
import User from "../../../models/user";
import logo from "../../../../assets/images/logo_meetjobs_nav_standard_white.svg";
import InternalUri from "../../../models/internal_uri";
import { sendDataLayer } from "../../../../assets/js/dataLayer";
import DashboardMenuInner from "../DashboardMenuInner";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.updateCompanies = this.updateCompanies.bind(this);
		this.toggleMobileMainMenu = this.toggleMobileMainMenu.bind(this);
		this.toggleMobileSubMenu = this.toggleMobileSubMenu.bind(this);
		this.handleSubMenu = this.handleSubMenu.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.signOut = this.signOut.bind(this);
		this.state = {
			active_company: {},
			active_user: {},
			access_control_list: {},
			options: [],
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { current_user, reduxTokenAuth } = nextProps;

		if (
			reduxTokenAuth.currentUser.isSignedIn !== prevState.isSignedIn ||
			JSON.stringify(reduxTokenAuth.currentUser.attributes) !==
				JSON.stringify(prevState.userAttributes)
		) {
			return {
				isSignedIn: reduxTokenAuth.currentUser.isSignedIn,
				userAttributes: reduxTokenAuth.currentUser.attributes,
				active_user: reduxTokenAuth.currentUser.isSignedIn
					? new User(reduxTokenAuth.currentUser.attributes)
					: null,
			};
		}

		if (
			JSON.stringify(prevState.current_user) !== JSON.stringify(current_user)
		) {
			return {
				current_user,
				access_control_list:
					current_user.employer_roles[current_user.active_company.id]
						.access_control_list,
			};
		}

		return null;
	}

	componentDidMount() {
		this.updateCompanies(this.props);
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.current_user.companies !== prevProps.current_user.companies
		) {
			this.updateCompanies(this.props);
		}
	}

	updateCompanies(props) {
		const { current_user } = props;
		const { t } = this.props;
		if (current_user.companies) {
			let options = [];
			for (let i = 0; i < current_user.companies.length; i++) {
				options.push({
					value: current_user.companies[i].id,
					label: current_user.companies[i].name,
				});
			}
			options.push({ value: "new_company", label: t("create_employer") });
			this.setState({
				active_company: new Employer(current_user.active_company),
				options,
			});
		}
	}

	handleSubMenu = (e) => {
		e.preventDefault();
		this.props.updateSubMenu(!this.props.site_state.sub_menu);
	};

	toggleMobileMainMenu = (e) => {
		e.preventDefault();

		const { site_state, updateMobileMenu } = this.props;
		const cur_mobile_menu = site_state.mobile_menu;

		if (cur_mobile_menu) {
			updateMobileMenu("");
		} else {
			updateMobileMenu("main_menu");
		}
	};

	toggleMobileSubMenu = (e) => {
		e.preventDefault();

		const { site_state, updateMobileMenu } = this.props;
		const cur_mobile_menu = site_state.mobile_menu;

		if (cur_mobile_menu === "sub_menu") {
			updateMobileMenu("main_menu");
		} else {
			updateMobileMenu("sub_menu");
		}
	};

	handleSelectChange = (selected_option) => {
		if (selected_option) {
			const cur_value = selected_option.value;
			if (cur_value === "new_company") {
				this.props.updateCreateEmployerModal(true);
			} else {
				const { current_user } = this.props;
				for (let i = 0; i < current_user.companies.length; i++) {
					if (cur_value === current_user.companies[i].id) {
						this.setState(
							{
								active_company: current_user.companies[i],
							},
							() => {
								this.props.updateCurrentUserActiveCompany({
									...current_user.companies[i],
								});
								if (
									this.props.location.pathname.search("/dashboard/") !== -1 // 是 dashboard 首頁外的其他 dashboard 頁
									// employer profile page will do it itself (??? 5/23 拿掉試試看)
									// && this.props.location.pathname.search('/dashboard/employer-profile') === -1 // 不是 dashboard employer profile page
								) {
									const internal_uri = new InternalUri();
									this.props.history.push(
										internal_uri.formatted_dashboard_index_page_path(),
									);
								}
							},
						);
					}
				}
			}
		}
	};

	signOut = (e) => {
		e.preventDefault();
		const { signOutUser } = this.props;
		signOutUser()
			.then(() => {
				this.props.closeAll();
				this.props.updateConnectionOffModal(false);
				sendDataLayer("userId", -1);
			})
			.catch((error) => {
				if (typeof error.response === "undefined") {
					window.location.reload();
					this.props.updateConnectionOffModal(true);
				}

				if (error.response) {
					if (error.response.status === 404) {
						window.location.reload();
					}
				}
			});
	};

	render() {
		const { site_state, reduxTokenAuth, current_user } = this.props;
		const {
			access_control_list,
			active_user,
			active_company,
			options,
		} = this.state;
		const {
			toggleMobileMainMenu,
			toggleMobileSubMenu,
			handleSubMenu,
			handleSelectChange,
			signOut,
		} = this;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		const referral =
			reduxTokenAuth.currentUser.isSignedIn && current_user.is_referral_user;

		return (
			<header
				className={
					"dashboard" +
					`${
						site_state.mobile_menu === "sub_menu"
							? " open-main-menu open-sub-menu"
							: ""
					}` +
					`${site_state.mobile_menu === "main_menu" ? " open-main-menu" : ""}`
				}>
				<div className="navigation-wrapper">
					<div className="container-fluid">
						<div className="navigation">
							<ul className="left-menu">
								<li>
									<div className="logo-wrapper">
										<Link
											to={internal_uri.formatted_frontend_index_page_path()}></Link>
										<img
											src={logo}
											alt="meet.jobs logo"
											onClick={(e) => toggleMobileMainMenu(e)}
										/>
									</div>
								</li>
								<li>
									<h6>Dashboard</h6>
								</li>
								<li>
									<div className="select-wrapper">
										<Select
											className="drop-down drop-down-no-border"
											clearable={false}
											searchable={false}
											value={active_company.id}
											onChange={handleSelectChange}
											options={options}
										/>
									</div>
								</li>
							</ul>
							<ul className="right-menu">
								<li>
									<NavLink
										exact
										to={internal_uri.formatted_frontend_index_page_path()}
										className="btn btn-flat btn-hollow back-to-meet-jobs">
										<h5>{t("back_to_meet_jobs")}</h5>
									</NavLink>
								</li>
								<li
									className={`sub-menu-trigger-wrapper${
										site_state.sub_menu ? " open-sub-menu" : ""
									}`}>
									<a
										href="/#submenu"
										className="sub-menu-trigger"
										onClick={(e) => handleSubMenu(e)}>
										<div className="avatar">
											<div
												className="image-wrapper"
												style={{
													backgroundImage: `url(${active_user.formatted_avatar_toolbar_url()})`,
												}}
											/>
										</div>
										<h5>{active_user.formatted_name()}</h5>
									</a>

									<ul className="sub-menu">
										{DashboardMenuInner.map(({ name, path, category }, idx) => (
											<li
												key={idx}
												className={`${
													!referral && name === "my_referrals" ? "with-btn" : ""
												}`}>
												<NavLink exact to={internal_uri[path]()}>
													<h6>{t(name)}</h6>
												</NavLink>
												{!referral && name === "my_referrals" ? (
													<NavLink
														to={internal_uri.formatted_frontend_referrer_landing_page_path()}
														className="btn btn-smallest btn-flat btn-fill">
														<h6>{t("get_reward")}</h6>
													</NavLink>
												) : null}
											</li>
										))}
										<hr />
										<li>
											<a href="/#logout" onClick={(e) => signOut(e)}>
												<h6>{t("log_out")}</h6>
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="mobile-main-menu-wrapper">
					<div className="mobile-main-menu">
						<div className="container">
							<ul>
								<li
									className="with-avatar with-user-avatar with-border-bottom"
									onClick={(e) => toggleMobileSubMenu(e)}>
									<a href="/#" onClick={(e) => e.preventDefault()}>
										<div className="avatar">
											<div
												className="image-wrapper"
												style={{
													backgroundImage: `url(${active_user.formatted_avatar_toolbar_url()})`,
												}}
											/>
										</div>
										<h5>{active_user.formatted_name()}</h5>
									</a>
								</li>
								<li>
									<div className="select-wrapper">
										<Select
											className="drop-down drop-down-no-border"
											clearable={false}
											searchable={false}
											value={active_company.id}
											onChange={handleSelectChange}
											options={options}
										/>
									</div>
								</li>
								<li>
									<NavLink
										exact
										to={internal_uri.formatted_dashboard_index_page_path()}>
										<h5>{t("home")}</h5>
									</NavLink>
								</li>
								{access_control_list.jobs_manageable ? (
									<li>
										<NavLink
											exact
											to={internal_uri.formatted_dashboard_jobs_page_path()}>
											<h5>{t("jobs")}</h5>
										</NavLink>
									</li>
								) : (
									""
								)}
								{access_control_list.job_applications_manageable ? (
									<li>
										<NavLink
											exact
											to={internal_uri.formatted_dashboard_applications_page_path()}>
											<h5>{t("applications")}</h5>
										</NavLink>
									</li>
								) : (
									""
								)}
								{access_control_list.setting_manageable ? (
									<li>
										<NavLink
											exact
											to={internal_uri.formatted_dashboard_employer_profile_page_path()}>
											<h5>{t("employer_profile")}</h5>
										</NavLink>
									</li>
								) : (
									""
								)}
								{access_control_list.plan_manageable &&
								access_control_list.billing_manageable ? (
									<li>
										<NavLink
											exact
											to={internal_uri.formatted_dashboard_invoices_page_path()}>
											<h5>{t("invoices")}</h5>
										</NavLink>
									</li>
								) : (
									""
								)}
								{access_control_list.plan_manageable &&
								access_control_list.billing_manageable ? (
									<li>
										<NavLink
											exact
											to={internal_uri.formatted_dashboard_payment_setting_page_path()}>
											<h5>{t("payment_setting")}</h5>
										</NavLink>
									</li>
								) : (
									""
								)}
								<li>
									<NavLink
										exact
										to={internal_uri.formatted_dashboard_member_management_page_path()}>
										<h5>{t("member_management")}</h5>
									</NavLink>
								</li>
								<li className="back-to-meet-jobs">
									<Link
										to={internal_uri.formatted_frontend_index_page_path()}
										className="btn btn-smaller btn-flat btn-hollow">
										<h5>{t("back_to_meet_jobs")}</h5>
									</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="mobile-sub-menu-wrapper">
						<div className="mobile-sub-menu">
							<div className="container">
								<ul>
									<li
										onClick={(e) => toggleMobileSubMenu(e)}
										className="with-back-arrow with-border-bottom">
										<a href="/#back" onClick={(e) => e.preventDefault()}>
											<h5>{t("back")}</h5>
										</a>
									</li>
									{DashboardMenuInner.map(({ name, path, category }, idx) => (
										<li
											key={idx}
											className={`${
												!referral && name === "my_referrals" ? "with-btn" : ""
											}`}>
											<NavLink exact to={internal_uri[path]()}>
												<h5>{t(name)}</h5>
											</NavLink>
											{!referral && name === "my_referrals" ? (
												<NavLink
													to={internal_uri.formatted_frontend_referrer_landing_page_path()}
													className="btn btn-larger btn-flat btn-fill">
													<h5>{t("get_reward")}</h5>
												</NavLink>
											) : null}
										</li>
									))}
									<li>
										<a href="/#logout" onClick={(e) => signOut(e)}>
											<h5>{t("log_out")}</h5>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

Header.defaultProps = {
	companies: [],
	active_company_id: null,
};

export default connect(null, { signOutUser })(
	withNamespaces(["general"])(Header),
);
