class Abstract {
    constructor(attrs) {
        for (let prop in attrs) {
            if (Object.prototype.hasOwnProperty.call(attrs, prop)) {
                this[prop] = attrs[prop];
            }
        }
    }

    _build_associations(options) {
        let name  = options.name;
        let klass = options.klass;
        let attrs = options.attrs;

        this[name] = [];
        for(let i=0; i<attrs.length; i++) {
            this[name].push(new klass(attrs[i]));
        }
        return this[name];
    }

    _build_association(options) {
        let name  = options.name;
        let klass = options.klass;
        let attrs = options.attrs;

        this[name] = new klass(attrs);
        return this[name];
    }
}

export default Abstract;