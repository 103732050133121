import Abstract from './abstract';
import i18n from "../../i18n";

class Event extends Abstract {
    constructor(attrs) {
        super(attrs);

        const key = attrs.id;
        if(!i18n.exists(`events:${key}`)) {
            i18n.addResourceBundle('en', 'events', {
                [key]: attrs.name_en
            }, true, true);
            i18n.addResourceBundle('zh-TW', 'events', {
                [key]: attrs.name_zh_tw
            }, true, true);
        }
    }

    get_i18n_key() {
        return `events:${this.id}`;
    }

    get_url() {
        if(i18n.language === 'zh-TW') return this.event_setting.url_zh_tw;
        return this.event_setting.url_en;
    }

    get_banner_url() {
        if(i18n.language === 'zh-TW') return this.event_setting.banner_url_zh_tw || this.event_setting.banner_url_en;
        return this.event_setting.banner_url_en || this.event_setting.banner_url_zh_tw ;
    }
}

export default Event;