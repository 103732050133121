export default {

    // apply modal
    name: "Name",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    phone_number: "Phone number",
    your_phone_number: "Your phone number",
    https___linkedin_com_in_your_name: "https://linkedin.com/in/your-name",
    cover_letter: "Cover letter",
    write_cover_letter_here_: "Write cover letter here.",
    referral: "Referral",
    i_want_to_apply_without_a_referee_: "I want to apply without a referee.",
    or_enter_a_referral_code: "Or enter a referral code",
    sync_to_my_profile: "Sync to my profile",
    you_can_t_use_your_referral_code_: "You can't use your referral code.",
    applySuccessfullyTitle: "Apply successfully!",
    applySuccessfullyDescription: "Check your application status in {{my_applications}}.",
    applySuccessfullyOnboardRewardReminding: "Remember to confirm your employment when hired. You will receive <span class='reward'>100 USD</span> reward form Meet.jobs.",
    no_resume___cv_file_is_attached_do_you_want_to_apply_without_an_attachment_: "No Resume / CV file is attached. Do you want to Apply without an attachment?",

    // invite to apply
    referrer_info: "Referrer info",
    talent_info: "Talent info",
    ref_: "Ref.",
    choose_from_previous_referral: "Choose from previous referral",
    talent_s_first_name: "Talent's first name",
    talent_s_last_name: "Talent's last name",
    talent_s_email: "Talent's email",
    talent_s_phone_number: "Talent's phone number",
    recommendation_for_talent: "Recommendation for talent",
    tell_the_talent_why_this_job_is_perfect_for_him_her_: "Tell the talent why this job is perfect for him/her.",
    i_found_this_job_that_is_perfect_for_you_accept_my_referral_and_apply_for_it_on_meet_jobs_: "I found this job is a perfect fit and have applied for you on your behalf on Meet.jobs. Please contact with me if any further questions.",
    tell_a_little_bit_more_to_the_employer_why_you_recommend_this_talent_: "Tell a little bit more to the employer why you recommend this talent.",
    invite: "Invite",
    submit: "Submit",
    be_sure_everything_s_correct: "Be sure everything’s correct",
    you_cannot_modify_the_reference_letter_afterwards_are_you_sure_to_send_it_right_away_: "You cannot modify the reference letter afterwards. Are you sure to send it right away?",

    // cookie policy
    we_use_cookies_to_give_you_the_best_experience__we_suggest_you_accept_to_our___: "We use cookies to give you the best experience. We suggest you accept to our {{cookie_policy}}.",
    cookie_policy: "Cookie policy",
    got_it: "Got it",
    // 聲明
    disclaimer: "Disclaimer",
    disclaimer_content: `
    <p>Please Note!</p>
    <p>To submit your friend’s resume on Meet.jobs means you have been already authorized to do this by you friend.</p>
    <p>If NOT, please not to submit.</p>
    <p>Meet.jobs will not be responsible for any loss or damage of you or your friend, or violating the legislations related to Privacy and/or Personal Data in your country.</p>
    `,
    proceed: "Proceed",

};
