import React from 'react';
import { CreateEmployerFormWrapper } from '../../../containers/general.jsx';
import { CSSTransitionGroup } from 'react-transition-group'

const CreateEmployerModal = props => {

    const { site_state } = props;

    const closeModal = e => {
        e.preventDefault();
        props.updateCreateEmployerModal(false);
    };

    return (
        <CSSTransitionGroup
            component="div"
            transitionName="modal-wrapper-with-modal-slide"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
        >
            {(site_state.create_employer_modal) ?
                <div className="modal-wrapper create-employer-modal-wrapper">
                    <div className="modal-inner-wrapper">
                        <div className="modal-bg" onClick={ e => closeModal(e) } />
                        <div className="modal create-employer-modal">
                            <div className="btn-close" onClick={ e => closeModal(e) } />
                            <CreateEmployerFormWrapper />
                        </div>
                    </div>
                </div> : ''
            }
        </CSSTransitionGroup>
    );
};

export default CreateEmployerModal;
