import InternalUri from "./internal_uri";
import i18n from "../../i18n";

class Callback {
    constructor(attrs={
        resource: 'default',
        key: '',
        status: '',
        params: {}
    }) {
        this.type = attrs.resource;
        this.key = attrs.key;
        this.params = {...attrs.params};

        switch (attrs.status) {
            case 'fail':
            case 'danger':
            case 'false':
                this.status = 'danger';
                break;
            case 'success':
            case 'true':
            default:
                this.status = '';
                break;
        }
    }

    get_redirect_internal_uri(flash_message_id_to_open=null) {
        const internal_uri = new InternalUri();

        if(this.type === 'employers/member_invitation') {
            switch (this.key) {
                case 'role_not_found':
                case 'expired':
                    if(this.params.user_password === 'reset') {
                        return `${internal_uri.formatted_frontend_reset_password_page_path()}?redirect_url=${internal_uri.formatted_frontend_confirm_done_page_path()}${flash_message_id_to_open ? '&flash_message_id_to_open=' + flash_message_id_to_open : ''}`;
                    } else {
                        return internal_uri.formatted_frontend_confirm_done_page_path();
                    }
                case 'joined':
                    if(this.params.user_password === 'reset') {
                        return `${internal_uri.formatted_frontend_reset_password_page_path()}?redirect_url=${encodeURIComponent(`${internal_uri.formatted_dashboard_index_page_path()}?employer_id=${this.params.employer_id }`)}${flash_message_id_to_open ? '&flash_message_id_to_open=' + flash_message_id_to_open : ''}`;
                    } else {
                        return `${internal_uri.formatted_dashboard_index_page_path()}?employer_id=${this.params.employer_id}`
                    }
                case 'invitation_not_found':
                default:
                    return internal_uri.formatted_frontend_index_page_path();
            }
        }

        if(this.type === 'users/unverified_change_email') {
            switch (this.key) {
                case 'change_email_success':
                    return internal_uri.formatted_userpanel_index_page_path();
                case 'user_not_fount':
                case 'expired':
                case 'change_email_error':
                default:
                    return internal_uri.formatted_frontend_index_page_path();
            }
        }

        if(this.type === 'jobs/talent_invitation') {
            switch (this.key) {
                case 'talent_clicked':
                case 'assign_redirect_url':
                    return `${internal_uri.formatted_frontend_job_invitation_page_path(this.params.id)}?job_id=${this.params.job_id}&token=${this.params.token}`;
                default:
                    return internal_uri.formatted_frontend_index_page_path();
            }
        }

        if(this.type === 'user') {
            switch (this.key) {
                case 'expired':
                default:
                    return internal_uri.formatted_frontend_index_page_path();
            }
        }

        if(this.type === 'user/omniauth_callback') {
            switch (this.key) {
                case 'facebook':
                default:
                    return internal_uri.formatted_frontend_index_page_path();
            }
        }

        return internal_uri.formatted_frontend_index_page_path();
    };

    has_flash_message() {
        if(this.type === 'employers/member_invitation') {
            switch (this.key) {
                case 'expired':
                case 'joined':
                case 'role_not_found':
                case 'invitation_not_found':
                    return true;
                default:
                    return false;
            }
        }

        if(this.type === 'users/unverified_change_email') {
            switch (this.key) {
                case 'change_email_success':
                case 'user_not_fount':
                case 'expired':
                case 'change_email_error':
                    return true;
                default:
                    return false;
            }
        }

        if(this.type === 'user') {
            switch (this.key) {
                case 'expired':
                    return true;
                default:
                    return false;
            }
        }

        if(this.type === 'user/omniauth_callback') {
            switch (this.key) {
                case 'facebook':
                    return true;
                default:
                    return false;
            }
        }

        return false;
    };

    get_flash_message() {
        if(this.type === 'employers/member_invitation') {
            switch (this.key) {
                case 'expired':
                    return i18n.t('flash_messages:your_invitation_has_expired_please_ask_for_a_new_invitation_');
                case 'joined':
                    return i18n.t('flash_messages:you_have_successfully_joined_the_employer_dashboard_of___', { employer_name: this.params.employer_name });
                case 'role_not_found':
                case 'invitation_not_found':
                    return i18n.t('flash_messages:your_link_has_expired_please_send_the_request_again_');
                default:
                    return '';
            }
        }

        if(this.type === 'users/unverified_change_email') {
            switch (this.key) {
                case 'change_email_success':
                    return i18n.t('flash_messages:you_are_now_logged_in_as__', { email: this.params.email });
                case 'user_not_fount':
                case 'expired':
                case 'change_email_error':
                    return i18n.t('flash_messages:your_link_has_expired_please_send_the_request_again_');
                default:
                    return '';
            }
        }

        if(this.type === 'user') {
            switch (this.key) {
                case 'expired':
                    return i18n.t('flash_messages:your_link_has_expired_please_send_the_request_again_');
                default:
                    return '';
            }
        }

        if(this.type === 'user/omniauth_callback') {
            switch (this.key) {
                case 'facebook':
                    return i18n.t('flash_messages:can_t_get_your_email_address_from_the_service_Please_try_to_sign_up_with_another_method_');
                default:
                    return '';
            }
        }

        return '';
    };

    get_status() {
        return this.status;
    };

    show_flash_message_later() {
        if(this.type === 'employers/member_invitation') {
            if(this.params.user_password === 'reset') return true;
        }

        return false;
    }
}

export default Callback;