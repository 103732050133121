export default {
    my_referral_link: "我的推薦連結",

    refer_jobs_to_a_friend_and_get_reward_with_successful_hire_: "將精選工作推薦給朋友，獲取高額推薦感謝金。",
    view_jobs: "瀏覽工作機會",

    ongoing_referrals: "進行中的推薦",
    pending_invitation: "等待中的邀請",
    talent: "人才",
    date: "日期",
    applier: "應徵者",
    applications: "應徵數",
    job_title: "工作機會名稱",
    status: "狀態",

    reference_status: "推薦狀態",
    claim_rewards: "領取獎金",
};