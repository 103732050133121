import Config from "./config";
import axios from "axios";
import { sha256 } from 'js-sha256';

const config = new Config()

class facebookEvents {

    HashRequired = ["em","ph","ge","db","ln","fn","ct","st","zp","country"];

    constructor({eventName,userData=null}) {
        // this.eventName = eventName
        // this.userData = userData
        // this.isTest = isTest
        this.host = (typeof window === "undefined") ? "meet.jobs" : window.location.host;
        this.config = config.config
        this.facebook_event_api_version = "10.0";
        const isTest =  this.host.search('localhost') !== -1 ? true : false
        this.sendEvent(eventName,userData,isTest)
    }

    getCurrentUser(){
        if(window && window.localStorage.getItem('redux-store')){
            const store = JSON.parse(localStorage.getItem('redux-store'))
            const {
                reduxTokenAuth : {
                    currentUser
                },
            } = store
            return currentUser.isSignedIn  ? currentUser.attributes : null
        }

        return null
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    ApiUrl(){
        const apiVersion = this.facebook_event_api_version
        const pixelId = this.config.facebook_pixel_id
        const accessToken = this.config.facebook_event_token
        return `https://graph.facebook.com/v${apiVersion}/${pixelId}/events?access_token=${accessToken}`;
    }

    fbp(){
        this.getCookie("_fbp")
    }

    fbc(){
        this.getCookie("_fbc")
    }

    eventTime(){
        return Math.floor(Date.now() / 1000);
    }

    getUserData(user_data=null){

        if(!user_data){
            user_data = {}
            const currentUser = this.getCurrentUser()
            if(currentUser){
                user_data.em = currentUser.email
                user_data.ph = currentUser.phone
            }
        }

        const fbp = this.fbp()
        const fbc = this.fbc()
        if(fbp) user_data.fbq = fbp
        if(fbc) user_data.fbc = fbc

        if( Object.keys(user_data).length > 0 ){

            for(const [key, value] of Object.entries(user_data)){
                // console.log(key,value,typeof value)
                if(this.HashRequired.includes(key) && typeof value === "string"){
                    user_data[key] = sha256(value)
                }
            }
            return user_data
        }

        return null

    }

    sendEvent(eventName,userData,isTestEvent){

        if(!eventName) return false;

        const user_data = this.getUserData(userData)

        if(!user_data) return false;

        const apiUrl = this.ApiUrl()

        const event = {
            "event_name": eventName,
            "event_time": Math.floor(Date.now() / 1000),
            "action_source":"website",
            "event_source_url": document.URL,
            user_data
        }

        let data = {
            "data":[event]
        }

        if(isTestEvent) data.test_event_code = this.config.facebook_test_event_code

        axios.post(apiUrl,data).then(
            (response)=>{
                console.log(response)
            }
        ).catch(
            (error)=>{
                console.log(error)
            }
        )

    }

}

export default facebookEvents;
