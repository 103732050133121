export default {
    if_you_have_further_questions__please___meet_jobs_: "If you have further questions, please {{contact}} Meet.jobs.",
    contact: "contact",
    are_you_sure_to_upgrade_to___plan_: "Are you sure to upgrade to {{new_plan_name}} Plan?",
    would_you_like_to_upgrade_your_plan_: "Would you like to Upgrade your plan?",
    are_you_sure_to_upgrade_your_service_plan_and_accept_3_basic_rules_: "Are you sure to upgrade your service plan and Accept 3 Basic Rules?",
    if_you_would_like_to_upgrade_your_plan__please_click_next_: "If you would like to upgrade your plan, please click Next.",

    post_only_name: "Post",
    social_referral_name: 'Social',
    custom_referral_name: "Hunter",

    things_you_need_to_know_before_upgrade: "Things you need to know before upgrade",
    _1__all_published_jobs_would_be_closed_and_need_to_be_posted_again_: "1. All published jobs would be closed and need to be posted again.",
    _2__your_payment_would_change_to___: "2. Your payment would change to {{new_plan_info}}.",
    please_read_our_terms_as_followings_: "Please read our terms as followings:",

    post_only_payment: "US$ 30 per month",
    social_referral_payment: "16% of annual salary per hire",
    custom_referral_payment: "20% of annual salary per hire",
    __referral_payment: "{{ rate }}% of annual salary per hire",
};