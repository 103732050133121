import React from 'react';
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Select from 'react-select';
import { get_options } from "../../../../assets/js/functions";
import Textarea from "react-textarea-autosize";
import {withNamespaces} from "react-i18next";
import { CSSTransitionGroup } from 'react-transition-group'
import InternalUri from "../../../models/internal_uri";
import User from "../../../models/user";

const config = new Config();
const headers = new ApiHeaders();

class AddReferenceNotesModal extends React.Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            active_user_id: {},
            active_job: {},
            active_application: {},
            form_data: {},
            error_messages: {},
            loading: true,

            selectedOption: 0,
        };
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(next_props.site_state.add_reference_notes_modal.open !== this.props.site_state.add_reference_notes_modal.open) {
            if(next_props.site_state.add_reference_notes_modal.open) {
                this.setState({ loading: true });
                const { reduxTokenAuth } = next_props;
                let options = {
                    method: 'GET',
                    url: config.api_url('/countries'),
                    headers: headers.getItemsFromLocalStorage(),
                    json: true
                };
                axios(options)
                    .then((response) => {
                        headers.updateItemsToLocalStorage(response.headers);
                        this.props.updateConnectionOffModal(false);

                        const raw_data = response.data;
                        let phone_codes = get_options('phone_codes', raw_data);
                        const user = new User(reduxTokenAuth.currentUser.attributes);

                        this.setState((prev_state) => ({
                            ...prev_state,
                            active_user_id: user.id,
                            active_job: next_props.site_state.add_reference_notes_modal.job,
                            active_application: next_props.site_state.add_reference_notes_modal.application,
                            form_data: {
                                first_name: '',
                                last_name: '',
                                email: user.formatted_form_email(),
                                phone_number: '',
                                phone_codes_selected_value: '886',
                                content: '',
                                realization: false
                            },
                            options: {
                                phone_codes
                            },
                            error_messages: {},
                            loading: false
                        }));
                    })
                    .catch((error) => {
                        if(typeof error.response === 'undefined') {
                            this.props.updateConnectionOffModal(true);
                            const internal_uri = new InternalUri();
                            const { location } = this.props;
                            this.props.history.push(`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${location.pathname}${location.search}`);
                        }

                        if(error.response) {
                            headers.updateItemsToLocalStorage(error.response.headers);
                            if(error.response.status === 401) window.location.reload();
                        }

                        this.setState((prev_state) => ({
                            ...prev_state,
                            loading: false,
                            error_messages:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: 'There was an error. Please try again later.'},
                        }));
                    });
            }
        }
    };

    closeModal = e => {
        e.preventDefault();
        this.props.updateAddReferenceNotesModal(false, {});
    };

    handleSelectChange = (property_name, selected_option) => {
        if(selected_option) {
            this.setState((prev_state) => ({
                form_data: {
                    ...prev_state.form_data,
                    [property_name]: selected_option.value
                }
            }));
        }
    };

    handleInputChange = (e, property_name) => {
        e.preventDefault();
        let value = e.target.value;

        // if(property_name==='email') value = value.toLowerCase();

        this.setState((prev_state) => ({
            form_data: {
                ...prev_state.form_data,
                [property_name]: value
            }
        }));
    };

    handleToggleChange = (e, attr) => {
        e.preventDefault();
        this.setState((prev_state) => ({
            form_data: {
                ...prev_state.form_data,
                [attr]: !prev_state.form_data[attr]
            }
        }));
    };

    submitForm = e => {
        e.preventDefault();
        const { form_data, active_job, active_application, sending } = this.state;

        if(!sending) {
            this.setState({ sending: true });
            const internal_uri = new InternalUri();

            let formData = new FormData();
            formData.append('referrer_note[referrer_first_name]', form_data.first_name);
            formData.append('referrer_note[referrer_last_name]', form_data.last_name);
            formData.append('referrer_note[referrer_email]', form_data.email);
            formData.append('referrer_note[referrer_phone]', `${form_data.phone_codes_selected_value}${form_data.phone_number}`);
            formData.append('referrer_note[content]', form_data.content);

            let options = {
                method: 'POST',
                url: config.api_url(`/jobs/${active_job.id}/job_applications/${active_application.id}/referrer_notes`),
                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                data: formData,
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    this.setState({
                        error_messages: {},
                        sending: false
                    }, () => {
                        this.props.history.push(`${internal_uri.formatted_userpanel_my_referrals_page_path()}?refresh=true`);
                        this.props.updateAddReferenceNotesModal(false, {});
                    });
                })
                .catch((error) => {
                    if(typeof error.response === 'undefined') {
                        this.props.updateConnectionOffModal(true);
                        const { location } = this.props;
                        this.props.history.push(`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${location.pathname}${location.search}`);
                    }

                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        sending: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));
                });
        }
    };

    render() {

        const { site_state } = this.props;
        const { active_application, form_data, options, loading, sending, error_messages } = this.state;
        const { handleInputChange, handleSelectChange, handleToggleChange, closeModal, submitForm } = this;
        const { t } = this.props;

        return (
            <CSSTransitionGroup
                component="div"
                transitionName="modal-wrapper-slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {(site_state.add_reference_notes_modal.open) ?
                    <div className="modal-wrapper add-reference-notes-modal-wrapper">
                        <CSSTransitionGroup
                            component="div"
                            className="modal-inner-wrapper"
                            transitionName="modal-slide"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >
                            <div className="modal-bg" onClick={ e => closeModal(e) } />
                            {(!loading) ?
                                <div className="modal add-reference-notes-modal">
                                    <div className="btn-close" onClick={ e => closeModal(e) } />
                                    <h4 className="modal-title">{t('add_reference_letter')}</h4>
                                    <h6 className="candidate-name">{active_application.formatted_applier_name()}</h6>
                                    <form>
                                        {
                                            (error_messages.full_message) ?
                                                <h6 className="message error-message">{error_messages.full_message}</h6> :
                                                ''
                                        }
                                        <label htmlFor="first-name"><h5>{t('name')}</h5></label>
                                        <div className="form-row form-row-2">
                                            <div className="form-col">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    value={form_data.first_name}
                                                    placeholder={t('first_name')}
                                                    onChange={ e => handleInputChange(e, `first_name`) }
                                                    id="first-name"
                                                />
                                            </div>
                                            <div className="form-col">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    value={form_data.last_name}
                                                    placeholder={t('last_name')}
                                                    onChange={ e => handleInputChange(e, `last_name`) }
                                                    id="last-name"
                                                />
                                            </div>
                                        </div>

                                        <label htmlFor="email"><h5>{t('email')}</h5></label>
                                        <div className="form-row">
                                            <div className="form-col">
                                                <input
                                                    className="input input-readonly"
                                                    type="email"
                                                    value={form_data.email}
                                                    placeholder={t('email')}
                                                    id="email"
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <label htmlFor="phone-number"><h5>{t('phone_number')}</h5></label>
                                        <div className="form-row form-row-2 form-row-phone-number">
                                            <div className="form-col">
                                                <Select
                                                    className="drop-down drop-down-larger drop-down-grey"
                                                    clearable={false}
                                                    value={form_data.phone_codes_selected_value}
                                                    onChange={ value => handleSelectChange('phone_codes_selected_value', value) }
                                                    options={options.phone_codes}
                                                />
                                            </div>
                                            <div className="form-col">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    value={form_data.phone_number}
                                                    onChange={ e => handleInputChange(e, `phone_number`) }
                                                    placeholder={t('phone_number')}
                                                    id="phone-number"
                                                />
                                            </div>
                                        </div>

                                        <label htmlFor="reference-notes"><h5>{t('general:reference_letter')}</h5></label>
                                        <div className="form-row">
                                            <Textarea
                                                className="textarea"
                                                minRows={5}
                                                maxRows={10}
                                                placeholder={t('let_us_know_more_about_this_candidate_')}
                                                value={form_data.content}
                                                onChange={ e => handleInputChange(e, `content`) }
                                                id="reference-notes"
                                            />
                                        </div>

                                        <div className="form-row">
                                            <input
                                                type="checkbox"
                                                className="checkbox-input"
                                                id="realization"
                                                checked={form_data.realization}
                                                readOnly={true}
                                            />
                                            <label
                                                htmlFor="sync-to-my-profile"
                                                className="checkbox"
                                                onClick={ e => handleToggleChange(e, 'realization') }
                                            >
                                                <p>{t('i_realize_that_the_reference_letter_can_not_be_modified_once_it_is_sent_')}</p>
                                            </label>
                                        </div>

                                        {
                                            (sending) ?
                                                <div className="buttons-wrapper">
                                                    <button onClick={ e => e.preventDefault() } className="btn btn-flat btn-ghost disabled">
                                                        <h5>{t('general:cancel')}</h5>
                                                    </button>
                                                    <button onClick={ e => e.preventDefault() } className="btn btn-flat btn-fill disabled">
                                                        <h5>{t('general:add')}</h5>
                                                    </button>
                                                </div> :
                                                <div className="buttons-wrapper">
                                                    <button className="btn btn-flat btn-ghost" onClick={ e => closeModal(e) }>
                                                        <h5>{t('general:cancel')}</h5>
                                                    </button>
                                                    {
                                                        (form_data.realization) ?
                                                            <button className="btn btn-flat btn-fill" onClick={ e => submitForm(e) }>
                                                                <h5>{t('general:add')}</h5>
                                                            </button> :
                                                            <button onClick={ e => e.preventDefault() } className="btn btn-flat btn-fill disabled">
                                                                <h5>{t('general:add')}</h5>
                                                            </button>
                                                    }
                                                </div>
                                        }
                                    </form>
                                </div> : ''
                            }
                        </CSSTransitionGroup>
                    </div> : ''
                }
            </CSSTransitionGroup>
        );
    };
}

export default withNamespaces(['userpanel_modals', 'general'])(AddReferenceNotesModal);
