import React from 'react';
import { withNamespaces, Interpolate } from 'react-i18next';
import { get_value_from_query_string } from "../../../../assets/js/functions";
import Config from "../../../models/config";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Job from "../../../models/job";

const config = new Config();
const headers = new ApiHeaders();

class ExternalJobRedirectPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.tick = this.tick.bind(this);
        this.state = {
            remaining_seconds: 5,
            url: '',
            job_id: -1,
            job: {},
            loading: true,
            error_messages: {},
        };
    };

    componentDidMount() {
        this._isMounted = true;

        const query = decodeURI(this.props.location.search);
        if(query) {
            let job_id = get_value_from_query_string('job_id', query);
            let user_id = get_value_from_query_string('user_id', query);
            if(job_id && user_id) {
                this.setState({ job_id }, () => this.fetchData(user_id));
            }
            this.props.history.replace(this.props.location.pathname);
        }
    };

    fetchData(user_id) {
        const { job_id } = this.state;
        let options = {
            method: 'GET',
            url: config.api_url(`/jobs/${job_id}`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let job = {};
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_job = response.data;
                    job = new Job(raw_job);

                    let formData = new FormData();
                    formData.append('external_job_application[job_id]', job_id);
                    options = {
                        method: 'POST',
                        url: config.api_url(`/users/${user_id}/external_job_applications`),
                        headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                        data: formData,
                        json: true
                    };
                    return axios(options);
                }
            })
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const external_job_application = response.data;

                    this.setState((prev_state) => ({
                        ...prev_state,
                        job,
                        url: external_job_application.job.external_url,
                        loading: false,
                        error_messages: {}
                    }), () => {
                        this.timer = setInterval(this.tick, 1000);
                    });
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));
                }
            });
    };

    componentWillUnmount() {
        clearInterval(this.timer);
        this._isMounted = false;
    };

    tick = () => {
        let remaining_seconds = this.state.remaining_seconds - 1;
        if(remaining_seconds <= 0) {
            window.location.replace(this.state.url);
        } else {
            this.setState({ remaining_seconds });
        }
    };

    render() {

        const { job, url, remaining_seconds, error_messages, loading } = this.state;
        const { t } = this.props;

        return(
            <div className="page-external-job-redirect content-wrapper">
                {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                <div className="image-wrapper" />
                {
                    (!loading) ?
                        <p>
                            <Interpolate
                                i18nKey="redirect_in___seconds"
                                useDangerouslySetInnerHTML={true}
                                remaining_seconds={remaining_seconds}
                            />
                        </p> :
                        <p>{t('loading')}...</p>
                }
                {
                    (!loading) ?
                        <div className="block">
                            <h3>{job.formatted_title()}</h3>
                            <h5>{job.formatted_employer_name()}</h5>
                            <a
                                className="link"
                                href={url}
                            >
                                <p>{url}</p>
                            </a>
                        </div> :
                        <div className="block">
                            <h3>{t('loading')}...</h3>
                            <h5>{t('loading')}...</h5>
                            <a href="/#loading" onClick={ e => e.preventDefault() }><p>{t('loading')}...</p></a>
                        </div>
                }
                <p>{t('please_apply_in_the_redirected_page__thank_you_')}</p>
            </div>
        );
    };
}

export default withNamespaces(['general'])(ExternalJobRedirectPage);
