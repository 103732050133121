export default {
    account: "帳戶",
    profile_picture: "個人頭像",
    _recommend_size__160x160px_: "(建議尺寸: 160x160像素)",
    user_name: "使用者名稱",
    email: "Email",
    not_verify_yet_we_will_still_send_email_to___: "新信箱驗證前，我們將持續寄送到 {{email}}",
    resend_verification: "重寄驗證信",
    discard_change: "放棄變更",
    newsletter:"訂閱電子報",
    jobs_recommendation_email: "Meet.jobs 工作機會推薦",
    newsletter_title: "電子報",
    password: "密碼",
    change_password: "修改密碼",
    current_password: "目前密碼",
    your_current_password: "您目前的密碼",
    new_password: "新密碼",
    your_new_password: "設定您新的密碼",
    new_password_confirmation: "確認新密碼",
    re_enter_new_password: "再次輸入新密碼",
    you_currently_do_not_have_a_password_you_may_add_one_to_your_account_: "您目前沒有設定密碼，請由此處新增一個。",
    add_password: "新增密碼",

    phone_number: "電話號碼",
    profile_for_application: "應徵資訊",
    name: "姓名",
    first_name: "名字",
    last_name: "姓氏",
    https___linkedin_com_in_your_name: "https://linkedin.com/in/your-name",
    to_save_your_changes__click_save_: "要儲存以下輸入的變更，請按「儲存」。",
};