import React from "react";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers.js";
import axios from "axios";
import User from "../../../models/user.js";
import { withNamespaces } from "react-i18next";
import { CSSTransitionGroup } from "react-transition-group";
import { compare } from "../../../../assets/js/functions";

const config = new Config();
const headers = new ApiHeaders();

class MyResumePage extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.fetchData = this.fetchData.bind(this);
		this.handleNotificationChange = this.handleNotificationChange.bind(this);
		this.checkPendingData = this.checkPendingData.bind(this);
		this.discard = this.discard.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.handleResumeChange = this.handleResumeChange.bind(this);
		this.toggleUploadSection = this.toggleUploadSection.bind(this);
		this.state = {
			active_user_id: -1,
			user: {},
			form_data: {},
			original_form_data: {},
			default_resume_attachment: null,
			error_messages: {},
			error_messages_password: {},
			has_pending_data: false,
			loading: true,
			publishing: false,
			show_upload_resume: true,
		};
		this.once_sent_out = false;
		this.resent_change_email = false;
	}

	componentDidMount() {
		this._isMounted = true;
		this.setState(
			{
				active_user_id: this.props.reduxTokenAuth.currentUser.attributes.id,
			},
			() => this.fetchData(),
		);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchData() {
		let options = {
			method: "GET",
			url: config.api_url(
				`/users/${this.state.active_user_id}?include=resume_attachments`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};

		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				this.props.updateConnectionOffModal(false);
				if (this._isMounted) {
					const raw_user = response.data;
					const user = new User(raw_user);

					let form_data = {
						resume: {
							file: {},
						},
						portfolio_url: user.formatted_portfolio_url(),
						// email_notification_checked: true,
						marketing_email: user.formatted_notification_setting_marketing_email(),
						jobs_recommendation_email: user.formatted_notification_setting_job_recommendation_email(),
						public_to_employer: !!user.resume_setting.public_to_employer,
					};

					this.setState((prev_state) => ({
						...prev_state,
						user,
						form_data,
						show_upload_resume: raw_user.default_resume_attachment
							? false
							: true,
						default_resume_attachment: raw_user.default_resume_attachment,
						original_form_data: JSON.parse(JSON.stringify(form_data)),
						has_pending_data: false,
						loading: false,
						error_messages: {},
					}));
				}
			})
			.catch((error) => {
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) window.location.reload();
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined") {
						window.location.reload();
						this.props.updateConnectionOffModal(true);
					}

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				}
			});
	}

	toggleUploadSection = (e) => {
		e.preventDefault();
		this.setState({
			show_upload_resume: !this.state.show_upload_resume,
		});
	};

	handleNotificationChange = (e, property_name) => {
		// e.preventDefault();
		let key = e.target.value;

		this.setState(
			(prev_state) => {
				let error_messages = { ...prev_state.error_messages };
				// check form error
				return {
					...prev_state,
					form_data: {
						...prev_state.form_data,
						[key]: !prev_state.form_data[key],
					},
					error_messages,
				};
			},
			() => this.checkPendingData(),
		);
	};

	handleResumeChange = (e) => {
		e.preventDefault();
		let file = e.target.files[0];
		if (file) {
			this.setState(
				(prev_state) => ({
					...prev_state,
					form_data: {
						...prev_state.form_data,
						resume_attachments_attributes: [
							{
								asset: file,
								official: true,
							},
						],
					},
				}),
				() => this.checkPendingData(),
			);
		}
	};

	//check pending data

	checkPendingData() {
		const { form_data, original_form_data } = this.state;
		const has_pending_data = !compare(
			JSON.parse(JSON.stringify(form_data)),
			original_form_data,
		);
		this.setState({ has_pending_data });
	}

	// actions bar

	discard = (e) => {
		e.preventDefault();
		this.setState(
			(prev_state) => ({
				...prev_state,
				form_data: JSON.parse(JSON.stringify(prev_state.original_form_data)),
				error_messages: {},
			}),
			() => this.checkPendingData(),
		);
	};

	submitForm = (e) => {
		e.preventDefault();
		const { form_data, active_user_id, publishing } = this.state;
		if (!publishing) {
			this.setState({ publishing: true });
			let formData = new FormData();
			if (
				typeof form_data.resume_attachments_attributes !== "undefined" &&
				typeof form_data.resume_attachments_attributes[0].asset !== "undefined"
			) {
				// formData.append('user[name]','testA' + Date.now());
				// formData.append('user[name]','testNow');
				formData.append(
					"user[resume_attachments_attributes][0][asset]",
					form_data.resume_attachments_attributes[0].asset,
				);
				formData.append(
					"user[resume_attachments_attributes][0][official]",
					form_data.resume_attachments_attributes[0].official,
				);
			}
			formData.append(
				"user[resume_setting_attributes][public_to_employer]",
				form_data.public_to_employer,
			);
			formData.append(
				"user[notification_setting_attributes][jobs_recommendation_email]",
				form_data.jobs_recommendation_email,
			);

			let options = {
				method: "PUT",
				url: config.api_url(`/users/${active_user_id}`),
				headers: headers.getItemsFromLocalStorage("multipart/form-data"),
				data: formData,
				json: true,
			};
			axios(options)
				.then((response) => {
					headers.updateItemsToLocalStorage(response.headers);
					this.props.updateConnectionOffModal(false);

					this.props.verifyTokenRequestSucceeded(response.data);

					if (this._isMounted) {
						this.setState(
							(prev_state) => ({
								...prev_state,
								error_messages: {},
								publishing: false,
							}),
							() => {
								this.fetchData();
							},
						);
					}
				})
				.catch((error) => {
					if (error.response) {
						headers.updateItemsToLocalStorage(error.response.headers);
						if (error.response.status === 401) {
							this.props.verifyTokenRequestFailed();
							window.location.reload();
						}
					}

					if (this._isMounted) {
						if (typeof error.response === "undefined") {
							window.location.reload();
							this.props.updateConnectionOffModal(true);
						}

						this.setState((prev_state) => ({
							...prev_state,
							publishing: false,
							error_messages:
								error.response &&
								error.response.data &&
								error.response.data.status === "error"
									? error.response.data.errors
									: {
											full_message:
												"There was an error. Please try again later.",
									  },
						}));
					}
				});
		}
	};

	render() {
		const {
			form_data,
			loading,
			publishing,
			has_pending_data,
			default_resume_attachment,
			show_upload_resume,
		} = this.state;
		const { error_messages } = this.state;
		const {
			handleNotificationChange,
			handleResumeChange,
			toggleUploadSection,
		} = this;
		const { discard, submitForm } = this;
		const { t } = this.props;

		if (loading) {
			return (
				<div className="loading-skeleton-wrapper">
					<h3>Loading...</h3>
				</div>
			);
		}

		let has_errors = false;
		const white_list = ["full_message", "avatar", "unverified_change_emails"];
		for (let key in error_messages) {
			if (white_list.indexOf(key) === -1 && error_messages[key].length)
				has_errors = true;
		}

		return (
			<div className="userpanel-account-setting">
				<div className="container-fluid">
					<div className="inner-wrapper">
						<h2>{t("general:my_resume")}</h2>
						{/*{(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}*/}
						{default_resume_attachment ? (
							<div className="block account">
								<div className="form-row form-row-dire-col">
									{/*{JSON.stringify(default_resume_attachment,null,4) }*/}
									<div className="form-col">
										<h4>{t("general:default_resume")}</h4>
										<h5>
											{t("general:file_name")}{" "}
											{default_resume_attachment.asset.filename}
										</h5>
										<a
											href={default_resume_attachment.asset.url}
											target="_blank"
											className="btn btn-smallest btn-fill btn-flat"
											rel="noopener noreferrer">
											{t("general:view_default_resume")}
										</a>
										<button
											onClick={toggleUploadSection}
											className="btn btn-smallest btn-flat btn-hollow btn-extend">
											{show_upload_resume
												? t("general:cancel")
												: t("general:upload_new_one")}
										</button>
									</div>
								</div>
							</div>
						) : (
							""
						)}
						{show_upload_resume ? (
							<div className="block account">
								<form>
									<label htmlFor="resume" className="lines">
										<h4>{t("general:upload_new_resume")}</h4>
										<h6>
											{form_data.resume &&
											typeof form_data.resume.file !== "undefined"
												? form_data.resume.file.name
												: t("general:no_resume___cv_file")}
										</h6>
									</label>
									<div className="form-row">
										<input
											className="input-upload-file"
											id="resume"
											type="file"
											accept={
												`.pdf, ` +
												`.doc, ` +
												`.docx, ` +
												`application/pdf, ` +
												`application/msword, ` +
												`application/vnd.openxmlformats-officedocument.wordprocessingml.document`
											}
											onChange={(e) => handleResumeChange(e)}
										/>
										<label
											htmlFor="resume"
											className="btn btn-larger btn-flat btn-hollow btn-upload-file">
											{t("general:choose_file")}
										</label>
									</div>
								</form>
							</div>
						) : null}

						<div className="block account">
							<form>
								<div className="form-row">
									<div>
										<input
											id="public_to_employer"
											checked={form_data.public_to_employer}
											onChange={(e) =>
												handleNotificationChange(e, `public_to_employer`)
											}
											value="public_to_employer"
											type="checkbox"
										/>
										&nbsp;
										<label htmlFor="public_to_employer">
											{t("general:open_for_searching")}
										</label>
									</div>
								</div>
								<div className="form-row">
									<input
										id="jobs_recommendation_email"
										checked={form_data.jobs_recommendation_email}
										onChange={(e) =>
											handleNotificationChange(e, `jobs_recommendation_email`)
										}
										value="jobs_recommendation_email"
										type="checkbox"
									/>
									&nbsp;
									<label htmlFor="jobs_recommendation_email">
										{t("jobs_recommendation_email")}
									</label>
								</div>
							</form>
						</div>
					</div>
				</div>

				<CSSTransitionGroup
					component="div"
					transitionName="actions-bar-slide"
					transitionEnterTimeout={200}
					transitionLeaveTimeout={100}>
					{has_pending_data ? (
						<div className="actions-bar-wrapper">
							<div className="container">
								<div className="actions-bar">
									<p>
										{publishing
											? `${t("general:saving")}...`
											: t("to_save_your_changes__click_save_")}
									</p>
									<div className="buttons-wrapper">
										{publishing ? (
											<button
												onClick={(e) => e.preventDefault()}
												className="btn btn-larger btn-flat btn-fill disabled">
												<h5>{t("general:cancel")}</h5>
											</button>
										) : (
											<button
												className="btn btn-larger btn-flat btn-fill"
												onClick={(e) => discard(e)}>
												<h5>{t("general:cancel")}</h5>
											</button>
										)}
										{publishing || has_errors ? (
											<button
												onClick={(e) => e.preventDefault()}
												className="btn btn-larger btn-flat btn-hollow disabled">
												<h5>{t("general:save")}</h5>
											</button>
										) : (
											<button
												className="btn btn-larger btn-flat btn-hollow"
												onClick={(e) => submitForm(e)}>
												<h5>{t("general:save")}</h5>
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</CSSTransitionGroup>
			</div>
		);
	}
}

export default withNamespaces([
	"userpanel_account_setting",
	"flash_messages",
	"general",
])(MyResumePage);
