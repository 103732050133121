export default {
    if_you_have_further_questions__please___meet_jobs_: "如果你有任何問題，請{{contact}} Meet.jobs。",
    contact: "聯繫",
    are_you_sure_to_upgrade_to___plan_: "確定要升級到 {{new_plan_name}} 方案？",
    would_you_like_to_upgrade_your_plan_: "確認要升級您的服務方案？",
    are_you_sure_to_upgrade_your_service_plan_and_accept_3_basic_rules_: "您確認要升級服務方案，並同意三定律？",
    if_you_would_like_to_upgrade_your_plan__please_click_next_: "要升級您的服務方案，請按「下一步」。",

    post_only_name: "Post",
    social_referral_name: "社群方案",
    custom_referral_name: "獵才方案",

    things_you_need_to_know_before_upgrade: "升級前您需要知道的事",
    _1__all_published_jobs_would_be_closed_and_need_to_be_posted_again_: "1. 所有招募中的工作機會均會被自動關閉，請重新張貼。",
    _2__your_payment_would_change_to___: "2. 您的收費方式將變更為: {{new_plan_info}}。",
    please_read_our_terms_as_followings_: "詳情請閱讀我們的條款：",

    post_only_payment: "每個工作機會每月 US$ 30",
    social_referral_payment: "每個成功率取 16% 年度薪資",
    custom_referral_payment: "每個成功率取 20% 年度薪資",
    __referral_payment: "每個成功率取 {{ rate }}% 年度薪資",
};