import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
// import LngDetector from 'i18next-browser-languagedetector';
import translation_EN_US from './locales/en-us/translation.js';
import translation_ZH_TW from './locales/zh-tw/translation.js';

const resources = {
    "en": translation_EN_US,
    "zh-TW": translation_ZH_TW
};

i18n
    // .use(LngDetector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: ["en", "zh-TW"],
        // debug: true,
        lng: "en",
        // detection: {
        //     // order: ['querystring', 'navigator'],
        //     order: ['querystring'],
        // },
        react: {
            wait: false,
            // bindI18n: 'languageChanged loaded',
            // bindStore: 'added removed',
            nsMode: 'general'
        }
    });

export default i18n;