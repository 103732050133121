export default {
  // user_agreement
  user_agreement_title_0: `<span class="li-number">1.</span>引言及名詞定義`,
  user_agreement_content_0: `
        <h5>「Meet.jobs」網站（網址：https://meet.jobs/）及其一切相關之衍生服務皆由「一起工作科技有限公司」所建置、維護以及提供。</h5>
    
        <h5>1.2. 名詞定義</h5>
        <h6>1.2.1. 「公司」是指「一起工作科技有限公司」。</h6>
        <h6>1.2.2. 「網站」、「系統」是指 Meet.jobs 透過資訊以及電子設備，例如電腦、行動電話等所營運，以提供會員服務之網站。</h6>
        <h6>1.2.3. 「服務」是指 Meet.jobs 透過網站及一切網路和實體管道所提供之求職與推薦服務。</h6>
        <h6>1.2.4. 「會員」是指同意本協議並使用 Meet.jobs 服務的個人，包括自然人與法人。</h6>
        <h6>1.2.5. 「帳戶」是指會員在 Meet.jobs 完成註冊後所持有可以使用服務之電子身份資料。</h6>
        <h6>1.2.6. 「密碼」是指由會員自行指定或網站自動生成，使會員得以用於註冊和登入 Meet.jobs 服務之授權代碼。</h6>
        <h6>1.2.7. 「訪客」是指尚未註冊成為會員，只能使用部份網站功能與服務的個人。</h6>

        <h5>1.3. 您按下「註冊」、「立即加入」、「訂閱服務」等或相似字詞，註冊、登入或使用「Meet.jobs」，即表示您同意本協議，並與
            Meet.jobs 簽訂具法律效力之合約。如您不同意本協議，請勿按下「註冊」（或相似字詞），並請勿再進入或以任何方式使用 Meet.jobs
            的「服務」。如您要終止本協議，只需不再存取或使用 Meet.jobs 的服務，或與我們聯繫，關閉帳戶即可。</h5>
 
        <h5>1.4. 本協議適用於 Meet.jobs 之網站以及一切依本協議所涵蓋之服務，並同時適用於會員及訪客。</h5>
    
        <h5>1.5. 作為
            Meet.jobs「服務」的「會員」或「訪客」，您個人資料的收集、使用及分享必須遵守本服務之「隱私權政策」和「Cookie政策」。</h5>
    
        <h5>1.6. Meet.jobs 隨時可能修改本協議、「隱私權政策」以及「Cookie
            政策」。如有重大變更，我們會透過「服務」或其他方式通知您，讓您有機會在變更生效前詳閱變更內容。我們同意變更不能溯及既往。如您不同意變更後的內容，您可以不再使用、存取
            Meet.jobs 服務，或透過聯絡我們關閉您的帳戶。如您繼續使用 Meet.jobs 服務，即代表您同意更新後的條款。</h5>
    
        <h5>1.7. Meet.jobs 的會員服務可分為「共同部份」、「求職部份」和「推薦部份」等三個部份，分別由本協議第2、3、4條說明。</h5>
        `,

  user_agreement_title_1: `<span class="li-number">2.</span>共同部份`,
  user_agreement_content_1: `
        <h5>2.1. 會員即是 Meet.jobs 帳戶的持有人。您同意：1）選擇安全度高的密碼；2）妥善保管您的密碼，不可隨意對外透露；3）不會轉讓帳戶的任一部份；4）遵守法律，以及 Meet.jobs 的協議、隱私權和Cookie政策。如您與他人有利益協議，例如僱傭關係時，他人或有權取得部份您的帳戶可取得的服務資訊，但付費方仍無權使用您的會員帳戶。</h5>
   
        <h5>2.2. 如您在 Meet.jobs 上使用任何付費服務，即表示您同意支付相關手續費、稅金及其他一切衍生費用。如未付款，Meet.jobs
            會終止該付費服務。此外，您同意：</h5>
        <h6>A. 您可能因所在地點的緣故而需要支付匯兌手續費、匯差（例如匯率）或其他的衍生費用。</h6>
        <h6>B.
            即便是在您的付款方式（如信用卡）過期後，我們仍可儲存並繼續透過該方式收費，除可避免您的「服務」中斷以外，也可用於支付您可能購買的其他「服務」。</h6>
        <h6>C. 我們會根據您在購買時提供的帳單資訊計算應付稅金。</h6>
        <h6>D. 如為連續性收費服務，則在您於 Meet.jobs 系統上，或以明確可留下紀錄方式回覆取消或暫停該服務以前，Meet.jobs
            都具有收費之權利。</h6>
    
        <h5>2.3. 您同意我們透過以下方式向您提供訊息及通知：1）透過 Meet.jobs
            服務；2）傳送到您提供的聯絡方式，如信箱、手機或郵寄地址等。您可以直接在 Meet.jobs 網站的「設定」中管理您從 Meet.jobs
            接收到的訊息。</h5>
   
        <h5>2.4. 您所傳送或發表於 Meet.jobs 的內容及資訊屬於您所有，您只授權 Meet.jobs 以下非獨佔性授權：</h5>
        <h6>A. 除了 a) 您已經透過 Meet.jobs 將資訊分享給他人，且對方可複製、轉分享或儲存之內容，以及 b) Meet.jobs
            從備份或其他系統移除內容所需合理時間以外，您都可以透過在 Meet.jobs 「刪除」該內容的方式終止這項權限。</h6>
        <h6>B.
            未經您同意，我們不會在提供給他人的產品廣告及第三方服務中包含該內容（包括贊助內容）。但是，我們有權在您的內容及資訊附近顯示廣告，且按照「隱私權政策」中的規定顯示您的社群互動並附帶廣告，不需付費給您或他人。</h6>
        <h6>C. 我們可能會編輯內容或改變格式（如：翻譯、調整大小、排版或檔案類型或移除後設資料），但我們不會變更您想表達的意思。</h6>
        <h6>D. 因為您擁有您的內容及資料，而我們僅擁有非專屬的權利，因此您可以選擇是否公開內容。</h6>
        <h6>E. 您同意 Meet.jobs 可按照「隱私權政策」及您的選項（包括設定）存取、儲存、處理和使用您提供的任何資訊和個人資料。</h6>
        <h6>F. 傳送與 Meet.jobs「服務」相關的建議或意見，即表示您同意 Meet.jobs
            不需支付任何補償，便可將您的意見使用及分享（但非必要）到任何用途。</h6>
        <h6>G. 您同意只提供不違法也不違反他人權利（包括智慧財產權）的內容或資訊。您也同意您的檔案資料屬實。為符合當地法令，Meet.jobs
            在某些國家可能需要移除部分內容。若您提供的資訊侵害他人權利或智慧財產權，您應自行承擔相關法律責任，概與 Meet.jobs 無涉。</h6>
    
        <h5>2.5. 我們可能變更或停止任何 Meet.jobs「服務」。我們不保證會儲存或持續顯示您的任何資訊或內容。請注意 Meet.jobs
            非儲存服務。除適用法律規定及
            Meet.jobs「隱私權政策」所規定的範圍以外，您同意我們沒有義務儲存、保留或提供您或他人所提供之任何內容或資訊的副本給您。</h5>
    
        <h5>2.6. 使用 Meet.jobs 您可能會看到不正確、不完整、過時、誤導、違法、具冒犯性或其他有害的內容或資訊。Meet.jobs
            通常不會審查會員或他人提供的內容。您同意我們不為他人（包括其他會員）的內容或資訊負責。我們不一定能夠預防他人濫用我們的服務，且您同意我們不需為此類濫用行為負責。您亦承擔以下風險：Meet.jobs
            讓您的聯絡人及關注者知道您本人或貴組織上新聞時，他人相關之內容可能會與您本人或貴組織誤連。</h5>
    
        <h5>2.7. Meet.jobs 保留限制您使用「服務」的權利，包括您的聯絡人數及您聯絡其他會員的權限。如果 Meet.jobs
            認為您可能違反本協議或法律，或有濫用「服務」情事，Meet.jobs保留限制、停用或終止帳戶的權利。</h5>
    
        <h5>2.8. Meet.jobs 保留所有服務之其智慧財產權。使用「服務」不代表您擁有我們的「服務」或透過「服務」提供的內容或資訊。「服務」所用之商標及標誌可能為其個別所有者之商標。「Meet.jobs」、「meet.jobs」、「Meet.Jobs」的商標、服務圖標、圖片及其他所用的標誌皆為 Meet.jobs 的商標或註冊商標。</h5>
    
        <h5>2.9.
            我們將使用您所提供以及我們所擁有的關於會員的資訊和資料來作出對您可能有用的連接、內容和功能推薦。例如，我們會使用關於您的資料和資訊為您推薦工作以及向招募專員推薦您。維護您個人檔案的正確性並適時更新將能幫助我們為您作出更準確且相關的推薦。</h5>
        `,

  user_agreement_title_2: `<span class="li-number">3.</span>求職部份`,
  user_agreement_content_2: `
        <h5>3.1. 註冊為個人會員後，您可以透過 Meet.jobs 搜尋、儲存或直接應徵網站上的工作機會。</h5>

        <h5>3.2. 您可以在 Meet.jobs 上透過個人資訊、履歷的設定，讓徵才企業、招募專員或獵頭顧問搜尋到您的資訊，進而與您聯繫，錄取工作。</h5>
   
        <h5>3.3. 凡透過 Meet.jobs 服務錄取工作，您將會獲得由 Meet.jobs 提供的100美元（或等值當地貨幣）「就職祝賀金」（Hiring Bonus），並負有向 Meet.jobs 告知錄取工作之義務。Meet.jobs 同時歡迎您透過各種方式分享求職和工作的經驗與心得，幫助更多的會員用戶。</h5>
    
        <h5>3.4. 求職、職場工作，都以「誠信」為原則，秉持互相尊重的態度進行。如企業、機構或徵才方以任何利益、條件交換您不要向 Meet.jobs 告知錄取，您可以向 Meet.jobs 舉報。一旦查證屬實，Meet.jobs 會因該企業的不誠信行為，將其列為「不推薦企業」，並在網站上公告。而您將可以仍然獲得由 Meet.jobs 所提供的100美元「檢舉感謝金」。</h5>
   
        <h5>3.5. 承上，如您以不要向 Meet.jobs 告知錄取為條件，要求企業或徵才方提供任何利益交換，一旦查證屬實，Meet.jobs 會因您的不誠信行為，將您列為「不推薦會員」，並向企業用戶公告。由於這將嚴重影響您日後的求職與職涯發展，盼望切以「誠信」為重，切莫違反。</h5>
    
        <h5>3.6. 承上，如您在求職過程中有任何違反一般「誠信」、「尊重」的言行，例如在求職資料上造假、無故且未通知即缺席面試，或對於應徵企業造謠生事等，一旦查證屬實，Meet.jobs 也會將您列為「不推薦會員」，並向企業用戶公告。</h5>
    
        <h5>3.7. 前項所提之100美元「就職祝賀金」，因各國法規、稅率、付款方式、手續費等，各有所不同，故 Meet.jobs 僅支付100美元或等值之當地貨幣，而不負擔發送獎金之成本費用。</h5>
    
        <h5>3.8. 除不可抗力之因素，「就職祝賀金」將在開始就職後的第60個日曆天內，以現金、轉帳等各種形式發放。</h5>
        `,

  user_agreement_title_3: `<span class="li-number">4.</span>推薦部份`,
  user_agreement_content_3: `
        <h5>4.1. 註冊為個人會員後，您可以透過 Meet.jobs 分享、推薦網站上的工作機會。一旦確認「推薦成功」，被推薦的應徵者已於該企業順利就職，則您將可獲得「推薦感謝金」（Referral Reward）。</h5>
    
        <h5>4.2. 「推薦感謝金」之金額為 200 美元，Meet.jobs 系統也會直接標示推薦感謝金的金額。</h5>
    
        <h5>4.3. 支付規則：一旦推薦成功，推薦感謝金的頒發時間為該名應徵者成功任職並後一個月的 20 日，如 20 日遇例假日則延至下一個工作日。</h5>
    
        <h5>4.4. 承上，考慮到推薦人可能為社團、機構、組織、企業或任何形式法人的代表人，推薦人達成「推薦成功」獲得推薦獎金條件時，必須仍持有該社團、機構、組織、企業或法人的代表帳號。如未能持有該帳號，則推薦獎金應屬於該社團、機構、組織、企業或法人所有，而非原本的代表人個人。</h5>
        
        <h5>4.5. 承上，推薦人雖然沒有確保徵才企業支付 Meet.jobs 服務費用的義務，然而如果企業尚未支付費用，考慮到支付成本，Meet.jobs 將不會支付推薦感謝金給推薦人。此一情況通常發生在首次透過 Meet.jobs 徵才的企業或雇主，因文書作業，支付時間較長，懇請您理解，Meet.jobs 必須在收到企業的費用以後才能支付推薦感謝金。</h5>
    
        <h5>4.6. 「推薦成功」定義與功能說明</h5>
        <h6>4.6.1. 推推薦人可透過 Meet.jobs 網站的推薦分享功能，產生自己專屬的「推薦碼」及「推薦連結」。將推薦碼（或推薦連結）分享給應徵者，使其透過 Meet.jobs 應徵工作或登錄履歷而被徵才企業搜尋到，最後由徵才企業在系統確認就職，且任職滿 90 個日曆天，即為「推薦成功」。</h6>
        <h6>4.6.2. 承上，推薦人在取得應徵者同意的情況下，得使用應徵功能於 Meet.jobs 直接代為投遞履歷，應徵工作機會。唯此一行為推薦人必須先取得應徵者對於個人資料之授權。如因未獲授權而衍生相關法律問題及賠償，Meet.jobs 概不負任何責任。</h6>
        <h6>4.6.3. 推薦人必須根據徵才企業於 Meet.jobs 平台上所公開之企業及工作資訊進行分享。推薦人必須確保分享的工作、企業資訊，包括文字、圖片、影音等一切訊息皆屬事實，無誇大、造假、洩漏商業機密之情事。如因不實、誇大、造假、洩漏商業機密等而衍生相關法律問題及賠償，Meet.jobs 概不負任何責任。</h6>
        <h6>4.6.4 前項所提「誇大、造假、洩漏商業機密等」之不適宜的推薦分享內容，係由 Meet.jobs 單方面認定，並裁決之，推薦人不可有任何異議。</h6>
        <h6>4.6.5 承上，如分享內容中含有誇大、造假、洩漏商業機密等資訊，致使應徵期間，甚或試用期內造成勞資雙方糾紛，或任何法律問題，Meet.jobs 概不負一切責任。若因此而造成 Meet.jobs 之損害，推薦人應對 Meet.jobs 負擔損害賠償責任。</h6>
    
        <h5>4.7. 推薦人資訊保密規範</h5>
        <h6>4.7.1. 應徵者在應徵時，將可在 Meet.jobs 系統上看到推薦人帳戶的使用者名稱（username）。除此之外，應徵者將無法從 Meet.jobs 獲得推薦人的任何個人資料及聯絡資訊。</h6>
        <h6>4.7.2. 為保護推薦人隱私，推薦人對於企業之資訊預設將以匿名方式顯示，不會涉及任何使用者相關資訊。如推薦人願意，可選擇將聯絡資訊公開給企業，使企業可與推薦人聯絡，確認應徵人選之情況，但非必要。</h6>
        <h6>4.7.3. 承上，如推薦人願意，可選擇將與企業之間的對話公開給應徵者，使其得以了解企業所詢問之問題及推薦人之答覆，但非必要。</h6>
    
    
        <h5>4.8 推薦人係 Meet.jobs 網站及相關軟體服務之使用者，雙方皆獨立個體，無僱傭、委任、承攬、合夥之關係。如推薦人因濫用、誤用 Meet.jobs 推薦分享機制而致法律糾紛，Meet.jobs 概不負一切責任。若因此而造成 Meet.jobs 之損害，推薦人應對 Meet.jobs 負擔損害賠償責任。</h5>
    
    
        <h5>4.9 承上，Meet.jobs 有權終止使用者帳號的推薦分享權限。</h5>
    
    
        <h5>4.10 所提之「推薦感謝金」，因各國法規、稅率、付款方式、手續費等，各有所不同，故 Meet.jobs 僅支付感謝金的金額，而不負擔發送獎金之成本費用。</h5>
        `,

  user_agreement_title_4: `<span class="li-number">5.</span>免責聲明及責任限制`,
  user_agreement_content_4: `
        <h5>5.1. 在法律允許的範圍內，Meet.jobs（A）否認所有暗示的擔保及說明（如：可銷性擔保、符合特定目的、資料正確性和不侵權）的責任；（B）不保證服務不中斷或沒有錯誤，與（C）根據「現況」與「可用性」提供服務（包括內容及資訊）。</h5>
   
        <h5>5.2. 在法律允許的範圍內，Meet.jobs 對任何對您或他人造成的間接的、偶然的、特別的、重大的或懲罰性的損失，或任何與服務相關造成的資料、機會、名譽、利潤或收入的損失（如：具冒犯性或誹謗性的言論、故障時間或損失、對您資料或內容的使用或變更）概不負責。</h5>
    
        <h5>5.3. 本責任限制為您和 Meet.jobs 協議的依據，並適用於所有責任賠償（如擔保、侵權行為、過失、合約、法律），不論 Meet.jobs 是否已得知發生此類損害的可能性，也不論這些補救措施能否達成必要的目標。</h5>
        `,

  user_agreement_title_5: `<span class="li-number">6.</span>終止服務`,
  user_agreement_content_5: `
        <h5>6.1. 您和 Meet.jobs 隨時皆可終止本協議，惟須以書面或其他可留下記錄之形式通知對方。終止協議時，您將失去讀取或使用「服務」的權利。協議終止後，以下內容仍有效：</h5>
        <h6>A. 我們使用、披露您意見反映的權利；</h6>
        <h6>B. 會員和／或訪客轉分享您透過「服務」分享之內容和資訊的權利，惟複製或轉分享之舉動須在終止之前；</h6>
        <h6>C. 本協議第5、第7、和第8條；</h6>
        <h6>D. 所有在協議終止前所積欠對方的金額，於終止後仍然有效。</h6>
        `,

  user_agreement_title_6: `<span class="li-number">7.</span>準據法及爭議解決`,
  user_agreement_content_6: `
        <h5>7.1. 您和 Meet.jobs 同意除與法規相左之處以外，如本協議及／或「服務」引發任何爭議，皆以中華民國自由地區相關法規為主，並以臺灣臺北地方法院為第一審管轄法院。</h5>

        <h5>7.2. 如本協議有任何無法履行的部分或條款，雙方均同意應以保留原始目的且又能履行的方式修改本協議條款。如果無法修改，雙方同意移除無法履行的部分，並履行協議中的其餘條款。</h5>
 
        <h5>7.3. 在法律允許的範圍內，本協議的英文版本及繁體中文版本均具有法律約束效力，其他譯本皆為方便閱讀使用。如不同版本間條文、用詞有所歧異，則以繁體中文版本為主。本協議為雙方最終且完整之合意，有取代磋商過程中所提出之任何口頭或書面上的承諾、理解或其他意思表示之效力，且本協議之條款標題，僅為參考之用，雙方同意標題之內容不能作為解釋本協議之依據。</h5>

        <h5>7.4. 如果 Meet.jobs 對違約行為未採取法律行動，並不代表 Meet.jobs 放棄履行本協議的權利。未經 Meet.jobs 的同意，您不得指定或轉讓此協議（或您的會員身分或服務使用）。然而，您同意 Meet.jobs 得將此協議轉讓給其相關機構或購買 Meet.jobs 的機構。</h5>
 
        <h5>7.5. 您同意第10條所述的地址為提供 Meet.jobs 法律通知唯一的方式。</h5>
        `,

  user_agreement_title_7: `<span class="li-number">8.</span>Meet.jobs 的使用守則`,
  user_agreement_content_7: `  
        <h5>8.1. 您同意將會遵守以下原則使用 Meet.jobs：</h5>
        <h6>A. 遵守所有適用法規，包括，但不僅限於隱私權法、智慧財產權法、反垃圾訊息法、出口管理條例、稅務法及監管要求；</h6>
        <h6>B. 提供正確資訊並實時更新；</h6>
        <h6>C. 個人簡歷檔案中使用真實姓名；並且</h6>
        <h6>D. 以專業的方式及以善良管理人之注意義務使用「服務」。</h6>
    
    
        <h5>8.2. 您同意您在使用 Meet.jobs 時將不會有以下行為：</h5>
        <h6>A. 在 Meet.jobs 上建立虛假身份、謊報身份、建立非您本人（非真人）之個人檔案，或使用或試圖使用他人帳戶；</h6>
        <h6>B.
            開發、支援或使用軟體、裝置、指令碼、自動程式或其他方式或程序（包括網路爬蟲、瀏覽器外掛程式和附加元件，或任何其他技術）來抓取「服務」或從「服務」複製檔案和其他資料；</h6>
        <h6>C. 覆寫任何安全功能，或迴避或規避任何「服務」的存取控制或使用限制；</h6>
        <h6>D. 未經 Meet.jobs 同意而複製、使用、揭露或散佈任何從「服務」取得之資料（不論是以直接或經由第三方（如搜尋引擎）的間接方式）；</h6>
        <h6>E. 揭露您未經同意即取得的資料（如他人（包括您的雇主）的機密資料）；</h6>
        <h6>F.
            違反他人智慧財產權，包括：著作權、專利、商標、商業機密或其他財產權；例如：請勿在未經他人許可的情況下複製或散佈（透過可用的分享功能除外）他人工作資訊、文章或其他內容；</h6>
        <h6>G. 違反 Meet.jobs 的智慧財產權或其他權利，包括但不限於 (i)
            未獲授權複製、修改或散佈我們的文章、圖片、影音與其他資料，作為商用，或 (ii)
            複製或散佈我們的技術，依開放資源授權發佈的技術則不在此限；(iii)
            未獲授權在任何商業名稱、電子郵件地址或網址使用「Meet.jobs」字眼或商標；</h6>
        <h6>H. 刊登含有軟體病毒、電腦蠕蟲或其他有害程式碼的內容；</h6>
        <h6>I. 反向工程、反編譯、解譯、破解或以其他方式試圖獲取「服務」或非開放資源之相關技術的原始程式碼；</h6>
        <h6>J. 未經 Meet.jobs 同意，暗示或明示您為 Meet.jobs 附屬個體或已取得或將取得 Meet.jobs 背書；</h6>
        <h6>K. 未經 Meet.jobs
            同意即出租、抵押、交易、販售／轉售「服務」或相關資料或其存取權，或以其他方式利用「服務」或相關資料或其存取權賺取收益；</h6>
        <h6>L. 未經 Meet.jobs 同意，除在我們的「服務」上宣傳您的檔案或社團外，基於任何其他理由利用深層連結連結到我們的「服務」；</h6>
        <h6>M. 使用自動程式或其他自動方法使用「服務」、新增或下載聯絡人、傳送或重新導向訊息；</h6>
        <h6>N. 為任何競爭目的，監視「服務」的可用性、表現或功能；</h6>
        <h6>O. 進行「框架」、「鏡像」或其他模擬「服務」外觀或功能之行為；</h6>
        <h6>P. 覆蓋或以其他方式修改「服務」或其外觀（例如透過插入元素到服務或移除、遮蓋或模糊服務所含之廣告）；</h6>
        <h6>Q. 干擾「服務」的運作或製造不合理的負載（如：垃圾郵件、阻斷服務攻擊、病毒、遊戲演算）。</h6>
        `,

  user_agreement_title_8: `<span class="li-number">9.</span>內容相關的投訴`,
  user_agreement_content_8: `
        <h5>9.1. 我們尊重他人之智慧財產權。因此，我們要求會員提供正確、未侵犯第三方智慧財產權及其他權利之資訊。</h5>
    
        <h5>9.2. 您可以透過第10條「聯絡我們」與 Meet.jobs 聯繫，投訴上述違反智慧財產權及一切違反本協議之使用行為。</h5> 
        `,

  user_agreement_title_9: `<span class="li-number">10.</span>聯絡我們`,
  user_agreement_content_9:
    `<h6>線上聯絡：回覆留言機器人、留言板，或來信 service@meet.jobs。</h6>` +
    `<h6>郵寄聯絡：8 Claymore Hill #03-01 Singapore 229572 或 2nd Floor, No. 2, Lane 59, Yitong Street, Zhongshan District, Taipei 10486, Taiwan</h6>`,

  // employer_agreement
  employer_agreement_head_content:
    `<p>本合約係由</p>` +
    `<p>` +
    `名稱：__________ （以下簡稱甲方、客戶、僱主、買方、徵才企業等）<br>` +
    `電話：__________<br>` +
    `Email：__________` +
    `</p>` +
    `<p>授權 一起工作科技有限公司（以下簡稱 Meet.jobs、乙方、服務提供者、賣方等） 透過所屬平台與服務委託協助聘用人才之合約。</p>` +
    `<br>`,

  employer_agreement_section_title_0: "引言及說明",
  employer_agreement_part_0: `
    <h5>1. 本合約係 一起工作科技有限公司 與所屬網站、平台，及網路服務提供人才招募解決方案服務時所使用。</h5>
    <h5>2. 本合約所有名詞解釋、定義等皆與 Meet.jobs 的「個人用戶協議」、「隱私權政 策」及一切相關文件相同。</h5>
    <h5>3. 客戶必須先有負責招募的聯絡人註冊成為 Meet.jobs 服務的「會員」，才能進一步註冊企業帳號。</h5>
    <h5>4. 除 客戶 與 Meet.jobs 以書面另外所達成的協議以外，一切資料認定皆以 Meet.jobs 網站、系統與延伸服務上的紀錄為準。</h5>
    `,
  employer_agreement_section_title_1: "客戶的義務",
  employer_agreement_part_1: `
  <h5>5. 客戶張貼職缺無須支付張貼費用。</h5>
  <h5>6. 客戶必須於 Meet.jobs 之網站、平台與服務內容中採用服務方案。同一個企業帳號只能一次採用一種方案。特定時間所選用的方案以 Meet.jobs 服務系統上所顯示的紀錄為準。</h5>
  <h5>7. 客戶可將要招募的工作資訊張貼於 Meet.jobs 網站及所有延伸之網路平台，並保證所提供資訊正確無誤。若客戶提供之資訊有誤而造成 Meet.jobs 之損害，客戶應對 Meet.jobs 負擔損害賠償責任；若因此而造成求職者之損害，客戶應自行負責，概與 Meet.jobs 無涉。</h5>
  <h5>8. 「資訊透明」是 Meet.jobs 最重要的核心價值。企業客戶透過 Meet.jobs 服務發佈職缺時，最底限度必須公開該職位的薪資區間或薪資下限的資訊。</h5>

<h6>說明：「信任」為 Meet.jobs 所相信的核心價值，而「資訊透明」能夠給予人才對僱主充份的信任。當 僱主 所提供的資訊愈加透明、完整、準確，便能透過 Meet.jobs 更有效率地錄取合適的人選。 反之，如因無法公開資訊而需採用獵才方案，則獵才服務之收費將以人才的專業性、資深程度等收取不同比例的服務費用。
</h6>
<h5>9. 客戶得以 Meet.jobs 之專業顧問建議提供招募人才用的必要相關資訊，包括但不限於影片、圖片、錄音、文字訊息，以及現職員工、招聘主管或企業創辦人專訪及/或簡歷等素材。</h5>
<h5>10. 前項所提之素材不包含客戶因商務考量而必須保密的資訊和材料。包括客戶的企業名稱在內，Meet.jobs須獲得客戶以任何可留下紀錄之方式許可，才可公佈該項資訊。如未獲許可擅自公佈，則客戶有權立即解除合約。如因此造成客戶至或任何損失，Meet.jobs當負一切賠償與法律責任。</h5>
<h5>11. 客戶須於 Meet.jobs 網站、平台及網路服務上回覆求職者的應徵訊息，包括拒絕應徵（即感謝信）。特別強調如該筆應徵未被拒絕，狀態仍顯示為「應徵中」，則無論經過多久，也無論是否錄取同一職位，一旦客戶錄取該人選，則視為透過 Meet.jobs 錄取，Meet.jobs仍保有收費之權利。</h5>
<h5>12. 客戶須於 3 個工作日內回覆求職者或推薦人回報錄取之訊息，確認是否錄取該人選。如確認錄取與人選就職非同一日期，請以可留下紀錄之方式告知 Meet.jobs 該人選的就職日期即可。</h5>
<h5>13. 客戶於確認錄取時，須同步提供該人選之薪資資訊，包括但不限於聘書（Offer Letter）、僱傭合約、薪水單等，作為 Meet.jobs 收費之依據。</h5>
<h5>14. 客戶須在收到 Meet.jobs 的 invoice 後的 30 個日曆天內完成付款。</h5>
    `,

  employer_agreement_section_title_2: "Meet.jobs 的義務",
  employer_agreement_part_2: `
  <h5>15. 客戶在 Meet.jobs 系統註冊企業帳號以後，Meet.jobs將有專業顧問於1個工作天內與客戶聯繫，提供協助。</h5>
  <h5>16. Meet.jobs將依本身專業判斷，視客戶招募需求之不同，提供目標行銷、文字或影音專訪、線上或線下活動推廣、社群推薦等方式，協助客戶徵才。</h5>
  <h5>17. Meet.jobs將於社群網路分享並推廣客戶之企業和職缺資訊，包括且不限於在 Facebook、LinkedIn、Youtube、Instagram、WeChat、TikTok等，以及客戶有特殊需求之網站或社群。</h5>
  <h5>18. 承上，如Meet.jobs所提供的招募方案有任何衍生的額外費用，則在取得客戶同意，並以任何可留下紀錄的方式佐證之後，雙方議定由哪方負擔該費用，並另行約定付款、憑證、墊款等細節事項。</h5>
  <h5>19. Meet.jobs須追蹤每一筆應徵紀錄之狀態，直至人選錄取、取消應徵或被客戶拒絕為止。如客戶有未讀及未處理之應徵人選，Meet.jobs必須以可留下紀錄之方式，提醒客戶儘快處理之。</h5>
  <h5>20. 確認人選就職後，Meet.jobs必須依客戶所提供的薪資資訊開立invoice，並有義務建議客戶最為方便且節省成本的付款方式。</h5>
  <h5>21. 一旦客戶完成付款，Meet.jobs必須於3個工作日內確認並告知客戶。</h5>
`,

  employer_agreement_section_title_3: "錄取與就職的認定",
  employer_agreement_part_3: `
  <h5>23. 承上，客戶在確認錄取人選時，須同時告知該人選就職日期，以當日人選就職為準。如期間就職日期有所變更，以雙方可留下紀錄之方式同意之。</h5>
<h5>24. 前項求職者與推薦人回報就職之情況，如確認為「未就職」，客戶須在3個工作日內於Meet.jobs系統回覆，或以任何可留下紀錄的方式告知Meet.jobs。如未告知，則Meet.jobs仍保有收費的權利。</h5>
<h5>25. 如該人選在並未就職客戶的工作職位，則客戶須在Meet.jobs系統「拒絕」其應徵。如有未「拒絕」的求職者，皆視為仍然處於應徵狀態。則無論時間經過多久，一旦就職，Meet.jobs仍有收費的權利。</h5>
<h5>26. 如該人選在Meet.jobs系統上是應徵狀態，則一旦就職客戶的工作職位，無論最後就任的是否為最初所應徵的職位，Meet.jobs都保有收費的權利。亦即如果客戶無意錄用該人選，請務必在Meet.jobs上「拒絕」該人選，切勿有「無聲卡」等損害客戶商譽與信任的行為。</h5>
<h5>27. 凡是透過Meet.jobs系統應徵的求職者，以及Meet.jobs以任何可留下紀錄的方式所推薦的人選，一旦就職，Meet.jobs即有權利向客戶收費。特別說明，此與僱主是否透過其他管道得知此位人選無關，Meet.jobs仍有向客戶收費之權利。</h5>
<h5>28. 如該人選已透過其他管道應徵，且仍在「應徵中」，則該人選再透過 Meet.jobs 應徵，亦不計為 Meet.jobs 的推薦人選。注意此處「應徵中」的定義為「客戶已透過其他管道聯繫該人選面試，人選已回覆接受面試且有聯繫紀錄，而該次應徵尚未『拒絕』或『取消應徵』，最後一次的『客戶與人選聯繫』與『人選透過 Meet.jobs 應徵的日期』相距 10 個工作日以內」。在此情況下，將視為該人選前一次透過其他管道的應徵尚未結束，Meet.jobs 將不收取服務費用。</h5>
<h5>29. 如客戶認定已就職的求職者並非透過 Meet.jobs 的服務所錄用，則須取得該名求職者以任何可留下紀錄的方式，確認並非透過 Meet.jobs 就職之聲明。在此情況下，則 Meet.jobs 將不收取服務費用。</h5>
`,

  employer_agreement_section_title_4: "就職保障期",
  employer_agreement_part_4: `
  <h5>30. 凡透過 Meet.jobs 錄取之人選，Meet.jobs 保障該名錄取人選任滿 30 個日曆天。除 客戶 與 Meet.jobs 另有議定且留下紀錄同意者，不在此限。</h5>
  <h5>31. 如在任職的前 30 個日曆天內，該人選自行離職，則無論客戶選擇何種服務方案，Meet.jobs 都將退還 50% 的服務費用。</h5>
  <h5>32. 承上，如 客戶 對於該職位仍有招募人選之需求，則 Meet.jobs 亦將提供相應服務，協助招募替代人選。唯此一情況下，客戶 仍須支付該人選之招募服務費用。</h5>
  <h5>33. 特別強調，如該人選在前 30 個日曆天內因工作專業表現等問題，無法過試用期而被 客戶 解任，則不在 Meet.jobs 的保障範圍之內。</h5>
  <h5>34. 上述 30 – 33 條內容為「社群方案」所提供之標準服務內容。如 客戶 另有特殊服務需求（例如希望延長試用期等），請選擇「獵才方案」提供客製化招募服務。</h5>
`,

  employer_agreement_section_title_5: "實質薪資（年薪）的認定",
  employer_agreement_part_5: `
  <h5>35. 一旦確認錄取，客戶 必須提供薪資資訊，例如僱傭合約、錄取信或薪資單作為佐證，以當作 Meet.jobs 收費的依據。如該資訊由求職者或推薦人提供，也必須獲得 客戶 以任何可留下紀錄形式，回覆確認。</h5>
  <h5>36. Meet.jobs 對於薪資資訊負有保密責任，只能做為收取服務費用的佐證，而不可以用於其他用途。如因資訊外洩造成 客戶 損失，Meet.jobs 必須負賠償之責任。</h5>
  <h5>37. 客戶 在透過 Meet.jobs 系統發佈職缺時，可選擇年薪、月薪、日薪或時薪的計算方式。而 Meet.jobs 根據 客戶 所選擇的服務方案不同，收取服務費用。 </h5>
  <h5>38. 如該職位的薪資計算方式為年薪，則以正式聘書（Offer Letter）或僱傭合約上所記載之正職錄用金額為實質薪資。</h5>
  <h5>39. 如該職位的薪資計算方式為月薪，則以正式聘書（Offer Letter）或僱傭合約上所記載之正職錄用金額，乘上該職位所保障的月份數字（例如 14 個月。如無規範則為 12 個月）為實質薪資。如保障月份未達 12 個月，則以 12 個月計算之。</h5>
  <h5>40. 如該職位的薪資計算方式為日薪，則以正式聘書（Offer Letter）或僱傭合約上所記載之正職錄用金額，乘以 20（每月工作日數），再乘以 客戶 所保障的月份數字（例如 14 個月，如無規範則為 12 個月）為實質薪資。如保障月份未達 12 個月，則以 12 個月計算之。</h5>
  <h5>41. 如該職位的薪資計算方式為時薪，則以正式聘書（Offer Letter）或僱傭合約上所記載之正職錄用金額，乘以 160（每月工作時數），再乘以 客戶 所保障的月份數字（例如 14 個月，如無規範則為 12 個月）為實質薪資。如保障月份未達 12 個月，則以 12 個月計算之。</h5>
  <h5>42. 客戶 在 Meet.jobs 系統發佈職缺時，可選擇全職、兼職、實習和志願者等 4 種不同的工作型態。凡選擇「全職」工作的職缺，無論是否永久合約（Permanent）或契約聘用（Contract），實質薪資都以上述的方式計算之。</h5>
  <h5>43. 如該職位為「兼職」或「實習」職位，則為「全職」職位實質薪資的 60%。然而當 客戶 先以「兼職」或「實習」招募「全職」人員時，其實質薪資與服務費用仍以「全職」工作計算之。</h5>
<h5>44. 如遇 客戶 在試用期間的薪資與正式通過試用時不同，則以正式聘用時的薪資作 為計算實質薪資的基準。</h5>
`,

  employer_agreement_section_title_6: "費用支付",
  employer_agreement_part_6: `
  <h5>45. 一旦 客戶 在 Meet.jobs 系統上確認就職，並告知薪資資訊，Meet.jobs 將提供帳單（invoice）及付款所需文件（如發票）。收到帳單以後，客戶 須在 30 個日曆天內 完成付款。</h5>
  <h5>46. Meet.jobs 現可接受「信用卡」、「轉帳匯款」和「虛擬貨幣」（目前僅接受 USDT 及 USDC）三種付費方式，客戶 可從中選擇， 並顯示於 Meet.jobs 系統上。</h5>
  <h5>47. 如 客戶 選擇信用卡，則只需要在 Meet.jobs 系統上註冊信用卡資料即可。 Meet.jobs 會依就職確認開立帳單，並於每個月的結帳日（每月 5 日）收費。信用卡為 Meet.jobs 建議最佳的付費方式。</h5>
  <h5>48. 如 客戶 選擇轉帳匯款，則 Meet.jobs 將提供銀行帳戶，供 客戶 進行匯款。</h5>
  <h5>49. Meet.jobs 有責任告知 客戶 最為方便且節省成本的付款方式。然 Meet.jobs 的收費為「全額的服務費用」，如有其他衍生費用，例如手續費、匯費、各項相關租稅等 ，皆由 客戶 自行負擔。</h5>  
`,

  employer_agreement_section_title_7: "*社群方案",
  employer_agreement_part_7: `
  <h5>50. 社群方案（Social Plan）的收費為招募職位實質薪資年薪的 16%，以確認錄取人選就職為依據，向 客戶 收取服務費用。</h5>
  <h5>51. 採用社群方案的客戶，將獲得 Meet.jobs 所有用戶推薦合適人選。一旦推薦人選成功錄取，Meet.jobs 將提供就職祝賀金、推薦感謝金等，並邀請人選分享錄取心得。</h5>
  <h5>52. 採用社群方案的客戶，Meet.jobs 將以本身專業判斷，於線上、線下社群，針對 客戶 的企業及職缺資訊，進行目標行銷推廣。</h5>
  <h5>53. 採用社群方案的客戶，可參與 Meet.jobs 所舉辦線上、線下整合行銷招募活動。如該活動有其他衍生費用，則直接以可留下紀錄之方式由雙方議定之。</h5>
  <h5>54. 社群方案（Social Plan）合約為標準服務合約，不接受修改。惟合約中未明確規範之細節，可由雙方以合約附註方式說明之。但附註之內容，不可違背主合約之內容與精神。如遇相抵觸者，則附註之內容自動失效。</h5>
  <h5>55. 如客戶要從其他方案切換至社群方案，則需另行以書面或可留下紀錄之方式，告知 Meet.jobs，由系統管理者協助完成切換。</h5>
`,

  employer_agreement_section_title_8: "*獵才方案",
  employer_agreement_part_8: `
  <h5>56. 獵才方案（Hunter Plan）的收費為招募職位實質薪資年薪的 20% 以上（含），以確認錄取人選就職為依據，向客戶收取服務費用。</h5>
  <h5>57. 採用獵才方案的客戶，可享有社群方案所有的服務內容。此外，Meet.jobs本身所配置的獵才顧問，將會主動協助推薦、篩選人選。</h5>
  <h5>58. 採用獵才方案的客戶，Meet.jobs將有專業獵才顧問，針對招募職位的工作內容、薪資範圍與招募方式（例如活動等）等提出建議。</h5>
  <h5>59. 採用獵才方案的客戶，Meet.jobs將可協助進行語言、專業技術、適性測試等人才篩選測驗，惟須與客戶詳細確認測驗之方式與標準。</h5>
  <h5>60. 其他客製化服務，例如挖角人選、薪資談判、保障面試人數等，依服務內容需求不同，Meet.jobs將收取 20% 以上的服務費用。費用與收費方式由客戶和Meet.jobs雙方以可留下紀錄之方式，共同議定之。如有需求，可另擬附約，以茲紀錄。</h5>
  <h5>61. 如 客戶 在已選擇其他方案的情況下，要升級到 獵才方案，只需在 Meet.jobs 系統上點選升級方案，或者以可留下紀錄的形式通知 Meet.jobs 即可。確認 客戶 升級方案需求的以後，Meet.jobs 將有專業顧問與 客戶 聯繫，提供協助。</h5>
`,

  employer_agreement_section_title_9: "道德宣告",
  employer_agreement_part_9: `
  <h5>62. 人才招募的成敗往往決定一家企業的成敗。而企業能夠成功招募到優秀人才，必須基於企業、人才和服務提供者間的互相信任。因此，如有求職者或其推薦人以「不向 Meet.jobs 回報就任工作」為條件，要求客戶提供任何額外的報酬、獎金或其他形式的利益回饋，Meet.jobs 強烈建議客戶不要錄取該求職者。如向 Meet.jobs 舉報該求職者或推薦人，Meet.jobs 也將負查證之責任；一經查證屬實，即將該求職者或推薦人列為「排除名單」，告知 Meet.jobs 所有的企業客戶。</h5>
  <h5>63. 如客戶以「不向 Meet.jobs 回報就任工作」為條件，主動提供求職者或推薦人任何形式的利益交換，則求職者或推薦人亦有權利舉報該企業。如查證屬實，考量此一行為有違企業徵才時的誠信原則，Meet.jobs 將在網站上公開客戶企業名稱，並列入「不推薦企業」名單。</h5>
  <h5>64. 如客戶無法在收到帳單後的 30 個日曆天內完成付費，必須以可留下紀錄的形式取得 Meet.jobs 的同意，雙方並約定付款時間。如客戶有刻意拖延，甚至在未取得 Meet.jobs 同意下未準時付費的情況，則 Meet.jobs 將會在網站上公開企業名稱，並列入「不推薦企業」名單。</h5>
  <h5>65. 如 客戶 提供的職缺資訊與最後錄取之條件不符，尤其當錄取薪資低於招聘職缺薪資區間的最低薪資時，企業必須在 72 小時內主動以書面或可留下記錄的方式提出說明，並取得求職者和推薦人同意的聲明作為佐證。如未獲得求職者和推薦人的同意，且該情況發生超過三次，Meet.jobs 將在網站上公開 客戶 名稱，並列入「不推薦企業」名單。</h5>
`,

  employer_agreement_section_title_10: "個資保密",
  employer_agreement_part_10: `
    <h5>66. 由 Meet.jobs 應徵工作或推薦的求職者，其履歷、作品集、技術測試結果等所有資 料，僅供 客戶 評估該求職者是否錄取使用，客戶 不可用於其他用途。若因此而 造成求職者之損害，由 客戶 對求職者負擔損害賠償責任，概與 Meet.jobs 無涉。 </h5>
`,

  employer_agreement_section_title_11: "簽核方式",
  employer_agreement_part_11: `
  <h5>67. Meet.jobs 提供以下方式供 客戶 簽核本合約。客戶 只需擇一採用即可。</h5>
  <h6>A. 直接在 Meet.jobs 系統上勾選同意合約。此為 Meet.jobs 所建議具備法 律效力且最有效率的方式。</h6>
  <h6>B. 採用 Meet.jobs 所認同的第三方電子簽核系統，簽核後上傳到 Meet.jobs 系統。</h6>
  <h6>C. 下載 Meet.jobs 已簽核完成的合約，由 客戶 列印，簽核完成後，掃描上傳到 Meet.jobs 系統。</h6>
  <h6>D. 在 Meet.jobs 系統上點選寄送紙本合約，並填入收件地址，或聯絡 Meet.jobs 要求提供紙本合約。Meet.jobs 收到要求後將寄送已簽核完成的紙本合約共一式兩份，由 客戶 簽核完成後，掃描上傳到Meet.jobs 系統，並將一份紙本寄回到 Meet.jobs 備存。</h6>
`,

  employer_agreement_section_title_12: "合約修改與終止",
  employer_agreement_part_12: `
  <h5>68. 無論選擇任何方案，只要未張貼職缺或職缺關閉，則除未完成之義務以外，客戶無新的費用產生。故絕大部份的情況下，本合約沒有終止的必要。</h5>
  <h5>69. 如有需要，Meet.jobs 與 客戶 雙方皆具有單方面終止合約之權利。如一方欲終止合約，必須於終止日前一個月內告知對方。並於履行本合約全部義務後，合約方得終止。屆時 Meet.jobs 也將移除 客戶 在系統上所有資訊。惟第三方平台如 Google 等係由 SEO 排名效果，所搜尋到之資訊，非 Meet.jobs 所能控制修改。</h5>
  <h5>70. 前項全部義務包括一切錄取與就職確認、款項支付、資訊提供以及資訊關閉等事項。</h5>
  <h5>71. 若客戶有違約或違反法令之情事，經 Meet.jobs 認定有必要者，Meet.jobs 得自行移除客戶所張貼之職缺，並得立即終止合約。</h5>
  <h5>72. 如遇不可抗力之因素，如其中有一方受災難、政治事件、宗教、種族等衝突以致 無法履行合約，另一方對他方不負任何法律責任。</h5>
  <h5>73. 除獵才方案可能有客製化合約以外，如 Meet.jobs 針對本合約有所修改，將透過系統以及可留下紀錄的方式通知 客戶。如 客戶 不同意修改的內容，一般情況下 ，只需不再使用 Meet.jobs 服務即可。</h5>
  <h5>74. 對於客戶所要張貼之雇主資訊與職缺，Meet.jobs 保留接受或拒絕張貼之權利。</h5>
`,

  employer_agreement_section_title_13: "準據法及爭議解決",
  employer_agreement_part_13: `
  <h5>75. 如本合約及／或「服務」引發任何爭議，皆以中華民國自由地區相關法規及法務單位為主，並以臺灣臺北地方法院為第一審管轄法院。</h5>
  <h5>76. 如本合約有任何無法履行的部分或條款，雙方均同意應以保留原始目的且又能履行的方式修改本合約條款。如果無法修改，雙方同意移除無法履行的部分，並履行合約中的其餘條款。</h5>
  <h5>77. 在法律允許的範圍內，本合約的英文版本及繁體中文版本均具有法律約束效力，其他譯本皆為方便閱讀使用。如不同版本間條文、用詞有所歧異，則以繁體中文版本為主。本合約為雙方最終且完整之合意，有取代磋商過程中所提出之任何口頭或書面上的承諾、理解或其他意思表示之效力，且本合約之條款標題，僅為參考之用，雙方同意標題之內容不能作為解釋本合約之依據。</h5>
  <h5>78. 如果 Meet.jobs 對違約行為未採取法律行動，並不代表 Meet.jobs 放棄履行本合約的權利。未經 Meet.jobs 的同意，客戶不得指定或轉讓此合約（或會員身分或服務使用）。然而，客戶您同意 Meet.jobs 得將此合約指定給其相關機構或收購 Meet.jobs 的機構。</h5>
  <h5>79. 您同意以本合約所述的聯絡方式為提供 Meet.jobs 法律通知唯一的方式。</h5>
`,

  employer_agreement_section_title_14: "舊合約適用",
  employer_agreement_part_14: `
<h5>80. 如 客戶 在 2023 年 05 月 01 日 以後仍繼續採用 Meet.jobs，則視為同意新合約。</h5>
`,

  employer_agreement_section_title_15: "聯絡我們",
  employer_agreement_part_15: `
<h5>81. 線上聯絡：回覆留言機器人、留言板，或來信 service@meet.jobs。</h5>
<h5>82. 電話聯絡：來電 +886 2 2577 5800。</h5>
<h5>83.郵寄聯絡：台灣台北市松山區南京東路四段 106 號 5 樓</h5>
`,

  employer_agreement_title_0:
    "1. 本合約係 一起工作科技有限公司 與所屬網站、平台，及網路服務提供人才招募解決方案服務時所使用。",

  employer_agreement_title_1:
    "2. 本合約所有名詞解釋、定義等皆與 Meet.jobs 的「個人用戶協議」、「隱私權政策」及一切相關文件相同。",

  employer_agreement_title_2:
    "3. 客戶 必須先有負責招募的聯絡人註冊成為 Meet.jobs 服務的「會員」，才能進一步註冊企業帳號。",

  employer_agreement_title_3:
    "4. 除 客戶 與 Meet.jobs 以書面另外所達成的協議以外，一切資料認定皆以 Meet.jobs 網站、系統與延伸服務上的紀錄為準。",

  employer_agreement_section_title_0_0: "- 客戶的義務",

  employer_agreement_title_4: "5. 客戶 張貼工作機會無須支付張貼費用。",

  employer_agreement_title_5:
    "6. 客戶 必須於 Meet.jobs 之網站、平台與服務內容中採用服務方案。同一個 企業帳號 只能一次採用一種方案。特定時間所選用的方案以 Meet.jobs 服務系統上所顯示的紀錄為準。",

  employer_agreement_title_6:
    "7. 客戶 必須將要招募的工作資訊張貼於 Meet.jobs 網站及所有延伸之網路平台，並保證所提供資訊正確無誤。若 客戶 提供之資訊有誤而造成 Meet.jobs 之損害，客戶 應對 Meet.jobs 負擔損害賠償責任；若因此而造成求職者之損害，客戶 應自行負責，概與 Meet.jobs 無涉。",

  employer_agreement_title_7:
    "8. 「資訊透明」是 Meet.jobs 最重要的核心價值。企業客戶 透過 Meet.jobs 服務發佈工作機會時，最底限度必須公開該職位的薪資區間或薪資下限的資訊。",
  employer_agreement_content_7: `<h6>說明：「信任」為 Meet.jobs 所相信的核心價值，而「資訊透明」能夠給予人才對僱主充份的信任。當 僱主 所提供的資訊愈加透明、完整、準確，便能透過 Meet.jobs 更有效率地錄取合適的人選。</h6>`,

  // employer_agreement_section_title_1: "第一部份：錄取方案（Hire Plan）",

  employer_agreement_title_8:
    "9. 錄取方案為 Meet.jobs 所提供的標準化服務，如需以下內容以外的服務項目，例如客製化合約、延長保障期限、修改徵才廣告等等，請 客戶 選用「加值方案（Hire+ Plan）」。",

  employer_agreement_title_9: "10. 錄取方案的規範",
  employer_agreement_content_9:
    `<h6>10.1. Meet.jobs 的錄取方案服務以「成果」收費。客戶 在成功錄取人選以後，支付 Meet.jobs 費用，額度為該職位第一年年薪的 16%。</h6>` +
    `<h6>10.2. 客戶 須在該名錄取人選開始工作後的30個日曆天內支付該筆費用。</h6>` +
    `<h6>10.3. Meet.jobs 保障該名錄取人選最少任滿90個日曆天。</h6>` +
    `<h6>10.4. 如前 90 個日曆天內，該人選自行離職，則自離職日起算的 90 個日曆天內，Meet.jobs 須再補充一名替代人選。</h6>` +
    `<h6>10.5. 承上，該名替代人選自任職起同樣有 90 個日曆天的保障期。與原本人選的薪資如有差異，則服務費用亦多退少補。</h6>` +
    `<h6>10.6. 承上，如 Meet.jobs 無法在期限內補充替代人選，或 客戶 已自其他管道錄取合適人選，或已無該人選需求（但 客戶 需提出無該人選需求之合理說明），則 Meet.jobs 須全額退費。</h6>` +
    `<h6>10.7. 特別強調，如該人選在前 90 個日曆天內因工作專業表現等問題，無法過試用期而被 客戶 解任，則不在 Meet.jobs 的保障範圍之內。</h6>` +
    `<h6>10.8. 使用本方案的 客戶 張貼工作機會無須支付張貼費用。</h6>`,

  employer_agreement_title_10: "11. 錄取方案的內容",
  employer_agreement_content_10:
    `<h6>11.1. 客戶 在 Meet.jobs 系統上選擇錄取方案服務以後，Meet.jobs 將有專業顧問與 客戶 聯繫，提供協助。</h6>` +
    `<h6>11.2. 客戶 必須將要招募的工作資訊張貼於 Meet.jobs 網站及所有延伸之網路平台，並保證所提供資訊正確無誤。若 客戶 提供之資訊有誤而造成 Meet.jobs 之損害，客戶 應對 Meet.jobs 負擔損害賠償責任；若因此而造成求職者之損害，客戶 應自行負責，概與 Meet.jobs 無涉。</h6>` +
    `<h6>11.3. Meet.jobs 將依本身專業判斷，視 客戶 招募需求之不同，提供目標行銷、文字或影音專訪、線上或線下活動推廣、社群推薦等方式，協助 客戶 徵才。</h6>` +
    `<h6>11.4. Meet.jobs 將於社群網路分享並推廣 客戶 之企業和工作機會資訊，包括且不限於在 Facebook、Google+、LinkedIn、Youtube、Instagram、WeChat 等，以及 客戶 有特殊需求之網站或社群。</h6>` +
    `<h6>11.5. 客戶 得以 Meet.jobs 之專業顧問建議提供招聘用的必要相關資訊，包括但不限於影片、圖片、錄音、文字訊息，以及現職員工、招聘主管或企業創辦人專訪及/或簡歷等素材。</h6>` +
    `<h6>11.6. 前項所提之素材不包含 客戶 因商務考量而必須保密的資訊和材料。包括 客戶 的企業名稱在內，Meet.jobs 須獲得僱主以任何可留下紀錄之方式許可，才可公佈該項資訊。如未獲許可擅自公佈，則 客戶 有權立即解除合約。如因此造成 客戶 至或任何損失，Meet.jobs 當負一切賠償與法律責任。</h6>` +
    `<h6>11.7. 如 Meet.jobs 所提供的招募方案有任何衍生的額外費用，則在取得 客戶 同意，並以任何可留下紀錄的方式佐證之後，雙方議定由哪方負擔該費用，並另行約定付款、憑證、墊款等細節事項。</h6>`,

  employer_agreement_title_11: "12. 就職的認定",
  employer_agreement_content_11:
    `<h6>12.1. 在 Meet.jobs 服務上確認錄取有以下三種方式：</h6>` +
    `<h6>A. 客戶 確認：客戶 可直接在 Meet.jobs 系統上確認就職，或以任何可留下紀錄的方式告知 Meet.jobs。</h6>` +
    `<h6>B. 求職者回報後 客戶 確認：一旦錄取工作，求職者將可在 Meet.jobs 系統上回報就職，Meet.jobs 系統將自動向 客戶 確認是否錄用該求職者。客戶 可在 Meet.jobs 系統上確認就職，或以任何可留下紀錄的方式告知。</h6>` +
    `<h6>C. 推薦人回報後 客戶 確認：如該人選有推薦人，則推薦人將可在 Meet.jobs 系統上回報就職，Meet.jobs 系統將自動向 客戶 確認是否錄用該人選。客戶 可在 Meet.jobs 系統上確認就職，或以任何可留下紀錄的方式告知。</h6>` +
    `<h6>12.2. 前項 求職者 與 推薦人 回報就職之情況，如確認為「未就職」，客戶 須在 72 小時內於 Meet.jobs 系統回覆，或以任何可留下紀錄的方式告知 Meet.jobs。如未告知，則 Meet.jobs 仍保有收費的權利。</h6>` +
    `<h6>12.3. 如該人選在並未就職 客戶 的工作職位，則 客戶 須在 Meet.jobs 系統「拒絕」其應徵。如有未「拒絕」的求職者，皆視為仍然處於應徵狀態。則無論時間經過多久，一旦就職，Meet.jobs 仍有收費的權利。</h6>` +
    `<h6>12.4. 如該人選在 Meet.jobs 系統上是應徵狀態，則一旦就職 客戶 的工作職位，無論最後就任的是否為最初所應徵的職位，Meet.jobs 都保有收費的權利。亦即如果 客戶 無意錄用該人選，請務必在 Meet.jobs 上「拒絕」該人選，切勿有「無聲卡」等損害 客戶 商譽與信任的行為。</h6>` +
    `<h6>12.5. 凡是透過 Meet.jobs 系統應徵的求職者，以及 Meet.jobs 以任何可留下紀錄的方式所推薦的人選，一旦就職，Meet.jobs 即有權利向 客戶 收費。特別說明，此與僱主是否透過其他管道得知此位人選無關，Meet.jobs 仍有向 客戶 收費之權利。</h6>` +
    `<h6>12.6. 如 客戶 認定已就職的求職者並非透過 Meet.jobs 的服務所錄用，則須取得該名求職者以任何可留下紀錄的方式，確認並非透過 Meet.jobs 就職，且放棄「就職祝賀金」的聲明；如該人選有推薦人，也需要取得推薦人以任何可留下紀錄的方式，確認並非透過 Meet.jobs 就職，且放棄「推薦感謝金」的聲明，以做為佐證。在此情況下，則 Meet.jobs 將不收取服務費用。</h6>`,

  employer_agreement_title_12: "13. 實質薪資的認定",
  employer_agreement_content_12:
    `<h6>13.1. 一旦確認錄取，客戶 必須提供薪資資訊，例如僱傭合約、錄取信或薪資單作為佐證，以當作 Meet.jobs 收費的依據。如該資訊由求職者或推薦人提供，也必須獲得 客戶 以任何可留下紀錄形式，回覆確認。</h6>` +
    `<h6>13.2. Meet.jobs 對於薪資資訊負有保密責任，只能做為收取服務費⽤的佐證，⽽不可以⽤於其他⽤途。如因資訊外洩造成 客⼾ 損失，Meet.jobs 必須負賠償之責任。</h6>` +
    `<h6>13.3. 服務費⽤的計算⽅式為，如該職位的薪資計算方式為年薪，則直接以該數字 乘以 16%；如為月薪，則乘以 客⼾ 所保障的⽉份數字（例如 14 個月）以後，再乘以 16%，即為服務費用的數字。</h6>` +
    `<h6>13.4. 如該職位的薪資計算方式為日薪，則乘以 20（每⽉⼯作⽇數），乘以 客戶 所保障的月份數字（例如 14 個月，如無規範則為 12 個月），再乘以 16%，即為服務費用的數字。</h6>` +
    `<h6>13.5. 如該職位的薪資計算⽅式為時薪，則乘以 160（每⽉⼯作時數），乘以 客⼾ 所保障的⽉份數字（例如 14 個月，如無規範則為 12 個月），再乘以 16%，即為服務費⽤的數字。</h6>` +
    `<h6>13.6. 如該職位為「兼職」或「實習」職位，則為「全職」職位實質薪資的 60%。然⽽當 客戶 先以「兼職」或「實習」招募「全職」⼈員，意即在系統上顯示為「全職」時，其實質薪資與服務費⽤仍以「全職」⼯作計算之。</h6>`,

  employer_agreement_title_13: "14. 付款方式",
  employer_agreement_content_13:
    `<h6>14.1. 一旦 客戶 在 Meet.jobs 系統上確認就職，並告知薪資資訊，Meet.jobs 將提供帳單（invoice）及付款所需文件（如發票）。收到帳單以後，客戶 須在30個日曆天內完成付款。</h6>` +
    `<h6>14.2. Meet.jobs 現可接受「信用卡」和「轉帳匯款」兩種付費方式，客戶 可從中選擇，並顯示於 Meet.jobs 系統上</h6>` +
    `<h6>14.3. 如 客戶 選擇信用卡，則只需要在 Meet.jobs 系統上註冊信用卡資料即可。Meet.jobs 會依就職確認開立帳單，並於每個月的結帳日（每月5日）收費。信用卡為 Meet.jobs 建議最佳的付費方式。</h6>` +
    `<h6>14.4. 如 客戶 選擇轉帳匯款，則 Meet.jobs 將提供銀行帳戶，供 客戶 進行匯款。</h6>` +
    `<h6>14.5. Meet.jobs 有責任告知 客戶 最為方便且節省成本的付款方式。然而，Meet.jobs 的錄取方案收費為「年薪的 16%」，如有其他衍生費用，例如手續費、匯費、各項相關租稅等，皆由 客戶 自行負擔。</h6>`,

  employer_agreement_title_14: "15. 道德宣告",
  employer_agreement_content_14:
    `<h6>15.1. 人才招募的成敗往往決定一家企業的成敗。而企業能夠成功招募到優秀人才，必須基於企業、人才和服務提供者間的互相信任。因此，如有求職者或其推薦人以「不向 Meet.jobs 回報就任工作」為條件，要求 客戶 提供任何額外的報酬、獎金或其他形式的利益回饋，Meet.jobs 強烈建議 客戶 不要錄取該求職者。如向 Meet.jobs 舉報該求職者或推薦人，Meet.jobs 也將負查證之責任；一經查證屬實，即將該求職者或推薦人列為「排除名單」，告知 Meet.jobs 所有的企業 客戶。</h6>` +
    `<h6>15.2. 如 客戶 以「不向 Meet.jobs 回報錄取工作」為條件，主動提供求職者或推薦人任何形式的利益交換，則求職者或推薦人亦有權利舉報該企業。如查證屬實，考量此一行為有違企業徵才時的誠信原則，Meet.jobs 將在網站上公開 客戶 企業名稱，並列入「不推薦企業」名單。</h6>` +
    `<h6>15.3. 如 客戶 無法在收到帳單後的 30 個日曆天內完成付費，必須以可留下紀錄的形式取得 Meet.jobs 的同意，雙方並約定付款時間。如 客戶 有刻意拖延，甚至在未取得 Meet.jobs 同意下未準時付費的情況，則 Meet.jobs 將會在網站上公開企業名稱，並列入「不推薦企業」名單。</h6>` +
    `<h6>15.4. 如 客戶 提供的工作機會資訊，與最後錄取之條件不符，尤其當錄取薪資低於招聘工作機會薪資區間的最低薪資時，企業 須在 72 小時內主動以書面或可留下記錄的方式提出說明，並取得求職者和推薦人同意的聲明，作為佐證。如未獲得求職者和推薦人的同意，且該情事發生超過三次，Meet.jobs 將會在網站上公開 客戶 名稱，並列入「不推薦企業」名單。</h6>`,

  // employer_agreement_section_title_2: "第二部份、加值方案（Hire+ Plan）",

  employer_agreement_title_15:
    "16. 加值方案（Hire+ Plan）係 Meet.jobs 因 客戶 特殊需求所設立的服務方案，以下條列與錄取方案（Hire Plan）不同之處，供 客戶 自行判斷、選擇。",
  employer_agreement_content_15:
    `<h6>16.1. 如 客戶 選用加值方案，則可與 Meet.jobs 另外訂立客製化的服務合約，合約內容視所需服務而定，如人才策略諮詢、徵才廣告優化、延長保障期限等。</h6>` +
    `<h6>16.2. 加值方案的收費為該職位年薪的 20% 起，不設上限。其實際比例與金額視特殊的服務內容而定。</h6>` +
    `<h6>16.3. 加值方案保障錄取人最少任滿 180 個日曆天。</h6>` +
    `<h6>16.4. 承上，加值方案的收費方式為，客戶 在人選任職的前 30 個日曆天以前，完成支付該職位年薪的16%。其餘金額的支付時間由 客戶 與 Meet.jobs 雙方另行議定。</h6>` +
    `<h6>16.5. 承上，如該人選任滿前 90 個日曆天而後離職，則 Meet.jobs 將不會退回已收費的 16%。其餘金額退還比例由 客戶 與 Meet.jobs 雙方另行議定。</h6>`,

  employer_agreement_title_16:
    "17. 除上述內容以外，加值方案一切服務細節皆與錄取方案（Hire Plan）相同，可直接參考第一部份的條款內容。",

  employer_agreement_title_17:
    "18. 如 客戶 在已選擇其他方案的情況下，要升級到加值方案，只需在 Meet.jobs 系統上點選升級方案，或者以可留下紀錄的形式通知 Meet.jobs 即可。確認 客戶 升級方案需求的以後，Meet.jobs 將有專業顧問與 客戶 聯繫，提供協助。",

  // employer_agreement_section_title_3: "第三部份、基本方案（Post Plan）",

  employer_agreement_title_18: "19. 基本方案的功能、規範與收費",
  employer_agreement_content_18:
    `<h6>19.1. Meet.jobs 的基本方案僅提供網站及其延伸平臺張貼工作機會與管理應徵的功能。使用基本方案的 客戶 將在張貼工作機會以後，等待求職者應徵。相較於錄取方案主動協助 客戶 徵才，基本方案較為被動。</h6>` +
    `<h6>19.2. 基本方案以「使用」收費。費用為每個工作機會每天 1 美元，每月計費，客戶應於次月 5 號支付上個月費用。如未張貼工作機會或工作機會關閉，則無衍生費用。</h6>` +
    `<h6>19.3. 基本方案的費用僅能以信用卡的方式支付。</h6>` +
    `<h6>19.4. 使用基本方案的 客戶 可在 Meet.jobs 系統直接升級成錄取方案或加值方案。而如 客戶 要從錄取方案或加值方案切換至基本方案，則需另行以書面或可留下紀錄之方式，告知 Meet.jobs，由系統管理者協助完成切換。</h6>`,

  // employer_agreement_section_title_4: "第四部份、共同部份",

  employer_agreement_title_19: "20. 個資保密",
  employer_agreement_content_19: `<h6>由 Meet.jobs 應徵工作或推薦的求職者，其履歷、作品集、技術測試結果等所有資料，僅供 客戶 評估該求職者是否錄取使用，客戶 不可用於其他用途。若因此而造成求職者之損害，由 客戶 對求職者負擔損害賠償責任，概與 Meet.jobs 無涉。</h6>`,

  employer_agreement_title_20: "21. 簽核方式",
  employer_agreement_content_20:
    `<h6>Meet.jobs 提供以下方式供 客戶 簽核本合約。客戶 只需擇一採用即可。</h6>` +
    `<h6>A. 直接在 Meet.jobs 系統上勾選同意合約。此為 Meet.jobs 所建議具備法律效力且最有效率的方式。</h6>` +
    `<h6>B. 採用 Meet.jobs 所認同的第三方電子簽核系統，簽核後上傳到 Meet.jobs 系統。</h6>` +
    `<h6>C. 下載 Meet.jobs 已簽核完成的合約，由 客戶 列印，簽核完成後，掃描上傳到 Meet.jobs 系統。</h6>` +
    `<h6>D. 在 Meet.jobs 系統上點選寄送紙本合約，並填入收件地址。Meet.jobs 收到要求後將寄送已簽核完成的紙本合約共一式兩份，由 客戶 簽核完成後，掃描上傳到 Meet.jobs 系統，並將一份紙本寄回到 Meet.jobs 備存。</h6>`,

  employer_agreement_title_21: "22. 合約修改與終止",
  employer_agreement_content_21:
    `<h6>22.1. 無論選擇任何方案，只要未張貼工作機會或工作機會關閉，客戶 即無需再負擔任何費用。故絕大部份的情況下，本合約沒有終止的必要。</h6>` +
    `<h6>22.2. 如有需要，Meet.jobs 與 客戶 雙方皆具有單方面終止合約之權利。如一方欲終止合約，必須於終止日前一個月內告知對方。並於履行本合約全部義務後，合約方得終止。</h6>` +
    `<h6>22.3. 前項全部義務包括一切款項支付、資訊提供以及資訊關閉等事項。</h6>` +
    `<h6>22.4. 如遇不可抗力之因素，如其中有一方受災難、政治事件、宗教、種族等衝突以致無法履行合約，另一方對他方不負任何法律責任。</h6>` +
    `<h6>22.5. 除加值方案可能有客製化合約以外，如 Meet.jobs 針對本合約有所修改，將透過系統以及可留下紀錄的方式通知 客戶。如 客戶 不同意修改的內容，一般情況下，只需不再使用 Meet.jobs 服務即可。</h6>`,

  employer_agreement_title_22: "23. 準據法及爭議解決",
  employer_agreement_content_22:
    `<h6>23.1. 您和 Meet.jobs 同意除與法規相左之處以外，如本合約及／或「服務」引發任何爭議，皆以中華民國自由地區相關法規及法務單位為主，並以臺灣臺北地方法院為第一審管轄法院。</h6>` +
    `<h6>23.2. 如本合約有任何無法履行的部分或條款，雙方均同意應以保留原始目的且又能履行的方式修改本合約條款。如果無法修改，雙方同意移除無法履行的部分，並履行合約中的其餘條款。</h6>` +
    `<h6>23.3. 在法律允許的範圍內，本合約的英文版本及繁體中文版本均具有法律約束效力，其他譯本皆為方便閱讀使用。如不同版本間條文、用詞有所歧異，則以英文版本為主。本合約為雙方最終且完整之合意，有取代磋商過程中所提出之任何口頭或書面上的承諾、理解或其他意思表示之效力，且本合約之條款標題，僅為參考之用，雙方同意標題之內容不能作為解釋本合約之依據。</h6>` +
    `<h6>23.4. 如果 Meet.jobs 對違約行為未採取法律行動，並不代表 Meet.jobs 放棄履行本合約的權利。未經 Meet.jobs 的同意，客戶 不得指定或轉讓此合約（或會員身分或服務使用）。然而，客戶 您同意 Meet.jobs 得將此合約指定給其相關機構或購買 Meet.jobs 的機構。</h6>` +
    `<h6>23.5. 您同意第 24 條所述的地址為提供 Meet.jobs 法律通知唯一的方式。</h6>`,

  employer_agreement_title_23: "24. 聯絡我們",
  employer_agreement_content_23:
    `<h6>線上聯絡：回覆留言機器人、留言板，或來信 service@meet.jobs。</h6>` +
    `<h6>郵寄聯絡：8 Claymore Hill #03-01 Singapore 229572 或 5F., No. 1, Sec. 2, Jianguo N. Rd., Zhongshan Dist., Taipei City 104, Taiwan.</h6>`,

  // privacy_policy
  privacy_policy_intro_content:
    "<p>當您使用Meet.jobs「服務」時，本「隱私權政策」則適用於您。有關Meet.jobs收集的資料、使用及分享方式，Meet.jobs為用戶提供如本「隱私權政策」、「Cookie政策」、「企業用戶合約」、「個人用戶協議」。</p>",

  privacy_policy_title_0: `<span class="li-number">1.</span>前言及名詞定義`,
  privacy_policy_content_0:
    `<h5>1.1. Meet.jobs的「服務」提供註冊用戶（「會員」）尋找、應徵及推薦工作機會。部份的「服務」內容及資料會向非會員（「訪客」）開放。</h5>` +
    `<h5>1.2. 本「隱私權政策」適用於Meet.jobs網站及其他一切衍生服務，但不含聲明以不同隱私權政策為準據的服務。</h5>` +
    `<h5>1.3. 身為Meet.jobs「服務」的「訪客」或「會員」，您個人資料的收集、使用及分享必須遵守本「隱私權政策」及更新條款。</h5>` +
    `<h5>1.4. Meet.jobs隨時可能修改「隱私權政策」。如有重大變更，我們會透過「服務」或其他方式通知您，讓您有機會在變更生效前詳閱變更內容。我們同意變更不能溯及既往。如您不同意變更後的內容，您可以不再使用、存取Meet.jobs服務，或透過聯絡我們關閉您的帳戶。如您繼續使用Meet.jobs服務，即代表您同意並會遵守更新後的條款。</h5>`,

  privacy_policy_title_1: `<span class="li-number">1.</span>Meet.jobs收集的資料`,
  privacy_policy_content_1:
    `<h5>2.1. 您提供的資料</h5>` +
    `<h6>2.1.1. 註冊：如要建立帳戶，您必須提供包括電子郵件地址及、使用者名稱以及密碼在內的資料。若您註冊付費「服務」，您必須提供付款資料（例如信用卡）及帳務資訊。</h6>` +
    `<h6>2.1.2. 個人檔案：您可以選擇個人檔案相關資訊，例如教育背景、工作經歷、技能、照片、城市或地區等等。您不需要提供有關您個人檔案的額外資訊，不過，個人檔案資訊能夠協助您充分運用Meet.jobs「服務」，也有助於讓徵才企業和獵頭顧問找到您。請勿在個人檔案中發表或新增您不想公開的個人資料。</h6>` +
    `<h6>2.1.3. 當您填寫表單、回覆問卷調查或送出履歷時，您會提供、發表或上傳個人資料到Meet.jobs「服務」，此時我們會收集您所提供的個人資料。發表或上傳個人資料並非是必要的，雖然若不這麼做，可能會限制您可以使用Meet.jobs「服務」的內容。</h6>` +
    `<h5>2.2. 他人提供的資料</h5>` +
    `<h6>您和他人在Meet.jobs「服務」發表的內容皆可能包含與您相關的資訊。Meet.jobs將會收集與您相關的公開資訊。</h6>` +
    `<h5>2.3. 使用服務</h5>` +
    `<h6>當您造訪或使用Meet.jobs網站或其他服務，例如在您瀏覽或按一下內容（例如學習影片）或廣告（Meet.jobs網站內外及 APP 上）、執行搜尋、分享文章或應聘工作時，我們會記錄使用資料。Meet.jobs使用登入資料、Cookie、裝置資訊及網際網路通訊協定 (「IP」) 位址識別您的身分並記錄使用情形。</h6>` +
    `<h5>2.4. Cookie、Web指標及其他類似技術</h5>` +
    `<h6>Meet.jobs使用 Cookie 和類似技術（例如 Web 指標、像素、廣告標記和裝置識別碼）在不同的「服務」和裝置中辨識您及/或您的裝置。我們也允許他人按照「Cookie 政策」的規定使用 Cookie。您可透過您的瀏覽器設定或其他工具控制 Cookie。您也可以要求我們停止為了顯示第三方廣告而使用 Cookie 和類似技術追蹤您在其他網站上的行為。</h6>` +
    `<h5>2.5. 您的裝置和地點</h5>` +
    `<h6>當您造訪或離開Meet.jobs「服務」（包括我們在其他網站上的外掛程式或 Cookie 或類似技術）時，我們會接收您原來所在網站及後續造訪網站的 URL。我們也會收到與您的 IP 位址、代理伺服器、作業系統、Web 瀏覽器和附加組件、裝置識別碼和功能及／或 ISP 或電信公司相關的資訊。若您透過行動裝置使用Meet.jobs「服務」，該裝置會根據您的手機設定，將您的位置相關資料傳送給我們。在我們使用 GPS 或其他工具識別您的精確位置之前，我們會先要求您選擇加入。</h6>` +
    `<h5>2.6. 訊息</h5>` +
    `<h6>當您透過Meet.jobs「服務」發送、接收或使用訊息時，我們會收集與您相關的資訊。例如，若您收到徵才企業的應徵邀請，我們會追蹤您是否做出回應，並且會傳送提醒給您。我們也會使用自動掃描技術來處理訊息。</h6>` +
    `<h5>2.7. 其他</h5>` +
    `<h6>Meet.jobs「服務」是動態的，我們經常推出新功能，因此可能需要收集新資訊。若我們收集完全不同的個人資料，或針對資料使用方式進行重大變更，我們會通知您，而且可能會修改本「隱私權政策」。</h6>`,

  privacy_policy_title_2: `<span class="li-number">3.</span>Meet.jobs如何使用您的資料`,
  privacy_policy_content_2:
    `<h5>3.1. 期間：上述收集的資料之利用期間係指依照蒐集之特定目的、相關法令所定、因執行業務所須之必要保存期間或依個別契約所定之保存年限為利用存續期間。</h5>` +
    `<h5>3.2. 地區: 上述收集的資料將用於Meet.jobs及Meet.jobs全球之關係企業或合作夥伴，以便提供相關服務。</h5>` +
    `<h5>3.3. Meet.jobs使用您個人資料的方式取決於您所使用的「服務」、您使用這些「服務」的方式，以及您在設定中進行的選擇。我們使用與您相關的資料來提供及個人化（包括借助自動化系統及我們的推測）Meet.jobs「服務」（包括廣告），並提高本服務之於您及其他人的關聯性與實用性。</h5>` +
    `<h5>3.4. 我們運用您的資料來授權您使用Meet.jobs「服務」。Meet.jobs「服務」讓您能夠探索潛在職涯、尋求與得到就業機會。徵才者（特定職位）或推薦人都可以找到您的個人檔案。我們會運用您的資料推薦職位給您。您可以釋出轉職意願，並與徵才企業分享資訊。我們可能會運用自動化系統建立個人檔案並提供推薦，協助提高Meet.jobs「服務」之於「會員」、「訪客」及徵才企業的關聯性。讓您的個人檔案維持在準確且最新的狀態，可能更有助於讓您透過Meet.jobs「服務」掌握機會。</h5>` +
    `<h5>3.5. Meet.jobs「服務」能為徵才企業、獵頭顧問和推薦人提供專屬的搜尋功能及工具（包括傳訊及活動通知）。這些會員將能夠從您的個人檔案匯出有限的資訊，例如您的姓名、頭銜、現職公司、現職職稱，以及一般位置。您可以透過設定，決定是否要被其他會員搜尋到。</h5>` +
    `<h5>3.6. Meet.jobs會透過電子郵件、行動電話、張貼至Meet.jobs網站或 APP 的通知、在Meet.jobs系統傳送給您的訊息，以及Meet.jobs「服務」提供的其他方式（包括手機簡訊及推播通知）與您聯絡。我們會傳送「服務」可用與否、安全或其他與服務相關問題的訊息。我們傳送的訊息也包括如何使用「服務」、工作機會建議以及Meet.jobs和合作夥伴提供的宣傳訊息。請注意，您不能拒收我們所傳送的服務訊息，包括安全與法律相關通知。</h5>` +
    `<h5>3.7. Meet.jobs使用下列資料（個別使用或聯合使用），直接或透過各種合作夥伴，在Meet.jobs「服務」內外針對「會員」、「訪客」及其他人投放鎖定特定對象的廣告（以及衡量廣告效果）：</h5>` +
    `<h6>Meet.jobs「服務」內外所用 Web 指標、像素、廣告標記、Cookie 及裝置識別碼等廣告技術收集到的資料；</h6>` +
    `<h6>會員提供的資訊（例如個人檔案、聯絡資訊、職稱及產業）；</h6>` +
    `<h6>您透過使用Meet.jobs「服務」提供的資料（例如搜尋記錄、動態消息、您閱讀的內容、瀏覽的頁面、觀看的影片、點擊的廣告等）；</h6>` +
    `<h6>廣告合作夥伴和出版商提供的資訊；</h6>` +
    `<h6>運用上述資料推測所得的資訊（例如使用個人檔案中的工作職稱推測產業、資歷及薪資水準；使用畢業日期推測年齡，或使用名字或所用的代名詞來推測性別）。</h6>` +
    `<h6>若您針對這類廣告執行任何操作（例如點擊），您的操作會與您的帳戶產生關聯，他人（包括廣告商）都會看見。</h6>` +
    `<h5>3.8. Meet.jobs不會為廣告目的與任何第三方廣告商或廣告網分享您的個人資料，除了以下內容以外：(i) 雜湊或裝置識別碼（除非在某些國家這些資料為個人資料）；(ii) 獲得您的另行授權，或 (iii) 任何Meet.jobs「服務」的使用者已能看見的資料（例如個人檔案）。不過，若您在我們的網站或 APP 內外瀏覽或按一下某則廣告，廣告提供者將收到訊號，知道有人已造訪顯示廣告的網頁，而他們可能會透過使用 Cookie 等機制來判斷是否為您本人。廣告合作夥伴可以將廣告商運用我們的 Cookie 和類似技術收集到的個人資料與您建立直接關聯。在此情況下，我們會在合約中明確要求這類廣告合作夥伴必須先請您明確同意如此行為才能建立關聯。</h5>` +
    `<h5>3.9. Meet.jobs運用與「會員」相關的資料和內容傳送宣傳會員及網路成長、參與以及Meet.jobs「服務」的邀請與通訊內容。</h5>` +
    `<h5>3.10. Meet.jobs運用資料（包括公開的意見反映）進一步研究及開發「服務」，以利為您及他人提供更好、更容易直覺操作而且符合個人需求的使用經驗、提高「服務」會員人數，以及推動在「服務」中個人和企業會員的互動。</h5>` +
    `<h5>3.11. Meet.jobs運用可得的個人資料研究社會、經濟和職場趨勢，例如工作機會和相關職位所需的技能，以及有助於拉近各產業及地理區域差距的政策。在某些情況下，我們會與值得信賴的第三方合作進行這項研究，並會以保障您的隱私權為前提進行控制。我們發表或允許他人發表經濟方面的實用資訊，但會以彙總資料呈現而非個人資料。</h5>` +
    `<h5>3.12. Ｍeet.jobs 及我們的合作夥伴會透過「服務」進行問卷調查。您不具回覆問卷調查的義務，而且可以選擇您所提供的資訊。</h5>` +
    `<h5>3.13. Meet.jobs會使用資料（可能包括您的通訊內容）來調查、回覆及解決申訴與「服務」問題（如錯誤）。</h5>` +
    `<h5>3.14. Meet.jobs會使用您的資料產生及分享彙總實用資訊，此類資訊無法用於辨別您的身分。例如，我們可能會使用您的資料，產生有關我們的會員、其職業或所屬產業的相關統計資料、計算投放廣告的曝光次數或點擊次數，或公布「服務」的訪客人口統計或人口統計職場實用資訊。</h5>` +
    `<h5>3.15. 若我們認為有安全性方面的必要，或需調查疑似詐騙或其他違反用戶協議或本「隱私權政策」及/或企圖傷害Meet.jobs「會員」或「訪客」的行為，我們會使用您的資料（包括您的通訊內容）。</h5>`,

  privacy_policy_title_3: `<span class="li-number">4.</span>Meet.jobs如何分享資訊`,
  privacy_policy_content_3:
    `<h5>4.1. 個人檔案</h5>` +
    `<h6>Meet.jobs服務內的會員、客戶，以及外部訪客都能看見您的個人檔案。個人檔案的開放程度，以及對方是否能瀏覽檔案中的特定欄位，都依據您在Meet.jobs服務上的設定而決定。</h6>` +
    `<h5>4.2. 互動資訊</h5>` +
    `<h6>Meet.jobs服務允許會員透過訊息、關注、貼文回應和分享等形式的互動。所有互動資訊都屬於您個人檔案的一部份，可以根據您在Meet.jobs服務的設定決定公開內容與開放程度。如您與其他會員有互動資訊，對方也會知道此一互動行為、紀錄和訊息。</h6>` +
    `<h5>4.3. 企業帳戶</h5>` +
    `<h6>依據企業「服務」而定，在您使用該等「服務」之前，我們會要求權限，以利分享您個人檔案中或使用非企業「服務」時的相關資料。我們瞭解某些活動（例如求職和私人訊息）具敏感性，因此，除非您選擇透過我們的「服務」與您的僱主分享（例如應徵同一家公司的新職務或在透過「服務」傳送給同事的訊息中提到您在求職），否則我們不會與您的僱主分享這些活動。</h6>` +
    `<h5>4.4. 通訊封存</h5>` +
    `<h6>某些「會員」（或其僱主）為遵守法律或專業規定之故而需要封存通訊內容及社群媒體活動，我們會使用第三方服務提供此類封存服務。我們開放在「服務」以外進行此類的訊息封存。例如，某財務顧問需要透過我們的「服務」封存她與客戶之間的通訊內容，才能繼續持有專業財務顧問執照。</h6>` +
    `<h5>4.5. 其他服務（API連接）</h5>` +
    `<h6>根據您在Meet.jobs服務的設定，其他服務可能會查詢您的檔案。當您選擇將自己的帳戶與其他服務連結時，對方即可取得您的個人資料。當您選擇連結帳戶時，會出現一個同意畫面，內容說明分享及使用該等個人資料的相關資訊。</h6>` +
    `<h5>4.6. 相關服務</h5>` +
    `<h6>我們會與我們的相關機構共用您的個人資料，以利提供及開發我們的「服務」。我們可能會將受到本隱私權政策約束的不同服務進行內部整合，來協助我們的「服務」變得更相關和實用。</h6>` +
    `<h5>4.7. 服務提供者</h5>` +
    `<h6>我們會在他人的協助之下提供我們的「服務」（例如維護、分析、稽核、付款、詐騙偵測、行銷以及開發）。此類服務提供者只能在合理所需範圍內取得您的資訊，以利代表我們進行這些工作，此外，他們有義務不得披露或挪用相關資訊。</h6>` +
    `<h5>4.8. 法定揭露事項</h5>` +
    `<h6>如為法律、傳票或其他訴訟程序要求，或者我們基於下列情況合理認定有必要披露，我們可能會披露與您有關的資訊：(1) 針對疑似或實際非法活動進行調查、預防或採取行動，或協助政府執法機關；(2) 履行我們與您之間的協議；(3) 於任何第三方提出主張或指控時進行調查及為自己辯護；(4) 保護「服務」的安全性或完整性（例如與其他面臨類似威脅的公司分享）；或 (5) 履行或保障Meet.jobs、Meet.jobs「會員」、員工或他人的權利與安全。除非法律或法院諭令禁止或相關要求屬於緊急情況，我們會酌情適時通知「會員」與其個人資料相關的法律要求。如果我們認為要求太過分、模糊或不合理，我們可能對此類要求提出異議，但不保證會對每項要求提出異議。</h6>` +
    `<h5>4.9. 主控權變更或出售</h5>` +
    `<h6>在出售、併購或主控權變更的過程中，或在準備進行其中任何活動期間，我們也可以分享您的個人資料。凡任何其他收購本公司或本公司部份業務的實體，皆有權繼續使用您的資料，但除非另行徵得您同意，否則使用方式仍以本「隱私權政策」的規定為準。</h6>`,

  privacy_policy_title_4: `<span class="li-number">5.</span>您的選擇及義務`,
  privacy_policy_content_4:
    `<h5>5.1. 資料保留</h5>` +
    `<h6>只要您的帳戶仍然存在，或為提供您服務所需，Meet.jobs會繼續保留您的個人資料；包括您或他人提供的資料，及從您使用服務時生成或推斷的資料。即便您單純只在每隔幾年尋找新工作時使用我們的「服務」，我們仍然會保留您的資訊並開放您的個人檔案，直到您決定關閉帳戶為止。在某些情況下，我們會選擇保留資訊，但去除個人身分或採用彙總形式。</h6>` +
    `<h5>5.2. 存取及控制個人資料的權利</h5>` +
    `<h6>Meet.jobs提供您控制及管理我們所擁有關於您的資料的設定權限如下：</h6>` +
    `<h6>5.2.1. 刪除資料：您可以要求我們清除或刪除全部或部分您的個人資料（例如：我們已不需該資料來提供相關「服務」）。</h6>` +
    `<h6>5.2.2. 變更或更正資料：您可以透過您的帳戶編輯部分個人資料。在部分情況下，特別是資料有誤時，您也可以要求我們變更、更新或訂正您的資料。</h6>` +
    `<h6>5.2.3. 反對或限制資料使用：您可以要求我們停止使用所有或部分您的個人資料（例如：我們沒有持續使用的法定權利）或限制我們使用（例如：如果您的個人資料不正確或是透過非法取得的）。</h6>` +
    `<h6>5.2.4. 讀取和/或獲得您的資料：您可以要求我們提供您個人資料的副本，並可以要求我們提供機器可讀的個人資料副本。</h6>` +
    `<h6>請注意在特定國家的實際情況可能視其法規有額外的權利。</h6>` +
    `<h5>5.3. 關閉帳戶</h5>` +
    `<h6>若您選擇關閉Meet.jobs帳戶，我們通常會在 24 小時內停止開放「服務」的其他用戶瀏覽您的個人資料。除下方註明之情況外，我們通常會在關閉帳戶後30天內刪除已關閉帳戶的資訊。</h6>` +
    `<h6>即便您已關閉帳戶，若在合理範圍內為遵守法律義務（包括應執法機關要求）、遵守法規要求、解決爭議、維護安全、預防詐騙及濫用、履行「用戶協議」或完成您「取消訂閱」我們日後發送的訊息之申請所需，我們會繼續保留您的個人資料。在您關閉帳戶後，我們會保留無法辨別個人身分的資訊。</h6>` +
    `<h6>在您關閉帳戶或將資訊從您自己的個人檔案或信箱中刪除之後，您與他人的互動資訊仍將可見，我們無法控制其他「會員」複製到「服務」之外的資料。第三方服務（例如搜尋引擎結果）更新快取之前，仍然可能會繼續顯示您的個人檔案。</h6>`,

  privacy_policy_title_5: `<span class="li-number">6.</span>其他重要資訊`,
  privacy_policy_content_5:
    `<h5>6.1. 資訊安全</h5>` +
    `<h6>Meet.jobs採用為保護資料所設計的安全防護措施，例如 HTTPS。我們會定期監控系統可能發生的漏洞與攻擊。但是，我們無法保證您傳送給我們的任何資訊一定安全。我們不保證資料不會遭他人存取、披露、篡改，或由於我們的實體、技術、或管理保護措施受到破壞而遭銷毀。</h6>` +
    `<h5>6.2. 跨境資料傳輸</h5>` +
    `<h6>Meet.jobs的用戶來自各國，我們仰賴合法提供的機制以符合法規的方式跨境傳送資料。我們處理資料的國家可能擁有不同的法規，且可能與您國家的法律相比較不具保護性。</h6>` +
    `<h5>6.3. 法律基礎與授權</h5>` +
    `<h6>只有當Meet.jobs擁有法律依據時，我們才會收集和處理您的個人資料。法律依據包括同意聲明（如取得您的同意）、合約（資料處理在締約時為必要行為）和合法利益。</h6>` +
    `<h6>我們仰賴您的同意來處理個人資料時，您隨時有權利撤回或拒絕同意；我們仰賴合法利益處理資料時，您有拒絕的權利。</h6>` +
    `<h5>6.4. 直接行銷與「Do Not Track」訊號</h5>` +
    `<h6>在未經您授權的情況下，我們不與第三方分享個人資料做為直接行銷之用。此外，在大部份情況下，Meet.jobs將不回覆「Do Not Track」訊號。</h6>` +
    `<h5>6.5. 聯絡我們</h5>` +
    `<h6>線上聯絡：回覆留言機器人、留言板，或來信 service@meet.jobs。</h6>` +
    `<h6>郵寄聯絡：8 Claymore Hill #03-01 Singapore 229572 或 2nd Floor, No. 2, Lane 59, Yitong Street, Zhongshan District, Taipei 10486, Taiwan</h6>` +
    `<h5>6.6. 準據法暨管轄法院</h5>` +
    `<h6>因本網站造成之爭訟均以中華民國法律為準據法，並以臺灣臺北地方法院為第一審管轄法院。</h6>`,

  // cookie_policy
  cookie_policy_intro_content: `<p>當您使用Meet.jobs「服務」時，本「Cookie政策」則適用於您。我們將透過此政策，為您說明Meet.jobs使用Cookie的方式和時間。</p>`,

  cookie_policy_title_0: `<span class="li-number">1.</span>Meet.jobs使用Cookie嗎`,
  cookie_policy_content_0: `<h5>是的。我們使用Cookie和其他技術來為所有Meet.jobs用戶提供最佳使用體驗。並協助我們維護帳戶安全。繼續瀏覽或使用Meet.jobs服務即表示您同意Meet.jobs以本政策所描述的方式來使用Cookie及相似技術。</h5>`,

  cookie_policy_title_1: `<span class="li-number">2.</span>Cookie是什麼？`,
  cookie_policy_content_1: `<h5>Cookie是放在您裝置上的小檔案，並啟用Meet.jobs許多功能。例如，Meet.jobs可透過Cookie來識別您的裝置，保障您登入Meet.jobs及相關網頁時的安全；如果有人試圖使用其他裝置登入您的帳戶，Cookie也能協助我們即時發現。Cookie也方便會員分享內容，並協助我們為您提供更相關的資訊。</h5>`,

  cookie_policy_title_2: `<span class="li-number">3.</span>Meet.jobs什麼時候放置Cookie？`,
  cookie_policy_content_2: `<h5>我們會在網頁和行動應用程式使用Cookie。所有造訪這些網頁的瀏覽器都會收到Meet.jobs的Cookie。當您造訪使用Meet.jobs外掛程式或標籤的外部網站時，我們也會在瀏覽器上放置Cookie。</h5>`,

  cookie_policy_title_3: `<span class="li-number">4.</span>Meet.jobs使用怎樣的Cookie？`,
  cookie_policy_content_3: `<h5>Meet.jobs使用兩種類型的Cookie：永續型和工作階段。永續型Cookie協助Meet.jobs識別您為現有會員，因此您下回使用Meet.jobs時，無需再次登入便可使用服務。登入帳戶後，永續型的Cookie將會留在您的瀏覽器上，您下回造訪我們的網站或使用我們服務的夥伴網站（如分享或應徵工作機會的按鈕）時，Meet.jobs便可讀取您的身分。工作階段Cookie僅限您登入時（通常是您當下造訪網站或瀏覽器開啟期間）使用。</h5>`,

  cookie_policy_title_4: `<span class="li-number">5.</span>Meet.jobs為什麼使用Cookie？`,
  cookie_policy_content_4:
    `<h5>Cookie的用途包括在您造訪Meet.jobs網站時識別您的身份、儲存您的偏好設定及根據您的設定提供您更個人化的使用體驗。Cookie也可讓您和Meet.jobs的互動更安全及快速。此外，我們也透過Cookie在Meet.jobs網頁內外為您顯示推薦資訊，並透過Meet.jobs外掛程式提供您個人化的功能，如「分享」按鈕。</h5>` +
    `<table style="width:100%">` +
    `<tr>` +
    `<th style="width:30%">使用類別</th>` +
    `<th style="width:70%">說明</th>` +
    `</tr>` +
    `<tr>` +
    `<td>驗證</td>` +
    `<td>如果您登入Meet.jobs，Cookie會協助我們顯示正確的資料並為您提供個人化的使用體驗。</td>` +
    `</tr>` +
    `<tr>` +
    `<td>安全</td>` +
    `<td>Meet.jobs使用Cookie來支援或啓用安全功能，並協助我們偵測不法活動及發現違反我們用戶協議的情事。</td>` +
    `</tr>` +
    `<tr>` +
    `<td>偏好設定、功能及服務</td>` +
    `<td>Cookies 能顯示您的語言及通訊偏好設定，也可協助您輕鬆填寫Meet.jobs的表單。此外，結合我們的外掛程式，Cookie還能提供您其他功能、實用資訊及個人化的內容。如想深入瞭解，請參閱我們的隱私權政策。</td>` +
    `</tr>` +
    `<tr>` +
    `<td>表現、分析及研究</td>` +
    `<td>Cookie協助我們瞭解Meet.jobs網站和外掛程式在不同地點的表現。我們也使用Cookie以瞭解、改進並研究產品、功能與服務，包括當您從其他網頁、應用程式或其他裝置，如公司電腦或行動裝置使用Meet.jobs。</td>` +
    `</tr>` +
    `</table>`,

  cookie_policy_title_5: `<span class="li-number">6.</span>Do Not Track (DNT) 是什麼？`,
  cookie_policy_content_5: `<h5>DNT 是立法機關，主要為 U.S. Federal Trade Commission（FTC）所提倡的概念，要求網路產業開發並執行特定機制，讓網路用戶得以透過瀏覽器設定自行控制線上活動的追蹤情況。全球資訊網協會（W3C）致力與產業團體、網路瀏覽器公司、科技公司及管理機關合作，共同開發 DNT 標準技術。雖然有部分進展，但速度緩慢，目前尚無既定標準。因此，一般來說Meet.jobs不回應「Do Not Track」訊號。</h5>`,

  cookie_policy_title_6: `<span class="li-number">7.</span>控制 Cookie`,
  cookie_policy_content_6: `<h5>多數瀏覽器都允許您透過其偏好設定管理 Cookie。然而，如果您限制網站放置 Cookie，您可能無法瀏覽個人化的內容，這可能會影響您的使用體驗，您也可能無法儲存個人化的設定，如：登入資訊。</h5>`,

  cookie_policy_title_7: `<span class="li-number">8.</span>如果不想被放置 Cookie 或想移除 Cookie 應該怎麼做？`,
  cookie_policy_content_7: `<h5>如果您不希望接收Cookie，您可以變更電腦或行動裝置的瀏覽器設定。若您使用Meet.jobs時，未變更瀏覽器設定，我們將假定您願意接收所有 Meet.jobs 網站的 Cookie。大部分的瀏覽器都提供查看或移除 Cookie 的功能，包括 Meet.jobs Cookie。請注意，Meet.jobs 網站在沒有 Cookie 的情況下無法正常運作。</h5>`,

  cookie_policy_title_8: `<span class="li-number">9.</span>相關資訊`,
  cookie_policy_content_8: `<h5>您可以前往 wikipedia.org、www.allaboutCookies.org，或www.aboutCookies.org 深入瞭解如何查看已放置的 Cookie 及如何管理和刪除 Cookie 等相關資訊。</h5>`,

  // summary_of_changes
  summary_of_changes_user_agreement_content: ``,
  summary_of_changes_employer_agreement_content: `<p>7. 客戶 可將要招募的工作資訊張貼於 Meet.jobs 網站及所有延伸之網路平台， 並保證所提供資訊正確無誤。若 客戶 提供之資訊有誤而造成 Meet.jobs 之損害 ，客戶 應對 Meet.jobs 負擔損害賠償責任；若因此而造成求職者之損害，客戶 應 自行負責，概與 Meet.jobs 無涉。</p>
        <p>17. Meet.jobs 將於社群網路分享並推廣 客戶 之企業和職缺資訊，包括且不限於在 Facebook、LinkedIn、Youtube、Instagram、WeChat、TikTok 等，以及客戶 有特殊需求之網站或社群。</p>
        <p>30. 凡透過 Meet.jobs 錄取之人選，Meet.jobs 保障該名錄取人選任滿 30 個日曆天。 除 客戶 與 Meet.jobs 另有議定且留下紀錄同意者，不在此限。</p>
        <p>31. 如在任職的前 30 個日曆天內，該人選自行離職，則無論客戶選擇何種服務方案，Meet.jobs 都將退還 50% 的服務費用。</p>
        <p>32. 承上，如 客戶 對於該職位仍有招募人選之需求，則 Meet.jobs 亦將提供相應服務，協助招募替代人選。唯此一情況下，客戶 仍須支付該人選之招募服務費用。</p>
        <p>33. 特別強調，如該人選在前 30 個日曆天內因工作專業表現等問題，無法過試用期而被 客戶 解任，則不在 Meet.jobs 的保障範圍之內。</p>
        <p>34.上述 30 – 33 條內容為「社群方案」所提供之標準服務內容。如 客戶 另有特殊服務需求（例如希望延長試用期等），請選擇「獵才方案」提供客製化招募服務。</p>
        <p>46. Meet.jobs 現可接受「信用卡」、「轉帳匯款」和「虛擬貨幣」（目前僅接受 USDT 及 USDC）三種付費方式，客戶 可從中選擇， 並顯示於 Meet.jobs 系統上。</p>
        <p>50. 社群方案（Social Plan）的收費為招募職位實質薪資年薪的 16%，以確認錄取人選 就職為依據，向 客戶 收取服務費用。</p>
        <p>56. 獵才方案（Hunter Plan）的收費為招募職位實質薪資年薪的 20% 以上（含），以 確認錄取人選就職為依據，向 客戶 收取服務費用。</p>
        <p>60. 由 Meet.jobs 應徵工作或推薦的求職者，其履歷、作品集、技術測試結果等所有資料，僅供 客戶 評估該求職者是否錄取使用，客戶 不可用於其他用途。若因此而造成求職者之損害，由 客戶 對求職者負擔損害賠償責任，概與 Meet.jobs 無涉。</p>
        <p>80. 如 客戶 在 2023 年 05 月 01 日 以後仍繼續採用 Meet.jobs，則視為同意新合約。</p>`,
  summary_of_changes_privacy_policy_content: ``,
  summary_of_changes_cookie_policy_content: ``,
};
