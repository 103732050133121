class Metas {
    constructor(type, attrs) {
        switch(type) {
            case 'countries':
                for(let prop in attrs) {
                    if(Object.prototype.hasOwnProperty.call(attrs, prop)) {
                        this[prop] = attrs[prop].name;
                    }
                }
                break;
            case 'phone_codes':
                for(let prop in attrs) {
                    if(Object.prototype.hasOwnProperty.call(attrs, prop)) {
                        this[prop] = attrs[prop].phone.country_code;
                    }
                }
                break;
            case 'locations':
                for(let i=0; i<attrs.length; i++) {
                    this[attrs[i].id] = attrs[i].formattnpmed_add
                }
                break;
            case 'currency_types':
                for(let prop in attrs) {
                    if(Object.prototype.hasOwnProperty.call(attrs, prop)) {
                        this[prop] = prop;
                    }
                }
                break;
            case 'requirement_skills':
                for(let prop in attrs.collection) {
                    if(Object.prototype.hasOwnProperty.call(attrs, prop)) {
                        this[prop] = attrs[prop];
                    }
                }
                break;
            default:
                for(let prop in attrs) {
                    if(Object.prototype.hasOwnProperty.call(attrs, prop)) {
                        this[prop] = attrs[prop];
                    }
                }
        }

    }

    value(key) {
        return this[key];
    }

    key(value) {
        if(!value) return null;
        for(let key in this) {
            if(this[key] === value)
                return key;
        }
        return null;
    }
}

export default Metas;