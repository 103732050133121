export default {
  view_employer_page: "View employer page",
  basic_information: "Basic information",
  employer_log: "Employer logo",
  _recommend_size__300x300px_: "(recommend size: 300x300px)",
  industry: "Industry",
  employer_size: "Employer size",
  address: "Address",
  city: "City",
  full_address: "Full address",
  set_as_main_office: "Set as main office",
  delete_address: "Delete address",
  add_address: "Add address",
  website: "Website",
  employer_description: "Employer description",
  describe_your_organization_in_one_sentence:
    "Describe your organization in one sentence",
  make_candidates_understand_your_company_quickly____180_:
    "Make candidates understand your company quickly. ({{count}}/180)",
  description: "Description",
  introduce_your_products_or_corporate_culture:
    "Introduce your products or corporate culture.",
  photos_and_videos: "Photos & videos",
  photos: "Photos",
  youtube_video_link: "Youtube video link",
  employer_contact: "Employer contact",
  name: "Name",
  phone_number: "Phone number",
  full_name: "Full name",
  these_information_will_be_seen_on: "These information will be seen on",
  your_employer_profile_is_not_complete__are_you_sure_you_want_to_leave_this_page_:
    "Your employer profile is not complete. Are you sure you want to leave this page?",
  finish_later: "Finish later",
  keep_writing: "Keep writing",
};
