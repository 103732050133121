import React from 'react';
import { LogInFormWrapper } from '../../../containers/frontend.jsx';
import { Link } from 'react-router-dom';
import { withNamespaces, Interpolate } from "react-i18next";
import InternalUri from "../../../models/internal_uri";

const LogInPage = ({t}) => {

    const internal_uri = new InternalUri();

    return (
        <div className="page-entrance content-wrapper white">
            <div className="container">
                <section className="page-title">
                    <h2>{t('welcome_back_')}</h2>
                </section>
                <section className="page-content">
                    <div className="entrance-wrapper">
                        <div className="entrance-form-wrapper">
                            <LogInFormWrapper />
                        </div>
                        <hr />
                        <p>
                            <Interpolate
                                i18nKey="don_t_have_an_account____now_"
                                useDangerouslySetInnerHTML={true}
                                sign_up={
                                    <Link
                                        to={internal_uri.formatted_frontend_sign_up_page_path()}
                                        className="link"
                                    >
                                        {t('general:sign_up')}
                                    </Link>
                                }
                            />
                        </p>
                    </div>
                </section>
            </div>
        </div>
    )
}
export default withNamespaces(['frontend_authentication', 'general'])(LogInPage);