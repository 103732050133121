export const isEmail = (email) => {
    // Reference: http://emailregex.com/
    // let regExp = /^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()/[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regExp.test(email);
};

export const isSlug = (string) => {
    let regExp = /^(?! )[0-9a-z-]*$/;
    return regExp.test(string);
};

export const isNumeral = (string) => {
    let regExp = /^[+-]?\d*(\.\d*)?$/;
    return regExp.test(string);
};

export const isInteger = (string) => {
    let regExp = /^\d+$/;
    return regExp.test(string);
};
