import React from 'react';
import Select from 'react-select';
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import {contain_not_only_whitespace, page_smothly_scroll_to} from "../../../../assets/js/functions";
import { withNamespaces, Interpolate } from "react-i18next";
import { CSSTransitionGroup } from 'react-transition-group'
import Roleship from "../../../models/roleships";
import InternalUri from "../../../models/internal_uri";
import Employer from "../../../models/employer";
import modal_pop_up_delete from "../../../../assets/images/i047-pop-ups-delete.svg";
import modal_pop_up_cancel from "../../../../assets/images/i048-pop-ups-cancel.svg";

const config = new Config();
const headers = new ApiHeaders();

class EditMemberModal extends React.Component {

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.cancelTheAction = this.cancelTheAction.bind(this);
        this.removeMember = this.removeMember.bind(this);
        this.leaveEmployer = this.leaveEmployer.bind(this);
        this.continueRemoveMember = this.continueRemoveMember.bind(this);
        this.continueLeaveEmployer = this.continueLeaveEmployer.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            active_user_id: -1,
            active_employer: {},
            active_role_id: -1,
            active_roleship_id: -1,
            active_member: {},
            access_control_list: {},
            form_data: {},
            message_modal: {
                open: false,
                img_url: '',
                text: '',
                action_type: '',
                the_action: ''
            },
            error_messages: {},
            loading: true,
            saving: false,
            deleting: false,
            options: {}
        };
        this.transferable_ownership = false;
        this.removable_member = false;
        this.member_leavable = false;
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        const { open, roleship, role, creator_user_id } = next_props.site_state.edit_member_modal;
        const { reduxTokenAuth, current_user } = next_props;
        if(open !== this.props.site_state.edit_member_modal.open) {
            if(open) {
                const active_user_id = reduxTokenAuth.currentUser.attributes.id;
                this.transferable_ownership = (
                    active_user_id === creator_user_id
                    && active_user_id === roleship.member.id
                );

                // only owner can remove other members
                this.removable_member = (
                    active_user_id === creator_user_id
                    && active_user_id !== roleship.member.id
                );

                this.member_leavable = (
                    active_user_id !== creator_user_id
                    && active_user_id === roleship.member.id
                );

                if(this.transferable_ownership) {
                    this.setState((prev_state) => ({
                        ...prev_state,
                        active_user_id,
                        active_employer: new Employer(current_user.active_company),
                        active_role_id: role.id,
                        active_roleship_id: roleship.id,
                        active_member: roleship.member,
                        access_control_list: current_user.employer_roles[current_user.active_company.id].access_control_list,
                        form_data: {
                            title: (roleship.title) ? roleship.title : '',
                            role: role.name,
                            member_to_transfer: -1,
                        },
                        loading: true,
                        error_messages: {},
                    }), () => this.fetchData());
                } else {
                    this.setState((prev_state) => ({
                        ...prev_state,
                        active_user_id,
                        active_employer: new Employer(current_user.active_company),
                        active_role_id: role.id,
                        active_roleship_id: roleship.id,
                        active_member: roleship.member,
                        access_control_list: current_user.employer_roles[current_user.active_company.id].access_control_list,
                        form_data: {
                            title: (roleship.title) ? roleship.title : '',
                            role: role.name,
                            member_to_transfer: -1,
                        },
                        loading: false,
                        error_messages: {},
                    }));
                }
            }
        }
    };

    fetchData() {
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${this.state.active_employer.id}/roles?include=roleships`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let grouped_members = [];
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                const raw_roles = response.data.collection;
                for(let i=0; i<raw_roles.length; i++) {
                    const role = raw_roles[i];
                    const raw_roleships = role.roleships;

                    for(let j=0; j<raw_roleships.length; j++) {
                        const roleship = new Roleship(raw_roleships[j]);
                        const { active_user_id } = this.state;

                        if(roleship.member.id !== active_user_id) {
                            grouped_members.push({
                                value: roleship.member.id,
                                label: roleship.member.formatted_name()
                            });
                        }
                    }
                }

                this.setState((prev_state) => ({
                    ...prev_state,
                    loading: false,
                    error_messages: {},
                    options: {
                        ...prev_state.options,
                        grouped_members
                    }
                }));
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(typeof error.response === 'undefined') {
                    window.location.reload();
                    this.props.updateConnectionOffModal(true);
                }

                this.setState((prev_state) => ({
                    ...prev_state,
                    loading: false,
                    error_messages:
                        (error.response && error.response.data && error.response.data.status === 'error') ?
                            error.response.data.errors :
                            {full_message: 'There was an error. Please try again later.'},
                }));

                page_smothly_scroll_to(0, 300);
            });
    };

    handleInputChange = (e, property_name) => {
        e.preventDefault();
        const value = e.target.value;
        this.setState((prev_state) => ({
            ...prev_state,
            form_data: {
                ...prev_state.form_data,
                [property_name]: value
            }
        }));
    };

    handleSelectChange = (property_name, selected_option) => {
        if(selected_option) {
            this.setState((prev_state) => ({
                form_data: {
                    ...prev_state.form_data,
                    [property_name]: selected_option.value
                }
            }));
        }
    };

    cancelTheAction = e => {
        e.preventDefault();
        this.setState((prev_state) => ({
            ...prev_state,
            message_modal: {
                open: false,
                img_url: '',
                text: '',
                action_type: '',
                the_action: '',
            }
        }));
    };

    removeMember = (e) => {
        e.preventDefault();
        const { active_member } = this.state;
        this.setState((prev_state) => ({
            ...prev_state,
            message_modal: {
                open: true,
                img_url: modal_pop_up_delete,
                text: <Interpolate
                    i18nKey="are_you_sure_to_remove___"
                    useDangerouslySetInnerHTML={true}
                    member_name={active_member.formatted_name()}
                />,
                action_type: 'remove',
                the_action: this.continueRemoveMember,
            }
        }));
    };

    continueRemoveMember = e => {
        e.preventDefault();
        const { deleting, active_employer, active_role_id, active_roleship_id } = this.state;
        if(!deleting) {
            this.setState({ deleting: true });
            let options = {
                method: 'DELETE',
                url: config.api_url(`/employers/${active_employer.id}/roles/${active_role_id}/roleships/${active_roleship_id}`),
                headers: headers.getItemsFromLocalStorage(),
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    this.setState((prev_state) => ({
                        ...prev_state,
                        message_modal: {
                            open: false,
                            text: '',
                            img_url: '',
                            action_type: '',
                            the_action: ''
                        },
                        error_messages: {},
                        deleting: false
                    }), () => {
                        const internal_uri = new InternalUri();
                        this.props.history.replace(`${internal_uri.formatted_dashboard_member_management_page_path()}?refresh=true`);
                        this.props.updateEditMemberModal(false, {}, {}, -1);
                    });
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        deleting: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                })
        }
    };

    leaveEmployer = (e) => {
        e.preventDefault();
        const { active_employer } = this.state;
        this.setState((prev_state) => ({
            ...prev_state,
            message_modal: {
                open: true,
                img_url: modal_pop_up_cancel,
                text: <Interpolate
                    i18nKey="are_you_sure_to_leave___"
                    useDangerouslySetInnerHTML={true}
                    employer_name={active_employer.formatted_name()}
                />,
                action_type: 'leave',
                the_action: this.continueLeaveEmployer,
            }
        }));
    };

    continueLeaveEmployer = e => {
        e.preventDefault();
        const { deleting, active_user_id, active_employer, active_role_id, active_roleship_id } = this.state;
        if(!deleting) {
            this.setState({ deleting: true });
            let options = {
                method: 'DELETE',
                url: config.api_url(`/employers/${active_employer.id}/roles/${active_role_id}/roleships/${active_roleship_id}`),
                headers: headers.getItemsFromLocalStorage(),
                json: true
            };
            let employer_roles = {}, employers = [];
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    options = {
                        method: 'GET',
                        url: config.api_url(`/users/${active_user_id}?include=employer_roles`),
                        headers: headers.getItemsFromLocalStorage(),
                        json: true
                    };

                    return axios(options);
                })
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);

                    let promises_employers = [];
                    const raw_employer_roles = response.data.employer_roles;
                    for(let i=0; i<raw_employer_roles.length; i++) {
                        options = {
                            method: 'GET',
                            url: config.api_url(`/employers/${raw_employer_roles[i].employer_id}?include=addresses,photos`),
                            headers: headers.getItemsFromLocalStorage(),
                            json: true
                        };
                        promises_employers.push(axios(options));
                        employer_roles[raw_employer_roles[i].employer_id] = raw_employer_roles[i];
                    }

                    return axios.all(promises_employers);
                })
                .then((results) => {
                    for(let i=0; i<results.length; i++) {
                        headers.updateItemsToLocalStorage(results[i].headers);
                    }

                    let employer;
                    for(let i=0; i<results.length; i++) {
                        employer = new Employer(results[i].data);
                        employers.push(employer);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        message_modal: {
                            open: false,
                            text: '',
                            img_url: '',
                            action_type: '',
                            the_action: ''
                        },
                        error_messages: {},
                        deleting: false
                    }), () => {
                        const internal_uri = new InternalUri();
                        const { t } = this.props;
                        this.props.history.push(internal_uri.formatted_frontend_index_page_path());
                        this.props.updateEditMemberModal(false, {}, {}, -1);

                        this.props.updateCurrentUserEmployerRoles(employer_roles);
                        this.props.updateCurrentUserCompanies(employers);
                        this.props.updateCurrentUserActiveCompany((employers.length > 0) ? {...employers[0]} : {});
                        this.props.updateCurrentUserIsEmployerUser(employers.length > 0);

                        this.props.addFlashMessage(
                            true,
                            t('flash_messages:you_have_left___', { employer_name: active_employer.formatted_name() }),
                            '',
                            'with-dismiss',
                            ''
                        );
                    });
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        deleting: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                })
        }
    };

    closeModal = e => {
        e.preventDefault();
        this.props.updateEditMemberModal(false, {}, {}, -1);
    };

    submitForm = e => {
        e.preventDefault();

        const { form_data, saving, active_member, active_employer, active_role_id, active_roleship_id } = this.state;
        if(!saving) {
            this.setState({ saving: true });
            let formData = new FormData();
            formData.append('roleship[user_id]', active_member.id);
            formData.append('roleship[title]', form_data.title);

            let options = {
                method: 'PUT',
                url: config.api_url(`/employers/${active_employer.id}/roles/${active_role_id}/roleships/${active_roleship_id}`),
                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                data: formData,
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    if(this.transferable_ownership && form_data.member_to_transfer !== -1) {
                        // go transfer ownership
                        let formData = new FormData();
                        formData.append('user[id]', form_data.member_to_transfer);

                        options = {
                            method: 'PATCH',
                            url: config.api_url(`/employers/${active_employer.id}/change_owner`),
                            headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                            data: formData,
                            json: true
                        };
                        return axios(options);
                    } else {
                        this.setState((prev_state) => ({
                            ...prev_state,
                            form_data: {
                                title: '',
                                role: '',
                                member_to_transfer: -1
                            },
                            error_messages: {},
                            saving: false
                        }), () => {
                            const internal_uri = new InternalUri();
                            this.props.history.replace(`${internal_uri.formatted_dashboard_member_management_page_path()}?refresh=true`);
                            this.props.updateEditMemberModal(false, {}, {}, -1);
                        });

                        return new Promise(() => {});
                    }
                })
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    window.location.reload();
                })
                .catch((error) => {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        saving: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));
                });
        }
    };

    render() {

        const { site_state } = this.props;
        const { form_data, active_user_id, active_member, access_control_list, error_messages, message_modal, saving, options, loading, deleting } = this.state;
        const { handleInputChange, handleSelectChange, cancelTheAction, removeMember, leaveEmployer, closeModal, submitForm } = this;
        const { t } = this.props;

        return (
            <CSSTransitionGroup
                component="div"
                transitionName="modal-wrapper-slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {(site_state.edit_member_modal.open) ?
                    <div className="modal-wrapper edit-member-modal-wrapper">
                        <CSSTransitionGroup
                            component="div"
                            className="modal-inner-wrapper"
                            transitionName="modal-slide"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >
                            <div className="modal-bg" onClick={e => closeModal(e)} />
                            {(!loading) ?
                                <div className="modal edit-member-modal">
                                    <div className="btn-close" onClick={e => closeModal(e)}/>
                                    <div className="modal-header">
                                        <h4 className="modal-title">
                                            {active_member.formatted_name()}
                                            {(active_member.id === active_user_id) ?
                                                <span> ({t('dashboard_member_management_page:you')})</span> : ''
                                            }
                                        </h4>
                                        <h6>{active_member.email}</h6>
                                        {
                                            (this.removable_member) ?
                                                <button
                                                    onClick={ e => removeMember(e) }
                                                    className="btn btn-smallest btn-flat btn-hollow btn-danger"
                                                >
                                                    <h5>{t('remove_member')}</h5>
                                                </button> : ''
                                        }
                                        {
                                            (this.member_leavable) ?
                                                <button
                                                    onClick={ e => leaveEmployer(e) }
                                                    className="btn btn-smallest btn-flat btn-hollow btn-danger"
                                                >
                                                    <h5>{t('general:leave')}</h5>
                                                </button> : ''
                                        }
                                    </div>
                                    <div className="modal-body">
                                        {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                                        <form>
                                            <label htmlFor="member-name-or-title"><h5>{t('dashboard_member_management_page:title')}</h5></label>
                                            <div className="form-row">
                                                {(access_control_list.roles_manageable) ?
                                                    <input
                                                        className="input"
                                                        type="text"
                                                        value={form_data.title}
                                                        onChange={e => handleInputChange(e, 'title')}
                                                        id="title"
                                                    /> :
                                                    <input
                                                        className="input input-readonly"
                                                        type="text"
                                                        value={form_data.title}
                                                        id="title"
                                                        readOnly
                                                    />
                                                }
                                            </div>

                                            <label htmlFor="role"><h5>{t('dashboard_member_management_page:role')}</h5></label>
                                            <div className="form-row">
                                                <input
                                                    className="input input-readonly"
                                                    type="text"
                                                    value={form_data.role}
                                                    id="role"
                                                    readOnly
                                                />
                                            </div>

                                            {(this.transferable_ownership) ?
                                                <div>
                                                    <label htmlFor="transfer_ownership"><h5>{t('transfer_ownership')}</h5></label>
                                                    <div className="form-row">
                                                        <div className="select-wrapper">
                                                            <Select
                                                                className="Select drop-down drop-down-larger drop-down-grey"
                                                                classNamePrefix="Select"
                                                                clearable={false}
                                                                value={form_data.member_to_transfer}
                                                                placeholder={t('select_member')}
                                                                onChange={ value => handleSelectChange('member_to_transfer', value) }
                                                                options={options.grouped_members}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> : ''
                                            }
                                        </form>
                                        {(saving || !contain_not_only_whitespace(form_data.title) || !access_control_list.roles_manageable) ?
                                            <div className="buttons-wrapper">
                                                <button className="btn btn-flat btn-ghost" onClick={e => closeModal(e)}>
                                                    <h5>{t('general:cancel')}</h5>
                                                </button>
                                                <button onClick={ e => e.preventDefault() } className="btn btn-flat btn-fill disabled">
                                                    <h5>{t('general:save')}</h5>
                                                </button>
                                            </div> :
                                            <div className="buttons-wrapper">
                                                <button className="btn btn-flat btn-ghost" onClick={e => closeModal(e)}>
                                                    <h5>{t('general:cancel')}</h5>
                                                </button>
                                                <button className="btn btn-flat btn-fill" onClick={e => submitForm(e)}>
                                                    <h5>{t('general:save')}</h5>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div> : ''
                            }
                        </CSSTransitionGroup>

                        <CSSTransitionGroup
                            component="div"
                            transitionName="modal-wrapper-with-modal-slide"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >
                            {(message_modal.open) ?
                                <div className="modal-wrapper double-confirm-modal-wrapper">
                                    <div className="modal-inner-wrapper">
                                        <div className="modal-bg" />
                                        <div className="modal double-confirm-modal with-pop-up-icon">
                                            <div
                                                className="image-wrapper"
                                                style={{backgroundImage: `url(${message_modal.img_url}`}}
                                            />
                                            <div className="content">
                                                <h4 className="modal-title">{message_modal.text}</h4>
                                                {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                                                <div className="buttons-wrapper">
                                                    {(!deleting) ?
                                                        <button
                                                            className="btn btn-larger btn-flat btn-ghost"
                                                            onClick={ e => cancelTheAction(e) }
                                                        >
                                                            <h5>{t('general:cancel')}</h5>
                                                        </button> :
                                                        <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-ghost disabled">
                                                            <h5>{t('general:cancel')}</h5>
                                                        </button>
                                                    }
                                                    {(!deleting) ?
                                                        <button
                                                            className="btn btn-larger btn-flat btn-fill btn-danger"
                                                            onClick={ e => message_modal.the_action(e) }
                                                        >
                                                            <h5>{t(`general:${message_modal.action_type}`)}</h5>
                                                        </button> :
                                                        <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-fill btn-danger disabled">
                                                            <h5>{t(`general:${message_modal.action_type}`)}</h5>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''
                            }
                        </CSSTransitionGroup>
                    </div> : ''
                }
            </CSSTransitionGroup>
        );
    };
}

export default withNamespaces(['dashboard_modals', 'dashboard_member_management_page', 'flash_messages', 'general'])(EditMemberModal);
