import { createStore, combineReducers, applyMiddleware } from 'redux';
import { site_state, search_words, current_user, ads, utm, ads_cover } from './reducers';
import stateData from '../data/initialState';

//thunk
import thunk from 'redux-thunk';
import { reduxTokenAuthReducer } from 'redux-token-auth';

const clientLogger = store => next => action => {
    let result;
    // console.groupCollapsed("dispatching", action.type);
    // console.log('prev state', store.getState());
    // console.log('action', action);
    result = next(action);
    // console.log('next state', store.getState());
    // console.groupEnd();
    return result;
};

const serverLogger = store => next => action => {
    return next(action);
};

const saver = store => next => action => {
    let result = next(action);
    // localStorage['redux-store'] = JSON.stringify(store.getState());

    let state_from_store = JSON.parse(JSON.stringify(store.getState()));
    let nextStore = {...stateData};

    // 僅存入 site_state.dashboard_sidebar_collapsed、 site_state.flash_message、 reduxTokenAuth、 current_user.accepted_cookie_policy、 current_user.referral_codes 至 localStorage
    nextStore['site_state']['dashboard_sidebar_collapsed'] = state_from_store['site_state']['dashboard_sidebar_collapsed'];
    nextStore['reduxTokenAuth'] = JSON.parse(JSON.stringify(state_from_store['reduxTokenAuth']));
    nextStore['current_user']['accepted_cookie_policy'] = state_from_store['current_user']['accepted_cookie_policy'];
    nextStore['current_user']['referral_codes'] = state_from_store['current_user']['referral_codes'];
    nextStore['current_user']['active_company'] = JSON.parse(JSON.stringify(state_from_store['current_user']['active_company']));
    nextStore['current_user']['cover_ads_dismiss'] = JSON.parse(JSON.stringify(state_from_store['current_user']['cover_ads_dismiss']));
    nextStore['utm'] = JSON.parse(JSON.stringify(state_from_store['utm']));

    localStorage['redux-store'] = JSON.stringify(nextStore);
    return result
};

const middleware = server => [
    (server) ? serverLogger : clientLogger,
    saver,
    thunk
];

const storeFactory = (server = false, initialState = stateData) => {
    let nextState = {...initialState};
    if(!server && typeof localStorage !== 'undefined' && localStorage['redux-store']) {
        let state_from_localstorage = JSON.parse(localStorage['redux-store']);

        // 從 localStorage 中僅取出 site_state.dashboard_sidebar_collapsed、 site_state.flash_message、 reduxTokenAuth、 current_user.accepted_cookie_policy、 current_user.referral_codes
        nextState['site_state']['dashboard_sidebar_collapsed'] = state_from_localstorage['site_state']['dashboard_sidebar_collapsed'] || initialState['site_state']['dashboard_sidebar_collapsed'];
        nextState['reduxTokenAuth'] = JSON.parse(JSON.stringify(state_from_localstorage['reduxTokenAuth'] || initialState['reduxTokenAuth']));
        nextState['current_user']['accepted_cookie_policy'] = state_from_localstorage['current_user']['accepted_cookie_policy'] || initialState['current_user']['accepted_cookie_policy'];
        nextState['current_user']['referral_codes'] = state_from_localstorage['current_user']['referral_codes'] || initialState['current_user']['referral_codes'];
        nextState['current_user']['active_company'] = JSON.parse(JSON.stringify(state_from_localstorage['current_user']['active_company'] || initialState['current_user']['active_company']));
        nextState['current_user']['cover_ads_dismiss'] = JSON.parse(JSON.stringify(state_from_localstorage['current_user']['cover_ads_dismiss'] || initialState['current_user']['cover_ads_dismiss'] ));
        nextState['utm'] = JSON.parse(JSON.stringify(state_from_localstorage['utm'] || initialState['utm']));
    }

    return (
        applyMiddleware(...middleware(server))(createStore)(
            combineReducers({
                site_state,
                search_words,
                current_user,
                ads,
                ads_cover,
                utm,
                reduxTokenAuth: reduxTokenAuthReducer,
            }),
            nextState
        )
    );
};

export default storeFactory;
