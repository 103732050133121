import React from 'react';
import { init, captureException } from '@sentry/browser';
import Config from "../../models/config";
import {withRouter} from "react-router-dom";
import InternalUri from "../../models/internal_uri";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    };

    componentDidMount() {
        const config = new Config();
        init({ dsn: config.sentry_dns() });
    };

    componentDidCatch(error, errorInfo) {
        const internal_uri = new InternalUri();

        captureException(error, { extra: errorInfo });
        this.setState({ error }, () => this.props.history.push(internal_uri.formatted_frontend_error_500_page_path()));
    };

    render() {
        return this.props.children;
    };
}

export default withRouter(ErrorBoundary);