import React from 'react';
import { Redirect, withRouter } from "react-router-dom";
import InternalUri from "../../../models/internal_uri";

const NoMatchPage = props => {

    const internal_uri = new InternalUri();

    // TODO: 三個月後左右可以刪除，for mail legacy 轉址用。
    if(
        props.location.pathname.search('/privacy-policy') !== -1
        || props.location.pathname.search('/cookie-policy') !== -1
        || props.location.pathname.search('/user-agreement') !== -1
        || props.location.pathname.search('/employer-agreement') !== -1
    ) return <Redirect to={internal_uri.formatted_frontend_privacy_and_terms_page_path() + `?type=${props.location.pathname.slice(1)}`} />;

    return <Redirect to={internal_uri.formatted_frontend_error_404_page_path()} />;
};

export default withRouter(NoMatchPage);
