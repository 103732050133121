import Abstract from './abstract';
import Job from './job';
import default_avatar from "../../assets/images/image_default_avatar.svg";
import i18n from "../../../src/i18n";

class User extends Abstract {
    constructor(attrs) {
        super(attrs);

        let raw_referral_jobs = attrs.referral_jobs;
        if(raw_referral_jobs) {
            delete attrs['jobs'];
            this._build_associations({
                name:  'referral_jobs',
                klass: Job,
                attrs: raw_referral_jobs
            });
        }
    };

    formatted_name() {
        if(this.name) return this.name;
        if(!this.first_name && !this.last_name) return this.email;
        let name = '';
        if(this.first_name) name += this.first_name;
        if(this.first_name && this.last_name) name += ' ';
        if(this.last_name) name += this.last_name;
        return name;
    };

    formatted_form_name() {
        if(this.name) return this.name;
        return '';
    }

    formatted_form_first_name() {
        return this.first_name ? this.first_name : '';
    };

    formatted_form_last_name() {
        return this.last_name ? this.last_name : '';
    };

    formatted_form_applier_first_name() {
        if(this.applied_profile) {
            if(this.applied_profile.first_name) {
                return this.applied_profile.first_name;
            }
        }
        return (this.first_name) ? this.first_name : '';
    };

    formatted_form_applier_last_name() {
        if(this.applied_profile) {
            if(this.applied_profile.last_name) {
                return this.applied_profile.last_name;
            }
        }
        return (this.last_name) ? this.last_name : '';
    };

    formatted_referral_code() {
        return (this.referral_code) ? this.referral_code : '(empty)';
    };

    formatted_provider_id() {
        if(!this.provider) return -1;
        if(!this.provider.id) return -1;
        return this.provider.id;
    }

    formatted_avatar_url() {
        if(!this.avatar) return default_avatar;
        if(!this.avatar.url) return default_avatar;
        return this.avatar.url;
    };

    formatted_avatar_applying_url() {
        if(!this.avatar) return default_avatar;
        if(!this.avatar.applying) return default_avatar;
        if(!this.avatar.applying.url) return default_avatar;
        return this.avatar.applying.url;
    };

    formatted_avatar_setting_url() {
        if(!this.avatar) return default_avatar;
        if(!this.avatar.setting) return default_avatar;
        if(!this.avatar.setting.url) return default_avatar;
        return this.avatar.setting.url;
    };

    formatted_avatar_toolbar_url() {
        if(!this.avatar) return default_avatar;
        if(!this.avatar.toolbar) return default_avatar;
        if(!this.avatar.toolbar.url) return default_avatar;
        return this.avatar.toolbar.url;
    };

    formatted_avatar_conversation_url() {
        if(!this.avatar) return default_avatar;
        if(!this.avatar.conversation) return default_avatar;
        if(!this.avatar.conversation.url) return default_avatar;
        return this.avatar.conversation.url;
    };

    formatted_email() {
        return (this.email) ? this.email : i18n.t('general:_not_provided_');
    };

    formatted_form_email() {
        return (this.email) ? this.email : '';
    }

    formatted_form_applier_email() {
        if(this.applied_profile) {
            if(this.applied_profile.email) {
                return this.applied_profile.email;
            }
        }
        return this.formatted_email();
    };

    formatted_phone() {
        return (this.phone) ? this.phone : i18n.t('general:_not_provided_');
    };

    formatted_form_phone() {
        return (this.phone) ? this.phone : '';
    }

    formatted_form_applier_phone() {
        if(this.applied_profile) {
            if(this.applied_profile.phone) {
                return this.applied_profile.phone;
            }
        }
        return this.formatted_form_phone();
    };

    formatted_portfolio_url() {
        if(this.applied_profile) {
            if(this.applied_profile.portfolio_url) {
                return this.applied_profile.portfolio_url;
            }
        }
        return '';
    };

    formatted_notification_setting_marketing_email() {
        if(typeof this.notification_setting == "undefined" || typeof this.notification_setting.marketing_email == "undefined") {
            return true;
        }else{

            return this.notification_setting.marketing_email;
        }
    };

    formatted_notification_setting_job_recommendation_email() {
        if(typeof this.notification_setting == "undefined" || typeof this.notification_setting.jobs_recommendation_email == "undefined") {
            return true;
        }else{
            return this.notification_setting.jobs_recommendation_email;
        }
    };

    formatted_referrer_note() {
        return (this.referrer_note) ? this.referrer_note : '';
    };

    has_default_resume_asset_url() {
        if(!this.default_resume_attachment) return false;
        if(!this.default_resume_attachment.asset) return false;
        if(!this.default_resume_attachment.asset.url) return false;
        return true;
    };

    formatted_default_resume_asset_url() {
        if(this.has_default_resume_asset_url()) {
            return this.default_resume_attachment.asset.url;
        } else {
            return '';
        }
    };



    formatted_default_resume_file_name() {
        if(this.has_default_resume_asset_url()) {
            return this.default_resume_attachment.asset.url.substring(this.default_resume_attachment.asset.url.lastIndexOf('/') + 1);
            // return /[^/]*$/.exec(this.attachment.asset.url)[0];
        } else {
            return i18n.t('general:no_resume___cv_file');
        }
    };



    formatted_attachment_asset_url() {
        if(!this.attachment) return '';
        if(!this.attachment.asset) return '';
        if(!this.attachment.asset.url) return '';
        return this.attachment.asset.url;
    };

    formatted_attachment_asset_filename() {
        if(!this.attachment) return i18n.t('general:no_resume___cv_file');
        if(!this.attachment.asset) return i18n.t('general:no_resume___cv_file');
        if(!this.attachment.asset.filename) return i18n.t('general:no_resume___cv_file');
        return this.attachment.asset.filename;
    };



    has_current_password() {
        if(this.has_password) return true;
        return false;
    };
}

export default User;