import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Element as ScrollElement,
  Link as ScrollLink,
} from "react-scroll/modules";
import axios from "axios";
import { JobInfoWrapper } from "../../../containers/frontend";
import {
  SaveJobButtonWrapper,
  UseReferralUrlWrapper,
} from "../../../containers/general.jsx";
import Job from "../../../models/job.js";
import User from "../../../models/user.js";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers.js";
import InternalUri from "../../../models/internal_uri";
import {
  copy_string,
  get_value_from_query_string,
  page_smothly_scroll_to,
} from "../../../../assets/js/functions";
import { withNamespaces, Interpolate } from "react-i18next";
import { Helmet } from "react-helmet";
import icon_refer_24 from "../../../../assets/images/icon/icon_24_refer_B100.svg";
import icon_refer_48 from "../../../../assets/images/icon/icon_48_refer_BG400.svg";
import icon_refer_48_grey from "../../../../assets/images/icon/icon_48_refer_BL400.svg";
import coin from "../../../../assets/images/coin_single.svg";
import external_redirect from "../../../../assets/images/i032-external.svg";
import Employer from "../../../models/employer";
import { CSSTransitionGroup } from "react-transition-group";
import modal_pop_up_warning from "../../../../assets/images/i050-pop-ups-warning.svg";
import { ReactComponent as IconLink } from "./../../../../assets/images/icon/icon_24_link_BG400.svg";
import { ReactComponent as IconArrow } from "../../../../assets/images/icon/icon_48_arrow_right_B100.svg";
import Invitation from "../../../models/invitation";
import MarketingBanner from "../MarketingBanner";
import { JobCardWrapper } from "../../../containers/general";
import JobCardLoader from "../../general/JobCardLoader";
import BreadCrumb from "../../general/BreadCrumb";
import facebookEvents from "../../../models/facebook_events";
import i18n from "../../../../i18n";
import Ad from "../../../models/ad";

const config = new Config();
const headers = new ApiHeaders();
// const JobPagef = (props) => {
// 	const handleScroll = () => {
// 		// TODO: 其實可以去掉 scrollTop，也可以拉出成 function 共用
// 		const scrollTop =
// 			window.pageYOffset ||
// 			document.body.scrollTop ||
// 			document.documentElement.scrollTop;
// 		const screenHeight =
// 			window.innerHeight ||
// 			document.documentElement.clientHeight ||
// 			document.body.clientHeight;
// 		const scrollBottom = scrollTop + screenHeight;
// 		const element = document.querySelector(".call-to-action-main-wrapper");
// 		if (element) {
// 			const benchmark = element.getBoundingClientRect().top + scrollTop;
// 			if (scrollBottom <= benchmark + 60) {
// 				if (!main.show_mobile_call_to_actions) {
// 					setMain((currentState) => ({
// 						...currentState,
// 						show_mobile_call_to_actions: true,
// 					}));
// 				}
// 			} else {
// 				if (main.show_mobile_call_to_actions) {
// 					setMain((currentState) => ({
// 						...currentState,
// 						show_mobile_call_to_actions: false,
// 					}));
// 				}
// 			}
// 		}
// 	};
// 	const fetchData = (job_id) => {
// 		let job = {},
// 			employer = {};
// 		let options = {
// 			method: "GET",
// 			url: config.api_url(`/jobs/${job_id}?include=required_skills`),
// 			headers: headers.getItemsFromLocalStorage(),
// 			json: true,
// 		};
// 		axios(options)
// 			.then((response) => {
// 				headers.updateItemsToLocalStorage(response.headers);
// 				props.updateConnectionOffModal(false);

// 				if (isMounted.current) {
// 					const raw_job = response.data;
// 					job = new Job(raw_job);

// 					if (job.has_employer()) {
// 						options = {
// 							method: "GET",
// 							url: config.api_url(
// 								`/employers/${job.employer.id}?include=published_jobs`,
// 							),
// 							headers: headers.getItemsFromLocalStorage(),
// 							json: true,
// 						};

// 						return axios(options);
// 					} else {
// 						// is external job
// 						setMain((currentState) => ({
// 							...currentState,
// 							job,
// 							loading: false,
// 							error_messages: {},
// 						}));

// 						return new Promise(() => {});
// 					}
// 				}
// 			})
// 			.then((response) => {
// 				headers.updateItemsToLocalStorage(response.headers);

// 				if (isMounted.current) {
// 					const raw_employer = response.data;
// 					employer = new Employer(raw_employer);

// 					let other_jobs = [];
// 					if (employer.published_jobs && employer.published_jobs.length) {
// 						for (let i = 0; i < employer.published_jobs.length; i++) {
// 							if (employer.published_jobs[i].id !== job.id) {
// 								other_jobs.push(new Job(employer.published_jobs[i]));
// 							}
// 						}
// 					}

// 					const { reduxTokenAuth } = props;
// 					const internal_uri = new InternalUri();
// 					let referral_link = `${
// 						window.location.origin
// 					}${internal_uri.formatted_frontend_job_page_path(job.id, job.slug)}`;
// 					if (reduxTokenAuth.currentUser.isSignedIn) {
// 						referral_link += `?referral_code=${reduxTokenAuth.currentUser.attributes.referral_code}`;
// 						setMain((currentState) => ({
// 							...currentState,
// 							job,
// 							employer,
// 							other_jobs,
// 							referral_link,
// 							loading: false,
// 							loading_pending_invitations: true,
// 							error_messages: {},
// 						}));

// 						fetchAcceptedInvitations(job_id);
// 					} else {
// 						setMain((currentState) => ({
// 							...main,
// 							job,
// 							employer,
// 							other_jobs,
// 							invitations: [],
// 							referral_link,
// 							loading: false,
// 							error_messages: {},
// 						}));
// 					}
// 				}
// 			})
// 			.catch((error) => {
// 				if (error.response) {
// 					headers.updateItemsToLocalStorage(error.response.headers);
// 					const internal_uri = new InternalUri();
// 					if (error.response.status === 401) window.location.reload();
// 					if (error.response.status === 404)
// 						props.history.push(
// 							internal_uri.formatted_frontend_error_404_page_path(),
// 						);
// 				}

// 				if (isMounted.current) {
// 					if (typeof error.response === "undefined")
// 						props.updateConnectionOffModal(true);
// 					setMain((currentState) => ({
// 						...currentState,
// 						loading: false,
// 						error_messages:
// 							error.response &&
// 							error.response.data &&
// 							error.response.data.status === "error"
// 								? error.response.data.errors
// 								: {
// 										full_message: "There was an error. Please try again later.",
// 								  },
// 					}));
// 				}

// 				page_smothly_scroll_to(0, 300);
// 			});
// 	};
// 	const fetchAcceptedInvitations = (job_id) => {
// 		const { reduxTokenAuth } = props;
// 		let invitations = [];
// 		let options = {
// 			method: "GET",
// 			url: config.api_url(
// 				`/users/${reduxTokenAuth.currentUser.attributes.id}/job_applications/accepted_talent_invitations?job_id=${job_id}`,
// 			),
// 			headers: headers.getItemsFromLocalStorage(),
// 			json: true,
// 		};
// 		axios(options)
// 			.then((response) => {
// 				headers.updateItemsToLocalStorage(response.headers);

// 				const raw_invitations = response.data.collection;
// 				for (let i = 0; i < raw_invitations.length; i++) {
// 					invitations.push(
// 						new Invitation({
// 							...raw_invitations[i],
// 							referrer: {
// 								...raw_invitations[i].referrer, // avatar
// 								first_name: raw_invitations[i].referrer_first_name,
// 								last_name: raw_invitations[i].referrer_last_name,
// 							},
// 						}),
// 					);
// 				}
// 				setMain((currentState) => ({
// 					...currentState,
// 					invitations,
// 					loading_pending_invitations: false,
// 				}));
// 			})
// 			.catch((error) => {
// 				if (error.response) {
// 					headers.updateItemsToLocalStorage(error.response.headers);
// 					if (error.response.status === 401) window.location.reload();
// 				}

// 				if (isMounted.current) {
// 					if (typeof error.response === "undefined")
// 						props.updateConnectionOffModal(true);
// 					setMain((currentState) => ({
// 						...currentState,
// 						loading_pending_invitations: false,
// 						error_messages:
// 							error.response &&
// 							error.response.data &&
// 							error.response.data.status === "error"
// 								? error.response.data.errors
// 								: {
// 										full_message: "There was an error. Please try again later.",
// 								  },
// 					}));
// 				}

// 				page_smothly_scroll_to(0, 300);
// 			});
// 	};
// 	const openApplyModal = (e) => {
// 		e.preventDefault();
// 		const { job, referral_user } = main;
// 		if (Object.keys(job).length !== 0) {
// 			props.updateApplyModal(true, { ...job }, { ...referral_user });
// 		}
// 		if (main.applied_reminder_modal.open) {
// 			setMain((currentState) => ({
// 				...currentState,
// 				applied_reminder_modal: {
// 					open: false,
// 				},
// 			}));
// 		}
// 	};
// 	const openLogInModal = (e) => {
// 		e.preventDefault();
// 		props.updateEntranceFormModal("log-in");
// 	};
// 	const updateGoReferModal = (e) => {
// 		e.preventDefault();
// 		setMain((currentState) => ({
// 			...currentState,
// 			go_refer_modal: {
// 				...currentState.go_refer_modal,
// 				open: !currentState.go_refer_modal.open,
// 			},
// 		}));
// 	};
// 	const updateGoRedirectModal = (e) => {
// 		e.preventDefault();
// 		alert("mm");
// 		setMain((currentState) => ({
// 			...currentState,
// 			go_redirect_modal: {
// 				...currentState.go_redirect_modal,
// 				open: !currentState.go_redirect_modal.open,
// 			},
// 		}));
// 	};
// 	const updateAppliedReminderModal = (e) => {
// 		e.preventDefault();
// 		setMain((currentState) => ({
// 			...currentState,
// 			applied_reminder_modal: {
// 				...currentState.applied_reminder_modal,
// 				open: !currentState.applied_reminder_modal.open,
// 			},
// 		}));
// 	};
// 	const updateInviteToApplyModal = (e) => {
// 		e.preventDefault();
// 		const { job } = main;
// 		props.updateInviteToApplyModal(true, { ...job });
// 	};
// 	const handleExternalJobRedirect = (e) => {
// 		setTimeout(() => {
// 			window.location.reload();
// 		}, 5000);
// 	};
// 	const updateJob = (next_job) => {
// 		const job = new Job({
// 			...main.job,
// 			current_user_status: { ...next_job.current_user_status },
// 		});
// 		setMain((currentState) => ({ ...currentState, job }));
// 	};
// 	const handleShareLinkSubMenu = (e) => {
// 		e.preventDefault();
// 		const { reduxTokenAuth } = props;
// 		const { referral_link } = main;
// 		if (reduxTokenAuth.currentUser.isSignedIn) {
// 			handleCopy(e, referral_link);
// 			return;
// 		}
// 		setMain((currentState) => ({
// 			...currentState,
// 			open_share_link_sub_menu: !currentState.open_share_link_sub_menu,
// 		}));
// 	};
// 	const handleShareLinkSubMenuClose = (e) => {
// 		// console.log("handleShareLinkSubMenuClose");
// 		e.stopPropagation();
// 		// e.preventDefault();
// 		setMain((currentState) => ({
// 			...currentState,
// 			open_share_link_sub_menu: false,
// 		}));
// 	};
// 	const handleSharing = (e, href) => {
// 		e.preventDefault();
// 		window.open(
// 			href,
// 			"",
// 			"left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0",
// 		);
// 		setMain((currentState) => ({
// 			open_share_link_sub_menu: false,
// 			...currentState,
// 		}));
// 	};
// 	const handleCopy = (e, string) => {
// 		e.preventDefault();
// 		copy_string(e, string);
// 		setMain((currentState) => ({
// 			...currentState,
// 			open_share_link_sub_menu: false,
// 		}));
// 		props.addFlashMessage(
// 			true,
// 			<Interpolate
// 				i18nKey="flash_messages:link_copied_"
// 				useDangerouslySetInnerHTML={true}
// 			/>,
// 			"",
// 			"with-auto-disappear",
// 			"",
// 		);
// 	};
// 	const handleOnClick = (e) => {
// 		// e.preventDefault();
// 		const target = e.target.outerHTML;
// 		const share_wrapper = document.querySelector(".btn-share-wrapper");
// 		if (
// 			!share_wrapper ||
// 			(share_wrapper && share_wrapper.outerHTML.indexOf(target) === -1)
// 		) {
// 			if (main.open_share_link_sub_menu) handleShareLinkSubMenu(e);
// 		}
// 	};
// 	const isMounted = useRef(false);
// 	const [main, setMain] = useState({
// 		job: {},
// 		employer: {},
// 		other_jobs: [],
// 		invitations: [],
// 		referral_user: {},
// 		open_share_link_sub_menu: false,
// 		referral_link: "",
// 		go_refer_modal: {
// 			open: false,
// 		},
// 		go_redirect_modal: {
// 			open: false,
// 		},
// 		applied_reminder_modal: {
// 			open: false,
// 		},
// 		show_mobile_call_to_actions: true,
// 		loading: true,
// 		error_messages: {},
// 		facebook_app_id: config.config.facebook_app_id,
// 	});
// 	useEffect(() => {
// 		// componentDidMount
// 		if (!isMounted.current) {
// 			isMounted.current = true;
// 			new facebookEvents({
// 				eventName: "ViewContent",
// 			});
// 			window.addEventListener("scroll", handleScroll);

// 			const query = decodeURI(props.location.search);
// 			if (query) {
// 				let referral_code = get_value_from_query_string("referral_code", query);
// 				if (referral_code) {
// 					let formData = new FormData();
// 					formData.append("referral_code", referral_code);
// 					let options = {
// 						method: "POST",
// 						url: config.api_url("/users/referral_info"),
// 						headers: headers.getItemsFromLocalStorage("multipart/form-data"),
// 						data: formData,
// 						json: true,
// 					};
// 					axios(options)
// 						.then((response) => {
// 							headers.updateItemsToLocalStorage(response.headers);
// 							props.updateConnectionOffModal(false);

// 							if (isMounted.current) {
// 								const raw_referral_user = response.data;
// 								const referral_user = new User({
// 									...raw_referral_user,
// 									referral_code,
// 								});
// 								setMain((currentState) => ({ ...currentState, referral_user }));
// 							}
// 						})
// 						.catch((error) => {
// 							if (error.response) {
// 								headers.updateItemsToLocalStorage(error.response.headers);
// 								if (error.response.status === 401) window.location.reload();
// 							}

// 							if (isMounted.current) {
// 								if (typeof error.response === "undefined")
// 									props.updateConnectionOffModal(true);
// 								setMain((currentState) => ({
// 									...currentState,
// 									error_messages:
// 										error.response &&
// 										error.response.data &&
// 										error.response.data.status === "error"
// 											? error.response.data.errors
// 											: {
// 													full_message:
// 														"There was an error. Please try again later.",
// 											  },
// 								}));
// 								page_smothly_scroll_to(0, 300);
// 							}
// 						});
// 				}
// 			}

// 			const job_id = props.match.params.id_slug.split("-")[0];
// 			fetchData(job_id);
// 		}
// 		return () => {
// 			isMounted.current = false;
// 			window.removeEventListener("scroll", handleScroll);
// 		};
// 	}, []);
// 	// callback
// 	useEffect(() => {
// 		// console.log("z");
// 		if (isMounted.current) {
// 			const query = decodeURI(props.location.search);
// 			let referral_code = get_value_from_query_string("referral_code", query);
// 			props.addCurrentUserReferralCode(referral_code);
// 			const job_id = props.match.params.id_slug.split("-")[0];
// 			// fetchData(job_id);
// 		}
// 	}, [main]);

// 	//UNSAFE_componentWillReceiveProps
// 	let preprops = props;
// 	useEffect(() => {
// 		// console.log("x");
// 		if (isMounted.current) {
// 			const job_id = props.match.params.id_slug.split("-")[0];
// 			if (job_id !== preprops.match.params.id_slug.split("-")[0]) {
// 				setMain((currentState) => ({ ...currentState, loading: false }));
// 			}
// 			const { reduxTokenAuth } = props;
// 			if (
// 				reduxTokenAuth.currentUser.isSignedIn !==
// 				preprops.reduxTokenAuth.currentUser.isSignedIn
// 			) {
// 				setMain((currentState) => ({ ...currentState, loading: false }));
// 			}

// 			let refresh = get_value_from_query_string(
// 				"refresh",
// 				props.location.search,
// 			);
// 			if (refresh === "true") {
// 				const { job } = main;
// 				const internal_uri = new InternalUri();
// 				props.history.replace(
// 					internal_uri.formatted_frontend_job_page_path(job.id, job.slug),
// 				);
// 				fetchData(job_id);
// 			}
// 			preprops = props;
// 		}
// 	}, [props]);
// 	const {
// 		job,
// 		employer,
// 		other_jobs,
// 		invitations,
// 		go_refer_modal,
// 		go_redirect_modal,
// 		applied_reminder_modal,
// 		referral_user,
// 		open_share_link_sub_menu,
// 		referral_link,
// 		show_mobile_call_to_actions,
// 		loading,
// 		error_messages,
// 	} = main;
// 	const { reduxTokenAuth, t } = props;
// 	const internal_uri = new InternalUri();

// 	// render
// 	if (loading || !Object.keys(job).length) {
// 		return (
// 			<div className="loading-skeleton-wrapper">
// 				<div className="page-job content-wrapper with-gradient blue-to-cyan">
// 					<div className="container">
// 						<div className="row">
// 							<div className="col-md-8">
// 								<div className="tablet-wrapper">
// 									<JobInfoWrapper loading={true} />
// 									<h4 className="title-outside">
// 										{t("other_jobs_from_this_employer")}
// 									</h4>
// 									<div className="job-cards">
// 										<JobCardLoader />
// 										<JobCardLoader />
// 									</div>
// 								</div>
// 							</div>

// 							<div className="col-md-4">
// 								<div className="tablet-wrapper">
// 									<div className="call-to-action-main-wrapper block">
// 										<div className="call-to-action-main">
// 											<button className="btn btn-larger btn-flat btn-fill">
// 												<h5>{t("general:apply")}</h5>
// 											</button>
// 											<button className="btn btn-larger">
// 												<h5>{t("general:save")}</h5>
// 											</button>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>

// 					<div className="mobile-call-to-action-wrapper">
// 						<div className="container">
// 							<div className="mobile-call-to-action without-featured">
// 								<button className="btn btn-larger btn-flat btn-fill">
// 									<h5>{t("general:apply")}</h5>
// 								</button>
// 								<div className="subscribe subscribe-save-grey-48" />
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}

// 	return (
// 		<div
// 			className="page-job content-wrapper with-gradient blue-to-cyan"
// 			onClick={(e) => handleOnClick(e)}>
// 			<Helmet>
// 				<title>
// 					{job.formatted_title()} - {job.formatted_employer_name()}｜Meet.jobs
// 				</title>
// 			</Helmet>
// 			<UseReferralUrlWrapper />
// 			<div className="container">
// 				{error_messages.full_message ? (
// 					<h6 className="message error-message">
// 						{error_messages.full_message}
// 					</h6>
// 				) : (
// 					""
// 				)}
// 				{job.is_closed() ? (
// 					<div className="system-message-wrapper warning">
// 						<p>{t("this_job_is_no_longer_accepting_applicants_")}</p>
// 						<Link
// 							to={internal_uri.formatted_frontend_jobs_page_path()}
// 							className="btn btn-flat btn-fill"
// 							target="_blank"
// 							rel="noopener noreferrer">
// 							<h5>{t("browse_more_jobs")}</h5>
// 						</Link>
// 					</div>
// 				) : (
// 					""
// 				)}
// 				{job.is_draft() ? (
// 					<div className="system-message-wrapper warning">
// 						<p>
// 							{t(
// 								"this_job_is_in_the_process_of_editing_and_not_accepting_applicants_",
// 							)}
// 						</p>
// 					</div>
// 				) : (
// 					""
// 				)}
// 				<div className="tablet-wrapper">
// 					<BreadCrumb
// 						items={[
// 							{
// 								text: t("general:home"),
// 								uriType: "internal",
// 								uri: internal_uri.formatted_frontend_index_page_path(),
// 								eventCategory: "314_breadcrumb_home",
// 							},
// 							{
// 								text: job.formatted_employer_name(),
// 								uriType: job.has_employer()
// 									? "internal"
// 									: job.external_employer_url
// 									? "external"
// 									: null,
// 								uri: job.has_employer()
// 									? `${internal_uri.formatted_frontend_employer_page_path(
// 											employer.id,
// 											employer.slug,
// 									  )}${
// 											Object.keys(referral_user).length
// 												? `?referral_code=${referral_user.referral_code}`
// 												: ""
// 									  }`
// 									: job.external_employer_url
// 									? job.external_employer_url
// 									: null,
// 								eventCategory: "315_breadcrumb_employer",
// 							},
// 							{
// 								text: job.formatted_title(),
// 							},
// 						]}
// 					/>
// 				</div>
// 				<div className="row">
// 					<div className="col-md-8">
// 						<div className="tablet-wrapper">
// 							{job.has_event() && job.events[0].get_banner_url() ? (
// 								<div>
// 									<div className="event-marketing-wrapper tl">
// 										<MarketingBanner
// 											type="horizontal"
// 											url={job.events[0].get_url()}
// 											banner_url={job.events[0].get_banner_url()}
// 											content={t(job.events[0].get_i18n_key())}
// 											// parameters={{...this.eventMarketingParameters, utm_campaign: t(job.events[0].get_i18n_key())}}
// 											eventCategory="311_marketing_job_page"
// 										/>
// 									</div>
// 									<div className="event-marketing-wrapper mb">
// 										<MarketingBanner
// 											type=""
// 											url={job.events[0].get_url()}
// 											banner_url={job.events[0].get_banner_url()}
// 											content={t(job.events[0].get_i18n_key())}
// 											// parameters={{...this.eventMarketingParameters, utm_campaign: t(job.events[0].get_i18n_key())}}
// 											eventCategory="311_marketing_job_page"
// 										/>
// 									</div>
// 								</div>
// 							) : (
// 								""
// 							)}
// 							<div className="invitations-wrapper mb">
// 								{invitations.length ? (
// 									<div className="invitations">
// 										{invitations.map((invitation, i) => (
// 											<Link
// 												className="invitation-wrapper"
// 												to={`${internal_uri.formatted_frontend_job_invitation_page_path(
// 													invitation.id,
// 												)}?job_id=${invitation.job.id}`}
// 												key={i}>
// 												<div className="invitation">
// 													<h3>{t("you_have_a_pending_invitation")}</h3>
// 													<div className="referrer-wrapper">
// 														<div className="referrer">
// 															<div className="referrer-inner-wrapper">
// 																<div className="avatar">
// 																	<div
// 																		className="image-wrapper"
// 																		style={{
// 																			backgroundImage: `url(${invitation.referrer.formatted_avatar_url()})`,
// 																		}}
// 																	/>
// 																</div>
// 																<h5>{invitation.referrer.formatted_name()}</h5>
// 																<IconArrow className="icon-arrow" />
// 															</div>
// 														</div>
// 													</div>
// 												</div>
// 											</Link>
// 										))}
// 									</div>
// 								) : (
// 									""
// 								)}
// 							</div>
// 							<JobInfoWrapper
// 								job={{ ...job }}
// 								employer={{ ...employer }}
// 								referral_user={{ ...referral_user }}
// 								loading={loading}
// 								show_event
// 								eventCategories={{
// 									headEmployer: "307_link_employer",
// 									headLocation: "308_link_location",
// 									requiredSkill: "309_link_skill_tag",
// 									employerInfo: "312_employer_info",
// 								}}
// 							/>
// 							{job.has_employer() ? (
// 								<div>
// 									<h4 className="title-outside">
// 										{t("other_jobs_from_this_employer")}
// 									</h4>
// 									<div className="job-cards">
// 										{other_jobs.length === 0 ? (
// 											<p>{t("no_other_jobs_")}</p>
// 										) : (
// 											other_jobs.map((job, i) => (
// 												<JobCardWrapper
// 													key={i}
// 													job={job}
// 													referral_code={
// 														Object.keys(referral_user).length
// 															? referral_user.referral_code
// 															: null
// 													}
// 													eventCategories={{
// 														whole: "313_employer_other_jobs",
// 														employer: "325_card_job_employer",
// 														location: "326_card_job_location",
// 														save: "327_card_job_save",
// 													}}
// 												/>
// 											))
// 										)}
// 									</div>
// 								</div>
// 							) : (
// 								""
// 							)}
// 						</div>
// 					</div>

// 					<div className="col-md-4">
// 						<div className="tablet-wrapper">
// 							{job.has_event() && job.events[0].get_banner_url() ? (
// 								<div className="event-marketing-wrapper pc">
// 									<MarketingBanner
// 										type=""
// 										url={job.events[0].get_url()}
// 										banner_url={job.events[0].get_banner_url()}
// 										content={t(job.events[0].get_i18n_key())}
// 										// parameters={{...this.eventMarketingParameters, utm_campaign: t(job.events[0].get_i18n_key())}}
// 										eventCategory="311_marketing_job_page"
// 									/>
// 								</div>
// 							) : (
// 								""
// 							)}
// 							<div className="invitations-wrapper pc">
// 								{invitations.length ? (
// 									<div className="invitations">
// 										{invitations.map((invitation, i) => (
// 											<Link
// 												className="invitation-wrapper"
// 												to={`${internal_uri.formatted_frontend_job_invitation_page_path(
// 													invitation.id,
// 												)}?job_id=${invitation.job.id}`}
// 												key={i}>
// 												<div className="invitation">
// 													<h3>{t("you_have_a_pending_invitation")}</h3>
// 													<div className="referrer-wrapper">
// 														<div className="referrer">
// 															<div className="referrer-inner-wrapper">
// 																<div className="avatar">
// 																	<div
// 																		className="image-wrapper"
// 																		style={{
// 																			backgroundImage: `url(${invitation.referrer.formatted_avatar_url()})`,
// 																		}}
// 																	/>
// 																</div>
// 																<h5>{invitation.referrer.formatted_name()}</h5>
// 																<IconArrow className="icon-arrow" />
// 															</div>
// 														</div>
// 													</div>
// 												</div>
// 											</Link>
// 										))}
// 									</div>
// 								) : (
// 									""
// 								)}
// 							</div>

// 							<ScrollElement name="call_to_actions">
// 								<div
// 									className="call-to-action-main-wrapper block coins-animation-trigger"
// 									onMouseEnter={() => {
// 										let coinsAnimation = document.querySelector(
// 											"#coins-animation",
// 										);
// 										if (coinsAnimation) coinsAnimation.className = "jumped-in";
// 									}}
// 									onMouseLeave={() => {
// 										let coinsAnimation = document.querySelector(
// 											"#coins-animation",
// 										);
// 										if (coinsAnimation) coinsAnimation.className = "jumped-out";
// 									}}
// 									onMouseOver={() => {
// 										let coinsAnimation = document.querySelector(
// 											"#coins-animation",
// 										);
// 										if (
// 											coinsAnimation &&
// 											!document.body.classList.contains("jumped-in")
// 										)
// 											coinsAnimation.className = "jumped-in";
// 									}}>
// 									{Object.keys(referral_user).length ? (
// 										<div className="referral-user-wrapper">
// 											<h6>{t("referred_by")}</h6>
// 											<div className="referral-user">
// 												<div className="avatar">
// 													<div
// 														className="image-wrapper"
// 														style={{
// 															backgroundImage: `url(${referral_user.formatted_avatar_applying_url()})`,
// 														}}
// 													/>
// 												</div>
// 												<p>{referral_user.formatted_name()}</p>
// 											</div>
// 										</div>
// 									) : (
// 										""
// 									)}
// 									{reduxTokenAuth.currentUser.isSignedIn &&
// 									job.is_current_user_ever_applied() ? (
// 										<h6 className="applied-reminder">
// 											<Interpolate
// 												i18nKey="you_have_applied_on___"
// 												useDangerouslySetInnerHTML={true}
// 												applied_at={job.formatted_current_user_applied_time()}
// 											/>
// 										</h6>
// 									) : (
// 										""
// 									)}
// 									<div className="call-to-action-main">
// 										{job.is_closed() || job.is_draft() ? (
// 											<button
// 												onClick={(e) => e.preventDefault()}
// 												className="btn btn-larger btn-flat btn-fill disabled">
// 												<h5>{t("general:apply")} </h5>
// 											</button>
// 										) : reduxTokenAuth.currentUser.isSignedIn ? (
// 											job.is_current_user_ever_applied() ? (
// 												<button
// 													className="btn btn-larger btn-flat btn-fill"
// 													onClick={(e) => updateAppliedReminderModal(e)}
// 													data-event-category="301_apply_job_page">
// 													<h5>{t("general:apply")}</h5>
// 												</button>
// 											) : job.has_employer() ? (
// 												<button
// 													className="btn btn-larger btn-flat btn-fill"
// 													onClick={(e) => openApplyModal(e)}
// 													data-event-category="301_apply_job_page"
// 													data-event="job_apply_click_apply_signed_in">
// 													<h5>{t("general:apply")}</h5>
// 												</button>
// 											) : (
// 												<a
// 													className="btn btn-larger btn-flat btn-fill"
// 													href={`${internal_uri.formatted_external_job_redirect_page_path()}?job_id=${
// 														job.id
// 													}&user_id=${
// 														reduxTokenAuth.currentUser.attributes.id
// 													}`}
// 													target="_blank"
// 													rel="noopener noreferrer"
// 													onClick={(e) => handleExternalJobRedirect(e)}
// 													data-event-category="301_apply_job_page"
// 													data-event="job_apply_click_apply_signed_in">
// 													<h5>{t("general:apply")}</h5>
// 												</a>
// 											)
// 										) : (
// 											<button
// 												className="btn btn-larger btn-flat btn-fill"
// 												onClick={(e) => openLogInModal(e)}
// 												data-event-category="301_apply_job_page"
// 												data-event="job_apply_click_apply_not_signed_in">
// 												<h5>{t("general:apply")}</h5>
// 											</button>
// 										)}
// 										<SaveJobButtonWrapper
// 											type="button"
// 											color="blue"
// 											size="24"
// 											job={job}
// 											updateJob={updateJob}
// 											eventCategory="302_save_job_page"
// 										/>
// 										{job.is_referralable() ? (
// 											<h6
// 												dangerouslySetInnerHTML={{
// 													__html: t(
// 														"get___reward_from_meet_jobs_when_you_are_successfully_hired_",
// 													),
// 												}}
// 											/>
// 										) : (
// 											""
// 										)}
// 										{!job.has_employer() ? (
// 											<div className="external-job-applying-description">
// 												<img src={external_redirect} alt="" />
// 												<h3>{t("we_will_redirect_you")}</h3>
// 												<h6
// 													dangerouslySetInnerHTML={{
// 														__html: t("redirect_description"),
// 													}}
// 												/>
// 											</div>
// 										) : (
// 											""
// 										)}
// 									</div>
// 									{job.is_referralable() ? (
// 										<div id="coins-animation">
// 											<img className="coin" src={coin} alt="" />
// 											<img className="coin" src={coin} alt="" />
// 											<img className="coin" src={coin} alt="" />
// 										</div>
// 									) : (
// 										""
// 									)}
// 								</div>

// 								{job.is_referralable() ? (
// 									<div className="call-to-action-refer-wrapper block">
// 										<div className="call-to-action-refer">
// 											<h3>{t("general:referral_reward")}</h3>
// 											<p>
// 												<Interpolate
// 													i18nKey="refer_to_a_friend_and_get___reward_with_successful_hire_"
// 													useDangerouslySetInnerHTML={true}
// 													reward={
// 														<span className="reward">
// 															{job.formatted_reward()}
// 														</span>
// 													}
// 												/>
// 											</p>
// 											{job.is_closed() || job.is_draft() ? (
// 												<button
// 													onClick={(e) => e.preventDefault()}
// 													className="btn btn-larger btn-flat btn-hollow btn-with-icon disabled">
// 													<h5>
// 														{/*<img src={icon_refer_24_grey} alt="" />*/}
// 														{t("general:invite_to_apply")}
// 													</h5>
// 												</button>
// 											) : reduxTokenAuth.currentUser.isSignedIn ? (
// 												<div>
// 													<button
// 														className={`btn btn-larger btn-flat btn-hollow mr-2 ${
// 															i18n.language === "zh-TW" ? "" : "lg:full-btn"
// 														}`}
// 														onClick={(e) => handleShareLinkSubMenu(e)}
// 														data-event-category="351_invite_to_apply"
// 														data-event="jobRefer_submit_for_friend_not_signed_in">
// 														<h5>{t("general:share_link")}</h5>
// 													</button>
// 													<button
// 														className={`btn btn-larger btn-flat btn-hollow mr-2 ${
// 															i18n.language === "zh-TW" ? "" : "lg:full-btn"
// 														}`}
// 														onClick={(e) => updateInviteToApplyModal(e)}
// 														data-event-category="351_invite_to_apply"
// 														data-event="jobRefer_submit_for_friend_signed_in">
// 														<h5>{t("general:submit_for_friend")}</h5>
// 													</button>
// 												</div>
// 											) : (
// 												<div>
// 													<button
// 														className={`btn btn-larger btn-flat btn-hollow mr-2 className={\`${
// 															i18n.language === "zh-TW" ? "" : "lg:text-sm"
// 														}\`}`}
// 														onClick={(e) => handleShareLinkSubMenu(e)}
// 														data-event-category="351_invite_to_apply">
// 														<h5>
// 															{/*<img src={icon_refer_24} alt=""/>*/}
// 															{t("general:share_link")}
// 														</h5>
// 													</button>
// 													<button
// 														className="btn btn-larger btn-flat btn-hollow"
// 														onClick={(e) => openLogInModal(e)}
// 														data-event-category="351_invite_to_apply">
// 														<h5
// 															className={`${
// 																i18n.language === "zh-TW" ? "" : "lg:text-sm"
// 															}`}>
// 															{t("general:submit_for_friend")}
// 														</h5>
// 													</button>
// 												</div>
// 											)}
// 										</div>
// 									</div>
// 								) : (
// 									""
// 								)}
// 							</ScrollElement>

// 							<div className="marketing-materials-wrapper">
// 								{!job.has_event() && props.ads && props.ads.length
// 									? props.ads.map((raw_ad, i) => {
// 											const ad = new Ad(raw_ad);
// 											return (
// 												<MarketingBanner
// 													key={i}
// 													type="vertical"
// 													id={ad.id}
// 													url={ad.get_url()}
// 													banner_url={ad.get_img_url()}
// 													content={ad.formatted_title()}
// 													// parameters={{...this.eventMarketingParameters, utm_campaign: ad.formatted_title()}}
// 													eventCategory="216_marketing_search"
// 												/>
// 											);
// 									  })
// 									: ""}
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>

// 			<CSSTransitionGroup
// 				component="div"
// 				transitionName="fade-in-out"
// 				transitionEnterTimeout={300}
// 				transitionLeaveTimeout={300}>
// 				{show_mobile_call_to_actions ? (
// 					<div className="mobile-call-to-action-wrapper">
// 						<div className="container">
// 							<div
// 								className={`mobile-call-to-action ${
// 									job.is_referralable() ? "" : "without-featured"
// 								}`}>
// 								{Object.keys(referral_user).length ? (
// 									<div className="referrer-wrapper">
// 										<ScrollLink
// 											to="call_to_actions"
// 											spy={true}
// 											smooth={true}
// 											offset={-80}
// 											duration={400}>
// 											<div className="referrer">
// 												<div className="avatar">
// 													<div
// 														className="image-wrapper"
// 														style={{
// 															backgroundImage: `url(${referral_user.formatted_avatar_url()})`,
// 														}}
// 													/>
// 												</div>
// 												<h6>{referral_user.formatted_name()}</h6>
// 											</div>
// 										</ScrollLink>
// 									</div>
// 								) : (
// 									""
// 								)}
// 								{job.is_closed() || job.is_draft() ? (
// 									<button
// 										onClick={(e) => e.preventDefault()}
// 										className="btn btn-larger btn-flat btn-fill disabled">
// 										<h5>{t("general:apply")}</h5>
// 									</button>
// 								) : reduxTokenAuth.currentUser.isSignedIn ? (
// 									job.is_current_user_ever_applied() ? (
// 										<button
// 											className="btn btn-larger btn-flat btn-fill"
// 											onClick={(e) => updateAppliedReminderModal(e)}
// 											data-event-category="301_apply_job_page">
// 											<h5>{t("general:apply")}</h5>
// 										</button>
// 									) : job.has_employer() ? (
// 										<button
// 											className="btn btn-larger btn-flat btn-fill"
// 											onClick={(e) => openApplyModal(e)}
// 											data-event-category="301_apply_job_page">
// 											<h5>{t("general:apply")}</h5>
// 										</button>
// 									) : (
// 										<button
// 											className="btn btn-larger btn-flat btn-fill"
// 											onClick={(e) => updateGoRedirectModal(e)}
// 											data-event-category="301_apply_job_page">
// 											<h5>{t("general:apply")}</h5>
// 										</button>
// 									)
// 								) : (
// 									<button
// 										className="btn btn-larger btn-flat btn-fill"
// 										onClick={(e) => openLogInModal(e)}
// 										data-event-category="301_apply_job_page">
// 										<h5>{t("general:apply")}</h5>
// 									</button>
// 								)}
// 								{job.is_referralable() ? (
// 									job.is_closed() || job.is_draft() ? (
// 										<img className="disabled" src={icon_refer_48_grey} alt="" />
// 									) : (
// 										<img
// 											src={icon_refer_48}
// 											alt=""
// 											onClick={(e) => handleShareLinkSubMenu(e)}
// 											data-event-category="362_icon_invite_to_apply_mobile"
// 										/>
// 									)
// 								) : (
// 									""
// 								)}
// 								<SaveJobButtonWrapper
// 									color="grey"
// 									size="48"
// 									job={{ ...job }}
// 									updateJob={updateJob}
// 									eventCategory="302_save_job_page"
// 								/>
// 							</div>
// 						</div>
// 					</div>
// 				) : (
// 					""
// 				)}
// 			</CSSTransitionGroup>

// 			<CSSTransitionGroup
// 				component="div"
// 				transitionName="modal-wrapper-with-modal-slide"
// 				transitionEnterTimeout={300}
// 				transitionLeaveTimeout={300}>
// 				{open_share_link_sub_menu ? (
// 					<div className="modal-wrapper share-modal">
// 						<div className="modal-inner-wrapper">
// 							<div
// 								className="modal-bg"
// 								onClick={(e) => handleShareLinkSubMenuClose(e)}
// 							/>
// 							<div className="modal double-confirm-modal with-pop-up-icon">
// 								<div
// 									className="btn-close"
// 									onClick={(e) => handleShareLinkSubMenuClose(e)}></div>
// 								<div className="content">
// 									<div className="sub-menu">
// 										<h2>
// 											<img src={icon_refer_24} alt="" />{" "}
// 											{t("general:invite_to_apply")}
// 										</h2>
// 										{!reduxTokenAuth.currentUser.isSignedIn ? (
// 											<div className="warning">
// 												<h6>
// 													<Interpolate
// 														i18nKey="if_you_refer_without___you_will_not_get_any_referral_reward_"
// 														useDangerouslySetInnerHTML={true}
// 														log_in={
// 															<button
// 																className="link"
// 																onClick={(e) => openLogInModal(e)}>
// 																{t("general:log_in")}
// 															</button>
// 														}
// 													/>
// 												</h6>
// 											</div>
// 										) : (
// 											""
// 										)}
// 										<ul>
// 											<li className="li-link">
// 												<button
// 													onClick={(e) => handleCopy(e, referral_link)}
// 													data-event-category="303_share_copy_link">
// 													<IconLink />
// 													<h6>{t("general:copy_link")}</h6>
// 												</button>
// 											</li>
// 										</ul>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				) : (
// 					""
// 				)}

// 				{go_refer_modal.open ? (
// 					<div className="modal-wrapper go-refer-modal-wrapper">
// 						<div className="modal-inner-wrapper">
// 							<div
// 								className="modal-bg"
// 								onClick={(e) => updateGoReferModal(e)}
// 							/>
// 							<div className="modal go-refer-modal">
// 								<div
// 									className="btn-close white"
// 									onClick={(e) => updateGoReferModal(e)}
// 								/>
// 								<h3>{t("general:referral_reward")}</h3>
// 								<p>
// 									<Interpolate
// 										i18nKey="refer_to_a_friend_and_get___reward_with_successful_hire_"
// 										useDangerouslySetInnerHTML={true}
// 										reward={
// 											<span className="reward">{job.formatted_reward()}</span>
// 										}
// 									/>
// 								</p>
// 								{reduxTokenAuth.currentUser.isSignedIn ? (
// 									<button
// 										className="btn btn-larger btn-flat btn-fill btn-with-icon"
// 										onClick={(e) => updateInviteToApplyModal(e)}
// 										data-event-category="351_invite_to_apply">
// 										<h5>
// 											<img src={icon_refer_24} alt="" />
// 											{t("general:invite_to_apply")}
// 										</h5>
// 									</button>
// 								) : (
// 									<button
// 										className="btn btn-larger btn-flat btn-fill btn-with-icon"
// 										onClick={(e) => openLogInModal(e)}
// 										data-event-category="351_invite_to_apply">
// 										<h5>
// 											<img src={icon_refer_24} alt="" />
// 											{t("general:invite_to_apply")}
// 										</h5>
// 									</button>
// 								)}
// 							</div>
// 						</div>
// 					</div>
// 				) : (
// 					""
// 				)}
// 				{go_redirect_modal.open ? (
// 					<div className="modal-wrapper go-redirect-modal-wrapper">
// 						<div className="modal-inner-wrapper">
// 							<div
// 								className="modal-bg"
// 								onClick={(e) => updateGoRedirectModal(e)}
// 							/>
// 							<div className="modal go-redirect-modal">
// 								{!reduxTokenAuth.currentUser.isSignedIn ? (
// 									<div className="warning">
// 										<h6>
// 											<Interpolate
// 												i18nKey="if_you_refer_without___you_will_not_get_any_referral_reward_"
// 												useDangerouslySetInnerHTML={true}
// 												log_in={
// 													<button
// 														className="link"
// 														onClick={(e) => openLogInModal(e)}>
// 														{t("general:log_in")}
// 													</button>
// 												}
// 											/>
// 										</h6>
// 									</div>
// 								) : (
// 									""
// 								)}
// 								<ul>
// 									<li className="li-link">
// 										<button
// 											onClick={(e) => handleCopy(e, referral_link)}
// 											data-event-category="303_share_copy_link">
// 											<IconLink />
// 											<h6>{t("general:copy_link")}</h6>
// 										</button>
// 									</li>
// 								</ul>
// 								<img src={external_redirect} alt="" />
// 								<h3>{t("we_will_redirect_you")}</h3>
// 								<h6
// 									dangerouslySetInnerHTML={{
// 										__html: t("redirect_description"),
// 									}}
// 								/>
// 								<a
// 									className="btn btn-larger btn-flat btn-fill"
// 									href={`${internal_uri.formatted_external_job_redirect_page_path()}?job_id=${
// 										job.id
// 									}&user_id=${reduxTokenAuth.currentUser.attributes.id}`}
// 									target="_blank"
// 									rel="noopener noreferrer"
// 									onClick={(e) => handleExternalJobRedirect(e)}>
// 									<h5>{t("general:continue")}</h5>
// 								</a>
// 							</div>
// 						</div>
// 					</div>
// 				) : (
// 					""
// 				)}
// 				{applied_reminder_modal.open ? (
// 					<div className="modal-wrapper double-confirm-modal-wrapper">
// 						<div className="modal-inner-wrapper">
// 							<div
// 								className="modal-bg"
// 								onClick={(e) => updateAppliedReminderModal(e)}
// 							/>
// 							<div className="modal double-confirm-modal with-pop-up-icon">
// 								<div
// 									className="image-wrapper"
// 									style={{ backgroundImage: `url(${modal_pop_up_warning}` }}
// 								/>
// 								<div className="content">
// 									<h4 className="modal-title">
// 										{t("you_have_applied_before")}
// 									</h4>
// 									<p>
// 										<Interpolate
// 											i18nKey="applied_reminder_description"
// 											useDangerouslySetInnerHTML={true}
// 											applied_at={job.formatted_current_user_applied_time()}
// 										/>
// 									</p>
// 									<div className="buttons-wrapper">
// 										{job.has_employer() ? (
// 											<button
// 												className="btn btn-larger btn-flat btn-ghost"
// 												onClick={(e) => openApplyModal(e)}
// 												href="#">
// 												<h5>{t("apply_again")}</h5>
// 											</button>
// 										) : (
// 											<a
// 												className="btn btn-larger btn-flat btn-ghost"
// 												href={job.external_url}
// 												target="_blank"
// 												rel="noopener noreferrer">
// 												<h5>{t("go_to_job_page")}</h5>
// 											</a>
// 										)}
// 										<Link
// 											className="btn btn-larger btn-flat btn-fill"
// 											to={internal_uri.formatted_userpanel_my_applications_page_path()}
// 											target="_blank"
// 											rel="noopener noreferrer">
// 											<h5>{t("general:my_applications")}</h5>
// 										</Link>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				) : (
// 					""
// 				)}
// 			</CSSTransitionGroup>
// 		</div>
// 	);
// };
class JobPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.openApplyModal = this.openApplyModal.bind(this);
    this.openLogInModal = this.openLogInModal.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchAcceptedInvitations = this.fetchAcceptedInvitations.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.updateGoReferModal = this.updateGoReferModal.bind(this);
    this.updateGoRedirectModal = this.updateGoRedirectModal.bind(this);
    this.updateAppliedReminderModal = this.updateAppliedReminderModal.bind(
      this
    );
    this.updateInviteToApplyModal = this.updateInviteToApplyModal.bind(this);
    this.handleExternalJobRedirect = this.handleExternalJobRedirect.bind(this);
    this.updateJob = this.updateJob.bind(this);
    this.handleShareLinkSubMenu = this.handleShareLinkSubMenu.bind(this);

    this.handleShareLinkSubMenuClose = this.handleShareLinkSubMenuClose.bind(
      this
    );

    this.handleSharing = this.handleSharing.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.state = {
      job: {},
      employer: {},
      other_jobs: [],
      invitations: [],
      referral_user: {},
      open_share_link_sub_menu: false,
      referral_link: "",
      go_refer_modal: {
        open: false,
      },
      go_redirect_modal: {
        open: false,
      },
      applied_reminder_modal: {
        open: false,
      },
      show_mobile_call_to_actions: true,
      loading: true,
      error_messages: {},
      facebook_app_id: config.config.facebook_app_id,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    new facebookEvents({ eventName: "ViewContent" });
    window.addEventListener("scroll", this.handleScroll);

    const query = decodeURI(this.props.location.search);
    if (query) {
      let referral_code = get_value_from_query_string("referral_code", query);
      if (referral_code) {
        let formData = new FormData();
        formData.append("referral_code", referral_code);
        let options = {
          method: "POST",
          url: config.api_url("/users/referral_info"),
          headers: headers.getItemsFromLocalStorage("multipart/form-data"),
          data: formData,
          json: true,
        };
        axios(options)
          .then((response) => {
            headers.updateItemsToLocalStorage(response.headers);
            this.props.updateConnectionOffModal(false);

            if (this._isMounted) {
              const raw_referral_user = response.data;
              const referral_user = new User({
                ...raw_referral_user,
                referral_code,
              });
              this.setState(
                {
                  referral_user,
                },
                () => this.props.addCurrentUserReferralCode(referral_code)
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              headers.updateItemsToLocalStorage(error.response.headers);
              if (error.response.status === 401) window.location.reload();
            }

            if (this._isMounted) {
              if (typeof error.response === "undefined")
                this.props.updateConnectionOffModal(true);

              this.setState((prev_state) => ({
                ...prev_state,
                error_messages:
                  error.response &&
                  error.response.data &&
                  error.response.data.status === "error"
                    ? error.response.data.errors
                    : {
                        full_message:
                          "There was an error. Please try again later.",
                      },
              }));

              page_smothly_scroll_to(0, 300);
            }
          });
      }
    }

    const job_id = this.props.match.params.id_slug.split("-")[0];
    this.fetchData(job_id);
  }

  UNSAFE_componentWillReceiveProps(next_props) {
    const job_id = next_props.match.params.id_slug.split("-")[0];
    if (job_id !== this.props.match.params.id_slug.split("-")[0]) {
      this.setState(
        {
          loading: true,
        },
        () => this.fetchData(job_id)
      );
    }

    const { reduxTokenAuth } = next_props;
    if (
      reduxTokenAuth.currentUser.isSignedIn !==
      this.props.reduxTokenAuth.currentUser.isSignedIn
    ) {
      this.setState(
        {
          loading: true,
        },
        () => this.fetchData(job_id)
      );
    }

    let refresh = get_value_from_query_string(
      "refresh",
      next_props.location.search
    );
    if (refresh === "true") {
      const { job } = this.state;
      const internal_uri = new InternalUri();
      this.props.history.replace(
        internal_uri.formatted_frontend_job_page_path(job.id, job.slug)
      );
      this.fetchData(job_id);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    window.removeEventListener("scroll", this.handleScroll);
  }

  fetchData(job_id) {
    let job = {},
      employer = {};
    let options = {
      method: "GET",
      url: config.api_url(`/jobs/${job_id}?include=required_skills`),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          const raw_job = response.data;
          job = new Job(raw_job);

          if (job.has_employer()) {
            options = {
              method: "GET",
              url: config.api_url(
                `/employers/${job.employer.id}?include=published_jobs`
              ),
              headers: headers.getItemsFromLocalStorage(),
              json: true,
            };

            return axios(options);
          } else {
            // is external job
            this.setState((prev_state) => ({
              ...prev_state,
              job,
              loading: false,
              error_messages: {},
            }));

            return new Promise(() => {});
          }
        }
      })
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);

        if (this._isMounted) {
          const raw_employer = response.data;
          employer = new Employer(raw_employer);

          let other_jobs = [];
          if (employer.published_jobs && employer.published_jobs.length) {
            for (let i = 0; i < employer.published_jobs.length; i++) {
              if (employer.published_jobs[i].id !== job.id) {
                other_jobs.push(new Job(employer.published_jobs[i]));
              }
            }
          }

          const { reduxTokenAuth } = this.props;
          const internal_uri = new InternalUri();
          let referral_link = `${
            window.location.origin
          }${internal_uri.formatted_frontend_job_page_path(job.id, job.slug)}`;
          if (reduxTokenAuth.currentUser.isSignedIn) {
            referral_link += `?referral_code=${reduxTokenAuth.currentUser.attributes.referral_code}`;
            this.setState(
              (prev_state) => ({
                ...prev_state,
                job,
                employer,
                other_jobs,
                referral_link,
                loading: false,
                loading_pending_invitations: true,
                error_messages: {},
              }),
              () => this.fetchAcceptedInvitations(job_id)
            );
          } else {
            this.setState((prev_state) => ({
              ...prev_state,
              job,
              employer,
              other_jobs,
              invitations: [],
              referral_link,
              loading: false,
              error_messages: {},
            }));
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          const internal_uri = new InternalUri();
          if (error.response.status === 401) window.location.reload();
          if (error.response.status === 404)
            this.props.history.push(
              internal_uri.formatted_frontend_error_404_page_path()
            );
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined")
            this.props.updateConnectionOffModal(true);

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));
        }

        page_smothly_scroll_to(0, 300);
      });
  }

  fetchAcceptedInvitations(job_id) {
    const { reduxTokenAuth } = this.props;
    let invitations = [];
    let options = {
      method: "GET",
      url: config.api_url(
        `/users/${reduxTokenAuth.currentUser.attributes.id}/job_applications/accepted_talent_invitations?job_id=${job_id}`
      ),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);

        const raw_invitations = response.data.collection;
        for (let i = 0; i < raw_invitations.length; i++) {
          invitations.push(
            new Invitation({
              ...raw_invitations[i],
              referrer: {
                ...raw_invitations[i].referrer, // avatar
                first_name: raw_invitations[i].referrer_first_name,
                last_name: raw_invitations[i].referrer_last_name,
              },
            })
          );
        }

        this.setState((prev_state) => ({
          ...prev_state,
          invitations,
          loading_pending_invitations: false,
        }));
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined")
            this.props.updateConnectionOffModal(true);

          this.setState((prev_state) => ({
            ...prev_state,
            loading_pending_invitations: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));
        }

        page_smothly_scroll_to(0, 300);
      });
  }

  handleScroll() {
    // TODO: 其實可以去掉 scrollTop，也可以拉出成 function 共用
    const scrollTop =
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const scrollBottom = scrollTop + screenHeight;
    const element = document.querySelector(".call-to-action-main-wrapper");
    if (element) {
      const benchmark = element.getBoundingClientRect().top + scrollTop;
      if (scrollBottom <= benchmark + 60) {
        if (!this.state.show_mobile_call_to_actions) {
          this.setState({ show_mobile_call_to_actions: true });
        }
      } else {
        if (this.state.show_mobile_call_to_actions) {
          this.setState({ show_mobile_call_to_actions: false });
        }
      }
    }
  }

  openApplyModal = (e) => {
    e.preventDefault();
    const { job, referral_user } = this.state;
    if (Object.keys(job).length !== 0) {
      this.props.updateApplyModal(true, { ...job }, { ...referral_user });
    }

    if (this.state.applied_reminder_modal.open) {
      this.setState((prev_state) => ({
        ...prev_state,
        applied_reminder_modal: {
          open: false,
        },
      }));
    }
  };

  openLogInModal = (e) => {
    e.preventDefault();
    this.props.updateEntranceFormModal("log-in");
  };

  updateGoReferModal = (e) => {
    e.preventDefault();
    this.setState((prev_state) => ({
      ...prev_state,
      go_refer_modal: {
        ...prev_state.go_refer_modal,
        open: !prev_state.go_refer_modal.open,
      },
    }));
  };

  updateGoRedirectModal = (e) => {
    e.preventDefault();
    alert("mm");
    this.setState((prev_state) => ({
      ...prev_state,
      go_redirect_modal: {
        ...prev_state.go_redirect_modal,
        open: !prev_state.go_redirect_modal.open,
      },
    }));
  };

  updateAppliedReminderModal = (e) => {
    e.preventDefault();
    this.setState((prev_state) => ({
      ...prev_state,
      applied_reminder_modal: {
        ...prev_state.applied_reminder_modal,
        open: !prev_state.applied_reminder_modal.open,
      },
    }));
  };

  updateInviteToApplyModal = (e) => {
    e.preventDefault();
    const { job } = this.state;
    this.props.updateInviteToApplyModal(true, { ...job });
  };

  handleExternalJobRedirect = (e) => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  updateJob = (next_job) => {
    this.setState((prev_state) => {
      const job = new Job({
        ...prev_state.job,
        current_user_status: { ...next_job.current_user_status },
      });
      return { job };
    });
  };

  handleShareLinkSubMenu = (e) => {
    e.preventDefault();
    const { reduxTokenAuth } = this.props;
    const { referral_link } = this.state;
    if (reduxTokenAuth.currentUser.isSignedIn) {
      this.handleCopy(e, referral_link);
      return;
    }

    this.setState((prev_state) => ({
      open_share_link_sub_menu: !prev_state.open_share_link_sub_menu,
    }));
  };

  handleShareLinkSubMenuClose = (e) => {
    console.log("handleShareLinkSubMenuClose");
    e.stopPropagation();
    // e.preventDefault();
    this.setState({ open_share_link_sub_menu: false });
  };

  handleSharing = (e, href) => {
    e.preventDefault();
    window.open(
      href,
      "",
      "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    );
    this.setState({ open_share_link_sub_menu: false });
  };

  handleCopy = (e, string) => {
    e.preventDefault();
    copy_string(e, string);
    this.setState({ open_share_link_sub_menu: false });
    this.props.addFlashMessage(
      true,
      <Interpolate
        i18nKey="flash_messages:link_copied_"
        useDangerouslySetInnerHTML={true}
      />,
      "",
      "with-auto-disappear",
      ""
    );
  };

  handleOnClick = (e) => {
    // e.preventDefault();
    const target = e.target.outerHTML;
    const share_wrapper = document.querySelector(".btn-share-wrapper");
    if (
      !share_wrapper ||
      (share_wrapper && share_wrapper.outerHTML.indexOf(target) === -1)
    ) {
      if (this.state.open_share_link_sub_menu) this.handleShareLinkSubMenu(e);
    }
  };

  render() {
    const {
      openApplyModal,
      openLogInModal,
      updateGoReferModal,
      updateGoRedirectModal,
      updateAppliedReminderModal,
      updateInviteToApplyModal,
      handleExternalJobRedirect,
      updateJob,
      handleShareLinkSubMenu,
      handleShareLinkSubMenuClose,
      handleOnClick,
      handleCopy,
    } = this;
    const {
      job,
      employer,
      other_jobs,
      invitations,
      go_refer_modal,
      go_redirect_modal,
      applied_reminder_modal,
      referral_user,
      open_share_link_sub_menu,
      referral_link,
      show_mobile_call_to_actions,
      loading,
      error_messages,
    } = this.state;
    const { reduxTokenAuth } = this.props;
    const { t } = this.props;
    const internal_uri = new InternalUri();

    if (loading || !Object.keys(job).length) {
      return (
        <div className="loading-skeleton-wrapper">
          <div className="page-job content-wrapper with-gradient blue-to-cyan">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="tablet-wrapper">
                    <JobInfoWrapper loading={true} />
                    <h4 className="title-outside">
                      {t("other_jobs_from_this_employer")}
                    </h4>
                    <div className="job-cards">
                      <JobCardLoader />
                      <JobCardLoader />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="tablet-wrapper">
                    <div className="call-to-action-main-wrapper block">
                      <div className="call-to-action-main">
                        <button className="btn btn-larger btn-flat btn-fill">
                          <h5>{t("general:apply")}</h5>
                        </button>
                        <button className="btn btn-larger">
                          <h5>{t("general:save")}</h5>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mobile-call-to-action-wrapper">
              <div className="container">
                <div className="mobile-call-to-action without-featured">
                  <button className="btn btn-larger btn-flat btn-fill">
                    <h5>{t("general:apply")}</h5>
                  </button>
                  <div className="subscribe subscribe-save-grey-48" />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`page-job content-wrapper with-gradient blue-to-cyan ${
          job.is_closed() ? "page-job-closed" : ""
        }`}
        onClick={(e) => handleOnClick(e)}
      >
        <Helmet>
          <title>
            {job.formatted_title()} - {job.formatted_employer_name()}｜Meet.jobs
          </title>
        </Helmet>
        <UseReferralUrlWrapper />
        <div className="container">
          {error_messages.full_message ? (
            <h6 className="message error-message">
              {error_messages.full_message}
            </h6>
          ) : (
            ""
          )}
          {job.is_closed() ? (
            <div className="system-message-wrapper warning">
              <p>{t("this_job_is_no_longer_accepting_applicants_")}</p>
              <Link
                to={internal_uri.formatted_frontend_jobs_page_path()}
                className="btn btn-flat btn-fill"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5>{t("browse_more_jobs")}</h5>
              </Link>
            </div>
          ) : (
            ""
          )}
          {job.is_draft() ? (
            <div className="system-message-wrapper warning">
              <p>
                {t(
                  "this_job_is_in_the_process_of_editing_and_not_accepting_applicants_"
                )}
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="tablet-wrapper">
            <BreadCrumb
              items={[
                {
                  text: t("general:home"),
                  uriType: "internal",
                  uri: internal_uri.formatted_frontend_index_page_path(),
                  eventCategory: "314_breadcrumb_home",
                },
                {
                  text: job.formatted_employer_name(),
                  uriType: job.has_employer()
                    ? "internal"
                    : job.external_employer_url
                    ? "external"
                    : null,
                  uri: job.has_employer()
                    ? `${internal_uri.formatted_frontend_employer_page_path(
                        employer.id,
                        employer.slug
                      )}${
                        Object.keys(referral_user).length
                          ? `?referral_code=${referral_user.referral_code}`
                          : ""
                      }`
                    : job.external_employer_url
                    ? job.external_employer_url
                    : null,
                  eventCategory: "315_breadcrumb_employer",
                },
                {
                  text: job.formatted_title(),
                },
              ]}
            />
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="tablet-wrapper">
                {job.has_event() && job.events[0].get_banner_url() ? (
                  <div>
                    <div className="event-marketing-wrapper tl">
                      <MarketingBanner
                        type="horizontal"
                        url={job.events[0].get_url()}
                        banner_url={job.events[0].get_banner_url()}
                        content={t(job.events[0].get_i18n_key())}
                        // parameters={{...this.eventMarketingParameters, utm_campaign: t(job.events[0].get_i18n_key())}}
                        eventCategory="311_marketing_job_page"
                        ads={job.events}
                      />
                    </div>
                    <div className="event-marketing-wrapper mb">
                      <MarketingBanner
                        type=""
                        url={job.events[0].get_url()}
                        banner_url={job.events[0].get_banner_url()}
                        content={t(job.events[0].get_i18n_key())}
                        // parameters={{...this.eventMarketingParameters, utm_campaign: t(job.events[0].get_i18n_key())}}
                        ads={job.events}
                        eventCategory="311_marketing_job_page"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="invitations-wrapper mb">
                  {invitations.length ? (
                    <div className="invitations">
                      {invitations.map((invitation, i) => (
                        <Link
                          className="invitation-wrapper"
                          to={`${internal_uri.formatted_frontend_job_invitation_page_path(
                            invitation.id
                          )}?job_id=${invitation.job.id}`}
                          key={i}
                        >
                          <div className="invitation">
                            <h3>{t("you_have_a_pending_invitation")}</h3>
                            <div className="referrer-wrapper">
                              <div className="referrer">
                                <div className="referrer-inner-wrapper">
                                  <div className="avatar">
                                    <div
                                      className="image-wrapper"
                                      style={{
                                        backgroundImage: `url(${invitation.referrer.formatted_avatar_url()})`,
                                      }}
                                    />
                                  </div>
                                  <h5>
                                    {invitation.referrer.formatted_name()}
                                  </h5>
                                  <IconArrow className="icon-arrow" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <JobInfoWrapper
                  job={{ ...job }}
                  employer={{ ...employer }}
                  referral_user={{ ...referral_user }}
                  loading={loading}
                  show_event
                  eventCategories={{
                    headEmployer: "307_link_employer",
                    headLocation: "308_link_location",
                    requiredSkill: "309_link_skill_tag",
                    employerInfo: "312_employer_info",
                  }}
                />
                {job.has_employer() ? (
                  <div>
                    <h4 className="title-outside">
                      {t("other_jobs_from_this_employer")}
                    </h4>
                    <div className="job-cards">
                      {other_jobs.length === 0 ? (
                        <p>{t("no_other_jobs_")}</p>
                      ) : (
                        other_jobs.map((job, i) => (
                          <JobCardWrapper
                            key={i}
                            job={job}
                            referral_code={
                              Object.keys(referral_user).length
                                ? referral_user.referral_code
                                : null
                            }
                            eventCategories={{
                              whole: "313_employer_other_jobs",
                              employer: "325_card_job_employer",
                              location: "326_card_job_location",
                              save: "327_card_job_save",
                            }}
                          />
                        ))
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="tablet-wrapper">
                {job.has_event() && job.events[0].get_banner_url() ? (
                  <div className="event-marketing-wrapper pc">
                    <MarketingBanner
                      type=""
                      url={job.events[0].get_url()}
                      banner_url={job.events[0].get_banner_url()}
                      content={t(job.events[0].get_i18n_key())}
                      // parameters={{...this.eventMarketingParameters, utm_campaign: t(job.events[0].get_i18n_key())}}
                      eventCategory="311_marketing_job_page"
                      ads={job.events}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="invitations-wrapper pc">
                  {invitations.length ? (
                    <div className="invitations">
                      {invitations.map((invitation, i) => (
                        <Link
                          className="invitation-wrapper"
                          to={`${internal_uri.formatted_frontend_job_invitation_page_path(
                            invitation.id
                          )}?job_id=${invitation.job.id}`}
                          key={i}
                        >
                          <div className="invitation">
                            <h3>{t("you_have_a_pending_invitation")}</h3>
                            <div className="referrer-wrapper">
                              <div className="referrer">
                                <div className="referrer-inner-wrapper">
                                  <div className="avatar">
                                    <div
                                      className="image-wrapper"
                                      style={{
                                        backgroundImage: `url(${invitation.referrer.formatted_avatar_url()})`,
                                      }}
                                    />
                                  </div>
                                  <h5>
                                    {invitation.referrer.formatted_name()}
                                  </h5>
                                  <IconArrow className="icon-arrow" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <ScrollElement name="call_to_actions">
                  <div
                    className="call-to-action-main-wrapper block coins-animation-trigger"
                    onMouseEnter={() => {
                      let coinsAnimation = document.querySelector(
                        "#coins-animation"
                      );
                      if (coinsAnimation)
                        coinsAnimation.className = "jumped-in";
                    }}
                    onMouseLeave={() => {
                      let coinsAnimation = document.querySelector(
                        "#coins-animation"
                      );
                      if (coinsAnimation)
                        coinsAnimation.className = "jumped-out";
                    }}
                    onMouseOver={() => {
                      let coinsAnimation = document.querySelector(
                        "#coins-animation"
                      );
                      if (
                        coinsAnimation &&
                        !document.body.classList.contains("jumped-in")
                      )
                        coinsAnimation.className = "jumped-in";
                    }}
                  >
                    {Object.keys(referral_user).length ? (
                      <div className="referral-user-wrapper">
                        <h6>{t("referred_by")}</h6>
                        <div className="referral-user">
                          <div className="avatar">
                            <div
                              className="image-wrapper"
                              style={{
                                backgroundImage: `url(${referral_user.formatted_avatar_applying_url()})`,
                              }}
                            />
                          </div>
                          <p>{referral_user.formatted_name()}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {reduxTokenAuth.currentUser.isSignedIn &&
                    job.is_current_user_ever_applied() ? (
                      <h6 className="applied-reminder">
                        <Interpolate
                          i18nKey="you_have_applied_on___"
                          useDangerouslySetInnerHTML={true}
                          applied_at={job.formatted_current_user_applied_time()}
                        />
                      </h6>
                    ) : (
                      ""
                    )}
                    <div className="call-to-action-main">
                      {job.is_closed() || job.is_draft() ? (
                        <button
                          onClick={(e) => e.preventDefault()}
                          className="btn btn-larger btn-flat btn-fill disabled"
                        >
                          <h5>{t("general:apply")} </h5>
                        </button>
                      ) : reduxTokenAuth.currentUser.isSignedIn ? (
                        job.is_current_user_ever_applied() ? (
                          <button
                            className="btn btn-larger btn-flat btn-fill"
                            onClick={(e) => updateAppliedReminderModal(e)}
                            data-event-category="301_apply_job_page"
                          >
                            <h5>{t("general:apply")}</h5>
                          </button>
                        ) : job.has_employer() ? (
                          <button
                            className="btn btn-larger btn-flat btn-fill"
                            onClick={(e) => openApplyModal(e)}
                            data-event-category="301_apply_job_page"
                            data-event="job_apply_click_apply_signed_in"
                          >
                            <h5>{t("general:apply")}</h5>
                          </button>
                        ) : (
                          <a
                            className="btn btn-larger btn-flat btn-fill"
                            href={`${internal_uri.formatted_external_job_redirect_page_path()}?job_id=${
                              job.id
                            }&user_id=${
                              reduxTokenAuth.currentUser.attributes.id
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => handleExternalJobRedirect(e)}
                            data-event-category="301_apply_job_page"
                            data-event="job_apply_click_apply_signed_in"
                          >
                            <h5>{t("general:apply")}</h5>
                          </a>
                        )
                      ) : (
                        <button
                          className="btn btn-larger btn-flat btn-fill"
                          onClick={(e) => openLogInModal(e)}
                          data-event-category="301_apply_job_page"
                          data-event="job_apply_click_apply_not_signed_in"
                        >
                          <h5>{t("general:apply")}</h5>
                        </button>
                      )}
                      <SaveJobButtonWrapper
                        type="button"
                        color="blue"
                        size="24"
                        job={job}
                        updateJob={updateJob}
                        eventCategory="302_save_job_page"
                      />
                      {job.is_referralable() ? (
                        <h6
                          dangerouslySetInnerHTML={{
                            __html: t(
                              "get___reward_from_meet_jobs_when_you_are_successfully_hired_"
                            ),
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {!job.has_employer() ? (
                        <div className="external-job-applying-description">
                          <img src={external_redirect} alt="" />
                          <h3>{t("we_will_redirect_you")}</h3>
                          <h6
                            dangerouslySetInnerHTML={{
                              __html: t("redirect_description"),
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {job.is_referralable() ? (
                      <div id="coins-animation">
                        <img className="coin" src={coin} alt="" />
                        <img className="coin" src={coin} alt="" />
                        <img className="coin" src={coin} alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {job.is_referralable() ? (
                    <div className="call-to-action-refer-wrapper block">
                      <div className="call-to-action-refer">
                        <h3>{t("general:referral_reward")}</h3>
                        <p>
                          <Interpolate
                            i18nKey="refer_to_a_friend_and_get___reward_with_successful_hire_"
                            useDangerouslySetInnerHTML={true}
                            reward={<span className="reward">200 USD</span>}
                          />
                        </p>
                        {job.is_closed() || job.is_draft() ? (
                          <button
                            onClick={(e) => e.preventDefault()}
                            className="btn btn-larger btn-flat btn-hollow btn-with-icon disabled"
                          >
                            <h5>
                              {/*<img src={icon_refer_24_grey} alt="" />*/}
                              {t("general:invite_to_apply")}
                            </h5>
                          </button>
                        ) : reduxTokenAuth.currentUser.isSignedIn ? (
                          <div>
                            <button
                              className={`btn btn-larger btn-flat btn-hollow mr-2 ${
                                i18n.language === "zh-TW" ? "" : "lg:full-btn"
                              }`}
                              onClick={(e) => handleShareLinkSubMenu(e)}
                              data-event-category="351_invite_to_apply"
                              data-event="jobRefer_submit_for_friend_signed_in"
                            >
                              <h5>{t("general:share_link")}</h5>
                            </button>
                            <button
                              className={`btn btn-larger btn-flat btn-hollow mr-2 ${
                                i18n.language === "zh-TW" ? "" : "lg:full-btn"
                              }`}
                              onClick={(e) => updateInviteToApplyModal(e)}
                              data-event-category="351_invite_to_apply"
                              data-event="jobRefer_submit_for_friend_signed_in"
                            >
                              <h5>{t("general:submit_for_friend")}</h5>
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              className={`btn btn-larger btn-flat btn-hollow mr-2 className={\`${
                                i18n.language === "zh-TW" ? "" : "lg:text-sm"
                              }\`}`}
                              onClick={(e) => handleShareLinkSubMenu(e)}
                              data-event-category="351_invite_to_apply"
                              data-event="jobRefer_submit_for_friend_not_signed_in"
                            >
                              <h5>
                                {/*<img src={icon_refer_24} alt=""/>*/}
                                {t("general:share_link")}
                              </h5>
                            </button>
                            <button
                              className="btn btn-larger btn-flat btn-hollow"
                              onClick={(e) => openLogInModal(e)}
                              data-event-category="351_invite_to_apply"
                              data-event="jobRefer_submit_for_friend_not_signed_in"
                            >
                              <h5
                                className={`${
                                  i18n.language === "zh-TW" ? "" : "lg:text-sm"
                                }`}
                              >
                                {t("general:submit_for_friend")}
                              </h5>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </ScrollElement>

                <div className="marketing-materials-wrapper">
                  {!job.has_event() && this.props.ads && this.props.ads.length
                    ? this.props.ads.map((raw_ad, i) => {
                        const ad = new Ad(raw_ad);
                        return (
                          <MarketingBanner
                            key={i}
                            type="vertical"
                            id={ad.id}
                            url={ad.get_url()}
                            banner_url={ad.get_img_url()}
                            content={ad.formatted_title()}
                            // parameters={{...this.eventMarketingParameters, utm_campaign: ad.formatted_title()}}
                            eventCategory="216_marketing_search"
                          />
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        <CSSTransitionGroup
          component="div"
          transitionName="fade-in-out"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {show_mobile_call_to_actions ? (
            <div className="mobile-call-to-action-wrapper">
              <div className="container">
                <div
                  className={`mobile-call-to-action ${
                    job.is_referralable() ? "" : "without-featured"
                  }`}
                >
                  {Object.keys(referral_user).length ? (
                    <div className="referrer-wrapper">
                      <ScrollLink
                        to="call_to_actions"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={400}
                      >
                        <div className="referrer">
                          <div className="avatar">
                            <div
                              className="image-wrapper"
                              style={{
                                backgroundImage: `url(${referral_user.formatted_avatar_url()})`,
                              }}
                            />
                          </div>
                          <h6>{referral_user.formatted_name()}</h6>
                        </div>
                      </ScrollLink>
                    </div>
                  ) : (
                    ""
                  )}
                  {job.is_closed() || job.is_draft() ? (
                    <button
                      onClick={(e) => e.preventDefault()}
                      className="btn btn-larger btn-flat btn-fill disabled"
                    >
                      <h5>{t("general:apply")}</h5>
                    </button>
                  ) : reduxTokenAuth.currentUser.isSignedIn ? (
                    job.is_current_user_ever_applied() ? (
                      <button
                        className="btn btn-larger btn-flat btn-fill"
                        onClick={(e) => updateAppliedReminderModal(e)}
                        data-event-category="301_apply_job_page"
                      >
                        <h5>{t("general:apply")}</h5>
                      </button>
                    ) : job.has_employer() ? (
                      <button
                        className="btn btn-larger btn-flat btn-fill"
                        onClick={(e) => openApplyModal(e)}
                        data-event-category="301_apply_job_page"
                      >
                        <h5>{t("general:apply")}</h5>
                      </button>
                    ) : (
                      <button
                        className="btn btn-larger btn-flat btn-fill"
                        onClick={(e) => updateGoRedirectModal(e)}
                        data-event-category="301_apply_job_page"
                      >
                        <h5>{t("general:apply")}</h5>
                      </button>
                    )
                  ) : (
                    <button
                      className="btn btn-larger btn-flat btn-fill"
                      onClick={(e) => openLogInModal(e)}
                      data-event-category="301_apply_job_page"
                    >
                      <h5>{t("general:apply")}</h5>
                    </button>
                  )}
                  {job.is_referralable() ? (
                    job.is_closed() || job.is_draft() ? (
                      <img
                        className="disabled"
                        src={icon_refer_48_grey}
                        alt=""
                      />
                    ) : (
                      <img
                        src={icon_refer_48}
                        alt=""
                        onClick={(e) => handleShareLinkSubMenu(e)}
                        data-event-category="362_icon_invite_to_apply_mobile"
                      />
                    )
                  ) : (
                    ""
                  )}
                  <SaveJobButtonWrapper
                    color="grey"
                    size="48"
                    job={{ ...job }}
                    updateJob={updateJob}
                    eventCategory="302_save_job_page"
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </CSSTransitionGroup>

        <CSSTransitionGroup
          component="div"
          transitionName="modal-wrapper-with-modal-slide"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {open_share_link_sub_menu ? (
            <div className="modal-wrapper share-modal">
              <div className="modal-inner-wrapper">
                <div
                  className="modal-bg"
                  onClick={(e) => handleShareLinkSubMenuClose(e)}
                />
                <div className="modal double-confirm-modal with-pop-up-icon">
                  <div
                    className="btn-close"
                    onClick={(e) => handleShareLinkSubMenuClose(e)}
                  ></div>
                  <div className="content">
                    <div className="sub-menu">
                      <h2>
                        <img src={icon_refer_24} alt="" />{" "}
                        {t("general:invite_to_apply")}
                      </h2>
                      {!reduxTokenAuth.currentUser.isSignedIn ? (
                        <div className="warning">
                          <h6>
                            <Interpolate
                              i18nKey="if_you_refer_without___you_will_not_get_any_referral_reward_"
                              useDangerouslySetInnerHTML={true}
                              log_in={
                                <button
                                  className="link"
                                  onClick={(e) => openLogInModal(e)}
                                >
                                  {t("general:log_in")}
                                </button>
                              }
                            />
                          </h6>
                        </div>
                      ) : (
                        ""
                      )}
                      <ul>
                        <li className="li-link">
                          <button
                            onClick={(e) => handleCopy(e, referral_link)}
                            data-event-category="303_share_copy_link"
                          >
                            <IconLink />
                            <h6>{t("general:copy_link")}</h6>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {go_refer_modal.open ? (
            <div className="modal-wrapper go-refer-modal-wrapper">
              <div className="modal-inner-wrapper">
                <div
                  className="modal-bg"
                  onClick={(e) => updateGoReferModal(e)}
                />
                <div className="modal go-refer-modal">
                  <div
                    className="btn-close white"
                    onClick={(e) => updateGoReferModal(e)}
                  />
                  <h3>{t("general:referral_reward")}</h3>
                  <p>
                    <Interpolate
                      i18nKey="refer_to_a_friend_and_get___reward_with_successful_hire_"
                      useDangerouslySetInnerHTML={true}
                      reward={<span className="reward">200 USD</span>}
                    />
                  </p>
                  {reduxTokenAuth.currentUser.isSignedIn ? (
                    <button
                      className="btn btn-larger btn-flat btn-fill btn-with-icon"
                      onClick={(e) => updateInviteToApplyModal(e)}
                      data-event="jobRefer_submit_for_friend_signed_in"
                      data-event-category="351_invite_to_apply"
                    >
                      <h5>
                        <img src={icon_refer_24} alt="" />
                        {t("general:invite_to_apply")}
                      </h5>
                    </button>
                  ) : (
                    <button
                      className="btn btn-larger btn-flat btn-fill btn-with-icon"
                      onClick={(e) => openLogInModal(e)}
                      data-event-category="351_invite_to_apply"
                      data-event="jobRefer_submit_for_friend_not_signed_in"
                    >
                      <h5>
                        <img src={icon_refer_24} alt="" />
                        {t("general:invite_to_apply")}
                      </h5>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {go_redirect_modal.open ? (
            <div className="modal-wrapper go-redirect-modal-wrapper">
              <div className="modal-inner-wrapper">
                <div
                  className="modal-bg"
                  onClick={(e) => updateGoRedirectModal(e)}
                />
                <div className="modal go-redirect-modal">
                  {!reduxTokenAuth.currentUser.isSignedIn ? (
                    <div className="warning">
                      <h6>
                        <Interpolate
                          i18nKey="if_you_refer_without___you_will_not_get_any_referral_reward_"
                          useDangerouslySetInnerHTML={true}
                          log_in={
                            <button
                              className="link"
                              onClick={(e) => openLogInModal(e)}
                            >
                              {t("general:log_in")}
                            </button>
                          }
                        />
                      </h6>
                    </div>
                  ) : (
                    ""
                  )}
                  <ul>
                    <li className="li-link">
                      <button
                        onClick={(e) => handleCopy(e, referral_link)}
                        data-event-category="303_share_copy_link"
                      >
                        <IconLink />
                        <h6>{t("general:copy_link")}</h6>
                      </button>
                    </li>
                  </ul>
                  <img src={external_redirect} alt="" />
                  <h3>{t("we_will_redirect_you")}</h3>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: t("redirect_description"),
                    }}
                  />
                  <a
                    className="btn btn-larger btn-flat btn-fill"
                    href={`${internal_uri.formatted_external_job_redirect_page_path()}?job_id=${
                      job.id
                    }&user_id=${reduxTokenAuth.currentUser.attributes.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => handleExternalJobRedirect(e)}
                  >
                    <h5>{t("general:continue")}</h5>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {applied_reminder_modal.open ? (
            <div className="modal-wrapper double-confirm-modal-wrapper">
              <div className="modal-inner-wrapper">
                <div
                  className="modal-bg"
                  onClick={(e) => updateAppliedReminderModal(e)}
                />
                <div className="modal double-confirm-modal with-pop-up-icon">
                  <div
                    className="image-wrapper"
                    style={{ backgroundImage: `url(${modal_pop_up_warning}` }}
                  />
                  <div className="content">
                    <h4 className="modal-title">
                      {t("you_have_applied_before")}
                    </h4>
                    <p>
                      <Interpolate
                        i18nKey="applied_reminder_description"
                        useDangerouslySetInnerHTML={true}
                        applied_at={job.formatted_current_user_applied_time()}
                      />
                    </p>
                    <div className="buttons-wrapper">
                      {job.has_employer() ? (
                        <button
                          className="btn btn-larger btn-flat btn-ghost"
                          onClick={(e) => openApplyModal(e)}
                          href="#"
                        >
                          <h5>{t("apply_again")}</h5>
                        </button>
                      ) : (
                        <a
                          className="btn btn-larger btn-flat btn-ghost"
                          href={job.external_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h5>{t("go_to_job_page")}</h5>
                        </a>
                      )}
                      <Link
                        className="btn btn-larger btn-flat btn-fill"
                        to={internal_uri.formatted_userpanel_my_applications_page_path()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h5>{t("general:my_applications")}</h5>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default withNamespaces(["frontend_job_page", "general", "states"])(
  JobPage
);
