import React,{useState,useEffect} from "react";
import {get_value_from_query_string} from "../../../assets/js/functions";
import axios from "axios";
import User from "../../models/user";
import Config from "../../models/config"
import ApiHeaders from "../../models/api_headers";
import {withNamespaces} from "react-i18next";

const ReferralUserBottom = ({location,reduxTokenAuth,addCurrentUserReferralCode,t,...rest}) => {
    const config = new Config();
    const headers = new ApiHeaders();
    const [referralUser,setReferralUser] = useState(null)
    const [showReferralUser,setShowReferralUser] = useState(true)
    const [showByPosition,setShowByPosition] = useState(true)

    useEffect(()=>{
        if(typeof window === "undefined"){
            return;
        }
        const scrollHanlder = () => {
            const positionY = window.pageYOffset
            const screenHeight = window.innerHeight
            const bodyHeight = document.getElementById('root').clientHeight
            const offset = 100
            if(positionY + screenHeight + offset >= bodyHeight) {
                setShowByPosition(false)
            }else{
                setShowByPosition(true)
            }
            // console.log(positionY + screenHeight + offset,bodyHeight)
        }

        window.addEventListener("scroll",scrollHanlder)

        return ()=>{
            window.removeEventListener("scroll",scrollHanlder)
        }
    },[])

    useEffect(()=>{

        const query = decodeURI(location.search);
        // console.log("query",query)
        if(query) {
            let referral_code = get_value_from_query_string('referral_code', query);
            if(referral_code) {

                if(reduxTokenAuth.currentUser.isSignedIn) {
                    const user_referral_code = reduxTokenAuth.currentUser.attributes.referral_code;
                    if(user_referral_code === referral_code){
                        setReferralUser(null)
                        return;
                    }
                }

                let formData = new FormData();
                formData.append('referral_code', referral_code);
                let options = {
                    method: 'POST',
                    url: config.api_url('/users/referral_info'),
                    headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                    data: formData,
                    json: true
                };
                axios(options)
                    .then((response) => {
                        // console.log(response)
                        const referral_user = new User({
                            ...response.data,
                            referral_code
                        });

                        addCurrentUserReferralCode(referral_code)
                        headers.updateItemsToLocalStorage(response.headers);

                        setReferralUser(referral_user)

                    })
                    .catch((error) => {
                        if(error.response) {
                            if(error.response.status === 401) window.location.reload();
                        }
                    });
            }
        }else{

            setReferralUser(null)
        }
    },[location])



    return (
        <React.Fragment>
            {
                 referralUser ?
                    (
                    <div className={`referral-user-bottom ${showReferralUser && showByPosition ? 'show' : ''}`}>
                        <p className="ok" onClick={()=>{setShowReferralUser(false)}}>{t('ok')}</p>
                        <h6>{t('referred_by')}</h6>
                        <div className="data-container">
                            <div className="avatar">
                                <div
                                    className='image-wrapper'
                                    style={{backgroundImage: `url(${referralUser.formatted_avatar_applying_url()})`}}
                                />
                            </div>
                            <p className="referral-user">{referralUser.formatted_name()}</p>
                        </div>
                    </div>
                    )
                 : null
            }
        </React.Fragment>
    )
}


// export default withNamespaces(['frontend_job_page'])(ReferralUserBottom);
// export default withRouter(withNamespaces(['frontend_job_page'])(ReferralUserBottom));
export default withNamespaces(['frontend_job_page'])(ReferralUserBottom);