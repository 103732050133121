export default {

    text_version:

`This Agreement is authorized by 

Name: __________(hereinafter, such as “Party A,” “Client,” “Employer,” “Buyer,”  and “Recruiting Business”) 

Telephone: __________ 

Email: __________ 

to WORK TOGETHER CO., LTD. (hereinafter, such as “Meet.jobs,” “Party B”, “Service Provider,” “Seller”) for the assistance of recruiting talents through the platform and service owned thereby. 

Recitals and Explanations 

1. This Agreement is to be used when the WORK TOGETHER CO., LTD. and its website, platform and online service provides solution service to talent recruitment. 

2. All explanations and definitions of the terms under this Agreement are the same as those in the “User Agreement”, “Privacy Policy” and all relevant documents of Meet.jobs. 

3. Client must have the contact person responsible for recruitment registered as a member of the Meet.jobs service to further register a business account. 

4. Except for the agreements separately reached by the Client and Meet.jobs in writing, all information will be recognized as that recorded on the Meet.jobs website, system and  extended service. 

Obligations of Client 

5. The Client does not need to pay for posting expenses. 

6. Clients shall adopt the service plan from those stated on Meet.jobs' website, platform and service content. Only one plan may be adopted at a time for the same corporate account. The plan selected at a given time shall be determined based on the record displayed on the service system of Meet.jobs. 

7. The Client is able to post the recruiting job information to the Meet.jobs website and all extended web platforms and guarantee the information provided is correct and without mistakes. If the information provided by the Client is incorrect and causes damages to Meet.jobs, the Client shall be liable to Meet.jobs for the damages; in the event applicants suffer damages incurred therefrom, the Client shall be liable and Meet.jobs will not be involved. 

8. When the Client publishes an opening through the service of Meet.jobs, the bottom line is that the salary range or lower limit of the salary needs to be published. 

Note: “Trust” is a core value which Meet.jobs believes, and “transparent information” could allow the talents to sufficiently trust the employer. When the employer provides information as transparent, complete, and accurate, it will be able to recruit a suitable employee more efficiently through Meet.jobs. Contrarily, if there any information related to the employer and/or to the job description including and especially about the salary information cannot be revealed openly, such talent demand will only be served under the term of Hunter Plan, and the service fee will be adjusted according to how open the information can be published.

9. The Client may, based on advice from professional consultants of Meet.jobs, provide information necessary and relating to the recruitment, including, without limitation,  videos, pictures, recordings, text messages, and interviews and/or resumes of current  employees, hiring manager or business founder. 

10. The materials mentioned under the preceding section do not include information and materials that need to be kept confidential due to the Client’s business concerns.  Including the Client’s company name, Meet.jobs needs to obtain the employer’s permit in any recordable form to publish such information. If it is published without permit, the Client is entitled to immediately terminate the Agreement. In the event the Client suffers any damages therefrom, Meet.jobs will be liable for all damages and legal responsibilities. 

11. Clients are required to respond to job seekers' application messages, including rejection letters (i.e., thank you letters), on Meet.jobs website, platform, and online services. In particular, if a job application is not rejected and the status is still shown as “applying," Meet.jobs will retain the right to charge a fee regardless of the length of time that has elapsed and whether or not the same job position is filled; once the client has  hired the candidate, it is deemed to have been hired through Meet.jobs. 

12. Clients shall reply to the job seeker or his/her referrer within 3 business days to confirm whether to accept the candidate. If the acceptance is not confirmed on the same date as the candidate's commencement of employment, please inform Meet.jobs of the candidate’s commencement date of employment by means capable of leaving a record.

13. When confirming the acceptance of an application, the client shall provide the candidate’s salary information, including but not limited to the Offer Letter, employment agreement, and salary slip, as the basis for Meet.jobs' charges. 

14. The client needs to complete the payment within 30 calendar days after receipt of the invoice. 

Obligations of Meet.jobs 

15. After the client registers for a corporate account on the Meet.jobs system, a Meet.jobs professional consultant will contact the client within one business day to provide  assistance. 

16. Meet.jobs will, based on its professional judgment and depending on the recruiting needs of the Client, provide target marketing, text or video interview, online or offline  promotion, community referral to assist the Client in recruiting. 

17. Meet.jobs will share and promote the Client’s business and job openings information on social networks, including, without limitation, Facebook, LinkedIn, YouTube,  Instagram, WeChat and TikTok, as well as other websites or networks specially required by the  Client. 

18. In the event any additional expenses derive from the recruitment plan provided by Meet.jobs, then after obtaining the Client’s consent shown in any recordable form, the  parties will agree upon the party bearing such expenses and then separately agree upon  the details of payment, certificates, and reimbursement. 

19. Meet.jobs shall track the status of each job application until the candidate’s application is accepted, canceled, or rejected by the client. If a client has unread and/or unprocessed job seekers, Meet.jobs shall remind the client to process them as soon as possible by  means capable of leaving a record. 

20. After the candidate’s commencement of employment is confirmed, Meet.jobs shall issue an invoice based on the salary information provided by the client, and has an obligation to advise the clients on the payment method that is most convenient and cost effective.



21. Once the client has completed the payment, Meet.jobs shall confirm and inform the client within 3 business days. 

Recognition of employment 

22. Employment on Meet.jobs service may be recognized in the following three ways: 

A. Client confirmation: Client may directly confirm the employment on Meet.jobs system or inform it in any recordable form. 

B. Client confirmation after the applicant reports: Once employed the job, the applicant may report the employment on the Meet.jobs system and Meet.jobs system will automatically confirm with the Client about the employment. The client may confirm the employment on the Meet.jobs system or inform it in any recordable form. 

C. Client confirmation after the referrer reports: If the applicant has a referrer, the referrer may report the employment on the Meet.jobs system, and the Meet.jobs system will automatically confirm with the Client about the employment. The Client may confirm the employment on Meet.jobs system or inform it in any recordable form. 

23. Based on the above, when confirming the acceptance of a candidate, the client shall also inform the candidate of the commencement date of employment, which shall be the specific date the candidate commences employment. If there is any change in the commencement date of employment during the period, it shall be agreed by both parties by means capable of leaving a record. 

24. In the event applicant and referrer report the employment under the preceding section, if confirmed as “not hired,” the Client needs to reply the same on the Meet.jobs system within 3 workdays, or inform Meet.jobs in any recordable form, failing which Meet jobs will  reserve the right to charge. 

25. If the applicant is not hired for the Client’s job, the Client needs to “reject” its application on the Meet.jobs system. In the event there is any applicant not refused, it will be deemed as in the “applying” status. As such, however long the time has passed, once hired, Meet.jobs is still entitled to charge. 

26. If the applicant is in the status of “applying”, then once employed with the Client’s opening, whether or not it is employed with the position originally applied for, Meet.jobs will be entitled to the charge. Namely, if the Client does not intend to hire such an applicant, it must “reject” the applicant on Meet.jobs, and please refrain from committing any conduct damaging the Client’s goodwill and trust such as “silent refusal”.

27. Applicants who apply through the Meet.jobs system, and any candidate referred by Meet.jobs in any recordable form, once hired, Meet.jobs is entitled to charge the Client therefor. In addition, this is irrelevant to whether or not the employer learned about this candidate through other channels, and Meet.jobs is still entitled to charge. 

28. If the candidate has applied for a job through other channels and is still “applying”, then the candidate will not be counted as a referral of Meet.jobs if he/she applies separately through Meet.jobs. Note that the term “applying” used herein refers to “The client has contacted the candidate for interview through other channels, and the candidate  has responded to accept the interview with contact records, while such application has  not been ‘rejected’ or ‘canceled,’ and the duration between the last ‘contact between the  client and the candidate’ and the ‘date on which the candidate applied through Meet.jobs’  is within 10 business days.” Under such circumstances, it will be deemed that the candidate’s previous application through other channels has not been closed, and Meet.jobs will not charge service fees. 

29. If the Client considers that the hired applicant was not employed through the service of Meet.jobs, it needs to obtain the applicant’s confirmation in any recordable form. In this situation, Meet.jobs will not charge service fees. 

Work period guarantee 

30. For those candidates who are accepted through Meet.jobs, Meet.jobs guarantees that such candidates will work for a period not less than 30 calendar days; however, this shall  not apply to the case where the client and Meet.jobs will otherwise agree and keep records  of such agreement. 

31. If the candidate resigns within the first 30 calendar days, then Meet.jobs needs to refund 50% of the service fee no matter which recruitment plan the client has adopted.

32. Following the foregoing, Meet.jobs will help the client to recruit the substitute candidate and charge the full service fee for the substitute candidate of employment.

33. Specifically, if such an offeree is disemployed by the Client during the trial period due to work performance within the first 30 calendar days, it will not be within the guaranteed scope of Meet.jobs. 

34. If the client has a customized demand, such as extending the guarantee period, please adopt the Hunter Plan and Meet.jobs will adjust the service fee based on the customized demand.

Actual Salary (Annual salary) Recognition 

35. Once confirmed hired, the Client needs to provide the salary information, such as employment agreement, offer letter or payment slip as evidence as the basis for Meet.jobs to charge. If such information is provided by the applicant or referrer, it needs to obtain the Client’s confirmation thereof in any recordable form. 

36. Meet.jobs is obliged to keep the foregoing salary information confidential, and such information may only be used as the evidence of charging service fees. In the event the information is disseminated, causing the Client’s damages, Meet.jobs needs to be liable therefor. 

37. Clients may select the calculation method of annual salary, monthly salary, daily salary or hourly salary when posting vacancies for job positions through the Meet.jobs system.  Meet.jobs will charge service fees in accordance with different service plans selected by the client. 

38. If the salary for the job position is calculated on an annual basis, the actual salary shall be the sum of full-time employment offered and stated on the official Offer Letter or employment agreement. 

39. If the salary for the job position is calculated on a monthly basis, the sum of the actual salary shall be the sum of full-time employment offered and stated on the official Offer Letter or employment agreement times by the number of months guaranteed for the job position (for example, 14 months. If it has not been specified, it shall be 12 months.) If the months of guaranteed salary are less than 12 months, it shall be calculated as 12 months. 

40. If the salary for the job position is calculated on a daily basis, the sum of the actual salary shall be the sum of full-time employment offered and stated on the official Offer Letter or employment agreement times by 20 (number of working days per month) and times by the number of months guaranteed for the job position by the client (for example,  14 months. If it has not been specified, it shall be 12 months.) If the months of guaranteed salary are less than 12 months, it shall be calculated as 12 months. 

41. If the salary for the job position is calculated on an hourly basis, the sum of the actual salary shall be the sum of full-time job offered and stated on the official Offer Letter or employment agreement times by 160 (number of working hours per month) and times by  the number of months guaranteed for the job position by the client (for example, 14  months. If it has not been specified, it shall be 12 months.) If the months of guaranteed salary are less than 12 months, it shall be calculated as 12 months. 

42. When clients post their job vacancies in the Meet.jobs system, they may choose four different types of jobs which are full-time, part-time, internship and volunteer work. For those who choose a "full-time" job for the job vacancies, regardless of whether it is an indefinite agreement or a fixed-term agreement, the actual salary shall be calculated in  accordance with the aforesaid methods. 

43. Following the foregoing, if the position is listed on the system as “part-time” or “internship” job, the salary of the position is recognized as 60% of the “full-time” position under the preceding section. However, the salary of the position is still recognized as a “full-time” job, while the position on the system is targeted to a full-time employment. 

44. If the salary during the probation period is different from the salary during the regular employment, the salary during the regular employment should be used as the basis for calculating the actual salary. 

Payment 

45. Once the Client confirms the employment on the Meet.jobs system and informs it of the salary information, Meet.jobs will provide an invoice and the document required for payment (such as receipt). The client needs to complete the payment within 30 calendar days after receipt of the invoice. 

46. Meet.jobs now accepts two payment methods of “credit cards”,  “remittance” and “digital currency” among which the Client may choose therefrom and the same will be shown on the Meet.jobs system.

47. If the Client chooses a credit card, then it will only need to register the credit card information on the Meet.jobs system. Meet.jobs will issue invoice according to the employment confirmation and charge on the monthly settlement day (the 5th day of each month). Credit card is the best payment method recommended by Meet.jobs. 

48. If the Client chooses to remit the amount, then Meet.jobs will provide a bank account for the Client to remit the payment. 

49. Meet.jobs is responsible for informing the Client with the most convenient and cost-effective payment methods. Meet.jobs charges "full-service fee". In the event there is any derivative expenses, such as charges, remittance fees, and all relevant taxes, it will be  borne by the Client. 

*Social Plan 

50. The fee for the Social Plan shall be 16% of the actual annual salary of the recruited position, and the service fee shall be charged to the client based on the confirmation of  the candidate's commencement of employment. 

51. Clients that adopt the Social Plan will obtain the information of all appropriate candidates recommended to users by Meet.jobs. Once the recommended candidate is successfully accepted, Meet.jobs will provide a congratulatory gift of money for commencement of employment, and/or a gratitude gift of money for recommendation, etc., and invite the candidate to share their experience for acceptance in the recruitment process. 

52. For clients that adopt the Social Plan, Meet.jobs will use its own professional judgment to conduct targeted marketing and promotion in online and offline communities based on clients’ information regarding enterprises themselves and their job vacancies. 

53. Clients that adopt the Social Plan may participate in online and offline integrated marketing recruitment activities organized by Meet.jobs. If there is any other expense derived from such an event, it shall be directly discussed and agreed by both parties by means capable of leaving a record. 

54. The Social Plan Agreement is a standard service agreement, and any modification to the agreement shall not be acceptable. However, the details not clearly stated in the agreement may be interpreted by both parties in the form of an agreement supplement.  However, the content of the agreement supplement shall not violate the content and spirit of the master agreement. In the event of any conflict, the content of the agreement supplement shall automatically become invalid. 

55. If a client desires to switch to the Social Plan from other plans, it needs to separately notify Meet.jobs in writing or by means capable of leaving a record, and the system administrator will help it perform the operation on its behalf. 

*Hunter Plan 

56. The fee for the Hunter Plan shall be 20% or more of the actual salary of the recruited position, and service fee shall be charged to the client based on the confirmation of the  candidate’s commencement of employment. 

57. Clients that adopt the Hunter Plan may be entitled to all the service content under the Social Plan. In addition, the talent hunting consultants deployed by Meet.jobs will actively assist with recommending and selecting candidates. 

58. For clients that adopt the Hunter Plan, professional talent hunting consultants of Meet.jobs will provide advice on the work content, salary range and recruitment methods (such as events) of the recruited position. 

59. For clients that adopt the Hunter Plan, Meet.jobs will assist with conducting talent screening tests, such as language, professional skills, and adaptive testing; however, it must confirm the test methods and standards in detail with the client. 

60. For other customized services, such as poaching candidates, salary negotiations, guaranteeing the number of interviews, etc., Meet.jobs will charge 20% or more of the service fee depending on the needs of the service content. Fees and collecting methods shall be discussed and agreed by both the client and Meet.jobs by means capable of leaving a record. If necessary, a supplementary agreement may be made for recording. 

61. In the event the Client has already chosen other plans and wishes to upgrade to Hunter Plan, it only needs to select Hunter Plan on Meet.jobs system or inform Meet.jobs in recordable form. After confirming the Client’s needs to upgrade the plan, Meet.jobs will have a professional consultant contacting the Client to provide assistance.



Ethics Statement 

62. The success of talent recruitment often determines the success of a business. A business could successfully recruit excellent talent based on the mutual trust among the business, talents and service provider. As such, if an applicant or its referrer requests the Client to provide any extra compensation, bonus or benefits in other forms under the condition of “not reporting the employment to Meet.jobs,” Meet.jobs strongly advises the Client not to hire such an applicant. In the event such applicant or referrer is reported to Meet.jobs, Meet.jobs will be responsible for the investigation thereof, and once confirmed to be true, such an applicant or referrer will be listed as “excluded,” and be informed to all of Meet.jobs’ business clients. 

63. If the Client actively offers benefit to applicant or referrer in any form under the condition of “not reporting the employment to Meet.jobs,” the applicant or referrer is also entitled to report such a business. Once confirmed to be true, considering such a conduct is in violation of the integrity of the recruitment, Meet.jobs will announce the Client’s name on the website and list it as “not recommended.” 

64. If the Client cannot complete the payment within 30 calendar days after receipt of invoice, it needs to obtain Meet.jobs consent in recordable form and the parties will agree on a payment day. If the Client intends to delay or does not pay in time without Meet.jobs’ consent, Meet.jobs will announce the business name on the website and list it as “not recommended.” 

65. If the information of the opening provided by the Client is inconsistent with the condition of the final offer, especially where the offered salary is lower than the lowest salary in the recruiting salary range on the system, the Business needs to proactively  submit an explanation in writing or other recordable form within 72 hours and obtain the  applicant’s and the referrer’s consent statement as evidence. If the applicant’s and the referrer’s consent is not obtained and the same happens for more than three times, Meet.jobs will announce the Client’s name on the website and list it as “not recommended.” 

Confidentiality of Personal Information 

66. For applicants answering the post through or referred by Meet.jobs, all of their information including resume, portfolio, technical test results will only be provided to the Client to assess whether the applicant is hired or not and the Client may not use the same otherwise. In the event any damages are caused to the applicant, the Client will be liable to the applicant therefor and Meet.jobs is not involved. 

Execution 

67. Meet.jobs provides the following methods for the Client to sign this Agreement and the Client only needs to adopt one. 

A. Directly select agree to the agreement on Meet.jobs system. This is recommended by Meet.jobs to be legally binding and the most efficient. 

B. Adopt a third-party electronic signing system acknowledged by Meet.jobs, and upload the same to Meet.jobs system after execution. 

C. Download the agreement already executed by Meet.jobs and printed by the Client, and scan and upload the executed version to the Meet.jobs system. 

D. Select sending hardcopy on Meet.jobs system and fill in the receipt address. Meet.jobs will send the executed hard copies in two counterparts after receipt of the request. After the Client executes the same, scan and upload it to the Meet.jobs system, and send a hard copy back to Meet.jobs for reference. 

Amendment and Termination 

68. No matter which plan is selected, as long as the job vacancy is not posted or the job vacancy is closed, the client shall not incur additional expenses except for the unfulfilled  obligations. Thus, in most cases, there is no need to terminate this agreement. 

69. If necessary, both Meet.jobs and the client shall have the right to unilaterally terminate this agreement. If either Party desires to terminate this agreement, it shall inform the other Party within one month before the termination date. And this agreement may be terminated only after all obligations of this agreement have been fulfilled. At that time, Meet.jobs will also remove the client's all information on the system. However, since the information obtained via third-party platforms such as Google are used for SEO ranking purposes, such information cannot be controlled or modified by Meet.jobs.

70. All obligations stated in the preceding paragraph shall include all matters with regard to offer and confirmation of employment, payment of fee, provision of information, and  closure of information. 

71. If Client has breached the agreement or violated the law, Meet.jobs can remove the job vacancies posted by Client and can immediately terminate the agreement. 

72. In the event of force majeure events, if either party cannot perform the agreement due to disasters, political events, religion, racial and other conflicts, the party is not legally  liable to the other party. 

73. Except for the Hunter Plan that may have a customized plan, if Meet.jobs has any revision to the Agreement, it will inform the Client through the system and recordable  form. In the event the Client disagrees with the revised content, under the general circumstances, it only needs to cease to use Meet.jobs services. 

74. Meet.jobs reserves the right to accept or reject the employer profile and the job vacancy that the Client wants to post. 

Governing Law and Dispute Resolution 

75. Client and Meet.jobs agree that any dispute arising from this Agreement and/or “services,” will be governed by the relevant laws and regulations of the Republic of China  and shall be submitted to Taiwan Taipei District Court for the first instance. 

76. If the Agreement has any parts or terms that cannot be performed, the parties both agree that the terms of the Agreement shall be revised in a way feasible and consistent  with the original ends. If the revision is impossible, the parties agree to remove those parts incapable of being performed, and perform the rest of the term in the Agreement. 

77. Within the extent permissible by laws, both the English version and the Traditional Chinese version are legally binding and other translations are only for the convenience of  understanding. In the event there is any discrepancy in the clauses and terms between the different versions, then the Traditional Chinese version shall prevail. This Agreement is the final and complete consent of the parties and replaces any oral or written promises,  understanding or other expressions submitted during the negotiation. The title of terms of this Agreement is only for reference, the parties agree that the title may not be used as the basis of interpretation. 

78. If Meet.jobs does not take legal actions for breaches, it does not mean that Meet.jobs waive the right to perform this Agreement. The client may not assign or transfer this Agreement (or membership or use of service) without the consent of Meet.jobs. However, the Client agrees that Meet.jobs may assign this Agreement to relevant institutes or institutes purchase Meet.jobs. 

79. You agree that the address specified in this agreement will be the only way to provide Meet.jobs a legal notice. 

Applicable to the Existing Agreement 

80. If the client continues to use Meet.jobs after 1st May 2023, it shall be deemed to have agreed to the new agreement. 

Contact 

81. Online: Reply robot, message board or email us at service@meet.jobs 85. TEL: +886 2 2577 5800 

82. Mail Delivery: 5F., No. 106, Sec. 4, Nanjing E. Rd., Songshan Dist., Taipei City 105, Taiwan (R.O.C.) `

};

