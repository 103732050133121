import Abstract from './abstract';
import User from "./user.js";
import Job from "./job";
import moment from "moment";

class Invitation extends Abstract {

    constructor(attrs) {
        super(attrs);

        let raw_referrer = attrs.referrer;
        if(raw_referrer) {
            delete attrs['referrer'];
            this._build_association({
                name:  'referrer',
                klass: User,
                attrs: raw_referrer
            });
        }

        let raw_talent = attrs.talent;
        if(raw_talent) {
            delete attrs['talent'];
            this._build_association({
                name:  'talent',
                klass: User,
                attrs: raw_talent
            });
        }

        let raw_job = attrs.job;
        if(raw_job) {
            delete attrs['job'];
            this._build_association({
                name:  'job',
                klass: Job,
                attrs: raw_job
            });
        }
    };

    formatted_updated_at() {
        const the_time = this.updated_at;
        if(the_time) return moment(the_time).format('YYYY/MM/DD HH:mm');
        return '';
    };

    formatted_updated_at_past_time(t) {
        const the_time = this.updated_at;
        if(the_time) {
            const now_time = moment(Date.now());
            const time_by_minute = Math.floor(parseFloat(moment(now_time).diff(moment(the_time), 'minutes', true)));
            const time_by_hour = Math.floor(parseFloat(moment(now_time).diff(moment(the_time), 'hours', true)));
            const time_by_day = Math.floor(parseFloat(moment(now_time).diff(moment(the_time), 'days', true)));

            if(time_by_minute <= 1 && time_by_minute >= 0) {
                return time_by_minute + t('general:_minute_ago');
            } else if(time_by_hour < 1 && time_by_hour >= 0) {
                return time_by_minute + t('general:_minutes_ago');
            } else if(time_by_hour === 1) {
                return time_by_hour + t('general:_hour_ago');
            } else if(time_by_day < 1 && time_by_day >= 0) {
                return time_by_hour + t('general:_hours_ago');
            } else if(time_by_day === 1) {
                return time_by_day + t('general:_day_ago');
            } else {
                return time_by_day + t('general:_days_ago');
            }
        }
        return '';
    }
}

export default Invitation;
