import React from 'react';
import axios from "axios";
import Config from "../../../models/config.js";
import { withNamespaces } from "react-i18next";
import icon_ok from "../../../../assets/images/icon/icon_28_ok_B100.svg";
import i18n from "../../../../i18n";
import InternalUri from "../../../models/internal_uri";

const config = new Config();

class ForgotPasswordPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.submit= this.submit.bind(this);
        this.state = {
            email: '',
            error_messages: {},
            sneding: false,
            sent: false
        };
    };

    componentDidMount() {
        this._isMounted = true;

        if(this.props.reduxTokenAuth.currentUser.isSignedIn) {
            const internal_uri = new InternalUri();
            this.props.history.push(internal_uri.formatted_frontend_index_page_path());
        }
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(this.props.reduxTokenAuth.currentUser.isSignedIn !== next_props.reduxTokenAuth.currentUser.isSignedIn) {
            if(next_props.reduxTokenAuth.currentUser.isSignedIn) {
                const internal_uri = new InternalUri();
                this.props.history.push(internal_uri.formatted_frontend_index_page_path());
            }
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    handleInputChange = (e, property_name) => {
        e.preventDefault();
        let value = e.target.value;

        // if(property_name==='email') value = value.toLowerCase();

        this.setState({ [property_name]: value });
    };

    submit = e => {
        e.preventDefault();
        const { email } = this.state;
        const { sending } = this.state;

        if(!sending) {
            this.setState({ sending: true });
            const internal_uri = new InternalUri();
            let options = {
                method: 'POST',
                url: config.api_url(`/auth/password?locale=${i18n.language}`),
                data: {
                    email,
                    redirect_url: `${window.location.origin}${internal_uri.formatted_frontend_redirect_from_forgot_password_email_page_path()}`
                },
                json: true
            };
            axios(options)
                .then(() => {
                    this.props.updateConnectionOffModal(false);
                    if(this._isMounted) {
                        this.setState({
                            error_messages: {},
                            sending: false,
                            sent: true
                        });
                    }
                })
                .catch((error) => {
                    if(this._isMounted) {
                        if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                        this.setState((prev_state) => ({
                            ...prev_state,
                            sending: false,
                            error_messages:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: 'There was an error. Please try again later.'},
                        }))
                    }
                });
        }
    };

    render() {

        const { email, error_messages, sending, sent } = this.state;
        const { handleInputChange, submit } = this;
        const { t } = this.props;

        return (
            <div className={`page-password content-wrapper white ${(sent) ? 'email-sent' : ''}`}>
                <div className="container">
                    <section className="page-title">
                        <h2>{t('forgot_password')}</h2>
                        <div className="text-sent-animation-wrapper">
                            <p className="text">{t('enter_your_registered_email')}</p>
                            <p className="text-sent">{t('password_reset_link_has_been_sent_to_your_mail_box_')}</p>
                        </div>
                    </section>
                    <section className="page-content">
                        <div className="entrance-wrapper">
                            {
                                (error_messages.full_message) ?
                                    <div className="form-row">
                                        <h6 className="message error-message">{error_messages.full_message}</h6>
                                    </div> : ''
                            }
                            <div className="email-sent-animation-wrapper">
                                <form>
                                    <div className="form-row">
                                        <input
                                            className="input"
                                            type="text"
                                            value={email}
                                            placeholder={t('email_address')}
                                            onChange={ e => handleInputChange(e, 'email') }
                                        />
                                    </div>
                                    {
                                        (!sending) ?
                                            <button
                                                className="btn btn-larger btn-flat btn-fill"
                                                onClick={ e => submit(e) }
                                            >
                                                <h6>{t('general:send')}</h6>
                                            </button> :
                                            <button className="btn btn-larger btn-flat btn-fill disabled">
                                                <h6>{t('general:send')}</h6>
                                            </button>
                                    }
                                </form>
                                <div className="btn-check-wrapper">
                                    <div className="btn-check"><img src={icon_ok} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    };
}

export default withNamespaces(['frontend_authentication', 'general'])(ForgotPasswordPage);