import React from 'react';
import { SignUpFormWrapper } from '../../../containers/frontend.jsx';
import { Link } from 'react-router-dom';
import { withNamespaces, Interpolate } from "react-i18next";
import InternalUri from "../../../models/internal_uri";

const SignUpPage = ({t}) => {

    const internal_uri = new InternalUri();

    return (
        <div className="page-entrance content-wrapper white">
            <div className="container">
                <div>
                    <section className="page-title">
                        <h2>{t('welcome_to_meet_jobs')}</h2>
                    </section>
                    <section className="page-content">
                        <div className="entrance-wrapper">
                            <div className="entrance-form-wrapper">
                                <SignUpFormWrapper />
                            </div>
                            <hr />
                            <p>
                                <Interpolate
                                    i18nKey="already_have_an_account___"
                                    useDangerouslySetInnerHTML={true}
                                    log_in={
                                        <Link
                                            to={internal_uri.formatted_frontend_log_in_page_path()}
                                            className="link"
                                        >
                                            {t('general:log_in')}
                                        </Link>
                                    }
                                />
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default withNamespaces(['frontend_authentication', 'general'])(SignUpPage);