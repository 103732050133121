import React from 'react';
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import {page_smothly_scroll_to, smoothly_scroll_to} from "../../../../assets/js/functions";
import { withNamespaces, Interpolate } from "react-i18next";
import { CSSTransitionGroup } from 'react-transition-group'
import Roleship from "../../../models/roleships";
import InternalUri from "../../../models/internal_uri";
import modal_pop_up_delete from "../../../../assets/images/i047-pop-ups-delete.svg";
import modal_pop_up_assure from "../../../../assets/images/i049-pop-ups-assure.svg";

const config = new Config();
const headers = new ApiHeaders();
const internal_uri = new InternalUri();

class EditInvitedMemberModal extends React.Component {

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.cancelTheAction = this.cancelTheAction.bind(this);
        this.resendInvitation = this.resendInvitation.bind(this);
        this.continueResendInvitation = this.continueResendInvitation.bind(this);
        this.deleteMember = this.deleteMember.bind(this);
        this.continueDeleteMember = this.continueDeleteMember.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            active_user_id: -1,
            access_control_list: {},
            active_employer_id: -1,
            active_role_id: -1,
            active_invited_roleship: {},
            form_data: {},
            message_modal: {
                open: false,
                text: '',
                action_type: '',
                the_action: ''
            },
            error_messages: {},
            loading: true,
            saving: false,
            deleting: false,
            resending_invitation: false,
            options: {}
        };
        this.removable_member = false;
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        const { open, employer_id, invited_roleship, role, creator_user_id } = next_props.site_state.edit_invited_member_modal;
        if(open !== this.props.site_state.edit_invited_member_modal.open) {
            if(open) {
                const active_user_id = next_props.reduxTokenAuth.currentUser.attributes.id;

                // only owner can remove other members
                this.removable_member = active_user_id === creator_user_id;

                const { current_user } = next_props;
                this.setState((prev_state) => ({
                    ...prev_state,
                    active_user_id,
                    access_control_list: current_user.employer_roles[employer_id].access_control_list,
                    active_employer_id: employer_id,
                    active_role_id: role.id,
                    active_invited_roleship: invited_roleship,
                    form_data: {
                        title: (invited_roleship.roleship_title) ? invited_roleship.roleship_title : '',
                        role: role.name,
                    },
                    loading: false,
                    error_messages: {},
                }));
            }
        }
    };

    fetchData() {
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${this.state.active_employer_id}/roles?include=roleships`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let grouped_members = [];
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                const raw_roles = response.data.collection;
                for(let i=0; i<raw_roles.length; i++) {
                    const role = raw_roles[i];
                    const raw_roleships = role.roleships;

                    for(let j=0; j<raw_roleships.length; j++) {
                        const roleship = new Roleship(raw_roleships[j]);
                        const { active_user_id } = this.state;

                        if(roleship.member.id !== active_user_id) {
                            grouped_members.push({
                                value: roleship.member.id,
                                label: roleship.member.formatted_name()
                            });
                        }
                    }
                }

                this.setState((prev_state) => ({
                    ...prev_state,
                    loading: false,
                    error_messages: {},
                    options: {
                        ...prev_state.options,
                        grouped_members
                    }
                }));
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(typeof error.response === 'undefined') {
                    window.location.reload();
                    this.props.updateConnectionOffModal(true);
                }

                this.setState((prev_state) => ({
                    ...prev_state,
                    loading: false,
                    error_messages:
                        (error.response && error.response.data && error.response.data.status === 'error') ?
                            error.response.data.errors :
                            {full_message: 'There was an error. Please try again later.'},
                }));

                page_smothly_scroll_to(0, 300);
            });
    };

    cancelTheAction = e => {
        e.preventDefault();
        this.setState((prev_state) => ({
            ...prev_state,
            message_modal: {
                open: false,
                text: '',
                action_type: '',
                the_action: '',
            }
        }));
    };

    resendInvitation = e => {
        e.preventDefault();
        const { active_invited_roleship } = this.state;
        this.setState((prev_state) => ({
            ...prev_state,
            message_modal: {
                open: true,
                text: <Interpolate
                    i18nKey="resend_member_invitation_to___"
                    useDangerouslySetInnerHTML={true}
                    invited_roleship_email={active_invited_roleship.email}
                />,
                action_type: 'resend',
                the_action: this.continueResendInvitation,
            }
        }));
    };

    continueResendInvitation = e => {
        e.preventDefault();

        const { active_role_id, active_invited_roleship, form_data, resending_invitation, active_employer_id } = this.state;
        if(!resending_invitation) {
            this.setState({ resending_invitation: true });

            let formData = new FormData();
            formData.append('member_invitation[role_id]', active_role_id);
            formData.append('member_invitation[email]', active_invited_roleship.email);
            formData.append('member_invitation[roleship_title]', form_data.title);

            let options = {
                method: 'POST',
                url: config.api_url(`/employers/${active_employer_id}/member_invitations`),
                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                data: formData,
                json: true
            };
            // 對象未註冊 -> invited
            // 對象已註冊 -> joined
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    this.setState((prev_state) => ({
                        ...prev_state,
                        message_modal: {
                            open: false,
                            text: '',
                            action_type: '',
                            the_action: ''
                        },
                        error_messages: {},
                        resending_invitation: false
                    }), () => {
                        this.props.history.replace(`${internal_uri.formatted_dashboard_member_management_page_path()}?refresh=true`);
                        this.props.updateEditInvitedMemberModal(false, -1, {}, {}, -1);
                    });

                    return new Promise(() => {});
                })
                .catch((error) => {
                    if(typeof error.response === 'undefined') this.props.updateConnectionOffModal(true);

                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    if(error.response && error.response.data && error.response.data.data ) {
                        if(typeof error.response.data.data.user_exists !== 'undefined' && error.response.data.data.user_exists) {
                            // console.log('member should be join');
                            formData = new FormData();
                            formData.append('roleship[email]', active_invited_roleship.email);
                            formData.append('roleship[title]', form_data.title);

                            options = {
                                method: 'POST',
                                url: config.api_url(`/employers/${active_employer_id}/roles/${active_role_id}/roleships`),
                                headers: headers.getItemsFromLocalStorage('multipart/form-data'),
                                data: formData,
                                json: true
                            };
                            return axios(options);
                        }
                    } else {
                        // console.log('invited failed');
                        this.setState((prev_state) => ({
                            ...prev_state,
                            resending_invitation: false,
                            error_messages:
                                (error.response && error.response.data && error.response.data.status === 'error') ?
                                    error.response.data.errors :
                                    {full_message: 'There was an error. Please try again later.'},
                        }));

                        smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);

                        return new Promise(() => {});
                    }
                })
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    this.setState((prev_state) => ({
                        ...prev_state,
                        message_modal: {
                            open: false,
                            text: '',
                            action_type: '',
                            the_action: ''
                        },
                        error_messages: {},
                        resending_invitation: false
                    }), () => {
                        this.props.history.replace(`${internal_uri.formatted_dashboard_member_management_page_path()}?refresh=true`);
                        this.props.updateEditInvitedMemberModal(false, -1, {}, {}, -1);
                    });
                })
                .catch((error) => {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        resending_invitation: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    smoothly_scroll_to(document.querySelector(".modal-wrapper"), 0, 200);
                });
        }
    };

    deleteMember = (e) => {
        e.preventDefault();
        const { active_invited_roleship } = this.state;
        this.setState((prev_state) => ({
            ...prev_state,
            message_modal: {
                open: true,
                text: <Interpolate
                    i18nKey="are_you_sure_to_remove___"
                    useDangerouslySetInnerHTML={true}
                    member_name={active_invited_roleship.email}
                />,
                action_type: 'delete',
                the_action: this.continueDeleteMember,
            }
        }));
    };

    continueDeleteMember = e => {
        e.preventDefault();
        const { deleting, active_employer_id, active_invited_roleship } = this.state;
        if(!deleting) {
            this.setState({ deleting: true });
            let options = {
                method: 'DELETE',
                url: config.api_url(`/employers/${active_employer_id}/member_invitations/${active_invited_roleship.id}`),
                headers: headers.getItemsFromLocalStorage(),
                json: true
            };
            axios(options)
                .then((response) => {
                    headers.updateItemsToLocalStorage(response.headers);
                    this.props.updateConnectionOffModal(false);

                    this.setState((prev_state) => ({
                        ...prev_state,
                        message_modal: {
                            open: false,
                            text: '',
                            action_type: '',
                            the_action: ''
                        },
                        error_messages: {},
                        deleting: false
                    }), () => {
                        const internal_uri = new InternalUri();
                        this.props.history.replace(`${internal_uri.formatted_dashboard_member_management_page_path()}?refresh=true`);
                        this.props.updateEditInvitedMemberModal(false, -1, {}, {}, -1);
                    });
                })
                .catch((error) => {
                    if(error.response) {
                        headers.updateItemsToLocalStorage(error.response.headers);
                        if(error.response.status === 401) window.location.reload();
                    }

                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        deleting: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                })
        }
    };

    closeModal = e => {
        e.preventDefault();
        this.props.updateEditInvitedMemberModal(false, -1, {}, {}, -1);
    };

    render() {

        const { site_state } = this.props;
        const { access_control_list, form_data, active_invited_roleship, error_messages, message_modal, loading, deleting, resending_invitation } = this.state;
        const { resendInvitation, cancelTheAction, deleteMember, closeModal } = this;
        const { t } = this.props;

        return (
            <CSSTransitionGroup
                component="div"
                transitionName="modal-wrapper-slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {(site_state.edit_invited_member_modal.open) ?
                    <div className="modal-wrapper edit-invited-member-modal-wrapper">
                        <CSSTransitionGroup
                            component="div"
                            className="modal-inner-wrapper"
                            transitionName="modal-slide"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >
                            <div className="modal-bg" onClick={e => closeModal(e)}/>
                            {(!loading) ?
                                <div className="modal edit-invited-member-modal">
                                    <div className="btn-close" onClick={e => closeModal(e)}/>
                                    <div className="modal-header">
                                        <h4 className="modal-title">
                                            <span className={`badge ${active_invited_roleship.state==='expired' ? 'badge-yellow' : 'badge-light-grey'}`}>
                                                {t(`general:${active_invited_roleship.state}`)}
                                            </span>
                                        </h4>
                                        <h6>{active_invited_roleship.email}</h6>
                                        <div className="header-buttons-wrapper">
                                            {
                                                (access_control_list.roles_manageable && active_invited_roleship.state==='expired') ?
                                                    <div className="button-wrapper">
                                                        <button
                                                            onClick={ e => resendInvitation(e) }
                                                            className="btn btn-smallest btn-flat btn-hollow"
                                                        >
                                                            <h5>{t('resend_invitation')}</h5>
                                                        </button>
                                                    </div> : ''
                                            }
                                            {
                                                (this.removable_member) ?
                                                    <div className="button-wrapper">
                                                        <button
                                                            onClick={ e => deleteMember(e) }
                                                            className="btn btn-smallest btn-flat btn-hollow btn-danger"
                                                        >
                                                            <h5>{t('remove_member')}</h5>
                                                        </button>
                                                    </div>: ''
                                            }
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                                        <form>
                                            <label htmlFor="member-name-or-title"><h5>{t('dashboard_member_management_page:title')}</h5></label>
                                            <div className="form-row">
                                                <input
                                                    className="input input-readonly"
                                                    type="text"
                                                    value={form_data.title}
                                                    id="title"
                                                    readOnly
                                                />
                                            </div>

                                            <label htmlFor="role"><h5>{t('dashboard_member_management_page:role')}</h5></label>
                                            <div className="form-row">
                                                <input
                                                    className="input input-readonly"
                                                    type="text"
                                                    value={form_data.role}
                                                    id="role"
                                                    readOnly
                                                />
                                            </div>
                                        </form>
                                        <div className="buttons-wrapper">
                                            <button className="btn btn-flat btn-ghost" onClick={e => closeModal(e)}>
                                                <h5>{t('general:cancel')}</h5>
                                            </button>
                                            <button onClick={ e => e.preventDefault() } className="btn btn-flat btn-fill disabled">
                                                <h5>{t('general:save')}</h5>
                                            </button>
                                        </div>
                                    </div>
                                </div> : ''
                            }
                        </CSSTransitionGroup>

                        <CSSTransitionGroup
                            component="div"
                            transitionName="modal-wrapper-with-modal-slide"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >
                            {(message_modal.open) ?
                                <div className="modal-wrapper double-confirm-modal-wrapper">
                                    <div className="modal-inner-wrapper">
                                        <div className="modal-bg" />
                                        <div className="modal double-confirm-modal with-pop-up-icon">
                                            {(message_modal.action_type === 'delete') ?
                                                <div
                                                    className="image-wrapper"
                                                    style={{backgroundImage: `url(${modal_pop_up_delete}`}}
                                                /> : ''
                                            }
                                            {(message_modal.action_type === 'resend') ?
                                                <div
                                                    className="image-wrapper"
                                                    style={{backgroundImage: `url(${modal_pop_up_assure}`}}
                                                /> : ''
                                            }
                                            <div className="content">
                                                <h4 className="modal-title">{message_modal.text}</h4>
                                                {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}
                                                <div className="buttons-wrapper">
                                                    {(!deleting && !resending_invitation) ?
                                                        <button
                                                            className="btn btn-larger btn-flat btn-ghost"
                                                            onClick={ e => cancelTheAction(e) }
                                                        >
                                                            <h5>{t('general:cancel')}</h5>
                                                        </button> :
                                                        <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-ghost disabled">
                                                            <h5>{t('general:cancel')}</h5>
                                                        </button>
                                                    }
                                                    {(message_modal.action_type === 'delete') ?
                                                        (
                                                            (!deleting && !resending_invitation) ?
                                                                <button
                                                                    className="btn btn-larger btn-flat btn-fill btn-danger"
                                                                    onClick={ e => message_modal.the_action(e) }
                                                                >
                                                                    <h5>{t('general:remove')}</h5>
                                                                </button> :
                                                                <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-fill btn-danger disabled">
                                                                    <h5>{t('general:remove')}</h5>
                                                                </button>
                                                        ) : ''
                                                    }
                                                    {(message_modal.action_type === 'resend') ?
                                                        (
                                                            (!deleting && !resending_invitation) ?
                                                                <button
                                                                    className="btn btn-larger btn-flat btn-fill btn-success"
                                                                    onClick={ e => message_modal.the_action(e) }
                                                                >
                                                                    <h5>{t('general:send')}</h5>
                                                                </button> :
                                                                <button onClick={ e => e.preventDefault() } className="btn btn-larger btn-flat btn-fill btn-success disabled">
                                                                    <h5>{t('general:send')}</h5>
                                                                </button>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''
                            }
                        </CSSTransitionGroup>
                    </div> : ''
                }
            </CSSTransitionGroup>
        );
    };
}

export default withNamespaces(['dashboard_modals', 'dashboard_member_management_page', 'general'])(EditInvitedMemberModal);
