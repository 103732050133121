import React, { useEffect, useRef, useState } from "react";
import { Link, Element } from "react-scroll";
import { withNamespaces } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Repeat from "../../general/Repeat.jsx";
import { get_value_from_query_string } from "../../../../assets/js/functions";
import { withRouter } from "react-router-dom";
import InternalUri from "../../../models/internal_uri";
import { UseReferralUrlWrapper } from "../../../containers/general";
const PrivacyAndTermsPagef = (props) => {
	const isMounted = useRef(false);
	const preProps = useRef(props);
	const [main, setMain] = useState({
		active_tab_index: 0,
	});
	const types = [
		"user-agreement",
		"employer-agreement",
		"privacy-policy",
		"cookie-policy",
		"summary-of-changes",
	];
	const handleTabChange = (tab_index) => {
		setMain({
			active_tab_index: tab_index,
		});
	};
	useEffect(() => {
		if (isMounted.current) {
			const internal_uri = new InternalUri();
			props.history.replace(
				`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=${
					types[main.active_tab_index]
				}`,
			);
		} else {
			isMounted.current = true;
			const query = decodeURI(props.location.search);
			if (query) {
				let type = get_value_from_query_string("type", query);
				if (type) {
					handleTabChange(types.indexOf(type) !== -1 ? types.indexOf(type) : 0);
				} else {
					handleTabChange(0);
				}
			} else {
				handleTabChange(0);
			}
		}
	}, [main]);
	// UNSAFE_componentWillReceiveProps
	useEffect(() => {
		// console.log("hi");
		if (isMounted) {
			const next_query = decodeURI(props.location.search);
			const query = decodeURI(preProps.current.location.search);
			if (next_query !== query) {
				let next_type = get_value_from_query_string("type", next_query);
				handleTabChange(
					types.indexOf(next_type) !== -1 ? types.indexOf(next_type) : 0,
				);
				preProps.current = props;
			}
		}
	}, [props]);
	const { t } = props;
	return (
		<div className="page-privacy-and-terms content-wrapper white">
			<Tabs
				className="react-tabs"
				selectedIndex={main.active_tab_index}
				onSelect={(index) => handleTabChange(index)}>
				<div className="tab-list-wrapper">
					<TabList>
						<div className="container">
							<Tab>
								<p>{t("general:user_agreement")}</p>
							</Tab>
							<Tab>
								<p>{t("general:employer_agreement")}</p>
							</Tab>
							<Tab>
								<p>{t("general:privacy_policy")}</p>
							</Tab>
							<Tab>
								<p>{t("general:cookie_policy")}</p>
							</Tab>
							<Tab>
								<p>{t("general:summary_of_changes")}</p>
							</Tab>
						</div>
					</TabList>
				</div>

				{/* 使用者條款 */}
				<TabPanel>
					<div className="container">
						<div className="privacy-and-terms-sidebar-wrapper hidden-xs hidden-sm">
							<ul className="sidebar">
								<Repeat numTimes={10}>
									{(index) => (
										<li key={index}>
											<Link
												activeClass="active"
												to={`user_agreement_section_${index}`}
												spy={true}
												smooth={true}
												offset={-80}
												duration={400}>
												<h5
													dangerouslySetInnerHTML={{
														__html: t(`user_agreement_title_${index}`),
													}}
												/>
											</Link>
										</li>
									)}
								</Repeat>
							</ul>
						</div>
						<div className="privacy-and-terms-content-wrapper">
							<div className="privacy-and-terms-content">
								<div className="inner-wrapper">
									<h3 className="main-title">{t("general:user_agreement")}</h3>
									<p className="last-revised-date">
										{t("general:last_revised_on")} January 22, 2019
									</p>

									<Element name="user_agreement_section_0">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_0"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_0"),
											}}
										/>
									</Element>
									<Element name="user_agreement_section_1">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_1"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_1"),
											}}
										/>
									</Element>
									<Element name="user_agreement_section_2">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_2"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_2"),
											}}
										/>
									</Element>
									<Element name="user_agreement_section_3">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_3"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_3"),
											}}
										/>
									</Element>
									<Element name="user_agreement_section_4">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_4"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_4"),
											}}
										/>
									</Element>
									<Element name="user_agreement_section_5">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_5"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_5"),
											}}
										/>
									</Element>
									<Element name="user_agreement_section_6">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_6"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_6"),
											}}
										/>
									</Element>
									<Element name="user_agreement_section_7">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_7"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_7"),
											}}
										/>
									</Element>
									<Element name="user_agreement_section_8">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_8"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_8"),
											}}
										/>
									</Element>
									<Element name="user_agreement_section_9">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_title_9"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("user_agreement_content_9"),
											}}
										/>
									</Element>
								</div>
							</div>
						</div>
					</div>
				</TabPanel>

				{/* 徵才企業條款 */}
				<TabPanel>
					<div className="container">
						<div className="privacy-and-terms-sidebar-wrapper hidden-xs hidden-sm">
							<ul className="sidebar">
								<Repeat numTimes={16}>
									{(index) => (
										<li key={index}>
											<Link
												activeClass="active"
												to={`employer_agreement_section_${index}`}
												spy={true}
												smooth={true}
												offset={-80}
												duration={400}>
												<h5
													dangerouslySetInnerHTML={{
														__html: t(
															`employer_agreement_section_title_${index}`,
														),
													}}
												/>
											</Link>
										</li>
									)}
								</Repeat>
							</ul>
						</div>
						<div className="privacy-and-terms-content-wrapper">
							<div className="privacy-and-terms-content">
								<div className="inner-wrapper">
									<h3 className="main-title">
										{t("general:employer_agreement")}
									</h3>
									<p className="last-revised-date">
										{t("general:last_revised_on")} February 28, 2023
									</p>

									<div
										dangerouslySetInnerHTML={{
											__html: t("employer_agreement_head_content"),
										}}
									/>
									<Repeat numTimes={16}>
										{(idx) => (
											<Element
												key={`dl-${idx}`}
												name={`employer_agreement_section_${idx}`}>
												<h3>{t(`employer_agreement_section_title_${idx}`)}</h3>
												<div
													dangerouslySetInnerHTML={{
														__html: t(`employer_agreement_part_${idx}`),
													}}
												/>
											</Element>
										)}
									</Repeat>
								</div>
							</div>
						</div>
					</div>
				</TabPanel>

				{/* 隱私權政策 */}
				<TabPanel>
					<div className="container">
						<div className="privacy-and-terms-sidebar-wrapper hidden-xs hidden-sm">
							<ul className="sidebar">
								<Repeat numTimes={6}>
									{(index) => (
										<li key={index}>
											<Link
												activeClass="active"
												to={`privacy_policy_section_${index}`}
												spy={true}
												smooth={true}
												offset={-80}
												duration={400}>
												<h5
													dangerouslySetInnerHTML={{
														__html: t(`privacy_policy_title_${index}`),
													}}
												/>
											</Link>
										</li>
									)}
								</Repeat>
							</ul>
						</div>
						<div className="privacy-and-terms-content-wrapper">
							<div className="privacy-and-terms-content">
								<div className="inner-wrapper">
									<h3 className="main-title">{t("general:privacy_policy")}</h3>
									<p className="last-revised-date">
										{t("general:last_revised_on")} January 22, 2019
									</p>

									<Element name="privacy_policy_section_0">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_title_0"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_content_0"),
											}}
										/>
									</Element>
									<Element name="privacy_policy_section_1">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_title_1"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_content_1"),
											}}
										/>
									</Element>
									<Element name="privacy_policy_section_2">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_title_2"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_content_2"),
											}}
										/>
									</Element>
									<Element name="privacy_policy_section_3">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_title_3"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_content_3"),
											}}
										/>
									</Element>
									<Element name="privacy_policy_section_4">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_title_4"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_content_4"),
											}}
										/>
									</Element>
									<Element name="privacy_policy_section_5">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_title_5"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("privacy_policy_content_5"),
											}}
										/>
									</Element>
								</div>
							</div>
						</div>
					</div>
				</TabPanel>

				{/* Cookie 政策 */}
				<TabPanel>
					<div className="container">
						<div className="privacy-and-terms-sidebar-wrapper hidden-xs hidden-sm">
							<ul className="sidebar">
								<Repeat numTimes={9}>
									{(index) => (
										<li key={index}>
											<Link
												activeClass="active"
												to={`cookie_policy_section_${index}`}
												spy={true}
												smooth={true}
												offset={-80}
												duration={400}>
												<h5
													dangerouslySetInnerHTML={{
														__html: t(`cookie_policy_title_${index}`),
													}}
												/>
											</Link>
										</li>
									)}
								</Repeat>
							</ul>
						</div>
						<div className="privacy-and-terms-content-wrapper">
							<div className="privacy-and-terms-content">
								<div className="inner-wrapper">
									<h3 className="main-title">{t("general:cookie_policy")}</h3>
									<p className="last-revised-date">
										{t("general:last_revised_on")} January 22, 2019
									</p>

									<div
										dangerouslySetInnerHTML={{
											__html: t("cookie_policy_intro_content"),
										}}
									/>
									<Element name="cookie_policy_section_0">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_title_0"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_content_0"),
											}}
										/>
									</Element>
									<Element name="cookie_policy_section_1">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_title_1"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_content_1"),
											}}
										/>
									</Element>
									<Element name="cookie_policy_section_2">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_title_2"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_content_2"),
											}}
										/>
									</Element>
									<Element name="cookie_policy_section_3">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_title_3"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_content_3"),
											}}
										/>
									</Element>
									<Element name="cookie_policy_section_4">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_title_4"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_content_4"),
											}}
										/>
									</Element>
									<Element name="cookie_policy_section_5">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_title_5"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_content_5"),
											}}
										/>
									</Element>
									<Element name="cookie_policy_section_6">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_title_6"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_content_6"),
											}}
										/>
									</Element>
									<Element name="cookie_policy_section_7">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_title_7"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_content_7"),
											}}
										/>
									</Element>
									<Element name="cookie_policy_section_8">
										<h3
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_title_8"),
											}}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: t("cookie_policy_content_8"),
											}}
										/>
									</Element>
								</div>
							</div>
						</div>
					</div>
				</TabPanel>

				{/* 更新總覽 */}
				<TabPanel>
					<div className="container">
						<div className="privacy-and-terms-content summary-of-changes">
							<div className="inner-wrapper">
								<h3 className="main-title">
									{t("general:summary_of_changes")}
								</h3>
								<p className="last-revised-date">
									{t("general:last_revised_on")} February 28, 2023
								</p>
								<hr />
								<h3 className="sub-title">{t("general:employer_agreement")}</h3>
								<div
									dangerouslySetInnerHTML={{
										__html: t("summary_of_changes_employer_agreement_content"),
									}}
								/>
							</div>
						</div>
					</div>
				</TabPanel>
			</Tabs>
		</div>
	);
};
// class PrivacyAndTermsPage extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.handleTabChange = this.handleTabChange.bind(this);
// 		this.state = {
// 			active_tab_index: 0,
// 		};
// 		this.types = [
// 			"user-agreement",
// 			"employer-agreement",
// 			"privacy-policy",
// 			"cookie-policy",
// 			"summary-of-changes",
// 		];
// 	}

// 	componentDidMount() {
// 		const query = decodeURI(this.props.location.search);
// 		if (query) {
// 			let type = get_value_from_query_string("type", query);
// 			if (type) {
// 				this.handleTabChange(
// 					this.types.indexOf(type) !== -1 ? this.types.indexOf(type) : 0,
// 				);
// 			} else {
// 				this.handleTabChange(0);
// 			}
// 		} else {
// 			this.handleTabChange(0);
// 		}
// 	}

// 	UNSAFE_componentWillReceiveProps(next_props) {
// 		const next_query = decodeURI(next_props.location.search);
// 		const query = decodeURI(this.props.location.search);
// 		if (next_query !== query) {
// 			let next_type = get_value_from_query_string("type", next_query);
// 			this.handleTabChange(
// 				this.types.indexOf(next_type) !== -1
// 					? this.types.indexOf(next_type)
// 					: 0,
// 			);
// 		}
// 	}
// 	handleTabChange(tab_index) {
// 		const internal_uri = new InternalUri();
// 		this.setState(
// 			{
// 				active_tab_index: tab_index,
// 			},
// 			() =>
// 				this.props.history.replace(
// 					`${internal_uri.formatted_frontend_privacy_and_terms_page_path()}?type=${
// 						this.types[tab_index]
// 					}`,
// 				),
// 		);
// 	}
// 	render() {
// 		const { active_tab_index } = this.state;
// 		const { handleTabChange } = this;
// 		const { t } = this.props;

// 		return (
// 			<div className="page-privacy-and-terms content-wrapper white">
// 				<Tabs
// 					className="react-tabs"
// 					selectedIndex={active_tab_index}
// 					onSelect={(index) => handleTabChange(index)}>
// 					<div className="tab-list-wrapper">
// 						<TabList>
// 							<div className="container">
// 								<Tab>
// 									<p>{t("general:user_agreement")}</p>
// 								</Tab>
// 								<Tab>
// 									<p>{t("general:employer_agreement")}</p>
// 								</Tab>
// 								<Tab>
// 									<p>{t("general:privacy_policy")}</p>
// 								</Tab>
// 								<Tab>
// 									<p>{t("general:cookie_policy")}</p>
// 								</Tab>
// 								<Tab>
// 									<p>{t("general:summary_of_changes")}</p>
// 								</Tab>
// 							</div>
// 						</TabList>
// 					</div>

// 					{/* 使用者條款 */}
// 					<TabPanel>
// 						<div className="container">
// 							<div className="privacy-and-terms-sidebar-wrapper hidden-xs hidden-sm">
// 								<ul className="sidebar">
// 									<Repeat numTimes={10}>
// 										{(index) => (
// 											<li key={index}>
// 												<Link
// 													activeClass="active"
// 													to={`user_agreement_section_${index}`}
// 													spy={true}
// 													smooth={true}
// 													offset={-80}
// 													duration={400}>
// 													<h5
// 														dangerouslySetInnerHTML={{
// 															__html: t(`user_agreement_title_${index}`),
// 														}}
// 													/>
// 												</Link>
// 											</li>
// 										)}
// 									</Repeat>
// 								</ul>
// 							</div>
// 							<div className="privacy-and-terms-content-wrapper">
// 								<div className="privacy-and-terms-content">
// 									<div className="inner-wrapper">
// 										<h3 className="main-title">
// 											{t("general:user_agreement")}
// 										</h3>
// 										<p className="last-revised-date">
// 											{t("general:last_revised_on")} January 22, 2019
// 										</p>

// 										<Element name="user_agreement_section_0">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_0"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_0"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="user_agreement_section_1">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_1"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_1"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="user_agreement_section_2">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_2"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_2"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="user_agreement_section_3">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_3"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_3"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="user_agreement_section_4">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_4"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_4"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="user_agreement_section_5">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_5"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_5"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="user_agreement_section_6">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_6"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_6"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="user_agreement_section_7">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_7"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_7"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="user_agreement_section_8">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_8"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_8"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="user_agreement_section_9">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_title_9"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("user_agreement_content_9"),
// 												}}
// 											/>
// 										</Element>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</TabPanel>

// 					{/* 徵才企業條款 */}
// 					<TabPanel>
// 						<div className="container">
// 							<div className="privacy-and-terms-sidebar-wrapper hidden-xs hidden-sm">
// 								<ul className="sidebar">
// 									<Repeat numTimes={16}>
// 										{(index) => (
// 											<li key={index}>
// 												<Link
// 													activeClass="active"
// 													to={`employer_agreement_section_${index}`}
// 													spy={true}
// 													smooth={true}
// 													offset={-80}
// 													duration={400}>
// 													<h5
// 														dangerouslySetInnerHTML={{
// 															__html: t(
// 																`employer_agreement_section_title_${index}`,
// 															),
// 														}}
// 													/>
// 												</Link>
// 											</li>
// 										)}
// 									</Repeat>
// 								</ul>
// 							</div>
// 							<div className="privacy-and-terms-content-wrapper">
// 								<div className="privacy-and-terms-content">
// 									<div className="inner-wrapper">
// 										<h3 className="main-title">
// 											{t("general:employer_agreement")}
// 										</h3>
// 										<p className="last-revised-date">
// 											{t("general:last_revised_on")} February 28, 2023
// 										</p>

// 										<div
// 											dangerouslySetInnerHTML={{
// 												__html: t("employer_agreement_head_content"),
// 											}}
// 										/>
// 										<Repeat numTimes={16}>
// 											{(idx) => (
// 												<Element
// 													key={`dl-${idx}`}
// 													name={`employer_agreement_section_${idx}`}>
// 													<h3>
// 														{t(`employer_agreement_section_title_${idx}`)}
// 													</h3>
// 													<div
// 														dangerouslySetInnerHTML={{
// 															__html: t(`employer_agreement_part_${idx}`),
// 														}}
// 													/>
// 												</Element>
// 											)}
// 										</Repeat>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</TabPanel>

// 					{/* 隱私權政策 */}
// 					<TabPanel>
// 						<div className="container">
// 							<div className="privacy-and-terms-sidebar-wrapper hidden-xs hidden-sm">
// 								<ul className="sidebar">
// 									<Repeat numTimes={6}>
// 										{(index) => (
// 											<li key={index}>
// 												<Link
// 													activeClass="active"
// 													to={`privacy_policy_section_${index}`}
// 													spy={true}
// 													smooth={true}
// 													offset={-80}
// 													duration={400}>
// 													<h5
// 														dangerouslySetInnerHTML={{
// 															__html: t(`privacy_policy_title_${index}`),
// 														}}
// 													/>
// 												</Link>
// 											</li>
// 										)}
// 									</Repeat>
// 								</ul>
// 							</div>
// 							<div className="privacy-and-terms-content-wrapper">
// 								<div className="privacy-and-terms-content">
// 									<div className="inner-wrapper">
// 										<h3 className="main-title">
// 											{t("general:privacy_policy")}
// 										</h3>
// 										<p className="last-revised-date">
// 											{t("general:last_revised_on")} January 22, 2019
// 										</p>

// 										<Element name="privacy_policy_section_0">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_title_0"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_content_0"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="privacy_policy_section_1">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_title_1"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_content_1"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="privacy_policy_section_2">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_title_2"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_content_2"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="privacy_policy_section_3">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_title_3"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_content_3"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="privacy_policy_section_4">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_title_4"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_content_4"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="privacy_policy_section_5">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_title_5"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("privacy_policy_content_5"),
// 												}}
// 											/>
// 										</Element>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</TabPanel>

// 					{/* Cookie 政策 */}
// 					<TabPanel>
// 						<div className="container">
// 							<div className="privacy-and-terms-sidebar-wrapper hidden-xs hidden-sm">
// 								<ul className="sidebar">
// 									<Repeat numTimes={9}>
// 										{(index) => (
// 											<li key={index}>
// 												<Link
// 													activeClass="active"
// 													to={`cookie_policy_section_${index}`}
// 													spy={true}
// 													smooth={true}
// 													offset={-80}
// 													duration={400}>
// 													<h5
// 														dangerouslySetInnerHTML={{
// 															__html: t(`cookie_policy_title_${index}`),
// 														}}
// 													/>
// 												</Link>
// 											</li>
// 										)}
// 									</Repeat>
// 								</ul>
// 							</div>
// 							<div className="privacy-and-terms-content-wrapper">
// 								<div className="privacy-and-terms-content">
// 									<div className="inner-wrapper">
// 										<h3 className="main-title">{t("general:cookie_policy")}</h3>
// 										<p className="last-revised-date">
// 											{t("general:last_revised_on")} January 22, 2019
// 										</p>

// 										<div
// 											dangerouslySetInnerHTML={{
// 												__html: t("cookie_policy_intro_content"),
// 											}}
// 										/>
// 										<Element name="cookie_policy_section_0">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_title_0"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_content_0"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="cookie_policy_section_1">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_title_1"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_content_1"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="cookie_policy_section_2">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_title_2"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_content_2"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="cookie_policy_section_3">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_title_3"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_content_3"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="cookie_policy_section_4">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_title_4"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_content_4"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="cookie_policy_section_5">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_title_5"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_content_5"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="cookie_policy_section_6">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_title_6"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_content_6"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="cookie_policy_section_7">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_title_7"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_content_7"),
// 												}}
// 											/>
// 										</Element>
// 										<Element name="cookie_policy_section_8">
// 											<h3
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_title_8"),
// 												}}
// 											/>
// 											<div
// 												dangerouslySetInnerHTML={{
// 													__html: t("cookie_policy_content_8"),
// 												}}
// 											/>
// 										</Element>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</TabPanel>

// 					{/* 更新總覽 */}
// 					<TabPanel>
// 						<div className="container">
// 							<div className="privacy-and-terms-content summary-of-changes">
// 								<div className="inner-wrapper">
// 									<h3 className="main-title">
// 										{t("general:summary_of_changes")}
// 									</h3>
// 									<p className="last-revised-date">
// 										{t("general:last_revised_on")} February 28, 2023
// 									</p>
// 									<hr />
// 									<h3 className="sub-title">
// 										{t("general:employer_agreement")}
// 									</h3>
// 									<div
// 										dangerouslySetInnerHTML={{
// 											__html: t(
// 												"summary_of_changes_employer_agreement_content",
// 											),
// 										}}
// 									/>
// 								</div>
// 							</div>
// 						</div>
// 					</TabPanel>
// 				</Tabs>
// 			</div>
// 		);
// 	}
// }

export default withRouter(
	withNamespaces(["frontend_privacy_and_terms_page", "general"])(
		PrivacyAndTermsPagef,
	),
);
