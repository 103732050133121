import Abstract from './abstract';
import moment from "moment";

class Photo extends Abstract {

    formatted_url() {
        if(!this) return '';
        if(!this.asset) return '';
        if(!this.asset.url) return '';
        return this.created_at ? `${this.asset.url}?${moment(this.created_at).unix()}` : this.asset.url;
    }

    formatted_sharing_url() {
        if(!this) return '';
        if(!this.asset) return '';
        if(!this.asset.sharing) return '';
        if(!this.asset.sharing.url) return '';
        return this.created_at ? `${this.asset.sharing.url}?${moment(this.created_at).unix()}` : this.asset.sharing.url;
    }

    formatted_gallery_url() {
        if(!this) return '';
        if(!this.asset) return '';
        if(!this.asset.gallery) return '';
        if(!this.asset.gallery.url) return '';
        return this.created_at ? `${this.asset.gallery.url}?${moment(this.created_at).unix()}` : this.asset.gallery.url;
    }
}

export default Photo;
