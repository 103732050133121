import React from 'react';
import { Link } from "react-router-dom";
import Config from "./../../../models/config.js";
import ApiHeaders from "./../../../models/api_headers";
import axios from "axios";
import { get_value_from_query_string, page_smothly_scroll_to } from "../../../../assets/js/functions.js";
import { withNamespaces, Interpolate } from "react-i18next";
import Employer from "./../../../models/employer";
import Role from "../../../models/role";
import icon_add from "../../../../assets/images/icon/icon_24_add_B100.svg";
import InternalUri from "../../../models/internal_uri";
import Roleship from "../../../models/roleships";
import default_avatar from "./../../../../assets/images/image_default_avatar.svg";

const config = new Config();
const headers = new ApiHeaders();

class MemberManagementPage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.updateActiveCompany = this.updateActiveCompany.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.toggleAddMemberModal = this.toggleAddMemberModal.bind(this);
        this.toggleEditMemberModal = this.toggleEditMemberModal.bind(this);
        this.toggleEditInvitedMemberModal = this.toggleEditInvitedMemberModal.bind(this);
        this.state = {
            active_user_id: -1,
            active_company_id: -1,
            creator_user_id: -1,
            active_employer_name: "",
            roles: [],
            access_control_list: {},
            loading: true,
            error_messages: {},
        };
    };

    componentDidMount() {
        this._isMounted = true;

        const { current_user } = this.props;
        this.setState({
            access_control_list: current_user.employer_roles[current_user.active_company.id].access_control_list,
        });
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${this.props.current_user.active_company.id}?include=creator`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_company = response.data;
                    const company = new Employer(raw_company);

                    const { current_user } = this.props;
                    this.setState((prev_state) => ({
                        ...prev_state,
                        creator_user_id: company.creator.id,
                        active_employer_name: company.formatted_name()
                    }), () => this.updateActiveCompany(current_user.active_company.id));
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                        loading: false
                    }));

                    page_smothly_scroll_to(0, 300);
                }
            })
    };

    UNSAFE_componentWillReceiveProps(next_props) {
        if(this.props.current_user.active_company.id !== next_props.current_user.active_company.id) {
            this.updateActiveCompany(next_props.current_user.active_company.id);
        }

        // TODO: 如果 AddMemberModal 關閉後的寫法有更換，這邊也要做調整！
        let refresh = get_value_from_query_string('refresh', next_props.location.search);
        if(refresh === 'true') {
            const internal_uri = new InternalUri();
            this.props.history.replace(internal_uri.formatted_dashboard_member_management_page_path());
            this.fetchData();
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    updateActiveCompany(company_id) {
        this.setState((prev_state) => ({
            ...prev_state,
            active_company_id: company_id,
            active_user_id: this.props.reduxTokenAuth.currentUser.attributes.id,
            loading: true,
        }), () => this.fetchData());
    };

    fetchData() {
        this.setState({ loading: true });
        let options = {
            method: 'GET',
            url: config.api_url(`/employers/${this.state.active_company_id}/roles?include=roleships`),
            headers: headers.getItemsFromLocalStorage(),
            json: true
        };
        let roles = [];
        axios(options)
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_roles = response.data.collection;

                    for(let i=0; i<raw_roles.length; i++) {
                        const role = new Role(raw_roles[i]);

                        if(role.is_admin) {
                            let roleship_owner = {};
                            let roleships_exclude_owner = [];
                            for(let j=0; j<role.roleships.length; j++) {
                                const roleship = role.roleships[j];

                                if(roleship.member.id === this.state.creator_user_id) {
                                    roleship_owner = roleship;
                                } else {
                                    roleships_exclude_owner.push(roleship);
                                }
                            }

                            roles = [
                                new Role({
                                    ...role,
                                    roleships: [roleship_owner],
                                    is_owner: true
                                }),
                                new Role({
                                    ...role,
                                    roleships: roleships_exclude_owner
                                }),
                                ...roles
                            ]
                        } else {
                            roles.push(role);
                        }
                    }

                    options = {
                        method: 'GET',
                        url: config.api_url(`/employers/${this.state.active_company_id}/member_invitations`),
                        headers: headers.getItemsFromLocalStorage(),
                        json: true
                    };
                    return axios(options)
                }
            })
            .then((response) => {
                headers.updateItemsToLocalStorage(response.headers);
                this.props.updateConnectionOffModal(false);

                if(this._isMounted) {
                    const raw_roleships = response.data.collection;
                    let roleships = [];
                    for (let i=0; i<raw_roleships.length; i++) {
                        roleships.push(new Roleship(raw_roleships[i]));
                    }

                    for(let i=0; i<roles.length; i++) {
                        let invited_roleships = [];
                        for(let j=0; j<roleships.length; j++) {
                            if(roleships[j].role_id === roles[i].id && !roles[i].is_owner) {
                                invited_roleships.push(roleships[j]);
                            }
                        }
                        roles[i]['invited_roleships'] = invited_roleships;
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        roles,
                        loading: false,
                        error_messages: {}
                    }));
                }
            })
            .catch((error) => {
                if(error.response) {
                    headers.updateItemsToLocalStorage(error.response.headers);
                    if(error.response.status === 401) window.location.reload();
                }

                if(this._isMounted) {
                    if(typeof error.response === 'undefined') {
                        window.location.reload();
                        this.props.updateConnectionOffModal(true);
                    }

                    this.setState((prev_state) => ({
                        ...prev_state,
                        loading: false,
                        error_messages:
                            (error.response && error.response.data && error.response.data.status === 'error') ?
                                error.response.data.errors :
                                {full_message: 'There was an error. Please try again later.'},
                    }));

                    page_smothly_scroll_to(0, 300);
                }
            });
    };

    toggleAddMemberModal = (e, role_id) => {
        e.preventDefault();
        const { active_company_id } = this.state;
        this.props.updateAddMemberModal(true, active_company_id, role_id);
    };

    toggleEditMemberModal = (e, roleship, role) => {
        e.preventDefault();
        const { creator_user_id } = this.state;
        this.props.updateEditMemberModal(true, roleship, role, creator_user_id);
    };

    toggleEditInvitedMemberModal = (e, invited_roleship, role) => {
        e.preventDefault();
        const { active_company_id, creator_user_id } = this.state;
        this.props.updateEditInvitedMemberModal(true, active_company_id, invited_roleship, role, creator_user_id);
    };

    render() {
        const { active_user_id, access_control_list, active_employer_name, roles, loading, error_messages } = this.state;
        const { toggleAddMemberModal, toggleEditMemberModal, toggleEditInvitedMemberModal } = this;
        const { t } = this.props;
        const internal_uri = new InternalUri();

        if(loading)
            return (
                <div className="loading-skeleton-wrapper">
                    <div className="dashboard-member-management">
                        <div className="container-fluid">
                            <h6 className="breadcrumb">
                                <Link to={internal_uri.formatted_dashboard_index_page_path()}>{t('general:home')}</Link> / {t('general:member_management')}
                            </h6>

                            <div className="member-management-wrapper">
                                <h2>{t('general:member_management')}</h2>
                                <div>
                                    <h4 className="title-outside">
                                        <Interpolate
                                            i18nKey="owner_of__"
                                            useDangerouslySetInnerHTML={true}
                                            employer_name={active_employer_name}
                                        />
                                    </h4>
                                    <div className="members">
                                        <div className="member block">
                                            <div className="avatar">
                                                <div className="image-wrapper" />
                                            </div>
                                            <h6 className="title text text-80">{`${t('general:loading')}...`}</h6>
                                            <h4 className="name-wrapper text text-80">{`${t('general:loading')}...`}</h4>
                                            <h6 className="email text text-160">{`${t('general:loading')}...`}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        return (
            <div className="dashboard-member-management">
                <div className="container-fluid">
                    <h6 className="breadcrumb">
                        <Link to={internal_uri.formatted_dashboard_index_page_path()}>{t('general:home')}</Link> / {t('general:member_management')}
                    </h6>

                    <div className="member-management-wrapper">
                        <h2>{t('general:member_management')}</h2>
                        {(error_messages.full_message) ? <h6 className="message error-message">{error_messages.full_message}</h6> : ''}

                        {
                            roles.map((role, i) => {
                                    return (
                                        <div key={i}>
                                            <h4 className="title-outside">
                                                {
                                                    (role.is_owner) ?
                                                        <Interpolate
                                                            i18nKey="owner_of__"
                                                            useDangerouslySetInnerHTML={true}
                                                            employer_name={active_employer_name}
                                                        /> : role.formatted_name()
                                                }
                                            </h4>
                                            {
                                                (!role.is_owner) ?
                                                    <div className="badges">
                                                        <div
                                                            className={`badge badge-hollow ${
                                                                (role.access_control_list.job_applications_manageable) ?
                                                                    'badge-green' :
                                                                    'badge-grey'
                                                            }`}
                                                        >
                                                            {t('general:applications')}
                                                        </div>
                                                        <div
                                                            className={`badge badge-hollow ${
                                                                (role.access_control_list.jobs_manageable) ?
                                                                    'badge-green' :
                                                                    'badge-grey'
                                                            }`}                                                    >
                                                            {t('general:jobs')}
                                                        </div>
                                                        <div
                                                            className={`badge badge-hollow ${
                                                                (role.access_control_list.setting_manageable) ?
                                                                    'badge-green' :
                                                                    'badge-grey'
                                                            }`}                                                    >
                                                            {t('general:employer_profile')}
                                                        </div>
                                                        <div
                                                            className={`badge badge-hollow ${
                                                                (role.access_control_list.plan_manageable && role.access_control_list.billing_manageable) ?
                                                                    'badge-green' :
                                                                    'badge-grey'
                                                            }`}                                                    >
                                                            {t('general:payment_and_invoices')}
                                                        </div>
                                                        <div
                                                            className={`badge badge-hollow ${
                                                                (role.access_control_list.roles_manageable) ?
                                                                    'badge-green' :
                                                                    'badge-grey'
                                                            }`}                                                    >
                                                            {t('general:member_management')}
                                                        </div>
                                                    </div> : ''
                                            }
                                            <div className="members">
                                                {
                                                    role.roleships.map((roleship, i) => {
                                                        return (
                                                            <div
                                                                className={`member ${(access_control_list.roles_manageable || roleship.member.id===active_user_id) ? 'card' : 'block'}`}
                                                                key={i}
                                                                onClick={ e => (access_control_list.roles_manageable || roleship.member.id===active_user_id) ? toggleEditMemberModal(e, roleship, role) : null }
                                                            >
                                                                <div className="avatar">
                                                                    <div
                                                                        className="image-wrapper"
                                                                        style={{backgroundImage: `url(${roleship.member.formatted_avatar_applying_url()})`}}
                                                                    />
                                                                </div>
                                                                <h6 className="title">{roleship.formatted_form_title()}</h6>
                                                                {
                                                                    (active_user_id === roleship.member.id) ?
                                                                        <h4 className="name-wrapper">
                                                                            <span className="name">{roleship.member.formatted_name()}</span>
                                                                            <span className="you">({t('you')})</span>
                                                                        </h4> :
                                                                        <h4 className="name-wrapper">{roleship.member.formatted_name()}</h4>
                                                                }
                                                                <h6 className="email">{roleship.member.email}</h6>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    role.invited_roleships.map((invited_roleship, i) => {
                                                        return (
                                                            <div
                                                                className={`member ${access_control_list.roles_manageable ? 'card' : 'block'}`}
                                                                key={i}
                                                                onClick={ e => access_control_list.roles_manageable ? toggleEditInvitedMemberModal(e, invited_roleship, role) : null }
                                                            >
                                                                <div className="avatar">
                                                                    <div
                                                                        className="image-wrapper"
                                                                        style={{backgroundImage: `url(${default_avatar})`}}
                                                                    />
                                                                </div>
                                                                <span className={`badge ${invited_roleship.state==='expired' ? 'badge-yellow' : 'badge-light-grey'}`}>
                                                                    {t(`general:${invited_roleship.state}`)}
                                                                </span>
                                                                <h6 className="email">{invited_roleship.email}</h6>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    (!role.is_owner && access_control_list.roles_manageable) ?
                                                        <div
                                                            className="member btn-add-member"
                                                            onClick={ e => toggleAddMemberModal(e, role.id) }
                                                        >
                                                            <h5><img src={icon_add} alt=""/>{t('add_member')}</h5>
                                                        </div> : ''
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces(['dashboard_member_management_page', 'general'])(MemberManagementPage);
