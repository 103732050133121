import C from "../constants";

export const site_state = (state = {}, action) => {
  switch (action.type) {
    // frontend

    case C.UPDATE_APPLY_MODAL:
      return {
        ...state,
        apply_modal: {
          open: action.open,
          job: action.job,
          referrer: action.referrer,
        },
        isAnyModalOpen: Boolean(action.open),
      };

    case C.UPDATE_INVITE_TO_APPLY_MODAL:
      return {
        ...state,
        invite_to_apply_modal: {
          open: action.open,
          job: action.job,
        },
        isAnyModalOpen: Boolean(action.open),
      };

    // dashboard

    case C.UPDATE_EMPLOYER_MESSAGE_MODAL:
      return {
        ...state,
        employer_message_modal: {
          open: action.open,
          company_id: action.company_id,
          job_id: action.job_id,
          application_id: action.application_id,
        },
        isAnyModalOpen: Boolean(action.open),
      };
    case C.UPDATE_CREATE_EMPLOYER_MODAL:
      return {
        ...state,
        create_employer_modal: action.create_employer_modal,
        isAnyModalOpen: Boolean(action.create_employer_modal),
      };
    case C.UPDATE_UPDATE_DEFAULT_PLAN_MODAL:
      return {
        ...state,
        update_default_plan_modal: {
          open: action.open,
          plan_name: action.plan_name,
          contract_url: action.contract_url,
        },
        isAnyModalOpen: Boolean(action.open),
      };
    case C.UPDATE_ADD_MEMBER_MODAL:
      return {
        ...state,
        add_member_modal: {
          open: action.open,
          company_id: action.company_id,
          role_id: action.role_id,
        },
        isAnyModalOpen: Boolean(action.open),
      };
    case C.UPDATE_EDIT_MEMBER_MODAL:
      return {
        ...state,
        edit_member_modal: {
          open: action.open,
          roleship: action.roleship,
          role: action.role,
          creator_user_id: action.creator_user_id,
        },
        isAnyModalOpen: Boolean(action.open),
      };
    case C.UPDATE_EDIT_INVITED_MEMBER_MODAL:
      return {
        ...state,
        edit_invited_member_modal: {
          open: action.open,
          employer_id: action.employer_id,
          invited_roleship: action.invited_roleship,
          role: action.role,
          creator_user_id: action.creator_user_id,
        },
        isAnyModalOpen: Boolean(action.open),
      };
    case C.UPDATE_CONTACT_MEET_JOBS_MODAL:
      return {
        ...state,
        contact_meet_jobs_modal: action.contact_meet_jobs_modal,
        isAnyModalOpen: Boolean(action.contact_meet_jobs_modal),
      };
    case C.UPDATE_WELCOME_EMPLOYER_MODAL:
      return {
        ...state,
        welcome_employer_modal: {
          open: action.open,
          banner_img_url: action.banner_img_url,
          title: action.title,
          content: action.content,
          and_content: action.and_content,
          plus_content: action.plus_content,
        },
        isAnyModalOpen: Boolean(action.open),
      };

    case C.UPDATE_EMPLOYER_PROFILE_PENDING_DEGREE_OF_COMPLETION:
      return {
        ...state,
        employer_profile_pending_degree_of_completion:
          action.employer_profile_pending_degree_of_completion,
      };

    // userpanel

    case C.UPDATE_CANDIDATE_MESSAGE_MODAL:
      return {
        ...state,
        candidate_message_modal: {
          open: action.open,
          job_id: action.job_id,
          application_id: action.application_id,
        },
        isAnyModalOpen: Boolean(action.open),
      };
    case C.UPDATE_REFERRAL_MESSAGE_MODAL:
      return {
        ...state,
        referral_message_modal: {
          open: action.open,
          job_id: action.job_id,
          application_id: action.application_id,
        },
        isAnyModalOpen: Boolean(action.open),
      };
    case C.UPDATE_ADD_REFERENCE_NOTES_MODAL:
      return {
        ...state,
        add_reference_notes_modal: {
          open: action.open,
          job: action.job,
          application: action.application,
        },
        isAnyModalOpen: Boolean(action.open),
      };
    case C.UPDATE_READ_REFERENCE_NOTES_MODAL:
      return {
        ...state,
        read_reference_notes_modal: {
          open: action.open,
          application: action.application,
        },
        isAnyModalOpen: Boolean(action.open),
      };
    case C.UPDATE_CLAIM_REFERRAL_REWARD_MODAL:
      return {
        ...state,
        claim_referral_reward_modal: {
          open: action.open,
          application: action.application,
        },
        isAnyModalOpen: Boolean(action.open),
      };

    case C.UPDATE_MY_REFERRAL_LINK_MODAL:
      return {
        ...state,
        my_referral_link_modal: {
          open: action.open,
        },
        isAnyModalOpen: Boolean(action.open),
      };

    // general

    case C.UPDATE_SEARCH_BAR:
      return {
        ...state,
        search_bar: action.search_bar,
      };
    case C.UPDATE_KEEP_SEARCH_BAR:
      return {
        ...state,
        keep_search_bar: action.keep_search_bar,
      };
    case C.UPDATE_MOBILE_MENU:
      return {
        ...state,
        mobile_menu: action.mobile_menu,
      };
    case C.UPDATE_SUB_MENU:
      return {
        ...state,
        sub_menu: action.sub_menu,
      };
    case C.UPDATE_DASHBOARD_SIDEBAR_COLLAPSED:
      return {
        ...state,
        dashboard_sidebar_collapsed: action.dashboard_sidebar_collapsed,
      };
    case C.UPDATE_ENTRANCE_FORM_MODAL:
      return {
        ...state,
        entrance_form_modal: action.entrance_form_modal,
        isAnyModalOpen: Boolean(action.entrance_form_modal),
      };
    case C.UPDATE_LOG_OUT_MODAL:
      return {
        ...state,
        log_out_modal: action.log_out_modal,
        isAnyModalOpen: Boolean(action.log_out_modal),
      };
    case C.UPDATE_CONNECTION_OFF_MODAL:
      return {
        ...state,
        connection_off_modal: action.connection_off_modal,
      };
    case C.ADD_FLASH_MESSAGE:
    case C.REMOVE_FLASH_MESSAGE:
    case C.OPEN_FLASH_MESSAGE:
      return {
        ...state,
        flash_messages: flash_messages(state.flash_messages, action),
      };
    case C.UPDATE_AD_CORNER_MODAL:
      return {
        ...state,
        ad_corner_modal: action.ad_corner_modal,
      };
    case C.UPDATE_AD_COVER_MODAL:
      return {
        ...state,
        ad_cover_modal: action.ad_cover_modal,
      };
    case C.CLOSE_ALL:
      return {
        ...state,
        isAnyModalOpen: false,
        search_bar: action.search_bar,
        mobile_menu: action.mobile_menu,
        sub_menu: action.sub_menu,
        entrance_form_modal: action.entrance_form_modal,
        apply_modal: {
          open: false,
          job: {},
          referrer: {},
        },
        employer_message_modal: {
          open: false,
          company_id: -1,
          job_id: -1,
          application_id: -1,
        },
        contact_meet_jobs_modal: action.contact_meet_jobs_modal,
        welcome_employer_modal: {
          open: false,
          banner_img_url: null,
          title: "",
          content: "",
          and_content: null,
          plus_content: null,
        },
        candidate_message_modal: {
          open: false,
          company_id: -1,
          job_id: -1,
          application_id: -1,
        },
        create_employer_modal: action.create_employer_modal,
        update_default_plan_modal: {
          open: false,
          plan_name: "",
          contract_url: "",
        },
        add_member_modal: {
          open: false,
          company_id: -1,
          role_id: -1,
        },
        edit_member_modal: {
          open: false,
          roleship: {},
          role: {},
          creator_user_id: -1,
        },
        edit_invited_member_modal: {
          open: false,
          employer_id: -1,
          invited_roleship: {},
          role: {},
          creator_user_id: -1,
        },
        add_reference_notes_modal: {
          open: false,
          job: {},
          application: {},
        },
        read_reference_notes_modal: {
          open: false,
          application: {},
        },
        claim_referral_reward_modal: {
          open: false,
          application: {},
        },
        my_referral_link_modal: {
          open: false,
        },
        log_out_modal: action.log_out_modal,
        flash_messages: [],
      };
    case C.CLOSE_HEADER:
      return {
        ...state,
        search_bar: action.search_bar,
        mobile_menu: action.mobile_menu,
        sub_menu: action.sub_menu,
      };
    default:
      return state;
  }
};

export const flash_messages = (state = [], action) => {
  switch (action.type) {
    case C.ADD_FLASH_MESSAGE:
      return [
        {
          id: action.id,
          open: action.open,
          text: action.text,
          status: action.status,
          display_type: action.display_type,
          action_button_text: action.action_button_text,
        },
        ...state,
      ];
    case C.REMOVE_FLASH_MESSAGE:
      let new_array = [];
      for (let i = 0; i < state.length; i++) {
        if (state[i].id !== action.id) {
          new_array.push(state[i]);
        }
      }
      return new_array;
    case C.OPEN_FLASH_MESSAGE:
      return state.map((flash_message) =>
        flash_message.id === action.id
          ? { ...flash_message, open: true }
          : flash_message
      );
    default:
      return state;
  }
};

export const search_words = (state = {}, action) => {
  switch (action.type) {
    case C.UPDATE_SEARCH_KEYWORDS:
      return {
        ...state,
        keyword: action.keyword,
        keyword_place: action.keyword_place,
      };
    default:
      return state;
  }
};

export const current_user = (state = {}, action) => {
  switch (action.type) {
    case C.UPDATE_COOKIE_POLICY_CORNER_MODAL:
      return {
        ...state,
        accepted_cookie_policy: action.accepted_cookie_policy,
      };
    case C.UPDATE_CURRENT_USER_COMPANIES:
      return {
        ...state,
        companies: companies(state.companies, action),
      };
    case C.UPDATE_CURRENT_USER_ACTIVE_COMPANY:
      return {
        ...state,
        active_company: action.active_company,
        companies: companies(state.companies, action),
      };
    case C.UPDATE_CURRENT_USER_EMPLOYER_ROLES:
      return {
        ...state,
        employer_roles: action.employer_roles,
      };
    case C.UPDATE_CURRENT_USER_REFERRAL_CODES:
      return {
        ...state,
        referral_codes: referral_codes(state.referral_codes, action),
      };
    case C.ADD_CURRENT_USER_REFERRAL_CODE:
      return {
        ...state,
        referral_codes: referral_codes(state.referral_codes, action),
      };
    case C.DELETE_CURRENT_USER_REFERRAL_CODE:
      return {
        ...state,
        referral_codes: referral_codes(state.referral_codes, action),
      };
    case C.UPDATE_CURRENT_USER_IS_REFERRAL_USER:
      return {
        ...state,
        is_referral_user: action.is_referral_user,
      };
    case C.UPDATE_CURRENT_USER_IS_EMPLOYER_USER:
      return {
        ...state,
        is_employer_user: action.is_employer_user,
      };
    case C.UPDATE_COVER_ADS_MODAL_DISMISS:
      const dismisses = [...state.cover_ads_dismiss];
      const idx = dismisses.findIndex(({ id }) => {
        return id === action.ad_dismissed.id;
      });
      if (idx >= 0) {
        dismisses[idx] = { ...action.ad_dismissed };
      } else {
        dismisses.push({ ...action.ad_dismissed });
      }
      return {
        ...state,
        cover_ads_dismiss: dismisses,
      };
    default:
      return state;
  }
};

export const companies = (state = [], action) => {
  switch (action.type) {
    case C.UPDATE_CURRENT_USER_COMPANIES:
      return [...action.companies];
    case C.UPDATE_CURRENT_USER_ACTIVE_COMPANY:
      let new_array = state.slice();
      for (let i = 0; i < new_array.length; i++) {
        if (new_array[i].id === action.active_company.id) {
          new_array[i] = action.active_company;
        }
      }
      return new_array;
    default:
      return state;
  }
};

export const referral_codes = (state = [], action) => {
  switch (action.type) {
    case C.UPDATE_CURRENT_USER_REFERRAL_CODES:
      return [...action.referral_codes];
    case C.ADD_CURRENT_USER_REFERRAL_CODE: {
      let new_array = state.slice();
      const index = new_array.indexOf(action.referral_code);
      if (index === -1) {
        new_array.push(action.referral_code);
        return new_array;
      } else {
        return new_array;
      }
    }
    case C.DELETE_CURRENT_USER_REFERRAL_CODE: {
      let new_array = state.slice();
      const index = new_array.indexOf(action.referral_code);
      if (index === -1) {
        return new_array;
      } else {
        new_array.splice(index, 1);
        return new_array;
      }
    }
    default:
      return state;
  }
};

export const ads = (state = [], action) => {
  switch (action.type) {
    case C.UPDATE_ADS:
      return [...action.ads];
    default:
      return state;
  }
};

export const ads_cover = (state = [], action) => {
  switch (action.type) {
    case C.UPDATE_ADS_COVER:
      return [...action.ads];
    default:
      return state;
  }
};

export const utm = (state = {}, action) => {
  switch (action.type) {
    case C.UPDATE_UTM:
      return { ...action.utm };
    default:
      return state;
  }
};
