import React, { Component, lazy, useEffect } from "react";

const ExperienceChartf = (props) => {
	const { t } = props;
	function drawChart(D3) {
		const color = [
			"#2768F6", //Blue (100% saturation)
			"#3C73F6", //Blue (90% saturation)
			"#517DF6", //Blue (80% saturation)
			"#6688F6", //Blue (70% saturation)
			"#7A92F6", //Blue (60% saturation)
			"#8FAAF6", //Blue (50% saturation)
			"#A4B5F6", //Blue (40% saturation)
			"#B9C0F6", //Blue (30% saturation)
			"#CECBF6", //Blue (20% saturation)
			"#E3D6F6", //Blue (10% saturation)
			"#F8E1F6", //Blue (0% saturation)
			"#E6D4F0", //Blue (10% desaturated)
			"#D4C8EA", //Blue (20% desaturated)
			"#C2BBE4", //Blue (30% desaturated)
			"#B0AFE0", //Blue (40% desaturated)
			"#9EA3DA", //Blue (50% desaturated)
			"#8C96D4", //Blue (60% desaturated)
			"#7A89CE", //Blue (70% desaturated)
			"#687DC8", //Blue (80% desaturated)
			"#5660C2", //Blue (90% desaturated)
		];

		const oneYearCombinedData = {
			yearsOfExperience: "y0_1",
			count: 1086,
			percentage: 13.78,
			label: "0~1",
		};
		const twoToThreeYearCombinedData = {
			yearsOfExperience: "y1_3",
			count: 1958,
			percentage: 24.84,
			label: "1~3",
		};
		const threeToFiveYearCombinedData = {
			yearsOfExperience: "y3_5",
			count: 2399,
			percentage: 30.44,
			label: "3~5",
		};
		// const fiveToSevenYearCombinedData = {
		// 	yearsOfExperience: "y5_7",
		// 	count: 1326,
		// 	percentage: 16.817136754391743,
		// 	label: "5~7",
		// };
		// const sevenToTenYearCombinedData = {
		// 	yearsOfExperience: "y7_10",
		// 	count: 1138,
		// 	percentage: 14.427079365079365,
		// 	label: "7~10",
		// };
		const fiveToTenYearCombinedData = {
			yearsOfExperience: "y5_10",
			count: 1632,
			percentage: 20.71,
			label: "5~10",
		};
		const overTenYearCombinedData = {
			yearsOfExperience: "y10",
			count: 1003,
			percentage: 10.24,
			label: "10+",
		};
		const combinedData = [
			oneYearCombinedData,
			twoToThreeYearCombinedData,
			threeToFiveYearCombinedData,
			fiveToTenYearCombinedData,
			overTenYearCombinedData,
		];
		// set the dimensions and margins of the graph
		const margin = { top: 10, right: 10, bottom: 30, left: 50 },
			width = 450 - margin.left - margin.right,
			height = 450 - margin.top - margin.bottom;

		const D3SVG = D3.selectAll("#experienceChart")
			.append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", `translate(${margin.left},${margin.top})`);

		// X axis
		const x = D3.scaleBand()
			.range([0, width])
			.domain(combinedData.map((d) => d.label))
			.padding(0.2);

		D3SVG.append("g")
			.attr("transform", `translate(0, ${height})`)
			.call(D3.axisBottom(x))
			.selectAll("text")
			// .attr("transform", "translate(5,0)rotate(45)")
			// .style("text-anchor", "start")
			.text((d) => d);
		// .attr("transform", "translate(-10,0)rotate(-45)")
		//

		// Add Y axis
		const y = D3.scaleLinear()
			.domain([0, D3.max(combinedData, (d) => d.percentage)])
			.range([height, 0]);

		D3SVG.append("g").call(D3.axisLeft(y).tickFormat((d) => `${d}%`));

		// Bars
		D3SVG.selectAll("rect")
			.data(combinedData)
			.join("rect")
			.attr("x", (d) => x(d.label))
			.attr("y", (d) => y(d.percentage))
			.attr("width", x.bandwidth())
			.attr("height", (d) => height - y(d.percentage))
			.attr("fill", (d, i) => (i < 2 ? "#b8cbe6" : "rgb(39, 104, 246)"))
			// add rounded corners to bar
			.attr("rx", 6)
			.style("opacity", 1)

			.on("mouseover", function() {
				return tooltip.style("visibility", "visible");
			})
			.on("mousemove", function(event, d) {
				// console.log(d);
				return (
					tooltip
						.style("top", event.layerY + 10 + "px")
						.style("left", event.layerX + 10 + "px")
						// get data from data and change the text
						.html(
							`
							<div style="white-space:nowrap;font-weight:bold">
							 ${t(d.yearsOfExperience)}:
								<span style="color:#2768F6">
								${Math.round(Number(d.percentage))}%
								</span>
							</div>
							
							 `,
						)
				);
			})
			.on("mouseout", function() {
				return tooltip.style("visibility", "hidden");
			});

		D3SVG.selectAll("text")
			.data(combinedData)
			.enter()
			.append("text")
			.text((d) => `${Math.round(d.percentage)}%`)
			.attr("x", (d, i) => i * x.bandwidth())
			.attr("y", (d) => height - y(d.percentage) - 5);

		// make axis has same color as bar
		D3SVG.selectAll("path").attr("stroke", "white");
		D3SVG.selectAll("line").attr("stroke", "white");
		D3SVG.selectAll("text")
			.attr("fill", "rgb(39, 104, 246)")
			.style("font-weight", "bold");

		// Add horizontal reference line
		const yTicks = y.ticks();
		D3SVG.append("g")
			.attr("class", "reference-line")
			.selectAll("line")
			.data(yTicks)
			.join("line")
			.attr("x1", 0)
			.attr("y1", (d) => y(d))
			.attr("x2", width)
			.attr("y2", (d) => y(d))
			.attr("stroke", "rgb(39, 104, 246)")
			.attr("stroke-width", 1.5)
			.style("opacity", 0.3);

		//  add tooltip
		const tooltip = D3.select(`#experienceChart`)
			.append("div")
			.style("position", "absolute")
			.style("visibility", "hidden")
			.style("background-color", "white")
			.style("backdrop-filter", "blur(10px)")
			.style("border", "solid #ccc")
			.style("border-width", "1px")
			.style("border-radius", "5px")
			.style("padding", "10px")
			.style("color", "black")
			.style("box-shadow", "0 0 10px rgba(0,0,0,0.25)")
			.style("font-size", "1rem")
			.style("opacity", 0.95)
			.style("text-align", "left");
	}
	useEffect(() => {
		import("d3").then((d3) => {
			drawChart(d3);
		});
	}, []);
	return (
		<div
			style={{
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "space-around",
				alignItems: "center",
				gap: "5%",
				rowGap: "5rem",
			}}>
			<div
				style={{
					textAlign: "start",
					maxWidth: "600px",
					minWidth: "400px",
				}}>
				{/* <h3>nice chart too!</h3> */}
				{/* <p style={{ fontSize: "1.3rem", lineHeight: "2rem" }}>
					1 - 10 年工作經驗的用戶超過 75%。
				</p> */}
				<p
					style={{
						fontSize: "1.4rem",
						fontWeight: "bold",
						lineHeight: "2.5rem",
					}}>
					{t("experienceChartTitle")}
				</p>
			</div>
			<div id="experienceChart"></div>
		</div>
	);
};

export default ExperienceChartf;
