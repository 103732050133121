import React, { Fragment } from "react";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import Textarea from "react-textarea-autosize";
import { CSSTransitionGroup } from "react-transition-group";
import icon_download from "../../../../assets/images/icon/icon_24_download_B100.svg";
import DeviceDetector from "../../../models/device_detector";
import downloadjs from "downloadjs";
import icon_link from "../../../../assets/images/icon/icon_24_link_BG400.svg";

const config = new Config();
const headers = new ApiHeaders();

class UpdateDefaultPlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleDownload = this.handleDownload.bind(this);
    this.saveDefaultPlan = this.saveDefaultPlan.bind(this);

    this.state = {
      loading: false,
      form_data: {
        agreement_checked: false,
        default_plan_name: "",
      },
    };
  }

  saveDefaultPlan = (e) => {
    e.preventDefault();

    this.setState((prev_state) => ({
      ...prev_state,
      loading: true,
    }));

    let formData = new FormData();
    formData.append(
      "setting[default_plan_name]",
      this.props.site_state.update_default_plan_modal.plan_name
    );
    let options = {
      method: "PUT",
      url: config.api_url_v2(
        `/employers/${this.props.current_user.active_company.id}/setting`
      ),
      headers: headers.getItemsFromLocalStorage("multipart/form-data"),
      data: formData,
      json: true,
    };
    return axios(options)
      .then((response) => {
        // headers.updateItemsToLocalStorage(response.headers);
        // this.props.updateConnectionOffModal(false);
        // interface response.data = {
        //   "id": 2,
        //   "onboard_reward": false,
        //   "locale": "en",
        //   "priority": 2,
        //   "review_resume": false,
        //   "default_plan_name": "post_only"
        // }
        // let form_data = {
        //   default_plan_name:
        //     response.data && response.data.default_plan_name
        //       ? response.data.default_plan_name
        //       : "post_only",
        // };
        // this.setState((prev_state) => ({
        //   ...prev_state,
        //   form_data: JSON.parse(JSON.stringify(form_data)),
        //   original_form_data: JSON.parse(JSON.stringify(form_data)),
        //   has_pending_data: false,
        //   error_messages: {},
        //   loading: false,
        // }));
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));
        }

        return null;
      });
  };

  handleAgreementChange = (e) => {
    e.preventDefault();
    this.setState((prev_state) => ({
      ...prev_state,
      form_data: {
        ...prev_state.form_data,
        agreement_checked: !prev_state.form_data.agreement_checked,
      },
    }));
  };

  handleDownload = (e, url) => {
    e.preventDefault();
    downloadjs(url);
  };

  render() {
    const { site_state, t } = this.props;
    const { handleDownload, handleAgreementChange } = this;
    const { form_data, loading } = this.state;

    const closeModal = (e) => {
      e.preventDefault();
      this.props.updateUpdateDefaultPlanModal(false);
    };

    const device_detector = new DeviceDetector();

    return (
      <CSSTransitionGroup
        component="div"
        transitionName="modal-wrapper-with-modal-slide"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {site_state.update_default_plan_modal &&
        site_state.update_default_plan_modal.open ? (
          <div className="dashboard-upgrade-plans">
            <div className="modal-wrapper create-employer-modal-wrapper">
              <div className="modal-inner-wrapper">
                <div className="modal-bg" onClick={(e) => closeModal(e)} />
                <div className="modal create-employer-modal">
                  <div className="btn-close" onClick={(e) => closeModal(e)} />

                  <div className="container-fluid">
                    {site_state.update_default_plan_modal.contract_url ? (
                      <section className="section-contract">
                        <div className="desktop-wrapper">
                          <h4>{t("general:employer_user_agreement")}</h4>
                          <a
                            className="btn btn-larger btn-float btn-hollow btn-with-icon"
                            href={
                              site_state.update_default_plan_modal.contract_url
                            }
                            target="_blank"
                          >
                            {t("general:contract_signed_check_details")}
                            <img
                              src={icon_link}
                              alt=""
                              className="icon-link"
                              width={20}
                            />
                          </a>
                        </div>
                      </section>
                    ) : (
                      <Fragment>
                        <section className="section-contract">
                          <div className="desktop-wrapper">
                            <h4>
                              {t("general:employer_user_agreement")}
                              {device_detector.is_mobile_or_tablet() ? (
                                <a
                                  className="btn btn-larger btn-float btn-hollow btn-with-icon"
                                  href={`/public_assets/contracts/${t(
                                    "general:contract_file_name"
                                  )}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <h5>
                                    <img src={icon_download} alt="" />
                                    {t("general:download")}
                                  </h5>
                                </a>
                              ) : (
                                <button
                                  className="btn btn-larger btn-float btn-hollow btn-with-icon"
                                  onClick={(e) =>
                                    handleDownload(
                                      e,
                                      `/public_assets/contracts/${t(
                                        "general:contract_file_name"
                                      )}`
                                    )
                                  }
                                >
                                  <h5>
                                    <img src={icon_download} alt="" />
                                    {t("general:download")}
                                  </h5>
                                </button>
                              )}
                            </h4>
                            <Textarea
                              className="textarea"
                              minRows={12}
                              maxRows={12}
                              value={t(
                                "frontend_employer_agreement_online_text:text_version"
                              )}
                              readOnly
                              id="contract_content"
                            />
                          </div>
                        </section>
                      </Fragment>
                    )}
                    <section className="section-contract">
                      <div className="desktop-wrapper">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          id="accept-agreement"
                          checked={form_data.agreement_checked}
                          // readOnly
                        />
                        <label
                          className="checkbox"
                          onClick={(e) => handleAgreementChange(e)}
                        >
                          <p>
                            {t(
                              "general:i_accept_meet_jobs_employer_user_agreement"
                            )}
                          </p>
                        </label>
                      </div>
                    </section>

                    <section className="section-go-create">
                      <div className="desktop-wrapper">
                        <div className="buttons-wrapper">
                          {form_data.agreement_checked && !loading ? (
                            <button
                              className="btn btn-larger btn-float btn-fill"
                              onClick={(e) => this.saveDefaultPlan(e)}
                            >
                              <h5>{t("general:save_default_plan")}</h5>
                            </button>
                          ) : (
                            <button
                              onClick={(e) => e.preventDefault()}
                              className="btn btn-larger btn-float btn-fill disabled"
                            >
                              <h5>{t("general:save_default_plan")}</h5>
                            </button>
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </CSSTransitionGroup>
    );
  }
}

export default withNamespaces([
  "dashboard_upgrade_plans_page",
  "general",
  "frontend_employer_agreement_online_text",
  "dashboard_modals",
])(UpdateDefaultPlanModal);
