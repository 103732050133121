import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Config from "../../../models/config";
import User from "../../../models/user";
import ApiHeaders from "../../../models/api_headers";
import { withNamespaces } from "react-i18next";
import welcome_1 from "../../../../assets/images/confirm_done_upload_resume.svg";
import welcome_2 from "../../../../assets/images/confire_done_consoltant.svg";
import welcome_3 from "../../../../assets/images/confirm_done_open_search.svg";
import { get_value_from_query_string } from "../../../../assets/js/functions";
import InternalUri from "../../../models/internal_uri";
import { update } from "draft-js/lib/DefaultDraftBlockRenderMap";
import axios from "axios";
import ReactLoading from "react-loading";
const config = new Config();
const headers = new ApiHeaders();
const internal_uri = new InternalUri();
class ConfirmDonePage extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			show_reset_password_warning: false,
			active_user_id: -1,
			publishing: false,
			resume: null,
			resumeUploadSuccess: false,
			resumeUploadError: [],
			form_data: {},
			is_uploading: false,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		// console.log("ConfirmDonePage.componentDidMount", this.props);
		if (!this.props.reduxTokenAuth.currentUser.isSignedIn) {
			const { location } = this.props;
			this.props.history.push(
				`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${
					location.pathname
				}${location.search}`,
			);
		} else {
			const query = decodeURI(this.props.location.search);
			if (query) {
				let reset_password_warning = get_value_from_query_string(
					"reset_password_warning",
					query,
				);
				if (reset_password_warning === "true") {
					this.setState({ show_reset_password_warning: true });
				}
			}

			this.setState(
				{
					active_user_id: this.props.reduxTokenAuth.currentUser.attributes.id,
				},
				() => this.fetchData(),
			);
		}
	}

	UNSAFE_componentWillReceiveProps(next_props) {
		if (
			this.props.reduxTokenAuth.currentUser.isSignedIn !==
			next_props.reduxTokenAuth.currentUser.isSignedIn
		) {
			if (!next_props.reduxTokenAuth.currentUser.isSignedIn) {
				const internal_uri = new InternalUri();
				const { location } = this.props;
				this.props.history.push(
					`${internal_uri.formatted_frontend_log_in_page_path()}?go_back_path=${
						location.pathname
					}${location.search}`,
				);
			}
		}
	}
	fetchData() {
		let options = {
			method: "GET",
			url: config.api_url(
				`/users/${this.state.active_user_id}?include=resume_attachments`,
			),
			headers: headers.getItemsFromLocalStorage(),
			json: true,
		};

		axios(options)
			.then((response) => {
				headers.updateItemsToLocalStorage(response.headers);
				// this.props.updateConnectionOffModal(false);
				if (this._isMounted) {
					const raw_user = response.data;
					const user = new User(raw_user);

					let form_data = {
						resume: {
							file: {},
						},
						portfolio_url: user.formatted_portfolio_url(),
						// email_notification_checked: true,
						marketing_email: user.formatted_notification_setting_marketing_email(),
						jobs_recommendation_email: user.formatted_notification_setting_job_recommendation_email(),
						public_to_employer: !!user.resume_setting.public_to_employer,
					};

					this.setState((prev_state) => ({
						...prev_state,
						user,
						form_data,
						show_upload_resume: raw_user.default_resume_attachment
							? false
							: true,
						default_resume_attachment: raw_user.default_resume_attachment,
						original_form_data: JSON.parse(JSON.stringify(form_data)),
						has_pending_data: false,
						loading: false,
						error_messages: {},
					}));
				}
			})
			.catch((error) => {
				console.log("error", error);
				if (error.response) {
					headers.updateItemsToLocalStorage(error.response.headers);
					if (error.response.status === 401) {
						// window.location.reload();
					}
				}

				if (this._isMounted) {
					if (typeof error.response === "undefined") {
						// window.location.reload();
						// this.props.updateConnectionOffModal(true);
					}

					this.setState((prev_state) => ({
						...prev_state,
						loading: false,
						error_messages:
							error.response &&
							error.response.data &&
							error.response.data.status === "error"
								? error.response.data.errors
								: {
										full_message: "There was an error. Please try again later.",
								  },
					}));
				}
			});
	}
	uploadResume(e) {
		e.preventDefault();
		if (this.state.is_uploading) return;

		const file = e.target.files[0];
		this.setState(
			(prevState) => ({
				...prevState,
				form_data: {
					...prevState.form_data,
					resume_attachments_attributes: [
						{
							asset: file,
							official: true,
						},
					],
				},
			}),
			() => {
				this.setState({ is_uploading: true });
				const { form_data, active_user_id, publishing } = this.state;
				if (!publishing) {
					this.setState({ publishing: true });
					let formData = new FormData();
					if (
						typeof form_data.resume_attachments_attributes !== "undefined" &&
						typeof form_data.resume_attachments_attributes[0].asset !==
							"undefined"
					) {
						// formData.append('user[name]','testA' + Date.now());
						// formData.append('user[name]','testNow');
						formData.append(
							"user[resume_attachments_attributes][0][asset]",
							form_data.resume_attachments_attributes[0].asset,
						);
						formData.append(
							"user[resume_attachments_attributes][0][official]",
							form_data.resume_attachments_attributes[0].official,
						);
					}
					formData.append(
						"user[resume_setting_attributes][public_to_employer]",
						form_data.public_to_employer,
					);
					formData.append(
						"user[notification_setting_attributes][jobs_recommendation_email]",
						form_data.jobs_recommendation_email,
					);

					let options = {
						method: "PUT",
						url: config.api_url(`/users/${active_user_id}`),
						headers: headers.getItemsFromLocalStorage("multipart/form-data"),
						data: formData,
						json: true,
					};
					axios(options)
						.then((response) => {
							headers.updateItemsToLocalStorage(response.headers);
							// this.props.updateConnectionOffModal(false);

							// this.props.verifyTokenRequestSucceeded(response.data);

							if (this._isMounted) {
								this.setState(
									(prev_state) => ({
										...prev_state,
										error_messages: {},
										publishing: false,
									}),
									() => {
										this.fetchData();
										const internal_uri = new InternalUri();
										this.props.history.push(
											internal_uri.formatted_userpanel_my_resume_page_path(),
										);
									},
								);
							}
						})
						.catch((error) => {
							if (error.response) {
								headers.updateItemsToLocalStorage(error.response.headers);
								if (error.response.status === 401) {
									this.props.verifyTokenRequestFailed();
									console.log("401", error);
									// window.location.reload();
								}
							}

							if (this._isMounted) {
								if (typeof error.response === "undefined") {
									// window.location.reload();
									console.log("undefined", error);
									// this.props.updateConnectionOffModal(true);
								}

								this.setState((prev_state) => ({
									...prev_state,
									publishing: false,
									error_messages:
										error.response &&
										error.response.data &&
										error.response.data.status === "error"
											? error.response.data.errors
											: {
													full_message:
														"There was an error. Please try again later.",
											  },
								}));
							}
						});
				}
			},
		);
		if (file) {
			this.setState({ resume: file }, () => {
				// console.log("resueme", this.state.resume);
				const formData = new FormData();
				formData.append(
					"user[resume_attachments_attributes][0][asset]",
					this.state.resume,
				);
				formData.append(
					"user[resume_attachments_attributes][0][official]",
					true,
				);
				formData.append(
					"user[resume_setting_attributes][public_to_employer]",
					true,
				);
				formData.append(
					"user[notification_setting_attributes][jobs_recommendation_email]",
					true,
				);
				const options = {
					method: "put",
					url: config.api_url(`/users/${this.state.active_user_id}`),
					headers: headers.getItemsFromLocalStorage("multipart/form-data"),
					data: formData,
					json: true,
				};
				axios(options)
					.then((res) => {
						// console.log(res);
						headers.updateItemsInLocalStorage(res.headers);
						// this.props.updateConnectionOffModal(false);
						// this.props.verifyTokenRequestSucceeded(res.data);
						this.setState(
							(prevState) => ({
								...prevState,
								error_message: {},
								resumeData: res.data,
								resumeUploadSuccess: true,
								resumeUploadError: [],
							}),
							() => {
								let options = {
									method: "GET",
									url: config.api_url(
										`/users/${this.state.active_user_id}?include=resume_attachments`,
									),
									headers: headers.getItemsFromLocalStorage(),
									json: true,
								};

								axios(options)
									.then((response) => {
										headers.updateItemsToLocalStorage(response.headers);
										// this.props.updateConnectionOffModal(false);

										const raw_user = response.data;
										const user = new User(raw_user);

										let form_data = {
											resume: {
												file: {},
											},
											portfolio_url: user.formatted_portfolio_url(),
											// email_notification_checked: true,
											marketing_email: user.formatted_notification_setting_marketing_email(),
											jobs_recommendation_email: user.formatted_notification_setting_job_recommendation_email(),
											public_to_employer: !!user.resume_setting
												.public_to_employer,
										};

										this.setState((prev_state) => ({
											...prev_state,
											user,
											form_data,
											show_upload_resume: raw_user.default_resume_attachment
												? false
												: true,
											default_resume_attachment:
												raw_user.default_resume_attachment,
											original_form_data: JSON.parse(JSON.stringify(form_data)),
											has_pending_data: false,
											loading: false,
											error_messages: {},
										}));
									})
									.catch((error) => {
										if (error.response) {
											headers.updateItemsToLocalStorage(error.response.headers);
											if (error.response.status === 401)
												window.location.reload();
										}

										if (typeof error.response === "undefined") {
											window.location.reload();
											// this.props.updateConnectionOffModal(true);
										}

										this.setState((prev_state) => ({
											...prev_state,
											loading: false,
											error_messages:
												error.response &&
												error.response.data &&
												error.response.data.status === "error"
													? error.response.data.errors
													: {
															full_message:
																"There was an error. Please try again later.",
													  },
										}));
									});
							},
						);
					})

					.catch((error) => {
						if (error.response) {
							headers.updateItemsToLocalStorage(error.response.headers);
							if (error.response.status === 401) {
								this.props.verifyTokenRequestFailed();
								window.location.reload();
							}
							if (typeof error.response === "undefined") {
								window.location.reload();
								// this.props.updateConnectionOffModal(true);
							}
							this.setState((prev_state) => ({
								...prev_state,
								publishing: false,
								resumeuploadError: error.response.data,
								resumeUploadSuccess: false,
							}));
						}
					});
			});
		}
	}
	render() {
		const { show_reset_password_warning } = this.state;
		const { reduxTokenAuth } = this.props;
		const { t } = this.props;
		const internal_uri = new InternalUri();

		return (
			<div className="page-confirm-done  white">
				<div className="">
					<section
						style={{ paddingTop: "200px" }}
						className={`page-title ${
							show_reset_password_warning ? "with-system-message" : ""
						}`}>
						{show_reset_password_warning ? (
							<div className="system-message-wrapper warning">
								<p>
									{t(
										"your_email_account_has_been_used_to_register_meet_jobs__we_suggest_resetting_your_password_right_away_",
									)}
								</p>
								<Link
									to={internal_uri.formatted_frontend_reset_password_page_path()}
									className="btn btn-flat btn-fill"
									target="_blank"
									rel="noopener noreferrer">
									<h5>{t("reset_password")}</h5>
								</Link>
							</div>
						) : (
							""
						)}
						<h2>
							{t("welcome_")}{" "}
							<span className="user-name">
								{reduxTokenAuth.currentUser.attributes.name}
							</span>
							!
						</h2>
						<p>{t("you_re_all_set_")}</p>
					</section>
					<section className="page-content ">
						<div className="landing-card" style={{ padding: "3%" }}>
							<p style={{ fontSize: "2rem", fontWeight: "600" }}>
								{t(`upload_for_reason`)}
							</p>
							{this.state.resume && <a href="#">{this.state.resume.name}</a>}
							<input
								className="input-upload-file"
								id="resume"
								type="file"
								accept={
									`.pdf, ` +
									`.doc, ` +
									`.docx, ` +
									`application/pdf, ` +
									`application/msword, ` +
									`application/vnd.openxmlformats-officedocument.wordprocessingml.document`
								}
								onChange={(e) => {
									this.uploadResume(e);
								}}
							/>
							<label
								htmlFor="resume"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									margin: "0 auto",
									padding: ".5%",
									pointerEvents: this.state.is_uploading ? "none" : "auto",
								}}
								className="btn btn-larger btn-flat btn-hollow btn-upload-file">
								{this.state.is_uploading ? (
									<ReactLoading
										type="spin"
										color="#2768f6"
										height={30}
										width={30}
									/>
								) : (
									t("general:upload_resume")
								)}
							</label>
						</div>
					</section>
					<section
						className="page-content"
						style={{
							padding: "3%",
							backgroundColor: "#f5f6f7",
							width: "100%",
						}}>
						<div
							style={{
								fontSize: "2rem",
								fontWeight: 700,
								textAlign: "center",
								margin: "3rem auto",
							}}>
							{t("benefit_of_uploading_resume")}
						</div>
						<div
							style={{
								width: "90%",
								margin: "0 auto",
								display: "flex",
								justifyContent: "center",
								alignItems: "stretch",
								flexWrap: "wrap",
								gap: "1rem",
							}}>
							<div
								className="landing-card landing-card-1"
								style={{ flex: "1", padding: "1%" }}>
								<div
									className="image-wrapper"
									style={{ backgroundImage: `url(${welcome_1})` }}
								/>
								<h3>{t("only_need_upload_title")}</h3>
								<p>{t("only_need_upload")}</p>
								{/* <p>{t("start_to_search_and_view_all_jobs")}</p> */}
								{/* <Link
										to={internal_uri.formatted_frontend_jobs_page_path()}
										className="btn btn-larger btn-flat btn-fill"
										data-event-category="017_welcome_jobs">
										<h5>{t("see_all_jobs")}</h5>
									</Link> */}
							</div>

							<div
								className="landing-card landing-card-2"
								style={{ flex: "1", padding: "1%" }}>
								<div
									className="image-wrapper"
									style={{ backgroundImage: `url(${welcome_2})` }}
								/>
								<h3>{t("free_consoltant_title")}</h3>
								<p>
									{t("free_consoltant_1")}
									<a
										style={{ color: "#2768f6", fontWeight: 600 }}
										href={internal_uri.formatted_frontend_job_page_path(
											"7669",
											"career-consulting-service-職涯諮詢服務",
										)}>
										{t("free_consultation")}
									</a>
									{t("free_consoltant_2")}
								</p>
							</div>

							<div
								className="landing-card landing-card-3"
								style={{ flex: "1", padding: "1%" }}>
								<div
									className="image-wrapper"
									style={{ backgroundImage: `url(${welcome_3})` }}
								/>
								<h3>{t("let_employer_see_title")}</h3>
								<p>
									{t("enable")}
									<a
										style={{ color: "#2768f6", fontWeight: 600 }}
										href={internal_uri.formatted_userpanel_my_resume_page_path()}>
										{t("open_resume")}
									</a>
									{t("let_employer_see")}
								</p>
								{/* <Link
									to={internal_uri.formatted_frontend_employer_landing_page_path()}
									className="btn btn-larger btn-flat btn-fill"
									data-event-category="019_welcome_employer">
									<h5>{t("see_plans")}</h5>
								</Link> */}
							</div>
						</div>
					</section>
					<section
						style={{
							padding: "3%",
							backgroundColor: "white",
							textAlign: "center",
						}}>
						<input
							className="input-upload-file"
							id="resume"
							type="file"
							accept={
								`.pdf, ` +
								`.doc, ` +
								`.docx, ` +
								`application/pdf, ` +
								`application/msword, ` +
								`application/vnd.openxmlformats-officedocument.wordprocessingml.document`
							}
							onChange={(e) => {
								this.uploadResume(e);
							}}
						/>
						<label
							htmlFor="resume"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								margin: "0 auto",
								padding: ".5%",
								pointerEvents: this.state.is_uploading ? "none" : "auto",
							}}
							className="btn btn-larger btn-flat btn-hollow btn-upload-file">
							{this.state.is_uploading ? (
								<ReactLoading
									type="spin"
									color="#2768f6"
									height={30}
									width={30}
								/>
							) : (
								t("general:upload_resume")
							)}
						</label>
					</section>
				</div>
			</div>
		);
	}
}

export default withRouter(
	withNamespaces(["frontend_confirm_done_page", "general"])(ConfirmDonePage),
);
