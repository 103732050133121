export default {
    // matching
    processing: "待處理",
    not_recommended: "不建議",
    recommended: "建議",

    // applications
    applying: "應徵中",
    rejected: "未錄取",
    cancelled: "已取消",
    confirmed: "已錄取",

    // applier state key
    // applying: "Applying",
    // cancelled: "Cancelled",
    reported: "已回報錄取",

    // employer state key
    initialized: "無狀態",
    // rejected: "未錄取",
    // confirmed: "Confirmed",
    archived: "已封裝",
    processed: "Processed",

    // referrer state key
    reviewed: "審核中",
    claimed: "已回報錄取",

    // jobs
    all: "全部",
    draft: "草稿",
    published: "已發佈",
    closed: "已關閉",

    // paid period
    annually: "年薪",
    monthly: "月薪",
    daily: "日薪",
    hourly: "時薪",

    // work type
    full_time: "全職",
    part_time: "兼職",
    internship: "實習",
    volunteer: "志工",

    // contract type
    permanent: "正式",
    contract: "約聘",

    // company size
    company_size_0: "不提供",
    company_size_1: "1 - 10",
    company_size_2: "11 - 50",
    company_size_3: "51 - 200",
    company_size_4: "201 - 500",
    company_size_5: "501 - 1,000",
    company_size_6: "1,001 - 5,000",
    company_size_7: "5,001 - 10,000",
    company_size_8: "10,000+",

    // invoice payment status
    paid: "已付款",
    // cancelled: "Cancelled",
    overdue: "已過期",

    // paid type
    remittance: "匯款",
    stripe: "Stripe",
};