export default {
    efficient_recruitment_with_social_referral: "Efficient Recruitment with Social Referral",
    speed_of_internet_startup_and_quality_of_headhunter_service_to_leverage_on_professional_social_networks_let_the_whole_world_get_you_right_talent_: "Speed of internet startup and quality of headhunter service. To leverage on professional social networks, let the whole world get you right talent!",
    refer_a_job: "Refer a job",

    _01_title: "Refer in seconds",
    _01_content: "Click the paper plane icon on featured job pages ,<br />and send your personal referral link right away.",
    _02_title: "Claim generous rewards",
    _02_content: "Both of the candidate and you <br />will be rewarded after a successful hire.",

    sharings_of_our_referral_specialists: "Sharings of Our Referral Specialists",
    browse_featured_jobs_and_refer_to_your_friend_today_: "Browse featured jobs and refer to your friend today!",
};