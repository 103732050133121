import Abstract from './abstract';
import i18n from "../../i18n";

class JobFunction extends Abstract {
    constructor(attrs) {
        super(attrs);

        const key = `${attrs.id}`;
        if(!i18n.exists(`job_functions:${key}`)) {
            i18n.addResourceBundle('en', 'job_functions', {
                [key]: attrs.name_en
            }, true, true);
            i18n.addResourceBundle('zh-TW', 'job_functions', {
                [key]: attrs.name_zh_tw
            }, true, true);
        }
    }

    get_i18n_key() {
        return `job_functions:${this.id}`;
    }
}

export default JobFunction;