import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  closeAll,
  updateEmployerMessageModal,
  updateCreateEmployerModal,
  updateUpdateDefaultPlanModal,
  updateAddMemberModal,
  updateEditMemberModal,
  updateEditInvitedMemberModal,
  updateContactMeetJobsModal,
  updateWelcomeEmployerModal,
  updateCurrentUserActiveCompany,
  updateMobileMenu,
  updateLogOutModal,
  updateSubMenu,
  updateConnectionOffModal,
  updateEmployerProfilePendingDegreeOfCompletion,
  updateDashboardSidebarCollapsed,
  updateCurrentUserEmployerRoles,
  updateCurrentUserIsEmployerUser,
  updateCurrentUserCompanies,
  addFlashMessage,
} from "../../actions";

import Header from "../views/dashboard/layouts/Header";
import IndexPage from "../views/dashboard/pages/index/IndexPage.jsx";
import JobsPage from "../views/dashboard/pages/JobsPage.jsx";
import JobPage from "../views/dashboard/pages/JobPage.jsx";
import CreateJobPage from "../views/dashboard/pages/CreateJobPage.jsx";
import PlansPage from "../views/dashboard/pages/PlansPage.jsx";
import ApplicationsPage from "../views/dashboard/pages/ApplicationsPage.jsx";
import EmployerProfilePage from "../views/dashboard/pages/EmployerProfilePage.jsx";
import MemberManagementPage from "../views/dashboard/pages/MemberManagementPage.jsx";
import UpgradePlansPage from "../views/dashboard/pages/UpgradePlansPage.jsx";
import PaymentPage from "../views/dashboard/pages/PaymentPage.jsx";
import PaymentPostonlyPrograms from "../views/dashboard/pages/PaymentPostonlyProgramsPage.jsx";
import InvoicesPage from "../views/dashboard/pages/InvoicesPage.jsx";

import EmployerMessageModal from "../views/dashboard/modals/EmployerMessageModal.jsx";
import CreateEmployerModal from "../views/dashboard/modals/CreateEmployerModal.jsx";
import UpdateDefaultPlanModal from "../views/dashboard/modals/UpdateDefaultPlanModal.jsx";
import AddMemberModal from "../views/dashboard/modals/AddMemberModal.js";
import EditMemberModal from "../views/dashboard/modals/EditMemberModal.jsx";
import EditInvitedMemberModal from "../views/dashboard/modals/EditInvitedMemberModal.jsx";
import ContactMeetJobsModal from "../views/dashboard/modals/ContactMeetJobsModal.jsx";
import WelcomeEmployerModal from "../views/dashboard/modals/WelcomeEmployerModal.jsx";
import LockedModal from "../views/dashboard/modals/LockedModal.jsx";
import Sidebar from "../views/dashboard/layouts/Sidebar";

export const HeaderWrapper = withRouter(
  connect(
    (state) => ({
      site_state: state.site_state,
      current_user: state.current_user,
      reduxTokenAuth: state.reduxTokenAuth,
    }),
    (dispatch) => ({
      updateMobileMenu(mobile_menu) {
        dispatch(updateMobileMenu(mobile_menu));
      },
      updateSubMenu(sub_menu) {
        dispatch(updateSubMenu(sub_menu));
      },
      updateCreateEmployerModal(create_employer_modal) {
        dispatch(updateCreateEmployerModal(create_employer_modal));
      },
      closeAll() {
        dispatch(closeAll());
      },
      updateCurrentUserActiveCompany(active_company) {
        dispatch(updateCurrentUserActiveCompany(active_company));
      },

      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(Header)
);

export const SidebarWrapper = withRouter(
  connect(
    (state) => ({
      site_state: state.site_state,
      current_user: state.current_user,
      reduxTokenAuth: state.reduxTokenAuth,
    }),
    (dispatch) => ({
      updateDashboardSidebarCollapsed(dashboard_sidebar_collapsed) {
        dispatch(updateDashboardSidebarCollapsed(dashboard_sidebar_collapsed));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(Sidebar)
);

export const IndexPageWrapper = withRouter(
  connect(
    (state) => ({
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(IndexPage)
);

export const JobPageWrapper = withRouter(
  connect(
    (state) => ({
      reduxTokenAuth: state.reduxTokenAuth,
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
      addFlashMessage(open, text, status, display_type, action_button_text) {
        dispatch(
          addFlashMessage(open, text, status, display_type, action_button_text)
        );
      },
      updateContactMeetJobsModal(open) {
        dispatch(updateContactMeetJobsModal(open));
      },
    })
  )(JobPage)
);

export const CreateJobPageWrapper = withRouter(
  connect(
    (state) => ({
      reduxTokenAuth: state.reduxTokenAuth,
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
      addFlashMessage(open, text, status, display_type, action_button_text) {
        dispatch(
          addFlashMessage(open, text, status, display_type, action_button_text)
        );
      },
      updateContactMeetJobsModal(open) {
        dispatch(updateContactMeetJobsModal(open));
      },
    })
  )(CreateJobPage)
);

export const JobsPageWrapper = withRouter(
  connect(
    (state) => ({
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
      addFlashMessage(open, text, status, display_type, action_button_text) {
        dispatch(
          addFlashMessage(open, text, status, display_type, action_button_text)
        );
      },
    })
  )(JobsPage)
);

export const PlansPageWrapper = withRouter(
  connect(
    (state) => ({
      reduxTokenAuth: state.reduxTokenAuth,
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateLogOutModal(log_out_modal) {
        dispatch(updateLogOutModal(log_out_modal));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
      updateUpdateDefaultPlanModal(open, plan_name, contract_url) {
        dispatch(updateUpdateDefaultPlanModal(open, plan_name, contract_url));
      },
    })
  )(PlansPage)
);

export const ApplicationsPageWrapper = withRouter(
  connect(
    (state) => ({
      reduxTokenAuth: state.reduxTokenAuth,
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateEmployerMessageModal(
        employer_message_modal,
        company_id,
        job_id,
        application_id
      ) {
        dispatch(
          updateEmployerMessageModal(
            employer_message_modal,
            company_id,
            job_id,
            application_id
          )
        );
      },
      updateCurrentUserActiveCompany(active_company) {
        dispatch(updateCurrentUserActiveCompany(active_company));
      },
      updateLogOutModal(log_out_modal) {
        dispatch(updateLogOutModal(log_out_modal));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(ApplicationsPage)
);

export const EmployerProfilePageWrapper = withRouter(
  connect(
    (state) => ({
      reduxTokenAuth: state.reduxTokenAuth,
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateCurrentUserActiveCompany(active_company) {
        dispatch(updateCurrentUserActiveCompany(active_company));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
      updateEmployerProfilePendingDegreeOfCompletion(
        employer_profile_pending_degree_of_completion
      ) {
        dispatch(
          updateEmployerProfilePendingDegreeOfCompletion(
            employer_profile_pending_degree_of_completion
          )
        );
      },
    })
  )(EmployerProfilePage)
);

export const MemberManagementPageWrapper = withRouter(
  connect(
    (state) => ({
      reduxTokenAuth: state.reduxTokenAuth,
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateAddMemberModal(open, company_id, role_id) {
        dispatch(updateAddMemberModal(open, company_id, role_id));
      },
      updateEditMemberModal(open, roleship, role, creator_user_id) {
        dispatch(updateEditMemberModal(open, roleship, role, creator_user_id));
      },
      updateEditInvitedMemberModal(
        open,
        employer_id,
        invited_roleship,
        role,
        creator_user_id
      ) {
        dispatch(
          updateEditInvitedMemberModal(
            open,
            employer_id,
            invited_roleship,
            role,
            creator_user_id
          )
        );
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(MemberManagementPage)
);

export const UpgradePlansPageWrapper = withRouter(
  connect(
    (state) => ({
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateCurrentUserActiveCompany(active_company) {
        dispatch(updateCurrentUserActiveCompany(active_company));
      },
      updateContactMeetJobsModal(open) {
        dispatch(updateContactMeetJobsModal(open));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
      updateWelcomeEmployerModal(
        open,
        banner_img_url,
        title,
        content,
        and_content,
        plus_content
      ) {
        dispatch(
          updateWelcomeEmployerModal(
            open,
            banner_img_url,
            title,
            content,
            and_content,
            plus_content
          )
        );
      },
    })
  )(UpgradePlansPage)
);

export const PaymentPageWrapper = withRouter(
  connect(
    (state) => ({
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateCurrentUserActiveCompany(active_company) {
        dispatch(updateCurrentUserActiveCompany(active_company));
      },
      updateContactMeetJobsModal(open) {
        dispatch(updateContactMeetJobsModal(open));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(PaymentPage)
);

export const PaymentPostonlyProgramsPageWrapper = withRouter(
  connect(
    (state) => ({
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateCurrentUserActiveCompany(active_company) {
        dispatch(updateCurrentUserActiveCompany(active_company));
      },
      updateContactMeetJobsModal(open) {
        dispatch(updateContactMeetJobsModal(open));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(PaymentPostonlyPrograms)
);

export const InvoicesPageWrapper = withRouter(
  connect(
    (state) => ({
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(InvoicesPage)
);

export const EmployerMessageModalWrapper = withRouter(
  connect(
    (state) => ({
      current_user: state.current_user,
      reduxTokenAuth: state.reduxTokenAuth,
      site_state: state.site_state,
    }),
    (dispatch) => ({
      updateEmployerMessageModal(
        employer_message_modal,
        company_id,
        job_id,
        application_id
      ) {
        dispatch(
          updateEmployerMessageModal(
            employer_message_modal,
            company_id,
            job_id,
            application_id
          )
        );
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
      updateApplicaitonsStatus() {
        // console.log(" test");
      },
    })
  )(EmployerMessageModal)
);

export const CreateEmployerModalWrapper = connect(
  (state) => ({
    site_state: state.site_state,
  }),
  (dispatch) => ({
    updateCreateEmployerModal(create_employer_modal) {
      dispatch(updateCreateEmployerModal(create_employer_modal));
    },
  })
)(CreateEmployerModal);

export const UpdateDefaultPlanModalWrapper = connect(
  (state) => ({
    site_state: state.site_state,
    current_user: state.current_user,
  }),
  (dispatch) => ({
    updateUpdateDefaultPlanModal(open, plan_name, contract_url) {
      dispatch(updateUpdateDefaultPlanModal(open, plan_name, contract_url));
    },
  })
)(UpdateDefaultPlanModal);

export const AddMemberModalWrapper = withRouter(
  connect(
    (state) => ({
      site_state: state.site_state,
    }),
    (dispatch) => ({
      updateAddMemberModal(open, company_id, role_id) {
        dispatch(updateAddMemberModal(open, company_id, role_id));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(AddMemberModal)
);

export const EditMemberModalWrapper = withRouter(
  connect(
    (state) => ({
      reduxTokenAuth: state.reduxTokenAuth,
      site_state: state.site_state,
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateEditMemberModal(open, roleship, role, creator_user_id) {
        dispatch(updateEditMemberModal(open, roleship, role, creator_user_id));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
      updateCurrentUserEmployerRoles(employer_roles) {
        dispatch(updateCurrentUserEmployerRoles(employer_roles));
      },
      updateCurrentUserCompanies(companies) {
        dispatch(updateCurrentUserCompanies(companies));
      },
      updateCurrentUserActiveCompany(active_company) {
        dispatch(updateCurrentUserActiveCompany(active_company));
      },
      updateCurrentUserIsEmployerUser(is_employer_user) {
        dispatch(updateCurrentUserIsEmployerUser(is_employer_user));
      },
      addFlashMessage(open, text, status, display_type, action_button_text) {
        dispatch(
          addFlashMessage(open, text, status, display_type, action_button_text)
        );
      },
    })
  )(EditMemberModal)
);

export const EditInvitedMemberModalWrapper = withRouter(
  connect(
    (state) => ({
      reduxTokenAuth: state.reduxTokenAuth,
      site_state: state.site_state,
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateEditInvitedMemberModal(
        open,
        employer_id,
        invited_roleship,
        role,
        creator_user_id
      ) {
        dispatch(
          updateEditInvitedMemberModal(
            open,
            employer_id,
            invited_roleship,
            role,
            creator_user_id
          )
        );
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(EditInvitedMemberModal)
);

export const ContactMeetJobsModalWrapper = withRouter(
  connect(
    (state) => ({
      site_state: state.site_state,
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateContactMeetJobsModal(open) {
        dispatch(updateContactMeetJobsModal(open));
      },
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(ContactMeetJobsModal)
);

export const WelcomeEmployerModalWrapper = withRouter(
  connect(
    (state) => ({
      site_state: state.site_state,
    }),
    (dispatch) => ({
      updateWelcomeEmployerModal(
        open,
        banner_img_url,
        title,
        content,
        and_content,
        plus_content
      ) {
        dispatch(
          updateWelcomeEmployerModal(
            open,
            banner_img_url,
            title,
            content,
            and_content,
            plus_content
          )
        );
      },
    })
  )(WelcomeEmployerModal)
);

export const LockedModalWrapper = withRouter(
  connect(
    (state) => ({
      current_user: state.current_user,
    }),
    (dispatch) => ({
      updateConnectionOffModal(connection_off_modal) {
        dispatch(updateConnectionOffModal(connection_off_modal));
      },
    })
  )(LockedModal)
);
